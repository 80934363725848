import React, {
  useState,
  useEffect,
  useRef,
  useMemo
} from "react";
import { Row, Col } from "react-bootstrap";
import { Select, Button } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import {
  useOrdersColumns
} from "../layout/tableColumns";
import { addRunsheet } from "../../stateManagement/slices/addRunsheetSlice";
import { addRunsheetOrders } from "../../stateManagement/slices/rsOrdersSlice";
import { useReassignedRS, isReAssigned as isReAssignedAction } from "../../stateManagement/slices/reassignedRSSlice";

import TablePro from "../tables/TablePro";
import OrderDetails from "../layout/OrderDetails";
import {
  useGetOrderMutation,
  useFilterOrdersMutation
} from "../../stateManagement/apis/ordersApi";
import { useGetBranchesMutation, useFilterAreasMutation } from "../../stateManagement/apis/treecodeApi";
import { useGetRunsheetMutation, useRemoveOrdersFromRunsheetMutation } from "../../stateManagement/apis/runsheetsApi";
import { useFilterCouriersMutation } from "../../stateManagement/apis/accountsApi";
import GenericForm from "../form/GenericForm";
import { notifyErrors } from "../../api/ErrorNotifier";
import scannerListener from "../../features/admin/ticket-scan/domain/scanner-listener";

function RunsheetOrders ({ value, isReAssigned, handleChange, removeOrders }) {
  const dispacthReassign = useDispatch();
  const [page] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [getOrder] = useGetOrderMutation();
  const [getBranchesApi] = useGetBranchesMutation();
  const [getRunsheet] = useGetRunsheetMutation();
  const [filterOrders] = useFilterOrdersMutation();
  const [filterAreas] = useFilterAreasMutation();
  const [removeOrdersFromRunsheet, { isLoading: removeOrdersLoading }] = useRemoveOrdersFromRunsheetMutation();
  const [filterCouriers, { isLoading: filterCouriersLoading }] = useFilterCouriersMutation();
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [courierSearch, setCourierSearch] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns = useOrdersColumns();
  const [filters, setFilters] = useState({
    branch: undefined,
    // city: undefined,
    runsheet_type: undefined,
    pickupArea: undefined,
    deliveryArea: undefined
    // area: undefined,
  });
  const [filterData, setFilterData] = useState({
    branch: [],
    runsheet_type: ["RPS", "ODRS", "RRS", "HRS"],
    pickupArea: [],
    deliveryArea: []
    // area: [],
  });

  const handleOrderScan = (orderWaybill) => {
    const orders = Array.isArray(orderWaybill) ? orderWaybill : [orderWaybill];
    orders.forEach((waybill) => {
      const order = data.find((it) => it.waybill === waybill);
      if (order) {
        const idsSet = new Set([...selectedRowKeys.map((it) => it.id)]);
        if (idsSet.has(order.id)) {
          setSelectedRowKeys((prev) => prev.filter((it) => it.id !== order.id));
        } else {
          setSelectedRowKeys((prev) => [...prev, order]);
        }
      }
    });
  };

  const getAreas = async () => {
    const result = await filterAreas({
      page_size: 10000
    });
    if (result.data) {
      setFilterData((prev) => ({
        ...prev,
        pickupArea: result.data.data,
        deliveryArea: result.data.data
      }));
    }
  };

  const getCouriers = async () => {
    const result = await filterCouriers({
      search: courierSearch,
      available: "available",
      branch: filters.branch
    });
    if (result.data) {
      setCouriers(result.data.data ?? []);
    }
  };

  const fetchOrderDetails = async (id, page) => {
    const result = await getOrder(id, {
      page_size: 4,
      page: page ?? 1
    });
    if (result.data) {
      setModalData(result.data);
    }
  };

  const onPaginateTickets = async (page) => {
    setModalLoading(true);
    await fetchOrderDetails(modalData.id, page);
    setModalLoading(false);
  };

  const filterCriterion = {
    branch: (it) => +it.current_branch.id === +filters.branch,
    pickupArea: (it) => {
      return +it.seller_address?.area?.id === +filters.pickupArea;
    },
    deliveryArea: (it) => {
      return +it.client_address?.area?.id === +filters.deliveryArea;
    },
    runsheet_type: (it) => it.runsheet_type.includes(filters.runsheet_type)
  };

  const filteredData = useMemo(() => {
    let filtered = data;
    if (!isReAssigned && !removeOrders) {
      filtered = filtered.filter((it) => {
        let condition = true;
        for (const key in filters) {
          if (filters[key] !== undefined) {
            condition = filterCriterion[key](it);
          }
        }
        return condition;
      });
    }

    if (searchTerm) {
      return filtered.filter((it) =>
        it.id.toString().includes(searchTerm)
      );
    } else return filtered;
  }, [data, filters, searchTerm, isReAssigned, removeOrders]);

  const [orders, setOrders] = useState([]);

  const prevSelectionModel = useRef(selectionModel);
  const prevOrders = useRef(orders);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(value);
  }, []);
  const dispatchOrders = useDispatch();
  const reAssignedRS = useReassignedRS();
  const getOrders = async () => {
    const result = await filterOrders({
      for_runsheet: true,
      state: "1",
      page_size: 100000,
      current_branch: filters.branch
    });
    if (result.data) {
      setData(result.data.data);
    }
  };

  const getBranches = async () => {
    const result = await getBranchesApi({
      page_size: 10000
    });
    if (result.data) {
      setFilterData((prev) => ({ ...prev, branch: result.data.data }));
    }
  };

  useEffect(() => {
    getBranches();
    // getCities();
    getAreas();
  }, []);

  useEffect(() => {
    if (isReAssigned || removeOrders) {
      getSelectedRsOrders();
    } else {
      if (filters.branch) {
        getCouriers();
        getOrders();
      }
    }
  }, [filters.branch, isReAssigned, removeOrders]);

  useEffect(() => {
    if (filters.branch) {
      getCouriers();
    }
  }, [courierSearch]);

  // const searching = (e) => {
  //   setSearchTerm(e.target.value);
  //   searchDispatch(search(e.target.value));
  // };

  useEffect(() => {
    const orderIDs = [
      ...new Set([...selectionModel, ...prevSelectionModel.current])
    ];
    const orderObject = [...new Set([...orders, ...prevOrders.current])];
    console.log({ orderIDs, orderObject });
    dispatch(addRunsheet({ orders: orderIDs }));
    dispatchOrders(addRunsheetOrders(orderObject));
  }, [selectionModel]);

  useEffect(() => {
    if (isReAssigned) {
      getSelectedRsOrders();
    }
  }, []);

  useEffect(() => {
    setSelectionModel([...selectionModel, ...prevSelectionModel.current]);
    setOrders([...orders, ...prevOrders.current]);
    console.log("selection model ", selectionModel);
  }, [page, data]);

  useEffect(() => {
    console.log({ reAssignedRS });
    if (isReAssigned || removeOrders) {
      console.log("REASSIGNED RS", reAssignedRS);
      setFilters({
        runsheet_type: reAssignedRS.type,
        branch: reAssignedRS.branch?.id
        // area: reAssignedRS.branch.area,
      });
    }
  }, [isReAssigned, removeOrders]);
  const proceed = () => {
    console.log("FILTERS", filters);
    if (!filters.branch) {
      notifyErrors(t("Please select branch"));
      return;
    }
    if (!isReAssigned && !removeOrders) {
      if (!selectedCourier) {
        notifyErrors(t("Please select courier"));
        return;
      }
    }

    // Log data and selectedRowKeys for debugging
    console.log("selectedRowKeys:", selectedRowKeys);
    console.log("data:", data);

    // Extract the `id` values from selectedRowKeys for comparison
    const selectedRowIds = selectedRowKeys.map((row) => row.id);

    // Filter the data based on selectedRowIds
    const selectedOrders = data.filter((it) => selectedRowIds.includes(it.id));

    // Log selectedOrders to verify the filtering
    console.log("selectedOrders:", selectedOrders);

    if (selectedOrders.length === 0 && !removeOrders && !isReAssigned) {
      toast.error("No orders selected. Please select orders before proceeding.");
      return;
    }

    dispatch(
      addRunsheet({ orders: selectedRowIds, branch: filters.branch, courier: couriers.find((it) => it.id === selectedCourier) })
    );
    dispatchOrders(addRunsheetOrders(selectedOrders));

    const filterObj = Object.keys(filters).reduce((prev, key) => {
      return { ...prev, [key]: filters[key] };
    }, {});
    console.log("FILTER OBJ:", filterObj);

    dispatch(addRunsheet(filterObj));
    handleChange(null, value + 1);
  };

  const getSelectedRsOrders = async () => {
    console.log("ORDER");
    const result = await getRunsheet(reAssignedRS.id);
    if (result.data) {
      setData(result.data.orders);
    }
  };

  useEffect(() => {
    return () => {
      dispacthReassign(isReAssignedAction(false));
      console.log("cleaned up");
    };
  }, []);

  // Define the fields array for GenericForm
  const fields = Object.keys(filters).map((key) => ({
    name: key,
    label: t(key),
    show: true,
    component: Select,
    props: {
      allowClear: true,
      value: filters[key],
      disabled: isReAssigned,
      showSearch: true,
      onChange: (value) => {
        setFilters((prev) => ({
          ...prev,
          [key]: value
        }));
      },
      filterOption: (input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      filterSort: (optionA, optionB) =>
        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()),
      optionFilterProp: "children",
      children: filterData[key].map((it) => (
          <Select.Option
            value={typeof it === "string" ? it : it.id}
            key={typeof it === "string" ? it : it.id}
          >
            {typeof it === "string" ? it : it.name}
          </Select.Option>
      ))
    }
  }));

  fields.push({
    name: "courier",
    label: t("Courier"),
    show: !isReAssigned && !removeOrders,
    component: Select,
    props: {
      loading: filterCouriersLoading,
      allowClear: true,
      value: selectedCourier,
      onChange: (value) => {
        setSelectedCourier(value);
      },
      onSearch: debounce((value) => {
        setCourierSearch(value);
      }, 1000),
      filterOption: false,
      showSearch: true,
      options: couriers.map((it) => ({
        value: it.id,
        label: it.user.name
      }))
    }
  });

  const handleRemoveOrders = async () => {
    const result = await removeOrdersFromRunsheet({
      runsheetId: reAssignedRS.id,
      orders: selectedRowKeys.map((it) => it.id)
    });
    if (result.data) {
      navigate(`/edit_runsheet_priorities/${reAssignedRS.id}/ext`);
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors(t("Something went wrong"));
    }
  };

  useEffect(() => {
    if (!(filteredData || []).length) return;
    const cancel = scannerListener(handleOrderScan);
    return () => cancel();
  }, [filteredData?.length]);

  return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={true}
                draggable={true}
                pauseOnHover={true}
            />
            <Row>
                <Col sm="6">
                    {isReAssigned
                      ? (
                        <p className="orderID">Runsheet ID#{reAssignedRS.id}</p>
                        )
                      : null}
                </Col>
                <Col className="text-end" sm={6}>
                    {
                    (isReAssigned && !removeOrders)
                      ? (
                        <>
                            {/* <button className="cancel">{t("back")}</button> */}

                            <Button onClick={proceed} className="confirm">
                                {t("next")}
                            </Button>
                        </>
                        )
                      : null}
                </Col>
            </Row>
            <div>
            <GenericForm
      fields={fields.filter((it) => it.show)}
      submitFunction={proceed}
      formDisabled={isReAssigned}
      hooks={{
        initialValues: filters
      }}
    />
            </div>

            {
            (isReAssigned && !removeOrders)
              ? null
              : (

                <Row className="my-2">
                    <Col className="text-end buttons-margin" sm="12">
                        {/* <button className="cancel">{t("discard")}</button> */}

                        <Button
                            disabled={selectedRowKeys.length === 0}
                            onClick={removeOrders ? handleRemoveOrders : proceed}
                            className="confirm"
                            loading={removeOrdersLoading}
                        >
                            {removeOrders ? t("Remove") : t("next")}
                        </Button>
                    </Col>
                </Row>
                )}

            <div>
                <Row>
                    <Col sm="12">
                        <div className="position-relative">
                        <TablePro
                          showSelectedCount
                          onSearch={setSearchTerm}
                          columns={columns}
                          selection={!isReAssigned || removeOrders} // Toggle selection
                          onRowClick={(row) => {
                            fetchOrderDetails(row.id); // Fetch details on row click
                          }}
                          selectedRows={selectedRowKeys} // Bind selected row keys
                          setSelectedRows={setSelectedRowKeys} // Function to update selected rows
                          dataSource={isReAssigned ? data : filteredData} // Dynamic data source
                          pagination={{
                            size: "medium",
                            showSizeChanger: true // Allow page size change
                          }}
                          rowKey="id" // Ensure unique row key
                        />

                            {
                                modalData && (
                                    <OrderDetails
                                        pagination={onPaginateTickets}
                                        current={modalData.tickets.page ?? 1}
                                        tickets={modalData.tickets}
                                        ticketsCount={
                                            modalData.tickets.count
                                        }
                                        show={modalData}
                                        loading={modalLoading}
                                        setClose={() => setModalData(null)}
                                        data={modalData}
                                    />
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </>
  );
}

export default RunsheetOrders;
