import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Menu, Dropdown } from "antd";
import { CheckCircleFilled, CloseCircleFilled, PlusCircleFilled, MinusCircleFilled, EyeFilled, UserSwitchOutlined } from "@ant-design/icons";
import { SuccessfulRequest, UnSuccessfulRequest } from "../modals/confirmationModal";
import TablePro from "../tables/TablePro";
import useLanguage from "../../hooks/useLanguage";
import { useFilterInternalRunsheetsMutation, useAcceptRejectInternalRunsheetMutation } from "../../stateManagement/apis/runsheetsApi";
import { stringErrorsHandler } from "../../api/ErrorNotifier";

function AllInternals () {
  const [sheets, setSheets] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modaltype, setModalType] = useState("success");
  const lang = useLanguage();
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    success: false
  });
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterInternalRunsheets, { isLoading: loading }] = useFilterInternalRunsheetsMutation();
  const [acceptRejectInternalRunsheet, { isLoading: loadingAction }] = useAcceptRejectInternalRunsheetMutation();

  const handleModalClose = () => {
    setShowModal(false);
    setModalData({
      title: "",
      body: "",
      success: false
    });
  };

  const getRunSheets = async () => {
    const result = await filterInternalRunsheets({ page, search: searchTerm, ordering: "-id" });
    if (result.data) {
      setSheets(result.data.data);
      setCount(result.data.count);
    }
  };
  useEffect(() => {
    getRunSheets();
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getRunSheets();
    }
  }, [searchTerm]);

  const acceptRunsheet = async (id, state) => {
    const result = await acceptRejectInternalRunsheet({ runsheetId: id, state });
    if (result.data) {
      setModalType("success");
      setModalData({
        title: t(`runsheet${state === "accepted" ? "Accept" : "Reject"} Runsheet`),
        body: t(`Runsheet ${state === "accepted" ? "Accepted" : "Rejected"} Successfully`),
        success: true
      });
      getRunSheets();
    } else if (result.error?.data) {
      setModalType("error");
      setModalData({
        title: t(`runsheet${state === "accepted" ? "Accept" : "Reject"} Runsheet`),
        body: stringErrorsHandler(result.error.data),
        success: false
      });
    } else {
      setModalType("error");
      setModalData({
        title: t(`runsheet${state === "accepted" ? "Accept" : "Reject"} Runsheet`),
        body: t("server_error"),
        success: false
      });
    }
    setShowModal(true);
  };

  const columns = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id"
    },
    {
      title: t("courier"),
      key: "courier",
      dataIndex: "courier"
    },
    {
      title: t("branches.from"),
      key: "branch",
      dataIndex: "branch"
    },
    {
      title: t("branches.to"),
      key: "to_branch",
      dataIndex: "to_branch"
    },
    {
      title: t("status"),
      dataIndex: "state_display",
      width: 150,
      render: (_, row) =>
        row.state === "1"
          ? (
                    <button className="btn accepted">Accepted</button>
            )
          : row.state === "2"
            ? (
                    <p className="btn rejected">Rejected</p>
              )
            : row.state === "3"
              ? (
                    <p className="btn pending">Pending</p>
                )
              : (
                    <p className="btn closed">Closed</p>
                )
    },
    {
      title: t("actions"),
      key: "actions",
      render: (_, row) => (
                <Dropdown
                    overlay={<ThreeDotsDropdown row={row} acceptRunsheet={acceptRunsheet} loadingAction={loadingAction} t={t} navigate={navigate} />}
                    trigger={["click", "hover"]}
                    placement={lang === "en" ? "bottomLeft" : "bottomRight"}
                    disabled={loadingAction}
                    arrow={true}
                >
                    <i className="fas fa-ellipsis-v fa-lg cursor-pointer"></i>
                </Dropdown>)

    }
  ];

  return (
        <Container>
            <SuccessfulRequest
                hide={handleModalClose}
                show={showModal && modaltype === "success"}
                {...modalData}
            />
            <UnSuccessfulRequest
                hide={handleModalClose}
                show={showModal && modaltype === "error"}
                {...modalData}
            />
            <Row className="mb-4" >
                <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.internalRunSheet")}
                        </span>

                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                            {t("sideMenu.allInternals")}
                        </span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">
                        {t("sideMenu.allInternals")}
                    </h2>
                </Col>
            </Row>
            <div className={"tableContainer"}>
                <TablePro
                    loading={loading}
                    dataSource={sheets}
                    columns={columns}
                    onSearch={setSearchTerm}
                    pagination={{
                      size: "medium",
                      current: page,
                      onChange: setPage,
                      total: count
                    }}
                    rowKey={(row) => row.id}
                />
            </div>
        </Container>
  );
}

export default AllInternals;

const ThreeDotsDropdown = ({ row, acceptRunsheet, loadingAction, t, navigate }) => {
  return (
        <Menu>
            <Menu.Item
                key="4"
                icon={<EyeFilled style={{ color: "#1890ff" }}/>}
                onClick={() => {
                  navigate(`/edit_runsheet_priorities/${row.id}/int`);
                }}
                disabled={loadingAction}
            >
                {t("View Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="0"
                icon={<CheckCircleFilled style={{ color: "#52c41a" }}/>}
                onClick={() => {
                  acceptRunsheet(row.id, "accepted");
                }}
                disabled={loadingAction || row.state !== "3"}
            >
                {t("Accept Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="1"
                icon={<CloseCircleFilled style={{ color: "#ff4d4f" }}/>}
                onClick={() => {
                  acceptRunsheet(row.id, "rejected");
                }}
                disabled={loadingAction || row.state !== "3"}
            >
                {t("Reject Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="2"
                icon={<PlusCircleFilled style={{ color: "#1890ff" }}/>}
                onClick={() => {
                  navigate(`/edit_internal/${row.id}/add`);
                }}
                disabled={loadingAction || row.state !== "3"}
            >
                {t("Add Orders to Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="3"
                icon={<MinusCircleFilled style={{ color: "#ff4d4f" }}/>}
                onClick={() => {
                  navigate(`/edit_internal/${row.id}/remove`);
                }}
                disabled={loadingAction || row.orders.length < 1 || row.state !== "3"}
            >
                {t("Remove Orders from Runsheet")}
            </Menu.Item>
            <Menu.Item
                key="5"
                icon={<UserSwitchOutlined style={{ color: "#1890ff" }}/>}
                onClick={() => {
                  navigate(`/reassign_internal/${row.id}`);
                }}
                disabled={loadingAction || row.state !== "3"}
            >
                {t("Reassign Runsheet")}
            </Menu.Item>

        </Menu>
  );
};
