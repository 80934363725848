import React from "react";
import { Spin, Switch } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./PermissionsList.module.css";

function PermissionCard ({ permission, onUpdate }) {
  const onChange = (checked) => {
    onUpdate(checked);
  };

  const { t } = useTranslation();

  return (
    <li>
      <div className={styles.card}>
        <div>{permission.name}</div>
        <Switch
          checked={permission.active}
          onChange={onChange}
          checkedChildren={t("activate")}
          unCheckedChildren={t("deactivate")}
        />
      </div>
    </li>
  );
}

export default function PermissionsList ({
  permissions,
  loading,
  updatePermission
}) {
  const { t } = useTranslation();
  return (
        <div className={styles.container}>
            <h1>{t("permissions")}</h1>

            {loading
              ? (
                <div>
                    <Spin />
                </div>
                )
              : (
                <ul>
                    {permissions?.map((permission, i) => (
                        <PermissionCard
                            onUpdate={(v) => updatePermission(i, v)}
                            key={permission.id}
                            permission={permission}
                        />
                    ))}
                </ul>
                )}
        </div>
  );
}
