import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const locale = i18n;
locale
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
// pass the i18n instance to react-i18next.
  .use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "ar",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {

        translation: {
          location: "Location",
          addProduct: {
            title: "Add Product",
            photoLabel: "Product photo",
            photoRequired: "Please upload at least one product photo!",
            photo: "Photo {{index}}",
            upload: "Upload",
            productNameLabel: "Product name",
            productNameRequired: "Please enter the product name!",
            productNamePlaceholder: "Enter product name",
            skuLabel: "SKU",
            skuRequired: "Please enter the SKU!",
            skuPlaceholder: "Enter SKU",
            barcodeLabel: "Barcode",
            barcodeRequired: "Please enter the barcode!",
            barcodePlaceholder: "Enter barcode",
            categoryLabel: "Category",
            categoryRequired: "Please select a category!",
            categoryPlaceholder: "Select category",
            merchantLabel: "Merchant",
            merchantRequired: "Please select a merchant!",
            merchantPlaceholder: "Select merchant",
            priceLabel: "Price",
            priceRequired: "Please enter the product price!",
            priceInvalid: "Please enter a valid price (e.g., 99.99)!",
            pricePlaceholder: "Enter price",
            descriptionLabel: "Description",
            descriptionPlaceholder: "Enter description",
            instructionsLabel: "Instructions",
            instructionsRequired: "Please enter the instructions!",
            instructionsPlaceholder: "Enter product usage instructions",
            cancel: "Cancel",
            next: "Next",
            messages: {
              loadDataFailed: "Failed to load data.",
              invalidFile: "{{fileName}} is not a supported image type or exceeds the size limit of 5MB.",
              productCreated: "Product created successfully!",
              createProductFailed: "Failed to create product."
            }
          },
          products: "Products",
          searchPlaceholder: "Search with Runsheet ID",
          filter: "Filter",
          productName: "Product Name",
          productCategory: "Product Category",
          productCode: "Product Code",
          actions: "Actions",
          createNewOrder: "Create New Order",
          inventoryOrderInfo: "Inventory Order Information",
          orderType: "Order Type",
          orderTypeRequired: "Order Type is required",
          return: "Return",
          receive: "Receive",
          transfer: "Transfer",
          selectLocation: "Select Location",
          locationRequired: "Location is required",
          orderItems: "Order Items",
          item: "Item",
          selectItem: "Select Item",
          quantity: "Quantity",
          quantityRequired: "Quantity is required",
          inventoryLocation: "Inventory Location",
          selectInventoryLocations: "Select Inventory Locations",
          inventoryLocationRequired: "Inventory Location is required",
          addItem: "Add Item",
          sellerInfo: "Seller Information",
          sellerName: "Seller Name",
          selectSeller: "Select Seller",
          sellerNameRequired: "Seller Name is required",
          loading: "Loading...",
          noData: "No Data Found",
          loadMore: "Load More",
          cancel: "Cancel",
          createOrder: "Create Order",
          address: "Address",
          addressRequired: "Address is required",
          businessName: "Business Name",
          businessNameRequired: "Business Name is required",
          businessNamePlaceholder: "Enter Business Name",
          phoneNumber: "Phone Number",
          phoneNumberRequired: "Phone Number is required",
          phoneNumberPlaceholder: "Enter Phone Number",
          exceedsAvailableQuantity: "Exceeds available quantity",
          errorCreatingOrder: "Error creating order",
          orderCreatedSuccessfully: "Order created successfully",
          AvailableShelves: "Available Shelves",
          availabe: "Availabe",
          used: "Used",
          create: "Create",
          "Are you sure you want to delete the category?": "Are you sure you want to delete the category?",
          DeleteCategory: "Delete Category",
          stock_overview: "Stock Overview",
          returnType: "Return Type",
          Select_Return_Type: "Select Return Type",
          website: "Website",
          facebook: "Facebook",
          instagram: "Instagram",
          twitter: "Twitter",
          AllOrders: "All Orders",
          Approved: "Approved",
          Rejected: "Rejected",
          Completed: "Completed",
          Groups: "Groups",
          Filter: "Filter",
          ExportAll: "Export All",
          Duplicate: "Duplicate",
          Delete: "Delete",
          MarkasCompleted: "Mark as Completed",
          MarkasRejected: "Mark as Rejected",
          SearchWaybillNumber: "Search Waybill Number",
          DashboardFilters: "Dashboard Filters",
          WaybillNumber: "Waybill Number",
          InventoryOrders: "Inventory Orders",
          confirmation: "Confirmation",
          archivedOrders_sendRequest: "Are you sure you want to complete this order?",
          archivedOrders: "Archived Orders",
          orderDeleted: "Order Deleted",
          orderDeletionError: "Error Deleting Order",
          confirmationTitle: "Confirmation",
          confirmationText: "Are you sure you want to send this request?",
          details: "Details",
          deleteArchive: "Delete Archive",
          weight: "Weight",
          specialLabel: "Special Label",
          orderId: "Order ID",
          createDate: "Create Date",
          customerInfo: "Customer Info",
          store: "Store",
          sellerAddress: "Seller Address",
          orderDetails: "Order Details",
          completeArchive: "Complete Archive",
          productInfo: "Product Info",
          archive_order: "Archive Order",
          archiving_msg: "Are you sure you want to archive this order to finish it later ?",
          specialLabel_is_required: "Special Label is required",
          SpecialCare: "Special Care",
          Fragile: "Fragile",
          Allow_Open_Package: "Allow Open Packages",
          cod_disclaimer: "this `Cash on Dilevery` field is used if you need to collect cash amount greater than the calculated amount from the customer.",
          Pos: "POS",
          CashOnDelivery: "Cash On Delivery",
          notes: "Notes",
          SAR: "SAR",
          integrationApps: "Integration Apps",
          all: "All",
          "3PL": "3PL",
          ecommerce: "E-commerce",
          sms: "SMS",
          paymentGateways: "Payment Gateways",
          maps: "Maps",
          activate: "Activate",
          deactivate: "Deactivate",
          thirdParty: "Third Party",
          FromInventoryStock: "From Inventory Stock",
          percentage: "Percentage",
          free: "Free",
          same: "Same as Previous",
          pricing: "Pricing",
          cod_fees: "COD Fees",
          return_fees: "Return Fees",
          reverse_shipment_fees: "Reverse Shipment Fees",
          extra_shipment_weight: "Extra Shipment Weight",
          discount: "Discount",
          discount_percentage: "Discount Percentage",
          target_orders_volume: "Target Orders Volume",
          active: "Active",
          from_date: "From Date",
          tier: "Tier",
          same_day: "Same Day",
          next_day: "Next Day",
          first_100: "First 100",
          up_to_500: "Up to 500",
          above_500: "Above 500",
          select_work_model: "Select Work Model",
          selectTickets: "Select Tickets",
          forcePrice: "Force Price",
          oldShippingFees: "Original Order Shipping Fees",
          leaveComment: "Leave a Comment",
          cancelComment: "Discard Comment",
          confirmPayment: "Confirm Payment",
          paymentMethodConfirm: "Payment Confirmed Successfully",
          itemQuantity: "Item Quantity",
          sellerId: "Seller Id",
          customerId: "Customer Id",
          customerName: "Customer Name",
          customerPhone: "Customer Phone",
          customerAddress: "Customer Address",
          courierName: "Courier Name",
          feedbackComment: "Feedback Comment",
          shipmentFees: "Shipment Fees",
          editTiersAreas: "Edit Tiers Areas",
          editWarehouse: "Edit Warehouse",
          addLocation: "Add Location",
          createWarehouse: "Create Warehouse",
          newWarehouse: "New Warehouse",
          editWarehouseLocation: "Edit Warehouse Location",
          addInventoryRule: "Add Inventory Rule",
          editWarehouseLocationRule: "Edit Warehouse Location Rule",
          createWarehouselocationRule: "Create Warehouse Location Rule",
          newWarehouseLocationRule: "New Warehouse Location Rule",
          createWarehouselocation: "Create Warehouse Location",
          newWarehouseLocation: "New Warehouse Location",
          createProductCategory: "Create Product Category",

          "Template Name": "Template Name",
          "Template Description": "Template Description",
          "Template Code": "Template Code",
          "Recipient Type": "Recipient Type",
          Actions: "Actions",
          "Edit Sms Template": "Edit Sms Template",
          Name: "Name",
          Description: "Description",
          "Message Content (English)": "Message Content (English)",
          "Message Content (Arabic)": "Message Content (Arabic)",
          Keywords: "Keywords",
          "Update Template": "Update Template",
          "Courier Name": "Courier Name",
          "Courier Phone": "Courier Phone",
          "Courier Balance": "Courier Balance",
          "Settle Balance": "Settle Balance",
          "AWB Label Type": "AWB Label Type",
          "Configure AWB Label Type": "Configure AWB Label Type",
          Active: "Active",
          "Courier Wallet": "Courier Wallet",
          Inventory: "Inventory",
          "Order Types": "Order Types",
          "Manage Order Types And Their Prices": "Manage Order Types And Their Prices",
          "Order AWB": "Order AWB",
          "Ticket AWB": "Ticket AWB",
          "Change AWB Type": "Change AWB Type",
          "AWB Type": "AWB Type",
          "Order Type Name": "Order Type Name",
          "Delivery Within": "Delivery Within",
          "Pricing Type": "نوع التسعير",
          "Extra Price": "سعر إضافي",
          "Reduced Price": "Reduced Price",
          "Default Type?": "Default Type?",
          "Create Order Type": "Create Order Type",
          "Edit Order Type": "Edit Order Type",
          "Within Unit": "Within Unit",
          "Within Period": "Within Period",
          "Restricted Areas": "Restricted Areas",
          "Restricted Time Start": "Restricted Time Start",
          "Restricted Time End": "Restricted Time End",
          "Is Default": "Is Default",
          "Seller Can Pick": "Seller Can Pick",
          "Workflow States": "Workflow States",
          "Selected States Order": "Selected States Order",
          "Available States": "Available States",
          "Enter name": "Enter name",
          "Enter description": "Enter description",
          "Enter within period": "Enter within period",
          "Enter reduced price": "Enter reduced price",
          "Enter extra price": "Enter extra price",
          "Select areas to restrict": "Select areas to restrict",
          "Select time": "Select time",
          "Drop state here": "Drop state here",
          "OTP Delivery": "OTP Delivery",
          "Activate Third Party": "Activate Third Party",
          "Order Type": "Order Type",
          pos_fees: "POS Fees",
          approved: "Approved",
          "Enter address to search": "Enter address to search",
          Search: "Search",

          "Select AWB Type": "Select AWB Type",
          storeName: "Store Name",
          "Select Order Type": "Select Order Type",
          sideMenu: {
            wallets: "Wallets",
            allCourierWallets: "All Courier Wallets",
            courierWallets: "Courier Wallets",
            adminDashboard: "Admin Dashboard",
            inventoryDashboard: "Inventory Dashboard",
            productCategories: "Product Categories",

            allProductCategories: "All Product Categories",
            allWarehouses: "All Warehouses",
            locationRules: "Location Rules",
            locations: "Locations",
            warehouseLocations: "Warehouse Locations",
            warehouses: "Warehouses",
            warehouseLocationsRules: "Warehouse Locations Rules",
            locationsRules: "Locations Rules",
            webhooks: "Webhooks",
            smsTemplates: "SMS Templates",
            allSmsTemplates: "All SMS Templates",
            apiKeys: "API Keys",
            all_tiers: "All Tiers",
            tiers: "Tiers",
            tier: "Tier",
            createInternal: "Create Internal RunSheet",
            allInternals: "All Internal RunSheets",
            internalRunSheet: "Internal RunSheets",
            all_work_models: "All Work Models",
            view_work_model: "View Work Model",
            work_models: "Work Models",
            price_list: "Price List",
            salesChannels: "SALES CHANNELS",
            onlineStore: "Online Store",
            dashboard: "Dashboard",
            accounts: "Accounts",
            tickets: "Tickets",
            createWorkModel: "Create Work Model",
            runsheets: "RunSheets",
            workModels: "Work Model",
            wallet: "Wallet",
            products: "Products",
            reports: "Reports",
            ticketScan: "Ticket Scan",
            ticketScanSession: "Ticket Scan Session",
            settings: "Settings",
            logs: "Activity Logs",
            createNewAcc: "Create New",
            createBulk: "Create Bulk Accounts",
            allAccounts: "All Accounts",
            archived: "Archived",
            createNewTicket: "Create New",
            postpond: "Postponed",
            returned: "Returned & Reversed",
            replace: "Replacement",
            branches: "Branches",
            ranges: "Delivery Ranges",
            priceList: "Price List",
            createNewRunsheet: "Create New",
            createNewInternalRunsheet: "Create New",
            createNewWorkModel: "Create New",
            assinedRunsheet: "Assigned-RS",
            requestsToPay: "Requests to Pay",
            requestsToRecieve: "Requests to Recieve",
            confirmReceive: "Confirm the Receive",
            general: "General",
            integrationApps: "Integration Apps",
            sellers: "Merchants",
            ordersPackage: "Orders Package",
            moveOrders: "Move Orders",
            orderInfo: "Order Info",
            createProduct: "Add Product",
            allProducts: "All Products"
          },
          states: {
            "Pickup Request": "Pickup Request",
            "Pickup Confirmed": "Pickup Confirmed",
            "Courier Received": "Courier Received",
            "In Hub": "In Hub",
            "On Delivery": "On Delivery",
            Delivered: "Delivered",
            "Return Request": "Return Request",
            "Courier Returning": "Courier Returning",
            Returned: "Returned",
            Cancelled: "Cancelled"
          },
          endScanSession: "End Scanning Session",
          scanStatus: "Scan Status",
          ticketsScanned: "Tickets Scanned",
          searchCouriers: "Search Couriers",
          verify: "Verify",
          verificationCode: "Verification Code",
          sendVerificationCode: "Send Verification Code",
          verifyIdentity: "Verify your identity",
          id: "ID",
          settled: "Settled",
          with_shipment: "Includes Shipment",
          actionStage: "Action Stage",
          entitlements: "Entitlements",
          debts: "Debts For Merchant",
          yes: "Yes",
          no: "No",
          pickupAddress: "Pickup Address",
          courierId: "Courier ID",
          branchesSubtitle: "Customize Your Branches",
          noOrders: "You Don't Have Any Orders Yet",
          addNewBranch: "Add New Branch",
          dimentions: "Dimentions(for boxes)",
          addTicketsToRunsheet: "Add Tickets",
          choose_template_type:
                        "Choose template type accoarding to shipping type of the seller work model",
          model_type: "Model Type",
          runsheet_type: "State",
          linked_sellers: "Linked Sellers Addresses",
          appendSellerAddress: "Add New Seller Address",
          sellersAddresses: "Sellers Addresses",
          view_sellers: "View Addresses",
          add_new_work_model: "Add New Work Model",
          addDimention: "Add Dimention",
          includingFees: "Including Fees",
          notIncludingFees: "Doesn't Include Fees",
          price_id: "Price ID",
          city: "City",
          fixed: "Fixed",
          increase: "Increase",
          decrease: "Decrease",
          value: "Value",
          by_value: "By Value",
          by_percentage: "By Percentage",
          Area: "Area",
          over_weight_price: "Over Weight Price",
          dimention: "Dimention",
          shipping_price: "Shipping Price",
          return_price: "Return Price",
          replacement_price: "Replacement Price",
          delivery_time: "Delivery Time",
          return_time: "Return Time",
          search_city_id: "Search By City Or ID",
          ticket: "Ticket",
          date: "Date",
          customer: "Customer",
          managerSearch:
                        "Search with Branch Manger ID Or Branch Manger Name",
          sellerSearch: "Search with Seller ID Or Store Name",
          courierSearch: "Search with Courier ID Or Courier Name",
          archivedSearch: "Search with User ID Or User Name",
          operation: "Operation/Branch Manager",
          orgUsers: "Organization Employees",
          seller: "Merchant",
          courier: "Courier",
          accountant: "Accountant",
          inventoryManager: "Inventory Manager",
          operationTeamMember: "Operation Team Member",
          accType: "Account Type",
          personalInfo: "Personal Info",
          sendInvitation: "Send Invitation",
          userType: "User Type",
          phone: "Phone Number",
          no_alt_phone: "No alternative phone numbers",
          altPhone: "Alt Phone Number (optional)",
          fullname: "Full Name",
          email: "Email",
          proceed: "Proceed",
          discard: "Discard",
          confirm: "Confirm",
          previous: "Previous",
          coveredzones: "Covered Zones",
          typeofvehicles: "Type of Vehicle",
          storename: "Business/Store Name",
          area: "Areas",
          areas: "Areas",
          dimensionsSubTiltle:
                        "Configure your organization boxes dimensions",
          workmodelDeleted: "Work Model Deleted Successfully",
          sellerInfoTxt:
                        " We’ll use this address to send an invitation link for this seller",
          zones: "Cities",
          back: "Back",
          addAddress: "Add Another Address",
          reActive: "Reactive",
          admin: "Organization Admin ",
          ticketInfo: "Ticket Info",
          orderInfo: "Order Info",
          chooseSellerAddress: "Choose Seller pickup address",
          chooseSellerReturnAddress: "Choose Seller return address",
          sellername: "Seller Name",
          createNewCustomer: "Create New Customer",
          notSupportedBySeller: "Not supported by seller address",
          chooseCustomerAddress: "Choose Customer address",
          cancelTicket: "Cancel Ticket",
          createNewProduct: "Create New Product",
          edit_priorities: "Edit Priorities",
          width: "Width",
          height: "Height",
          length: "Length",
          dimensions: "Dimensions",
          weight_type: "Weight",
          dimensions_type: "Dimensions",
          price: "Product Price",
          selectProduct: "Select Product",
          selectLabel: "Select Label",
          addAnotherProduct: "Add Another Product",
          shippmentType: "Type of Shippment",
          paymentMethod: "Payment Method",
          deliveryRange: "Select Delivery Range",
          total: "Total",
          rsValue: "RS Value",
          lastUpdated: "Last Updated",
          shipment: "Shippment",
          totalProducts: "Total Products",
          calculate: "Calculate",
          cash: "Cash",
          online: "Online",
          partial: "Partial",
          complete: "Complete",
          scan: "Scan",
          orderID: "Order ID",
          save: "Save",
          req_all_fields: "Please fill all fields",
          addNewCustomer: "Add New Customer",
          addNewProduct: "Add New Product",
          name: "Name",
          description: "Description",
          createPostpondRequest: "Create Postponed Request",
          newDate: "New Date",
          reviewRequest: "Review Request",
          searchOrderID: "Search with order ID",
          next: "Next",
          postponedTxt1: "Please Enter Order ID",
          postponedTxt2: "Enter Order ID you want to return back",
          postponedDate: "Data of postpone",
          empty: "No Data",
          cities: "Cities",
          sendRequest: "Send Request",
          createReturnRequest: "Create Return Request",
          createReplaceRequest: "Create Replacement Request",
          ticketId: "Ticket ID",
          replaceTxt1: "Enter Order Id",
          replaceTxt2: "Please Enter Order Id",
          replaceTxt3:
                        "Enter Order ID of the tickets you want to replace",
          returnedTxt1: "Search with Order ID Ticket ID",
          returnedTxt2: "Please Enter Order Or Ticket ID",
          returnedTxt3:
                        "Enter Order ID or Ticket ID you want to return back",
          createNewRunsheet: "Create New Runsheets",
          reassignRunsheet: "Re-Assign Runsheet",
          server_error: "Something went wrong on our side.",
          runsheetInfo: "Runsheet info",
          createRs: "Create RS",
          chooseCourier: "Choose Courier",
          chooseBranch: "Choose Branch",
          chooseRunsheetZone: "Choose Runsheet Zone",
          runsheetorderSearch: "Search with order ID Or Store Name",
          branch: "Branch",
          runsheetZone: "Runsheet Zone",
          createRSsearch: "Search with",
          choose: "Choose",
          runsheetID: "Runsheet ID",
          courierBranch: "Courier Branch",
          couriername: "Courier Name",
          courierPhone: "Courier Phone",
          courierVehicle: "Courier Vehicle",
          runsheetConfirm: "Runsheet Confirmation",
          runsheetCreateMsg1:
                        "Are you sure that you want to send Runsheet to",
          reAssign: "Reassign",
          assigndRsSearch: "Search with Runsheet ID",
          archivedRsTxt1: "There are no runsheets yet.",
          archivedRsTxt2:
                        "May be you need to refresh this page to check new runsheets.",
          workmodelInfoTitle: "Information about your Work Models",
          workmodelInfoSub:
                        "You have to follow these steps in order to create work model(s).",
          select: "Select",
          workModelCreateTxt1: "Select a branch and",
          workModelCreateTxt2: "Create Zone(s)",
          workModelCreateTxt3: "coverd by specific branch",
          workModelCreateTxt4: "Select a zone and",
          workModelCreateTxt5: "Create Area(s)",
          workModelCreateTxt6: "coverd by specific zone",
          workModelCreateTxt7: "Last but not least  ",
          workModelCreateTxt8: "Create Price List",
          workModelCreateTxt9: "Finally Assign",
          workModelCreateTxt10: "Work Model",
          workModelCreateTxt11: "for each group of sellers",
          createNewBranch: "Add New Branch",
          branchName: "Branch Name",
          addBranch: "Add Branch",
          close: "Close",
          createNewZone: "Add New Zone",
          zoneName: "Zone Name",
          addZone: "Add Zone",
          addNewArea: "Add New Area",
          areaName: "Area Name",
          addArea: "Add Area",
          zonesCovered: " Zones Covered",
          areaList: " Areas List",
          createDeliveryRange: "Add New Range",
          rangeOverAll: "Delivery Ranges Overall",
          addRange: "Add Range",
          createWorkModel: "Add New Work Model",
          workModelName: "Work Model Name",
          listInput: "PRICE LIST INPUTS",
          custom: "Custom",
          sameZone: "Same Zone",
          sameBranch: "Same Branch",
          between2Branch: "Between Two Branches",
          shipmentFee: "Ship. Fee",
          returnedFee: "Rto. Fee",
          addAnotherBranch: "Add Another Custom two branches",
          submit: "Submit",
          err: {
            name: "Please input name!",
            range: "Please select the range!",
            price: "Please input the price!"
          },
          totalBalance: "Total Balance",
          searchBranch: "Search with Branch ID",
          apply: "Apply",
          branchID: "Branch ID",
          debit: "Debit",
          credit: "Credit",
          totalAmount: "Total Amount",
          more: "More",
          sellerID: "Seller ID",
          searchSeller: "Search with Seller ID",
          sellerWallet: "Sellers wallet",
          ticketID: "Ticket ID",
          ticketValue: "Ticket Value",
          orderValue: "Order Value",
          areasSubTiltle: "Configure Areas",
          addNewDimention: "Add New Box",
          payTo: "Pay To",
          recieveFrom: "Recieve From",
          sentRequest: "Sent Requests",
          invoiceAttached: "Invoice Attached",
          paymentProof: "Payment Proof",
          collected: "Collected",
          scanInfoTxt: [
            "Step 1: you should have a barcode scanner.",
            "Step 2: point your scanner at the barcode on tickets, to scan ticket per ticket, so there is no missing tickets.",
            "Step 3: Search about specific courier.",
            "Step 4: View Courier’s Runsheet and start scan session.",
            "Step 5: Scan & Count.",
            "Step 6: Get results and you may leave a note.",
            "Step 7: End Ticket Scan Session."
          ],
          infoScanTitle: "Information about Ticket Scan.",
          infoScanSub:
                        "Hub scan tickets to confirm the receipt of orders correctly from courier and then tickets change their status from courier receiving to in hub.",
          searchTicket: "Search with Ticket ID",
          recieveInHub: "Recieve In Hub",
          bulkAccountTitle: "Information about Bulk Accounts.",
          bulkAccountInfo:
                        "Hub scan tickets to confirm the receipt of orders correctly from courier and then tickets change their status from courier receiving to in hub.",
          bulkList1: "Step 1: you should have a barcode scanner.",
          bulkList2:
                        "Step 2:  point your scanner at the barcode on tickets, to scan ticket per ticket, so there is no missing tickets.",
          search: "Search",
          selectAction: "Select Action",
          viewResults: "View Results",
          startDate: "Start Date",
          endDate: "End Date",
          archivedDate: "Archived Date",
          archivedBy: "Archived By",
          logsInfo1:
                        " Choose Date Range and Select Action Type First.",
          logsInfo2:
                        "May be you need to refresh this page after select filters to check Activity log.",
          dateTime: "Date/Time",
          actionType: "",
          organization: "Organization",
          orgSubTitle: "Adjust your company profile information.",
          billing: "Billing",
          billingSubTitle: "Manage your billing information.",
          preferences: "Preferences",
          security: "Security",
          permissions: "Users Permissions",
          language: "Language",
          selectLang: "Select Language",
          currency: "Currency",
          selectCurrency: "Select Currency",
          lang: "Language",
          FAQs: "FAQs",
          preferencesSubTitle:
                        "Customize your dashboard and tracking.",
          securitySubTitle: "Manage your password and authentication",
          permissionsSubTiltle: "Manage Users permissions.",
          langSubTitle: "Adjust your system language.",
          FAQsSubTitle: "Frequently Asked Questions.",
          firstName: "First Name",
          lastName: "Last Name",
          companyName: "Company name",
          CompanyName_ar: "Company Name Arabic",
          changeEmail: "Change Email",
          prefCardTitle:
                        "The dashboard is dynamic, so you can customize a tracking way and table headers as your business needs.",
          prefCardSubTitle1: "To custom the new status : ",
          prefCardStep1:
                        "Step 1: from the main menu ,select settings and then choose preferences",
          prefCardStep2: "Step 2: then select the tracking board",
          prefCardStep3: "Step 3: Click on add new state button",
          prefCardStep4:
                        "finally fill in the add new state form and click onthe “Save” button.",
          prefCardSubTitle2: "To custom new table headers :",
          prefCardStep5:
                        "Step 1: from the main menu ,select settings and then choose preferences",
          prefCardStep6: "Step 2: then select the tables headers",
          prefCardStep7: "Step 3: Click on add new header button",
          prefCardStep8:
                        "Step 4: choose state title from drop-down menu",
          prefCardStep9:
                        "Step 5 : finally select the headers and click on apply",
          trackingBoard: "Tracking Board",
          trackingBoardDesc: "Custom your suitable tracking way.",
          tableHeaders: "Table Headers",
          tableHeadersDesc:
                        "View the most important info for each step.",
          addNewState: "Add New State",
          trackingTable: {
            number: "No.",
            title: "Title",
            titleAr: "Arabic Title",
            description: "Description",
            primColor: "Primary Color",
            secColor: "Secondary Color",
            prevState: "Previous State",
            nextState: "Next State",
            possible: "Possible States "
          },
          systemTheme: "System Theme",
          systemThemeSubtitle: "Custom your Logo and main colors.",
          headersTable: {
            number: "No.",
            title: "Title",
            actions: "Actions",
            tableHeader: "Table Headers"
          },
          stateTableHeaders: "State Table Headers",
          addNewTable: "Add New Table",
          refresh: "Refresh",
          edit: "Edit",
          changePassword2: "Change Password",
          delete: "Delete",
          stateTitle: "State Title",
          password: "Password",
          updatePassword: "Update Password",
          changePassword: "Change the password you use to login.",
          changeCourierPassword: "Change Courier's Password",
          deleteOrg: "Delete Organization",
          chooseOrders: "Choose Orders",
          deleteOrgTitle:
                        "We will remove your organization permanently. This action can't be reversed.",
          passwordUpdated: "Password updated successfully",
          newPassword: "New Password",
          oldPassword: "Old Password",
          updatePasswordError:
                        "The two passwords that you entered do not match!",
          confirmPassword: "Please confirm your password!",
          confirmPasswordLabel: "Confirm Password",
          inputOldPassword: "Please input your old password!",
          inputNewPassword: "Please input your new password!",
          chooseAccount: "Choose User Account",
          permissionsList: "Permissions List",
          managerName: "Branch Manager Name",
          editBranch: "Edit Branch",
          editZone: "Edit Zone",
          editArea: "Edit Area",
          editRange: "Edit Range",
          welcome: "Welcome Back!",
          welcomeSubtitle: "Everything is under control...",
          inputEmailErr: "Please input your Email!",
          inputPasswordErr: "Please input your Password!",
          forgotPassword: "Forgot Password?",
          login: "Log in",
          tip: "We’ll use this description to be as a tip for other members use this system.",
          packageUsage: "Package Usage",
          plan: "Plan",
          orderPackage: "Orders Package",
          outOf: "out of",
          needHelp: "Need Help?",
          contactSupport1: "Contact Support",
          contactSupport2:
                        "Submit a request and we will get back to you as soon as possible.",
          helpCenter1: "Help Center",
          helpCenter2: "Make use of our documents to get help.",
          shipmentType: "Shipment Type",
          orders: "Orders",
          register: "Register",
          signUp: "Sign Up",
          orgConfigure: "Configuring your organization...",
          orgAlert:
                        "Kindly do not close this page and give up to 5 minutes",
          selectUser: "Select User",
          changeStatus: "Change Status",
          accountCreateTxt1:
                        "Are you sure you want to create account? ",
          // accountCreateTxt2:"account",
          accountConfirmation: "Create Account Confirmation",
          saveChanges: "Save Changes",
          requiredField: "This Field is required",
          overview: "Overview",
          progressChart: "Progress Chart",
          clickHere: "Click Here",
          changeStatusConfirmation: "Change Status Confirmation",
          archiveConfirmation: "Archive Confirmation",
          logout: "Logout",
          assign: "Assign",
          egp: "EGP",
          EGP: "EGP",
          receiverInfo: "Receiver info",
          productDetails: "Product Details",
          update: "Update",
          ticketScan: "Ticket Scan",
          ticketScanSession: "Ticket Scan Session",
          scanning: "Scanning...",
          ticketNotFound: "Ticket Not Found",
          exportToPDF: "Export To PDF",
          noStates: "There's no Possible states yet",
          forceConfirm: "Force Accept",
          accountcreation: "Account Creation",
          forceAcceptText:
                        "Are you sure you want to force accept the selected runsheets?",
          cancelOrder: "Cancel Order",
          unassign: "Unassign",
          unassignRunsheet: "Unassign Runsheet",
          archiveRunsheet: "Archive Runsheet",
          forceAcceptedSuccess: "Runsheets accepted successfully",
          activated: "is Activated",
          deactivated: "is Deactivated",
          refreshText:
                        "May be you need to refresh this page to check new requests.",
          refreshTitle: "There are no requests yet for",
          deliveryRangeErr: "Delivery Range needed",
          addressErr: "Branch , zone and area are required",
          branchErr: "Branch is required",
          phoneErr: "Phone number isn't valid",
          totalOrderValues: "Total Order Values",
          branches: {
            from: "From Branch",
            to: "To Branch"
          },
          totalAmountToSettlement: "Total Amout to Settlement",
          addManualAttachment: "Add Manual Attachment",
          attachmentText: "Click or drag file to this area to upload",
          settleTicket: "Settle Order",
          settleTicketBody: "Request sent to seller successfully",
          courierReturnAmount: "Courier Return Amount",
          moneyRecievedText: "Money received successfully",
          postponeConfirmation: "Postpone Confirmation",
          postponeConfirmText:
                        "Are you sure that you want to postpone these orders?",
          postponeSuccessText: "Orders has been postponed",
          returnConfirmation: "Ticket Returned Confirmation",
          replacementConfirmation: "Ticket Replacement Confirmation",
          returnConfirmText: "Return request created successfully",
          replacementConfirmText:
                        "Replacement request created successfully",
          paidRequests: "Paid Requests",
          collectInvoice: "Collect Invoice",
          collectInvoiceSuccess: "Invoice Collected Successfully",
          settle: "Settlement",
          printTicket: "Print Ticket",
          deleteOrder: "Delete Order Confirmation",
          pay: "Pay",
          image: "Payment Proof",
          noProof: "No proof image available",
          reject: "Reject",
          paymentID: "Payment ID",
          status: "Status",
          successfulRequest: "Request done successfully",
          createWorkModelText:
                        "New Work Model has been created successfully",
          deleteProcess: "Delete Process",
          deleteText: "Are you sure you want to Delete?",
          enterEmail: "Please Enter Your Email.",
          courier_id: "Courier ID",
          done: "Done",
          emailedYou: "We just emailed you.",
          enterCode: "Please enter the code we emailed you.",
          resendCode: "Resend Code",
          confirmationCode: "Confirmation Code",
          enterNewPassword: "Enter Your New Password",
          resetPassword: "Please reset your password",
          passwordReset: "Password Reset Successfully",
          addressFields: "All address fields are required",
          payments: "Extend Your Package",
          ordersNumber: "Orders Number",
          amountpayed: "Amount to be payed",
          paymentGate: "Payment Gateway",
          packageExpired: "Package Expired",
          packageExpiredText: "Your 100 free shipment has ended! \n But it's not too late to take the next step. Upgrade to a paid plan to keep using TreeCode shipment system.",
          paymentState: "Payment State",
          paymentSuccess: "Payment Done Successfully",
          paymentFailed:
                        "Something wrong happened , please try again later \n Reason for failure :",
          retry: "Retry",
          parcel: "Parcel Order",
          bulk: "Bulk Orders",
          bulkSuccessTitle: "Bulk Upload",
          infoBulkTitle: "Information about Bulk Order.",
          infoBulkSub:
                        "You will be able to upload and preview errors here then you can check orders before any new objects are created.",
          infoBulkStep1: "Download Excel file template",
          infoBulkStep2: "Add Orders",
          infoBulkStep3: "Upload you file here",
          bulkHyperLink: "Download here",
          uploadFileText: "Upload filled-in template",
          uploadFileSub: "Drag and drop, or click to select",
          row: "Row",
          issues: "Issues",
          moveOrdersTitle: "Move Orders",
          moveOrdersSuccess: "Orders were moved successfully",
          moveOrdersText:
                        "Are you sure you want to move the selected orders?",
          deliveryRangesAvailable: "Delivery Ranges Available",
          shipFee: "Price List(ShipFee)",
          RTO: "Price List(RTO)",
          uploadLogo: "Upload Your Logo",
          uploadLogoText: "Add logo image or drop image to upload",
          selectColors: "Select Colors",
          primaryColor: "Primary Color",
          secondaryColor: "Secondary Color",
          invalidImage: "Please select valid image.",
          deleteMain: "Can't delete main workmodel",
          date_picker: {
            start_date: "Start Date",
            end_date: "End Date",
            today: "Today",
            week: "This Week",
            month: "This Month",
            year: "This Year"
          },
          reports: {
            report_type_select: "Select your Report Type",
            branch_reports: "Branch Reports",
            branch_list: {
              branch: "Branch Performance",
              courier: "Courier Branches",
              financial: "Financial Reports"
            },
            store_reports: "Store Reports",
            store_list: {
              seller: "Seller Performance",
              financial: "Financial Reports"
            },
            branch_name: "Branch Name",
            workModel: "Work Model",
            branch_name_ph: "Select Branch",
            branch_name_req: "Branch is Required",
            report_type: "Report Type",
            report_type_ph: "Select Report Type",
            report_type_req: "Report Type is Required",
            courier_name: "Courier Name/Number",
            courier_name_ph: "Search Couriers By Name or Id",
            courier_name_req: "Courier is Required",
            store_name: "Store",
            store_name_ph: "Select Store",
            store_name_req: "Store is Required",
            report_name: "Report Name",
            report_name_ph: "Report Name",
            generate: "Generate Report",
            clear: "Clear Filters",
            download: "Download Report",
            cards: {
              total_orders: "Total Orders",
              received_orders: "Received Orders",
              archived_orders: "Archived Orders",
              cash_orders: "Cash Orders",
              total_cash: "Total Cash Orders",
              online_orders: "Online Orders",
              total_online: "Total Online Orders",
              total_tickets: "Total Tickets",
              on_delivery_tickets: "On Delivery Tickets",
              deliverd_tickets: "Delivered Tickets",
              delivered_tickets: "Delivered Tickets",
              in_hub_tickets: "In Hub Tickets",
              returned_tickets: "Returned Tickets",
              debit: "Overall Shipment Fees",
              credit: "Order Fees",
              collected: "Collected",
              shipment_collected: "Collected Shipments",
              shipment_fees: "Shipment Fees",
              not_collected: "Not Collected"
            },
            table: {
              regular: {
                ticket_id: "Ticket Id",
                order_id: "Order Id",
                seller_name: "Merchant Name",
                store_name: "Store",
                payment_method: "Payment Method",
                shipment_status: "Shipment Status",
                shipment_fees: "Shipment Fees",
                customer_name: "Customer Name",
                create_date: "Creation Date"
              }
            }
          },
          Comments: "Comments",
          Comments_description: "Courier's Comments List",
          "Add Comment": "Add Comment",
          "Edit Comment": "Edit Comment",
          Text: "Text",
          "Text (Arabic)": "Text (Arabic)",
          "Is Pickup": "Is Pickup",
          "Is Other": "Is Other",
          "Create Date": "Create Date",
          Edit: "Edit",
          Cancel: "Cancel",
          Submit: "Submit",
          "Please enter text": "Please enter text",
          "Please enter Arabic text": "Please enter Arabic text",
          Yes: "Yes",
          No: "No",
          "Comment created successfully": "Comment created successfully",
          "Comment updated successfully": "Comment updated successfully",
          "Comment deleted successfully": "Comment deleted successfully",
          "Failed to save comment. Please try again.": "Failed to save comment. Please try again.",
          "Failed to delete comment. Please try again.": "Failed to delete comment. Please try again."
        }

      },
      ar: {
        translation: {
          location: " الموقع",
          addProduct: {
            title: "إضافة منتج",
            photoLabel: "صورة المنتج",
            photoRequired: "يرجى تحميل صورة واحدة على الأقل للمنتج!",
            photo: "صورة {{index}}",
            upload: "تحميل",
            productNameLabel: "اسم المنتج",
            productNameRequired: "يرجى إدخال اسم المنتج!",
            productNamePlaceholder: "أدخل اسم المنتج",
            skuLabel: "SKU",
            skuRequired: "يرجى إدخال SKU!",
            skuPlaceholder: "أدخل SKU",
            barcodeLabel: "الباركود",
            barcodeRequired: "يرجى إدخال الباركود!",
            barcodePlaceholder: "أدخل الباركود",
            categoryLabel: "الفئة",
            categoryRequired: "يرجى اختيار فئة!",
            categoryPlaceholder: "اختر فئة",
            merchantLabel: "التاجر",
            merchantRequired: "يرجى اختيار تاجر!",
            merchantPlaceholder: "اختر تاجرًا",
            priceLabel: "السعر",
            priceRequired: "يرجى إدخال سعر المنتج!",
            priceInvalid: "يرجى إدخال سعر صالح (مثال: 99.99)!",
            pricePlaceholder: "أدخل السعر",
            descriptionLabel: "الوصف",
            descriptionPlaceholder: "أدخل الوصف",
            instructionsLabel: "التعليمات",
            instructionsRequired: "يرجى إدخال التعليمات!",
            instructionsPlaceholder: "أدخل تعليمات استخدام المنتج",
            cancel: "إلغاء",
            next: "التالي",
            messages: {
              loadDataFailed: "فشل في تحميل البيانات.",
              invalidFile: "{{fileName}} ليس نوعًا مدعومًا من الصور أو يتجاوز الحد الأقصى للحجم 5 ميجابايت.",
              productCreated: "تم إنشاء المنتج بنجاح!",
              createProductFailed: "فشل في إنشاء المنتج."
            }
          },
          products: "المنتجات",
          searchPlaceholder: "ابحث باستخدام معرف Runsheet",
          filter: "تصفية",
          productName: "اسم المنتج",
          productCategory: "فئة المنتج",
          productCode: "كود المنتج",
          actions: "الإجراءات",
          createNewOrder: "إنشاء طلب جديد",
          inventoryOrderInfo: "معلومات طلب المخزون",
          orderTypeRequired: "نوع الطلب مطلوب",
          return: "إرجاع",
          receive: "استلام",
          transfer: "نقل",
          selectLocation: "اختر الموقع",
          locationRequired: "الموقع مطلوب",
          orderItems: "عناصر الطلب",
          item: "عنصر",
          selectItem: "اختر العنصر",
          quantity: "الكمية",
          quantityRequired: "الكمية مطلوبة",
          inventoryLocation: "موقع المخزون",
          selectInventoryLocations: "اختر مواقع المخزون",
          inventoryLocationRequired: "موقع المخزون مطلوب",
          addItem: "إضافة عنصر",
          sellerInfo: "معلومات البائع",
          sellerName: "اسم البائع",
          selectSeller: "اختر البائع",
          sellerNameRequired: "اسم البائع مطلوب",
          loading: "جاري التحميل...",
          noData: "لا توجد بيانات",
          loadMore: "تحميل المزيد",
          cancel: "إلغاء",
          createOrder: "إنشاء الطلب",
          address: "العنوان",
          addressRequired: "العنوان مطلوب",
          businessName: "اسم الشركة",
          businessNameRequired: "اسم الشركة مطلوب",
          businessNamePlaceholder: "أدخل اسم الشركة",
          phoneNumber: "رقم الهاتف",
          phoneNumberRequired: "رقم الهاتف مطلوب",
          phoneNumberPlaceholder: "أدخل رقم الهاتف",
          exceedsAvailableQuantity: "يتجاوز الكمية المتوفرة",
          errorCreatingOrder: "خطأ في إنشاء الطلب",
          orderCreatedSuccessfully: "تم إنشاء الطلب بنجاح",
          "Error while fetching branches": "خطأ أثناء جلب الفروع",
          "Warehouse Name": "اسم المستودع",
          requiredField: "هذا الحقل مطلوب",
          "Warehouse Code": "كود المستودع",
          Address: "العنوان",
          Branches: "الفروع",
          "Select Branches": "اختر الفروع",
          Locations: "المواقع",
          "Location Name": "اسم الموقع",
          "Has Shelves": "يحتوي على أرفف",
          "Parent Location": "الموقع الرئيسي",
          "Select Parent": "اختر الموقع الرئيسي",
          Remove: "إزالة",
          "Add Location": "إضافة موقع",
          Cancel: "إلغاء",
          Next: "التالي",
          "Update Warehouse": "تحديث المستودع",
          "Warehouse has been created successfully": "تم إنشاء المستودع بنجاح",
          "Warehouse has been updated successfully": "تم تحديث المستودع بنجاح",
          "An error occurred": "حدث خطأ",
          "An error occurred during submission": "حدث خطأ أثناء الإرسال",
          AvailableShelves: "الأرفف المتاحة",
          availabe: "متاح",
          used: "مستخدم",
          create: "اضافة",
          edit: "تعديل",
          DeleteCategory: "حذف الفئة",
          stock_overview: "نظرة عامة علي  المخزون",
          returnType: "نوع المرتجع",
          Select_Return_Type: "نوع طلب المرتجع",
          facebook: "فيسبوك",
          instagram: "انستجرام",
          twitter: "تويتر",
          AllOrders: "كل الطلبات",
          Approved: "مقبولة",
          Rejected: "مرفوض",
          Completed: "مكتملة",
          Groups: "المجموعات",
          Filter: "تصفية",
          ExportAll: "تصدير جميع",
          Duplicate: "تكرار",
          Delete: "حذف",
          MarkasCompleted: "تحديد كمكتمل",
          MarkasRejected: "تحديد كمرفوض",
          SearchWaybillNumber: "بحث عن بوليصة الشحن",
          DashboardFilters: "تصفيات لوحة التحكم",
          WaybillNumber: "رقم بوليصة الشحن",
          InventoryOrders: "طلبات المخزون",
          confirmation: "تأكيد",
          archivedOrders_sendRequest: "متأكد من أنك تريد أكمال هذا الطلب؟",
          archivedOrders: "الطلبات المؤرشفة",
          orderDeleted: "تم حذف الطلب",
          orderDeletionError: "خطأ في حذف الطلب",
          confirmationTitle: "تأكيد",
          confirmationText: "هل أنت متأكد أنك تريد إرسال هذا الطلب؟",
          details: "تفاصيل",
          deleteArchive: "حذف الأرشيف",
          weight: "الوزن",
          specialLabel: "تسمية خاصة",
          orderId: "رقم الطلب",
          createDate: "تاريخ الإنشاء",
          customerInfo: "معلومات العميل",
          store: "المتجر",
          sellerAddress: "عنوان البائع",
          orderDetails: "تفاصيل الطلب",
          completeArchive: "إكمال الطلب",
          productInfo: "معلومات المنتج",
          archive_order: "أرشفة الطلب",
          archiving_msg: " هل تريد أرشفة الطلب لاستكماله في وقت لاحق ؟",
          specialLabel_is_required: "يجب عليك تحديد بيانات الخاصية",
          SpecialCare: "عناية خاصة",
          Fragile: "قابل للكسر",
          Allow_Open_Package: "السماح بفتح الطرد",
          cod_disclaimer: " يستخدم حقل `الدفع عند الاستلام` هذا اذا كنت ترغب في تحصيل مبلغ نقدي اكبر من المحسوب من العميل",
          Pos: "Pos",
          CashOnDelivery: "الدفع عند الاستلام",
          notes: "ملاحظات",
          SAR: "ريال ",
          integrationApps: "تطبيقات التكامل",
          all: "الكل",
          "3PL": "الشركات اللوجستية",
          ecommerce: "التجارة الإلكترونية",
          sms: "الرسائل النصية",
          paymentGateways: "بوابات الدفع",
          maps: "الخرائط",
          activate: "تفعيل",
          deactivate: "إلغاء التفعيل",
          FromInventoryStock: "من المخزون المتاح",
          percentage: "نسبة",
          thirdParty: "مستخدموا الربط الخارجي",
          free: "مجانا",
          same: "مثل السابق",
          pricing: "التسعير",
          cod_fees: "رسوم الدفع عند الاستلام",
          return_fees: "رسوم الاسترجاع",
          reverse_shipment_fees: "رسوم عكس الشحن",
          extra_shipment_weight: "وزن الشحنة الزائدة",
          discount: "الخصومات",
          discount_percentage: "نسبة الخصم",
          target_orders_volume: "حجم الطلبات المستهدف",
          active: "مفعل",
          from_date: "بداية من تاريخ",
          tier: "مستوى",
          same_day: "نفس اليوم",
          next_day: "اليوم التالي",
          first_100: "اول 100",
          up_to_500: "حتى 500",
          above_500: "اكثر من 500",
          assign: "اعادة تعيين",
          selectTickets: "اختر البوليصات",
          forcePrice: "السعر الجديد",
          oldShippingFees: "مصاريف شحن الطلب الاصلي",
          leaveComment: "اكتب تعليق",
          cancelComment: "الغاء التعليق",
          confirmPayment: "اكد الاستلام",
          paymentMethodConfirm: "تم تأكيد الاستلام",
          itemQuantity: "الكمية",
          sellerId: "رقم التاجر",
          customerId: "رقم العميل",
          customerName: "اسم العميل",
          customerPhone: "رقم العميل",
          customerAddress: "عنوان العميل",
          courierName: "اسم المندوب",
          feedbackComment: "تعليق",
          shipmentFees: "مصاريف الشحن",
          editTiersAreas: "تعديل مناطق المستوى",
          createWarehouse: "إنشاء مخزن",
          editWarehouse: "تعديل المخزن",
          addLocation: "اضافة موقع",
          newWarehouse: "مخزن جديد",
          editWarehouseLocation: "تعديل موقع المخزن",
          addInventoryRule: "اضافة قاعدة المخزون",
          editWarehouseLocationRule: "تعديل قاعدة موقع المخزن",
          createWarehouselocationRule: "إنشاء قاعدة موقع المخزن",
          newWarehouseLocationRule: "قاعدة موقع المخزن الجديدة",
          createWarehouselocation: "إنشاء موقع المخزن",
          newWarehouseLocation: "موقع المخزن الجديد",
          createProductCategory: "إنشاء فئة المنتج",
          "Template Name": "اسم القالب",
          "Template Description": "وصف القالب",
          "Template Code": "كود القالب",
          "Recipient Type": "نوع المستلم",
          Actions: "الاجراءات",
          "Edit Sms Template": "تعديل قالب الرسالة النصية",
          Name: "الاسم",
          Description: "الوصف",
          "Message Content (English)": "محتوى الرسالة (انجليزي)",
          "Message Content (Arabic)": "محتوى الرسالة (عربي)",
          Keywords: "الكلمات المفتاحية",
          "Update Template": "تحديث القالب",
          Return: "مرتجع",
          Reverse: "عكس الشحن",
          "Courier Name": "اسم المندوب",
          "Courier Phone": "رقم المندوب",
          "Courier Balance": "رصيد المندوب",
          "Settle Balance": "تسوية الرصيد",
          "AWB Label Type": "نوع البوليصة",
          "Configure AWB Label Type": "تكوين نوع البوليصة",
          Active: "مفعل",
          "Courier Wallet": "محافظ المندوب",
          Inventory: "المخزون",
          "Order Types": "انواع الطلبات",
          "Manage Order Types And Their Prices": "إدارة انواع الطلبات وأسعارها",
          "Order AWB": "بوليصة الطلب",
          "Ticket AWB": "بوليصة التذكرة",
          "Change AWB Type": "تغيير نوع البوليصة",
          "AWB Type": "نوع البوليصة",
          "Order Type Name": "اسم نوع الطلب",
          "Delivery Within": "التسليم خلال",
          "Pricing Type": "نوع التسعير",
          "Extra Price": "سعر إضافي",
          "Reduced Price": "سعر مخفض",
          "Default Type?": "هل هذا النوع الافتراضي؟",
          "Create Order Type": "إنشاء نوع طلب",
          "Edit Order Type": "تعديل نوع الطلب",
          "Within Unit": "وحدة التسليم",
          "Within Period": "فترة التسليم",
          "Restricted Areas": "المناطق المقصورة",
          "Restricted Time Start": "بداية الفترة المقصورة",
          "Restricted Time End": "نهاية الفترة المقصورة",
          "Is Default": "هل هذا النوع الافتراضي؟",
          "Seller Can Pick": "يمكن للبائع أن يختار هذا النوع",
          "Workflow States": "حالات العملية",
          "Selected States Order": "الحالات المختارة للطلب",
          "Available States": "الحالات المتاحة",
          "Enter name": "ادخل الاسم",
          "Enter description": "ادخل الوصف",
          "Enter within period": "ادخل فترة التسليم",
          "Enter reduced price": "ادخل السعر المخفض",
          "Enter extra price": "ادخل السعر الإضافي",
          "Select areas to restrict": "اختر المناطق المقصورة",
          "Select time": "اختر الوقت",
          "Drop state here": "قم بإسقاط الحالة",
          "OTP Delivery": "التسليم بالمصادقة الثنائية",
          "Activate Third Party": "تفعيل الربط الخارجي",
          "Order Type": "نوع الطلب",
          pos_fees: "رسوم الدفع عند الاستلام (الة دفع)",
          approved: "مقبول؟",
          "Enter address to search": "ادخل العنوان للبحث",
          Search: "بحث",

          "Select AWB Type": "اختر نوع البوليصة",
          storeName: "اسم المتجر",
          "Select Order Type": "اختر نوع الطلب",
          sideMenu: {
            orderTypes: "انواع الطلبات",

            allOrderTypes: "جميع انواع الطلبات",

            orderTypeDetails: "تفاصيل نوع الطلب",
            wallets: "المحافظ",
            allCourierWallets: "جميع محافظ المندوبين",
            courierWallets: "محافظ المندوبين",
            adminDashboard: "لوحة قيادة الادارة",
            inventoryDashboard: "لوحة قيادة المخزون",

            allProductCategories: "جميع فئات المنتجات",
            productCategories: "فئات المنتجات",
            allWarehouses: "جميع المخازن",
            locationRules: "قواعد الموقع",
            locations: "المواقع",
            warehouseLocations: "مواقع المخازن",
            warehouses: "المخازن",
            warehouseLocationsRules: "قواعد مواقع المخازن",
            locationsRules: "قواعد المواقع",
            webhooks: "الويب هوك",
            smsTemplates: "قوالب الرسائل النصية",
            allSmsTemplates: "جميع قوالب الرسائل النصية",
            apiKeys: "مفاتيح الوصول",
            all_tiers: "جميع المستويات",
            tiers: "المستويات",
            tier: "مستوى",
            createInternal: "انشاء خط سير داخلي",
            allInternals: "جميع خطوط السير الداخلية",
            internalRunSheet: "خطوط السير الداخلية",
            all_work_models: "جميع نماذج العمل",
            view_work_model: "عرض نموذج العمل",
            work_models: "نماذج العمل",
            price_list: "قائمة الاسعار",
            salesChannels: "قنوات البيع",
            onlineStore: "متجر إلكترونى",
            dashboard: "لوحة القيادة",
            accounts: "الحسابات",
            tickets: "بوليصات",
            runsheets: "خط سير",
            workModels: "نموذج العمل",
            wallet: "المحفظة",
            reports: "التقارير",
            products: "المنتجات",
            ticketScan: "فحص البوليصة",
            ticketScanSession: "جلسة فحص البوليصة",
            settings: "الإعدادات",
            logs: "سجل النشاطات",
            createNewAcc: "إنشاء حساب جديد",
            createBulk: "انشاء حسابات",
            allAccounts: "جميع الحسابات",
            archived: "الأرشيف",
            createNewTicket: "إنشاء بوليصة جديدة",
            createWorkModel: "انشاء نموذج عمل",
            postpond: "مؤجلة",
            returned: "مرتجع",
            replace: "تبديل",
            branches: "الفروع",
            ranges: "متوسط مدة الشحن",
            priceList: "قائمة الأسعار",
            createNewRunsheet: "إنشاء خط سير جديد",
            createNewInternalRunsheet: "إنشاء خط سير داخلي جديد",
            createNewWorkModel: "انشاء نموذج عمل جديد",
            assinedRunsheet: "خطوط سير موكلة",
            requestsToPay: "طلبات الدفع ",
            requestsToRecieve: "طلبات الإستلام",
            confirmReceive: "تأكيد الاستلام",

            general: "عامة",
            integrationApps: "التطبيقات المدمجة",
            sellers: "البائعين",
            ordersPackage: "باقة الطلبات",
            moveOrders: "تحريك الطلبات",
            orderInfo: "تفاصيل الطلب",
            createProduct: "اضافة منتج",
            allProducts: "جميع المنتجات"
          },
          states: {
            "Pickup Request": "طلب استلام",
            "Pickup Confirmed": "استلام من المتجر",
            "Courier Received": "تسليم الى المخزن",
            "In Hub": "استلام من المخزن",
            "On Delivery": "توصيل الى العميل",
            Delivered: "تم التسليم",
            "Return Request": "مرتجعات",
            "Courier Returning": "تسليم الى التاجر",
            Returned: "تم تسليم التاجر",
            Cancelled: "ملغي"
          },
          endScanSession: "انهاء عملية المسح",
          scanStatus: "حالة المسح",
          ticketsScanned: "البوليصات الممسوحة",
          searchCouriers: "ابحث عن المندوب",
          verify: "تأكد",
          verificationCode: "كود التعريف",
          sendVerificationCode: "ارسال كود التعريف",
          verifyIdentity: "اكد هويتك",

          id: "الرقم",
          settled: "مسواه",
          with_shipment: "تشمل الشحن",
          actionStage: "مرحلة العمل",
          entitlements: "الاستحقاقات",
          debts: "الديون المستحقة للتاجر",
          yes: "اجل",
          no: "لا",
          pickupAddress: "عنوان الاستلام",
          courierId: "رقم المندوب",
          branchesSubtitle: "تخصيص الفروع",
          noOrders: "لا يوجد لديك اي طلبات للشحن بعد",
          addNewBranch: "اضافة فرع جديد",
          dimentions: "الابعاد(للصناديق)",
          choose_template_type:
                        "من فضلك اختر نوع النموذج بناءا على نوع خدمة التوصيل المستخدمة في نموذج العمل الخاص بالبائع",
          model_type: "نوع النموذج",
          linked_sellers: "عناوين التجار المرتبطون",
          appendSellerAddress: "اضافة عنوان تاجر",
          sellersAddresses: "عناوين التجار",
          view_sellers: "عرض العناوين",
          add_new_work_model: "اضافة نموذج عمل جديد",
          addDimention: "اضافة حجم صندوق",
          price_id: "ID السعر",
          city: "المدينة",
          Area: "الحي",
          over_weight_price: "سعر الوزن الزائد",
          dimention: "الابعاد",
          shipping_price: "سعر التوصيل",
          return_price: "سعر الاسترجاع",
          changeCourierPassword: "غير كلمة سر المندوب",
          status: "الحالة",
          replacement_price: "سعر التبديل",
          delivery_time: "مدة التوصيل",
          return_time: "مدة الاسترجاع",
          search_city_id: "بحث باسم المدينة او الID",
          operation: "مدير العمليات",
          ticket: "بوليصة",
          date: "تاريخ",
          customer: "عميل",
          seller: "البائع",
          courier: "مندوب الشحن",
          archivedDate: "تاريخ الارشفة",
          archivedBy: "تم الارشفة بواسطة",
          accountant: "محاسب",
          inventoryManager: "مدير المخزن",
          operationTeamMember: "عضو فريق التشغيل",
          orgUsers: "مستخدمين المنظمة",
          accType: "نوع الحساب",
          personalInfo: "معلومات شخصية",
          userType: "نوع المستخدم",
          proceed: "متابعة",
          chooseOrders: "اختر الطلبات",
          branches: {
            from: "فرع التسليم",
            to: "فرع الاستلام"
          },
          discard: "إلغاء",
          previous: "السابقة",
          phone: "رقم الهاتف",
          courier_id: "رقم المندوب",
          no_alt_phone: "لا يوجد رقم بديل",
          altPhone: "رقم تليفون بديل (اختياري)",
          cities: "المدن",
          fullname: "الاسم",
          email: "البريد الالكتروني",
          confirm: "تأكيد",
          coveredzones: "المناطق المغطاة",
          typeofvehicles: "نوع السيارة",
          storename: "إسم المتجر",
          area: "الحي",
          areas: "احياء",
          workmodelDeleted: "تم حذف نموذج العمل بنجاح",
          sellerInfoTxt:
                        "سوف نقوم بإستخدام هذا البريد الالكتروني لإرسال رابط الدعوه للتاجر",
          zones: "المدن",
          req_all_fields: "برجاء التأكد من ادخال جميع البيانات",
          back: "العودة",
          addAddress: "إضافه عنوان اخر",
          managerSearch:
                        "ابحث  بالرقم التعريفى ل مدير الفرع أو الاسم",
          rsValue: "قيمة خط السير",
          lastUpdated: "اخر تعديل",
          sellerSearch:
                        "ابحث  بالرقم التعريفى ل التاجر أو إسم المتجر",
          courierSearch: "ابحث  بالرقم التعريفى ل المندوب  أو الاسم",
          reActive: "إعادة تفعيل",
          archivedSearch: "ابحث  بالرقم التعريفى للمستخدم او إسمه",
          admin: "مدير المنظمة",
          ticketInfo: "معلومات البوليصة",
          orderInfo: "معلومات الطلب",
          chooseSellerAddress: "اختر عنوان التاجر",
          chooseSellerReturnAddress: "اختر عنوان الاسترجاع",
          sellername: "إسم التاجر",
          createNewCustomer: "انشاء عميل جديد",
          chooseCustomerAddress: "اختر عنوان العميل",
          cancelTicket: "الغاء  المنتج",
          createNewProduct: "اضافه منتج اخر",
          width: "العرض",
          length: "العمق",
          height: "الطول",
          dimensions: "الابعاد",
          weight_type: "وزن",
          dimensions_type: "ابعاد",
          price: "سعر المنتج",
          selectProduct: "اختر المنتج",
          selectLabel: "إشارة مميزة ",
          addAnotherProduct: "اضف منتج اخر",
          shippmentType: "نوع الشحنه",
          paymentMethod: "طريقه الدفع",
          deliveryRange: " متوسط ايام التوصيل",
          total: "المجموع",
          shipment: "إجمالي سعر الشحن",
          totalProducts: "أجمالي سعر المنتجات",
          calculate: "حساب",
          cash: "نقدي",
          online: "عبر الانترنت",
          partial: "جزئي",
          complete: "مكتمل",
          scan: "فحص",
          orderID: "رقم الطلب",
          save: "حفظ",
          includingFees: "بالمصاريف",
          notIncludingFees: "بدون المصاريف",
          addNewCustomer: "ادخال عميل جديد",
          addNewProduct: "انشاء منتج جديد",
          name: "الاسم",
          description: "الوصف",
          createPostpondRequest: "انشاء طلب تأجيل",
          newDate: "تاريخ ",
          reviewRequest: "مراجعه الطلب",
          searchOrderID: "ابحث بالرقم التعريفى للطلب",
          next: "التالى",
          postponedTxt1: "ادخل الرقم التعريفى للطلب",
          postponedTxt2: "ادخل الرقم التعريفى للطلب المراد تأجيله",
          postponedDate: "تاريخ التأجيل",
          sendRequest: "ارسال الطلب",
          createReturnRequest: "انشاء طلب استرجاع",
          createReplaceRequest: "انشاء طلب استبدال",
          ticketId: "رقم البوليصة",

          replaceTxt1: "ادخل رقم الطلب",
          replaceTxt2: "ادخل رقم الطلب",
          replaceTxt3:
                        "ادخل الرقم التعريفي للطلب الذي تريد استبدال البوليصات منه",
          returnedTxt1:
                        "قم بالبحث باستخدام رقم الطلب التعريفى او رقم البوليصه التعريفي",
          returnedTxt2: "ادخل الرقم التعريفي للطلب او البوليصه",
          returnedTxt3:
                        "ادخل الرقم التعريفي للطلب او البوليصة المراد ارجاعها",
          createNewRunsheet: "انشاء خطوط سير جديدة",
          runsheetInfo: "معلومات خط السير",
          createRs: "انشاء خط سير",
          chooseCourier: "اختر المندوب",
          chooseBranch: "اختر الفرع",
          chooseRunsheetZone: "اختر النطاق",
          runsheetorderSearch:
                        "ابحث بالرقم التعريفي للطلب او اسم المتجر",
          runsheet_type: "الحالة",
          branch: "الفرع",
          runsheetZone: "نطاق خط السير",
          createRSsearch: "",
          choose: "اختر",
          runsheetID: "الرقم التعريفي لخط السير",
          courierBranch: "عنوان المندوب",
          couriername: "اسم المندوب",
          courierPhone: "هاتف المندوب",
          courierVehicle: "مركبه المندوب",
          runsheetConfirm: "تأكيد خط السير",
          runsheetCreateMsg1:
                        "هل انت متأكد من ارسال خط سير الي المندوب",
          reAssign: "إعادة إسناد",
          assigndRsSearch: "ابحث بالرقم التعريفي لخط السير",
          archivedRsTxt1: "لا يوجد خطوط سير بعد",
          empty: "لا يوجد بيانات",
          archivedRsTxt2:
                        "ربما تحتاج الي تنشيط الصفحه لايجاد خطوط سير جديده",
          workmodelInfoTitle: "معلومات عن نموذج العمل",
          workmodelInfoSub:
                        "يجب اتباع تلك الخطوات لانشاء نموذج العمل",
          select: "اختر",
          workModelCreateTxt1: "اختر فرع و ",
          workModelCreateTxt2: "قم بانشاء منطقه مغطاه",
          workModelCreateTxt3: "بواسطة فرع محدد",
          workModelCreateTxt4: "اختر منطقه و ",
          workModelCreateTxt5: "قم بانشاء حي",
          workModelCreateTxt6: "مغطي بواسطه المنطقه",
          workModelCreateTxt7: "أخيرا وليس اخرا",
          workModelCreateTxt8: "قم بانشاء قائمة اسعار",
          workModelCreateTxt9: "أخيرا أسند  ",
          addTicketsToRunsheet: "اضافة",
          workModelCreateTxt10: "نموذج العمل",
          workModelCreateTxt11: "إلى مجموعة من التجار",
          createNewBranch: "انشاء فرع جديد",
          branchName: "إسم الفرع",
          select_work_model: "اختر نموذج العمل",
          addBranch: "اضافة فرع",
          close: "إغلاق",
          createNewZone: "إضافه منطقة جديدة",
          zoneName: "اسم المنطقه",
          addZone: "اضافه منطقة",
          addNewArea: "اضافه حى جديد",
          areaName: "اسم الحى",
          addArea: "إضافة حى",
          zonesCovered: "المناطق المغطاة",
          areaList: " قائمة الاحياء",
          createDeliveryRange: "اضافة متوسط ايام توصيل جديدة",
          rangeOverAll: "متوسط ايام التوصيل",
          addRange: "اضافة",
          createWorkModel: "اضافة نموذج عمل جديد",
          sendInvitation: "ارسال الدعوة",
          workModelName: "اسم نموذج العمل",
          listInput: "قائمة الاسعار",
          custom: "مخصصة",
          sameZone: "نفس المنطقة",
          sameBranch: "نفس الفرع",
          between2Branch: "بين فرعين",
          shipmentFee: "مصاريف شحن",
          returnedFee: "مصاريف الاسترجاع",
          addAnotherBranch: "اضافة فرعين مخصصين اخرين",
          submit: "انشاء",
          err: {
            name: "من فضلك ادخل الاسم",
            range: "من فضلك اختر متوسط ايام التوصيل",
            price: "من فضلك ادخل السعر"
          },
          totalBalance: "الرصيد الكلى",
          searchBranch: "ابحث باستخدام الرقم التعريفى للفرع",
          apply: "تطبيق",
          branchID: "الرقم التعريفي للفرع",
          debit: "مدين",
          credit: "دائن",
          totalAmount: "الإجمالى",
          more: "المزيد",
          areasSubTiltle: "تخصيص الاحياء",
          addNewDimention: "اضافة صندوق جديد",
          dimensionsSubTiltle: "تخصيص احجام صناديق المؤسسة",
          sellerID: "الرقم التعريفي للتاجر",
          searchSeller: "ابحث بالرقم التعريقي للتاجر",
          sellerWallet: "محفظة التاجر",
          ticketID: "الرقم التعريفى للبوليصة",
          ticketValue: "قيمة البوليصة",
          orderValue: "قيمة الطلب",
          payTo: "ادفع ل",
          recieveFrom: "استلام من",
          sentRequest: "طلبات مرسلة",
          invoiceAttached: "الفاتورة المرفقة",
          paymentProof: "دليل الدفع",
          collected: "تجميع",
          infoScanTitle:
                        "يقوم المخزن بفحص الباركود الخاص بالبوليصة  لتأكيد استلام كل بولصية بشكل صحيح من المندوب ، ثم تقوم البوالص بتغيير حالتها من استلام المندوب إلى استلام المخزن",
          infoScanSub: "لفحص الباركود:",
          scanInfoTxt: [
            "الخطوة 1: يجب أن يكون لديك جهاز ماسح الباركود",
            "الخطوة 2: قم بتوجيه ماسح الباركود إلى البار كود الموجود على البوليصة و  قم مسح  الباركود لكل بوليصة  حتي لا تكون هناك بوليصة مفقودة",
            "الخطوة 3: ابحث عن مندوب معين",
            "الخطوة 4: افتح خط سير المندوب وابدأ عملية المسح",
            "الخطوة 5: قم بالمسح",
            "الخطوة 6: راجع النتيجة وقم بوضع تعليق ان كنت تريد",
            "الخطوة 7: قم بانهاء عملية المسح"
          ],
          searchTicket: "ابحث بالرقم التعريفى للبوليصة",
          recieveInHub: "استلام فى المخزن",
          search: "ابحث",
          selectAction: "اختر اجراء",
          viewResults: "عرض النتائج",
          startDate: "تاريخ البداية",
          endDate: "تاريخ الانتهاء",
          logsInfo1: "قم باختيار النطاق الزمني ونوع الاجراء",
          logsInfo2:
                        "ربما تحتاج الي تنشيط الصفحة بعد اختيار الفيلتر للتحقق من سجل النشاطات",
          dateTime: "الوقت/التاريخ",

          organization: "الشركة",
          orgSubTitle: "تعديل الملف الشخصى لشركتك.",
          billing: "الفواتير",
          billingSubTitle: "تحكم في معلومات فواتيرك.",
          preferences: "التفضيلات",
          security: "أمان",
          permissions: "أدوار المستخدمين",
          language: "اللغة",
          lang: "اللغة",
          selectLang: "اختر اللغة",
          selectCurrency: "اختر العملة",
          currency: "العملة",
          FAQs: "الأسئلة الشائعة",
          preferencesSubTitle: "إنشاء حالات وعناوين مخصصة",
          securitySubTitle: "إدارة كلمة المرور",
          permissionsSubTiltle: "تعيين أدوار المستخدمين",
          langSubTitle: "تغيير اللغة",
          FAQsSubTitle: "أكثر الأسئلة شيوعاً ",
          firstName: "الاسم الأول",
          lastName: "اسم العائلة",
          companyName: "اسم الشركة",
          CompanyName_ar: "اسم الشركة بالعربية",
          changeEmail: "تغيير البريد الإلكترونى",
          prefCardTitle:
                        "لوحة القيادة ديناميكية ، لذا يمكنك تخصيص طريق تتبع للطلبات علي حسب احتياجات عملك.",
          prefCardSubTitle1: "لتعديل الحالات الجديدة : ",
          prefCardStep1:
                        "الخطوة 1: من القائمة الرئيسية ،  قم باختيار الإعدادات ثم اختر التفضيلات",
          prefCardStep2: "الخطوة 2: ثم حدد لوحة التتبع",
          prefCardStep3: "الخطوة 3: اضغط علي زر إضافة حالة جديدة",
          prefCardStep4:
                        "الخطوة 4: قم أخيرًا بملىء نموذج إضافة حالة جديدة واضغط علي زر حفظ.",
          prefCardSubTitle2: "انشاء عناوين للجدول مخصصة جديدة :",
          prefCardStep5:
                        "الخطوة 1: من القائمة الرئيسية ، قم باختيار الإعدادات ثم اختر التفضيلات",
          prefCardStep6: "الخطوة 2: ثم اختار عناواين الجدول",
          prefCardStep7: "الخطوة 3: اضغط علي  زر إضافة عنوان جديد",
          prefCardStep8:
                        "الخطوة 4: اختر عنوان لحالات الشحنة من القائمة المنسدلة",
          prefCardStep9:
                        "الخطوة 5: أخيرًا حدد العناواين واضغط علي زر تطبيق",
          trackingBoard: "لوحة التتبع",
          trackingBoardDesc: "تخصيص طريقة تتبعك المناسبة",
          tableHeaders: "عناوين الجدول",
          notSupportedBySeller: "غير مدعوم",
          tableHeadersDesc: "عرض أكثر المعلومات اهمية في كل خطوة",
          addNewState: "إضافة حالة جديدة",
          trackingTable: {
            number: "الرقم",
            title: "العنوان",
            titleAr: "العنوان عربى",
            description: "الوصف",
            primColor: "اللون الأساسى",
            secColor: "لون الإختيار",
            prevState: "الحالة السابقة",
            nextState: "الحالة التالية",
            possible: "الحالات المحتملة"
          },
          systemTheme: "سمات النظام",
          systemThemeSubtitle: "عدل الشعار و الألوان الرئيسية",
          headersTable: {
            number: "الرقم",
            title: "العنوان",
            actions: "الإجراء",
            tableHeader: "رؤوس الجدول"
          },
          stateTableHeaders: "رؤوس جداول الحالات",
          addNewTable: "إضافة جدول جديد",
          refresh: "تحديث",
          stateTitle: "عنوان الحالة",
          password: "كلمة المرور",
          updatePassword: "تعديل كلمة المرور",
          changePassword: "تغيير كلمة المرور المستخدمة للدخول",
          deleteOrg: "مسح المنظمة",
          deleteOrgTitle:
                        ".سنقوم بمسح منظمتك , هذا الإجراء لا يمكن عكسه",
          passwordUpdated: "تم تعديل كلمة المرور بنجاح",
          newPassword: "كلمة المرور الجديدة",
          oldPassword: "كلمة المرور القديمة",
          updatePasswordError: "كلمتى المرور غير متطابقتين!",
          confirmPassword: "من فضلك اكد كلمة المرور!",
          confirmPasswordLabel: "تأكيد كلمة المرور",
          inputOldPassword: "من فضلك ادخل كلمة المرور القديمة!",
          inputNewPassword: "من فضلك ادخل كلمة المرور الجديدة!",
          chooseAccount: "اختر حساب المستخدم",
          permissionsList: "قائمة الأدوار",
          changePassword2: "غير كلمة السر",
          managerName: "اسم مدير الفرع",
          editBranch: "تعديل الفرع",
          editZone: "تعديل المنطقة",
          editArea: "تعديل الحي",
          editRange: "تعديل النطاق",
          welcome: "مرحباً بك!",
          welcomeSubtitle: "كل شئ جاهز لأجلك...",
          inputEmailErr: "من فضلك ادخل بريدك الإلكترونى",
          inputPasswordErr: "من فضلك ادخل كلمة المرور",
          forgotPassword: "هل نسيت كلمة المرور؟ ",
          login: "تسجيل الدخول",
          tip: "سنقوم استخدام هذا الوصف ل مساعدة المستخدمون",
          reassignRunsheet: "إعادة التعيين خط السير",
          server_error: "يتعذر تنفيذ العملية",
          packageUsage: "استهلاك الباقة",
          plan: "الخطة",
          orderPackage: "باقة الطلبات",
          outOf: "من",
          fixed: "ثابتة",
          increase: "زيادة",
          decrease: "تقليل",
          value: "القيمة",
          by_value: "رقم ثابت",
          by_percentage: "نسبة",
          needHelp: "هل تحتاج مساعدة",
          contactSupport1: "تواصل للدعم",
          contactSupport2: "ارسل الطلب وسنقوم بالرد عليك فى اقرب وقت",
          helpCenter1: "مركز المساعدة",
          helpCenter2: "قم باستخدام مستنداتنا للحصول على مساعدة",
          shipmentType: "نوع الشحنة",
          orders: "الطلبات",
          register: "تسجيل",
          signUp: "تسجيل حساب",
          orgConfigure: "تجهيز منظمتك...",
          orgAlert:
                        "من فضلك لا تغلق هذه الصفحة و انتظر حتي خمس دقائق",
          selectUser: "اختر المستخدم",
          changeStatus: "تغيير الحالة",
          accountCreateTxt1: " هل تريد انشاء حساب ؟ ",
          accountConfirmation: "تأكيد انشاء الحساب",
          saveChanges: "حفظ التغيرات",
          overview: "نظرة عامة",
          progressChart: "الرسم التوضيحى",
          clickHere: "اضغط هنا",
          changeStatusConfirmation: "تأكيد تغيير الحالة",
          archiveConfirmation: "تأكيد الأرشفة",
          logout: "خروج",
          egp: "جنيه",
          EGP: "جنيه",
          receiverInfo: "معلومات المستلم",
          productDetails: "تفاصيل المنتج",
          update: "تعديل",
          ticketScan: "مسح البوليصة",
          ticketScanSession: "جلسة فحص البوليصة",
          scanning: "مسح البوليصة...",
          ticketNotFound: "لا يوجد بوليصة بهذا الرقم",
          exportToPDF: "تحويل إلي ملف",
          noStates: "لا يوجد حالات محتملة",
          forceConfirm: "موافقة بالنيابة",
          accountcreation: "إنشاء حساب",
          forceAcceptText:
                        "هل انت متأكد انك تريد الموافقة بالنيابة علي خطوط السير المختارة؟",
          cancelOrder: "إلغاء الطلب",
          unassign: "إلغاء الإسناد",
          unassignRunsheet: "إلغاء إسناد خط السير",
          archiveRunsheet: "مسح خط السير",
          forceAcceptedSuccess: "تم الموافقة علي خطوط السير بنجاح",
          activated: "مفعلة",
          deactivated: "غير مفعلة",
          refreshText:
                        "ربما تحتاج لتحديث الصفحة لرؤية المعاملات الجديدة",
          refreshTitle: "لا يوجد معاملات جديدة من نوع",
          deliveryRangeErr: "هذا الحقل مطلوب",
          branchErr: "الفرع مطلوب",
          addressErr: "الفرع و المنطقة و الحي مطلوبين في العنوان",
          phoneErr: "رقم الهاتف غير صحيح",
          totalOrderValues: "إجمالي قيمة الطلب",
          totalAmountToSettlement: "المبلغ المراد دفعه",
          addManualAttachment: "إضافة ملف او صورة",
          attachmentText: "اضغط او قم بجر ملف لهذه المنطقة للرفع",
          settleTicket: "تسوية الطلب",
          settleTicketBody: "تم إرسال الطلب للبائع بنجاح",
          courierReturnAmount: "القيمة المرجعة",
          moneyRecievedText: "تم الاستلام بنجاح",
          postponeConfirmation: "تأكيد التأجيل",
          postponeConfirmText:
                        "هل انت متأكد انك تريد تأجيل هذه الطلبات ؟",
          postponeSuccessText: "تم تأجيل الطلبات بنجاح",
          returnConfirmation: "تأكيد إرجاع البوليصة",
          replacementConfirmation: "تأكيد استبدال البوليصة",
          returnConfirmText: "تم انشاء طلب الارجاع بنجاح",
          replacementConfirmText: "تم انشاء طلب الاستبدال بنجاح",
          paidRequests: "الطلبات المدفوعة",
          collectInvoice: "تحصيل الفاتورة",
          collectInvoiceSuccess: "تم تحصيل الفاتورة بنجاح",
          settle: "تسوية",
          printTicket: "طباعة البوليصة",
          deleteOrder: "تأكيد مسح الطلب",
          pay: "ادفع",
          image: "إثبات الدفع",
          noProof: "لا يوجد إثبات دفع",
          reject: "رفض",
          paymentID: "رقم الدفع",
          successfulRequest: "تم تنفيذ الطلب بنجاح",
          createWorkModelText: "تم إنشاء نظام محاسبة جديد بنجاح",
          deleteProcess: "عملية مسح",
          deleteText: "هل انت متأكد انك تريد إكمال المسح؟",
          enterEmail: "من فضلك ادخل بريدك الإلكترونى.",
          done: "تم",
          emailedYou: "تم التواصل علي البريد الإلكترونى",
          enterCode:
                        "من فضلك ادخل الكود المرسل علي البريد الإلكترونى",
          resendCode: "إعادة إرسال الكود",
          confirmationCode: "كود التأكيد",
          enterNewPassword: "ادخل كلمة المرور الجديدة",
          resetPassword: "من فضلك غير كلمة المرور",
          passwordReset: "تم تغيير كلمة المرور",
          addressFields: "جميع حقول العنوان مطلوبة",
          payments: "شراء باقة إضافية",
          ordersNumber: "عدد الطلبات",
          amountpayed: "المبلغ الذي سيتم دفعه",
          paymentGate: "بوابة الدفع",
          packageExpired: "انتهت صلاحية الباقة",
          packageExpiredText: "لقد استفذت المائة طلب المجانيين , برجاء شراء باقات لتتمكن من استخدام نظام تري كود",
          paymentState: "حالة الدفع",
          paymentSuccess: "تم الدفع بنجاح",
          paymentFailed:
                        " حدث خطأ ما , برجاء إعادة المحاولة \n  سبب الرفض :",
          retry: "إعادة المحاولة",
          parcel: "طرد واحد",
          bulk: "مجموعة طرود",
          bulkSuccessTitle: "إضافة طلبات",
          infoBulkTitle: "معلومات عن إضافة مجموعة الطليات",
          infoBulkSub:
                        "سيكون بإمكانك رفع و رؤية الأخطاء من هنا مسبقاً قبل إنشاء اياً من الطلبات.",
          infoBulkStep1: "تحميل نموذج ملف الإكسل",
          infoBulkStep2: "إضافة الطلبات",
          infoBulkStep3: "ثم قم برفع الملف هنا",
          bulkHyperLink: "قم بالتنزيل من هنا",
          uploadFileText: "قم برفع الملف الطلبات",
          uploadFileSub: "قم بإدراج الملف هنا او إضغط للإختيار",
          row: "رقم الصف",
          issues: "الأخطاء",
          moveOrdersTitle: "نقل الطلبات",
          moveOrdersSuccess: "طلب تم نقلهم بنجاح",
          moveOrdersText:
                        "هل انت متأكد انك تريد تحريك الطلبات المختارة؟",
          deliveryRangesAvailable: "النطاقات المتاحة",
          shipFee: "قائمة الأسعار (مصاريف الشحن)",
          RTO: "قائمة الأسعار(مصاريف الإرتجاع)",
          uploadLogo: "قم برفع شعارك",
          uploadLogoText: "ارفع صورة الشعار,او قم بجر الصورة هنا",
          selectColors: "اختر الألوان",
          primaryColor: "اللون الأساسي",
          edit_priorities: "تعديل الاولويات",
          secondaryColor: "اللون الفرعي",
          invalidImage: "من فضلك اختر صورة مناسبة.",
          deleteMain: "لا يمكن مسح نموذج العمل الرئيسي",
          date_picker: {
            start_date: "تاريخ البدء",
            end_date: "تاريخ الانتهاء",
            today: "اليوم",
            week: "هذا الاسبوع",
            month: "هذا الشهر",
            year: "هذه السنة"
          },
          reports: {
            report_type_select: "اختار نوع التقرير",
            branch_reports: "تقارير الفروع",
            branch_list: {
              branch: "اداء الفروع",
              courier: "اداء مندوبين الشحن",
              financial: "التقارير المالية"
            },
            store_reports: "تقارير المتجر",
            store_list: {
              seller: "اداء البائعين",
              financial: "التقارير المالية"
            },
            branch_name: "اسم الفرع",
            branch_name_ph: "اختر الفرع المطلوب",
            branch_name_req: "يرجى ادخال الفرع",
            report_type: "نوع التقرير",
            report_type_ph: "اختر التقرير",
            report_type_req: "يرجي ادخال نوع التقرير",
            courier_name: "اسم او رقم المندوب",
            courier_name_ph:
                            "ابحث عن المندوب باستخدام الاسم او الرقم",
            courier_name_req: "يرجي اختيار المندوب",
            store_name: "المتجر",
            store_name_ph: "اختر المتجر",
            store_name_req: "يرجى اختيار المتجر",
            report_name: "اسم التقرير",
            report_name_ph: "ادخل اسم التقرير",
            generate: "انتج التقرير",
            clear: "الغاء عوامل التصفية",
            download: "تحميل التقرير",
            cards: {
              total_orders: "جميع الطلبات",
              received_orders: "الطلبات المستلمة",
              archived_orders: "الطلبات المؤرشفة",
              cash_orders: "الطلبات النقدية",
              total_cash: "جميع الطلبات النقدية",
              online_orders: "الطلبات الالكترونية",
              total_online: "جميع الطلبات الالكترونية",
              total_tickets: "جميع بوليصات",
              on_delivery_tickets: "البوليصات قيد التوصيل",
              deliverd_tickets: "البوليصات المستلمة",
              delivered_tickets: "البوليصات المستلمة",
              in_hub_tickets: "البوليصات المتاحة بالمخزن",
              debit: "مجموع تكلفة الشحن",
              credit: "مصاريف الشحنة",
              collected: "جمعت",
              shipment_collected: "الشحنات المجمعة",
              shipment_fees: "مصاريف الشحن",
              not_collected: "لم تجمع",
              returned_tickets: "البوليصات المسترجعة"
            },
            table: {
              regular: {
                ticket_id: "رقم البوليصة",
                order_id: "رقم الطلبية",
                seller_name: "اسم التاجر",
                store_name: "المتجر",
                payment_method: "وسيلة الدفع",
                shipment_status: "حالة الشحن",
                shipment_fees: "مصاريف الشحن",
                customer_name: "اسم العميل",
                create_date: "تاريخ الانشاء"
              }
            }
          },
          Comments: "التعليقات",
          Comments_description: " قائمة تعليقات المندوب",
          "Add Comment": "إضافة تعليق",
          "Edit Comment": "تعديل تعليق",
          Text: "النص",
          "Text (Arabic)": "النص (بالعربية)",
          "Is Pickup": "هل تم الاستلام",
          "Is Other": "هل هو آخر",
          "Create Date": "تاريخ الإنشاء",
          Edit: "تعديل",
          Submit: "إرسال",
          "Please enter text": "يرجى إدخال النص",
          "Please enter Arabic text": "يرجى إدخال النص بالعربية",
          Yes: "نعم",
          No: "لا",
          "Comment created successfully": "تم إنشاء التعليق بنجاح",
          "Comment updated successfully": "تم تحديث التعليق بنجاح",
          "Comment deleted successfully": "تم حذف التعليق بنجاح",
          "Failed to save comment. Please try again.": "فشل في حفظ التعليق. يرجى المحاولة مرة أخرى.",
          "Failed to delete comment. Please try again.": "فشل في حذف التعليق. يرجى المحاولة مرة أخرى."

        }
        // accountCreateTxt2:"account"
      }
    }
  });

export default locale;
