import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Select } from "antd";
import "../../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import { CreationConfirmation } from "../../modals/confirmationModal";
import {
  useArchivedUsers
} from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import useLanguage from "../../../hooks/useLanguage";
import { useFilterHistoricalEmployeesMutation, useReactivateEmployeeMutation } from "../../../stateManagement/apis/accountsApi";
import { useGetBranchesMutation } from "../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../api/ErrorNotifier";

const ArchivedUsers = (props) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [actionType] = useState(null);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState();
  const [selectionRows, setSelectionRows] = useState([]);
  const [selectedUserID, setSelectedUserID] = useState("");
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [filterHistoricalEmployees, { isLoading: loading }] = useFilterHistoricalEmployeesMutation();

  const [getBranchesApi] = useGetBranchesMutation();
  const [reactivateEmployee] = useReactivateEmployeeMutation();
  const [filters, setFilters] = useState({
    branch: {
      key: "branch",
      title: t("branch"),
      selectedValue: undefined,
      values: []
    },
    type: {
      key: "type",
      title: t("userType"),
      selectedValue: undefined,
      values: [
        {
          id: 2,
          name: t("operation")
        },
        {
          id: 4,
          name: t("accountant")
        },
        {
          id: 5,
          name: t("inventoryManager")
        },
        {
          id: 6,
          name: t("operationTeamMember")
        }
      ]
    }
  });
  const showModel = () => {
    setShow(true);
  };
  const hide = () => {
    setShow(false);
  };
  useEffect(() => {
    console.log(actionType);
  }, [actionType]);

  const getData = async () => {
    const result = await filterHistoricalEmployees({
      page,
      search: keyword,
      branch: filters.branch.selectedValue,
      type: filters.type.selectedValue
    });
    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getData();
  }, [page, keyword, filters]);

  useEffect(() => {
    getBranches();
  }, []);

  const getBranches = async () => {
    const result = await getBranchesApi({ page_size: 10000 });
    if (result.data) {
      setFilters({
        ...filters,
        branch: { ...filters.branch, values: result.data.data }
      });
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    console.log(selectionRows);
    setSelectedUserID(selectionRows[0]);
  }, [selectionRows]);

  const reActive = async () => {
    const result = await reactivateEmployee(selectedUserID);
    if (result.data) {
      getData();
      hide();
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors("Error");
    }
  };
  const lang = useLanguage();

  return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.accounts")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("sideMenu.archived")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("sideMenu.archived")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end" sm="12">
                        <button
                            disabled={selectionRows.length === 0}
                            className="confirm"
                            onClick={showModel}
                            type="submit"
                        >
                            {t("reActive")}
                        </button>
                    </Col>
                </Row>
                <Row>
                    <div>
                        <div>
                            <TablePro
                                loading={loading}
                                headerExtra={
                                    <div className="row-centered-horizontal gap-1">
                                        {Object.keys(filters ?? {}).map(
                                          (key) => (
                                                <Select
                                                    key={key}
                                                    onChange={(v) => {
                                                      setFilters({
                                                        ...filters,
                                                        [key]: {
                                                          ...filters[key],
                                                          selectedValue:
                                                                    v
                                                        }
                                                      });
                                                    }}
                                                    value={
                                                        filters[key]
                                                          ?.selectedValue
                                                    }
                                                    allowClear
                                                    style={{
                                                      minWidth: "150px"
                                                    }}
                                                    placeholder={
                                                        filters[key].title
                                                    }
                                                >
                                                    {filters[key].values.map(
                                                      (it) => (
                                                            <Select.Option
                                                                key={it.id}
                                                                value={it.id}
                                                            >
                                                                {it.name}
                                                            </Select.Option>
                                                      )
                                                    )}
                                                </Select>
                                          )
                                        )}
                                    </div>
                                }
                                dataSource={data}
                                columns={useArchivedUsers()}
                                onSearch={setKeyword}
                                selection={"radio"}
                                selectedRows={selectionRows}
                                setSelectedRows={setSelectionRows}
                                pagination={{
                                  current: page,
                                  total: count,
                                  onChange: (page) => setPage(page)
                                }}
                            />
                        </div>
                    </div>
                </Row>
            </Container>
            <CreationConfirmation
                sendRequest={reActive}
                show={show}
                hide={hide}
                body={
                    lang === "ar"
                      ? "هل تريد تفعيل هذا الحساب"
                      : "Are you sure that you want to Re-Active this user ?"
                }
                title={
                    lang === "ar" ? "أرشفة الحساب" : "Archive User Confirmation"
                }
            />
        </div>
  );
};

export default ArchivedUsers;
