import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import { Button, Input, Modal } from "antd";

import useLanguage from "../../hooks/useLanguage";

import TablePro from "../tables/TablePro";

const useInventoryItemLocationsColumns = (handleChangeInventoryLocations) => {
  const { t } = useTranslation();
  return [
    {
      title: t("Random ID"),
      dataIndex: "id",
      render: (_, row) => (
                <span className="tableID">#{row.random_id}</span>
      )
    },
    {
      title: t("Inventory Location"),
      dataIndex: "inventory_location"
    },
    {
      title: t("Ticket ID"),
      dataIndex: "ticket_id",
      render: (_, row) => <span className="tableID">{`#${_}`}</span>
    },
    {
      title: t("Inventory Order ID"),
      dataIndex: "inventory_order_id",
      render: (_, row) => <span className="tableID">{`#${_}`}</span>
    },
    // {
    //     title: t("Storage Date"),
    //     dataIndex: "storage_date",
    //     render: (_, row) => (
    //         <p className="paragraphMargin">
    //             {row.storage_date?.split("T")[0]} -{" "}
    //             {row.storage_date
    //                 ?.split("T")[1]
    //                 .split(":")
    //                 .slice(0, 2)
    //                 .join(":")}
    //         </p>
    //     ),
    // },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      render: (_, row) => <span className="tableID">{row.quantity}</span>
    },
    {
      title: t("Selected Quantity"),
      dataIndex: "selectedQuantity",
      render: (_, row) => (
                <Input
                    type="number"
                    value={row.selectedQuantity}
                    onChange={(e) =>
                      handleChangeInventoryLocations(
                        row.random_id,
                        row.location_id,
                        +e.target.value,
                        row.ticket_id,
                        row.inventory_order_id
                      )
                    }
                />
      )
    }
  ];
};

const InventoryItemLocationsModal = ({
  orderItem,
  handleChangeInventoryLocations,
  hideModal,
  inventoryItemLocations,
  fetchLoading,
  page,
  setPage,
  count,
  open
}) => {
  const columns = useInventoryItemLocationsColumns(
    handleChangeInventoryLocations
  );
  const [data, setData] = useState(inventoryItemLocations);
  const [loadingPrepared, setLoadingPrepared] = useState(false);
  const { t } = useTranslation();
  const lang = useLanguage();

  useEffect(() => {
    console.log({ "ORDER ITEM CHECK": orderItem });
    setLoadingPrepared(true);
    const preparedData = (inventoryItemLocations ?? []).map((item) => {
      return {
        ...item,
        selectedQuantity: orderItem.inventory_locations?.find(
          (location) => location.random_id === item.random_id
        )
          ? orderItem.inventory_locations.find(
            (location) => location.random_id === item.random_id
          ).quantity
          : 0
      };
    });
    setData(preparedData);
    setLoadingPrepared(false);
  }, [inventoryItemLocations, orderItem]);

  return (
        <Modal open={open} onOk={hideModal} width={1000}>
            <Container dir={lang === "ar" ? "rtl" : "ltr"} className={"pt-5"}>
                <Row>
                    <Col sm="12">
                        <span className="title1">
                            {t("Inventory Order Items")}
                        </span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("Inventory Item Locations")}
                        </span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {t("Selecting Inventory Item Locations")}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <TablePro
                            columns={columns}
                            dataSource={data}
                            loading={fetchLoading || loadingPrepared}
                            selection={false}
                            onSearch={() => {}}
                            pagination={{
                              current: page,
                              total: count,
                              pageSize: 10,
                              defaultPageSize: 10,
                              onChange: (page, pageSize) => {
                                setPage(page);
                              }
                            }}
                        />
                    </Col>
                </Row>
                <div className="d-flex justify-content-end mt-3">
                    <Button type="primary" onClick={hideModal}>
                        {t("Done")}
                    </Button>
                </div>
            </Container>
        </Modal>
  );
};

export default InventoryItemLocationsModal;
