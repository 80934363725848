import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { useUserObject } from "../../../stateManagement/slices/userSlice";
import ChooseOrdersStep from "./ChooseOrdersStep";
import ChooseCouriersStep from "./ChooseCouriersStep";
import RequestStep from "./RequestStep";
import styles from "./MoveOrders.module.css";

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function MoveOrders () {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const user = useUserObject();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
        <div className={styles.container}>
            <Row className="mb-4" >
                <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.internalRunSheet")}
                        </span>

                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                            {t("sideMenu.moveOrders")}
                        </span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">
                        {t("sideMenu.createInternal")}
                    </h2>
                </Col>
            </Row>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    aria-label="basic tabs example"
                    className={`${
                        user.role === "Seller" ? "seller" : "admin"
                    }`}
                >
                    <Tab
                        label={t("chooseOrders")}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={t("chooseCourier")}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label={t("reviewRequest")}
                        {...a11yProps(2)}
                    />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <ChooseOrdersStep
                    value={value}
                    handleChange={handleChange}
                />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <ChooseCouriersStep
                    value={value}
                    handleChange={handleChange}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <RequestStep
                    value={value}
                    handleChange={handleChange}
                />
            </TabPanel>
        </div>
  );
}

export default MoveOrders;
