import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  usePostponedColumns
} from "../layout/tableColumns";
import { postponeDate, usePostponedOrders } from "../../stateManagement/slices/postponedOrdersSlice";
import TablePro from "../tables/TablePro";

function NewPostponeDate ({ value, handleChange }) {
  const data = usePostponedOrders();
  console.log(usePostponedOrders());
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const { t } = useTranslation();

  const handleDate = (e) => {
    setDate(e.target.value);
    dispatch(postponeDate(e.target.value));
  };
  const proceed = () => {
    handleChange(null, value + 1);
  };
  return (
        <>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">{t("newDate")}</label>
                        <Form.Control
                            value={date}
                            onChange={handleDate}
                            type="date"
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button className="cancel" onClick={() => handleChange(null, 0)}>{t("discard")}</button>
                    <button
                        disabled={date === ""}
                        onClick={proceed}
                        className="confirm"
                    >
                        {t("next")}
                    </button>
                </Col>

            </Row>
            <TablePro dataSource={data} columns={usePostponedColumns()} />
        </>
  );
}

export default NewPostponeDate;
