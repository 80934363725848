import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { CircleRightIcon, ViewIcon } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAuthedUser } from "../../../stateManagement/slices/userSlice";
import { reduxBranch } from "../../../stateManagement/slices/branchSlice";
import { getSeller } from "../../../stateManagement/slices/selectedSellerSlice";
import { SuccessfulRequest } from "../../modals/confirmationModal";
import StatusCard from "../../layout/StatusCard";
import { addRunsheetOrders } from "../../../stateManagement/slices/rsOrdersSlice";
import useLanguage from "../../../hooks/useLanguage";
import TablePro from "../../tables/TablePro";
import { useFilterPaymentRequestsMutation, usePaymentSummaryMutation, useAcceptPaymentRequestMutation, useRejectPaymentRequestMutation } from "../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../api/ErrorNotifier";

function RecievedRequests (props) {
  const dispatch = useDispatch();
  const sellerDispatch = useDispatch();
  const dispatchOrders = useDispatch();

  const { t } = useTranslation();

  const [selected, setSelected] = useState("Sent");
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [payment, setPayment] = useState("");
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [page, setPage] = useState(1);
  // const [selectionModel, setSelectionModel] = useState([]);
  const [statesNumbers, setStatesNumbers] = useState({ 1: 0, 2: 0, 3: 0 });
  // const prevSelectionModel = useRef(selectionModel);
  const cur = useAuthedUser().organization.country.currency;
  const [filterPaymentRequests] = useFilterPaymentRequestsMutation();
  const [paymentSummary] = usePaymentSummaryMutation();
  const [acceptPaymentRequest] = useAcceptPaymentRequestMutation();
  const [rejectPaymentRequest] = useRejectPaymentRequestMutation();

  const hide = () => {
    setShow(false);
  };

  const hideSuccess = async () => {
    setShowSuccess(false);
    selectState(currentState);
    getSummary();
  };
  const [columns] = useState([
    {
      field: "payment_id",
      title: `${t("paymentID")}`,
      width: 200,
      // valueGetter: (row) => row..id,
      render: (_, row) => <p className="paragraphMargin">#{row.id}</p>
    },
    {
      field: "id",
      title: `${t("sellerID")}`,
      width: 200,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <p className="paragraphMargin">#{row.seller.id}</p>
      )
    },
    {
      field: "name",
      title: `${t("sellername")}`,
      width: 220,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <p className="paragraphMargin">{row.seller.user.name}</p>
      )
    },
    {
      field: "store",
      title: `${t("storename")}`,
      width: 220,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <p className="paragraphMargin">{row.seller.store_name}</p>
      )
    },
    {
      field: "total_amount",
      title: `${t("totalAmount")}`,
      width: 220,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <strong>
                    {cur} {parseInt(row.amount)}
                </strong>
      )
    },
    {
      field: "create_date",
      title: `${t("createDate")}`,
      width: 190,
      render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
      )
    },
    {
      field: "more",
      title: `${t("more")}`,
      width: 220,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <Link
                    onClick={() => {
                      dispatch(reduxBranch(row.seller.user.name));
                      sellerDispatch(getSeller(row.seller));
                      dispatchOrders(addRunsheetOrders(row.orders));
                    }}
                    to={"/view_orders"}
                    className="sellers-wallets"
                >
                    <Icon source={CircleRightIcon} color="base" />
                </Link>
      )
    }
  ]);

  const [column1] = useState([
    {
      field: "payment_id",
      title: `${t("paymentID")}`,
      width: 200,
      // valueGetter: (row) => row..id,
      render: (_, row) => <p className="paragraphMargin">#{row.id}</p>
    },
    {
      field: "id",
      title: `${t("sellerID")}`,
      width: 200,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <p className="paragraphMargin">#{row.seller.id}</p>
      )
    },
    {
      field: "name",
      title: `${t("sellername")}`,
      width: 220,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <p className="paragraphMargin">{row.seller.user.name}</p>
      )
    },
    {
      field: "store",
      title: `${t("storename")}`,
      width: 220,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <p className="paragraphMargin">{row.seller.store_name}</p>
      )
    },
    {
      field: "total_amount",
      title: `${t("totalAmount")}`,
      width: 220,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <strong>
                    {cur} {parseInt(row.amount)}
                </strong>
      )
    },
    {
      field: "create_date",
      title: `${t("createDate")}`,
      width: 190,
      render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
      )
    },
    {
      field: "more",
      title: `${t("more")}`,
      width: 220,
      // valueGetter: (row) => row..id,
      render: (_, row) => (
                <div
                    onClick={() => {
                      setShow(true);
                      setPayment(row);
                    }}
                    to={`/sellers_orders/${row.id}`}
                    className="sellers-wallets"
                >
                    <Icon source={ViewIcon} color="base" />
                </div>
      )
    }
  ]);
  const lang = useLanguage();

  const [currentState, setCurrentState] = useState({
    id: "2",
    type: ` ${lang === "ar" ? "المرسلة" : "Sent"}`,
    number: statesNumbers["2"],
    styleClass: "statusCard",
    background: "#E0F3E8",
    style: { borderLeft: "solid 20px #BFFFDA" }
  });
  const cards = [
    {
      id: "2",
      type: ` ${lang === "ar" ? "المرسلة" : "Sent"}`,
      number: statesNumbers["2"],
      styleClass: "statusCard",
      background: "#E0F3E8",
      style: { borderLeft: "solid 20px #BFFFDA" }
    },
    {
      id: "1",
      type: ` ${lang === "ar" ? "استجابة" : "Response"}`,
      number: statesNumbers["1"],
      styleClass: "statusCard",
      background: "#E0F3E8",
      style: { borderLeft: "solid 20px #57BE6C" }
    },
    {
      id: "3",
      type: ` ${lang === "ar" ? "تجميع" : "Collected"}`,
      number: statesNumbers["3"],
      styleClass: "statusCard",
      background: "#E0F3E8",
      style: { borderLeft: "solid 20px #148060" }
    }
  ];

  const getSummary = async () => {
    const result = await paymentSummary({ sender: 2 });
    if (result.data) {
      setStatesNumbers(result.data);
    }
  };

  const rejectPayment = async () => {
    const result = await rejectPaymentRequest(payment.id);
    if (result.data) {
      setShow(false);
      getSummary();
      selectState(currentState);
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors(t("Server Error"));
    }
  };
  const confirmPayment = async () => {
    const result = await acceptPaymentRequest(payment.id);
    if (result.data) {
      setShow(false);
      setShowSuccess(true);
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors(t("Server Error"));
    }
  };

  const selectState = async (
    state = {
      id: "2",
      type: "Sent",
      number: statesNumbers["2"],
      styleClass: "statusCard",
      selectedCard: "Sent",
      background: "#E0F3E8",
      style: { borderLeft: "solid 20px #BFFFDA" }
    }
  ) => {
    setCurrentState(state);
  };

  async function fetchState () {
    const state = currentState;
    const result = await filterPaymentRequests({
      state: state.id,
      sender: 2,
      page
    });
    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
    setSelected(state.type);
  }

  useEffect(() => {
    selectState();
    getSummary();
  }, []);
  // const lang = localStorage.getItem("lang");
  useEffect(() => {
    fetchState();
  }, [page, currentState]);
  return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("totalBalance")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sellerWallet")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <Row className="cardsMargin">
                {cards.map((card, index) => {
                  return (
                        <Col
                            key={card.id}
                            sm="12"
                            md="4"
                            onClick={() => selectState(card, cards[index + 1])}
                        >
                            <StatusCard
                                key={card.id}
                                selectedCard={selected}
                                type={card.type}
                                number={card.number}
                                styleClass={"statusCard"}
                                background={card.background}
                                style={card.style}
                            />
                        </Col>
                  );
                })}
            </Row>
            <TablePro
                title={<h6>{t("sentRequest")}</h6>}
                dataSource={data}
                columns={currentState.id === "3" ? columns : column1}
                pagination={{
                  current: page,
                  total: count,
                  onChange: (page) => setPage(page)
                }}
            />

            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("invoiceAttached")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        style={{ width: "100%" }}
                        src={payment.payment_proof}
                        alt={t("paymentProof")}
                    />
                </Modal.Body>
                {
                currentState.id === "4"
                  ? null
                  : (
                    <Modal.Footer>
                        <button onClick={hide} className="cancel">
                            {t("cancel")}
                        </button>
                        <button onClick={rejectPayment}>{t("reject")}</button>
                        <button
                            // onClick={}
                            className="confirm"
                            onClick={confirmPayment}
                        >
                            {t("collected")}
                        </button>
                    </Modal.Footer>
                    )}
            </Modal>
            <SuccessfulRequest
                title={t("collectInvoice")}
                body={t("collectInvoiceSuccess")}
                hide={hideSuccess}
                show={showSuccess}
            />
        </>
  );
}

export default RecievedRequests;
