import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button as AntBtn } from "antd";
import SeeMoreButton from "../../../../components/SeeMoreButton";
import { useAuthedUser } from "../../../../stateManagement/slices/userSlice";
import useLanguage from "../../../../hooks/useLanguage";

export const useUnConfirmedRequestsColumns = (fetchDetails) => {
  const { t } = useTranslation();
  const lang = useLanguage();

  return [
    {
      title: t("orderId"),
      dataIndex: "id",
      render: (_, row) => (
        <span
          className="tableID"
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => fetchDetails(row.id)}
        >
          #{row.id}
        </span>
      )
    },
    {
      title: t("store"),
      dataIndex: ["seller", "store_name"]
    },
    {
      title: t("From Inventory Stock"),
      dataIndex: "from_stock",
      render: (_, row) => (_ ? t("Yes") : t("No"))
    },
    {
      title: t("shipmentType"),
      dataIndex: "type"
    },
    {
      title: t("Order Type"),
      dataIndex: "order_type_name"
    },
    {
      title: t("Pickup City"),
      dataIndex: "id",
      render: (_, row) => <span>{lang === "en" ? row.seller_address?.area.city.name : row.seller_address?.area.city.name_ar}</span>
    },
    {
      title: t("Delivery City"),
      dataIndex: "id",
      render: (_, row) => <span>{lang === "en" ? row.client_address?.city_name : row.client_address?.city_name_ar}</span>
    },
    {
      title: t("itemQuantity"),
      dataIndex: "ticket_count"
    },
    {
      title: t("orderValue"),
      dataIndex: "sub_total"
    },
    {
      title: t("Request Date"),
      dataIndex: "create_date",
      render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]} -{" "}
                    {row.create_date?.split("T")[1]}
                </p>
      )
    },
    {
      title: t("paymentMethod"),
      dataIndex: "payment_method",
      render: (_, row) => (
                <strong>
                    {row.payment_method === "Online"
                      ? (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="onlineBtn"
                        >
                            {row.payment_method}
                        </Button>
                        )
                      : (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="cashBtn"
                        >
                            {row.payment_method}
                        </Button>
                        )}
                </strong>
      )
    },
    {
      title: t("actions"),
      show: true,
      render: (_, row) => (
                <div className="d-flex justify-content-center align-items-center gap-2">
                    <NavLink
                        to={`/order_chain_details/${row.order?.id ?? row.id}`}
                    >
                        <SeeMoreButton />
                    </NavLink>
                    {row.from_stock && (
                        <NavLink to={`/accept_from_stock/${row.id}`}>
                            <AntBtn type="dashed" color="primary">
                                {t("Accept")}
                            </AntBtn>
                        </NavLink>
                    )}
                </div>
      )
    }
  ];
};

export const useConfirmedRequestsColumns = (fetchDetails) => {
  const { t } = useTranslation();
  const lang = useLanguage();
  const cur = useAuthedUser().organization.country.currency;

  return [
    {
      title: t("orderId"),
      dataIndex: ["order", "id"],
      render: (_, row) => (
        <div
          style={{ minWidth: "100px", cursor: "pointer", color: "blue" }}
          className="tableID"
          onClick={() => fetchDetails(row.order?.id)}
        >
          #{row.order?.id}
        </div>
      )
    },
    {
      title: t("ticketId"),
      dataIndex: "id",
      render: (_, row) => (
        <span
          className="tableID"
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => fetchDetails(row.id)}
        >
          #{row.id}
        </span>
      )
    },
    {
      title: t("store"),
      dataIndex: ["order", "seller", "store_name"]
    },
    {
      title: t("Order Type"),
      dataIndex: ["order", "order_type_name"]
    },
    {
      title: t("createDate"),
      dataIndex: "create_date",
      render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]} -{" "}
                    {row.create_date
                      ?.split("T")[1]
                      .split(":")
                      .slice(0, 2)
                      .join(":")}
                </p>
      )
    },
    {
      title: t("productInfo"),
      dataIndex: ["product", "name"]
    },
    {
      title: t("postponedDate"),
      dataIndex: "new_date"
    },
    {
      title: t("Pickup City"),
      dataIndex: ["order", "seller_address", "area_names"],
      render: (_, row) => <span>{lang === "en" ? _?.en : _?.ar}</span>

    },
    {
      title: t("Delivery City"),
      dataIndex: ["order", "client_address", "area"],
      render: (_, row) => <span>{lang === "en" ? _?.name : _?.name_ar}</span>
    },
    {
      title: t("itemQuantity"),
      dataIndex: "quantity"
    },
    {
      title: t("weight"),
      dataIndex: "weight"
    },
    {
      title: t("specialLabel"),
      dataIndex: ["special"]
    },
    {
      title: t("feedbackComment"),
      dataIndex: ["comment", "text"]
    },
    {
      title: t("orderValue"),
      dataIndex: ["order", "sub_total"],
      render: (_, row) => (
                <>
                    {row.order?.sub_total} {cur}
                </>
      )
    },
    {
      title: t("shipmentFees"),
      field: ["order", "delivery_fees"],
      render: (_, row) => (
                <>
                    {row.order?.delivery_fees} {cur}
                </>
      )
    },
    {
      title: t("courierId"),
      dataIndex: ["order", "courier", "id"]
    },
    {
      title: t("courierName"),
      dataIndex: ["order", "courier", "user", "name"]
    },
    {
      title: t("courierBranch"),
      dataIndex: ["order", "courier", "branch", "name"]
    },
    {
      title: t("courierVehicle"),
      dataIndex: ["order", "courier", "vehicle"]
    },
    {
      title: t("courierPhone"),
      dataIndex: ["order", "courier", "user", "phone"]
    },
    {
      title: t("sellerId"),
      dataIndex: ["order", "seller", "id"]
    },
    {
      title: t("store"),
      dataIndex: ["order", "seller", "store_name"]
    },
    {
      title: t("sellerAddress"),
      dataIndex: ["order", "seller_address", "address"]
    },
    {
      title: t("customerId"),
      dataIndex: ["order", "client", "id"]
    },
    {
      title: t("customerName"),
      dataIndex: ["order", "client", "name"]
    },
    {
      title: t("customerPhone"),
      dataIndex: ["order", "client", "phone"]
    },
    {
      title: t("customerAddress"),
      dataIndex: ["order", "client_address", "address"]
    },
    {
      title: t("status"),
      dataIndex: ["order", "assigned_state"],
      render: (_, row) => (
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        {row.order?.assigned_state}
                    </Button>
                </strong>
      )
    },
    {
      title: t("paymentMethod"),
      dataIndex: ["order", "payment_method"],
      render: (_, row) => (
                <strong>
                    {/* {console.log(row)} */}
                    {row.payment_method === "1"
                      ? (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="onlineBtn"
                        >
                            Online
                        </Button>
                        )
                      : (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="cashBtn"
                        >
                            Cash
                        </Button>
                        )}
                </strong>
      )
    },
    {
      show: true,
      field: "action",
      headerName: "Action",
      width: 230,
      render: (_, row) => (
                <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                    <SeeMoreButton />
                </NavLink>
      )
    }
  ];
};

export const useInventoryColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t("Inventory ID"),
      dataIndex: "id",
      render: (_, row) => <span className="tableID">#{row.id}</span>
    },
    {
      title: t("Inventory Item ID"),
      dataIndex: ["item", "id"],
      render: (_, row) => (
                <span className="tableID">#{row.item?.id}</span>
      )
    },
    {
      title: t("Inventory Item Name"),
      dataIndex: ["item", "product", "name"]
    },
    {
      title: t("Inventory Item SKU"),
      dataIndex: ["item", "product", "sku"]
    },
    {
      title: t("Inventory Item Category"),
      dataIndex: ["item", "product", "category", "name"]
    },
    {
      title: t("Inventory Location"),
      dataIndex: ["location", "location", "name"],
      render: (_, row) =>
        row.location ? row.location.location.name : "N/A"
    },
    {
      title: t("Previous Location"),
      dataIndex: ["previous_location", "location", "name"],
      render: (_, row) =>
        row.previous_location
          ? row.previous_location.location.name
          : "N/A"
    },
    {
      title: t("Ticket ID"),
      dataIndex: ["ticket", "id"],
      render: (_, row) => (
                <span className="tableID">
                    {row.ticket ? `#${row.ticket.id}` : "N/A"}
                </span>
      )
    },
    {
      title: t("Inventory Order ID"),
      dataIndex: ["inventory_order", "id"],
      render: (_, row) => (
                <span className="tableID">
                    {row.inventory_order ? `#${row.inventory_order.id}` : "N/A"}
                </span>
      )
    },
    {
      title: t("Storage Date"),
      dataIndex: "storage_date",
      render: (_, row) => (
                <p className="paragraphMargin">
                    {row.storage_date?.split("T")[0]} -{" "}
                    {row.storage_date
                      ?.split("T")[1]
                      .split(":")
                      .slice(0, 2)
                      .join(":")}
                </p>
      )
    },
    {
      title: t("Previous Storage Date"),
      dataIndex: "previous_storage_date",
      render: (_, row) => (
                <p className="paragraphMargin">
                    {row.previous_storage_date?.split("T")[0]} -{" "}
                    {row.previous_storage_date
                      ?.split("T")[1]
                      .split(":")
                      .slice(0, 2)
                      .join(":")}
                </p>
      )
    },
    {
      title: t("Delivery Date"),
      dataIndex: "delivery_date",
      render: (_, row) => (
                <p className="paragraphMargin">
                    {row.delivery_date?.split("T")[0]} -{" "}
                    {row.delivery_date
                      ?.split("T")[1]
                      .split(":")
                      .slice(0, 2)
                      .join(":")}
                </p>
      )
    },
    {
      title: t("Return Date"),
      dataIndex: "return_date",
      render: (_, row) => (
                <p className="paragraphMargin">
                    {row.return_date?.split("T")[0]} -{" "}
                    {row.return_date
                      ?.split("T")[1]
                      .split(":")
                      .slice(0, 2)
                      .join(":")}
                </p>
      )
    }
  ];
};

export const useInventoryOrderColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t("Inventory Order ID"),
      dataIndex: "id",
      render: (_, row) => <span className="tableID">#{row.id}</span>
    },
    {
      title: t("Inventory Order Type"),
      dataIndex: "type"
    },
    {
      title: t("Inventory Order Status"),
      dataIndex: "status"
    },
    {
      title: t("Inventory Order Seller"),
      dataIndex: ["seller", "store_name"]
    },
    {
      title: t("Inventory Order Seller Address"),
      dataIndex: ["seller_address", "address"]
    },
    {
      title: t("Inventory Order Location"),
      dataIndex: ["location", "location", "name"],
      render: (_, row) =>
        row.location ? row.location.location.name : "N/A"
    },
    {
      title: t("Inventory Items Count"),
      dataIndex: "item_count"
    },
    {
      title: t("Inventory total"),
      dataIndex: "total_quantity"
    },
    {
      title: t("action"),
      dataIndex: "id",
      render: (_, row) =>
        row.seller_created
          ? (
                    <NavLink to={`/accept_inventory_order/${row.id}`}>
                        <AntBtn type="dashed" color="primary">
                            {t("Accept")}
                        </AntBtn>
                    </NavLink>
            )
          : null
    }
  ];
};
