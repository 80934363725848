import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Input, Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { loginAction } from "../../../stateManagement/authActions";
import useLanguage from "../../../hooks/useLanguage";
import { useUserObject } from "../../../stateManagement/slices/userSlice";
import {
  useGetOrganizationMutation,
  useOrganizationChangeEmailMutation,
  useOrganizationVerifyEmailMutation,
  useUpdateOrganizationMutation
} from "../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../api/ErrorNotifier";

const { Title, Text } = Typography;

function Organization () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUserObject();
  const language = useLanguage();

  const [showEmailModel, setShowEmailModel] = useState(false);
  const [firstName, setFirstName] = useState(user.user.name?.split(" ")[0]);
  const [lastName, setLastName] = useState(user.user.name?.split(" ")[1]);
  const [email] = useState(user.user.email);
  const [newEmail, setNewEmail] = useState(user.user.email);
  const [org, setOrg] = useState("");
  const [phone, setPhone] = useState(user.user.phone);
  const [verify, setVerify] = useState("");

  const [getOrganization] = useGetOrganizationMutation();
  const [updateOrganization, { isLoading: loading }] = useUpdateOrganizationMutation();
  const [organizationChangeEmail] = useOrganizationChangeEmailMutation();
  const [organizationVerifyEmail] = useOrganizationVerifyEmailMutation();

  useEffect(() => {
    const fetchOrganization = async () => {
      const result = await getOrganization();
      if (result.data) setOrg(result.data.data[0].name);
    };
    fetchOrganization();
  }, [getOrganization]);

  const handleInputChange = (setter) => (e) => setter(e.target.value);
  const showToast = (message, isError = false) => {
    setTimeout(() => {
      isError ? toast.error(message) : toast.success(message);
    }, 500);
  };

  const handleEmailChange = async () => {
    if (newEmail === email) {
      showToast(
        language.includes("en")
          ? "New email must be different from old email."
          : "يجب ان يكون البريد الاكلتروني مختلف",
        true
      );
      return;
    }

    const result = await organizationChangeEmail({ email: newEmail });
    if (result.data) {
      showToast(
        language.includes("en") ? "Verification Code Sent." : "تم ارسال الكود بنجاح"
      );
    } else {
      notifyErrors(result.error?.data || "Failed to send verification code");
    }
  };

  const handleVerification = async () => {
    const result = await organizationVerifyEmail({ code: verify });
    if (result.data) {
      showToast(language.includes("en") ? "Code Verified" : "تم التعرف على الكود");
    } else {
      notifyErrors(result.error?.data || "Failed to verify code");
    }
  };

  const handleSaveChanges = async () => {
    const updatedData = {
      name: `${firstName || ""} ${lastName || ""}`,
      org_name: org,
      phone
    };
    const updatedUser = structuredClone(user);
    updatedUser.user.name = updatedData.name;
    updatedUser.user.phone = updatedData.phone;

    dispatch(
      loginAction({
        ...user,
        user: {
          ...user.user,
          name: updatedData.name,
          phone: updatedData.phone,
          organization: { ...user.user.organization, name: updatedData.org_name }
        }
      })
    );

    const result = await updateOrganization(updatedData);
    if (result.data) {
      showToast(
        language.includes("en")
          ? "Organization Details Updated Successfully"
          : "تم تعديل معلومات المؤسسة بنجاح"
      );
      setTimeout(() => navigate("/"), 1000);
    } else {
      notifyErrors(result.error?.data || "Failed to update organization details");
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 24, padding: "0 24px" }}>
        <Col span={24}>
          <Title level={4} style={{ color: "#555" }}>
            {t("sideMenu.settings")}
          </Title>
          <Text style={{ color: "#888" }}>{t("organization")}</Text>
        </Col>
      </Row>
      <div style={{ background: "#fff", borderRadius: 8, padding: 24, boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
        <Text style={{ fontSize: 16, fontWeight: 600 }}>{t("details")}</Text>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col xs={24} md={12}>
            <label style={{ fontWeight: 500 }}>{t("firstName")}</label>
            <Input
              value={firstName}
              onChange={handleInputChange(setFirstName)}
              placeholder={t("firstName")}
              style={{ borderRadius: 8, marginTop: 8 }}
            />
          </Col>
          <Col xs={24} md={12}>
            <label style={{ fontWeight: 500 }}>{t("lastName")}</label>
            <Input
              value={lastName}
              onChange={handleInputChange(setLastName)}
              placeholder={t("lastName")}
              style={{ borderRadius: 8, marginTop: 8 }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <label style={{ fontWeight: 500 }}>{t("companyName")}</label>
            <Input
              value={org}
              onChange={handleInputChange(setOrg)}
              placeholder={t("companyName")}
              style={{ borderRadius: 8, marginTop: 8 }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <label style={{ fontWeight: 500 }}>{t("email")}</label>
              <Text
                onClick={() => setShowEmailModel(true)}
                style={{ color: "#1890ff", cursor: "pointer", fontWeight: 500 }}
              >
                {t("changeEmail")}
              </Text>
            </div>
            <Input value={email} disabled style={{ borderRadius: 8, marginTop: 8 }} />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <label style={{ fontWeight: 500 }}>{t("phone")}</label>
            <PhoneInput
              placeholder={t("phone")}
              value={phone}
              onChange={setPhone}
              style={{ borderRadius: 8, width: "100%", marginTop: 8 }}
            />
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 24 }}>
          <Button
            onClick={handleSaveChanges}
            loading={loading}
            type="primary"
            style={{ borderRadius: 8, padding: "0 24px" }}
          >
            {t("save")}
          </Button>
        </Row>

        <Modal
          visible={showEmailModel}
          onCancel={() => setShowEmailModel(false)}
          footer={null}
          title={t("verifyIdentity")}
          bodyStyle={{ padding: "16px 24px" }}
          style={{ borderRadius: 8 }}
        >
          <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
            <Col span={16}>
              <label style={{ fontWeight: 500 }}>Email</label>
              <Input
                value={newEmail}
                onChange={handleInputChange(setNewEmail)}
                type="email"
                placeholder="New email"
                style={{ borderRadius: 8, marginTop: 8 }}
              />
            </Col>
            <Col span={8}>
              <Button
                onClick={handleEmailChange}
                type="primary"
                style={{ borderRadius: 8, width: "100%", height: "100%", marginTop: 32 }}
              >
                {t("sendVerificationCode")}
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
            <Col span={16}>
              <label style={{ fontWeight: 500 }}>{t("verificationCode")}</label>
              <Input
                value={verify}
                onChange={handleInputChange(setVerify)}
                placeholder={t("verificationCode")}
                style={{ borderRadius: 8, marginTop: 8 }}
              />
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 24 }}>
            <Button
              onClick={() => setShowEmailModel(false)}
              style={{ marginRight: 8, borderRadius: 8 }}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={handleVerification}
              type="primary"
              style={{ borderRadius: 8 }}
            >
              {t("verify")}
            </Button>
          </Row>
        </Modal>
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
}

export default Organization;
