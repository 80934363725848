import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";
import { SuccessfulRequest } from "../../modals/confirmationModal";
import { useChangeCourierPasswordMutation } from "../../../stateManagement/apis/accountsApi";
import { stringErrorsHandler } from "../../../api/ErrorNotifier";

export default function CourierChangePassword ({ show, courierId, onHide }) {
  const lang = useLanguage();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [changeCourierPassword, { isLoading: loading }] = useChangeCourierPasswordMutation();

  async function submit (data) {
    const res = await changeCourierPassword({ courierId, ...data });
    if (res.data) {
      setSuccess(true);
      setTimeout(() => setSuccess(false), 1300);

      setTimeout(() => onHide?.(), 1000);
    } else if (res.error?.data) {
      setErrors([stringErrorsHandler(res.error.data)]);
    } else {
      setErrors([t("somethingWentWrong")]);
    }
  }

  return (
        <>
            <SuccessfulRequest
                show={success}
                title={
                    lang === "ar" ? "تحديث معلومات المستخدم" : "Update User Info"
                }
                body={
                    lang === "ar"
                      ? "تم تحديث المعلومات بنجاح"
                      : "This user has been updated successfully"
                }
            />
            <Modal
                dir={lang === "ar" ? "rtl" : "ltr"}
                show={show && courierId && !success}
                onHide={onHide}
            >
                <div style={{ padding: "20px" }}>
                    <h3>{t("changeCourierPassword")}</h3>
                    <Form layout={"vertical"} onFinish={submit}>
                        <Form.Item
                            label={t("newPassword")}
                            name={"new_password"}
                            rules={[
                              {
                                required: true,
                                message: t("required")
                              }
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <div style={{ marginBottom: "16px" }}>
                            {
                                errors.map(err => <p className="error" key={err}>{err}</p>)
                            }
                        </div>
                        <button type={"submit"} disabled={loading} className={"confirm"}>
                            {loading ? <div style={{ height: "20px" }}><Spin/></div> : t("confirm")}
                        </button>
                    </Form>
                </div>
            </Modal>
        </>
  );
}
