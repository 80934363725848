import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { UnSuccessfulPayment } from "../modals/confirmationModal";
function PaymentFail () {
  function useQuery () {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const [show, setShow] = useState(true);
  return (
        <div>
            <UnSuccessfulPayment body={query.get("status")} setShow={setShow} show={show} />
        </div>
  );
}

export default PaymentFail;
