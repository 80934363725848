import React, { useState } from "react";
import { Button, Input, message, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import SectionedDatePicker from "../../../form/SectionedDatePicker";
import WMButton from "../../AllWorkModels/WMButton";
import SecondaryWMButton from "../../AllWorkModels/WMButton/SecondaryWMButton";
import StatusCard from "../../../layout/StatusCard";
import ReportsRepository, { ordersDetailsRequest } from "../../../../api/ReportsRepository";
import GenericForm from "../../../form/GenericForm";
import ReportsTable from "./ReportsTable";
import styles from "./CommonReportsPage.module.css";

export default function CommonReportsPage ({ formFields = [], selectedType, section = "Reports" }) {
  const [reports, setReports] = useState({});
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(undefined);
  const [openTable, setOpenTable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  // Form fields including clear, download, and submission buttons
  const fields = [
    ...formFields,
    {
      name: "file_name",
      label: t("reports.report_name"),
      placeholder: t("reports.report_name_ph"),
      component: Input
    },
    {
      name: "dates",
      label: " ",
      component: SectionedDatePicker
    },
    {
      type: "button",
      label: t("reports.generate"),
      buttonType: "primary",
      htmlType: "submit",
      loading,
      component: WMButton
    },
    {
      type: "button",
      label: t("reports.clear"),
      buttonType: "default",
      htmlType: "button",
      onClick: handleClear,
      component: SecondaryWMButton
    },
    {
      type: "button",
      label: t("reports.download"),
      buttonType: "default",
      htmlType: "button",
      loading,
      onClick: handleDownload,
      component: Button,
      show: openTable && table?.data?.length > 0
    }
  ];

  // Handle form submission
  async function handleSubmit (data) {
    setFormData(data);
    setLoading(true);
    const { success, data: reportData, error } = await ReportsRepository.get(selectedType, data);
    setLoading(false);

    if (success) {
      setReports(reportData);
      setOpenTable(false);
    } else {
      messageApi.error(error);
    }
  }

  // Handle download logic
  async function handleDownload () {
    setLoading(true);
    const { success, error } = await ReportsRepository.get(selectedType, formData, true);

    setLoading(false);
    if (success) {
      messageApi.success(t("reports.download_success"));
    } else {
      messageApi.error(error);
    }
  }

  // Handle clearing reports and resetting form
  function handleClear () {
    setReports({});
    setTable(undefined);
    setOpenTable(false);
  }

  // Fetch report table data
  async function fetchTableData (key, orders) {
    const { success, data, extra } = await ReportsRepository.getTable(key, orders, formData);

    if (success) setTable({ data, extra });
  }

  return (
    <div>
      {contextHolder}
      <Row className="mb-4">
        <Col className="titleDisplay" sm="12">
          <h2 className="pageTitle">{section}</h2>
        </Col>
      </Row>

      <GenericForm
        fields={fields}
        submitFunction={handleSubmit}
        hooks={{
          successMessage: t("reports.generate_success"),
          errorMessage: t("reports.generate_error")
        }}
      />

      {/* Render report summary and table */}
      {reports.summary && (openTable && table?.data?.length > 0
        ? (
        <ReportsTable table={table} />
          )
        : (
        <div className={styles.reportsContainer}>
          <div className={styles.reportContainer}>
            <div className={styles.paddingSection}>
              <div className={styles.reportsBody}>
                {Object.keys(reports.summary ?? {}).map((key) => (
                  <StatusCard
                    key={key}
                    onClick={() => {
                      if (ordersDetailsRequest[key]) {
                        setOpenTable(true);
                        fetchTableData(key, reports.orders);
                      }
                    }}
                    type={t(`reports.cards.${key}`)}
                    number={reports.summary[key]?.value ?? 0}
                    styleClass="statusCard"
                    background="white"
                    style={{
                      cursor: ordersDetailsRequest[key] ? "pointer" : "auto",
                      width: "220px",
                      borderLeft: `solid 20px ${reports.summary[key]?.color}`,
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)"
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
          ))}
    </div>
  );
}
