import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/form.css";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SuccessfulRequest } from "../../modals/confirmationModal";
import { clearAuthAction } from "../../../stateManagement/authActions";
import { useUpdatePasswordMutation } from "../../../stateManagement/apis/authApi";
import { stringErrorsHandler } from "../../../api/ErrorNotifier";

const UpdatePassword = () => {
  const { t } = useTranslation();

  const [oldPass, setOldPassword] = useState("");
  const [newPass, setNewPass] = useState("");
  const [err, setErr] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [updatePassword] = useUpdatePasswordMutation();

  const update = async (values) => {
    console.log(values);
    const data = {
      old_password: oldPass,
      new_password: newPass
    };

    console.log(data);
    const result = await updatePassword(data);
    if (result.data) {
      console.log(result);
      if (result.status === 200) {
        setShowConfirm(true);
        setErr("");
        setTimeout(() => {
          setShowConfirm(false);
          dispatch(clearAuthAction());
        }, 2500);
      }
    } else if (result.error?.data) {
      setErr(stringErrorsHandler(result.error.data));
    } else {
      setErr(t("somethingWentWrong"));
    }
  };
  return (
        <div>
            <Container className="px-5 py-3">
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.settings")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("security")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("updatePassword")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("security")}</h2>
                    </Col>
                </Row>

                <Row className="justify-content-center">

                    <Col className="updatePassDiv" sm={12} md={6}>
                        <Form name="change_password" onFinish={update} onFinishFailed={onFinishFailed}
                              layout="vertical">
                            <h6 className="pageTitle mb-5">{t("updatePassword")}</h6>
                            <Form.Item
                                name="oldPass"
                                rules={[
                                  {
                                    required: true,
                                    message: `${t("inputOldPassword")}`
                                  }
                                ]}
                                label={t("oldPassword")}
                            >
                                <Input.Password
                                    placeholder={t("oldPassword")}
                                    name="oldPass"
                                    value={oldPass}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                            </Form.Item>
                            {err !== "" ? <p className="error">{err}</p> : null}
                            <Form.Item
                                name="newPass"
                                rules={[
                                  {
                                    required: true,
                                    message: `${t("inputNewPassword")}`
                                  }
                                ]}
                                label={t("newPassword")}
                            >
                                <Input.Password
                                    placeholder={t("newPassword")}
                                    name="newPass"
                                    value={newPass}
                                    onChange={(e) => setNewPass(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirm"
                                label={t("confirmPasswordLabel")}
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: `${t("confirmPassword")}`
                                  },
                                  ({ getFieldValue }) => ({
                                    validator (_, value) {
                                      if (!value || getFieldValue("newPass") === value) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error(
                                                    `${t("updatePasswordError")}`
                                        )
                                      );
                                    }
                                  })
                                ]}
                            >
                                <Input.Password placeholder={t("newPassword")}/>
                            </Form.Item>
                            <button className="update-password mt-3" type="submit">
                                {t("updatePassword")}
                            </button>
                        </Form>
                    </Col>
                </Row>
            </Container>

            <SuccessfulRequest
                show={showConfirm}
                // hide={hideSuccess}
                title={t("updatePassword")}
                body={t("passwordUpdated")}
            />
        </div>
  );
};

export default UpdatePassword;
