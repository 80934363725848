import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Space,
  Card,
  Divider,
  message,
  Tooltip
} from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CheckOutlined,
  ReloadOutlined,
  RightOutlined
} from "@ant-design/icons";
import ThreeDotsMenuRS from "../layout/ThreeDotsMenuRS";
import { getReassignedSheet } from "../../stateManagement/slices/reassignedRSSlice";
import {
  CreationConfirmation,
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../modals/confirmationModal";
import { useRunSheetColumns } from "../layout/tableColumns";
import TablePro from "../tables/TablePro";
import {
  useFilterRunsheetsMutation,
  useForceAcceptRejectRunsheetMutation
} from "../../stateManagement/apis/runsheetsApi";
import { stringErrorsHandler } from "../../api/ErrorNotifier";

const { Content } = Layout;
const { Title, Text } = Typography;

const ViewAssignedRunSheets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sheets, setSheets] = useState([]);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [page, setPage] = useState(1);
  const [isUnAssigned, setUnassigned] = useState(false);
  const [isArchived, setArchived] = useState(false);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [error, setError] = useState("");

  const columns = useRunSheetColumns();
  const [filterRunsheets, { isLoading: loading }] = useFilterRunsheetsMutation();
  const [forceAcceptRejectRunsheet] = useForceAcceptRejectRunsheetMutation();

  const toggleModal = (type) => {
    setShow(type === "confirm");
    setShowSuccess(type === "success");
    setShowFailed(type === "failed");
  };

  const fetchRunSheets = async () => {
    const result = await filterRunsheets({ page, search });
    if (result.data) {
      setSheets(result.data.data);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    fetchRunSheets();
  }, [page, search, isUnAssigned, isArchived]);

  useEffect(() => {
    if (selectionModel.length > 0) {
      dispatch(getReassignedSheet(selectionModel[0]));
    }
  }, [selectionModel, dispatch]);

  const handleForceAccept = async () => {
    const runsheetIds = selectionModel.map((sheet) => sheet.id);

    const result = await forceAcceptRejectRunsheet({
      runsheets: runsheetIds,
      verdict: true
    });
    if (result.data) {
      toggleModal("success");
      fetchRunSheets();
      message.success(t("forceAcceptedSuccess"));
    } else {
      setError(
        result.error?.data ? stringErrorsHandler(result.error.data) : "An error occurred"
      );
      toggleModal("failed");
      message.error(error);
    }
  };

  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <Content style={{ padding: "24px" }}>
        <Card bordered={false} style={{ marginBottom: "24px" }}>
          <Row gutter={[16, 16]} align="middle">
            <Col span={24}>
              <Space size="middle" style={{ fontSize: "16px" }}>
                <Text strong>{t("sideMenu.runsheets")}</Text>
                <RightOutlined />
                <Text>{t("sideMenu.assinedRunsheet")}</Text>
              </Space>
            </Col>
            <Col span={24}>
              <Title level={3} style={{ margin: "16px 0" }}>
                {t("sideMenu.assinedRunsheet")}
              </Title>
            </Col>
          </Row>
        </Card>

        <Card bordered={false}>
        <Row justify="space-between" align="middle" className="mb-3">
            <Col>
            </Col>
            <Col>
              <Space>
                <Tooltip title={t("forceConfirm")}>
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={() => toggleModal("confirm")}
                    disabled={selectionModel.length === 0}
                  >
                    {t("forceConfirm")}
                  </Button>
                </Tooltip>
                <Tooltip title={t("refresh")}>
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={fetchRunSheets}
                    loading={loading}
                  />
                </Tooltip>
              </Space>
            </Col>
          </Row>

          <TablePro
            setSelectedRows={setSelectionModel}
            selectedRows={selectionModel}
            selection={selectionModel}
            loading={loading}
            emptyPlaceholder={
              <div style={{ textAlign: "center", padding: "40px 0" }}>
                <Text type="secondary">{t("archivedRsTxt1")}</Text>
                <Divider />
                <Button
                  type="primary"
                  onClick={fetchRunSheets}
                  icon={<ReloadOutlined />}
                >
                  {t("refresh")}
                </Button>
              </div>
            }
            onSearch={setSearch}
            dataSource={sheets}
            columns={[
              ...columns,
              {
                title: t("actions"),
                dataIndex: "actions",
                render: (_, row) =>
                  row.state !== "2" && (
                    <ThreeDotsMenuRS
                      link="UnassignRunsheet"
                      setUnassigned={setUnassigned}
                      data={row}
                      setArchived={setArchived}
                      refresh={fetchRunSheets}
                    />
                  )
              }
            ]}
            pagination={{
              current: page,
              total: count,
              onChange: setPage
            }}
          />
        </Card>

        <CreationConfirmation
          title={t("forceConfirm")}
          show={show}
          hide={() => toggleModal()}
          sendRequest={handleForceAccept}
          body={t("forceAcceptText")}
        />
        <SuccessfulRequest
          show={showSuccess}
          hide={() => toggleModal()}
          title={t("forceConfirm")}
          body={t("forceAcceptedSuccess")}
        />
        <UnSuccessfulRequest
          show={showFailed}
          hide={() => toggleModal()}
          title={t("forceConfirm")}
          body={error}
        />
      </Content>
    </Layout>
  );
};

export default ViewAssignedRunSheets;
