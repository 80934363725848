import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { accountCreationErrorAction } from "../actions";

const initialState = {};

const addAccountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    addAccount: (state, action) => {
      if (action.payload === "") {
        state = {};
      } else {
        Object.assign(state, action.payload);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(accountCreationErrorAction, (state, action) => {
      state.errors = { ...action.payload };
    });
  }
});

export const { addAccount } = addAccountSlice.actions;
export const useAccount = () => useSelector((state) => state.account);
export const useAccountErrors = () => useSelector((state) => state.account.errors) || "";

export default addAccountSlice.reducer;
