import React, { useState } from "react";
import "../../../../../style-sheets/form.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { notification } from "antd"; // Import Ant Design notification
import { addAccount, useAccountErrors, useAccount } from "../../../../../stateManagement/slices/addAccountSlice";
import {
  AccountConfirmationModal,
  CreationConfirmationModal
} from "../../../../../components/modals/confirmationModal";
import { accountCreationErrorAction } from "../../../../../stateManagement/actions";
import GenericForm from "../../../../../components/form/GenericForm";
import { useCourierFields } from "../../../../../hooks/useFormFields";

export default function CourierPersonalInfo ({ value, handleChange, onBack }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [ID, setID] = useState("");
  const [password, setPassword] = useState("");
  const errors = useAccountErrors();

  const validationSchema = yup.object().shape({
    name: yup.string().required("fullnameRequired"),
    phone: yup
      .string()
      .required("phoneRequired")
      .matches(/^966\d{9}$/, "invalidPhone"),
    phone_alt: yup
      .string()
      .matches(/^966\d{9}$/, "invalidAltPhone")
      .nullable(),
    email: yup.string().email("invalidEmail").required("emailRequired"),
    vehicle: yup.string().required("vehicleRequired")
  });

  const account = useAccount();

  const renderErrorsInToast = (errors) => {
    Object.entries(errors).forEach(([field, messages]) => {
      messages.forEach((message) => {
        notification.error({
          message: `Error in ${field}`,
          description: message,
          placement: "topRight"
        });
      });
    });
  };

  const getErrors = (e) => {
    console.log(e);
    console.log(errors);
    dispatch(accountCreationErrorAction(e));

    // Display errors in Ant Design notification toasts
    if (e) {
      renderErrorsInToast(e);
    }
  };

  const showModal = () => setShow(true);
  const showConfirmModal = () => setShowConfirm(true);
  const hide = () => setShow(false);
  const hideConfirm = () => {
    setShowConfirm(false);
    navigate("/all_accounts");
  };

  const validate = (data) => {
    console.log("Data", data);
    dispatch(addAccount(data));
    if (value === 1) {
      handleChange(undefined, value + 1);
      dispatch(accountCreationErrorAction(""));
    } else showModal();
  };

  const fields = useCourierFields(onBack);

  return (
    <>
      <GenericForm
        fields={fields}
        submitFunction={validate}
        formDisabled={value === 2}
        skipDirty
        validationSchema={validationSchema}
        initialValues={value === 2 ? account : {}}
      />
      <CreationConfirmationModal
        getErrors={getErrors}
        setPassword={setPassword}
        setID={setID}
        title="accountCreation"
        show={show}
        showConfirm={showConfirmModal}
        hide={hide}
        account={account}
        onBack={onBack}
      />
      <AccountConfirmationModal
        show={showConfirm}
        hide={hideConfirm}
        id={ID}
        password={password}
        account={account}
      />
    </>
  );
}
