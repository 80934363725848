import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { useRegisterMutation } from "../../stateManagement/apis/publicSchemaApi";
import img from "../../images/signup.svg";
import {
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../modals/confirmationModal";
import { notifyErrors, stringErrorsHandler } from "../../api/ErrorNotifier";
import { BasePublicUrl } from "../../stateManagement/constants";

function compare (a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Registeration () {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [domain, setDomain] = useState("");
  const [country, setCountry] = useState({});
  const [countries2, setCountries2] = useState(null);
  const [registerApi, { isLoading: loading }] = useRegisterMutation();

  const [showFailed, setShowFailed] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    phone: Yup.string()
      .typeError("Phone is required")
      .required("Phone is required"),
    company_name: Yup.string().required("Company Name is required"),
    app_id: Yup.string().required("Domain is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    terms: Yup.boolean().oneOf(
      [true],
      "You must agree to the terms and conditions"
    )
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const changeCountry = (e) => {
    console.log(country);
    const c = countries2.find((country) => country.iso2 === e.target.value);
    setCountry({
      country: c.name,
      currency: Object.keys(c.currencies)[0],
      iso: c.iso2
    });
  };

  useEffect(() => {
    const getCountries = async () => {
      const result = await axios.get("https://restfulcountries.com/api/v1/countries", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 1941|vHVJ37RMhdnbgABlaBZlY3M9jSOWopSKD9pFbA07"
        }
      });

      const data = result.data.data;
      data.sort(compare);
      setCountries2(data);
      console.log(data);
    };
    getCountries();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(formOptions);

  const validate = async (data) => {
    setErrorArray([]);

    const result = await registerApi({ ...data, ...country });

    if (result.data) {
      gtag_report_conversion();
      setDomain(data.app_id);
      setShow(true);
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
      setErrorArray([stringErrorsHandler(result.error.data)]);
      setShowFailed(true);
    } else {
      notifyErrors(t("Failed to create account"));
      setErrorArray([t("Failed to create account")]);
      setShowFailed(true);
    }

    console.log(data);
  };

  const hide = (created) => {
    setShow(false);
    setShowFailed(false);
    localStorage.setItem("lang", "en");
    // testing
    let clientUrl;
    if (import.meta.env.VITE_PUBLIC_SUBDOMAIN && import.meta.env.VITE_PUBLIC_SUBDOMAIN !== "") {
      clientUrl = BasePublicUrl.replace(import.meta.env.VITE_PUBLIC_SUBDOMAIN, domain) + "/login/en";
    } else {
      clientUrl = `${BasePublicUrl.split("://")[0]}://${domain}.${BasePublicUrl.split("://")[1]}/login/en`;
    }

    if (created) window.location.href = clientUrl;

    // production
    // if (created) window.location.href = `https://${domain}.treecode.io/login/en`;
  };

  window.dataLayer = window.dataLayer || [];
  function gtag () {
    window.dataLayer.push(arguments);
  }

  function gtag_report_conversion (url) {
    // var callback = function () {
    //   if (typeof url != "undefined") {
    //     window.location = url;
    //   }
    // };
    gtag("js", new Date());
    gtag("config", "AW-587206414");
    gtag("event", "conversion", {
      send_to: "AW-587206414/H5QZCIjV4pADEI6egJgC"
      // event_callback: callback,
    });
    return false;
  }

  useEffect(() => {
    localStorage.setItem("lang", "en");
  }, []);
  return (
        <div className="form-background ">
            <div className="register-card">
              {countries2 && (
                <form className="text-center" onSubmit={handleSubmit(validate)}>
                    <Row>
                        <Col sm="6" md="6">
                            <p className="form-title">{t("signUp")}</p>
                            <div className="field-wrapper">
                                <i className="fas fa-lg fa-user"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("name")}
                                        placeholder="Your Name"
                                        type="text"
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.name?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i className="fas fa-envelope"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("email")}
                                        placeholder="Your Email"
                                        type="email"
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.email?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i className="fas fa-phone"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("phone")}
                                        placeholder="Your Phone"
                                        className="number-input"
                                        type="text"
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.phone?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i className="fas fa-building"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("company_name")}
                                        placeholder="Your Company Name"
                                        type="text"
                                        isInvalid={!!errors.company_name}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.company_name?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i className="fas fa-building"></i>
                                <Form.Select
                                    onChange={changeCountry}
                                    defaultValue="0"
                                    aria-label="Default select example"
                                >
                                    <option disabled value="0">
                                        Choose Your country
                                    </option>
                                    {countries2.map((country) => {
                                      return (
                                            <option
                                            key={country.iso2}
                                            value={country.iso2}>
                                                {country.name}
                                            </option>
                                      );
                                    })}
                                </Form.Select>
                            </div>
                            <div className="field-wrapper">
                                <i className="fas fa-link"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("app_id")}
                                        placeholder="Username"
                                        type="text"
                                        isInvalid={!!errors.app_id}
                                    />
                                    <InputGroup.Text id="basic-addon2">
                                        .treecode.io
                                    </InputGroup.Text>
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.app_id?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i className="fas fa-lock"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("password")}
                                        placeholder="Password"
                                        type="password"
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.password?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i className="fas fa-key"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("confirmPassword")}
                                        placeholder="Repeat your password"
                                        type="password"
                                        isInvalid={!!errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.confirmPassword?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>

                            <div className="text-center field-wrapper">
                                <input
                                    {...register("terms")}
                                    id="terms"
                                    type="checkbox"
                                />
                                <label className="ms-2" htmlFor="terms">
                                    I agree to all statements in{" "}
                                </label>
                                {/* testing */}
                                <a href="https://test.tree-code.com/en/terms">
                                    Terms of service
                                </a>

                                {/* production */}
                                {/* <a href="https://tree-code.com/en/terms">Terms of service</a> */}
                            </div>
                            <p className="error">{errors.terms?.message}</p>
                            <button
                                disabled={loading}
                                className="confirm mt-4"
                                type="submit"
                            >
                                {t("register")}
                            </button>
                        </Col>
                        <Col
                            sm="6"
                            md="6"
                            className="order-first order-md-last"
                        >
                            <img src={img} alt="Sign up photo" />
                        </Col>
                    </Row>
                </form>
              )}
              {!countries2 && (
                <div>
                  <Spin />
                </div>
              )}
            </div>
            <SuccessfulRequest
                show={show}
                title="Setup Your Account"
                body={
                    <p>
                        Your <strong>Password & Sub-Domain</strong> has been
                        created successfully
                    </p>
                }
                hide={() => hide(true)}
            />
            <UnSuccessfulRequest
                show={showFailed}
                title="Setup Your Account"
                body={errorArray[0]}
                hide={() => hide(false)}
            />
            {/* <CSSTransitionGroup  transitionName="overlay"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}> */}
            {loading
              ? (
                <div className="overlayModal">
                    <Spin
                        className="registration-spinner"
                        indicator={antIcon}
                    />
                    <p className="title fs-2">{t("orgConfigure")}</p>
                    <p className="fs-5">{t("orgAlert")}</p>
                </div>
                )
              : null}
            {/* </CSSTransitionGroup> */}
        </div>
  );
}

export default Registeration;
