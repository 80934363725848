import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Card, Space, Typography, notification, TimePicker, Spin, Input, Switch, Form as AntForm } from "antd";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import * as yup from "yup";

import {
  DndContext,
  closestCenter,
  DragOverlay,
  useDroppable
} from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import dayjs from "dayjs";
import GenericForm from "../../../form/GenericForm";
// import {
//     Form as AntForm,
//     Button,
//     Select,
//     Input,
//     Spin,
//     Collapse,
//     TimePicker,
//     Switch,
// } from "antd";
import { notifyErrors } from "../../../../api/ErrorNotifier";
import { WITHIN_UNITS, PRICING_TYPES } from "../../../../utils/beDict";
import useLanguage from "../../../../hooks/useLanguage";
import {
  useFilterStatesMutation,
  useGetOrderTypeMutation, useUpdateOrderTypeMutation, useCreateOrderTypeMutation,
  useFilterAreasMutation
} from "../../../../stateManagement/apis/treecodeApi";
import styles from "./OrderTypeDetails.module.css";

const { Content } = Layout;
const { Text, Title } = Typography;

const OrderTypeDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderTypeId } = useParams();
  const [orderType, setOrderType] = useState(null);
  const [states, setStates] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const language = useLanguage();
  const [draggedState, setDraggedState] = useState(null);
  const [filterStates, { isLoading: filterStatesLoading }] = useFilterStatesMutation();
  const [getOrderType, { isLoading: getOrderTypeLoading }] = useGetOrderTypeMutation();
  const [createOrderType, { isLoading: createOrderTypeLoading }] = useCreateOrderTypeMutation();
  const [updateOrderType, { isLoading: updateOrderTypeLoading }] = useUpdateOrderTypeMutation();
  const [getAreas, { isLoading: getAreasLoading }] = useFilterAreasMutation();
  // const [activeId, setActiveId] = useState(null);

  const orderTypeSchema = yup.object().shape({
    name: yup.string().required(),
    within_unit: yup.string().required().oneOf(Object.keys(WITHIN_UNITS), `Within unit must be one of ${Object.keys(WITHIN_UNITS).join(", ")}`),
    within_period: yup.number().required(),
    pricing_type: yup.string().required().oneOf(Object.keys(PRICING_TYPES), `Pricing type must be one of ${Object.keys(PRICING_TYPES).join(", ")}`),
    workflow_states: yup.array().of(yup.object().shape({
      state: yup.string().required(),
      sequence: yup.number().required()
    })).min(3, "At least 3 states are required"),
    restricted_areas: yup.array().of(yup.string().required()).nullable(),
    restricted_time_start: yup.string().nullable(),
    restricted_time_end: yup.string().nullable(),
    default: yup.boolean().required(),
    extra_price: yup.number().nullable(),
    reduced_price: yup.number().nullable(),
    seller_can_pick: yup.boolean().required()
  });

  const fields = [
    {
      name: "name",
      label: t("Name"),
      props: {
        placeholder: t("Enter name")
      }
    },
    {
      name: "within_unit",
      label: t("Within Unit"),
      component: "Select",
      props: {
        options: Object.entries(WITHIN_UNITS).map(([key, value]) => ({
          label: t(value),
          value: key
        }))
      }
    },
    {
      name: "within_period",
      label: t("Within Period"),
      props: {
        placeholder: t("Enter within period"),
        type: "number"
      }
    },
    {
      name: "pricing_type",
      label: t("Pricing Type"),
      component: "Select",
      props: {
        options: Object.entries(PRICING_TYPES).map(([key, value]) => ({
          label: t(value),
          value: key
        }))
      }
    },
    {
      name: "extra_price",
      label: t("Extra Price"),
      props: {
        type: "number",
        placeholder: t("Enter extra price"),
        onChange: (e, name, setValue) => {
          if (isNaN(e.target.value) || e.target.value === "") {
            console.log("Is NaN", e.target.value);
            setValue(name, null, { shouldDirty: true });
          } else {
            setValue(name, e.target.value, { shouldDirty: true });
          }
        }
      }
    },
    {
      name: "reduced_price",
      label: t("Reduced Price"),
      props: {
        type: "number",
        placeholder: t("Enter reduced price"),
        onChange: (e, name, setValue) => {
          if (isNaN(e.target.value) || e.target.value === "") {
            setValue(name, null, { shouldDirty: true });
          } else {
            setValue(name, e.target.value, { shouldDirty: true });
          }
        }
      }
    },
    {
      name: "restricted_areas",
      label: t("Restricted Areas"),
      component: "Select",
      props: {
        mode: "multiple",
        options: areas.map((area) => ({
          value: area.id,
          label: language === "ar" ? area.name_ar : area.name
        })),
        showSearch: true,
        loading: getAreasLoading,
        filterOption: (input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase()),
        optionFilterProp: "label",
        placeholder: t("Select areas to restrict"),
        allowClear: true
      }
    },
    {
      name: "restricted_time_start",
      label: t("Restricted Time Start"),
      component: TimePicker,
      props: {
        style: {
          width: "100%"
        },
        placeholder: t("Select time"),
        needConfirm: false,
        format: "HH:mm",
        allowClear: true,
        onChange: (time, name, setValue) => {
          setValue(name, time ? time.format("HH:mm") : null);
        },
        getFieldValue: (value) => (value ? dayjs(value, "HH:mm") : null)
      }
    },
    {
      name: "restricted_time_end",
      label: t("Restricted Time End"),
      component: TimePicker,
      props: {
        style: {
          width: "100%"
        },
        placeholder: t("Select time"),
        needConfirm: false,
        format: "HH:mm",
        allowClear: true,

        onChange: (time, name, setValue) => {
          setValue(name, time ? time.format("HH:mm") : null);
        },
        getFieldValue: (value) => (value ? dayjs(value, "HH:mm") : null)
      }
    },
    {
      name: "description",
      label: t("Description"),
      component: Input.TextArea,
      props: {
        rows: 4,
        placeholder: t("Enter description")
      }
    },
    {
      name: "default",
      label: t("Is Default"),
      itemProps: {
        valuePropName: "checked"
      },
      component: Switch,
      props: {
        onChange: (value, name, setValue) => {
          console.log("Set value", setValue);
          let confirmed = false;
          if (value) {
            confirmed = window.confirm(t("Are you sure you want to set this order type as default? This will remove all restricted areas, time restrictions, extra price and reduced price, are you sure?"));
          } else {
            confirmed = true;
          }
          if (confirmed) {
            setValue(name, value);
            setValue("restricted_areas", []);
            setValue("restricted_time_start", null);
            setValue("restricted_time_end", null);
            setValue("extra_price", null);
            setValue("reduced_price", null);
          } else {
            setValue(name, false);
          }
        }
      }
    },
    {
      name: "seller_can_pick",
      label: t("Seller Can Pick"),
      itemProps: {
        valuePropName: "checked"
      },
      component: Switch
    },
    {
      name: "submit_order_type",
      type: "button",
      buttonType: "primary",
      htmlType: "submit",
      label: t("Submit"),
      props: {
        loading: createOrderTypeLoading || updateOrderTypeLoading
      }
    }
  ];

  const getStates = async () => {
    const response = await filterStates({
      page_size: 1000
    });
    if (response.data) {
      setStates(response.data.data);
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors(t("somethingWentWrong"));
    }
  };

  const fetchOrderType = async () => {
    const response = await getOrderType(
      orderTypeId
    );
    if (response.data) {
      setOrderType({
        ...response.data,
        workflow_states: response.data.workflow_states.map((ws) => ({
          state: ws.state.id,
          sequence: ws.sequence,
          id: ws.id
        }))
      });
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors(t("somethingWentWrong"));
    }
  };

  const fetchAreas = async () => {
    const response = await getAreas({
      page_size: 1000
    });
    if (response.data) {
      setAreas(response.data.data);
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors(t("somethingWentWrong"));
    }
  };

  //   const handleChange = (event) => {
  //     const oldValues = orderTypeForm.getFieldValue(event.target.name);
  //     console.log("Old values", oldValues);
  //     orderTypeForm.setFieldValue(event.target.name, event.target.value);
  //     setDirty(orderTypeForm.isFieldsTouched(false));
  //     console.log("Is dirty", dirty);
  //   };

  useEffect(() => {
    console.log("Order type id", orderTypeId);
    if (orderTypeId !== "new") {
      fetchOrderType();
    } else {
      setOrderType({
        name: "",
        description: "",
        within_unit: Object.keys(WITHIN_UNITS)[0],
        within_period: null,
        pricing_type: Object.keys(PRICING_TYPES)[0],
        workflow_states: [],
        restricted_areas: [],
        restricted_time_start: null,
        restricted_time_end: null,
        default: false,
        extra_price: null,
        reduced_price: null,
        seller_can_pick: false
      });
    }
  }, [orderTypeId]);

  useEffect(() => {
    getStates();
    fetchAreas();
  }, []);

  useEffect(() => {
    if (orderType?.workflow_states?.length && states.length) {
      const initialStates = Array(states.length).fill(null);
      orderType.workflow_states.forEach((workflowState, index) => {
        const state = states.find((s) => s.id === workflowState.state);
        if (state) {
          initialStates[index] = state;
        }
      });
      setSelectedStates(initialStates);
    } else {
      setSelectedStates(Array(states.length).fill(null));
    }
  }, [orderType?.workflow_states, states]);

  const handleCreateOrderType = async (values) => {
    const response = await createOrderType(values);
    if (response.data) {
      notification.success({
        message: t("Order Type created successfully"),
        placement: "bottomRight"
      });
      navigate("/order_types");
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors(t("somethingWentWrong"));
    }
  };

  const handleUpdateOrderType = async (values) => {
    const response = await updateOrderType(
      {
        orderTypeId,
        ...values
      }
    );
    if (response.data) {
      notification.success({
        message: t("Order Type updated successfully"),
        placement: "bottomRight"
      });
      navigate("/order_types");
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors(t("somethingWentWrong"));
    }
  };

  const onFinish = async (values) => {
    console.log("Form values From onFinish", values);
    if (orderTypeId === "new") {
      await handleCreateOrderType(values);
    } else {
      await handleUpdateOrderType(values);
    }
  };

  const handleDragStart = (event) => {
    const { active } = event;
    // setActiveId(active.id);
    const state = states.find((s) => s.id === active.id);
    setDraggedState(state);
  };

  const handleDragEnd = (event, setValue) => {
    console.log("Set value From WorkflowStates", setValue);
    const { active, over } = event;

    if (!over || !active) {
      // setActiveId(null);
      setDraggedState(null);
      return;
    }
    console.log("Active", active);
    console.log("Over", over);
    // Find the dragged state from the states array
    const draggedStateItem = states.find((s) => s.id === active.id);
    console.log("Dragged state item", draggedStateItem);

    // Handle dropping to available states container
    if (over.id === "available-states") {
      const newSelectedStates = [...selectedStates];
      console.log("New selected states", newSelectedStates);
      const oldIndex = newSelectedStates.findIndex(
        (s) => s?.id === active.id
      );
      console.log("Old index", oldIndex);

      if (oldIndex !== -1) {
        // Remove the item and shift all items after it up
        newSelectedStates.splice(oldIndex, 1);
        console.log("New selected states after splice", newSelectedStates);
        newSelectedStates.push(null);
        console.log("New selected states after push", newSelectedStates);

        setSelectedStates(newSelectedStates);

        // Update form values with non-null values
        const newWorkflowStates = newSelectedStates
          .filter((s) => s !== null)
          .map((s) => s.id);

        setValue(
          "workflow_states",
          newWorkflowStates.map((id, index) => ({
            state: id,
            sequence: index + 1,
            id: orderType.workflow_states.find(
              (ws) => +ws.state === +id
            )?.id || null
          })),
          {
            shouldDirty: true
          }
        );
      }
    } else {
      const newSelectedStates = [...selectedStates];

      // If it's a new item from the left column
      if (!selectedStates.find((s) => s?.id === active.id)) {
        // Find the first null position
        const firstNullIndex = newSelectedStates.findIndex(
          (s) => s === null
        );
        if (firstNullIndex !== -1) {
          newSelectedStates[firstNullIndex] = draggedStateItem;
        }
      } else {
        const oldIndex = newSelectedStates.findIndex(
          (s) => s?.id === active.id
        );
        const newIndex = over.data?.current?.index; // Get index from the droppable data

        if (
          oldIndex !== -1 &&
                    newIndex !== undefined &&
                    newIndex !== oldIndex
        ) {
          // Swap positions
          [newSelectedStates[oldIndex], newSelectedStates[newIndex]] =
                        [
                          newSelectedStates[newIndex],
                          newSelectedStates[oldIndex]
                        ];
        }
      }

      setSelectedStates(newSelectedStates);
      setValue(
        "workflow_states",
        newSelectedStates.filter(Boolean).map((s, index) => ({
          state: s.id,
          sequence: index + 1,
          id: orderType.workflow_states.find(
            (ws) => +ws.state === +s.id
          )?.id || null
        })),
        {
          shouldDirty: true
        }
      );
    }

    // setActiveId(null);
    setDraggedState(null);
  };

  return (
    <Layout style={{ backgroundColor: "transparent" }}>
    <Content style={{ padding: "24px" }}>
      <Card bordered={false} style={{ marginBottom: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Space size="middle" style={{ fontSize: "16px" }}>
              <Text strong>{t("sideMenu.orderTypes")}</Text>
              <RightOutlined />
              <Text>{t("sideMenu.orderTypeDetails")}</Text>
            </Space>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
                <Title level={3} style={{ margin: "16px 0" }}>
                  {orderTypeId === "new" ? t("Create Order Type") : t("Edit Order Type")}
                </Title>
            </Row>
          </Col>
        </Row>
      </Card>

      <Card bordered={false}>
            {(getOrderTypeLoading || filterStatesLoading || getAreasLoading || !orderType) && (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "50vh" }}
                >
                    <Spin />
                </div>
            )}
            {!getOrderTypeLoading && !filterStatesLoading && !getAreasLoading && orderType && (
                    <Row>
                        <Col sm="12">
                            <GenericForm
                            fields={fields}
                            validationSchema={orderTypeSchema}
                            submitFunction={onFinish}
                            initialValues={orderType}
                            >
                                <WorkflowStates
                                    handleDragEnd={handleDragEnd}
                                    handleDragStart={handleDragStart}
                                    orderType={orderType}
                                    states={states}
                                    selectedStates={selectedStates}
                                    language={language}
                                    draggedState={draggedState}
                                    setSelectedStates={setSelectedStates}
                                />
                            </GenericForm>
                        </Col>
                    </Row>
            )}
        </Card>
        </Content>
    </Layout>
  );
};

const DroppableSlot = ({
  index,
  state,
  language,
  isDragging,
  selectedStates,
  setSelectedStates,
  setValue,
  orderType
}) => {
  const { t } = useTranslation();
  const { setNodeRef } = useDroppable({
    id: `slot-${index}`,
    data: {
      type: "slot",
      index
    }
  });

  const handleRemove = () => {
    const newSelectedStates = [...selectedStates];
    // Remove the item and shift all items after it up
    newSelectedStates.splice(index, 1);
    newSelectedStates.push(null);

    setSelectedStates(newSelectedStates);

    const newWorkflowStates = newSelectedStates
      .filter((s) => s !== null)
      .map((s, index) => ({
        state: s.id,
        sequence: index + 1,
        id: orderType.workflow_states.find(
          (ws) => +ws.state === +s.id
        )?.id || null
      }));

    setValue("workflow_states", newWorkflowStates, {
      shouldDirty: true
    });
  };

  // Only show slots that have items or the next empty slot
  const isNextEmptySlot = index === selectedStates.filter(Boolean).length;
  if (!state && !isNextEmptySlot) return null;

  return (
        <div
            ref={setNodeRef}
            className={`${styles.dropSlot} ${
                isDragging ? styles.dropTarget : ""
            }`}
            style={{
              backgroundColor: state?.color || "#fff"
            }}
        >
            {state
              ? (
                <div className={styles.slotContent}>
                    <DraggableState state={state} language={language} />
                    <button
                        className={styles.removeButton}
                        onClick={handleRemove}
                        type="button"
                    >
                        ×
                    </button>
                </div>
                )
              : (
                <span className={styles.dropPlaceholder}>
                    {t("Drop state here")}
                </span>
                )}
        </div>
  );
};

const DraggableState = ({ state, language }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
          id: state.id,
          data: {
            type: "state",
            state
          }
        });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
    touchAction: "none",
    backgroundColor: state?.accent_color ?? "#fff"
  };

  return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={styles.draggableState}
            draggable={true}
        >
            {language === "ar" ? state.title_ar : state.title}
        </div>
  );
};

const AvailableStatesContainer = ({ children, draggedState }) => {
  const { t } = useTranslation();
  const { setNodeRef, isOver } = useDroppable({
    id: "available-states",
    data: {
      type: "container",
      accepts: ["state"]
    }
  });

  return (
        <div
            ref={setNodeRef}
            className={`${styles.statesContainer} ${
                isOver ? styles.dropTarget : ""
            }`}
            id="available-states"
            data-type="available-states"
        >
            <h4>{t("Available States")}</h4>
            <div className={styles.statesWrapper}>{children}</div>
        </div>
  );
};

const DraggableStates = ({ children, ...props }) => {
  const onDragEnd = (e) => {
    props.onDragEnd(e, props.setValue);
  };

  useEffect(() => {
    console.log("DraggableStates Props", props);
  }, [props]);
  return (
    <DndContext
    collisionDetection={props.collisionDetection}
    onDragStart={props.onDragStart}
    onDragEnd={onDragEnd}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          ...child.props,
          onDragEnd,
          setValue: props.setValue
        });
      })}
    </DndContext>
  );
};

export default OrderTypeDetails;

const WorkflowStates = ({ handleDragEnd, handleDragStart, setValue, orderType, states, selectedStates, language, draggedState, setSelectedStates }) => {
  const { t } = useTranslation();
  return (
    <Row className="mt-4">
        <Col sm={24}>
            <h3>{t("Workflow States")}</h3>
            <AntForm.Item
                name="workflow_states"
            >
                <DraggableStates
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    setValue={setValue}
                >
                    <Row gutter={[64, 64]}>
                        <Col sm={12}>
                            <AvailableStatesContainer
                                draggedState={draggedState}
                            >
                                {states.map((state) => {
                                  const isSelected =
                                        selectedStates.some(
                                          (s) =>
                                            s?.id ===
                                                state.id
                                        );
                                  if (!isSelected) {
                                    return (
                                            <DraggableState
                                                key={
                                                    state.id
                                                }
                                                state={
                                                    state
                                                }
                                                language={
                                                    language
                                                }
                                            />
                                    );
                                  }
                                  return null;
                                })}
                            </AvailableStatesContainer>
                        </Col>

                        <Col sm={12}>
                            <div
                                className={
                                    styles.statesContainer
                                }
                            >
                                <h4>
                                    {t(
                                      "Selected States Order"
                                    )}
                                </h4>
                                {Array(states.length)
                                  .fill(null)
                                  .map((_, index) => (
                                        <DroppableSlot
                                            key={`slot-${index}`}
                                            index={index}
                                            state={
                                                selectedStates[
                                                  index
                                                ]
                                            }
                                            language={
                                                language
                                            }
                                            isDragging={
                                                !!draggedState
                                            }
                                            selectedStates={
                                                selectedStates
                                            }
                                            setSelectedStates={
                                                setSelectedStates
                                            }
                                            orderType={
                                                orderType
                                            }
                                            setValue={setValue}
                                        />
                                  ))}
                            </div>
                        </Col>
                    </Row>
                    <DragOverlay>
                        {draggedState && (
                            <div
                                className={
                                    styles.draggableState
                                }
                            >
                                {language === "ar"
                                  ? draggedState.title_ar
                                  : draggedState.title}
                            </div>
                        )}
                    </DragOverlay>
                </DraggableStates>
            </AntForm.Item>
        </Col>
    </Row>

  );
};
