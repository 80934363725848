import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Progress, Button, Col, Row, Typography, Card, Space, Modal, Spin } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import TicketScanRepository from "../data";
import scannerListener from "../domain/scanner-listener";
import useLanguage from "../../../../hooks/useLanguage";
import TicketsTable from "./TicketsTable";
import ScanProgress from "./ScanProgress";
import Header from "./Header";

const { Title, Text } = Typography;

function TicketScanSession () {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = useLanguage();

  const [runSheet, setRunSheet] = useState({ found: true, data: { details: {}, tickets: [] } });
  const [ticketsStates, setTicketsStates] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingEndSession, setLoadingEndSession] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    fetchActiveRunSheet().then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!runSheet.data.tickets.length) return;
    const cancel = scannerListener(handleTicketScan);
    return () => cancel();
  }, [runSheet.data.tickets.length]);

  async function fetchActiveRunSheet () {
    const res = await TicketScanRepository.getActiveRunSheet(id);
    if (res.success) setRunSheet(res.data);
  }

  async function checkTicketAvailability (ticket) {
    const isOrderType = ticket.length === 10 && ticket.startsWith("1");
    if (isOrderType) {
      const isOrderFound = runSheet.data.tickets.some(it => it.order.waybill === ticket);
      return [isOrderFound, "order"];
    }
    const ticketFound = runSheet.data.tickets.find(it => it.id.toString() === ticket);
    return [ticketFound, "ticket"];
  }

  function markTicketAsScanned (ticketIds) {
    const idsArray = Array.isArray(ticketIds) ? ticketIds : [ticketIds];
    setTicketsStates((prev) => {
      const updatedStates = {
        ...prev,
        ...Object.fromEntries(
          idsArray.map((id) => [id, { state: TicketScanRepository.scanStates.scanned }])
        )
      };
      console.log("Updated Tickets States:", updatedStates);
      return updatedStates;
    });
  }

  function handleTicketScan (ticketIds) {
    console.log(ticketIds);
    const idsArray = Array.isArray(ticketIds) ? ticketIds : [ticketIds];
    idsArray.forEach((ticket) => {
      checkTicketAvailability(ticket).then(([isFound, scanType]) => {
        if (isFound) {
          const ticketIdsToMark = scanType === "order"
            ? runSheet.data.tickets.filter(it => it.order.waybill === ticket).map(it => it.id)
            : [ticket];
          markTicketAsScanned(ticketIdsToMark);
          setSelectedTickets((prev) => [...new Set([...prev, ...ticketIdsToMark])]);
        }
      });
    });
  }

  async function confirmScan () {
    setLoadingEndSession(true);
    const numericId = Number(id);

    if (isNaN(numericId)) {
      setError(t("invalidId")); // Ensure a string
      setLoadingEndSession(false);
      return;
    }

    const receivedTickets = Object.entries(ticketsStates)
      .filter(([_, value]) => value.state === TicketScanRepository.scanStates.scanned)
      .map(([key]) => key);

    if (receivedTickets.length === 0) {
      setError(t("noTicketsProvided")); // e.g., "No tickets were provided"
      setLoadingEndSession(false);
      return;
    }

    try {
      const res = await TicketScanRepository.confirmScan(numericId, ticketsStates);

      if (res.success) {
        setSuccess(true);
      } else {
        setError(res.error || t("scanFailed")); // Ensure the error is a string
        setSuccess(false);
      }
    } catch (e) {
      setError(e.message || t("unexpectedError")); // Ensure the error is a string
      setSuccess(false);
    } finally {
      setLoadingEndSession(false);
    }
  }

  // const onFinish = data => handleTicketScan(data.ticket);

  return (
    <main style={{ padding: "24px", background: "transparent" }}>
      <Header />
      <Card style={{ margin: "16px 0", padding: "24px", borderRadius: "12px" }}>
        {/* <Title level={3} style={{ marginBottom: "16px" }}>{t("ticketScanSession")}</Title> */}

        {runSheet.found
          ? (
          <>
            <Row gutter={16} style={{ marginBottom: "24px" }}>
              {[
                { title: t("couriername"), value: runSheet.data?.courier?.user.name },
                { title: t("courierBranch"), value: runSheet.data?.courier?.branch.name },
                { title: t("courierVehicle"), value: runSheet.data?.courier?.vehicle }
              ].map((info, index) => (
                <Col span={8} key={index}>
                  <Card bordered={false} style={{ backgroundColor: "#fafafa" }}>
                    <Text type="secondary">{info.title}</Text>
                    <Title level={5} style={{ margin: 0 }}>{info.value}</Title>
                  </Card>
                </Col>
              ))}
            </Row>
{/*
            <Form layout="vertical" onFinish={onFinish} style={{ marginBottom: "16px" }}>
              <Form.Item label={t("ticket")} name="ticket">
                <Input size="large" placeholder={t("ticket")} />
              </Form.Item>
            </Form> */}

            <Space style={{ width: "100%", justifyContent: "space-between", marginBottom: "24px" }}>
  <ScanProgress ticketsStates={ticketsStates} runSheet={runSheet} />

  <Button
    type="primary"
    onClick={() => handleTicketScan(selectedTickets.map(ticket => ticket.id))}
    disabled={selectedTickets.length === 0}
    style={{
      padding: "8px 16px",
      borderRadius: "8px",
      backgroundColor: "#52c41a",
      border: "none"
    }}
  >
    {t("ticketScan")}
  </Button>

  <Button
    type="primary"
    onClick={confirmScan}
    loading={loadingEndSession}
    disabled={loadingEndSession}
    icon={loadingEndSession ? <Spin /> : null}
    style={{
      padding: "8px 16px",
      borderRadius: "8px",
      backgroundColor: "#1890ff",
      border: "none"
    }}
  >
    {loadingEndSession ? <Progress type="circle" percent={100} width={24} /> : t("endSession")}
  </Button>
</Space>

            <TicketsTable
              loading={loading}
              markTicketAsScanned={markTicketAsScanned}
              data={runSheet.data.tickets}
              ticketsStates={ticketsStates}
              selectedTickets={selectedTickets}
              setSelectedTickets={setSelectedTickets}
            />
          </>
            )
          : (
          <Row justify="center" style={{ marginTop: "32px" }}>
            <Text type="danger">{t("noRunsheet")}</Text>
          </Row>
            )}
      </Card>

      <Modal
        visible={success === true}
        title={t("ticketScan")}
        onCancel={() => {
          setSuccess(undefined);
          navigate("/");
        }}
        footer={null}
      >
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: "24px" }} />
        <p style={{ marginTop: "16px" }}>
          {language.includes("en")
            ? `You have received ${Object.keys(ticketsStates).length} tickets from courier ${runSheet.data.courier?.user?.name} with ID ${runSheet.data.courier?.id}`
            : `لقد تم استلام عدد ${Object.keys(ticketsStates).length} بوليصة من التاجر ${runSheet.data.courier?.name} برقم ${runSheet.data.courier?.id}`}
        </p>
      </Modal>

      <Modal
  visible={success === false}
  title={t("ticketScan")}
  onCancel={() => setSuccess(undefined)}
  footer={null}
>
  <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: "24px" }} />
  <p style={{ marginTop: "16px" }}>
    {typeof error === "string" ? error : (error?.message || t("unexpectedError"))}
  </p>
</Modal>

    </main>
  );
}

export default TicketScanSession;
