import React, { useEffect, useState } from "react";
import "../../style-sheets/form.css";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useLanguage from "../../hooks/useLanguage";
import { useSelectedSeller } from "../../stateManagement/slices/selectedSellerSlice";
import { useInventoryActive } from "../../stateManagement/slices/userSlice";
import { useGetProductCategoriesMutation, useCreateProductMutation } from "../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../api/ErrorNotifier";
import GenericForm from "../form/GenericForm";

import { useProductFormFields } from "../../hooks/useFormFields";

const Content = ({ hideModal, setCreated, useModal = true }) => {
  const [categories, setCategories] = useState([]);

  const { t } = useTranslation();
  const [createProduct] = useCreateProductMutation();
  const [getCategoriesApi] = useGetProductCategoriesMutation();
  const selectedSeller = useSelectedSeller();
  const inventoryActive = useInventoryActive();

  const getCategories = async () => {
    const result = await getCategoriesApi({ limit: 1000 });
    if (result.data) {
      setCategories(result.data.data);
    }
  };

  const create = async (values) => {
    const { pname, desc, price, category, skuNumber } = values;

    const data = {
      seller: selectedSeller.id,
      name: pname,
      description: desc,
      price,
      category,
      sku: skuNumber
    };

    const result = await createProduct(data);
    if (result.data) {
      setCreated(true);
      hideModal?.();
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors(t("server_error"));
    }
  };

  const lang = useLanguage();
  useEffect(() => {
    getCategories();
  }, []);

  const fields = useProductFormFields(categories, inventoryActive);

  const validationSchema = Yup.object().shape({
    pname: Yup.string().required(t("inputProductName")),
    desc: Yup.string(),
    skuNumber: inventoryActive ? Yup.string().required(t("inputSKU")) : Yup.string(),
    price: Yup.number().required(t("inputPrice")).positive(t("positivePrice")),
    category: inventoryActive ? Yup.string().required(t("inputCategory")) : Yup.string()
  });

  return (
    <div dir={lang === "ar" ? "rtl" : "ltr"}>
      <GenericForm
        fields={fields}
        submitFunction={create}
        validationSchema={validationSchema}
        hooks={{ successMessage: t("productCreated"), errorMessage: t("errorCreatingProduct") }}
      />
    </div>
  );
};

const CreateNewProduct = ({ showModal, hideModal, setCreated, useModal = true }) => {
  return (
    <div>
      {useModal
        ? (
        <Modal destroyOnClose={true} width={700} visible={showModal} onCancel={hideModal}>
          <Content setCreated={setCreated} hideModal={hideModal} useModal={useModal} />
        </Modal>
          )
        : (
        <Content setCreated={setCreated} hideModal={hideModal} useModal={useModal} />
          )}
    </div>
  );
};

export default CreateNewProduct;
