import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useRunsheetOrders } from "../../stateManagement/slices/rsOrdersSlice";
import { useAuthedUser } from "../../stateManagement/slices/userSlice";
import { useBranch } from "../../stateManagement/slices/branchSlice";

function ViewRunsheetOrders (props) {
  const { t } = useTranslation();
  const orders = useRunsheetOrders();
  const branch = useBranch();
  const cur = useAuthedUser().organization.country.currency;
  const [data] = useState([]);
  const [count] = useState(1);
  const [page] = useState(1);
  const [selectionModel, setSelectionModel] = useState([]);
  const [columns] = useState([
    {
      field: "id",
      headerName: `${t("orderID")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin tableID">#{row.row?.id}</p>
      )
    },
    {
      field: "sfee",
      headerName: `${t("shipmentFee")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin credit">{row.row?.delivery_fees}</p>
      )
    },
    // {
    //   field: "rto",
    //   headerName: `${t("returnedFee")}`,
    //   width: 220,
    //   // valueGetter: (row) => row.row?.id,
    //   renderCell: (row) => (
    //     <p className="paragraphMargin debit">EGP {row.row?.return_fees}</p>
    //   )
    // },
    {
      field: "value",
      headerName: `${t("orderValue")}`,
      width: 200,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin credit">{cur} {row.row?.sub_total}</p>
      )
    },
    {
      field: "method",
      headerName: `${t("paymentMethod")}`,
      width: 240,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.payment_method}</p>
      )
    }
  ]);
  const prevSelectionModel = useRef(selectionModel);
  // const prevOrders = useRef(orders);

  useEffect(() => {
    setSelectionModel([...selectionModel, ...prevSelectionModel.current]);
    console.log("selection model ", selectionModel);
  }, [page, data]);

  return (
    <>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.runsheets")}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("sideMenu.archived")}</span>

        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("sideMenu.archived")}</h2>
        </Col>
      </Row>

      <div className="reqTableContainer">
        <div className="tableHeader">
          <h6>{branch} {t("orders")}</h6>
        </div>

        <div className="requestTable">
          <DataGrid
            rows={orders}
            columns={columns}
            rowCount={count}
            pagination

          />
        </div>
      </div>
    </>
  );
}

export default ViewRunsheetOrders;
