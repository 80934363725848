import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import loginImg from "../../images/forgetPass.png";
import img2 from "../../images/passReset.jpg";
import logoSeller from "../../images/logoSeller.png";
import logoAdmin from "../../images/logoAdmin.png";
import arabicLogo from "../../images/arabicLogo.png";
import logoSellerAr from "../../images/sellerLogoAr.png";
import resetSeller from "../../images/resetSeller.png";
import sellerDone from "../../images/sellerDone.png";
import "../../style-sheets/login.css";
import { BaseUrl } from "../../stateManagement/constants";
import { useCode } from "../../stateManagement/slices/codeSlice";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const code = useCode();

  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errMsg, setErrorMsg] = useState("");
  const [done, setDone] = useState(false);
  const [error, setError] = useState(null);

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirm = (e) => {
    if (e.target.value !== password) {
      setErrorMsg(`${t("updatePasswordError")}`);
    } else {
      setConfirmPass(e.target.value);
      setErrorMsg("");
    }
  };

  const submit = async () => {
    if (errMsg) return;
    const result = await axios
      .post(BaseUrl + "/code_response", {
        code,
        password
      })
      .catch((err) => {
        setError(err.response.data);
      });

    if (result) {
      setDone(true);
      setTimeout(() => {
        if (location.pathname === "/admin_reset_password") {
          navigate("/");
        } else {
          navigate("/seller-login");
        }
      }, 3000);
    }
  };

  return (
    <div>
      <div className="p-3">
        {location.pathname === "/admin_reset_password"
          ? (
          <img
            src={localStorage.getItem("lang") === "en" ? logoAdmin : arabicLogo}
            className="img-fluid"
          />
            )
          : (
          <img
            src={localStorage.getItem("lang") === "en" ? logoSeller : logoSellerAr}
            className="img-fluid"
          />
            )}
      </div>
      {!done
        ? (
        <Container className="pageContainer">
          <Row>
            <Col md="6" sm="12">
              {location.pathname === "/admin_reset_password"
                ? (
                <img src={loginImg} className="img-fluid" />
                  )
                : (
                <img src={resetSeller} className="img-fluid" />
                  )}
            </Col>
            <Col md="1" sm="12"></Col>
            <Col className="formsCol" md="4" sm="12">
              <h3 className="title">{t("enterNewPassword")}</h3>
              <p className="subTitle mt-2">{t("resetPassword")}</p>
              <Form
                name="normal_login"
                className="login-form mt-5"
                initialValues={{
                  remember: true
                }}
                onFinish={submit}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: `${t("enterNewPassword")}`
                    }
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t("newPassword")}
                    value={password}
                    name="password"
                    onChange={handlePassword}
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPass"
                  rules={[
                    {
                      required: true,
                      message: `${t("confirmPassword")}`
                    }
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t("confirmPasswordLabel")}
                    value={confirmPass}
                    name="confirmPass"
                    onChange={handlePasswordConfirm}
                  />
                </Form.Item>
                <p className="error">{errMsg}</p>
                <p className="error">{error}</p>
                <Form.Item>
                  {location.pathname === "/admin_reset_password"
                    ? (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button adminBtn"
                    >
                      {t("confirm")}
                    </Button>
                      )
                    : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button sellerBtn"
                    >
                      {t("confirm")}
                    </Button>
                      )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
          )
        : (
        <Container className="pageContainer">
          <Row>
            <Col md="6" sm="12">
              {location.pathname === "/admin_reset_password"
                ? (
                <img src={img2} className="img-fluid" />
                  )
                : (
                <img src={sellerDone} className="img-fluid" />
                  )}
            </Col>
            <Col md="1" sm="12"></Col>
            <Col className="" md="5" sm="12">
              <h2 className="title confirmMsg">{t("passwordReset")}</h2>
            </Col>
          </Row>
        </Container>
          )}
    </div>
  );
};

export default ResetPassword;
