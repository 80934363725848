import React from "react";
import { Row, Col, Card, Button, Typography, message } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { clearAuthAction } from "../../../stateManagement/authActions";
import { useUserObject } from "../../../stateManagement/slices/userSlice";
import { useDeleteMyThirdPartyMutation } from "../../../stateManagement/apis/accountsApi";
import { notifyErrors } from "../../../api/ErrorNotifier";

const { Title, Text } = Typography;

function Security () {
  const { t } = useTranslation();
  const user = useUserObject();
  const dispatch = useDispatch();
  const [deleteMyThirdParty] = useDeleteMyThirdPartyMutation();

  const handleDeleteOrg = async () => {
    if (user.role === "Third Party") {
      const response = await deleteMyThirdParty();
      if (response.error?.data) {
        notifyErrors(response.error.data);
      } else if (response.error) {
        message.error(t("error"));
      } else {
        dispatch(clearAuthAction());
      }
    }
  };

  return (
    <div style={{ padding: "24px", background: "#fff", borderRadius: 12, boxShadow: "0 4px 12px rgba(0,0,0,0.1)" }}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick rtl={false} draggable style={{ zIndex: 9999 }} />
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Text style={{ fontSize: 16, fontWeight: 500, color: "#888" }}>
            {t("sideMenu.settings")} &gt; {t("security")}
          </Text>
        </Col>
        <Col span={24}>
          <Title level={3} style={{ margin: 0, color: "#333" }}>{t("security")}</Title>
        </Col>
        <Col span={24}>
          <Card title={t("password")} bordered={false} style={{ textAlign: "center", borderRadius: 8 }}>
            <Text>{t("changePassword")}</Text>
            <div style={{ marginTop: 16 }}>
              <Button type="primary">
                <Link to="/update_password">{t("updatePassword")}</Link>
              </Button>
            </div>
          </Card>
        </Col>
        {user.role !== "Seller" && (
          <Col span={24}>
            <Card title={t("deleteOrg")} bordered={false} style={{ textAlign: "center", borderRadius: 8 }}>
              <Text>{t("deleteOrgTitle")}</Text>
              <div style={{ marginTop: 16 }}>
                <Button type="primary" danger onClick={handleDeleteOrg}>
                  {t("deleteOrg")}
                </Button>
              </div>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default Security;
