import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import useQuery from "../../../../../hooks/useQuery";
import ProductInfoForm from "../../../../common/tickets/create/ui/productInfoForm";
import OrderInfoForm from "../../../../common/tickets/create/ui/orderInfoForm";
import { addOrder, useOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import {
  getSeller

} from "../../../../../stateManagement/slices/selectedSellerSlice";
import { getTicketsReturned } from "../../../../../stateManagement/slices/returnedTicketsSlice";
import SellerCustomerInfo from "./SellerCustomerInfo";

function TabPanel (props) {
  const { children, value, index, onClick, ...other } = props;

  const handlePanelClick = (event) => {
    // Check if the click target is the TabPanel itself and not a child element
    if (event.target === event.currentTarget) {
      onClick(index);
    }
  };

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            onClick={handlePanelClick} // Attach the click handler
            style={{ cursor: "pointer" }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function SellerCreateParcel () {
  const { t } = useTranslation();
  const { query, appendQuery } = useQuery();
  const value = +(query.page ?? 0);
  const handleChange = (event, newValue) => {
    appendQuery("page", parseInt(newValue));
  };
  const location = useLocation();
  const archivedData = location.state?.archivedData;

  const order = useOrder();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log({ order });
  }, [order]);

  useEffect(() => {
    appendQuery("page", 0);
    dispatch(addOrder(""));
    dispatch(getTicketsReturned(""));
    dispatch(getSeller(""));
  }, []);

  return (
        <>
            <Tabs
                value={value}
                onChange={handleChange} // Ensure Tabs component handles tab changes
                aria-label="basic tabs example"
                className="admin"
            >
                <Tab label={t("customerInfo")} {...a11yProps(0)} />
                <Tab label={t("productInfo")} {...a11yProps(1)} />
                <Tab label={t("orderInfo")} {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={value} index={0} onClick={handleChange}>
                <SellerCustomerInfo value={value} handleChange={handleChange} archivedData={archivedData} />
            </TabPanel>
            <TabPanel value={value} index={1} onClick={handleChange}>
                <ProductInfoForm value={value} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={2} onClick={handleChange}>
                <OrderInfoForm value={value} handleChange={handleChange} />
            </TabPanel>
        </>
  );
}

export default SellerCreateParcel;
