import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "antd";
import { toast, ToastContainer } from "react-toastify";

import {
  useTicketsColumns
} from "../../layout/tableColumns";
import {
  CreationConfirmation,
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../../modals/confirmationModal";
import {
  getSeller,
  useSelectedSeller
} from "../../../stateManagement/slices/selectedSellerSlice";
import { getTicketsReturned } from "../../../stateManagement/slices/returnedTicketsSlice";
import { addOrder, useOrder } from "../../../stateManagement/slices/addOrderSlice";
import useLanguage from "../../../hooks/useLanguage";
import TablePro from "../../tables/TablePro";
import { useUserObject } from "../../../stateManagement/slices/userSlice";
import { useUpdateOrderMutation, useCreateReturnOrderMutation } from "../../../stateManagement/apis/ordersApi";
import { notifyErrors, stringErrorsHandler } from "../../../api/ErrorNotifier";

function ReviewReturnRequest ({ value, handleChange, returnedObj }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [ID, setID] = useState("");
  const [oldShipping, setOldShipping] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [ordersDetails, setOrdersDetails] = useState(undefined);
  const [updateOrder] = useUpdateOrderMutation();
  const [createReturnOrder] = useCreateReturnOrderMutation();

  const selectedSeller = useSelectedSeller();
  // const returnedTicketsID = useSelector(
  //   (state) => state.returnedTickets.returnedTickets
  // );
  const sellerAddress = useOrder().seller_address;
  const user = useUserObject();

  const selectedSellerDispatch = useDispatch();
  const selectedAddressDispatch = useDispatch();
  const selectedTicketsDispatch = useDispatch();
  const ticketsColumns = useTicketsColumns();
  useEffect(() => {
    console.log(returnedObj);
  }, []);
  const sendRequest = () => {
    setShow(true);
  };

  const hide = () => {
    setShow(false);
  };
  const hideShowConfirm = () => {
    console.log("Modal closed. Navigating to /dashboard.");
    setShowConfirm(false);
    navigate("/dashboard");
  };

  const confirm = async () => {
    const orders = new Set(returnedObj.map((it) => it.order.id));
    const data = {
      tickets: returnedObj.map((it) => it.id),
      orders: [...orders],
      return_address: sellerAddress,
      old_shipping_fees: oldShipping ?? false
    };
    console.log(data);
    const result = await createReturnOrder(data);
    if (result.data) {
      setShow(false);
      console.log(result.data);
      setOrdersDetails(result.data);
      setID(result.data[0].id);
      console.log(ID);
      selectedAddressDispatch(addOrder(""));
      selectedTicketsDispatch(getTicketsReturned(""));
      selectedSellerDispatch(getSeller(""));
      setShowConfirm(true);
    } else if (result.error?.data) {
      setShowError(true);
      setError(stringErrorsHandler(result.error.data));
    } else {
      setShowError(true);
      setError("Something went wrong");
    }
  };

  const { t } = useTranslation();
  const lang = useLanguage();

  async function updatePrice (id, newFees) {
    const result = await updateOrder({ orderId: id, deliveryFees: newFees });
    if (result.data) {
      toast.success(
        lang.includes("en")
          ? "Order Shipping Fees updated successfully"
          : "تم تعديل تكلفة الشحنة بنجاح"
      );
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  }

  return (
        <>
            <ToastContainer
                position={lang.includes("en") ? "bottom-right" : "bottom-left"}
            />
            <Row>
                <Col sm="12" md="6">
                    <Row className="mt-3">
                        <Col>
                            <div className="position-relative text-area-form">
                                <label className="bold-label">
                                    {t("orderID")}
                                </label>
                                <Form.Control
                                    defaultValue={
                                        returnedObj.length !== 0
                                          ? returnedObj[0].order.id
                                          : ""
                                    }
                                    disabled
                                    type="text"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="position-relative text-area-form">
                                <label className="bold-label">
                                    {t("sellername")}
                                </label>
                                <Form.Control
                                    defaultValue={selectedSeller.user?.name}
                                    disabled
                                    type="text"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="position-relative text-area-form">
                                <label className="bold-label">
                                    {t("phone")}
                                </label>
                                <Form.Control
                                    defaultValue={selectedSeller.user?.phone}
                                    disabled
                                    type="text"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Checkbox
                                checked={oldShipping}
                                onChange={(e) =>
                                  setOldShipping(e.target.checked)
                                }
                            >
                                {t("oldShippingFees")}
                            </Checkbox>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col sm="12">
                    {ordersDetails
                      ? (
                        <div>
                            <TablePro
                                title={t("createdOrders")}
                                dataSource={ordersDetails}
                                columns={[
                                  {
                                    title: t("orderId"),
                                    dataIndex: "id"
                                  },
                                  {
                                    title: t("totalProducts"),
                                    dataIndex: "sub_total"
                                  },
                                  {
                                    title: t("shipment"),
                                    dataIndex: "delivery_fees",
                                    render (_, row, i) {
                                      return (
                                                <input
                                                    type="number"
                                                    style={{
                                                      border: "1px solid gray",
                                                      width: "50px"
                                                    }}
                                                    min={0}
                                                    value={
                                                        row.cal_delivery_fees ??
                                                        row.delivery_fees
                                                    }
                                                    onChange={(e) => {
                                                      const newOrdersDetails =
                                                            [...ordersDetails];
                                                      newOrdersDetails[
                                                        i
                                                      ].cal_delivery_fees =
                                                            e.target.value;
                                                      setOrdersDetails(
                                                        newOrdersDetails
                                                      );
                                                    }}
                                                />
                                      );
                                    }
                                  },
                                  {
                                    title: t("total"),
                                    render (_, row) {
                                      const total =
                                                row.delivery_fees &&
                                                row.sub_total
                                                  ? parseInt(
                                                    row.cal_delivery_fees ??
                                                              row.delivery_fees
                                                  ) +
                                                      parseInt(row.sub_total)
                                                  : "00.00";
                                      return <>{total}</>;
                                    }
                                  },
                                  {
                                    title: t("actions"),
                                    render (_, row) {
                                      return (
                                                <button
                                                    disabled={
                                                        row.delivery_fees ===
                                                        row.cal_delivery_fees
                                                    }
                                                    onClick={() =>
                                                      updatePrice(
                                                        row.id,
                                                        row.cal_delivery_fees
                                                      )
                                                    }
                                                    className={"tableID"}
                                                    style={{
                                                      background:
                                                            "transparent",
                                                      border: "none",
                                                      outline: "transparent"
                                                    }}
                                                >
                                                    {t("update")}
                                                </button>
                                      );
                                    }
                                  }
                                ]}
                            />
                        </div>
                        )
                      : (
                        <TablePro
                            rowHeight="80"
                            dataSource={returnedObj}
                            columns={ticketsColumns}
                        />
                        )}
                </Col>
            </Row>
            {/* <OrderSummary /> */}
            <Row>
                {ordersDetails
                  ? (
                    <Col className="text-end buttons-margin mb-3" sm="12">
                        <button
                            onClick={() => setShowConfirm(true)}
                            className={
                                user.role === "Seller"
                                  ? "confitmSeller"
                                  : "confirm"
                            }
                        >
                            {t("confirm")}
                        </button>
                    </Col>
                    )
                  : (
                    <Col className="text-end buttons-margin mb-3" sm="12">
                        <button
                            className="cancel"
                            type={"button"}
                            onClick={() => handleChange(null, value - 1)}
                        >
                            {t("previous")}
                        </button>
                        <button
                            onClick={sendRequest}
                            className={
                                user.role === "Seller"
                                  ? "confitmSeller"
                                  : "confirm"
                            }
                        >
                            {t("sendRequest")}
                        </button>
                    </Col>
                    )}
            </Row>
            <CreationConfirmation
                sendRequest={confirm}
                show={show}
                hide={hide}
                body={
                    lang === "en"
                      ? `Are you sure that you want to Return ${returnedObj.length} Tickets From Order ID #${returnedObj[0].order.id}`
                      : `هل انت متأكد انك تريد إرجاع عدد ${returnedObj.length} بوليصة من الطلب رقم #${returnedObj[0].order.id}`
                }
                title={t("returnConfirmation")}
            />
            <SuccessfulRequest
                show={showConfirm}
                hide={hideShowConfirm}
                title={t("returnConfirmation")}
                body={t("returnConfirmText")}
            />
            <UnSuccessfulRequest
                title={t("returnConfirmation")}
                show={showError}
                body={error}
                hide={() => setShowError(false)}
            />
        </>
  );
}

export default ReviewReturnRequest;
