import React from "react";
import { Row, Col, Switch, Typography, Card, Space } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  SettingOutlined,
  LockOutlined,
  SafetyOutlined,
  GlobalOutlined,
  QuestionCircleOutlined,
  BgColorsOutlined,
  EnvironmentOutlined,
  AppstoreOutlined,
  ClusterOutlined,
  ToolOutlined,
  CommentOutlined,
  TruckOutlined,
  BarcodeOutlined

} from "@ant-design/icons";
import { useInventoryActivateSwitchMutation } from "../../../stateManagement/apis/inventoryApi";
import { useCourierWalletActivateSwitchMutation } from "../../../stateManagement/apis/treecodeApi";
import { refreshOrg, useUserObject, useCourierWalletAvailable } from "../../../stateManagement/slices/userSlice";
import { notifyErrors } from "../../../api/ErrorNotifier";

const { Title, Text } = Typography;

function Settings () {
  const user = useUserObject();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [inventoryActivateSwitch] = useInventoryActivateSwitchMutation();
  const [courierWalletActivateSwitch] = useCourierWalletActivateSwitchMutation();
  const courierWalletAvailable = useCourierWalletAvailable();

  const handleSwitchInventory = async () => {
    const res = await inventoryActivateSwitch();
    if (res.data) {
      dispatch(refreshOrg(res.data));
    } else if (res.error?.data) {
      notifyErrors(res.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };

  const handleSwitchCourierWallet = async () => {
    const res = await courierWalletActivateSwitch();
    if (res.data) {
      dispatch(refreshOrg(res.data));
    } else if (res.error?.data) {
      notifyErrors(res.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };

  const settingsCards = user.role === "Organization Admin"
    ? [
        { icon: SettingOutlined, title: t("organization"), link: "/organization", description: t("orgSubTitle") },
        { icon: BgColorsOutlined, title: t("systemTheme"), link: "/theme", description: t("systemThemeSubtitle") },
        { icon: LockOutlined, title: t("security"), link: "/security", description: t("securitySubTitle") },
        { icon: SafetyOutlined, title: t("permissions"), link: "/permissions", description: t("permissionsSubTiltle") },
        { icon: GlobalOutlined, title: t("lang"), link: "/lang_settings", description: t("langSubTitle") },
        { icon: QuestionCircleOutlined, title: t("FAQs"), link: "https://lace-booth-6cf.notion.site/3f5ed58a721443b4935f4fe5ad9b69eb", description: t("FAQsSubTitle") },
        { icon: ClusterOutlined, title: t("sideMenu.branches"), link: "/branches", description: t("branchesSubtitle") },
        { icon: EnvironmentOutlined, title: t("areas"), link: "/areas", description: t("areasSubTiltle") },
        { icon: AppstoreOutlined, title: t("dimensions"), link: "/dimensions", description: t("dimensionsSubTiltle") },
        { icon: ToolOutlined, title: t("integrationApps"), link: "/plugins", description: t("integrationApps") },
        { icon: CommentOutlined, title: t("Comments"), link: "/comments", description: t("Comments_description") },
        {
          icon: TruckOutlined,
          title: `${t("Order Types")}`,
          link: "/order_types",
          description: `${t(
              "Manage Order Types And Their Prices"
          )}`
        },
        {
          icon: BarcodeOutlined,
          title: `${t("AWB Label Type")}`,
          link: "/awb_label_type",
          description: `${t("Configure AWB Label Type")}`
        }
      ]
    : [
        { icon: LockOutlined, title: t("security"), link: "/security", description: t("securitySubTitle") },
        { icon: GlobalOutlined, title: t("lang"), link: "/lang_settings", description: t("langSubTitle") },
        { icon: QuestionCircleOutlined, title: t("FAQs"), link: "https://lace-booth-6cf.notion.site/3f5ed58a721443b4935f4fe5ad9b69eb", description: t("FAQsSubTitle") }
      ];

  return (
    <div style={{ padding: "24px" }}>
      <Title level={3} style={{ marginBottom: "24px" }}>{t("sideMenu.settings")}</Title>
      <Row gutter={[24, 24]}>
        {settingsCards.map(card => (
          <Col key={card.title} xs={24} sm={12} md={8} lg={6}>
            {card.link.startsWith("http")
              ? (
              <a
                href={card.link}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Card
                  hoverable
                  className="d-flex justify-content-center align-items-center"
                  style={{ borderRadius: "8px", transition: "transform 0.3s" }}
                  onMouseEnter={e => (e.currentTarget.style.transform = "scale(1.02)")}
                  onMouseLeave={e => (e.currentTarget.style.transform = "scale(1)")}
                >
                  <Space direction="vertical" align="center" size="middle">
                    <card.icon style={{ fontSize: "24px", color: "#57be6c" }} />
                    <Title level={4} >{card.title}</Title>
                    <Text type="secondary">{card.description}</Text>
                  </Space>
                </Card>
              </a>
                )
              : (
              <Link to={card.link} style={{ textDecoration: "none" }}>
                <Card
                  hoverable
                  className="d-flex justify-content-center align-items-center"
                  style={{ borderRadius: "8px", transition: "transform 0.3s" }}
                  onMouseEnter={e => (e.currentTarget.style.transform = "scale(1.02)")}
                  onMouseLeave={e => (e.currentTarget.style.transform = "scale(1)")}
                >
                  <Space direction="vertical" align="center" size="middle">
                    <card.icon style={{ fontSize: "24px", color: "#57be6c" }} />
                    <Title level={4} >{card.title}</Title>
                    <Text type="secondary">{card.description}</Text>
                  </Space>
                </Card>
              </Link>
                )}
          </Col>
        ))}
        {user?.role_id === "1" && (
          <>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card
              className="d-flex justify-content-center align-items-center"
              hoverable
              style={{ borderRadius: "8px", transition: "transform 0.3s" }}
              onMouseEnter={e => (e.currentTarget.style.transform = "scale(1.02)")}
              onMouseLeave={e => (e.currentTarget.style.transform = "scale(1)")}
            >
              <Space direction="vertical" align="center" size="middle">
                <SettingOutlined style={{ fontSize: "24px", color: "#57be6c" }} />
                <Title level={4}>{t("Inventory")}</Title>
                <Switch
                  checked={user?.org?.inventory_active}
                  onChange={handleSwitchInventory}
                  checkedChildren={t("Active")}
                  unCheckedChildren={t("Inactive")}
                  style={{ backgroundColor: user?.org?.inventory_active ? "#57be6c" : undefined }}

                />
              </Space>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card
              className="d-flex justify-content-center align-items-center"
              hoverable
              style={{ borderRadius: "8px", transition: "transform 0.3s" }}
              onMouseEnter={e => (e.currentTarget.style.transform = "scale(1.02)")}
              onMouseLeave={e => (e.currentTarget.style.transform = "scale(1)")}
            >
              <Space direction="vertical" align="center" size="middle">
                <SettingOutlined style={{ fontSize: "24px", color: "#57be6c" }} />
                <Title level={4}>{t("Courier Wallet")}</Title>
                <Switch
                  checked={courierWalletAvailable}
                  onChange={handleSwitchCourierWallet}
                  checkedChildren={t("Active")}
                  unCheckedChildren={t("Inactive")}
                />
              </Space>
            </Card>
          </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default Settings;
