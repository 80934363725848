import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Spin } from "antd";
import PageHeader from "../../layout/PageHeader";
import { ThirdPartyKeys } from "../ThirdPartyInfo";
import { useGetMyThirdPartyMutation } from "../../../stateManagement/apis/accountsApi";
import { notifyErrors } from "../../../api/ErrorNotifier";

const ThirdPartyKeysPage = () => {
  const { t } = useTranslation();
  const [thirdPartyKeys, setThirdPartyKeys] = useState({});
  const [getMyThirdParty, { isLoading: loading }] = useGetMyThirdPartyMutation();

  const getThirdPartyKeys = async () => {
    try {
      const response = await getMyThirdParty();
      if (response.data) {
        setThirdPartyKeys(response.data);
      } else if (response.error?.data) {
        notifyErrors(response.error.data);
      } else {
        throw new Error("Error fetching third party keys");
      }
    } catch (err) {
      console.log(err);
      notifyErrors(err.message);
    }
  };

  useEffect(() => {
    getThirdPartyKeys();
  }, []);

  return (
        <div>

            <div>
                <PageHeader title={t("My Third Party Keys")} />
            </div>
            <div className="d-flex flex-column align-items-start justify-content-start gap-3">
                {
                    loading
                      ? <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "50vh" }}>
                        <Spin size="large" />
                    </div>
                      : <ThirdPartyKeys keys={thirdPartyKeys} setKeys={setThirdPartyKeys} thirdPartyId={thirdPartyKeys.id} />

                }
            </div>
        </div>
  );
};

export default ThirdPartyKeysPage;
