import React, {
  useState,
  useEffect,
  useMemo
} from "react";
import { Layout, Row, Col, Collapse, Select, DatePicker, Input, Form, Button } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClearOutlined } from "@ant-design/icons";
import Tour from "reactour";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import {
  ConfirmationModal,
  DeleteModal,
  UnSuccessfulRequest,
  PaymentAlert,
  UnConfirmedRequestResultModal
} from "../../../../components/modals/confirmationModal";
import { plans, usePlan } from "../../../../stateManagement/slices/planSlice";
import useLanguage from "../../../../hooks/useLanguage";
import { useUserObject } from "../../../../stateManagement/slices/userSlice";
import { useGetSummaryMutation, useGetOrderTypesMutation } from "../../../../stateManagement/apis/treecodeApi";
import { useGetMySubscriptionsMutation } from "../../../../stateManagement/apis/subscriptionApi";
import { useFilterSellersMutation } from "../../../../stateManagement/apis/accountsApi";
import { useFilterOrdersMutation, useCreateOrderMutation, useDeleteOrderMutation } from "../../../../stateManagement/apis/ordersApi";
import { useFilterTicketsMutation, useExportTicketsSheetMutation } from "../../../../stateManagement/apis/ticketsApi";
import CardsHeader from "./CardsHeader";
import DashboardRequestsTable from "./DashboardRequestsTable";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

// const lang = localStorage.getItem("lang");
const steps = [
  {
    selector: ".first-step",
    content: "This is my first Step"
  }
  // ...
];
const { Content } = Layout;

const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);

const Dashboard = React.memo(() => {
  const lang = useLanguage();
  const navigate = useNavigate();
  const user = useUserObject();
  const planDispatch = useDispatch();
  const { t } = useTranslation();
  const plan = usePlan();
  const [planConsumptionApi] = useGetMySubscriptionsMutation();
  const [showPaymentAlert, setPaymentAlert] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [status, setStatus] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState();
  const [createOrderApi] = useCreateOrderMutation();
  const [deleteOrderApi] = useDeleteOrderMutation();
  const [filterOrdersApi, { isLoading: loadingOrders }] = useFilterOrdersMutation();
  const [filterTicketsApi, { isLoading: loadingTickets }] = useFilterTicketsMutation();
  const [getSummaryApi, { isLoading: loading }] = useGetSummaryMutation();
  const [nextState, setNextState] = useState({
    name: lang === "en" ? "Choose State" : "اختر الحالة",
    id: "",
    background: ""
  });
  const [summaryFilters, setSummaryFilters] = useState({
  });
  const [cityFilter, setCityFilter] = useState("");
  const [tableSelection, setTableSelection] = useState([]);
  const [tableSelectedObjects, setTableSelectedObjects] = useState([]);
  const defaultState = useMemo(
    () => ({
      count: 1,
      state: {
        color: "#CEE1FF",
        accent_color: "#00000",
        description: null,
        id: 1,
        title: "Pickup Request",
        headers: [],
        title_ar: "طلب استلام"
      }
    }),
    []
  );
  const [currentState, setCurrentState] = useState(defaultState);
  const currentStateTitle = lang.includes("en")
    ? currentState?.state?.title
    : currentState?.state?.title_ar;
  const unConfirmedStates = {
    pickup: 6,
    return: 10,
    replace: 11
  };
  const isUnConfirmedRequest = useMemo(
    () =>
      Object.values(unConfirmedStates).includes(
        +(currentState.state?.type ?? 6)
      ),
    [currentState.state?.type]
  );

  const [tableLoading, setTableLoading] = useState(false);
  const [isUnConfirmedRequestResult, setIsUnConfirmedRequestResult] =
  useState({});
  const [showIsUnConfirmedRequestResult, setShowIsUnConfirmedRequestResult] =
  useState(false);

  useEffect(() => {
    if (!showConfirm && !showDelete && !showIsUnConfirmedRequestResult) {
      getSummary();
      selectState(currentState);
      if (isUnConfirmedRequest) {
        getPlans();
      }
      fetchTableData();
    }
  }, [showConfirm, showDelete, pageSize, summaryFilters, showIsUnConfirmedRequestResult]);

  useEffect(() => {
    if (loadingOrders || loadingTickets) {
      setTableLoading(true);
    } else {
      setTableLoading(false);
    }
  }, [loadingOrders, loadingTickets]);

  useEffect(() => {
    console.log("SUMMARY FILTERS", summaryFilters);
  }, [summaryFilters]);

  const getPlans = async () => {
    const result = await planConsumptionApi();

    if (result.data) {
      console.log(result.data);
      planDispatch(plans(result.data));
    }
  };

  const getSummary = async () => {
    const result = await getSummaryApi({
      ...summaryFilters
    });
    if (result.data) {
      console.log(result.data);
      setStatus(result.data);
      console.log(result.data);
    }
    // setLoading(false);
  };
  const showConfirmModal = () => {
    setShowConfirm(true);
  };
  const showDeleteModal = () => {
    setShowDelete(true);
  };
  const hideModal = () => {
    setShowConfirm(false);
    setShowDelete(false);
    setNextState({
      ...nextState,
      name: lang === "en" ? "Choose State" : "اختر الحالة",
      id: ""
    });
  };

  const paymentMethodMapping = {
    [t("AllOrders")]: "",
    [t("online")]: "1",
    [t("cash")]: "2",
    [t("pos")]: "3"
  };

  const showIsUnConfirmedRequestResultModal = () => {
    setShowIsUnConfirmedRequestResult(true);
  };

  const hideIsUnConfirmedRequestResultModal = () => {
    setShowIsUnConfirmedRequestResult(false);
  };

  async function fetchTableData (payment_method) {
    // setTableLoading(true);
    let result;

    const stateType = +currentState.state.type;

    const extractOrderType = () => {
      console.log({ stateType });

      if (stateType === unConfirmedStates.pickup) return 1;
      else if (stateType === unConfirmedStates.return) return 2;
      else if (stateType === unConfirmedStates.replace) return 3;
      else return undefined;
    };
    if (isUnConfirmedRequest) {
      result = await filterOrdersApi({
        search,
        page,
        page_size: pageSize,
        state: 3,
        order_type: extractOrderType(),
        ...summaryFilters,
        seller_address__area__city: cityFilter,
        payment_method
      });
    } else {
      result = await filterTicketsApi({
        search,
        page,
        page_size: pageSize,
        state: currentState.state?.id,
        ...summaryFilters,
        order__seller_address__area__city: cityFilter,
        only_orders: true

      });
    }
    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
    // setTableLoading(false);
  }

  function selectState (newState) {
    setCurrentState(newState);
    setTableSelection([]);
    setPage(1);
    setSearch(undefined);
  }

  useEffect(() => {
    fetchTableData();
  }, [currentState, search, page, pageSize, cityFilter]);

  const changeState = (e, v) => {
    console.log(e);
    console.log(v);
    console.log({ v });
    if (v.type === unConfirmedStates.return) {
      navigate("/return_ticket");
    } else {
      setNextState({
        ...nextState,
        name: e,
        id: v.id,
        background: v.style.background
      });
      showConfirmModal();
    }
  };

  useEffect(() => {
    if (plan?.orders_count >= plan?.orders_remaining) {
      setPaymentAlert(true);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const closeTour = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Layout style={{ backgroundColor: "transparent" }}>
        <Content>
          <DashboardFilterExpandable filters={summaryFilters} setFilters={setSummaryFilters} />
          <CardsHeader
            selectState={selectState}
            summaryFilters={summaryFilters}
            cityFilter={cityFilter}
            plan={plan}
            currentStateTitle={currentStateTitle}
            loading={loading}
            status={status}
          />

          <Row justify="end">
            {["Seller", "Third Party"].includes(user.role)
              ? null
              : (
              <Col span={24} style={{ textAlign: "end", marginInlineEnd: "85px" }}>
                {[1, 7, 10].includes(+(currentState.state?.id ?? 1)) && (
                  <Button
                    disabled={tableSelection?.length === 0}
                    onClick={showDeleteModal}
                    className="cancel"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      padding: "12px 24px",
                      fontSize: "16px",
                      borderRadius: "10px",
                      transition: "background-color 0.3s ease"
                    }}
                  >
                    {t("cancelOrder")}
                  </Button>
                )}

                {!isUnConfirmedRequest
                  ? (
                      currentState.state.accepted_states?.length > 0
                        ? (
                    <Select
                      placeholder={t("changeStatus")}
                      className="changeStatusSelect"
                      onChange={changeState}
                      value={nextState.name}
                      disabled={tableSelection.length === 0}
                      style={{ width: "200px" }}
                    >
                      {status
                        .filter((state) => state.state.id === 12)
                        .map((state) => (
                          <Select.Option
                            key={state.state.id}
                            style={{
                              background: state.state.color,
                              marginBottom: "5px",
                              padding: "5px",
                              color: "black",
                              fontWeight: "bold"
                            }}
                            value={lang === "en" ? state.state.title : state.state.title_ar}
                            id={state.state.id}
                          >
                            {lang === "en" ? state.state.title : state.state.title_ar}
                          </Select.Option>
                        ))}
                      {currentState.state.accepted_states.map((state) => (
                        <Select.Option
                          key={state.id}
                          style={{
                            background: state.color,
                            marginBottom: "5px",
                            padding: "5px",
                            color: "black",
                            fontWeight: "bold"
                          }}
                          value={lang === "en" ? state.title : state.title_ar}
                          id={state.id}
                        >
                          {lang === "en" ? state.title : state.title_ar}
                        </Select.Option>
                      ))}
                    </Select>
                          )
                        : null
                    )
                  : (
                  <Button
                    disabled={tableSelection.length === 0}
                    onClick={showConfirmModal}
                    className="confirm"
                    style={{
                      backgroundColor: "#57be6c",
                      color: "white",
                      border: "none",
                      padding: "12px 24px",
                      fontSize: "16px",
                      borderRadius: "10px",
                      transition: "background-color 0.3s ease"
                    }}
                  >
                    {t("confirm")}
                  </Button>
                    )}
              </Col>
                )}
          </Row>

          <Row>
            <Col span={24}>
              <DashboardRequestsTable
                onDelete={deleteOrderApi}
                onDuplicate={createOrderApi}
                filterHandler={fetchTableData}
                tabsFilters ={paymentMethodMapping}
                currentState={currentState}
                isUnConfirmedRequest={isUnConfirmedRequest}
                data={data}
                onSearch={setSearch}
                currentStateTitle={currentStateTitle}
                onRefresh={() => fetchTableData()}
                loading={tableLoading}
                selected={tableSelection}
                cityFilter={cityFilter}
                setCityFilter={setCityFilter}
                setSelected={setTableSelection}
                tableSelecedObjects={tableSelectedObjects}
                setTableSelecedObjects={setTableSelectedObjects}
                pagination={{
                  current: page,
                  total: count,
                  pageSize,
                  defaultPageSize: 10,
                  pageSizeOptions: [10, 30, 50, 80, 100],
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  }
                }}
              />
            </Col>
          </Row>

          <ConfirmationModal
            isUnConfirmedRequest={isUnConfirmedRequest}
            currentState={currentState}
            nextState={nextState}
            selectedCount={tableSelection.length}
            selectedOrders={tableSelection}
            show={showConfirm}
            onError={setError}
            hide={hideModal}
            setIsUnConfirmedRequestResult={
              setIsUnConfirmedRequestResult
          }
          showIsUnConfirmedRequestResult={
              showIsUnConfirmedRequestResultModal
          }
          />

        <UnConfirmedRequestResultModal
                show={showIsUnConfirmedRequestResult}
                hide={hideIsUnConfirmedRequestResultModal}
                successOrders={isUnConfirmedRequestResult.success}
                failedOrders={isUnConfirmedRequestResult.failed}
                canChangeOrderType={
                    isUnConfirmedRequestResult.may_change_order_type
                }
                errors={isUnConfirmedRequestResult.errors}
                onError={setError}
            />

          <DeleteModal
            currentState={currentState}
            selectedOrders={tableSelection}
            nextState={nextState}
            selectedCount={tableSelection.length}
            show={showDelete}
            hide={hideModal}
          />

          <PaymentAlert setShow={setPaymentAlert} show={showPaymentAlert} />

          <UnSuccessfulRequest
            show={error}
            hide={() => setError(undefined)}
            title={t("changeStatusConfirmation")}
            body={error}
          />
        </Content>
      </Layout>

      <Tour
        steps={steps}
        isOpen={isOpen}
        onRequestClose={closeTour}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
    </div>
  );
});
export default Dashboard;

const DashboardFilterExpandable = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [storeSearch, setStoreSearch] = useState("");
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const [orderSearch, setOrderSearch] = useState(filters?.order__id ?? filters?.id);
  const [waybillSearch, setWaybillSearch] = useState(filters?.order__waybill ?? filters?.waybill);
  const [filterSellersApi, { isLoading: loadingStores }] = useFilterSellersMutation();
  const [getOrderTypesApi, { isLoading: loadingOrderTypes }] = useGetOrderTypesMutation();
  const [dashboardFilters, setDashboardFilters] = useState({});

  const getStores = async () => {
    const result = await filterSellersApi({
      page_size: 20,
      search: storeSearch
    });
    if (result.data) {
      setStores(result.data.data);
    }
  };

  const getOrderTypes = async () => {
    console.log("GET ORDER TYPES");
    const result = await getOrderTypesApi({
      page: 1,
      limit: 1000
    });
    if (result.data) {
      setOrderTypes(result.data.data);
    }
  };

  useEffect(() => {
    getStores();
  }, [storeSearch]);

  useEffect(() => {
    getOrderTypes();
  }, []);

  const handleStoreSearch = (search) => {
    setStoreSearch(search);
  };

  const handleStoreSelect = (stores) => {
    console.log("HANDLE STORE SELECT", stores);
    setSelectedStores(stores);
  };

  const handleStoreClear = () => {
    setSelectedStores([]);
  };

  const handleFilterChange = (filtersKeys, value) => {
    console.log("HANDLE FILTER CHANGE", filtersKeys, value);
    const newFilters = { ...filters };
    filtersKeys.forEach((filter) => {
      newFilters[filter] = value ?? undefined;
    });
    const newDashboardFilters = { ...dashboardFilters };
    filtersKeys.forEach((filter) => {
      newDashboardFilters[filter] = value ?? undefined;
    });
    console.log("NEW FILTERS", newFilters, "FILTERS", filtersKeys);
    setFilters(newFilters);
    setDashboardFilters(newDashboardFilters);
  };

  const handleOrderTypeSelect = (value) => {
    setSelectedOrderTypes(value);
  };

  useEffect(() => {
    handleFilterChange(
      ["seller__in", "order__seller__in"],
      selectedStores.join(",")
    );
  }, [selectedStores]);

  useEffect(() => {
    handleFilterChange(
      ["order_type_instance__in", "order__order_type_instance__in"],
      selectedOrderTypes.join(",")
    );
  }, [selectedOrderTypes]);

  const clearFilters = () => {
    setFilters({});
    setSelectedStores([]);
    setDashboardFilters({});
    setStoreSearch("");
    setOrderSearch("");
    setWaybillSearch("");
  };

  return (
    <Collapse>
      <Panel header={t("DashboardFilters")} key="1" extra={<GenerateExtra clearFilters={clearFilters} dashboardFilters={filters} />}>
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item label={t("reports.store_name")}>
                <Select
                  showSearch
                  loading={loadingStores}
                  value={selectedStores}
                  onChange={handleStoreSelect}
                  onSearch={handleStoreSearch}
                  onClear={handleStoreClear}
                  allowClear
                  filterOption={false}
                  placeholder={t("reports.store_name_ph")}
                  mode="multiple"
                  options={stores.map(store => ({ label: store.store_name, value: store.id }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t("orderType")}>
                <Select
                  value={selectedOrderTypes}
                  allowClear
                  mode="multiple"
                  loading={loadingOrderTypes}
                  showSearch
                  optionFilterProp="label"
                  placeholder={t("Select Order Type")}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleOrderTypeSelect}
                  options={orderTypes.map(type => ({ label: type.name, value: type.id }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t("dateTime")}>
                <RangePicker
                  className="customDateRangePicker"
                  onChange={(dates, dateStrings) => handleFilterChange(["create_date__date__range"], dateStrings.join(","))}
                  placeholder={[t("startDate"), t("endDate")]}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t("orderId")}>
                <Input
                  onKeyDown={(e) => e.key === "Enter" && handleFilterChange(["order__id", "id"], orderSearch)}
                  onChange={(e) => setOrderSearch(e.target.value)}
                  value={orderSearch}
                  placeholder={t("searchOrderID")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t("WaybillNumber")}>
                <Input
                  onKeyDown={(e) => e.key === "Enter" && handleFilterChange(["order__waybill", "waybill"], waybillSearch)}
                  onChange={(e) => setWaybillSearch(e.target.value)}
                  value={waybillSearch}
                  placeholder={t("SearchWaybillNumber")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};

const GenerateExtra = ({ clearFilters, dashboardFilters }) => {
  const { t } = useTranslation();
  const [exportTicketsSheetApi, { isLoading: loadingExportTickets }] = useExportTicketsSheetMutation();
  const exportAllTickets = async () => {
    console.log("EXPORT ALL TICKETS");
    await exportTicketsSheetApi({
      fileName: "all_tickets.xlsx",
      export: true,
      ...dashboardFilters
    });
  };

  return (
      <>
          {/* <style>
              {`
          .ant-btn-primary {
              background-color: #27c456;
              border-color: #27c456;

          }
          .ant-btn-primary:hover {
              background-color: #27c456;
              border-color: #27c456;
              opacity: 0.8;
          }
          .ant-btn-primary:active {
              background-color: #27c456;
              border-color: #27c456;
          }
          `}
          </style> */}
          <div className="d-flex justify-content-center align-items-center gap-2">
              <Button
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    exportAllTickets();
                  }}
                  loading={loadingExportTickets}
                  disabled={loadingExportTickets}
              >
                  {t("Export All Tickets")}

              </Button>
              <ClearOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    clearFilters();
                  }}
                  style={{ color: "#27c456" }}
              />
          </div>
      </>
  );
};
