import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

// Define initial state with defaults
const initialState = {
  ids: [],
  date: null,
  orders: []
};

const postponedOrdersSlice = createSlice({
  name: "postponedOrders",
  initialState,
  reducers: {
    postponedIds: (state, action) => {
      state.ids = action.payload.map((order) => order.id); // Extract IDs on state update
    },
    postponeDate: (state, action) => {
      state.date = action.payload;
    },
    postponedOrders: (state, action) => {
      state.orders = action.payload;
    }
  }
});

// Export actions
export const { postponedIds, postponeDate, postponedOrders } = postponedOrdersSlice.actions;

// Export selectors with safe defaults
export const usePostponedOrders = () => useSelector((state) => state.postponed?.orders || []);
export const usePostponedIds = () => useSelector((state) => state.postponed?.ids || []);
export const usePostponeDate = () => useSelector((state) => state.postponed?.date || null);

// Export the reducer
export default postponedOrdersSlice.reducer;
