import formattedError from "../../../../api/errorFormatter";
import { runsheetsApi } from "../../../../stateManagement/apis/runsheetsApi";
import { accountsApi } from "../../../../stateManagement/apis/accountsApi";
import store from "../../../../stateManagement/store";

const scanStates = {
  unknown: "unknown",
  scanned: "scanned"
};
const TicketScanRepository = {
  scanStates,
  getCouriers: async (search) => {
    try {
      const res = await store.dispatch(accountsApi.endpoints.filterCouriers.initiate({
        search,
        page_size: 10000,
        unavailable: true
      })
      );

      if (res.data) {
        return {
          success: true,
          data: res.data.data
        };
      } else formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  getActiveRunSheet: async (courierId) => {
    try {
      const resActiveRunSheet = await store.dispatch(runsheetsApi.endpoints.courierActiveRunsheet.initiate(courierId));
      const resRunSheetData = await store.dispatch(
        resActiveRunSheet.data.runsheet_type === "1"
          ? runsheetsApi.endpoints.getRunsheet.initiate(resActiveRunSheet.data.runsheet_id)
          : runsheetsApi.endpoints.getInternalRunsheet.initiate(resActiveRunSheet.data.runsheet_id)
      );
      if (resActiveRunSheet.data && resRunSheetData.data) {
        return {
          success: true,
          data: {
            found: true,
            data: {
              id: resRunSheetData.data.id,
              details: {
                count: resActiveRunSheet.data.count,
                runsheet_type:
                                    resRunSheetData.data.runsheet_type
              },
              courier: resRunSheetData.data.courier,
              tickets: resActiveRunSheet.data.data?.map((it) => ({
                ...it,
                scan_status: scanStates.unknown
              }))
            }
          }
        };
      } else if (resActiveRunSheet.error) {
        return formattedError(resActiveRunSheet, true);
      } else if (resRunSheetData.error) {
        return formattedError(resRunSheetData, true);
      }
    } catch (e) {
      console.log({ e });
      if (e?.response?.data === "Runsheet not found") {
        return {
          success: true,
          data: {
            found: false
          }
        };
      }
      return formattedError(e);
    }
  },
  confirmScan: async (id, ticketStates) => {
    try {
      const receivedTickets = Object.entries(ticketStates)
        .filter(([_, value]) => {
          console.log({
            value: value.state,
            scanStates: scanStates.scanned
          });
          return value.state === scanStates.scanned;
        })
        .map(([key]) => key);

      const res = await store.dispatch(
        runsheetsApi.endpoints.hubReceivedOrders.initiate([id, { tickets_returned: receivedTickets }])
      );

      console.log("Payload Sent to API:", { tickets_returned: receivedTickets });

      if (res.data) {
        return {
          success: true
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  }
};

export default TicketScanRepository;
