import React, { useMemo, useState } from "react";
import { Collapse, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Icon } from "@shopify/polaris";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { permissionGranted } from "../../../global-state/routes/generate-routes";
import { useUserObject, useExpiredSubscription } from "../../../stateManagement/slices/userSlice";

export default function RouteGroup ({ meta = {}, paths = [], pathsGroup = {}, isSeller = false }) {
  const [open, setOpen] = useState(false);
  const user = useUserObject();
  const expiredSubscription = useExpiredSubscription();

  const authorizedPaths = useMemo(() => (
    isSeller
      ? paths
      : paths.filter(path =>
        permissionGranted(pathsGroup[path]?.rules, user, expiredSubscription)
      )
  ), [paths, pathsGroup, isSeller, user, expiredSubscription]);

  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  return (
    authorizedPaths.length > 0 && (
      <>
        <ListItem onClick={handleToggle} button>
          <ListItemIcon>
            <Icon source={meta.icon} color="base" />
          </ListItemIcon>
          <ListItemText primary={meta.title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List className="subList" component="div" disablePadding>
            {authorizedPaths.map(path => (
              <NavLink
                key={path}
                to={{ pathname: path, hash: pathsGroup[path]?.navigateProps?.status }}
                state= {pathsGroup[path]?.navigateProps}
                className={({ isActive }) => isActive ? (isSeller ? "sellerActiveLink" : "activeLink") : undefined}
              >
                <ListItem button>
                  <ListItemText primary={pathsGroup[path]?.title} />
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Collapse>
      </>
    )
  );
}
