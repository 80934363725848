import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select, Button } from "antd";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import {
  PaginatedSelect,
  Radio
} from "../../../../../components/form/TextInputFields";
import "react-phone-input-2/lib/style.css";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addOrder, useOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { useSelectedSeller, useSelectedClient } from "../../../../../stateManagement/slices/selectedSellerSlice";
import { useUserObject } from "../../../../../stateManagement/slices/userSlice";
import CreateNewCustomer from "../../../../../components/modals/CreateNewCustomer";
import useLanguage from "../../../../../hooks/useLanguage";
import { setInventoryOrderSellerData } from "../../../../../stateManagement/slices/inventoryOrderSlice";
import { useFilterClientsMutation } from "../../../../../stateManagement/apis/treecodeApi";
import { useGetSellerAddressMutation } from "../../../../../stateManagement/apis/accountsApi";
import { stringErrorsHandler } from "../../../../../api/ErrorNotifier";

export default function SellerCustomerInfo ({ value, handleChange, inventory }) {
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [clientsPage, setClientsPage] = useState(1);
  const [showCreate, setShowCreate] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [created, setCreated] = useState(true);
  const { t } = useTranslation();
  const [filterClients] = useFilterClientsMutation();
  const [getSellerAddressApi, { isLoading: isLoadingSellerAddress }] = useGetSellerAddressMutation();
  const [loadMoreSellerAddress, setLoadMoreSellerAddress] = useState(false);
  const [pageSellerAddress, setPageSellerAddress] = useState(1);
  const [searchSellerAddress, setSearchSellerAddress] = useState("");

  const adminSelectedAddress = useOrder()?.seller_address;

  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(undefined);

  const selectedClient = useSelectedClient();

  const selectedSeller = useSelectedSeller();

  const user = useUserObject();
  const openCreateModal = () => {
    setShowCreate(true);
    setCreated(false);
  };

  const hideCreateModal = (e) => {
    setShowCreate(false);
  };

  useEffect(() => {
    console.log(value);
  }, []);

  const getClients = async () => {
    const result = await filterClients(
      { page: clientsPage || 1, limit: 10, seller: user.role === "Seller" ? user.user?.user_id : selectedSeller.id }
    );

    if (result.data) {
      setClients([...clients, ...result.data.data]);
      if (result.data.next === null) {
        setLoadMore(false);
      } else {
        setClientsPage(clientsPage + 1);
        setLoadMore(true);
      }
    }
  };

  const onScroll = async (event) => {
    const target = event.target;
    if (
      loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      await getClients();
    }
  };

  const changeAddress = (value) => {
    setSelectedAddress(value);
  };
  const validate = (data) => {
    console.log(`Form Data = ${data}`);

    if (data.client === undefined && selectedClient === "" && !inventory) {
      console.log("not valid");
      setErrMsg("This field is required");
    } else {
      if (inventory) {
        dispatch(
          setInventoryOrderSellerData({
            seller: user.user.user_id,
            seller_address: selectedAddress || adminSelectedAddress
          })
        );
      } else {
        dispatch(
          addOrder({
            ...data,
            seller_address: selectedAddress || adminSelectedAddress
          })
        );
      }
      console.log(" valid");
      setErrMsg("");
      handleChange(null, parseInt(value) + 1);
    }
  };

  const previous = () => {
    handleChange(null, parseInt(value) - 1);
  };
  const getSellerAddresses = async (onlyNew = false) => {
    const result = await getSellerAddressApi({
      sellerAddressId: user.user?.user_id,
      page: onlyNew ? 1 : pageSellerAddress,
      search: searchSellerAddress
    });
    if (result.data) {
      const newSellerAddresses = onlyNew ? result.data.data : [...addresses, ...result.data.data];
      setAddresses(newSellerAddresses);
      setLoadMoreSellerAddress(result.data.next !== null);
      setPageSellerAddress(result.data.next ? result.data.page + 1 : result.data.page);
    } else if (result.error?.data) {
      setLoadMoreSellerAddress(false);
      setErrMsg(stringErrorsHandler(result.error.data));
    } else {
      setLoadMoreSellerAddress(false);
      setErrMsg(t("Error fetching sellers"));
    }
  };

  const handleSearchSellerAddress = debounce((value) => {
    setSearchSellerAddress(value); // Update search term
  }, 300);

  const onScrollSellerAddress = debounce(async (event) => {
    const target = event.target;
    if (loadMoreSellerAddress && (target.scrollTop + target.offsetHeight >= target.scrollHeight)) {
      await getSellerAddresses(); // Load more sellers when reaching the bottom
    }
  }, 200);

  useEffect(() => {
    console.log(created);
    if (created) {
      try {
        getClients();
      } catch (err) {
        console.log(err);
      }
    }
  }, [
    created,
    user
  ]);
  useEffect(() => {
    setErrMsg("");
  }, [selectedClient]);
  const lang = useLanguage();
  useEffect(() => {
    if (user.role === "Seller") getSellerAddresses();
  }, [user, searchSellerAddress]);

  return (
        <ModifiedForm submitFunction={validate}>
            {user.role === "Seller" && (
                <Row>
                    <Col sm={6}>
                        <Form.Label>{t("chooseSellerAddress")}</Form.Label>
                        <Select
                            id="seller_address"
                            style={{ width: "100%" }}
                            value={selectedAddress}
                            onSearch={handleSearchSellerAddress}
                            filterOption={false}
                            showSearch={true}
                            allowClear={true}
                            placeholder={t("Choose Seller Address")}
                            loading={isLoadingSellerAddress}
                            onScroll={onScrollSellerAddress}
                            onChange={changeAddress}
                        >
                            {
                                // return sellers[index]
                                (addresses || []).map((address) => (
                                    <Select.Option
                                        key={address.id}
                                        value={address.id}
                                    >
                                        {address.address}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>
            )}
            {!inventory && (
                <Row>
                    <Col sm="12" md="6">
                        <div className="position-relative text-area-form">
                            {/* <a
                                className={
                                    lang === "en"
                                      ? "over-select-link-en"
                                      : "over-select-link-ar"
                                }
                                onClick={openCreateModal}
                            >
                                {t("createNewCustomer")}
                            </a> */}
                            <PaginatedSelect
                                id="client"
                                title={t("fullname")}
                                disabled={
                                    !selectedAddress && !adminSelectedAddress
                                }
                                onScroll={onScroll}
                                choice={selectedClient.id}
                            >
                                {
                                    // return sellers[index]
                                    clients.map((client) => (
                                        <Select.Option
                                            key={client.id}
                                            clientinfo={client}
                                            value={client.id}
                                        >
                                            {client.name}
                                        </Select.Option>
                                    ))
                                }
                            </PaginatedSelect>
                            <p className="error">
                                {errMsg !== "" ? errMsg : null}
                            </p>

                            {/* Phone input for phone number */}
                            <Form.Label>{t("phone")}</Form.Label>
                            <PhoneInput
                                // country={"sa"}
                                value={
                                    selectedClient?.phone || ""
                                }
                                onChange={(phone) =>
                                  handleChange({
                                    target: {
                                      id: "phone1client",
                                      value: phone
                                    }
                                  })
                                }
                                inputProps={{
                                  required: true
                                }}
                              inputStyle={{
                                width: "100%",
                                height: "50px",
                                padding: "10px 12px",
                                direction: lang === "ar" ? "rtl" : "ltr", // Adjust text direction based on language
                                borderColor: "#d9d9d9",
                                borderRadius: "10px"
                              }}
                                containerClass="phone-input-container"
                                inputClass="phone-input"
                                buttonStyle={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  position: "absolute",
                                  right: lang === "ar" ? "initial" : "0px", // Align to the right for LTR
                                  left: lang === "ar" ? "0px" : "initial" // Align to the left for RTL
                                }}
                                buttonClass="country-dropdown" enableSearch={true}

                            />

                            {/* Phone input for alternate phone number */}
                            <Form.Label>{t("altPhone")}</Form.Label>
                            <PhoneInput
                                // country={"sa"}
                                value={
                                    selectedClient?.phone_alt || ""
                                }
                                onChange={(phone) =>
                                  handleChange({
                                    target: {
                                      id: "phone2client",
                                      value: phone
                                    }
                                  })
                                }
                                inputStyle={{
                                  width: "100%",
                                  height: "50px",
                                  padding: "10px 12px",
                                  direction: lang === "ar" ? "rtl" : "ltr", // Adjust text direction based on language
                                  borderColor: "#d9d9d9",
                                  borderRadius: "10px"
                                }}
                                containerClass="phone-input-container"
                                inputClass="phone-input"
                                buttonStyle={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  position: "absolute",
                                  right: lang === "ar" ? "initial" : "0px", // Align to the right for LTR
                                  left: lang === "ar" ? "0px" : "initial" // Align to the left for RTL
                                }}
                                buttonClass="country-dropdown" enableSearch={true}
                            />

                        </div>
                    </Col>
                    <Col sm="12" md="6">
                        <div className="addressCard">
                            <label>{t("chooseCustomerAddress")} </label>
                            {Object.keys(selectedClient ?? {}).length > 0
                              ? selectedClient?.client_addresses?.map(
                                (address) => {
                                  return (
                                              <>
                                                  <Radio
                                                      value={address.id}
                                                      id="client_address"
                                                      validation={{
                                                        required: true
                                                      }}
                                                      name="address"
                                                      className="radio-btn"
                                                      txt={address.address}
                                                  />
                                              </>
                                  );
                                }
                              )
                              : null}
                        </div>
                    </Col>
                </Row>
            )}
            <Row className="mt-4">
                <Col className="text-end" sm="12">
                    <Button className="secondary-btn" onClick={previous}>
                        {t("previous")}
                    </Button>
                    <Button className="secondary-btn" type="dashed" onClick={openCreateModal}>{t("createNewCustomer")}</Button>
                    <Button
                        // onClick={(e) => handleChange(e, value + 1)}
                        className="primary-btn"
                        type="primary"
                        htmlType="submit"
                    >
                      {t("proceed")}
                      </Button>
                </Col>
            </Row>
            <CreateNewCustomer
                setCreated={setCreated}
                showModal={showCreate}
                hideModal={hideCreateModal}
            />
            <style>{`
        .primary-btn {
          background-color: #57be6c;
          color: white;
          border: none;
          padding: 12px 24px;
          font-size: 16px;
          border-radius: 10px;
          transition: background-color 0.3s ease;
        }

        .primary-btn:hover {
          background-color: #4aa55d;
        }

        .secondary-btn {
          background-color: transparent;
          color: #57be6c;
          border: 2px solid #57be6c;
          padding: 12px 24px;
          margin-inline-end: 13px;
          font-size: 16px;
          border-radius: 10px;
          transition: background-color 0.3s ease, color 0.3s ease;
        }

        .secondary-btn:hover {
          background-color: #57be6c;
          color: white;
        }

        .buttons-margin {
          margin-top: 20px;
        }
      `}</style>
        </ModifiedForm>
  );
}
