import React from "react";
import useLanguage from "../hooks/useLanguage";
import SeeMoreIcon from "../images/see_more_arrow.svg";

export default function SeeMoreButton () {
  const lang = useLanguage();
  return (
        <div style={{ whiteSpace: "nowrap" }}>
            {lang === "ar"
              ? (
                <span>
                    اعرف اكتر{" "}
                    <span style={{ rotate: "180deg" }}>
                        <img src={SeeMoreIcon} alt="see more" />
                    </span>
                </span>
                )
              : (
                <span>
                    See More <img src={SeeMoreIcon} alt="see more" />
                </span>
                )}
        </div>
  );
}
