import React from "react";
import { useTranslation } from "react-i18next";
import TablePro from "../../../tables/TablePro";
import styles from "./TicketChainDetails.module.css";

// function TabPanel (props) {
//   const { children, value, index, ...other } = props;
//   return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//   );
// }

// function a11yProps (index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`
//   };
// }

export default function TicketChainDetails ({ data }) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("id"),
      dataIndex: "ticket_id",
      key: "id",
      render: (_, row, index) => {
        const neededData = row.courier?.length > 0 ? data.courier : data.seller;
        const firstIndex = neededData.findIndex((r) => r.ticket_id === row.ticket_id);
        if (firstIndex === index) {
          return {
            children: row.ticket_id,
            props: {
              rowSpan: neededData.filter((r) => r.ticket_id === row.ticket_id).length
            }
          };
        } else {
          return {
            props: {
              rowSpan: 0
            }
          };
        }
      }
    },
    {
      title: t("actionStage"),
      key: "state",
      render: (value, row, index) => t(row.state.split("changed ")[1])
    },
    {
      title: t("Transaction Effect"),
      key: "transaction_effect",
      dataIndex: "type",
      render: (_, row) => t(_)
    },
    {
      title: t("totalAmount"),
      dataIndex: "price",
      key: "price"
    },
    {
      title: t("date"),
      dataIndex: "trans_date",
      key: "date",
      render: (_, row) => row.trans_date?.split("T")[0] ?? "-"
    },
    {
      title: t("with_shipment"),
      dataIndex: "with_shipment",
      key: "with_shipment",
      render: (_, row) => (row.with_shipment ? t("yes") : t("no"))
    },
    {
      title: t("settled"),
      dataIndex: "setteld",
      key: "settled",
      render: (_, row) => (row.setteld ? t("yes") : t("no"))
    }
  ];
  return (
        <div className={styles.container} style={{ marginTop: "36px" }}>
            {/* <OrderChainHeader isTicket /> */}

            <div>

                <div>
                    {Object.entries({
                      ...data
                    }).map(([key, group], i) => (
                      group.length > 0 &&
                        <div key={key} className="d-flex flex-column justify-content-start align-items-start mt-3">
                          <h3>{t(key)}{" "}{t("Transactions")}</h3>
                            <TablePro
                                minHeight="auto"
                                columns={key === "courier"
                                  ? [...columns, {
                                      title: t("courier"),
                                      key: "courier",
                                      dataIndex: "courier"
                                    }]
                                  : columns}
                                dataSource={group}
                                disableHeaders={i !== 0}
                                pagination={false}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
  );
}
