import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useRunsheetOrders } from "../../../stateManagement/slices/rsOrdersSlice";
import { useSelectedSeller } from "../../../stateManagement/slices/selectedSellerSlice";
import { useAuthedUser } from "../../../stateManagement/slices/userSlice";

function PaidTickets (props) {
  const { t } = useTranslation();

  const tickets = useRunsheetOrders();
  const seller = useSelectedSeller();
  const cur = useAuthedUser().organization.country.currency;

  const [count] = useState(1);
  const [selectionModel] = useState([]);
  const [columns] = useState([
    {
      field: "id",
      headerName: "Ticket ID",
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin tableID">#{row.row?.id}</p>
    },
    {
      field: "sfee",
      headerName: "Ship. Fee",
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin credit">{row.row?.delivery_fees}</p>
    },
    {
      field: "rto",
      headerName: "RTO Fee",
      width: 180,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin debit">{cur} {row.row?.return_fees}</p>
    },
    {
      field: "value",
      headerName: "Ticket Value",
      width: 200,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin credit">{cur} {row.row?.price}</p>
    },
    {
      field: "method",
      headerName: "Payment Method",
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin">{row.row?.order.payment_method}</p>
    }

  ]);

  useEffect(() => {
  }, []);
  return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallets")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("paidRequests")}</span>

                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallets")}</h2>
                </Col>
            </Row>

            <div className="reqTableContainer">
                <div className="tableHeader">
                    <h6>{seller.user.name} Orders</h6>

                </div>

                <div dir='ltr' className="requestTable">
                    <DataGrid
                        rows={tickets}
                        columns={columns}
                        rowCount={count}
                        pagination
                        selectionModel={selectionModel}

                    />
                </div>
            </div>
        </>
  );
}

export default PaidTickets;
