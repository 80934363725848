import React, { useState } from "react";
import { Row, Col, Tooltip, Spin, Card, Typography } from "antd";
import { ToastContainer } from "react-toastify";
import {
  FileExcelOutlined, InfoCircleOutlined

} from "@ant-design/icons";
import { useExportOrdersSheetMutation } from "../../stateManagement/apis/ordersApi";
import { useExportTicketsSheetMutation } from "../../stateManagement/apis/ticketsApi";
import { notifyErrors } from "../../api/ErrorNotifier";
import "../../style-sheets/cards.css";

export const StatusCard = ({
  id,
  styleClass,
  style,
  background,
  number,
  type,
  selectedCard,
  description,
  onClick,
  summaryFilters,
  cityFilter,
  inventory
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [currentDownload, setCurrentDownload] = useState(null);
  const [exportOrdersSheet, { isLoading: loadingOrders }] = useExportOrdersSheetMutation();
  const [exportTicketsSheet, { isLoading: loadingTickets }] = useExportTicketsSheetMutation();

  const handleDownloadSheet = async (id) => {
    setCurrentDownload(id);
    const exportMethod = [1, 2, 3].includes(id)
      ? exportOrdersSheet
      : exportTicketsSheet;
    const res = await exportMethod({
      state: ![1, 2, 3].includes(id) ? id : "3",
      export: true,
      order_type: [1, 2, 3].includes(id) ? id : "",
      ...(summaryFilters ?? {}),
      seller_address__area__city: [1, 2, 3].includes(id) ? cityFilter : "",
      order__seller_address__area__city: ![1, 2, 3].includes(id) ? cityFilter : ""
    });
    if (res.error?.data) {
      notifyErrors(res.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Card
      className={styleClass}
      hoverable
      style={{
        ...style,
        margin: "24px 0",
        height: "auto",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: selectedCard === type ? background : "white",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        cursor: "pointer"
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "translateY(-5px)";
        e.currentTarget.style.boxShadow = "0 6px 16px rgba(0, 0, 0, 0.15)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
      }}
      onClick={onClick}
    >
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} />

      <Row justify="space-between" align="middle" style={{ padding: "12px" }}>
        <Col xs={24} sm={18}>
          <h6 style={{ fontSize: "1rem", fontWeight: 600, color: "#333", marginBottom: "0.5rem" }}>{type}</h6>
        </Col>
        {description && (
          <Col xs={24} sm={6}>
            <Tooltip title={description} placement="top" open={tooltipOpen} onOpenChange={toggleTooltip}>
              <InfoCircleOutlined
                style={{
                  color: selectedCard === type ? "#1F0765" : "#C0C0C0",
                  fontSize: "18px",
                  cursor: "pointer"
                }}
              />
            </Tooltip>
          </Col>
        )}
      </Row>

      <Row justify="space-between" align="middle" style={{ padding: "12px" }}>
        <Col xs={24} sm={18}>
          <p style={{ fontSize: "1.25rem", fontWeight: 700, color: "#333", marginBottom: "0.5rem" }}>{number}</p>
        </Col>
        {!inventory && (
          <Col xs={24} sm={6} style={{ textAlign: "right" }}>
            {currentDownload === id && (loadingOrders || loadingTickets)
              ? (
              <Spin />
                )
              : (
              <FileExcelOutlined
                style={{
                  color: selectedCard === type ? "#1F0765" : "#C0C0C0",
                  fontSize: "24px",
                  cursor: "pointer"
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownloadSheet(id);
                }}
              />
                )}
          </Col>
        )}
      </Row>
    </Card>
  );
};

export const InventoryStatusCard = ({
  id,
  background,
  icon,
  number,
  type,
  selectedCard,
  onClick
}) => {
  // Extract icon's color dynamically if needed
  const iconColor = icon.props.style.color || "#808080";

  return (
    <Card
      hoverable
      onClick={onClick}
      style={{
        width: "100%",
        height: "80px",
        backgroundColor: "transparent",
        borderRadius: "12px",
        padding: "0",
        cursor: "pointer",
        margin: "0",
        display: "flex",
        alignItems: "center",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        transform: selectedCard === type ? "scale(1.02)" : "scale(1)",
        boxShadow: selectedCard === type ? "0px 4px 12px rgba(0, 0, 0, 0.15)" : "none"
      }}
    >
      <Row
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center"
        }}
        gutter={[8, 0]}
      >
        {/* Icon Column */}
        <Col
          span={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%"
          }}
        >
          <div
            style={{
              width: "250px",
              height: "60px",
              backgroundColor: `${iconColor}20`, // Add transparency (hex alpha 20 is ~12.5% opacity)
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {icon}
          </div>
        </Col>

        {/* Text Column */}
        <Col
          span={18}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <Typography.Text
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#808080",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {type}
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#272727",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginTop: "4px"
            }}
          >
            {number} Orders
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  );
};

export default StatusCard;
