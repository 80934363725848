import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import CommonReportsPage from "../CommonReportsPage";
import { storeReportsTypes } from "../../../../api/ReportsRepository";
import { useGetSellersMutation } from "../../../../stateManagement/apis/accountsApi";

export default function StoreReportsPage () {
  const [selectedType, setSelectedType] = useState();
  const [sellers, setSellers] = useState([]);
  const [getSellersApi] = useGetSellersMutation();
  const { t } = useTranslation();

  // Fetch sellers data
  useEffect(() => {
    async function fetchSellers () {
      try {
        const res = await getSellersApi({ limit: 1000 });
        if (res.data) setSellers(res.data?.data ?? []);
      } catch (error) {
        console.error("Error fetching sellers", error);
      }
    }
    fetchSellers();
  }, [getSellersApi]);

  // Define form fields
  const fields = [
    {
      name: "type",
      label: t("reports.report_type"),
      component: Select,
      props: {
        options: Object.keys(storeReportsTypes).map((key) => ({
          value: key,
          label: t(`reports.store_list.${storeReportsTypes[key].title}`)
        })),
        placeholder: t("reports.report_type_ph"),
        onChange: (value) => setSelectedType(storeReportsTypes[value])
      },
      rules: [{ required: true, message: t("reports.report_type_req") }]
    },
    {
      name: "seller",
      label: t("reports.store_name"),
      component: Select,
      props: {
        mode: selectedType?.title !== "financial" ? "multiple" : undefined,
        options: sellers.map((seller) => ({
          value: seller.id,
          label: seller.store_name
        })),
        showSearch: true,
        placeholder: t("reports.store_name_ph")
      },
      rules: [{ required: true, message: t("reports.store_name_req") }]
    }
  ];

  return (
    <CommonReportsPage
      formFields={fields}
      selectedType={selectedType}
      section={t("reports.store_reports")}
    />
  );
}
