import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Button, Input, Checkbox, Modal, message } from "antd";
import TablePro from "../../../components/tables/TablePro";
import {
  useFetchCommentsQuery,
  useCreateCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation
} from "../../../stateManagement/apis/commentsApi";

const CommentsPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingComment, setEditingComment] = useState(null); // Stores the comment being edited
  const [currentPage, setCurrentPage] = useState(1);

  // API hooks
  const { data, isLoading, refetch } = useFetchCommentsQuery({ page: currentPage });
  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  // Handle modal open/close
  const showModal = (comment = null) => {
    setEditingComment(comment);
    setIsModalOpen(true);
    if (comment) {
      form.setFieldsValue(comment); // Populate the form with comment data for editing
    } else {
      form.resetFields(); // Reset form for new comment
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  // Handle form submission (Create or Update)
  const handleFormSubmit = async (values) => {
    try {
      if (editingComment) {
        await updateComment({ id: editingComment.id, ...values }).unwrap();
        message.success(t("Comment updated successfully"));
      } else {
        await createComment(values).unwrap();
        message.success(t("Comment created successfully"));
      }
      handleModalClose();
      refetch();
    } catch (error) {
      message.error(t("Failed to save comment. Please try again."));
    }
  };

  // Handle comment deletion
  const handleDelete = async (id) => {
    try {
      await deleteComment(id).unwrap();
      message.success(t("Comment deleted successfully"));
      refetch();
    } catch (error) {
      message.error(t("Failed to delete comment. Please try again."));
    }
  };

  // Table columns
  const columns = [
    {
      title: t("Text"),
      dataIndex: "text",
      key: "text"
    },
    {
      title: t("Text (Arabic)"),
      dataIndex: "text_ar",
      key: "text_ar"
    },
    {
      title: t("Is Pickup"),
      dataIndex: "is_pickup",
      key: "is_pickup",
      render: (value) => (value ? t("Yes") : t("No"))
    },
    {
      title: t("Is Other"),
      dataIndex: "is_other",
      key: "is_other",
      render: (value) => (value ? t("Yes") : t("No"))
    },
    {
      title: t("Create Date"),
      dataIndex: "create_date",
      key: "create_date",
      render: (value) => new Date(value).toLocaleString() // Format date
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => (
        <Button.Group>
          <Button onClick={() => showModal(record)}>{t("Edit")}</Button>
          <Button danger onClick={() => handleDelete(record.id)}>
            {t("Delete")}
          </Button>
        </Button.Group>
      )
    }
  ];

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: "16px" }}>
        <Col>
          <h1>{t("Comments_description")}</h1>
        </Col>
        <Col>
          <Button type="primary" onClick={() => showModal()}>
            {t("Add Comment")}
          </Button>
        </Col>
      </Row>

      <TablePro
        columns={columns}
        dataSource={data || []} // Use "data" from the response
        rowKey="id"
        loading={isLoading}
        pagination={{
          current: currentPage,
          pageSize: data?.limit || 10,
          total: data?.count || 0, // Use "count" from the response
          onChange: (page) => setCurrentPage(page)
        }}
      />

      <Modal
        title={editingComment ? t("Edit Comment") : t("Add Comment")}
        visible={isModalOpen}
        onCancel={handleModalClose}
        footer={null} // Remove default footer
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            text: "",
            text_ar: "",
            is_pickup: false,
            is_other: false
          }}
        >
          <Form.Item
            label={t("Text")}
            name="text"
            rules={[{ required: true, message: t("Please enter text") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("Text (Arabic)")}
            name="text_ar"
            rules={[{ required: true, message: t("Please enter Arabic text") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="is_pickup" valuePropName="checked">
            <Checkbox>{t("Is Pickup")}</Checkbox>
          </Form.Item>
          <Form.Item name="is_other" valuePropName="checked">
            <Checkbox>{t("Is Other")}</Checkbox>
          </Form.Item>
          <Form.Item>
            <Row justify="end" gutter={8}>
              <Col>
                <Button onClick={handleModalClose}>{t("Cancel")}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  {t("Submit")}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CommentsPage;
