import React, { useState, useEffect } from "react";
import { Form, Empty, Input, Table, Dropdown, Button, Menu, Tabs, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import {
  PullRequestOutlined,
  SettingOutlined,
  UploadOutlined,
  CopyOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import * as XLSX from "xlsx"; // For exporting to Excel
import SearchIcon from "../../../images/search.svg";

const TablePro = ({
  selection,
  selectedRows = [],
  setSelectedRows,
  setSelectedRecords,
  rowKey,
  onSearch,
  emptyPlaceholder,
  onRowClick,
  searchPlaceholder,
  title,
  headerExtra,
  disableHeaders,
  unselectableRowsCondition,
  disable_reason,
  checkStrictly,
  onDelete = () => {},
  tabsFilters = { },
  filterHandler,
  onDuplicate = () => {},
  showSelectedCount = false,
  showGroups = false,
  ...props

}) => {
  const { t } = useTranslation();

  const [selectedFilter, setSelectedFilter] = useState(Object.keys(tabsFilters)[0]);
  const [inputVisible, setInputVisible] = useState(false);
  const [groupByColumns, setGroupByColumns] = useState([]);
  const [groupedData, setGroupedData] = useState(props.dataSource);

  const handleFilterChange = (key) => {
    setSelectedFilter(key);
    if (filterHandler) filterHandler(tabsFilters[key]);
    console.log("key", tabsFilters[key]);
  };

  const handleIconClick = () => setInputVisible(!inputVisible);

  const renderSearchForm = () => (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <img
        src={SearchIcon}
        alt="search"
        onClick={handleIconClick}
        style={{ cursor: "pointer", transition: "transform 0.3s", transform: inputVisible ? "rotate(180deg)" : "rotate(0deg)" }}
      />
      <Form
        onFinish={(values) => {
          const searchTerm = values.searchTerm.trim();
          if (searchTerm) onSearch(searchTerm);
          setInputVisible(false);
        }}
        style={{
          display: inputVisible ? "block" : "none",
          transition: "opacity 0.3s ease"
        }}
      >
        <Form.Item name="searchTerm" style={{ margin: 0 }}>
          <Input placeholder={searchPlaceholder || t("Search")} style={{ width: 200 }} />
        </Form.Item>
      </Form>
    </div>
  );

  useEffect(() => {
    const updatedData = groupData(props.dataSource, groupByColumns);
    setGroupedData(updatedData);
  }, [props.dataSource, groupByColumns]);

  const groupData = (data, groups) => {
    if (!groups.length) return data;
    return recursiveGroup(data, groups);
  };

  const recursiveGroup = (data, groups, level = 0) => {
    if (level >= groups.length) return data;

    const grouped = data.reduce((acc, item) => {
      const groupKey = item[groups[level]];
      if (!acc[groupKey]) acc[groupKey] = { key: groupKey, children: [] };
      acc[groupKey].children.push(item);
      return acc;
    }, {});

    return Object.values(grouped).map((group) => ({
      ...group,
      children: recursiveGroup(group.children, groups, level + 1)
    }));
  };

  const handleGroupSelect = (groupKey) => {
    setGroupByColumns((prev) =>
      prev.includes(groupKey) ? prev.filter((g) => g !== groupKey) : [...prev, groupKey]
    );
  };

  const groupsMenu = (
    <Menu>
      {props.columns.map((column) => (
        <Menu.Item key={column.dataIndex}>
          <Checkbox
            checked={groupByColumns.includes(column.dataIndex)}
            onChange={() => handleGroupSelect(column.dataIndex)}
          >
            {t(column.title)}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  // Function to handle Export All
  const handleExport = () => {
    const exportData = props.dataSource;
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");
    XLSX.writeFile(wb, "orders.xlsx");
  };

  const handleDuplicate = () => {
    if (selectedRows.length === 0) {
      console.log("No rows selected for duplication.");
      return;
    }

    // Log selected rows to verify the structure
    console.log("Selected rows:", selectedRows);

    // Filter out rows that become empty objects after removing 'id'
    const rowsToDuplicate = selectedRows
      .map((row) => {
        const { id, ...rowWithoutId } = row;
        console.log("Row  id:", id);
        return rowWithoutId;
      })
      .filter((row) => Object.keys(row).length > 0); // Keep only non-empty rows

    // Log rows to be duplicated to confirm they are not empty
    console.log("Rows to duplicate:", rowsToDuplicate);

    // Call onDuplicate only if there's valid data to duplicate
    if (rowsToDuplicate.length > 0) {
      onDuplicate(rowsToDuplicate);
    } else {
      console.log("No valid rows to duplicate.");
    }
  };

  const handleDelete = () => {
    if (selectedRows.length > 0) {
      const idsToDelete = selectedRows.map((row) => row.id);
      onDelete(idsToDelete); // Calls onDelete from props or uses the default
    } else {
      console.log("No rows selected for deletion.");
    }
  };

  const actionsMenu = (
    <Menu>
      <Menu.Item key="1" icon={<UploadOutlined />} onClick={() => handleExport("orders.xlsx")}>
        {t("ExportAll")}
      </Menu.Item>
      <Menu.Item key="2" icon={<CopyOutlined />} onClick={handleDuplicate}>
        {t("Duplicate")}
      </Menu.Item>
      <Menu.Item key="3" icon={<DeleteOutlined />} onClick={handleDelete}>
        {t("Delete")}
      </Menu.Item>
    </Menu>
  );

  const renderHeader = () => {
    return (
      <div style={{ padding: "8px 0" }}>
        <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "8px", marginBottom: "16px" }}>
          <Tabs
            activeKey={selectedFilter}
            onChange={handleFilterChange}
            tabBarStyle={{ marginBottom: 0 }}
            type="card"
            items={Object.keys(tabsFilters).map((filter) => ({
              label: (
                <span style={{ color: selectedFilter === filter ? "#57be6c" : "#333" }}>
                  {t(filter)}
                </span>
              ),
              key: filter
            }))}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", gap: "8px" }}>
            {showGroups && (
              <Dropdown overlay={groupsMenu}>
                <Button icon={<PullRequestOutlined />}>{t("Groups")}</Button>
              </Dropdown>
            )}

            <Dropdown overlay={actionsMenu}>
              <Button icon={<SettingOutlined />}>{t("Actions")}</Button>
            </Dropdown>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-4">
          {showSelectedCount && (
            <div className="d-flex align-items-end ms-auto">
                {t("Selected")}: {selectedRows.length}
            </div>
          )}
          <div>{renderSearchForm()}</div>
          </div>
        </div>
        {headerExtra && headerExtra}
      </div>
    );
  };

  const rowSelection = {
    selectedRowKeys: Array.isArray(selectedRows)
      ? selectedRows.map((row) => row[rowKey] || row.id)
      : [],
    onChange: (selectedRowKeys, selectedRowObjects) => {
      setSelectedRows(selectedRowObjects); // Update with full row objects
      setSelectedRecords && setSelectedRecords(selectedRowObjects);
    },
    checkStrictly,
    getCheckboxProps: (record) => ({
      disabled: unselectableRowsCondition ? unselectableRowsCondition(record) : false,
      reason: disable_reason
    })
  };
  return (
    <div style={{ overflow: "auto", backgroundColor: "#fff", padding: "16px", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0,0,0,0.15)", minHeight: props.minHeight || "500px" }}
     className="w-100">
      <Table
        {...props}
        columns={props.columns}
        dataSource={groupedData}
        rowKey={(record) => record[rowKey] || record.id}
        rowSelection={selection ? rowSelection : null}
        title={!disableHeaders && renderHeader}
        locale={{ emptyText: <Empty description={t("empty")} /> }}
        // style={{ minHeight: "500px" }}
        onRow={(record) => ({
          onClick: () => onRowClick && onRowClick(record)
        })}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default TablePro;
