import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { addOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { setInventoryOrderSellerData } from "../../../../../stateManagement/slices/inventoryOrderSlice";
import { useSelectedSeller, getSeller } from "../../../../../stateManagement/slices/selectedSellerSlice";
import { useReturnedTickets } from "../../../../../stateManagement/slices/returnedTicketsSlice";
import { usePlan } from "../../../../../stateManagement/slices/planSlice";
import { useFilterSellersMutation, useGetSellerAddressMutation } from "../../../../../stateManagement/apis/accountsApi";
import GenericForm from "../../../../../components/form/GenericForm";
import { useSellerInfoFormFields } from "../../../../../hooks/useFormFields";
import { PaymentAlert } from "../../../../../components/modals/confirmationModal";
import { stringErrorsHandler } from "../../../../../api/ErrorNotifier";

export default function SellerInfoForm ({ value, handleChange, inventory }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // State management for sellers, pagination, error messages, and alerts
  const [sellers, setSellers] = useState([]); // List of sellers
  const [sellerAddresses, setSellerAddresses] = useState([]); // Addresses of selected seller
  const [page, setPage] = useState(1); // Current page for pagination
  const [loadMore, setLoadMore] = useState(true); // To check if more sellers can be loaded
  const [errMsg, setErrMsg] = useState(""); // Error message
  const [showPaymentAlert, setPaymentAlert] = useState(false); // Payment alert modal visibility
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering sellers
  const [getSellerAddressApi, { isLoading: isLoadingSellerAddress }] = useGetSellerAddressMutation();
  const [loadMoreSellerAddress, setLoadMoreSellerAddress] = useState(false);
  const [pageSellerAddress, setPageSellerAddress] = useState(1);
  const [searchSellerAddress, setSearchSellerAddress] = useState("");

  // API mutation for fetching sellers
  const [filterSellers, { isLoading, error }] = useFilterSellersMutation();

  // Redux states for selected seller, plan, and returned tickets
  const selectedSeller = useSelectedSeller();
  const plan = usePlan();
  const returnedTickets = useReturnedTickets();

  const getSellerAddress = async (onlyNew = false) => {
    const result = await getSellerAddressApi({
      sellerAddressId: selectedSeller.id,
      page: onlyNew ? 1 : pageSellerAddress,
      search: searchSellerAddress
    });
    if (result.data) {
      const newSellerAddresses = onlyNew ? result.data.data : [...sellerAddresses, ...result.data.data];
      setSellerAddresses(newSellerAddresses);
      setLoadMoreSellerAddress(result.data.next !== null);
      setPageSellerAddress(result.data.next ? result.data.page + 1 : result.data.page);
    } else if (result.error?.data) {
      setLoadMoreSellerAddress(false);
      setErrMsg(stringErrorsHandler(result.error.data));
    } else {
      setLoadMoreSellerAddress(false);
      setErrMsg(t("Error fetching sellers"));
    }
  };

  // Fetch sellers data with pagination and search term
  const getSellers = async (onlyNew = false) => {
    try {
      const result = await filterSellers({
        page: onlyNew ? 1 : page,
        search: searchTerm
      });
      if (result?.data?.data) {
        const newSellers = onlyNew ? result.data.data : [...sellers, ...result.data.data];
        console.log("New Sellers:", newSellers); // For debugging
        console.log("ONly New:", onlyNew); // For debugging
        setSellers(newSellers);
        setLoadMore(result.data.next !== null); // Update loadMore if there's a next page
        setPage(result.data.next ? result.data.page + 1 : result.data.page); // Update page number
      } else if (result?.error?.data) {
        setLoadMore(false); // No more pages to load
        setErrMsg(stringErrorsHandler(result.error.data)); // Show error message
      } else {
        setLoadMore(false); // No more pages to load
        setErrMsg(t("Error fetching sellers"));
      }
    } catch (error) {
      console.error("Error fetching sellers:", error);
      setErrMsg(t("Error fetching sellers"));
    }
  };

  const handleSearchSellerAddress = debounce((value) => {
    setSearchSellerAddress(value); // Update search term
  }, 300);

  const onScrollSellerAddress = debounce(async (event) => {
    const target = event.target;
    if (loadMoreSellerAddress && (target.scrollTop + target.offsetHeight >= target.scrollHeight)) {
      await getSellerAddress(); // Load more sellers when reaching the bottom
    }
  }, 200);

  useEffect(() => {
    if (selectedSeller?.id) {
      getSellerAddress(true);
    }
  }, [selectedSeller, searchSellerAddress]);

  // Search function with debounce to reduce API calls
  const search = debounce((value) => {
    setSearchTerm(value); // Update search term
  }, 300);

  useEffect(() => {
    getSellers(true); // Fetch new sellers on component mount
  }, [searchTerm]);

  // Infinite scrolling handler with debounce
  const onScroll = debounce(async (event) => {
    const target = event.target;
    if (loadMore && (target.scrollTop + target.offsetHeight >= target.scrollHeight)) {
      await getSellers(); // Load more sellers when reaching the bottom
    }
  }, 200);

  // Handle seller change and update seller addresses
  const handleSellerChange = (sellerId) => {
    const selected = sellers.find((seller) => seller.id === sellerId);
    console.log("Selected Seller:", selected); // For debugging
    dispatch(getSeller(selected)); // Dispatch selected seller to the store
    setSellerAddresses(selected ? selected.saved_address : []); // Update seller addresses
  };

  // Form validation and order dispatching
  const validate = (data) => {
    if (!data.seller && !selectedSeller && returnedTickets.length === 0) {
      toast.error(t("requiredField")); // Show toast error for missing seller
      return;
    }

    const address = data.seller_address ? JSON.parse(data.seller_address) : null;

    if (!address) {
      toast.error(t("chooseCustomerAddress")); // Show toast error for missing address
      return;
    }

    if (inventory) {
      dispatch(setInventoryOrderSellerData({ seller: selectedSeller.id, seller_address: address.id }));
    } else {
      dispatch(
        addOrder({
          ...data,
          seller_address: address.id,
          branch: address.branch.id,
          work_model_type: "1"
        })
      );
    }

    console.log(data); // For debugging
    handleChange(null, value + 1); // Move to the next form step
  };

  // Clear error message when the selected seller changes
  useEffect(() => {
    setErrMsg("");
  }, [selectedSeller]);

  // Show payment alert when the plan's order count reaches the limit
  useEffect(() => {
    if (plan?.orders_count >= plan?.orders_remaining) {
      setPaymentAlert(true);
    }
  }, [plan]);

  // Form fields configuration
  const fields = useSellerInfoFormFields({
    selectedSeller,
    sellers: Array.isArray(sellers) ? sellers : [], // Ensure sellers is always an array
    sellerAddresses,
    handleSellerChange,
    search,
    onScroll,
    setPaymentAlert,
    returnedTickets,
    plan,
    i18n,
    inventory,
    error,
    loadingSellers: isLoading,
    loadingSellerAddress: isLoadingSellerAddress,
    handleSearchSellerAddress,
    onScrollSellerAddress
  });

  return (
    <>
      <GenericForm
        fields={fields}
        submitFunction={validate}
        hooks={{
          successMessage: t("Success"),
          errorMessage: t("Error")
        }}
      />
      <PaymentAlert
        showModal={showPaymentAlert}
        setModal={setPaymentAlert}
        content={t("please topup payment")}
      />
      {errMsg && <div className="error-msg">{errMsg}</div>} {/* Display error message */}
      <ToastContainer />
    </>
  );
}
