import { INVENTORY_ORDER_STATUS } from "../utils/beDict";
import store from "../stateManagement/store";
import { inventoryApi } from "../stateManagement/apis/inventoryApi";
import formattedError from "./errorFormatter";

const InventoryOrderRepository = {
  getUnCompletedOrders: async ({ page = 1, limit = 10, ...qp }) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getUnCompletedOrders.initiate({
          page,
          page_size: limit,
          status: INVENTORY_ORDER_STATUS.APPROVED,
          ...qp
        })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },

  getCompletedOrders: async ({ page = 1, limit = 10, ...qp }) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getCompletedOrders.initiate({
          page,
          page_size: limit,
          status: INVENTORY_ORDER_STATUS.COMPLETED,
          ...qp
        })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },

  getOrder: async (id) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getOrder.initiate(id)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },

  getRejectedOrders: async ({ page = 1, limit = 10, ...qp }) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getRejectedOrders.initiate({
          page,
          page_size: limit,
          status: INVENTORY_ORDER_STATUS.REJECTED,
          ...qp
        })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },

  getPendingOrders: async ({ page = 1, limit = 10, ...qp }) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getPendingOrders.initiate({
          page,
          page_size: limit,
          status: INVENTORY_ORDER_STATUS.PENDING,
          ...qp
        })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },

  completeOrder: async (id) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.completeOrder.initiate(id)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },

  cancelOrder: async (id) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.cancelOrder.initiate(id)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },

  createOrder: async (data) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.createOrder.initiate(data)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },

  acceptSellerCreateOrder: async (id, data) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.acceptSellerCreateOrder.initiate({ id, ...data })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  }
};

export default InventoryOrderRepository;
