import React, { useEffect, useState, useCallback } from "react";
import { Form, Select, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TicketScanRepository from "../data";
import WMButton from "../../../../components/pages/AllWorkModels/WMButton";
import ScanInformation from "./ScanInformation";
import Header from "./Header";

export function TicketScanStart () {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [couriers, setCouriers] = useState([]);

  // Memoized fetch function to avoid unnecessary re-renders
  const fetchCouriers = useCallback(async (search = "") => {
    const res = await TicketScanRepository.getCouriers(search);
    if (res.success) setCouriers(res.data);
  }, []);

  // Fetch couriers on component mount
  useEffect(() => {
    fetchCouriers();
  }, [fetchCouriers]);

  // Handle form submission
  const handleFormSubmit = ({ courier }) => {
    navigate(`/ticket_scan/${courier}`);
  };

  // Mouse enter event handler for button
  const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = "#0056b3";
  };

  // Mouse leave event handler for button
  const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = "#007bff";
  };

  return (
    <main style={{ fontFamily: "Roboto, sans-serif", backgroundColor: "transparent", minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Header />
      <ScanInformation />

      <Form
        onFinish={handleFormSubmit}
        layout="vertical"
        style={{
          marginTop: "48px",
          width: "100%",
          maxWidth: "480px",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#ffffff",
          padding: "24px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease-in-out"
        }}
      >
        {/* Ant Design Row and Col for responsive layout */}
        <Row gutter={[16, 16]}>
          <Col sm={24} md={18} lg={16}>
            <Form.Item
              name="courier"
              label={t("chooseCourier")}
              rules={[{ required: true, message: t("courierRequired") }]}
              style={{ marginBottom: "24px" }}
            >
              <Select
                placeholder={t("searchCouriers")}
                showSearch
                onSearch={fetchCouriers}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  transition: "box-shadow 0.3s ease"
                }}
                onFocus={(e) => (e.target.style.boxShadow = "0 2px 8px rgba(0, 123, 255, 0.5)")}
                onBlur={(e) => (e.target.style.boxShadow = "0 2px 8px rgba(0, 0, 0, 0.1)")}
              >
                {couriers.map((courier) => (
                  <Select.Option key={courier.id} value={courier.id}>
                    {courier.user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <WMButton
          htmlType="submit"
          style={{
            width: "100%",
            maxWidth: "164px",
            marginTop: "32px",
            backgroundColor: "#007bff",
            color: "#fff",
            borderRadius: "8px",
            padding: "12px 0",
            fontSize: "16px",
            fontWeight: "bold",
            transition: "all 0.3s ease"
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {t("next")}
        </WMButton>
      </Form>
    </main>
  );
}

export default TicketScanStart;
