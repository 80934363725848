import { EnvelopeIcon } from "@shopify/polaris-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import CreateOrderTabs2 from "../../features/common/tickets/create/ui/CreateOrderTabs";

import PostponeOrder from "../../components/layout/postponeOrder";
import TicketReturnRequestTabs from "../../components/layout/TicketsReturns/TicketReturnRequestTabs";
import OrderChainDetails from "../../components/pages/OrderChainDetails/OrderChain";
import TicketChainDetails from "../../components/pages/OrderChainDetails/TicketChain";
import ViewOrders from "../../components/pages/wallets/viewOrders";
import PaidTickets from "../../components/pages/wallets/paidTickets";
import TicketScanStart from "../../features/admin/ticket-scan/ui/TicketScanStart";
import TicketScanSession from "../../features/admin/ticket-scan/ui/TicketScanSession";
import CreateBulkOrder from "../../features/common/tickets/create/ui/CreateBulkOrder";
import AdminCreateParcel from "../../features/admin/tickets/create/ui/AdminCreateParcel";
import SellerCreateParcel from "../../features/seller/tickets/create/ui/SellerCreateParcel";
import TicketsReplacement from "../../features/common/tickets/replace/ui/TicketsReplacement";
import AcceptFromStockOrder from "../../components/pages/InventoryOrders/AcceptFromStockOrder";
import ArchivedOrders from "../../features/common/archived/archivedOrders";

export function useSellerOrdersRoute () {
  const { t } = useTranslation();

  return {
    "/create_order/parcel": {
      element: SellerCreateParcel,
      title: t("sideMenu.createNewTicket"),
      rules: ["Can add order", "Can add ticket"]
    }
  };
}

export function useAdminOrdersRoute () {
  const { t } = useTranslation();
  return {
    "/create_order/parcel": {
      element: AdminCreateParcel,
      title: t("sideMenu.createNewTicket"),
      rules: ["Can add order", "Can add ticket"]
    },
    "/accept_from_stock/:id": {
      element: AcceptFromStockOrder,
      title: t("Accept From Stock Order"),
      rules: ["Can add order", "Can add ticket"]
    }
  };
}

export default function useOrdersRoutes () {
  const { t } = useTranslation();
  return {
    meta: {
      title: t("sideMenu.tickets"),
      icon: EnvelopeIcon
    },
    "/create_order/bulk": {
      element: CreateBulkOrder,
      title: t("sideMenu.createNewTicket"),
      rules: ["Can add order", "Can add ticket"]
    },

    "/create_order": {
      element: CreateOrderTabs2,
      title: t("sideMenu.createNewTicket"),
      rules: ["Can add order", "Can add ticket"]
    },
    "/postponed": {
      element: PostponeOrder,
      title: t("sideMenu.postpond"),
      rules: ["Can view order", "Can view ticket"]
    },
    "/return_ticket": {
      element: TicketReturnRequestTabs,
      title: t("sideMenu.returned"),
      rules: ["Can view order", "Can view ticket"]
    },
    "/replace_ticket": {
      element: TicketsReplacement,
      title: t("sideMenu.replace"),
      rules: ["Can view order", "Can view ticket"]
    },
    "/archived_tickets": {
      // element: ArchivedTickets,
      element: ArchivedOrders,
      title: t("sideMenu.archived"),
      rules: ["Can view order", "Can view ticket"]
    },
    "/ticket_scan": {
      element: TicketScanStart,
      icon: <span className="material-icons">document_scanner</span>,
      title: t("sideMenu.ticketScan"),
      rules: ["can scan ticket"]
    },
    "/ticket_scan/:id": {
      element: TicketScanSession,
      rules: ["can scan ticket"]
    },
    "/order_chain_details/:id": {
      element: (props) => <OrderChainDetails {...props} />
    },
    "/ticket_details/:id": {
      element: (props) => <TicketChainDetails {...props} />
    },
    "/view_orders": {
      element: ViewOrders,
      rules: ["Can view order", "Can view ticket"]
    },
    "/paid_tickets": {
      element: PaidTickets,
      rules: ["Can view order", "Can view ticket"]
    }
  };
}
