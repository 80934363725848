import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Navigate, // Changed Redirect to Navigate in React Router v6
  Route,
  Routes // Switch replaced by Routes in React Router v6
} from "react-router-dom"; // Updated imports for React Router v6
import { useDispatch } from "react-redux";
import { ConfigProvider, notification } from "antd";
import moment from "moment";
import Header from "./components/layout/Header";
import SideMenu from "./components/layout/SideMenu";
import { LanguageContext } from "./components/contexts/LanguageContext";
import {
  useAdminRoutes,
  useSellerRoutes,
  useThirdPartyRoutes,
  useInventoryRoutes,
  useNoSubscriptionRoutes
} from "./global-state/routes";
import generateRoutes from "./global-state/routes/generate-routes";
import useLanguage from "./hooks/useLanguage";
import { setDashboardType, useDashboardType } from "./stateManagement/slices/dashboardSlice";
import { useUserObject, useIsAuthed, useExpiredSubscription } from "./stateManagement/slices/userSlice";

function App () {
  const user = useUserObject();
  const dashboard = useDashboardType();
  const org = useUserObject().org;
  const dispatch = useDispatch();
  const lang = useLanguage();
  const isAuthed = useIsAuthed();
  const expiredSubscription = useExpiredSubscription();

  const languageChecker = (englishCallback, arabicCallback) =>
    lang.includes("en") ? englishCallback : arabicCallback;

  const direction = languageChecker("ltr", "rtl");
  const antLocale = languageChecker(
    moment.locale("en_US"),
    moment.locale("ar_EG")
  );

  const theme = createTheme({
    typography: {
      fontFamily: languageChecker(
        ["SF Pro Text"],
        ["Cairo", "sans-serif"].join(",")
      )
    }
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primaryColor",
      org?.primary_color ? org?.primary_color : "#57be6c"
    );
    document.documentElement.style.setProperty(
      "--secondaryColor",
      org?.secondary_color ? org?.secondary_color : "#1F0765"
    );
  }, [org]);

  const { unAuthed: unAuthedAdminRoutes, ...authedAdminRoutes } = useAdminRoutes() || {};
  const { unAuthed: unAuthedSellerRoutes, ...authedSellerRoutes } = useSellerRoutes(user?.user?.is_third_party) || {};
  const { unAuthed: unAuthedThirdPartyRoutes, ...authedThirdPartyRoutes } = useThirdPartyRoutes() || {};
  const { unAuthed: unAuthedInventoryRoutes, ...authedInventoryRoutes } = useInventoryRoutes() || {};
  const { unAuthed: unAuthedNoSubscriptionRoutes, ...authedNoSubscriptionRoutes } = useNoSubscriptionRoutes() || {};
  const [homeRoute, setHomeRoute] = useState(<Navigate to="/dashboard" />);

  const availableRoutes = useMemo(() => {
    if (isAuthed) {
      setHomeRoute(<Navigate to="/dashboard" />);
      if (expiredSubscription) {
        return Object.values(authedNoSubscriptionRoutes);
      } else if (dashboard === "default" && user?.user) {
        return user.role === "Seller"
          ? Object.values(authedSellerRoutes)
          : user.role === "Third Party"
            ? Object.values(authedThirdPartyRoutes)
            : Object.values(authedAdminRoutes);
      } else {
        return Object.values(authedInventoryRoutes);
      }
    } else {
      setHomeRoute(<Navigate to="/login" />);
      if (expiredSubscription) {
        return unAuthedNoSubscriptionRoutes;
      } else if (dashboard === "default") {
        return user?.user
          ? user.role === "Seller"
            ? unAuthedSellerRoutes
            : user.role === "Third Party"
              ? unAuthedThirdPartyRoutes
              : unAuthedAdminRoutes
          : unAuthedAdminRoutes;
      } else {
        return unAuthedInventoryRoutes;
      }
    }
  }, [
    isAuthed,
    expiredSubscription,
    dashboard
    // user
  ]);

  useEffect(() => {
    let pathes = ["/"];
    if (!isAuthed) {
      pathes = [...pathes, ...Object.keys(unAuthedAdminRoutes)];
    } else {
      pathes = [...pathes, ...Object.keys(Object.values(availableRoutes).reduce((acc, curr) => Object.assign(acc, curr), {}))];
    }
    console.log("PATHES", pathes);
    if (!pathes.includes(window.location.pathname)) {
      setTimeout(() => {
        window.location.pathname = isAuthed ? "/dashboard" : "/login";
      }, 500);
    }
  }, [window.location.pathname, availableRoutes]);

  useMemo(() => {
    const currentPath = window.location.pathname.split("/")[1];
    const inventoryPaths = Object.keys(
      Object.values(authedInventoryRoutes).reduce(
        (acc, curr) => Object.assign(acc, curr),
        {}
      )
    )
      .filter((path) => path.startsWith("/"))
      .map((path) => path.split("/")[1]);

    if (inventoryPaths.includes(currentPath) && currentPath !== "dashboard") {
      dispatch(setDashboardType("inventory"));
    }
  }, [org?.inventory_active, authedInventoryRoutes]);

  useEffect(() => {
    notification.config({
      placement: "bottomRight",
      bottom: 50,
      duration: 3,
      rtl: direction === "rtl"
    });
  }, [direction]);

  return (
    <LanguageContext.Provider value={{ language: lang }}>
      <ThemeProvider theme={theme}>
        <ConfigProvider
          direction={direction}
          locale={antLocale}
          theme={{
            "primary-color": document.documentElement.style.getPropertyValue(
              "--primaryColor"
            )
          }}
        >
          <div dir={direction} className={languageChecker("App", "AppAr")}>
            <Router>
              {isAuthed &&
              <>
                                <Header />
                  <SideMenu />
              </>
                }
                <div className={isAuthed ? "pagePadding" : ""} dir={direction}>
                <Routes>
                {isAuthed
                  ? availableRoutes.map((routeGroup) => routeGroup
                    ? generateRoutes(routeGroup, user)
                    : null
                  )
                  : generateRoutes(unAuthedAdminRoutes, null)
                }
                  <Route path="/" element={homeRoute} />
                </Routes>
              </div>
            </Router>
            <div className="footer-section">
              {lang === "en"
                ? (
                <p>
                  © 2022{" "}
                  <a
                    target="_blank"
                    href={`https://tree-code.com/${lang}/`}
                    rel="noreferrer"
                  >
                    TreeCode.
                  </a>{" "}
                  All rights reserved.
                </p>
                  )
                : (
                <p>
                  @ 2022{" "}
                  <a
                    target="_blank"
                    href={`https://tree-code.com/${lang}/`}
                    rel="noreferrer"
                  >
                    تري كود
                  </a>{" "}
                  .جميع الحقوق محفوظة.
                </p>
                  )}
            </div>
          </div>
        </ConfigProvider>
      </ThemeProvider>
    </LanguageContext.Provider>
  );
}

export default App;
