import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  search: ""
};

const searchSlice = createSlice({
  name: "search",
  initialState,

  reducers: {
    search: (state, action) => {
      state.search = action.payload;
    }
  }
});

export const { search } = searchSlice.actions;
export const useSearch = () => useSelector((state) => state.search.search);

export default searchSlice.reducer;
