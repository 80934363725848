import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../images/delete_wm.svg";
import SortableTable from "../SortableTable";
import { addRunsheetOrders, useRunsheetOrders } from "../../stateManagement/slices/rsOrdersSlice";
import useLanguage from "../../hooks/useLanguage";
import InformationCard from "../layout/informationCard";

// function DragHandle (props) {
//   return (
//         <div {...props} className="unselectable">
//             Move
//         </div>
//   );
// }

// function DragRow ({ id, ...rest }) {
//   return (
//         <div className="drag-row" {...rest}>
//             <DragHandle />
//             <div>{id}</div>
//         </div>
//   );
// }

function RunsheetInfo ({ value, handleChange }) {
  const initialData = useRunsheetOrders();
  const [data, setData] = useState(initialData ?? []);

  const dispatchOrders = useDispatch();

  const proceed = () => {
    dispatchOrders(addRunsheetOrders(data));
    handleChange(null, value + 1);
  };

  const runsheetOrders = [
    {
      field: "id",
      name: "Order ID",
      width: 200,
      valueGetter: (row) => row?.id,
      selector: (row) => <p className="tableID">{row?.id}</p>
    },

    {
      field: "store_name",
      name: "Store name",
      width: 200,
      valueGetter: (row) =>
        row?.seller?.store_name ? row.seller?.store_name : null,
      selector: (row) => (
                <p className="paragraphMargin">
                    {row?.seller?.store_name
                      ? row.seller?.store_name
                      : "No Name"}
                </p>
      )
    },
    {
      field: "create_date",
      name: "Date Created",
      width: 210,
      selector: (row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
      )
    },
    {
      field: "order_value",
      name: "Order Value",
      width: 210,
      valueGetter: (row) => row?.promise_date,
      selector: (row) => (
                <p className="paragraphMargin">{row?.sub_total}</p>
      )
    },

    {
      field: "status",
      name: "Status",
      width: 230,
      selector: (row) =>
        row.runsheet_type?.map((status) => {
          return (
                        <div
                          key={status}
                            className={status + " btn btn-outline-primary mx-2"}
                        >
                            {status}
                        </div>
          );
        })
    }
  ];

  const runsheetOrders_ar = [
    {
      field: "id",
      name: "رقم الطلب",
      width: 200,
      valueGetter: (row) => row?.id,
      selector: (row) => <p className="tableID">{row?.id}</p>
    },

    {
      field: "store_name",
      name: "اسم المتجر",
      width: 200,
      valueGetter: (row) =>
        row?.seller?.store_name ? row.seller?.store_name : null,
      selector: (row) => (
                <p className="paragraphMargin">
                    {row.seller?.store_name
                      ? row.seller?.store_name
                      : "No Name"}
                </p>
      )
    },
    {
      field: "create_date",
      name: "تاريخ الانشاء",
      width: 210,
      selector: (row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
      )
    },
    {
      field: "order_value",
      name: "قيمة الطلب",
      width: 210,
      valueGetter: (row) => row?.promise_date,
      selector: (row) => (
                <p className="paragraphMargin">{row?.sub_total}</p>
      )
    },

    {
      field: "status",
      name: "الحالة",
      width: 230,
      selector: (row) =>
        row.runsheet_type?.map((status) => {
          return (
                        <div
                        key={status}
                            className={status + " btn btn-outline-primary mx-2"}
                        >
                            {status}
                        </div>
          );
        })
    }
  ];

  const { t } = useTranslation();
  const lang = useLanguage();

  return (
        <>
            {/* <div className="grid-form"> */}
            {/*    {[ */}
            {/*        { label: "branch", name: branch?.name }, */}
            {/*        { label: "city", name: city?.name }, */}
            {/*        { label: "area", name: area?.name }, */}
            {/*    ] */}
            {/*        .filter((it) => it?.name) */}
            {/*        .map((item) => ( */}
            {/*            <div className="position-relative text-area-form"> */}
            {/*                <label className="bold-label"> */}
            {/*                    {t(item.label)} */}
            {/*                </label> */}
            {/*                <Form.Control */}
            {/*                    defaultValue={item?.name} */}
            {/*                    disabled */}
            {/*                    type="text" */}
            {/*                /> */}
            {/*            </div> */}
            {/*        ))} */}
            {/* </div> */}
            {/* <Form layout="vertical" className="grid-form"> */}
            {/*    {Object.keys(filters).map((key) => ( */}
            {/*        <Form.Item key={key} label={t(key)}> */}
            {/*            <Select */}
            {/*                allowClear */}
            {/*                value={filters[key]} */}
            {/*                onChange={(v) => */}
            {/*                    setFilters((prev) => ({ ...prev, [key]: v })) */}
            {/*                } */}
            {/*            > */}
            {/*                {filterData[key].map((it) => ( */}
            {/*                    <Select.Option */}
            {/*                        value={typeof it === "string" ? it : it.id} */}
            {/*                        key={typeof it === "string" ? it : it.id} */}
            {/*                    > */}
            {/*                        {typeof it === "string" ? it : it.name} */}
            {/*                    </Select.Option> */}
            {/*                ))} */}
            {/*            </Select> */}
            {/*        </Form.Item> */}
            {/*    ))} */}
            {/* </Form> */}
            <Row className="mb-3">
                <Col className="text-end buttons-margin" sm="12">
                    {/* <button className="cancel">{t("discard")}</button> */}
                    <button onClick={proceed} className="confirm">
                        {t("next")}
                    </button>
                </Col>
            </Row>
            <InformationCard
                title={
                    lang === "ar"
                      ? "يمكنك تغير أولويات الطلبات عن طريق اعادة ترتيبهم في الجدول"
                      : "You can change orders priority by arranging them in the table below"
                }
            />
            <Row style={{ marginTop: "16px" }}>
                <Col sm="12">
                    {/* {currentlyDrag && ( */}
                    {/*    <DragRow id={currentlyDrag} style={{ */}
                    {/*        top: mousePosition.y, */}
                    {/*        left: 0 */}
                    {/*    }} /> */}
                    {/* )} */}
                    <SortableTable
                        data={data}
                        setData={setData}
                        columns={[
                          ...(lang === "ar"
                            ? runsheetOrders_ar
                            : runsheetOrders),
                          {
                            field: "actions",
                            headerName: t("actions"),
                            selector: (row) => (
                                    <>
                                        {data.length > 1
                                          ? (
                                            <div
                                                onClick={() => {
                                                  setData(
                                                    data.filter(
                                                      (it) =>
                                                        it.id !== row.id
                                                    )
                                                  );
                                                }}
                                            >
                                                <img src={DeleteIcon} alt="delete" />
                                            </div>
                                            )
                                          : (
                                            <></>
                                            )}
                                    </>
                            )
                          }
                        ]}
                        disableSelectionOnClick
                        onSelectionModelChange={(ids) => {
                          // const selectedIDs = new Set(ids);
                          // const selectedRowData = data.filter((row) =>
                          //   selectedIDs.has(row.id)
                          // );
                        }}
                    />
                </Col>
            </Row>
        </>
  );
}

export default RunsheetInfo;
