import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ConfirmPayment from "../../../features/seller/wallet/confirm-payment/ui";
import TablePro from "../../tables/TablePro";
import { useRunsheetOrders } from "../../../stateManagement/slices/rsOrdersSlice";
import { useBranch } from "../../../stateManagement/slices/branchSlice";
import { useAuthedUser, useUserObject } from "../../../stateManagement/slices/userSlice";

function ViewOrders (props) {
  const { t } = useTranslation();
  const orders = useRunsheetOrders();
  console.log({ orders });
  const branch = useBranch();
  const cur = useAuthedUser().organization.country.currency;
  const user = useUserObject();

  const [data] = useState([]);
  const [count] = useState(1);
  const [page] = useState(1);
  const [selectionModel, setSelectionModel] = useState([]);
  const [columns] = useState([
    {
      field: "id",
      title: `${t("orderID")}`,
      width: 220,
      render: (_, row) => (
                <p className="paragraphMargin tableID">#{row.id}</p>
      )
    },
    {
      field: "sfee",
      title: `${t("shipmentFee")}`,
      width: 220,
      render: (_, row) => (
                <p className="paragraphMargin credit">{row.delivery_fees}</p>
      )
    },
    {
      field: "value",
      title: `${t("orderValue")}`,
      width: 200,
      render: (_, row) => (
                <p className="paragraphMargin credit">
                    {cur} {row.sub_total}
                </p>
      )
    },
    {
      field: "method",
      title: `${t("paymentMethod")}`,
      width: 240,
      render: (_, row) => (
                <p className="paragraphMargin">{row.payment_method}</p>
      )
    }
  ]);
  const prevSelectionModel = useRef(selectionModel);
  // const prevOrders = useRef(orders);

  useEffect(() => {
    setSelectionModel([...selectionModel, ...prevSelectionModel.current]);
    console.log("selection model ", selectionModel);
  }, [page, data]);

  useEffect(() => {
    console.log("ORDERS ", orders);
  }, [orders]);

  return (
        <>
            <div>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.wallet")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("totalBalance")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("sellerWallet")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                    </Col>
                </Row>

                <TablePro
                    title={
                        <div
                            className="tableHeader"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: 0,
                              width: "100%"
                            }}
                        >
                            <h6>
                                {branch} {t("orderDetails")}
                            </h6>
                        </div>
                    }
                    headerExtra={
                        user.role === "Seller" ? <ConfirmPayment /> : undefined
                    }
                    dataSource={orders.orders ? orders.orders : orders}
                    columns={[...columns]}
                    rowCount={count}
                    pagination
                />
            </div>
        </>
  );
}

export default ViewOrders;
