import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import {
  useOrdersColumns
} from "../../layout/tableColumns";
import {
  CreationConfirmation,
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../../modals/confirmationModal";
import TablePro from "../../tables/TablePro";
import useLanguage from "../../../hooks/useLanguage";
import { useRunsheetOrders } from "../../../stateManagement/slices/rsOrdersSlice";
import { useRunsheet } from "../../../stateManagement/slices/addRunsheetSlice";
import { useCreateRunsheetMutation } from "../../../stateManagement/apis/runsheetsApi";
import { stringErrorsHandler } from "../../../api/ErrorNotifier";

export default function RequestStep ({ value, handleChange }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [ID] = useState("");
  const [createRunsheet] = useCreateRunsheetMutation();

  const data = useRunsheet().orders || [];
  console.log(useRunsheetOrders());
  console.log(data);
  const courier = useRunsheet()?.courier || {};
  console.log(useRunsheet());
  const branches = useRunsheet()?.branches || { from: {}, to: {} };

  const hide = () => {
    setError("");
    setSuccess(false);
    setShow(false);
    navigate("/all_internals");
  };

  const confirm = async () => {
    try {
      const res = await createRunsheet({
        type: 2,
        courier: courier?.id,
        orders: data.map((it) => it?.id),
        branch: branches.from?.id,
        to_branch: branches.to?.id
      });

      if (res?.data) {
        setShow(false);
        setSuccess(true);
      } else if (res?.error?.data) {
        setShow(false);
        setError(stringErrorsHandler(res.error.data));
      } else {
        setShow(false);
        setError(t("server_error"));
      }
    } catch (err) {
      setShow(false);
      console.log({ ee: err.response?.data });
      if (err.response?.status < 500) {
        setError(err.response?.data?.courier?.[0]);
      } else {
        setError(t("server_error"));
      }
    }
  };

  const discardRunsheet = () => {
    handleChange(null, 0);
  };

  const lang = useLanguage();

  return (
      <>
        <Row className="mt-3">
          <Col sm="12" md="6">
            <div className="position-relative text-area-form">
              <label className="bold-label">{t("branches.from")}</label>
              <Form.Control
                defaultValue={branches.from?.name || ""}
                disabled
                type="text"
              />
            </div>
          </Col>
          <Col sm="12" md="6">
            <label className="bold-label">{t("branches.to")}</label>
            <Form.Control
              defaultValue={branches.to?.name || ""}
              disabled
              type="text"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="12" md="6">
            <div className="position-relative text-area-form">
              <label className="bold-label">{t("couriername")}</label>
              <Form.Control
                defaultValue={courier?.user?.name || ""}
                disabled
                type="text"
              />
            </div>
          </Col>
          <Col sm="12" md="6">
            <label className="bold-label">{t("courierBranch")}</label>
            <Form.Control
              defaultValue={courier?.branch?.name || ""}
              disabled
              type="text"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="12" md="6">
            <div className="position-relative text-area-form">
              <label className="bold-label">{t("courierVehicle")}</label>
              <Form.Control
                defaultValue={courier?.vehicle || ""}
                disabled
                type="text"
              />
            </div>
          </Col>
          <Col sm="12" md="6">
            <label className="bold-label">{t("courierPhone")}</label>
            <Form.Control
              defaultValue={courier?.user?.phone || ""}
              disabled
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-end buttons-margin mb-3" sm="12">
            <button onClick={discardRunsheet} className="cancel">
              {t("discard")}
            </button>
            <button onClick={() => setShow(true)} className="confirm">
              {t("sendRequest")}
            </button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div>
              <TablePro dataSource={data} columns={useOrdersColumns()} />
            </div>
          </Col>
          <CreationConfirmation
            sendRequest={confirm}
            show={show}
            hide={hide}
            body={`${t("runsheetCreateMsg1")} ${courier?.user?.name || ""}`}
            title={t("runsheetConfirm")}
          />
          <SuccessfulRequest
            show={success}
            hide={hide}
            title={t("runsheetConfirm")}
            body={
              lang === "en"
                ? `Runsheet with ID #${ID} has been successfully assigned to ${courier?.user?.name || ""}`
                : `تم تعيين خط سير برقم ${ID} للمندوب ${courier?.user?.name || ""}`
            }
          />
          <UnSuccessfulRequest
            show={error.length > 0}
            hide={hide}
            title={t("runsheetConfirm")}
            body={error}
          />
        </Row>
      </>
  );
}
