import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Layout, Spin, Empty } from "antd";
import { useTranslation } from "react-i18next";
import { useSmsTemplateColumns } from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import SmsTemplateRepository from "../../../api/SmsTemplateRepository";
import { notifyErrors } from "../../../api/ErrorNotifier";

const { Content } = Layout;
const { Title, Text } = Typography;

const AllSmsTemplates = () => {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 10,
    search: ""
  });
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const handleActivateAction = async (id) => {
    setLoadingAction(true);
    const response = await SmsTemplateRepository.actionActivateSwitch(id);
    if (response.success) {
      fetchTemplates();
    } else {
      notifyErrors(response.error);
    }
    setLoadingAction(false);
  };

  const columns = useSmsTemplateColumns(handleActivateAction, loadingAction);

  const fetchTemplates = async () => {
    setLoading(true);
    const response = await SmsTemplateRepository.getAllTemplates(queryParams);
    if (response.success) {
      setSmsTemplates(response.data || []); // Ensure we set an empty array if no data is returned
      setCount(response.count || 0);
    } else {
      notifyErrors(response.error);
    }
    setLoading(false);
  };

  const handleChangeQueryParams = (param, value) => {
    setQueryParams({ ...queryParams, [param]: value, page: param === "page" ? value : 1 });
  };

  useEffect(() => {
    fetchTemplates();
  }, [queryParams]);

  return (
    <Layout style={{ padding: "24px" }}>
      <Content>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={3}>{t("sideMenu.smsTemplates")}</Title>
            <Text>{t("sideMenu.allSmsTemplates")}</Text>
          </Col>

          <Col span={24}>
            <Title level={4}>{t("sideMenu.allSmsTemplates")}</Title>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {loading
              ? (
              <Spin tip={t("loading")} />
                )
              : (
              <TablePro
                loading={loading}
                emptyPlaceholder={
                  <Empty
                    description={
                      <span>
                        <Text strong>{t("noDataFound")}</Text>
                        <p>{t("There are no sms templates available")}</p>
                      </span>
                    }
                  />
                }
                onSearch={(value) => handleChangeQueryParams("search", value)}
                dataSource={smsTemplates}
                columns={columns}
                pagination={{
                  current: queryParams.page,
                  pageSize: queryParams.page_size,
                  total: count,
                  onChange: (page) => handleChangeQueryParams("page", page),
                  showSizeChanger: false
                }}
              />
                )}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default AllSmsTemplates;
