import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TablePro from "../../../../components/tables/TablePro";
import { useUserObject } from "../../../../stateManagement/slices/userSlice";
import { useFilterProductsMutation } from "../../../../stateManagement/apis/treecodeApi";

function AllProducts () {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const user = useUserObject();
  const [filterProducts] = useFilterProductsMutation();
  const getProducts = async () => {
    const result = await filterProducts({
      seller: user.user.user_id,
      page
    });
    if (result.data) {
      setProducts(result.data.data);
      setCount(result.data.count);
    }
  };

  const columns = [
    {
      title: t("id"),
      dataIndex: "id"
    },
    {
      title: t("name"),
      dataIndex: "name"
    },
    {
      title: t("price"),
      dataIndex: "price"
    }
  ];

  useEffect(() => {
    getProducts();
  }, []);
  return (
        <div>
            <div>
                <TablePro
                    columns={columns}
                    dataSource={products}
                    pagination={{
                      current: page,
                      total: count,
                      onChange: (page) => setPage(page)
                    }}
                />
            </div>
        </div>
  );
}

export default AllProducts;
