// import invokeDownload from "../utils/invokeDownload";
import store from "../stateManagement/store";
import { reportsApi } from "../stateManagement/apis/reportsApi";
import formattedError from "./errorFormatter";

const cardsColors = {
  total_orders: "#212121",
  received_orders: "#1f0765",
  archived_orders: "#babfc3",
  cash_orders: "#f8a306",
  total_cash: "#f8a306",
  online_orders: "#003ad2",
  total_online: "#003ad2",
  total_tickets: "#82be8e",
  on_delivery_tickets: "#57be6c",
  deliverd_tickets: "#148060",
  delivered_tickets: "#148060",
  in_hub_tickets: "#fec453",
  returned_tickets: "#871703",
  // financial
  debit: "#82be8e",
  credit: "#148060",
  collected: "#f8a306",
  shipment_fees: "#003ad2",
  not_collected: "#871703"
};

function convertKeyToTitle (key) {
  return key
    .split("_")
    .map((it) => `${it[0].toUpperCase()}${it.slice(1)}`)
    .join(" ");
}

function formatTickets (data) {
  const formattedTickets = Object.keys(data).reduce(
    (formatted, currentKey) => {
      return {
        ...formatted,
        [currentKey]: {
          title: convertKeyToTitle(currentKey),
          color: cardsColors[currentKey] ?? "#babfc3",
          value: data[currentKey]
        }
      };
    },
    {}
  );

  return formattedTickets;
}

export const branchReportsTypes = {
  performance: {
    title: "branch",
    endpoint: reportsApi.endpoints.generateBranchReport
  },
  courier: {
    title: "courier",
    endpoint: reportsApi.endpoints.generateCourierReport
  },
  financial: {
    title: "financial",
    endpoint: reportsApi.endpoints.generateFinancialReport
  }
};

export const storeReportsTypes = {
  seller: {
    title: "seller",
    endpoint: reportsApi.endpoints.generateSellerReport
  },
  financial: {
    title: "financial",
    endpoint: reportsApi.endpoints.generateFinancialReport
  }
};

const parseDate = (date) => date?.format("YYYY/MM/DD").replace(/\//g, "-");

const fetch = async (type, params) => {
  const res = await store.dispatch(type.endpoint.initiate(params));
  if (res.data) {
    return {
      success: true,
      data: res.data.summary
        ? { ...res.data, summary: formatTickets(res.data.summary) }
        : { summary: formatTickets(res.data) }
    };
  } else return formattedError(res, true);
};

const downloadTemplate = async (type, params) => {
  try {
    const res = await store.dispatch(type.endpoint.initiate({ ...params, type: "excel" }));
    if (res.data) {
      // invokeDownload(
      //   res.data,
      //           `${params.file_name ?? `${type.title} Report`}.xlsx`
      // );
      return {
        success: true,
        data: "Success"
      };
    } else return formattedError(res, true);
  } catch (e) {
    return formattedError(e);
  }
};
const get = async (type, params, isDownload) => {
  console.log({ params });
  const formattedParams = {
    ...params,
    seller: type.title === "financial" ? undefined : params.seller?.join(","),
    is_seller: type.title === "financial" ? params.seller?.[0] : undefined,
    branch: params.branch?.join(","),
    couriers: params.couriers?.join(","),
    start_date: parseDate(params.dates?.[0]),
    end_date: parseDate(params.dates?.[1])
  };
  delete formattedParams.dates;
  try {
    if (isDownload) return await downloadTemplate(type, formattedParams);
    else return await fetch(type, formattedParams, isDownload);
  } catch (e) {
    console.log("ya3aaaaaaaaaaaaaaaaam");
    const errorData = e.response?.data;

    console.log(typeof errorData);
    // if (errorData.constructor.name === "Blob") {
    console.log("eheheheheheeh");
    return {
      success: false,
      error: await errorData.text()
    };
    // }
    // else
    //     return formattedError(e);
  }
};

export const ordersDetailsRequest = {
  total_orders: {},
  deliverd_tickets: {
    state: "1"
  },
  delivered_tickets: {
    state: "1"
  },
  returned_tickets: {
    state: "4"
  },
  total_online: {
    payment: "1"
  },
  online_orders: {
    payment: "1"
  },
  total_cash: {
    payment: "2"
  },
  cash_orders: {
    payment: "2"
  }
};

const fetchTable = async (params) => {
  const res = store.dispatch(reportsApi.endpoints.orderDetailsReport.initiate(params));
  if (res.data) {
    return {
      success: true,
      data: res.data
    };
  } else return formattedError(res, true);
};

const downloadTable = async (params) => {
  try {
    const res = await store.dispatch(reportsApi.endpoints.orderDetailsReport.initiate({ ...params, type: "excel" }));
    if (res.data) {
      // invokeDownload(
      //   res.data,
      //           `${
      //               params.file_name
      //                   ? `${params.file_name} Detailed`
      //                   : "Orders Report"
      //           }.xlsx`
      // );
      return {
        success: true,
        data: "Success"
      };
    } else return formattedError(res, true);
  } catch (e) {
    return formattedError(e);
  }
};

const getTable = async (ticket, orders = [], params, isDownload) => {
  try {
    const orderParams = ordersDetailsRequest[ticket];
    if (!orderParams) {
      return {
        success: false,
        data: "No table found for this ticket"
      };
    }

    const formattedParams = {
      ...params,
      ...orderParams,
      orders: orders.join(","),
      start_date: parseDate(params.dates?.[0]),
      end_date: parseDate(params.dates?.[1]),
      dates: undefined
    };

    if (isDownload) return await downloadTable(formattedParams);
    else {
      return {
        ...(await fetchTable(formattedParams)),
        extra: {
          ticket,
          orders,
          params
        }
      };
    }
  } catch (e) {
    return formattedError(e);
  }
};

const ReportsRepository = {
  get: async (type, params, isDownload) => {
    const res = await get(type, params, isDownload);
    if (res.error) {
      const errorData = res.error;
      if (errorData.constructor.name === "Blob") {
        return {
          success: false,
          error: await errorData.text()
        };
      }
    }

    return res;
  },
  getTable
};

export default ReportsRepository;
