import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { INVENTORY_ORDER_TYPES } from "../../utils/beDict";

const initialState = {
  type: "default",
  seller: "",
  order_items: [],
  seller_address: "",
  location: null
};

const inventoryOrderSlice = createSlice({
  name: "inventoryOrder",
  initialState,
  reducers: {
    initiateInventoryOrder: (state, action) => {
      state.type = INVENTORY_ORDER_TYPES.RECIEVE;
      state.seller = "";
      state.order_items = [];
      state.seller_address = "";
      state.location = null;
    },
    setInventoryOrderSellerData: (state, action) => {
      state.seller = action.payload.seller;
      state.seller_address = action.payload.seller_address;
    },
    setInventoryOrderItemsData: (state, action) => {
      state.order_items = action.payload;
    },
    setInventoryOrderLocationAndType: (state, action) => {
      state.location = action.payload.location;
      state.type = action.payload.type;
    },
    setInventoryOrder: (state, action) => {
      state = { ...state, ...action.payload };
    }
  }
});

export const { initiateInventoryOrder, setInventoryOrderSellerData, setInventoryOrderItemsData, setInventoryOrderLocationAndType, setInventoryOrder } = inventoryOrderSlice.actions;
export const useInventoryOrder = () => useSelector((state) => state.inventoryOrder);
export default inventoryOrderSlice.reducer;
