import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { Spin } from "antd";
import { addOrder, useOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { useSelectedSeller } from "../../../../../stateManagement/slices/selectedSellerSlice";
import { UnSuccessfulRequest } from "../../../../../components/modals/confirmationModal";
import {
  useFilterAreasMutation,
  useFilterClientsMutation,
  useCreateClientMutation,
  useUpdateClientAddressMutation,
  useUpdateClientMutation
} from "../../../../../stateManagement/apis/treecodeApi";
import "react-toastify/dist/ReactToastify.css";
import { stringErrorsHandler } from "../../../../../api/ErrorNotifier";
import GenericForm from "../../../../../components/form/GenericForm";
import { useCustomerFields } from "../../../../../hooks/useFormFields";

export default function AdminCustomerInfo ({ value, handleChange }) {
  const { t } = useTranslation();
  const order = useOrder();
  const selectedSeller = useSelectedSeller();
  const [foundCustomer, setFoundCustomer] = useState(order.clientData ? { ...order.clientData, area: +order.clientData.area } : {});
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [filterAreas] = useFilterAreasMutation();
  const [filterClients] = useFilterClientsMutation();
  const [createClient] = useCreateClientMutation();
  const [updateClient] = useUpdateClientMutation();
  const [updateClientAddress] = useUpdateClientAddressMutation();

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required(t("required")),
    name: Yup.string().required(t("required")),
    area: Yup.string().required(t("required")),
    address: Yup.string().required(t("required"))
  });

  const fetchAreas = async () => {
    try {
      const { data } = await filterAreas({ page_size: 10000 });
      setAreas(data?.data || []);
    } catch {
      toast.error(t("areaFetchError"));
    } finally {
      setLoading(false);
    }
  };

  const fetchClients = async (phone) => {
    try {
      const { data } = await filterClients({ seller: selectedSeller?.id, phone });
      if (data) {
        const customer = data?.data?.[0];
        setFoundCustomer(customer
          ? formatClientData(customer)
          : {
              id: null,
              phone: "+966",
              name: "",
              phone_alt: "+966",
              area: null,
              address: null,
              address_id: null,
              lat: null,
              lng: null
            });
      }
    } catch {
      toast.error(t("clientFetchError"));
    }
  };

  const formatClientData = (customer) => ({
    id: customer.id,
    phone: customer.phone,
    name: customer.name,
    phone_alt: customer.phone_alt || "+966",
    area: customer.client_addresses[0]?.area.id,
    address: customer.client_addresses[0]?.address,
    address_id: customer.client_addresses[0]?.id,
    lat: customer.client_addresses[0]?.lat,
    lng: customer.client_addresses[0]?.lng
  });

  const handleSubmit = async (data) => {
    try {
      if (!selectedSeller) {
        setError(t("sellerIdRequired"));
        return;
      }

      let result;
      const requestData = {
        ...data,
        seller: selectedSeller.id,
        phone_alt: data.phone_alt === "+966" ? null : data.phone_alt,
        lat,
        lng
      };
      let clientSubmitData;
      let clientAddressSubmitData;
      let clientMethod = updateClient;
      console.log("Found Customer", foundCustomer);
      if (foundCustomer?.id) {
        console.log("foundCustomer", foundCustomer);
        clientAddressSubmitData = {
          addressId: foundCustomer.address_id,
          area: requestData.area,
          address: requestData.address,
          lat,
          lng
        };
        clientSubmitData = { clientId: foundCustomer.id, ...requestData };
      } else {
        clientSubmitData = requestData;
        clientMethod = createClient;
      }
      if (clientSubmitData) {
        console.log("clientSubmitData", clientSubmitData);
        result = await clientMethod(clientSubmitData);
        if (result.error?.data) {
          setError(stringErrorsHandler(result.error?.data));
          return;
        } else if (result.error) {
          setError(t("serverError"));
          return;
        }
      }
      if (clientAddressSubmitData) {
        result = await updateClientAddress(clientAddressSubmitData);
        if (result.error?.data) {
          setError(stringErrorsHandler(result.error?.data));
          return;
        } else if (result.error) {
          setError(t("serverError"));
          return;
        }
      }
      if (result && result.data) {
        dispatch(
          addOrder({
            ...order,
            clientData: requestData,
            client: foundCustomer
              ? foundCustomer.id
              : order.client || result.data.id,
            client_address: result.data.client_addresses?.[0]?.id,
            seller_address: order.seller_address
          })
        );
        handleChange(null, parseInt(value) + 1);
      } else {
        setError(
          stringErrorsHandler(result.error?.data) || t("createOrderError")
        );
      }
    } catch (err) {
      toast.error(t("unexpectedError"));
      setError(t("unexpectedError"));
    }
  };

  useEffect(() => {
    fetchAreas();
  }, []);

  const { fields, lng, lat } = useCustomerFields({
    selectedSeller,
    areas,
    getClients: fetchClients,
    handleChange,
    value
  });

  return (
    <div>
      <ToastContainer />
      {loading
        ? (
        <div className="centered-spinner">
          <Spin tip={t("loading")} size="large" />
        </div>
          )
        : selectedSeller
          ? (
        <GenericForm
          ref={formRef}
          fields={fields}
          submitFunction={handleSubmit}
          validationSchema={validationSchema}
          initialValues={foundCustomer}
          forceRerender={foundCustomer}
        />
            )
          : (
        <p>{t("noSellerSelected")}</p>
            )}
      {error && (
        <UnSuccessfulRequest
          show
          hide={() => setError(null)}
          title={t("createOrderError")}
          body={error}
        />
      )}
    </div>
  );
}
