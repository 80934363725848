import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { FormContext } from "../contexts/FormContext";
import { useSelectedSeller } from "../../stateManagement/slices/selectedSellerSlice";
import { useOrder } from "../../stateManagement/slices/addOrderSlice";

import "react-toastify/dist/ReactToastify.css";

export default function ModifiedForm ({
  children,
  defaultValues,
  submitFunction,
  listenToValues
}) {
  const selectedSeller = useSelectedSeller();

  const order = useOrder();
  const {
    register,
    formState,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues ?? {
      seller: selectedSeller?.id,
      seller_address: order?.seller_address,
      client_address: order?.client_address,
      client: order?.client,
      tickets: order?.tickets
    }
  });

  useEffect(() => {
    listenToValues?.(getValues);
  }, [getValues, listenToValues]);

  const onSubmit = async (data) => {
    console.log(`Form Data = ${JSON.stringify(data)}`);
    submitFunction(data);
  };

  const formStyles = {
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
  };

  return (
    <FormContext.Provider value={{ register, errors, control, formState, setValue }}>
      <form className="modifiedForm" onSubmit={handleSubmit(onSubmit)} style={formStyles}>
        {children}
        <ToastContainer position="bottom-right" />
      </form>
    </FormContext.Provider>
  );
}
