import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../../style-sheets/form.css";
import {
  useOrgEmployeesColumns,
  useSellerColumns,
  useCourierColumns,
  useThirdPartyColumns
} from "../../layout/tableColumns";
import { useUserObject } from "../../../stateManagement/slices/userSlice";
import { useGetBranchesMutation } from "../../../stateManagement/apis/treecodeApi";
import {
  useFilterOrgEmployeesMutation,
  useFilterCouriersMutation,
  useFilterSellersMutation,
  useFilter3rdPartyMutation,
  useDeleteThirdPartyMutation,
  useDeleteOrgEmployeeMutation,
  useDeleteSellerMutation
} from "../../../stateManagement/apis/accountsApi";
import UserTable from "./UserTable";

const TabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const a11yProps = (index) => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`
});

const AccountsTabs = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const user = useUserObject();
  const [value, setValue] = useState(state?.tabIndex || 0);
  const [allFilters, setAllFilters] = useState({
    branch: { title: t("branch"), selectedValue: undefined, values: [] },
    type: {
      title: t("userType"),
      selectedValue: undefined,
      values: [
        { id: 2, name: t("operation") },
        { id: 4, name: t("accountant") },
        { id: 5, name: t("inventoryManager") },
        { id: 6, name: t("operationTeamMember") }
      ]
    }
  });

  const [getBranchesApi] = useGetBranchesMutation();
  const [filterOrgEmployeesApi, { isLoading: isFilteringOrgEmployees }] = useFilterOrgEmployeesMutation();
  const [filterCouriersApi, { isLoading: isFilteringCouriers }] = useFilterCouriersMutation();
  const [filterSellersApi, { isLoading: isFilteringSellers }] = useFilterSellersMutation();
  const [filter3rdPartyApi, { isLoading: isFiltering3rdParty }] = useFilter3rdPartyMutation();
  const [deleteThirdPartyApi] = useDeleteThirdPartyMutation();
  const [deleteOrgEmployeeApi] = useDeleteOrgEmployeeMutation();
  const [deleteSellerApi] = useDeleteSellerMutation();

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const result = await getBranchesApi({ page_size: 100000 });
        setAllFilters((prevFilters) => ({
          ...prevFilters,
          branch: { ...prevFilters.branch, values: result.data?.data || [] }
        }));
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };
    fetchBranches();
  }, [getBranchesApi]);

  const handleTabChange = (_, newValue) => setValue(newValue);

  const onFilterChange = (key, value) => {
    setAllFilters((prevFilters) => ({
      ...prevFilters,
      [key]: { ...prevFilters[key], selectedValue: value }
    }));
  };

  const userTables = [
    {
      index: 0,
      label: t("orgUsers"),
      columns: useOrgEmployeesColumns(),
      userType: "manager",
      searchText: t("managerSearch"),
      fetchMethod: filterOrgEmployeesApi,
      deleteMethod: deleteOrgEmployeeApi,
      loading: isFilteringOrgEmployees,
      filters: { branch: allFilters.branch, type: allFilters.type }
    },
    {
      index: 1,
      label: t("seller"),
      columns: useSellerColumns(),
      userType: "seller",
      searchText: t("sellerSearch"),
      fetchMethod: filterSellersApi,
      deleteMethod: deleteSellerApi,
      loading: isFilteringSellers
    },
    {
      index: 2,
      label: t("courier"),
      columns: useCourierColumns(),
      userType: "courier",
      searchText: t("courierSearch"),
      fetchMethod: filterCouriersApi,
      deleteMethod: deleteOrgEmployeeApi,
      loading: isFilteringCouriers,
      filters: { branch: allFilters.branch }
    },
    {
      index: 3,
      label: t("thirdParty"),
      columns: useThirdPartyColumns(),
      userType: "thirdParty",
      searchText: t("thirdPartySearch"),
      fetchMethod: filter3rdPartyApi,
      deleteMethod: deleteThirdPartyApi,
      loading: isFiltering3rdParty
    }
  ];

  return (
    <div>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.accounts")}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("sideMenu.allAccounts")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("sideMenu.allAccounts")}</h2>
        </Col>
      </Row>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="accounts tabs"
            className={user.role === "Seller" ? "seller" : "admin"}
          >
            {userTables.map(({ index, label }) => (
              <Tab key={index} label={label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {userTables.map(
          ({ index, columns, userType, searchText, fetchMethod, deleteMethod, loading, filters }) => (
            <TabPanel key={index} value={value} index={index}>
              <UserTable
                columns={columns}
                userType={userType}
                searchText={searchText}
                fetchMethod={fetchMethod}
                deleteMethod={deleteMethod}
                loading={loading}
                filters={filters}
                onFilterChange={onFilterChange}
              />
            </TabPanel>
          )
        )}
      </Box>
    </div>
  );
};

export default AccountsTabs;
