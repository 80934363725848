import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TablePro from "../tables/TablePro";
import { useFilterRunsheetsMutation } from "../../stateManagement/apis/runsheetsApi";
import { useRunSheetColumns } from "./tableColumns";
import CourierDetails from "./courierDetails";
import ArchivedCourierCard from "./ArchivedCourierCard";

function ArchivedRunsheets () {
  const { t } = useTranslation();
  const [currentRunSheetCourier, setCurrentRunSheetCourier] = useState();
  const columns = useRunSheetColumns({ selectable: true });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(1);
  const [courierDetails] = useState({});
  const [filterRunsheets, { isLoading: loading }] = useFilterRunsheetsMutation();

  const onClose = (e) => {
    setShow(e);
  };

  const getArchived = async () => {
    const result = await filterRunsheets({
      state: 4,
      page
    });
    if (result.data) {
      setData(result.data.data ?? []);
      setCount(result.data.count);
    }
  };
  useEffect(() => {
    getArchived();
  }, [page]);

  return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.runsheets")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.archived")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.archived")}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm="12" style={{ position: "relative" }}>
                    <ArchivedCourierCard
                        current={currentRunSheetCourier}
                        onClose={() => setCurrentRunSheetCourier(undefined)}
                    />
                    <div>
                        <TablePro
                            loading={loading}
                            dataSource={data}
                            columns={columns}
                            pagination={{
                              current: page,
                              total: count,
                              onChange: (page) => setPage(page)
                            }}
                            onRowClick={(i, record) =>
                              setCurrentRunSheetCourier(record.courier)
                            }
                        />
                        {show
                          ? (
                            <CourierDetails
                                setClose={onClose}
                                data={courierDetails}
                                show={show}
                            />
                            )
                          : null}
                    </div>
                </Col>
            </Row>
        </>
  );
}

export default ArchivedRunsheets;
