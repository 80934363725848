import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import { notification, Form as AntForm, Button, Input, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";

import InventoryItemLocationsModal from "../../modals/InventoryItemLocationsModal";
import { useGetInventoryItemAvailabilityMutation } from "../../../stateManagement/apis/inventoryApi";
import { useAcceptFromStockMutation, useGetOrderMutation } from "../../../stateManagement/apis/ordersApi";
import { notifyErrors, stringErrorsHandler } from "../../../api/ErrorNotifier";

const AcceptFromStockOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const [acceptForm] = AntForm.useForm();
  const [order, setOrder] = useState();
  const [dirty, setDirty] = useState(false);
  const [backEndErrors, setBackEndErrors] = useState([]);
  const [getInventoryItemAvailability, { isLoading: fetchLoading }] = useGetInventoryItemAvailabilityMutation();
  const [acceptFromStock, { isLoading: submitting }] = useAcceptFromStockMutation();
  const [getOrderApi, { isLoading: loading }] = useGetOrderMutation();

  const handleChangeInventoryLocations = (
    fieldName,
    random_id,
    location,
    quantity,
    ticket,
    inventory_order
  ) => {
    const oldTicket = acceptForm.getFieldValue("tickets");
    const oldLocations = oldTicket[fieldName].inventory_locations || [];
    const oldLocationIndex = oldLocations?.findIndex(
      (loc) => loc.random_id === random_id
    );
    if (oldLocationIndex === -1) {
      oldLocations.push({
        random_id,
        location,
        quantity,
        ticket,
        inventory_order
      });
    } else {
      oldLocations[oldLocationIndex] = {
        random_id,
        location,
        quantity,
        ticket,
        inventory_order
      };
    }
    oldTicket[fieldName].inventory_locations = oldLocations;
    acceptForm.setFieldsValue({ tickets: oldTicket });
    setDirty(true);
  };

  const hideInventoryLocationsModal = (fieldName) => {
    const oldTicket = acceptForm.getFieldValue("tickets");
    oldTicket[fieldName].showInventoryLocationsModal = false;
    acceptForm.setFieldsValue({ tickets: oldTicket });
  };

  const showInventoryLocationsModal = (fieldName) => {
    const oldTicket = acceptForm.getFieldValue("tickets");
    oldTicket[fieldName].showInventoryLocationsModal = true;
    acceptForm.setFieldsValue({ tickets: oldTicket });
  };

  const fetchInventoryForItem = async (fieldName) => {
    const oldTicket = acceptForm.getFieldValue("tickets");
    console.log("CURRENT FIELD VALUE", oldTicket[fieldName]);
    const result = await getInventoryItemAvailability(
      oldTicket[fieldName].product.inventory_item,
      "TICKET",
      {
        page: acceptForm.getFieldValue([fieldName, "location_page"]),
        page_size: 10
      }
    );
    if (result.data) {
      oldTicket[fieldName].inventory_item_locations = result.data.data;
      oldTicket[fieldName].location_page = result.data.page;
      oldTicket[fieldName].location_count = result.data.count;
      acceptForm.setFieldsValue({ tickets: oldTicket });
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors(t("Error from server"));
    }
  };

  const getOrder = async () => {
    const result = await getOrderApi(orderId);
    if (result.data) {
      setOrder(result.data);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const onFinish = async (values) => {
    console.log("FORM VALUES", values);
    const response = await acceptFromStock({ orderId, ...values });
    if (response.data) {
      notification.success({ message: t("Order accepted successfully") });
      setTimeout(() => navigate("/dashboard"), 2000);
    } else if (response.error?.data) {
      setBackEndErrors([stringErrorsHandler(response.error.data)]);
      setDirty(false);
    } else {
      notifyErrors(t("Error from server"));
    }
  };

  return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">
                            {t("From Stock Orders") + " >"}
                        </span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title1">
                            {t("Accept From Stock Order")}
                        </span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("Order Number") + ": " + orderId}
                        </span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {t("Accept From Stock Order")}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <div>
                        {(loading || !order) && (
                            <div
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ height: "50vh" }}
                            >
                                {" "}
                                <Spin size="large" />{" "}
                            </div>
                        )}
                        {!loading && order?.id && (
                            <div>
                                <AntForm
                                    layout="vertical"
                                    className="grid-form small-gap"
                                    onFinish={onFinish}
                                    form={acceptForm}
                                    // onFinishFailed={onFinishFailed}
                                    initialValues={order}
                                    requiredMark
                                    labelWrap
                                >
                                    <AntForm.List
                                        name="tickets"
                                        initialValue={order.tickets}
                                    >
                                        {(
                                          fields,
                                          { add, remove },
                                          { errors }
                                        ) => (
                                            <>
                                                {fields.map(
                                                  (
                                                    {
                                                      key: fieldKey,
                                                      name: fieldName,
                                                      ...restField
                                                    },
                                                    index
                                                  ) => (
                                                        <div
                                                            key={index}
                                                            className="d-grid gap-2 border p-3"
                                                        >
                                                            <AntForm.Item
                                                                name={[
                                                                  fieldName,
                                                                  "ID"
                                                                ]}
                                                                label={t(
                                                                  "Ticket ID"
                                                                )}
                                                                disabled
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    disabled
                                                                    defaultValue={
                                                                        acceptForm.getFieldValue(
                                                                          "tickets"
                                                                        )[
                                                                          fieldName
                                                                        ].id
                                                                    }
                                                                />
                                                            </AntForm.Item>
                                                            <AntForm.Item
                                                                name={[
                                                                  fieldName,
                                                                  "inventory_item"
                                                                ]}
                                                                label={t(
                                                                  "Inventory Item"
                                                                )}
                                                                disabled
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    disabled
                                                                    defaultValue={
                                                                        acceptForm.getFieldValue(
                                                                          "tickets"
                                                                        )[
                                                                          fieldName
                                                                        ]
                                                                          .product
                                                                          .name
                                                                    }
                                                                />
                                                            </AntForm.Item>
                                                            <AntForm.Item
                                                                name={[
                                                                  fieldName,
                                                                  "quantity"
                                                                ]}
                                                                label={t(
                                                                  "Quantity"
                                                                )}
                                                                disabled
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    disabled
                                                                    value={acceptForm.getFieldValue(
                                                                      [
                                                                        fieldName,
                                                                        "quantity"
                                                                      ]
                                                                    )}
                                                                />
                                                            </AntForm.Item>
                                                            <AntForm.Item
                                                                name={[
                                                                  fieldName,
                                                                  "location"
                                                                ]}
                                                                label={t(
                                                                  "Location"
                                                                )}
                                                                rules={[
                                                                  {
                                                                    validator:
                                                                            async (
                                                                              _,
                                                                              value
                                                                            ) => {
                                                                              const sumQuantity =
                                                                                    acceptForm
                                                                                      .getFieldValue(
                                                                                        "tickets"
                                                                                      )[fieldName].inventory_locations?.reduce(
                                                                                        (
                                                                                          acc,
                                                                                          loc
                                                                                        ) =>
                                                                                          acc +
                                                                                                loc.quantity,
                                                                                        0
                                                                                      );
                                                                              if (
                                                                                sumQuantity !==
                                                                                    acceptForm.getFieldValue(
                                                                                      "tickets"
                                                                                    )[
                                                                                      fieldName
                                                                                    ]
                                                                                      .quantity
                                                                              ) {
                                                                                return Promise.reject(new Error("Location quantity must match ticket quantity"));
                                                                              }
                                                                            }
                                                                  }
                                                                ]}
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    loading={
                                                                        fetchLoading
                                                                    }
                                                                    onClick={async () => {
                                                                      await fetchInventoryForItem(
                                                                        fieldName
                                                                      );
                                                                      showInventoryLocationsModal(
                                                                        fieldName
                                                                      );
                                                                    }}
                                                                >
                                                                    {t(
                                                                      "Select Locations"
                                                                    )}
                                                                </Button>
                                                            </AntForm.Item>
                                                            <div>
                                                                <div className="d-flex flex-column gap-2 shadow-sm p-3">
                                                                    {acceptForm
                                                                      .getFieldValue(
                                                                        "tickets"
                                                                      )[fieldName].inventory_locations?.map(
                                                                        (
                                                                          location
                                                                        ) => (
                                                                                <div key={location.location} className="d-flex justify-content-between gap-2">
                                                                                    <span>
                                                                                        {t(
                                                                                          "Location ID"
                                                                                        )}

                                                                                        :{" "}
                                                                                        {
                                                                                            location.location
                                                                                        }
                                                                                    </span>
                                                                                    <span>
                                                                                        {t(
                                                                                          "Quantity"
                                                                                        )}

                                                                                        :{" "}
                                                                                        {
                                                                                            location.quantity
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                        )
                                                                      )}
                                                                    <div className="d-flex justify-content-end gap-2">
                                                                        <span>
                                                                            {t(
                                                                              "Total Quantity"
                                                                            )}
                                                                            :{" "}
                                                                            {acceptForm
                                                                              .getFieldValue(
                                                                                "tickets"
                                                                              )[fieldName].inventory_locations?.reduce(
                                                                                (
                                                                                  acc,
                                                                                  loc
                                                                                ) =>
                                                                                  acc +
                                                                                        loc.quantity,
                                                                                0
                                                                              )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <InventoryItemLocationsModal
                                                                handleChangeInventoryLocations={(
                                                                  ...args
                                                                ) =>
                                                                  handleChangeInventoryLocations(
                                                                    fieldName,
                                                                    ...args
                                                                  )
                                                                }
                                                                hideModal={() =>
                                                                  hideInventoryLocationsModal(
                                                                    fieldName
                                                                  )
                                                                }
                                                                count={
                                                                    acceptForm.getFieldValue(
                                                                      "tickets"
                                                                    )[fieldName]
                                                                      .location_count
                                                                }
                                                                page={
                                                                    acceptForm.getFieldValue(
                                                                      "tickets"
                                                                    )[fieldName]
                                                                      .location_page
                                                                }
                                                                fetchLoading={
                                                                    fetchLoading
                                                                }
                                                                inventoryItemLocations={
                                                                    acceptForm.getFieldValue(
                                                                      "tickets"
                                                                    )[fieldName]
                                                                      .inventory_item_locations
                                                                }
                                                                orderItem={
                                                                    acceptForm.getFieldValue(
                                                                      "tickets"
                                                                    )[fieldName]
                                                                }
                                                                setPage={async (
                                                                  page
                                                                ) => {
                                                                  const oldTicket =
                                                                        acceptForm.getFieldValue(
                                                                          "tickets"
                                                                        );
                                                                  oldTicket[
                                                                    fieldName
                                                                  ].location_page =
                                                                        page;
                                                                  acceptForm.setFieldsValue(
                                                                    {
                                                                      tickets:
                                                                                oldTicket
                                                                    }
                                                                  );
                                                                  await fetchInventoryForItem(
                                                                    fieldName
                                                                  );
                                                                }}
                                                                open={
                                                                    acceptForm.getFieldValue(
                                                                      "tickets"
                                                                    )[fieldName]
                                                                      .showInventoryLocationsModal
                                                                }
                                                            />
                                                        </div>
                                                  )
                                                )}
                                            </>
                                        )}
                                    </AntForm.List>
                                </AntForm>
                                <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                                    {backEndErrors.map((error, ind) => (
                                        <p className="error" key={ind}>
                                            {error}
                                        </p>
                                    ))}
                                </div>

                                <div className="w-100 d-flex justify-content-end py-4">
                                    <AntForm.Item>
                                        <Button
                                            htmlType="submit"
                                            style={{
                                              backgroundColor: "#57BE6C",
                                              color: "#fff",
                                              borderColor: "#57BE6C"
                                            }}
                                            type="primary"
                                            loading={submitting}
                                            disabled={submitting || !dirty}
                                            onClick={() => acceptForm.submit()}
                                        >
                                            {t("Accept Order")}
                                        </Button>
                                    </AntForm.Item>
                                </div>
                            </div>
                        )}
                    </div>
                </Row>
            </Container>
        </div>
  );
};

export default AcceptFromStockOrder;
