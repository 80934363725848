import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import CommonReportsPage from "../CommonReportsPage";
import { branchReportsTypes } from "../../../../api/ReportsRepository";
import { useGetBranchesMutation } from "../../../../stateManagement/apis/treecodeApi";
import { useFilterCouriersMutation } from "../../../../stateManagement/apis/accountsApi";

export default function BranchReportsPage () {
  const [selectedType, setSelectedType] = useState();
  const [branches, setBranches] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [courierSearch, setCourierSearch] = useState("");
  const [getBranchesApi] = useGetBranchesMutation();
  const [filterCouriersApi] = useFilterCouriersMutation();
  const { t } = useTranslation();

  // Fetch branches data
  useEffect(() => {
    async function fetchBranches () {
      try {
        const res = await getBranchesApi({ page_size: 1000 });
        if (res.data) setBranches(res.data?.data ?? []);
      } catch (error) {
        console.error("Error fetching branches", error);
      }
    }
    fetchBranches();
  }, [getBranchesApi]);

  // Fetch couriers data when the search query changes
  useEffect(() => {
    async function fetchCouriers () {
      try {
        const res = await filterCouriersApi({ page_size: 1000, search: courierSearch });
        if (res.data) setCouriers(res.data.data ?? []);
      } catch (error) {
        console.error("Error fetching couriers", error);
      }
    }

    if (selectedType === branchReportsTypes.courier) {
      fetchCouriers();
    }
  }, [courierSearch, selectedType, filterCouriersApi]);

  // Define fields dynamically based on selected report type
  const fields = [
    {
      name: "branch",
      label: t("reports.branch_name"),
      component: Select,
      props: {
        mode: "multiple",
        placeholder: t("reports.branch_name_ph"),
        options: branches.map((branch) => ({
          value: branch.id,
          label: branch.name
        }))
      },
      rules: [{ required: true, message: t("reports.branch_name_req") }]
    },
    {
      name: "type",
      label: t("reports.report_type"),
      component: Select,
      props: {
        placeholder: t("reports.report_type_ph"),
        options: Object.keys(branchReportsTypes).map((key) => ({
          value: key,
          label: t(`reports.branch_list.${branchReportsTypes[key].title}`)
        })),
        onChange: (value) => setSelectedType(branchReportsTypes[value])
      },
      rules: [{ required: true, message: t("reports.report_type_req") }]
    },
    selectedType === branchReportsTypes.courier && {
      name: "couriers",
      label: t("reports.courier_name"),
      component: Select,
      props: {
        mode: "multiple",
        placeholder: t("reports.courier_name_ph"),
        options: couriers.map((courier) => ({
          value: courier.id,
          label: courier.user.name
        })),
        showSearch: true,
        onSearch: setCourierSearch
      },
      rules: [{ required: true, message: t("reports.courier_name_req") }]
    }
  ].filter(Boolean);

  return (
    <CommonReportsPage
      formFields={fields}
      selectedType={selectedType}
      section={t("reports.branch_reports")}
    />
  );
}
