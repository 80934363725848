import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/form.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RunSheetInfo from "../forms/runsheetInfo";
import RunsheetOrders from "../forms/runsheetOrders";
import RunsheetCourier from "../forms/runsheetCourier";
import RunsheetRequest from "../forms/runsheetRequest";
import { isReAssigned, useReassignedRS } from "../../stateManagement/slices/reassignedRSSlice";
import { useUserObject } from "../../stateManagement/slices/userSlice";

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const CreateRunsheet = ({ isReAssignedProp, removeOrders, ...props }) => {
  const dispacthReassign = useDispatch();

  const [value, setValue] = useState(0);
  const reAssignedRS = useReassignedRS();
  const navigate = useNavigate();
  useEffect(() => {
    console.log({ reAssignedRS });
    if (!reAssignedRS && isReAssignedProp) navigate("/assigned_rs");
  }, [reAssignedRS]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const user = useUserObject();
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      dispacthReassign(isReAssigned(false));
    };
  }, []);

  return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.runsheets")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    {isReAssignedProp
                      ? (
                        <span className="title2">{t("reassignRunsheet")}</span>
                        )
                      : (
                        <span className="title2">{t("createNewRunsheet")}</span>
                        )}
                </Col>
                <Col sm="12">
                    {isReAssignedProp
                      ? (
                        <h2 className="pageTitle">{t("reassignRunsheet")}</h2>
                        )
                      : (
                        <h2 className="pageTitle">{t("createNewRunsheet")}</h2>
                        )}
                </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {isReAssignedProp && !removeOrders
                  ? (
                        <>
                            <Tabs
                                value={value}
                                aria-label="basic tabs example"
                                className={`${
                                    user.role === "Seller" ? "seller" : "admin"
                                }`}
                            >
                                <Tab
                                    label={t("runsheetInfo")}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={t("chooseCourier")}
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    label={t("reviewRequest")}
                                    {...a11yProps(2)}
                                />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <RunsheetOrders
                                    value={value}
                                    isReAssigned={isReAssigned}
                                    handleChange={handleChange}
                                />
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <RunsheetCourier
                                    value={value}
                                    isReAssigned={isReAssigned}
                                    handleChange={handleChange}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <RunsheetRequest
                                    value={value}
                                    isReAssigned={isReAssigned}
                                    handleChange={handleChange}
                                    skipDirty
                                />
                            </TabPanel>
                        </>
                    )
                  : removeOrders
                    ? (
                        <>
                            <Tabs
                                value={value}
                                aria-label="basic tabs example"
                                className={`${
                                    user.role === "Seller" ? "seller" : "admin"
                                }`}
                            >
                                <Tab
                                    label={t("runsheetInfo")}
                                    {...a11yProps(0)}
                                />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <RunsheetOrders
                                    value={value}
                                    isReAssigned={isReAssigned}
                                    handleChange={handleChange}
                                    removeOrders={removeOrders}
                                />
                            </TabPanel>
                        </>
                      )
                    : (
                        <>
                            <Tabs
                                className={`${
                                    user.role === "Seller" ? "seller" : "admin"
                                }`}
                                value={value}
                                aria-label="basic tabs example"
                            >
                                {" "}
                                <Tab label={t("createRs")} {...a11yProps(0)} />
                                <Tab
                                    label={t("runsheetInfo")}
                                    {...a11yProps(1)}
                                />
                                {/* <Tab
                                    label={t("chooseCourier")}
                                    {...a11yProps(2)}
                                /> */}
                                <Tab
                                    label={t("reviewRequest")}
                                    {...a11yProps(2)}
                                />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <RunsheetOrders
                                    value={value}
                                    handleChange={handleChange}
                                />
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <RunSheetInfo
                                    value={value}
                                    handleChange={handleChange}
                                />
                            </TabPanel>
                            {/*
                            <TabPanel value={value} index={2}>
                                <RunsheetCourier
                                    value={value}
                                    handleChange={handleChange}
                                />
                            </TabPanel> */}
                            <TabPanel value={value} index={2}>
                                <RunsheetRequest
                                    value={value}
                                    handleChange={handleChange}
                                    skipDirty
                                />
                            </TabPanel>
                        </>
                      )}
                </Box>
            </Box>
        </div>
  );
};

export default CreateRunsheet;
