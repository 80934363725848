import { createApi } from "@reduxjs/toolkit/query/react";
import { ACCOUNTS_API, SELLERS_API, THIRD_PARTY_API, COURIER_WALLET_API } from "../constants";
import { reAuthBaseQuery } from "./baseQuery";

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["Accounts", "Sellers", "Couriers", "HistoricalEmployees", "OrgEmployees", "ThirdParty"],
  endpoints: (builder) => ({
    getSellers: builder.mutation({
      query: () => ({
        url: SELLERS_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["Sellers"]
    }),
    getSeller: builder.mutation({
      query: (sellerId) => ({
        url: `${SELLERS_API.ROOT}/${sellerId}`,
        method: "GET"
      }),
      providesTags: ["Sellers"]
    }),
    createSeller: builder.mutation({
      query: (body) => ({
        url: SELLERS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Sellers"]
    }),
    updateSeller: builder.mutation({
      query: ({ sellerId, ...body }) => ({
        url: `${SELLERS_API.ROOT}/${sellerId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Sellers"]
    }),
    deleteSeller: builder.mutation({
      query: (sellerId) => ({
        url: `${SELLERS_API.ROOT}/${sellerId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Sellers"]
    }),
    filterSellers: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: SELLERS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Sellers"]
    }),
    createSeller3rdParty: builder.mutation({
      query: (id) => ({
        url: `${SELLERS_API.CREATE_3RD_PARTY}/${id}`,
        method: "POST"
      }),
      invalidatesTags: ["Sellers"]
    }),
    getSellerAddress: builder.mutation({
      query: ({ sellerAddressId, page = 1, limit = 10, ...queryParameters }) => ({
        url: `${SELLERS_API.FILTER_SELLER_ADDRESS}`,
        method: "GET",
        params: {
          seller__id: sellerAddressId,
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Sellers"]
    }),
    createSellerAddress: builder.mutation({
      query: (body) => ({
        url: SELLERS_API.SELLER_ADDRESS + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Sellers"]
    }),
    updateSellerAddress: builder.mutation({
      query: ({ sellerAddressId, ...body }) => ({
        url: `${SELLERS_API.SELLER_ADDRESS}/${sellerAddressId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Sellers"]
    }),
    bulkUpdateSellerAddress: builder.mutation({
      query: (body) => ({
        url: SELLERS_API.SELLER_ADDRESS + "/",
        method: "PUT",
        body
      }),
      invalidatesTags: ["Sellers"]
    }),
    getSellersWallets: builder.mutation({
      query: (queryParameters) => ({
        url: SELLERS_API.SELLER_WALLETS,
        method: "GET",
        params: queryParameters
      }),
      providesTags: ["Sellers"]
    }),

    // Couriers
    filterCouriers: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: ACCOUNTS_API.FILTER_COURIERS,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Couriers"]
    }),
    changeCourierPassword: builder.mutation({
      query: ({ courierId, ...body }) => ({
        url: `${ACCOUNTS_API.CHANGE_COURIER_PASSWORD}/${courierId}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Couriers"]
    }),

    filterHistoricalEmployees: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: ACCOUNTS_API.FILTER_HISTORY_EMPLOYEES,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["HistoricalEmployees"]
    }),

    // Org Employees
    getOrgEmployees: builder.mutation({
      query: () => ({
        url: ACCOUNTS_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["OrgEmployees"]
    }),
    getOrgEmployee: builder.mutation({
      query: (orgEmployeeId) => ({
        url: `${ACCOUNTS_API.ROOT}/${orgEmployeeId}`,
        method: "GET"
      }),
      providesTags: ["OrgEmployees"]
    }),
    createOrgEmployee: builder.mutation({
      query: (body) => ({
        url: ACCOUNTS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["OrgEmployees"]
    }),
    updateOrgEmployee: builder.mutation({
      query: ({ orgEmployeeId, ...body }) => ({
        url: `${ACCOUNTS_API.ROOT}/${orgEmployeeId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["OrgEmployees"]
    }),
    deleteOrgEmployee: builder.mutation({
      query: (orgEmployeeId) => ({
        url: `${ACCOUNTS_API.ROOT}/${orgEmployeeId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["OrgEmployees"]
    }),
    filterOrgEmployees: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: ACCOUNTS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["OrgEmployees"]
    }),

    // Third Party
    getMyThirdParty: builder.mutation({
      query: () => ({
        url: THIRD_PARTY_API.MY_THIRD_PARTY,
        method: "GET"
      }),
      providesTags: ["ThirdParty"]
    }),
    deleteMyThirdParty: builder.mutation({
      query: () => ({
        url: THIRD_PARTY_API.MY_THIRD_PARTY,
        method: "DELETE"
      }),
      invalidatesTags: ["ThirdParty"]
    }),
    getThirdParty: builder.mutation({
      query: (id) => ({
        url: `${THIRD_PARTY_API.ROOT}/${id}`,
        method: "GET"
      }),
      providesTags: ["ThirdParty"]
    }),
    createThirdParty: builder.mutation({
      query: (body) => ({
        url: THIRD_PARTY_API.ROOT,
        method: "POST",
        body
      }),
      invalidatesTags: ["ThirdParty"]
    }),
    updateThirdParty: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${THIRD_PARTY_API.ROOT}/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["ThirdParty"]
    }),
    deleteThirdParty: builder.mutation({
      query: (id) => ({
        url: `${THIRD_PARTY_API.ROOT}/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["ThirdParty"]
    }),
    regenerateThirdPartyKeys: builder.mutation({
      query: (id, body) => ({
        url: `${THIRD_PARTY_API.REGENERATE_KEYS}/${id}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["ThirdParty"]
    }),
    getMyWebhooks: builder.mutation({
      query: () => ({
        url: THIRD_PARTY_API.MY_WEBHOOKS,
        method: "GET"
      }),
      providesTags: ["ThirdParty"]
    }),
    updateMyWebhooks: builder.mutation({
      query: (body) => ({
        url: THIRD_PARTY_API.MY_WEBHOOKS,
        method: "PUT",
        body
      }),
      invalidatesTags: ["ThirdParty"]
    }),
    Filter3rdParty: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: THIRD_PARTY_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["ThirdParty"]
    }),

    reactivateEmployee: builder.mutation({
      query: (employeeId) => ({
        url: `${ACCOUNTS_API.REACTIVATE_EMPLOYEE}/${employeeId}`,
        method: "POST"
      }),
      invalidatesTags: ["OrgEmployees"]
    }),
    getCourierWallets: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: COURIER_WALLET_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["CourierWallets"]
    }),
    getCourierWallet: builder.mutation({
      query: (courierWalletId) => ({
        url: `${COURIER_WALLET_API.ROOT}/${courierWalletId}`,
        method: "GET"
      }),
      providesTags: ["CourierWallets"]
    }),
    settleCourierBalance: builder.mutation({
      query: ({ courierWalletId, ...body }) => ({
        url: `${COURIER_WALLET_API.ROOT}/${courierWalletId}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: ["CourierWallets"]
    })
  })
});

export const {
  useGetSellersMutation,
  useGetSellerMutation,
  useCreateSellerMutation,
  useUpdateSellerMutation,
  useDeleteSellerMutation,
  useFilterSellersMutation,
  useFilterCouriersMutation,
  useFilterHistoricalEmployeesMutation,
  useGetOrgEmployeesMutation,
  useGetOrgEmployeeMutation,
  useCreateOrgEmployeeMutation,
  useUpdateOrgEmployeeMutation,
  useDeleteOrgEmployeeMutation,
  useFilterOrgEmployeesMutation,
  useCreateSeller3rdPartyMutation,
  useDeleteMyThirdPartyMutation,
  useGetThirdPartyMutation,
  useUpdateThirdPartyMutation,
  useRegenerateThirdPartyKeysMutation,
  useGetMyWebhooksMutation,
  useUpdateMyWebhooksMutation,
  useChangeCourierPasswordMutation,
  useGetMyThirdPartyMutation,
  useCreateThirdPartyMutation,
  useGetSellerAddressMutation,
  useCreateSellerAddressMutation,
  useUpdateSellerAddressMutation,
  useGetSellersWalletsMutation,
  useReactivateEmployeeMutation,
  useFilter3rdPartyMutation,
  useDeleteThirdPartyMutation,
  useBulkUpdateSellerAddressMutation,
  useGetCourierWalletsMutation,
  useGetCourierWalletMutation,
  useSettleCourierBalanceMutation

} = accountsApi;
