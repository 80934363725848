import React from "react";
import { Row, Col } from "react-bootstrap";

export default function InformationCard ({
  title,
  subTitle,
  list,
  list2,
  subTitle2
}) {
  return (
    <div className={list2 ? "informationCard2" : "informationCard"}>
      {list2
        ? (
        <>
          <Row>
            <Col sm="1">
              <i id="TooltipExample" className="fas fa-info-circle"></i>
            </Col>
            <Col sm="11">
              <div className="informationCardTitle">{title}</div>
              <div className="informationCardSubtitle">{subTitle}</div>
              <ul>
                {list.map((item) => (
                  <li key={item} className="item">
                    {item}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col sm="1"></Col>
            <Col sm="11">
              <div className="informationCardSubtitle">{subTitle2}</div>
              <ul>
                {list2.map((item) => (
                  <li key={item} className="item">
                    {item}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </>
          )
        : (
        <>
          <div>
            <i id="TooltipExample" className="fas fa-info-circle ml-5"></i>
          </div>
          <div>
            <div className="informationCardTitle">{title}</div>
            <div className="informationCardSubtitle">{subTitle}</div>
            {list && (
              <ul>
                {list.map((item) => (
                  <li key={item} className="item">
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
          )}
    </div>
  );
}
