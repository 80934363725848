import { mdiDotsHorizontal } from "@mdi/js";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDelete, UnSuccessfulRequest } from "../modals/confirmationModal";
import { stringErrorsHandler } from "../../api/ErrorNotifier";

function ThreeDotsMenu ({
  data,
  deleted,
  setDeleted,
  setEdited,
  onClick,
  method,
  loading,
  getData
}) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [error, setError] = useState("");
  const [showFailed, setShowFailed] = useState(false);

  const deleteEntity = async () => {
    const result = await method(data.id);
    if (result.error?.data) {
      setError(stringErrorsHandler(result.error.data));
      setShowFailed(true);
      setShowDelete(false);
    } else if (result.error) {
      setError("Something went wrong");
      setShowFailed(true);
      setShowDelete(false);
    } else {
      setDeleted(!deleted);
      setShowDelete(false);
      getData();
    }
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    if (onClick) {
      onClick();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hide = () => {
    setShowDelete(false);
    setShowFailed(false);
  };

  return (
        <div className="actionMenu">
            <Button onClick={handleClick}>
                <Icon path={mdiDotsHorizontal} size={1.2} />
            </Button>
            <Menu
                id="card-actions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                      setEdited(true);
                      handleClose();
                    }}
                >
                    <i className="fas fa-pen my-3 mx-2"></i>
                    {t("edit")}
                </MenuItem>
                {/* {deleted && ( */}
                {/*    <MenuItem */}
                {/*        onClick={() => { */}
                {/*            setShowDelete(true); */}
                {/*            handleClose(); */}
                {/*        }} */}
                {/*    > */}
                {/*        <i className="fas fa-trash my-3 mx-2"></i> */}
                {/*        {t("delete")} */}
                {/*    </MenuItem> */}
                {/* )} */}
            </Menu>
            <ConfirmDelete
                hide={hide}
                deleteEntity={deleteEntity}
                title={t("deleteProcess")}
                body={t("deleteText")}
                show={showDelete}
                loading={loading}
            />
            <UnSuccessfulRequest
                title={t("deleteProcess")}
                show={showFailed}
                hide={hide}
                body={error}
            />
        </div>
  );
}

export default ThreeDotsMenu;
