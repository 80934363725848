import React from "react";
import { Button as AntButton } from "antd";
import styles from "./WMButton.module.css";

export default function SecondaryWMButton ({
  className,
  type,
  icon,
  iconAlt,
  iconPosition,
  ...rest
}) {
  return (
        <AntButton
            className={[
              styles.secondary,
              "row-centered",
              iconPosition === "end" ? styles.iconEnd : "",
              type ? `button-${type}` : "button-default",
              className
            ].join(" ")}
            icon={icon}
            {...rest}
        />
  );
}
