import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../../style-sheets/form.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SellerInfoForm from "../../forms/sellerInfoForm";
import { addOrder } from "../../../stateManagement/slices/addOrderSlice";
import { useUserObject } from "../../../stateManagement/slices/userSlice";
import ProductInfoTable from "./ProductInfoTable";
import ReviewReturnRequest from "./ReviewReturnRequest";
import ReturnRequestType from "./ReturnType";

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const TicketsReturnRequestTabs = () => {
  const [value, setValue] = useState(0);
  const [selectedProduct] = useState([]);
  const [returnedObj, setReturnedTicketsObj] = useState("");
  const [returnType, setReturnType] = useState("RETURN");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const selectedSeller = useSelector(
  //   (state) => state.selectedData.selectedSeller
  // );
  const user = useUserObject();

  useEffect(() => {
    //  console.log(selectedProduct)
  }, [selectedProduct]);

  const getReturnedTicketsObject = (e) => {
    console.log(e);
    setReturnedTicketsObj(e);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addOrder({}));
  }, []);

  return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.tickets")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.returned")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("createReturnRequest")}</h2>
                </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        // onChange={handleChange}
                        aria-label="basic tabs example"
                        className={`${
                            user.role === "Seller" ? "seller" : "admin"
                        }`}
                    >
                        <Tab label={t("Select_Return_Type")} {...a11yProps(0)} />
                        <Tab label={t("selectTickets")} {...a11yProps(1)} />
                        <Tab label={t("sellerInfo")} {...a11yProps(2)} />
                        <Tab label={t("reviewRequest")} {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ReturnRequestType
                        value={value}
                        handleChange={handleChange}
                        setReturnType={setReturnType}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ProductInfoTable
                        getReturnedTicketsObject={getReturnedTicketsObject}
                        value={value}
                        handleChange={handleChange}
                        returnType={returnType}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <SellerInfoForm value={value} handleChange={handleChange} returnedObj={returnedObj} />
                </TabPanel>

                <TabPanel value={value} index={3}>
                    <ReviewReturnRequest
                        returnedObj={returnedObj}
                        value={value}
                        handleChange={handleChange}
                    />
                </TabPanel>
            </Box>
        </div>
  );
};

export default TicketsReturnRequestTabs;
