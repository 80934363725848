import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { authApi } from "../apis/authApi";
import { loginAction, clearAuthAction, changeLanguageAction, refreshTokenAction } from "../authActions";
import { subscriptionApi } from "../apis/subscriptionApi";

const initialState = {
  isAuth: false,
  user: {},
  accessToken: "",
  language: "en",
  expiredSubscription: false
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.accessToken = action.payload;
      Cookies.set("refreshToken", action.payload);
    },
    refreshOrg: (state, action) => {
      state.user = {
        ...state.user,
        org: action.payload
      };
    },
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    setExpiredSubscription: (state, action) => {
      state.expiredSubscription = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginAction, (state, action) => {
      state.accessToken = action.payload.user.access;
      Cookies.set("refreshToken", action.payload.user.refresh);
      state.user = action.payload;
      state.isAuth = true;
    });
    builder.addCase(clearAuthAction, (state) => {
      Cookies.remove("refreshToken");
      // state.user = {};
      // state.isAuth = false;
      // state.accessToken = "";
      Object.assign(state, { user: {}, isAuth: false, accessToken: "" });
    });
    builder.addCase(changeLanguageAction, (state, action) => {
      state.language = action.payload;
    });
    builder.addCase(refreshTokenAction, (state, action) => {
      state.accessToken = action.payload.access;
      Cookies.set("refreshToken", action.payload.refresh);
    });
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      // state.isAuth = true;
      // state.user = action.payload;
      // state.accessToken = action.payload.user.access;
      Cookies.set("refreshToken", action.payload.user.refresh);
      Object.assign(state, { isAuth: true, user: action.payload, accessToken: action.payload.user.access });
    });
    builder.addMatcher(subscriptionApi.endpoints.getMySubscriptions.matchFulfilled, (state, action) => {
      state.expiredSubscription = !["ACTIVE", "EXTENDED", "CANCELLATION_REQUESTED"].includes(action.payload.status_code);
    });
    // builder.addMatcher(authApi.endpoints.me.matchFulfilled, (state, action) => {
    //   state.user = action.payload;
    // });
  }
});

export const { setUser, setToken, refreshOrg, changeLanguage, setExpiredSubscription } = userSlice.actions;
export const useIsAuthed = () => useSelector((state) => state.auth.isAuth);
export const useUserLanguage = () => useSelector((state) => state.auth.language);
export const useAuthedUser = () => useSelector((state) => state.auth.user.user);
export const useUserObject = () => useSelector((state) => state.auth.user);
export const useAuthToken = () => useSelector((state) => state.auth.accessToken);
export const useExpiredSubscription = () => useSelector((state) => state.auth.expiredSubscription);
export const useAwbType = () => useSelector((state) => state.auth.user?.org?.awb_type);
export const useCourierWalletAvailable = () => useSelector((state) => state.auth.user?.org?.courier_wallet_available);
export const useInventoryActive = () => useSelector((state) => state.auth.user?.org?.inventory_active);

export default userSlice.reducer;
