import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { EditIcon } from "@shopify/polaris-icons";

export default function OrderSummary ({ orderDetails, onShipmentFeesChange }) {
  const { t } = useTranslation();
  const currency = orderDetails?.seller_address?.area?.city?.country?.currency || "USD";

  const [shipmentFeesChange, setShipmentFeesChange] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Safely convert and calculate totals
  const deliveryFees = parseFloat(shipmentFeesChange ?? (orderDetails?.delivery_fees || 0));
  const subTotal = parseFloat(orderDetails?.sub_total || 0);

  const total = deliveryFees + subTotal;

  return (
    <Row>
      <ul className="calculationRow">
        <li>
          <span
            className="tabsTitles"
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            {t("shipment")}
          </span>
          <span
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center"
            }}
          >
            <span className="tabsInfoPrice">
              {t(currency)}
              <span className="tabsCost">
                {isEditing
                  ? (
                  <input
                    type="number"
                    min={0}
                    style={{
                      display: "inline-block",
                      width: "100%",
                      background: "transparent",
                      border: "none",
                      textAlign: "end"
                    }}
                    defaultValue={parseFloat(orderDetails?.delivery_fees || 0).toFixed(2)}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value || 0);
                      onShipmentFeesChange?.(value);
                      setShipmentFeesChange(value);
                    }}
                    onBlur={() => setIsEditing(false)} // Exit editing mode on blur
                  />
                    )
                  : (
                      deliveryFees.toFixed(2)
                    )}
              </span>
            </span>
            {orderDetails && (
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer"
                }}
                onClick={() => setIsEditing(true)}
              >
                <EditIcon color="#646464" fill="#646464" />
              </span>
            )}
          </span>
        </li>
        <li>
          <span className="tabsTitles">{t("totalProducts")}</span>
          <span className="tabsInfoPrice">
            {t(currency)}
            <span className="tabsCost">{subTotal.toFixed(2)}</span>
          </span>
        </li>
      </ul>
      <ul className="calculationRow">
        <li>
          <span className="tabsTitles">{t("total")}</span>
          <span className="tabsInfoPrice">
            {t(currency)}
            <span className="tabsCost">{total.toFixed(2)}</span>
          </span>
        </li>
      </ul>
    </Row>
  );
}
