import React, { useState } from "react";
import "../../../../../style-sheets/form.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { notification } from "antd"; // Import Ant Design notification
import {
  AccountConfirmationModal,
  CreationConfirmationModal
} from "../../../../../components/modals/confirmationModal";
import { accountCreationErrorAction } from "../../../../../stateManagement/actions";
import { useThirdPartyPersonalInfoFields } from "../../../../../hooks/useFormFields"; // Assuming custom hook for fields
import GenericForm from "../../../../../components/form/GenericForm";
import { addAccount, useAccountErrors, useAccount } from "../../../../../stateManagement/slices/addAccountSlice";

export default function ThirdPartyPersonalInfo ({ value, handleChange, onBack }) { // Typo in component name fixed
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useAccount();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [ID, setID] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const errors = useAccountErrors();
  console.log(errors);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Company name is required"),
    name_ar: Yup.string(),
    phone: Yup
      .string()
      .required("phoneRequired")
      .matches(/^966\d{9}$/, "invalidPhone"), // Ensures the number starts with 9665 and has 8 digits after
    phone_alt: Yup
      .string()
      .matches(/^966\d{9}$/, "invalidAltPhone") // Same format for the alternative phone
      .nullable(),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    address: Yup.string().required("Address is required"),
    website: Yup.string().url("Must be a valid URL"),
    facebook: Yup.string().url("Must be a valid URL"),
    instagram: Yup.string().url("Must be a valid URL"),
    twitter: Yup.string().url("Must be a valid URL")
  });

  const fields = useThirdPartyPersonalInfoFields(onBack);

  const showModal = () => setShow(true);
  const showConfirmModal = () => setShowConfirm(true);
  const hide = () => setShow(false);
  const hideConfirm = () => {
    setShowConfirm(false);
    navigate("/all_accounts");
  };

  const validate = (data) => {
    dispatch(addAccount(data));
    if (value === 1) {
      handleChange(undefined, value + 1);
      dispatch(accountCreationErrorAction(""));
    } else showModal();
  };

  const getErrors = (e) => {
    console.log(e);
    console.log(errors);
    dispatch(accountCreationErrorAction(e));

    // Display errors in Ant Design notification toasts
    if (e) {
      renderErrorsInToast(e);
    }
  };

  const renderErrorsInToast = (errors) => {
    if (typeof errors === "object" && errors !== null) {
      if (errors.error) {
        // Handle single error message case
        notification.error({
          message: "Error",
          description: errors.error,
          placement: "topRight"
        });
      } else {
        // Handle field-based error messages
        Object.entries(errors).forEach(([field, messages]) => {
          if (Array.isArray(messages)) {
            messages.forEach((message) => {
              notification.error({
                message: `Error in ${field}`,
                description: message,
                placement: "topRight"
              });
            });
          } else {
            // Handle unexpected structure
            notification.error({
              message: `Error in ${field}`,
              description: messages,
              placement: "topRight"
            });
          }
        });
      }
    } else {
      // Fallback for unexpected error formats
      notification.error({
        message: "Unexpected Error",
        description: "An unexpected error occurred.",
        placement: "topRight"
      });
    }
  };

  return (
    <>
      <GenericForm
        fields={fields}
        submitFunction={validate}
        validationSchema={validationSchema} // Add yupResolver here
        hooks={{
          initialValues: value === 2 ? account : undefined,
          successMessage: t("accountCreation"),
          errorMessage: t("accountCreationError")
        }}
      />

      <CreationConfirmationModal
        getErrors={getErrors}
        setPassword={setPassword}
        setID={setID}
        title={t("accountCreation")}
        show={show}
        showConfirm={showConfirmModal}
        hide={hide}
        account={account}
        onBack={onBack}
      />

      <AccountConfirmationModal
        show={showConfirm}
        hide={hideConfirm}
        id={ID}
        password={password}
        account={account}
        onBack={onBack}
      />
    </>
  );
}
