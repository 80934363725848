import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DatePicker, Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import "../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import { logsColumns, logsColumns_ar } from "../layout/tableColumns";
import useLanguage from "../../hooks/useLanguage";
import { useUserObject } from "../../stateManagement/slices/userSlice";

const { RangePicker } = DatePicker;
const ActivityLogs = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [selectedDate, setSelectedDate] = useState([]);
  const [actionType, setAction] = useState(null);
  const { Option } = Select;
  const user = useUserObject();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const handleActionType = (value) => {
    setAction(value);
  };
  function onChange (value, dateString) {
    console.log(dateString);
    setSelectedDate(dateString);
    if (dateString[0] === "" && dateString[1] === "") {
      console.log("here");
      setData([]);
      setCount(1);
    }
  }
  useEffect(() => {
    console.log(actionType);
    console.log(page);
  }, [actionType]);

  const getLogs = async () => {
    const result = await axiosInstance.get(
      `/ActivityLog/?action=${actionType}&history_user=${user.user.id}&start=${selectedDate[0]}&end=${selectedDate[1]}&page=1`
    );
    console.log(result);
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  const onpageChangeHandle = async (page) => {
    setPage(page);
    console.log(page);
    const result = await axiosInstance.get(
      `/ActivityLog/?action=${actionType}&history_user=${user.user.id}&start=${
        selectedDate[0]
      }&end=${selectedDate[1]}&page=${page + 1}`
    );
    console.log(result.data.data);
    setData(result.data.data);
    setCount(result.data.count);
  };
  const lang = useLanguage();

  return (
    <div>
      <Container>
        <Row>
          <Col sm="12">
            <h2 className="pageTitle">{t("sideMenu.logs")}</h2>
          </Col>
        </Row>
        <Row>
          <Col dir="ltr" sm="12" md="8">
            <RangePicker placeholder={lang === "en" ? ["Start Date", "End Date"] : ["تاريخ البداية", "تاريخ الإنتهاء"]} format="YYYY-MM-DD" onChange={onChange} />

            <Select
              onChange={handleActionType}
              value={actionType}
              style={{ width: 140 }}
              placeholder={t("selectAction")}
            >
              <Option name="runsheet" value="runsheet">
                {t("sideMenu.runsheets")}
              </Option>
              <Option name="ticket" value="ticket">
                {t("sideMenu.tickets")}
              </Option>
            </Select>
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }} sm="12" md="4">
            <button
              disabled={!!(actionType == null || (selectedDate === "" || (selectedDate[0] === "" || selectedDate[1] === "")))}
              className="confirm"
              onClick={getLogs}
            >
              {t("viewResults")}
            </button>
          </Col>
        </Row>
        <Row>
          <div className="requestTable rs mt-4">
            <DataGrid
              getRowId={(r) => r.history_id}
              rows={data}
              columns={lang === "ar" ? logsColumns_ar : logsColumns}
              //   disableSelectionOnClick
              pagination
              paginationMode="server"
              rowCount={count}
              pageSize={10}
              rowsPerPageOptions={[10]}
              //   checkboxSelection
              //   selectionModel={selectionModel}
              onPageChange={onpageChangeHandle}
              //   onSelectionModelChange={(ids) => {
              //     setChanged(!changed);
              //     setSelectionModel(ids);
              //     if (ids.length > 1) {
              //       const selectionSet = new Set(selectionModel);
              //       const result = ids.filter((s) => !selectionSet.has(s));
              //       const selectedRowData = sheets.filter((row) =>
              //         selectionSet.has(row.id)
              //       );
              //       setSelectedRs(selectedRowData);
              //       console.log(selectedRowData);
              //       setSelectionModel(result);
              //     } else {
              //       const selectionSet = new Set(ids);
              //       const selectedRowData = sheets.filter((row) =>
              //         selectionSet.has(row.id)
              //       );
              //       setSelectedRs(selectedRowData);
              //       setSelectionModel(ids);
              //     }
              //   }}
              //   isRowSelectable={(params) =>
              //     params.row.state == "3" || params.row.state == "2"
              //   }
              //   checkboxSelection
            />{" "}
            {
            data.length !== 0
              ? null
              : (
              <div className="noDataDiv">
                <h6 className="noDataHead">{t("logsInfo1")}</h6>
                <p>{t("logsInfo2")}</p>
                {/* <button className="confirm">Refresh</button> */}
              </div>
                )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ActivityLogs;
