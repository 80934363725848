import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sticky from "react-sticky-el";
import "../../../style-sheets/sidemenu.css";
import { useTranslation } from "react-i18next";
import {
  useAdminRoutes,
  useSellerRoutes,
  useThirdPartyRoutes,
  useInventoryRoutes,
  useNoSubscriptionRoutes
} from "../../../global-state/routes";
import useLanguage from "../../../hooks/useLanguage";
import { useUserObject, useExpiredSubscription, useInventoryActive } from "../../../stateManagement/slices/userSlice";
import { useDashboardType } from "../../../stateManagement/slices/dashboardSlice";

import RouteGroup from "./RouteGroup";
import SingleRoute from "./SingleRoute";

const drawerWidth = 240;
const commonStyles = {
  backgroundColor: "white",
  borderRight: "1px solid rgba(0,0,0,0.12)",
  minHeight: "95vh"
};

const useRenderAdminDrawer = (adminRoutes, walletPaths, user, t) => {
  return React.useMemo(() => (
    <div style={{ ...commonStyles, backgroundColor: "#F6F6F7" }} className="sidemenu">
      <SingleRoute path="/dashboard" details={adminRoutes.common["/dashboard"]} />
    <RouteGroup key="Accounts" meta={adminRoutes.accounts.meta} pathsGroup={adminRoutes.accounts} paths={["/create_account", "/all_accounts", "/archived_users"]} />
    <RouteGroup key="Tickets" meta={adminRoutes.orders.meta} pathsGroup={adminRoutes.orders} paths={["/create_order", "/postponed", "/return_ticket", "/replace_ticket", "/archived_tickets"]} />
    {user.org.inventory_active && (
      <SingleRoute path="/inventory_dashboard" details={adminRoutes.inventoryDashboard["/inventory_dashboard"]} />
    )}
    <RouteGroup key="RunSheet" meta={adminRoutes.runsheets.meta} pathsGroup={adminRoutes.runsheets} paths={["/create_runsheet", "/assigned_rs", "/archived_runsheets"]} />
    <RouteGroup key="InternalRunSheet" meta={adminRoutes.internalRunsheets.meta} pathsGroup={adminRoutes.internalRunsheets} paths={["/move_orders", "/all_internals"]} />
    <SingleRoute path="/tiers" details={adminRoutes.tiers["/tiers"]} />
    <SingleRoute path="/sms_templates" details={adminRoutes.smsTemplates["/sms_templates"]} />
    <RouteGroup key="Wallet" meta={adminRoutes.wallet.meta} pathsGroup={adminRoutes.wallet} paths={walletPaths} />
    <SingleRoute path="/ticket_scan" details={adminRoutes.orders["/ticket_scan"]} overrideIcon={<span className="material-icons">document_scanner</span>} />
    <p className="general">{t("sideMenu.general")}</p>
    <SingleRoute path="/select_reports" details={adminRoutes.reports["/select_reports"]} />
    <SingleRoute path="/plugins" details={adminRoutes.plugins["/plugins"]} />
      <SingleRoute path="/settings" className="settingsLink" details={adminRoutes.settings["/settings"]} />
    </div>
  ), [adminRoutes, walletPaths, user, t]);
};

const useRenderSellerDrawer = (sellerRoutes, user, inventory_active) => {
  return React.useMemo(() => (
    <Sticky>
      <div style={commonStyles} className="sidemenu">

        <SingleRoute path="/dashboard" details={sellerRoutes.common["/dashboard"]} isSeller />
        {user.user?.is_third_party && (
          <>
            <SingleRoute path="/api_keys" details={sellerRoutes.common["/api_keys"]} isSeller />
            <SingleRoute path="/webhooks" details={sellerRoutes.common["/webhooks"]} isSeller />
          </>
        )}
        <RouteGroup key="Inbox" meta={sellerRoutes.products.meta} pathsGroup={sellerRoutes.products} paths={["/add_product", "/products"]} isSeller />
        <RouteGroup key="Orders" meta={sellerRoutes.orders.meta} pathsGroup={sellerRoutes.orders} paths={["/create_order", "/postponed", "/return_ticket", "/replace_ticket", "/archived_tickets"]} isSeller />
        {inventory_active && (
          <RouteGroup key="Inventory Orders" meta={sellerRoutes.inventory.meta} pathsGroup={sellerRoutes.inventory} paths={["/create_inventory_order", "/pending_inventory_orders", "/approved_inventory_orders", "/completed_inventory_orders", "/rejected_inventory_orders"]} isSeller />
        )}
        <RouteGroup key="Wallets" meta={sellerRoutes.wallet.meta} pathsGroup={sellerRoutes.wallet} paths={["/seller_orders", "/seller_pay", "/seller_recieve"]} isSeller />
        <SingleRoute path="/settings" className="settingsLink" details={sellerRoutes.settings["/settings"]} isSeller />
      </div>
    </Sticky>
  ), [sellerRoutes, user, inventory_active]);
};

const useRenderInventoryDrawer = (inventoryRoutes) => {
  return React.useMemo(() => (
    <Sticky>
      <div style={commonStyles} className="sidemenu">
        <SingleRoute path="/inventory_dashboard" details={inventoryRoutes.home["/inventory_dashboard"]} />
        <SingleRoute path="/dashboard" details={inventoryRoutes.adminDashboard["/dashboard"]} />
        <RouteGroup key="Inventory Orders" meta={inventoryRoutes.inventoryOrders.meta} pathsGroup={inventoryRoutes.inventoryOrders} paths={["/create_inventory_order", "/inventory_orders/approved", "/inventory_orders/completed", "/inventory_orders/rejected"]} />
        <SingleRoute path="/warehouses" details={inventoryRoutes.warehouses["/warehouses"]} />
        <SingleRoute path="/inventory_items" details={inventoryRoutes.InventoryItems["/inventory_items"]} />
        <SingleRoute path="/product_categories" details={inventoryRoutes.productCategories["/product_categories"]} />
      </div>
    </Sticky>
  ), [inventoryRoutes]);
};

const useRenderThirdPartyDrawer = (thirdPartyRoutes) => {
  return React.useMemo(() => (
    <Sticky>
      <div style={commonStyles} className="sidemenu">
        <SingleRoute path="/dashboard" details={thirdPartyRoutes.common["/dashboard"]} isThirdParty />
        <SingleRoute path="/api_keys" details={thirdPartyRoutes.common["/api_keys"]} isThirdParty />
        <SingleRoute path="/webhooks" details={thirdPartyRoutes.common["/webhooks"]} isThirdParty />
        <SingleRoute path="/settings" className="settingsLink" details={thirdPartyRoutes.settings["/settings"]} isThirdParty />
      </div>
    </Sticky>
  ), [thirdPartyRoutes]);
};

const useRenderNoSubscriptionDrawer = (noSubscriptionRoutes) => {
  return React.useMemo(() => (
    <Sticky>
      <div style={commonStyles} className="sidemenu">
        <SingleRoute path="/dashboard" details={noSubscriptionRoutes.noSubscriptionDashboard["/dashboard"]} />
      </div>
    </Sticky>
  ), [noSubscriptionRoutes]);
};

function ResponsiveDrawer ({ window }) {
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const currentLang = useLanguage();
  const dashboardType = useDashboardType();
  const expiredSubscription = useExpiredSubscription();
  const inventoryActive = useInventoryActive();
  const user = useUserObject();
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const adminRoutes = useAdminRoutes();
  const sellerRoutes = useSellerRoutes(user.user?.is_third_party);
  const inventoryRoutes = useInventoryRoutes();
  const thirdPartyRoutes = useThirdPartyRoutes();
  const noSubscriptionRoutes = useNoSubscriptionRoutes();
  const [walletPaths, setWalletPaths] = useState([]);
  const [drawerKey, setDrawerKey] = useState(1);

  useEffect(() => {
    if (user?.org?.courier_wallet_available) {
      console.log("SETTING WALLET PATHS");
      setWalletPaths([
        "/sellers_wallets",
        "/pay_requests",
        "/recieved_requests",
        "/courier_wallets"
      ]);
    } else {
      setWalletPaths([
        "/sellers_wallets",
        "/pay_requests",
        "/recieved_requests"
      ]);
    }
  }, [user?.org?.courier_wallet_available]);

  useEffect(() => {
    setDrawerKey(prev => prev + 1);
  }, [walletPaths]);

  const drawerContent = React.useMemo(() => {
    if (expiredSubscription) {
      return useRenderNoSubscriptionDrawer(noSubscriptionRoutes);
    }
    if (dashboardType === "inventory") {
      return useRenderInventoryDrawer(inventoryRoutes);
    }
    if (user.role === "Seller") {
      return useRenderSellerDrawer(sellerRoutes, user, inventoryActive);
    }
    if (user.role === "Third Party") {
      return useRenderThirdPartyDrawer(thirdPartyRoutes);
    }
    return useRenderAdminDrawer(adminRoutes, walletPaths, user, t);
  }, [
    expiredSubscription,
    dashboardType,
    user.role,
    noSubscriptionRoutes,
    inventoryRoutes,

    sellerRoutes,
    thirdPartyRoutes,
    adminRoutes,
    walletPaths,
    user
  ]);

  return (
    <Box sx={{ display: "flex", backgroundColor: "#F6F6F7" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              boxShadow: currentLang === "ar"
                ? "-2px 0px 8px rgba(0, 0, 0, 0.1)" // Left shadow for Arabic
                : "2px 0px 8px rgba(0, 0, 0, 0.1)", // Right shadow for English
              borderRadius: "8px"
            }
          }}
          anchor={currentLang === "ar" ? "right" : "left"}
          key={drawerKey + "mobile"}

        >
          {drawerContent}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              boxShadow: currentLang === "ar"
                ? "-2px 0px 8px rgba(0, 0, 0, 0.1)" // Left shadow for Arabic
                : "2px 0px 8px rgba(0, 0, 0, 0.1)", // Right shadow for English
              borderRadius: "8px"
            }
          }}
          open
          anchor={currentLang === "ar" ? "right" : "left"}
          key={drawerKey + "desktop"}

        >
          {drawerContent}
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func
};

export default ResponsiveDrawer;
