import React, {
  useState,
  useEffect
} from "react";
import { Row, Col } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { addRunsheet, useRunsheet } from "../../stateManagement/slices/addRunsheetSlice";
import { useReassignedRS } from "../../stateManagement/slices/reassignedRSSlice";
import {

  useCourierColumns
} from "../layout/tableColumns";
import { search } from "../../stateManagement/slices/searchSlice";
import TablePro from "../tables/TablePro";
import { useFilterCouriersMutation } from "../../stateManagement/apis/accountsApi";

function RunsheetCourier ({ value, isReAssigned, handleChange }) {
  const searchDispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const reAssignedRS = useReassignedRS();
  const runSheet = useRunsheet();
  const dispatch = useDispatch();
  const dispatchCourier = useDispatch();
  const { t } = useTranslation();
  const columns = useCourierColumns();
  const [filterCouriers] = useFilterCouriersMutation();

  useEffect(() => {
    if (selectedRows[0] === undefined) return;
    dispatch(addRunsheet({ courier: selectedRows[0] }));
    dispatchCourier(
      addRunsheet({
        courierObj: data.find((it) => it.id === selectedRows[0])
      })
    );
  }, [selectedRows]);

  const getCouriers = async () => {
    const result = await filterCouriers({
      page,
      search: searchTerm,
      available: "available",
      branch: isReAssigned ? reAssignedRS.branch.id : runSheet.branch
    });
    if (result.data) {
      setData(result.data.data ?? []);
      setCount(result.data.count);
    }
  };

  const searching = (v) => {
    setSearchTerm(v);
    searchDispatch(search(v));
  };

  useEffect(() => {
    getCouriers();
  }, [page, searchTerm]);

  const proceed = () => {
    handleChange(null, value + 1);
  };
  return (
        <>
            <Row>
                <Col sm="6">
                    {isReAssigned
                      ? (
                        <p className="orderID">
                            {t("runsheetID")}#{reAssignedRS.id}
                        </p>
                        )
                      : null}
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    {/* <button className="cancel">{t("discard")}</button> */}
                    <button
                        disabled={selectedRows.length === 0}
                        onClick={proceed}
                        className="confirm"
                    >
                        {t("choose")}
                    </button>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col sm="12">
                        <div>
                            <TablePro
                                dataSource={data}
                                columns={columns}
                                disableSelectionOnClick
                                paginationMode="server"
                                pagination={{
                                  current: page,
                                  total: count,
                                  onChange: (page) => setPage(page)
                                }}
                                selection={"radio"}
                                onSearch={searching}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
  );
}

export default RunsheetCourier;
