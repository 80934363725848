import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {};

const addRunsheetSlice = createSlice({
  name: "runsheet",
  initialState,
  reducers: {
    addRunsheet: (state, action) => {
      if (action.payload === "") {
        return initialState; // Return the initial state when payload is empty
      } else {
        return { ...state, ...action.payload }; // Return a new state with merged payload
      }
    }
  }
});

export const { addRunsheet } = addRunsheetSlice.actions;
export const useRunsheet = () => useSelector((state) => state.runsheet);

export default addRunsheetSlice.reducer;
