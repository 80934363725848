import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./OrderChainDetails.module.css";
import OrderTag from "./OrderTag";

export default function OrderChainHeader ({ order }) {
  const { t } = useTranslation();
  return (
        <Row className="mb-4">
            <Col sm="12">
                <span className="title1">{t("sideMenu.tickets")}</span>
                <i className="fas fa-chevron-right px-3 title2" />
                <span className="title2">{t("sideMenu.orderInfo")}</span>
            </Col>
            <div className={styles.title}>
                <div>
                    <h2 className="pageTitle">{t("sideMenu.orderInfo")}</h2>
                    {order && (
                        <h4 className={styles.id}>
                            <span>
                                {t("orderId")} #{order.id}
                            </span>
                        </h4>
                    )}
                </div>
                <div className={styles.title}>
                    {order && (
                        <>
                            <OrderTag
                                title={t(order.paymentMethod)}
                                background={"#a4e8f2"}
                            />
                            <OrderTag
                                title={t(order.type)}
                                background={"#ffd79d"}
                            />
                        </>
                    )}
                </div>
            </div>
        </Row>
  );
}
