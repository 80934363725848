import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import { Select, Form as AntForm, Input } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { addOrder, useOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { useUserObject } from "../../../../../stateManagement/slices/userSlice";
import CreateNewProduct from "../../../../../components/modals/CreateNewProduct";
import useLanguage from "../../../../../hooks/useLanguage";
import { useFilterProductsMutation } from "../../../../../stateManagement/apis/treecodeApi";

export default function ProductInfoForm ({ value, handleChange }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = useOrder();
  const workModelFormItems = [
    {
      label: t("weight"),
      name: "weight",
      getValue: (data) => data.weight
    }
  ];

  const [products, setProducts] = useState([]);
  const [productPage, setProductPage] = useState(1);
  const [produuctSearch, setProductSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(
    order?.tickets?.map((ticket) => parseInt(ticket.product)) ?? []
  );
  const [selectedLabel] = useState(
    order?.tickets?.map((ticket) => parseInt(ticket.special_label)) ?? []
  );
  // const [labels, setLabels] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [labelErr, setLabelErr] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  // const [productPrice, setProductPrice] = useState([]);
  const [created, setCreated] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [filterProducts] = useFilterProductsMutation();

  console.log({ order });
  const [ticketData, setTicket] = useState(
    order?.tickets ?? [
      {
        weight: undefined,
        product: "",
        quantity: 1,
        special_label: "",
        price: undefined,
        allow_open: false,
        fragile: false,
        special: false
      }
    ]
  );

  useEffect(() => {
    console.log({ orderrrrrr: order.tickets });
    if (order?.tickets) setTicket([...order.tickets]);
  }, [value]);

  useEffect(() => {
    console.log(value);
  }, []);
  const user = useUserObject();

  // const selectedSeller = useSelector(
  //   (state) => state.selectedData.selectedSeller
  // );
  const handleInput = (e, index) => {
    const { name, value } = e.target;
    const list = [...ticketData];
    list[index][name] = isNaN(value) ? value : +value;
    setTicket(list);
  };

  const handleSelectedProduct = (e, v, i) => {
    // const productPriceList = [...productPrice]
    // productPriceList[i] = v.price
    // setProductPrice(productPriceList)
    console.log(v);
    console.log(e);
    const list = [...ticketData];
    list[i].product = e;
    list[i].price = v?.price;
    const copy = [...selectedProduct];
    copy[i] = e;
    setSelectedProduct(copy);
  };

  useEffect(() => {
    console.log(ticketData);
  }, [ticketData]);

  // const handleSelectedLabel = (e, i) => {
  //   const list = [...ticketData];
  //   list[i].special_label = e;
  //   const copy = [...selectedLabel];
  //   copy[i] = e;
  //   setSelectedLabel(copy);
  // };

  const addAnotherProduct = () => {
    const productList = [...ticketData];
    setTicket([
      ...productList,
      {
        weight: undefined,
        product: "",
        quantity: 1,
        special_label: "",
        price: undefined,
        allow_open: false,
        fragile: false,
        special: false
      }
    ]);
  };

  const getProducts = async (onlyNew = false) => {
    const result = await filterProducts({
      seller: user.role === "Seller" ? user.user.user_id : order.seller?.id,
      page: productPage,
      search: produuctSearch
    });
    if (result.data) {
      if (onlyNew) {
        setProducts(result.data.data);
      } else {
        setProducts([...products, ...result.data.data]);
      }
      if (result.data.next === null) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
        setProductPage(productPage + 1);
      }
    }
  };

  const validate = () => {
    if (selectedProduct !== "" && selectedLabel !== "") {
      // console.log(formState.isValid)
      const tickets = { tickets: ticketData };
      dispatch(addOrder(tickets));
      handleChange(null, parseInt(value) + 1);
    } else if (selectedProduct === "") {
      setErrMsg("This field is required");
    } else if (selectedLabel === "") {
      setLabelErr("This field is required");
    }
  };
  useEffect(() => {
    setErrMsg("");
  }, [selectedProduct]);
  useEffect(() => {
    setLabelErr("");
  }, [selectedLabel]);

  const previous = () => {
    handleChange(null, parseInt(value) - 1);
  };

  const cancelTicket = (e, i) => {
    console.log(i);
    console.log(ticketData);
    const ticketList = [...ticketData];

    ticketList.splice(i, 1);
    setTicket(ticketList);

    // let priceList = [...productPrice]

    // let newPrice = priceList.splice(i , 1)
    // setProductPrice(newPrice)
  };

  const onScroll = async (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && loadMore) {
      await getProducts();
    }
  };

  const openCreateModal = () => {
    setShowCreate(true);
    setCreated(false);
  };

  const hideCreateModal = (e) => {
    setShowCreate(false);
  };

  useEffect(() => {
    getProducts(true);
  }, [created, produuctSearch]);
  // useEffect(() => {
  //     getLabels();
  // }, []);
  const lang = useLanguage();

  return (
        <>
            {ticketData.map((data, i) => {
              return (
                    <>
                        <Row>
                            <Col className="btnPosition" sm-12>
                                <button
                                    className="cancelBtn"
                                    disabled={ticketData.length === 1}
                                    onClick={(e) => cancelTicket(e, i)}
                                >
                                    {t("cancelTicket")}
                                </button>
                            </Col>
                        </Row>
                        <AntForm
                            layout="vertical"
                            className="grid-form small-gap"
                        >
                            <div className="position-relative text-area-form">
                                {user.role === "Seller" && (
                                    <a
                                        className={
                                            lang.includes("en")
                                              ? "over-select-link-en"
                                              : "over-select-link-ar"
                                        }
                                        onClick={openCreateModal}
                                    >
                                        {t("createNewProduct")}
                                    </a>
                                )}
                                <div>
                                    <AntForm.Item
                                        label={t("selectProduct")}
                                        name="product"
                                    >
                                        {<></>}
                                        <Select
                                            value={
                                                data.product ??
                                                selectedProduct[i]
                                            }
                                            onChange={(e, v) =>
                                              handleSelectedProduct(e, v, i)
                                            }
                                            allowClear
                                            className="form-control paginated"
                                            name="product"
                                            onScroll={onScroll}
                                            showSearch
                                            onSearch={(v) => {
                                              setProductPage(1);
                                              setProductSearch(v);
                                            }}
                                        >
                                            <Select.Option
                                                selected
                                                hidden
                                                disabled
                                            >
                                                {lang === "en"
                                                  ? "Choose a product"
                                                  : "اختر المنتج"}
                                            </Select.Option>
                                            {products.map((product) => (
                                                <Select.Option
                                                    key={product.id}
                                                    name="product"
                                                    value={product.id}
                                                    price={product.price}
                                                >
                                                    {product.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </AntForm.Item>
                                </div>
                                <p className="error">
                                    {errMsg !== "" ? errMsg : null}
                                </p>
                            </div>
                            <div>
                                <AntForm.Item
                                    label={t("specialLabel")}
                                    name="label"
                                >
                                    {<></>}

                                    <Input
                                        onChange={(e) => handleInput(e, i)}
                                        value={data.special_label ?? ""}
                                        name="special_label"
                                    />
                                </AntForm.Item>
                                <p className="error">
                                    {labelErr !== "" ? labelErr : null}
                                </p>
                            </div>
                            <AntForm.Item label={t("quantity")} name="quantity">
                                {<></>}
                                <Input
                                    min={1}
                                    defaultValue={1}
                                    type="number"
                                    name="quantity"
                                    onChange={(e) => handleInput(e, i)}
                                    value={data.quantity ?? 1}
                                    className="formTextField text-area"
                                />
                            </AntForm.Item>

                            {workModelFormItems.map((it) => (
                                <AntForm.Item
                                    label={it.label}
                                    key={it.name}
                                    name={it.name}
                                >
                                    {<></>}
                                    <Input
                                        value={it.getValue(data)}
                                        type="number"
                                        name={it.name}
                                        onChange={(e) => handleInput(e, i)}
                                    />
                                </AntForm.Item>
                            ))}

                            <AntForm.Item label={t("price")} name="price">
                                {<></>}
                                <Input
                                    name="price"
                                    // onChange={(e) => handleInput(e, i)}
                                    readOnly
                                    value={data.price}
                                    className="formTextField text-area"
                                />
                            </AntForm.Item>
                             <div className="position-relative d-flex justify-content-between align-items-start">
                                {[
                                  { name: "fragile", label: t("Fragile") },
                                  {
                                    name: "special",
                                    label: t("Special Care")
                                  },
                                  {
                                    name: "allow_open",
                                    label: t("Allow Open Package")
                                  }
                                ].map((item, ind) => (
                                    <AntForm.Item
                                        key={ind}
                                        label={item.label}
                                        name={item.name}
                                        className="flex-row d-flex"
                                    >
                                        <Input
                                            name={item.name}
                                            type="checkbox"
                                            onChange={(e) =>
                                              handleInput(
                                                {
                                                  target: {
                                                    name: item.name,
                                                    value: e.target
                                                      .checked
                                                  }
                                                },
                                                i
                                              )
                                            }
                                            defaultValue={false}
                                            readOnly
                                            value={data[item.name]}
                                            className="formTextField text-area"
                                        />
                                    </AntForm.Item>
                                ))}
                            </div>
                        </AntForm>
                    </>
              );
            })}

            <Row className="mt-4">
                <Col sm="12">
                    <p onClick={addAnotherProduct} className="addBtn">
                        <i className="fas fa-plus-circle mx-2"></i>
                        {t("addAnotherProduct")}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin" sm="12">
                    <button className="previous" onClick={previous}>
                        {t("previous")}
                    </button>

                    <button className="confirm" onClick={validate}>
                        {t("proceed")}
                    </button>
                </Col>
            </Row>
            <CreateNewProduct
                setCreated={setCreated}
                showModal={showCreate}
                hideModal={hideCreateModal}
                useModal={true}
            />
        </>
  );
}
