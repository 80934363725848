import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  orders: null
};

const addRunsheetSlice = createSlice({
  name: "rsOrders",
  initialState,
  reducers: {
    addRunsheetOrders: (state, action) => {
      state.orders = action.payload;
    }
  }
});

export const { addRunsheetOrders } = addRunsheetSlice.actions;
export const useRunsheetOrders = () => useSelector((state) => state.rsOrders.orders);

export default addRunsheetSlice.reducer;
