import React, { useState } from "react";
import { Input, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";
import WMButton from "../../../../../components/pages/AllWorkModels/WMButton";
import { useRunsheetOrders } from "../../../../../stateManagement/slices/rsOrdersSlice";
import { useSellerConfirmPaymentRequestMutation } from "../../../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../../../api/ErrorNotifier";
import styles from "./ConfirmPayment.module.css";

export default function ConfirmPayment () {
  const { t } = useTranslation();
  const [isCommenting, setIsCommenting] = useState(false);
  const [comment, setComment] = useState(undefined);
  const [open, setOpen] = useState(false);
  const paymentMethod = useRunsheetOrders().payment;
  const [sellerConfirmPaymentRequest] = useSellerConfirmPaymentRequestMutation();
  console.log({ paymentMethod });

  async function onSubmit () {
    // const res = await axiosInstance
    //   .post(
    //     "SellerConfirmPayment/" + paymentMethod.id,
    //     !isCommenting && !comment
    //       ? {
    //           seller_confirmed: true
    //         }
    //       : {
    //           seller_confirmed: false,
    //           seller_note: comment
    //         }
    //   )
    //   .catch((err) => {
    //     console.error("Error:", err); // Simple error logging
    //     toast.error(t("server_error"), {
    //       toastId: paymentMethod.id
    //     });
    //   });
    const res = await sellerConfirmPaymentRequest({
      paymentRequestId: paymentMethod.id,
      sellerConfirmed: !isCommenting && !comment,
      sellerNote: !isCommenting && comment ? comment : undefined
    });

    if (res.data) {
      notification.success({
        message: t("Payment Confirmed"),
        description: t("Payment has been confirmed successfully")
      });
    } else if (res.error?.data) {
      notifyErrors(res.error.data);
    } else {
      notifyErrors(t("server_error"));
    }
  }

  return (
        <div>
            <WMButton onClick={() => setOpen(true)}>
                {t("confirmPayment")}
            </WMButton>
            <Modal open={open} onCancel={() => setOpen(false)}>
                <div
                    className={[
                      styles.container,
                      isCommenting ? styles.commenting : ""
                    ].join(" ")}
                >
                    <img
                        className={styles.invoiceImage}
                        src={paymentMethod?.payment_proof}
                    />
                    {isCommenting && (
                        <div className={styles.commentSection}>
                            <Input.TextArea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </div>
                    )}
                    <div className={styles.actions}>
                        {isCommenting
                          ? (
                            <WMButton
                                className={styles.secondaryButton}
                                onClick={() => {
                                  setIsCommenting(false);
                                  setComment(undefined);
                                }}
                            >
                                {t("cancelComment")}
                            </WMButton>
                            )
                          : (
                            <WMButton
                                className={styles.secondaryButton}
                                onClick={() => setIsCommenting(true)}
                            >
                                {t("leaveComment")}
                            </WMButton>
                            )}

                        <WMButton
                            className={styles.confirmButton}
                            onClick={onSubmit}
                        >
                            {t("confirmPayment")}
                        </WMButton>
                    </div>
                </div>
            </Modal>
        </div>
  );
}
