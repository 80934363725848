import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import ThreeDotsMenu from "../layout/threeDotsMenu";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";
import { useCreateAreaMutation, useUpdateAreaMutation, useGetCitiesMutation, useFilterAreasMutation, useDeleteAreaMutation } from "../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../api/ErrorNotifier";

function Areas (props) {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [type, setType] = useState("");
  const [data, setData] = useState([]);
  const [area, setArea] = useState("");
  const [count, setCount] = useState(1);
  const [deleted, setDeleted] = useState();
  const [show, setShow] = useState(false);
  const [editedArea, setEditedArea] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("all");
  const [cityFilter, setCityFilter] = useState("");
  const [createAreaApi, { isLoading: createAreaLoading }] = useCreateAreaMutation();
  const [updateArea, { isLoading: updateAreaLoading }] = useUpdateAreaMutation();
  const [getCities] = useGetCitiesMutation();
  const [filterAreas] = useFilterAreasMutation();
  const [deleteArea, { isLoading: deleteAreaLoading }] = useDeleteAreaMutation();

  const [columns] = useState([
    {
      dataIndex: "name",
      title: `${t("area")}`
    },
    {
      dataIndex: ["city", "name"],
      title: `${t("city")}`
    },
    {
      title: `${t("actions")}`,
      render: (_, row) => (
                <ThreeDotsMenu
                    onClick={() => settingEditedData(row)}
                    method={deleteArea}
                    loading={deleteAreaLoading}
                    deleted
                    setEdited={setShow}
                    setDeleted={setDeleted}
                    showModal={setShowDelete}
                    data={row}
                />
      )
    }
  ]);

  const handleFilter = (city) => {
    setCityFilter(city);
    setPage(1);
  };

  const settingEditedData = (data) => {
    setType("edit");
    setArea(data.name);
    setEditedArea(data);
    setSelectedCity(data.city.id);
  };
  useEffect(() => {
    getAreas();
    console.log(showDelete);
  }, [deleted, cityFilter, page]);
  // const handleArea = (e) => {
  //   setArea(e.target.value);
  // };
  const getAreas = async () => {
    const result = await filterAreas({
      city: !["", "all"].includes(cityFilter) ? cityFilter : "",
      page
    });
    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  const getcities = async () => {
    const result = await getCities({ limit: 10000 });
    if (result.data) {
      setCities([{ id: "all", name: "All Cities" }, ...result.data.data]);
    }
  };
  const handleCity = (value) => {
    setSelectedCity(value);
  };
  const handleInput = (e, setFuntion) => {
    setFuntion(e.target.value);
  };
  const createArea = () => {
    setType("create");
    setShow(true);
  };
  const confirmRequest = async (type) => {
    const data = {
      name: area,
      city: selectedCity
    };
    let result;
    if (type === "create") {
      result = await createAreaApi(data);
    } else {
      result = await updateArea({ areaId: editedArea.id, data });
    }

    if (result.data) {
      hide();
      getAreas();
      setArea("");
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors(t("server_error"));
    }
  };
  const hide = () => {
    setShow(false);
    setArea("");
  };
  useEffect(() => {
    getAreas();
  }, [cityFilter]);
  useEffect(() => {
    getcities();
    setCityFilter("all");
  }, []);
  useEffect(() => {
    if (show) {
      setSelectedCity(cities[1].id);
      setCities([...cities.slice(1)]);
    } else setCities([{ id: "all", name: "All Cities" }, ...cities]);
  }, [show]);
  const lang = useLanguage();

  useEffect(() => {
    if (createAreaLoading || updateAreaLoading) setLoading(true);
    else setLoading(false);
  }, [createAreaLoading, updateAreaLoading]);

  return (
        <>
            <style>
                {`
            @media (min-width: 600px) {
                .MuiToolbar-root .MuiButtonBase-root {
                  display: block;
                }
              }`}
            </style>
            <div>

                <Row>
                    <Col
                        sm="3"
                        className="btnPosition"
                        style={{ justifyContent: "flex-start" }}
                    >
                        {cities.length > 0 && (
                            <Select
                                onChange={handleFilter}
                                value={cityFilter}
                                style={{ width: "100%" }}
                                // defaultValue="all"
                            >
                                {cities.map((city) => (
                                    <Select.Option
                                        key={city.id}
                                        type={city.id}
                                        value={city.id}
                                        style={{
                                          position: "relative"
                                        }}
                                    >
                                        {city.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Col>
                    <Col sm="9" className="btnPosition">
                        <button onClick={createArea}>
                            <i className="fas fa-plus-circle"></i>{" "}
                            {t("addNewArea")}
                        </button>
                    </Col>
                </Row>
                <TablePro
                    dataSource={data}
                    columns={columns}
                    pagination={{
                      current: page,
                      total: count,
                      onChange: (page) => setPage(page)
                    }}
                />
                <Modal
                    dir={lang === "ar" ? "rtl" : "ltr"}
                    show={show}
                    onHide={hide}
                    style={{ zIndex: 1050 }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {type === "create"
                              ? t("addNewArea")
                              : t("editArea")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>{t("areaName")}</label>
                        <Form.Control
                            value={area}
                            onChange={(e) => handleInput(e, setArea)}
                            type="text"
                        />

                        <label>{t("city")}</label>
                        <Select
                            onChange={handleCity}
                            value={selectedCity}
                            style={{ width: "100%" }}
                            placeholder={t("select_city")}
                        >
                            {cities.map((city) => (
                                <Select.Option
                                    key={city.id}
                                    type={city.id}
                                    value={city.id}
                                    style={{
                                      zIndex: 10000,
                                      position: "relative"
                                    }}
                                >
                                    {city.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={hide} className="cancel">
                            {t("close")}
                        </button>
                        <button
                            className="confirm"
                            disabled={loading}
                            onClick={() => confirmRequest(type)}
                        >
                            {type === "create" ? t("addArea") : t("edit")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
  );
}

export default Areas;
