import React, { useState, useEffect, useRef } from "react";
import { notification, Form as AntForm, Button, Select, Input, Spin, Collapse, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import SmsTemplateRepository from "../../../api/SmsTemplateRepository";
import { notifyErrors } from "../../../api/ErrorNotifier";
import { RECIPIENT_TYPE } from "./Constants";
import KeywordsSelector from "./KeywordsSelector";

const { Panel } = Collapse;
const { Title } = Typography;

const SmsTemplateDetails = () => {
  const { t } = useTranslation();
  const { id: templateId } = useParams();
  const [template, setTemplate] = useState(null);
  const [templateForm] = AntForm.useForm();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);

  const contentRefEn = useRef(null);
  const contentRefAr = useRef(null);

  const handleChange = () => {
    setDirty(templateForm.isFieldsTouched(false));
  };

  const getTemplate = async () => {
    setLoading(true);
    const response = await SmsTemplateRepository.getTemplateById(templateId);
    if (response.success) {
      setTemplate(response.data);
    } else {
      notifyErrors(response.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTemplate();
  }, []);

  const onFinish = async (values) => {
    setSubmitting(true);
    const response = await SmsTemplateRepository.updateTemplate(templateId, values);
    if (response.success) {
      notification.success({ message: t("Sms Template updated successfully"), placement: "bottomRight" });
      setDirty(false);
    } else {
      notifyErrors(response.error);
    }
    setSubmitting(false);
  };

  const onSelectKeyword = (keyword, setFieldValue, field_name) => {
    const contentRef = field_name[1].includes("ar") ? contentRefAr.current : contentRefEn.current;
    const startPosition = contentRef.selectionStart;
    const endPosition = contentRef.selectionEnd;
    const newContent = `${contentRef.value.substring(0, startPosition)}{{${keyword}}}${contentRef.value.substring(endPosition)}`;
    setFieldValue(field_name, newContent);
    setTimeout(() => {
      contentRef.focus();
      contentRef.setSelectionRange(startPosition + keyword.length + 4, startPosition + keyword.length + 4);
    }, 0);
  };

  const setContentRefEn = (element) => {
    if (element) contentRefEn.current = element.resizableTextArea.textArea;
  };

  const setContentRefAr = (element) => {
    if (element) contentRefAr.current = element.resizableTextArea.textArea;
  };

  return (
    <div style={{ padding: "24px" }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title level={4}>{t("sideMenu.smsTemplates")} / {t("Edit Sms Template")}</Title>
          <Title level={2}>{template?.sms_template?.name}</Title>
        </Col>
      </Row>

      {loading || !template
        ? (
        <div className="loading-container">
          <Spin size="large" />
        </div>
          )
        : (
        <AntForm
          form={templateForm}
          layout="vertical"
          onFinish={onFinish}
          initialValues={template}
          onFieldsChange={handleChange}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={8}>
              <AntForm.Item
                name={["sms_template", "name"]}
                label={t("Name")}
              >
                <Input placeholder={t("Template Name")} disabled />
              </AntForm.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <AntForm.Item
                name="description"
                label={t("Description")}
              >
                <Input placeholder={t("Description")} disabled />
              </AntForm.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <AntForm.Item
                name="recipient_type"
                label={t("Recipient Type")}
                rules={[
                  { required: true, message: t("Please select recipient type") },
                  {
                    validator: (_, value) =>
                      Object.values(RECIPIENT_TYPE).includes(value)
                        ? Promise.resolve()
                        : Promise.reject(t("Invalid recipient type"))
                  }
                ]}
              >
                <Select
                  placeholder={t("Recipient Type")}
                  options={Object.values(RECIPIENT_TYPE).map((type) => ({
                    value: type,
                    label: t(type[0] + type.slice(1).toLowerCase())
                  }))}
                />
              </AntForm.Item>
            </Col>
          </Row>

          <Collapse defaultActiveKey={["message_content", "message_content_ar"]}>
            <Panel header={t("Message Content (English)")} key="message_content">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <AntForm.Item
                    name={["sms_template", "message_content"]}
                    label={t("Message Content (English)")}
                    rules={[
                      { required: true, message: t("Please enter message content") },
                      { max: 160, message: t("Message content should not exceed 160 characters") }
                    ]}
                  >
                    <Input.TextArea ref={setContentRefEn} rows={4} />
                  </AntForm.Item>
                  <KeywordsSelector keywords={template.sms_template.keywords} onClickKeyword={(keyword) => onSelectKeyword(keyword, templateForm.setFieldValue, ["sms_template", "message_content"])} />
                </Col>
              </Row>
            </Panel>
            <Panel header={t("Message Content (Arabic)")} key="message_content_ar">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <AntForm.Item
                    name={["sms_template", "message_content_ar"]}
                    label={t("Message Content (Arabic)")}
                    rules={[
                      { required: true, message: t("Please enter message content") },
                      { max: 70, message: t("Message content should not exceed 70 characters") }
                    ]}
                  >
                    <Input.TextArea ref={setContentRefAr} rows={4} />
                  </AntForm.Item>
                  <KeywordsSelector keywords={template.sms_template.keywords} onClickKeyword={(keyword) => onSelectKeyword(keyword, templateForm.setFieldValue, ["sms_template", "message_content_ar"])} />
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Row justify="end" style={{ marginTop: "24px" }}>
            <Col>
              <AntForm.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  disabled={!dirty}
                >
                  {t("Update Template")}
                </Button>
              </AntForm.Item>
            </Col>
          </Row>
        </AntForm>
          )}
    </div>
  );
};

export default SmsTemplateDetails;
