import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Form, Button, ConfigProvider, Input, Select, Switch } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import PhoneInput styles
import { yupResolver } from "@hookform/resolvers/yup";
import PermissionsList from "../forms/PermissionsList";
import AddressForm from "./AddressForm";
export default function GenericForm ({
  fields = [],
  submitFunction,
  validationSchema,
  hooks = {},
  formDisabled = false,
  initialValues = {},
  skipDirty = false,
  children, // Accept children for custom components
  forceRerender // Accept forceRerender for force rerender

}) {
  const {
    control,
    handleSubmit,
    setValue,
    reset, // Add reset to clear form data
    formState: { errors, isValid, isDirty }
  } = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: initialValues || hooks?.initialValues || {}
  });

  // UseEffect to reset form data when component changes (or form)
  useEffect(() => {
    return () => {
      reset(); // Reset form on unmount
    };
  }, [reset]);

  useEffect(() => {
    if (forceRerender && initialValues) {
      reset(initialValues);
    }
  }, [forceRerender, initialValues, reset]);

  // useEffect(() => {
  //   if (hooks.initialValues) {
  //     Object.keys(hooks.initialValues).forEach((key) => {
  //       if (hooks.initialValues[key] !== undefined) {
  //         setValue(key, hooks.initialValues[key]);
  //       }
  //     });
  //   }
  // }, [hooks.initialValues, setValue]);

  // const openNotificationWithIcon = (type, message, description) => {
  //   notification[type]({
  //     message,
  //     description
  //   });
  // };

  const onSubmit = async (data) => {
    try {
      await submitFunction(data); // Handle form submission
      // openNotificationWithIcon(
      //   "success",
      //   hooks.successMessage || "Success",
      //   hooks.successDescription || "Form submitted successfully"
      // );
    } catch (error) {
      console.log("Error From GenericForm", error);
      // openNotificationWithIcon(
      //   "error",
      //   hooks.errorMessage || "Error",
      //   hooks.errorDescription || "Form submission failed"
      // );
    }
  };

  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: "#57be6c",
        borderRadius: 10,
        fontSize: 16,
        colorBgContainer: "#f6f6f7",
        colorTextLabel: "#333"
      }
    }}>
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        className="genericForm"
        scrollToFirstError
        initialValues={initialValues}
        style={{
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
        }}
      >
        <Row gutter={[24, 24]}>
          {fields.map((field, index) => {
            if (field.type === "button") return null;

            if (field.component === "AddressForm" || field.component === "PermissionsList") {
              return (
                <Col key={index} span={24}>
                  <Form.Item
                    label={<span style={{ fontWeight: 500, fontSize: "16px", color: "#333" }}>{field.label} :</span>}
                  >
                    <field.component {...field.props} />
                  </Form.Item>
                </Col>
              );
            }

            return (
              <Col key={index} xs={field.xs || 24} sm={field.sm || field.xs || 24} md={field.md || field.sm || field.xs || 24} lg={field.lg || field.md || field.sm || field.xs || 12} xl={field.xl || field.lg || field.md || field.sm || field.xs || 12}>
                <Form.Item
                  label={<span style={{ fontWeight: 500, fontSize: "16px", color: "#333" }}>{field.label} :</span>}
                  validateStatus={errors[field.name] ? "error" : ""}
                  help={errors[field.name]?.message}
                  {...field.itemProps}
                >
                  <Controller
                    name={field.name}
                    control={control}
                    render={({ field: controllerField }) => {
                      if (field.component === PhoneInput) {
                        return (
                          <PhoneInput
                            disabled={formDisabled}
                            {...controllerField}
                            {...field.props}
                            country={field.props.country || "sa"}
                            onlyCountries={field.props.onlyCountries || []}
                            disableDropdown={field.props.disableDropdown !== undefined ? field.props.disableDropdown : true}
                            onChange={(value) => controllerField.onChange(value)}
                            inputStyle={{
                              width: "100%",
                              height: "50px",
                              padding: "10px 12px",
                              direction: field.props.lang === "ar" ? "rtl" : "ltr",
                              borderColor: errors[field.name] ? "#ff4d4f" : "#d9d9d9",
                              borderRadius: "10px"
                            }}
                            containerClass="phone-input-container"
                            inputClass="phone-input"
                            buttonStyle={{
                              backgroundColor: "transparent",
                              border: "none",
                              position: "absolute",
                              right: field.props.lang === "ar" ? "initial" : "25px",
                              left: field.props.lang === "ar" ? "25px" : "initial"
                            }}
                            buttonClass="country-dropdown"
                          />
                        );
                      }

                      if ([Select, "Select"].includes(field.component)) {
                        return (
                          <Select
                            disabled={formDisabled}
                            {...controllerField}
                            {...field.props}
                            onChange={(value) => {
                              console.log("OnChange Select From GenericForm", value);
                              controllerField.onChange(value);
                              field.props.onChange && field.props.onChange(value, field.name, setValue);
                            }}
                            value={controllerField.value}
                            style={{
                              width: "100%",
                              height: "50px",
                              padding: "5px",
                              borderRadius: "10px",
                              borderColor: errors[field.name] ? "#ff4d4f" : "#d9d9d9",
                              backgroundColor: "#ffffff"
                            }}
                          />
                        );
                      } else {
                        return React.createElement(field.component || Input, {
                          ...field.props,
                          ref: controllerField.ref,
                          name: field.name,
                          value: field.props?.getFieldValue ? field.props.getFieldValue(controllerField.value) : field.props?.value ? field.props.value : controllerField.value,
                          onBlur: controllerField.onBlur,
                          onChange: (e) => {
                            if (field.props?.onChange) {
                              field.props.onChange(e, field.name, setValue);
                            } else {
                              controllerField.onChange(e);
                            }
                          },
                          disabled: !!((formDisabled || field.props?.disabled)),
                          style: field.component === Switch
                            ? {}
                            : {
                                width: field.props?.type === "checkbox" ? "18px" : "100%",
                                padding: "10px",
                                borderRadius: "10px",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: errors[field.name] ? "#ff4d4f" : "#d9d9d9",
                                backgroundColor: "#ffffff"
                              }
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            );
          })}

       {/* Render custom children components */}
            <Col span={24}>
                {
                  React.Children.map(children, (child, index) => {
                    return (
                      React.isValidElement(child) && React.cloneElement(child, {
                        ...child.props,
                        setValue,
                        control,
                        errors,
                        isValid,
                        isDirty,
                        reset,
                        formDisabled,
                        initialValues,
                        fields
                      })
                    );
                  })
                }
            </Col>

            {/* Add PermissionsList in a full-width row */}
                  {fields.find(field => field.component === "PermissionsList") && (
                      <Col span={24}>
                        <Form.Item>
                          <div
                            style={{
                              padding: "20px",
                              borderRadius: "15px",
                              border: "1px solid transparent",
                              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                              backgroundColor: "#fff"
                            }}
                          >
                            <PermissionsList
                            {...fields.find(field => field.component === "PermissionsList").props}
                            />
                          </div>
                        </Form.Item>
                      </Col>
                  )}

          {fields.find(field => field.component === "AddressForm") && (
            <Col span={24}>
              <Form.Item>
                <div
                  style={{
                    padding: "20px",
                    borderRadius: "15px",
                    border: "1px solid transparent",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "#fff"
                  }}
                >
                  <AddressForm
                    {...fields.find(field => field.component === "AddressForm").props}
                  />
                </div>
              </Form.Item>
            </Col>
          )}

          <Col span={24} className="buttons-margin">
            <Row gutter={[12, 12]} justify="end">
              {fields
                .filter((field) => field.type === "button")
                .sort((a, b) => (a.buttonType === "primary" ? 1 : -1))
                .map((field, index) => {
                  const buttonClassName =
                    field.buttonType === "primary"
                      ? "confirm primary-btn"
                      : "secondary-btn";

                  return (
                    <Col key={index} xs="auto">
                      <Form.Item>
                        <Button
                          type={field.buttonType || "primary"}
                          htmlType={field.htmlType || "button"}
                          onClick={
                            field.htmlType === "submit"
                              ? undefined
                              : field.onClick
                          }
                          disabled={(field.htmlType === "submit" && (!isValid || !isDirty) && !skipDirty) || field.props?.disabled}
                          className={buttonClassName}
                          style={{
                            padding: "10px 20px",
                            borderRadius: "8px",
                            fontSize: "16px"
                          }}
                          {...field.props}
                        >
                          {field.label}
                        </Button>
                      </Form.Item>
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Form>

      <style>{`
        .primary-btn {
          background-color: #57be6c;
          color: white;
          border: none;
          padding: 12px 24px;
          font-size: 16px;
          border-radius: 10px;
          transition: background-color 0.3s ease;
        }

        .primary-btn:hover {
          background-color: #4aa55d;
        }

        .secondary-btn {
          background-color: transparent;
          color: #57be6c;
          border: 2px solid #57be6c;
          padding: 12px 24px;
          font-size: 16px;
          border-radius: 10px;
          transition: background-color 0.3s ease, color 0.3s ease;
        }

        .secondary-btn:hover {
          background-color: #57be6c;
          color: white;
        }

        .buttons-margin {
          margin-top: 20px;
        }
      `}</style>
    </ConfigProvider>
  );
}
