import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {};

const addOrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addOrder: (state, action) => {
      if (action.payload === "") {
        return {}; // Reset state to an empty object if payload is an empty string
      } else {
        Object.assign(state, action.payload); // Properly update the state object
      }
    }
  }
});

export const { addOrder } = addOrderSlice.actions;
export const useOrder = () => useSelector((state) => state.order);

export default addOrderSlice.reducer;
