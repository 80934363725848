import React, { useMemo } from "react";
import List from "@mui/material/List";
import { NavLink, useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Icon } from "@shopify/polaris";
import ListItemText from "@mui/material/ListItemText";
import { permissionGranted } from "../../../global-state/routes/generate-routes";
import { useUserObject, useExpiredSubscription } from "../../../stateManagement/slices/userSlice";

export default function SingleRoute (
  { className, path, details, isSeller, isThirdParty, overrideIcon } = {
    isSeller: false,
    isThirdParty: false
  }
) {
  const user = useUserObject();
  const navigate = useNavigate();
  const expiredSubscription = useExpiredSubscription();
  const isPermissionGranted = useMemo(
    () => permissionGranted(details.rules, user, expiredSubscription),
    [user?.role_id, user.role]
  );
  return (
        <>
            {isPermissionGranted
              ? (
                <List>
                    <NavLink
                        className={({ isActive }) =>
                          `${className} ${isActive ? (isSeller || isThirdParty ? "sellerActiveLink" : "activeLink") : ""}`
                        }
                        to={path}
                        onClick={(e) => {
                          e.preventDefault();
                          if (details.sidebarActionMethod) {
                            details.sidebarActionMethod();
                          }
                          navigate(path);
                        }
                        }
                    >
                        <ListItem button key={path}>
                            <ListItemIcon>
                                {overrideIcon ?? (
                                    <Icon source={details.icon} color="base" />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={details.title} />
                        </ListItem>
                    </NavLink>
                </List>
                )
              : undefined}
        </>
  );
}
