import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select, Switch, Row, Col, Button } from "antd";
import OrderDetails from "../../../../components/layout/OrderDetails";
import TablePro from "../../../../components/tables/TablePro";
import { useUserObject } from "../../../../stateManagement/slices/userSlice";
import { useGetOrderMutation } from "../../../../stateManagement/apis/ordersApi";
import { useFilterCitiesMutation } from "../../../../stateManagement/apis/treecodeApi";
import {
  useInventoryColumns,
  useConfirmedRequestsColumns,
  useUnConfirmedRequestsColumns,
  useInventoryOrderColumns
} from "./dashboardColumns";
import TableTitle from "./TableTitle";

function EmptyPlaceholder ({ currentStateTitle, onRefresh }) {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: "center", padding: "40px", backgroundColor: "#f5f5f5", borderRadius: "8px" }}>
      <h6 style={{ fontSize: "16px", fontWeight: "bold" }}>
        {t("refreshTitle")} {currentStateTitle}
      </h6>
      <p>{t("refreshText")}</p>
      <button
        onClick={onRefresh}
        style={{
          padding: "8px 16px",
          backgroundColor: "#126AFC",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer"
        }}
      >
        {t("refresh")}
      </button>
    </div>
  );
}

export default function DashboardRequestsTable ({
  currentState,
  currentStateTitle,
  isUnConfirmedRequest,
  data,
  onSearch,
  onRefresh,
  loading,
  selected,
  setSelected,
  pagination,
  cityFilter,
  setCityFilter,
  tableSelecedObjects,
  setTableSelecedObjects,
  inventory,
  tabsFilters,
  filterHandler,
  onDuplicate,
  onDelete
}) {
  const user = useUserObject();
  const [details, setDetails] = useState();
  const [checkStrictly, setCheckStrictly] = useState(false);
  const [getOrderDetails, { isLoading: detailsLoading }] = useGetOrderMutation();
  const [useCustomColumns, setUseCustomColumns] = useState(false);
  const { t } = useTranslation();
  const columnsMap = {
    inventory: {
      confirmed: useInventoryColumns(),
      unconfirmed: useInventoryOrderColumns()
    },
    requests: {
      confirmed: useConfirmedRequestsColumns(fetchDetails),
      unconfirmed: useUnConfirmedRequestsColumns(fetchDetails)
    }
  };

  const customColumns = useConfirmedRequestsColumns(fetchDetails)
    .filter((col) => {
      const keys = Array.isArray(col.dataIndex)
        ? col.dataIndex.join(".")
        : col.dataIndex; // Convert array to string
      return (
        ["order.id", "id", "order.seller.store_name", "create_date", "product.name", "quantity", "weight", "action"].includes(keys) ||
      ["orderId", "ticketId", "store", "createDate", "productInfo", "itemQuantity", "weight", "action"].includes(col.title)
      );
    })
    .sort((a, b) => {
      const order = [
        "product.name",
        "quantity",
        "weight",

        "order.id",
        "id",
        "order.seller.store_name",
        "create_date",
        "action"
      ];

      const keyA = Array.isArray(a.dataIndex) ? a.dataIndex.join(".") : a.dataIndex || a.title;
      const keyB = Array.isArray(b.dataIndex) ? b.dataIndex.join(".") : b.dataIndex || b.title;

      const indexA = order.indexOf(keyA);
      const indexB = order.indexOf(keyB);

      return indexA - indexB;
    });

  const columns = useCustomColumns
    ? customColumns
    : columnsMap[inventory ? "inventory" : "requests"][
      isUnConfirmedRequest ? "unconfirmed" : "confirmed"
    ];

  const handleCustomColumns = () => {
    setUseCustomColumns((prev) => !prev);
  };

  async function fetchDetails (id) {
    const result = await getOrderDetails(id);
    if (result.data) {
      setDetails(result.data);
    }
  }

  async function onChangeTickets (page) {
    const newDetails = { ...details };
    newDetails.tickets.page = page;

    const result = await getOrderDetails(details.id, { page_size: 4, page });
    if (result.data) {
      newDetails.tickets = result.data.tickets;
      newDetails.tickets.page = page;
      setDetails(newDetails);
    }
  }

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        width: "100%",
        margin: "0 auto"
      }}
    >
      {/* Header Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px"
        }}
      >
        <TableTitle currentStateTitle={currentStateTitle} />
        {currentState?.id === 6 && (
          <Button
            onClick={handleCustomColumns}
            type="primary"
            style={{ marginLeft: "16px" }}
          >
            {useCustomColumns ? t("states.In Hub") : t("products")}
          </Button>
        )}
      </div>

      {/* Table */}
      <TablePro
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        filterHandler={filterHandler}
        tabsFilters={tabsFilters}
        loading={loading}
        columns={columns}
        dataSource={loading ? [] : data}
        onSearch={onSearch}
        headerExtra={
          <ExtraHeader
            cityFilter={cityFilter}
            setCityFilter={setCityFilter}
            checkStrictly={checkStrictly}
            setCheckStrictly={setCheckStrictly}
            inventory={inventory}
          />
        }
        selection={user.role !== "Seller" ? "checkbox" : undefined}
        emptyPlaceholder={
          <EmptyPlaceholder
            currentStateTitle={currentStateTitle}
            onRefresh={onRefresh}
          />
        }
        onRowClick={(i, row) => fetchDetails(row.order?.id ?? row.id)}
        checkStrictly={checkStrictly}
        selectedRows={selected}
        setSelectedRows={setSelected}
        selectedRecords={tableSelecedObjects}
        setSelectedRecords={setTableSelecedObjects}
        pagination={pagination}
        preserveSelectedRowKeys
      />

      {/* Details Section */}
      {details && (
        <OrderDetails
          pagination={onChangeTickets}
          current={details.tickets.page ?? 1}
          tickets={details.tickets}
          ticketsCount={details.tickets.count}
          show={details}
          loading={detailsLoading}
          setClose={() => setDetails(undefined)}
          data={details}
        />
      )}
    </div>
  );
}

const ExtraHeader = ({ cityFilter, setCityFilter, checkStrictly, setCheckStrictly, inventory }) => {
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);
  const [citiesSearch, setCitiesSearch] = useState("");
  const [filterCities, { isLoading: citiesLoading }] = useFilterCitiesMutation();

  const getCities = async () => {
    const result = await filterCities({ page_size: 20, search: citiesSearch });
    if (result.data) {
      setCities(result.data.data);
    }
  };

  useEffect(() => {
    getCities();
  }, [citiesSearch]);

  return (
    <Row gutter={16} style={{ marginBottom: "16px", backgroundColor: "#fff", padding: "20px 0", borderRadius: "8px" }}>
      <Col>
        {!inventory && (
          <>
            <Select
              value={cityFilter?.length > 0 ? cityFilter : undefined}
              onChange={(area) => {
                setCityFilter(area);
                setCitiesSearch("");
              }}
              placeholder={t("Select Area")}
              options={cities.map((area) => ({ value: area.id, label: area.name }))}
              loading={citiesLoading}
              onSearch={setCitiesSearch}
              onDropdownVisibleChange={getCities}
              showSearch
              filterOption={false}
              style={{
                width: "220px",
                borderRadius: "8px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
              }}
              allowClear
              onClear={() => {
                setCityFilter(undefined);
                setCitiesSearch("");
              }}
            />
            <Switch
              checked={checkStrictly}
              onChange={(checked) => setCheckStrictly(checked)}
              checkedChildren={t("Unstrict")}
              unCheckedChildren={t("Strict")}
              style={{
                marginLeft: "16px",
                borderRadius: "8px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
              }}
            />
          </>
        )}
      </Col>
    </Row>
  );
};
