import { isRejectedWithValue } from "@reduxjs/toolkit";
import { notifyErrors } from "../../api/ErrorNotifier";
import { BaseUrl } from "../constants";
// import * as Sentry from "@sentry/react";
import i18n from "../../i18next";
import { setExpiredSubscription } from "../slices/userSlice";

export const RejectedWithValueHandler = (store) => (next) => (action) => {
  if (action && isRejectedWithValue(action)) {
    console.log("RejectedWithValueHandler: ", action);
    // Sentry.captureException(action.payload);
    const responseUrl = action.meta.baseQueryMeta.response.url;
    const responseStatus = isNaN(action.payload?.status) ? 500 : action.payload.status;
    console.log("responseStatus: ", responseStatus);
    let notificationMessage = "An error occurred. Please try again";
    let notify = true;
    switch (responseStatus) {
      case 400:
        notify = false;
        break;
      case 404:
        notificationMessage = "Resource not found";
        break;
      case 403:
        if (action.payload?.data?.code === "subscription_required") {
          notificationMessage = "Your subscription has expired. Please renew your subscription";
          store.dispatch(setExpiredSubscription(true)).then(() => { window.location.path = "/"; });
        }
        break;
      case 401:
        if (responseUrl === BaseUrl + "token/refresh/") {
          notificationMessage = "Session expired. Please login again";
        } else if (responseUrl === BaseUrl + "token/") {
          notify = false;
        }
        break;
      default:
        if (responseStatus < 400) {
          notify = false;
        } else if (responseUrl === BaseUrl + "token/") {
          notify = false;
        } else if (responseStatus === 500) {
          action.payload.data = i18n.t("server_error");
          notify = false;
        }
        break;
    }

    if (notify) {
      notifyErrors(i18n.t(notificationMessage));
    }
  }
  return next(action);
};
