import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "../../style-sheets/form.css";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PaginatedSelect, Radio, TextField } from "../form/TextInputFields";
import ModifiedForm from "../form/ModifiedForm";
import { addOrder, useOrder } from "../../stateManagement/slices/addOrderSlice";
import CreateNewCustomer from "../modals/CreateNewCustomer";
import useLanguage from "../../hooks/useLanguage";
import { useSelectedSeller, useSelectedClient } from "../../stateManagement/slices/selectedSellerSlice";
import { useUserObject } from "../../stateManagement/slices/userSlice";
import { useFilterClientsMutation } from "../../stateManagement/apis/treecodeApi";
import { useGetSellerMutation } from "../../stateManagement/apis/accountsApi";

export default function CustomerInfoForm ({ value, handleChange }) {
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [showCreate, setShowCreate] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [created, setCreated] = useState(true);
  const { t } = useTranslation();
  const adminAddresses = useSelectedSeller()?.saved_address;

  const adminSelectedAddress = useOrder().seller_address;
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [new_work_model, setNewWorkModel] = useState({});
  const [getSellerApi] = useGetSellerMutation();
  const [filterClientsApi] = useFilterClientsMutation();

  const selectedClient = useSelectedClient();

  const selectedSeller = useSelectedSeller();

  const user = useUserObject();
  const openCreateModal = () => {
    setShowCreate(true);
    setCreated(false);
  };

  const hideCreateModal = (e) => {
    setShowCreate(false);
  };

  useEffect(() => {
    console.log(value);
  }, []);

  const getClients = async () => {
    let params;
    if (user.role === "Seller") {
      params = {
        seller: user.user?.user_id,
        new_work_model: new_work_model?.id
      };
    } else {
      params = {
        seller: selectedSeller.id,
        new_work_model: new_work_model?.id
      };
    }

    const result = await filterClientsApi({ ...params, page });

    if (result.data) {
      setClients([...clients, ...result.data.data]);
      if (result.data.next === null) {
        setLoadMore(false);
      } else {
        setPage(page + 1);
      }
    }
  };
  const onScroll = async (event) => {
    const target = event.target;
    if (
      loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      await getClients();
    }
  };

  const changeAddress = (value) => {
    setSelectedAddress(value);
  };
  const validate = (data) => {
    console.log(`Form Data = ${data}`);

    if (data.client === undefined && selectedClient === "") {
      console.log("not valid");
      setErrMsg("This field is required");
    } else {
      dispatch(
        addOrder({
          ...data,
          seller_address: selectedAddress || adminSelectedAddress
        })
      );
      console.log(" valid");
      setErrMsg("");
      handleChange(null, parseInt(value) + 1);
    }
  };

  const previous = () => {
    handleChange(null, parseInt(value) - 1);
  };
  const getSellerAddresses = async () => {
    const result = await getSellerApi(user.user?.user_id);
    if (result.data) { setAddresses(result.data.saved_address); }
  };
  useEffect(() => {
    console.log(created);
    if (created) {
      try {
        getClients();
      } catch (err) {
        console.log(err);
      }
    }
  }, [created, new_work_model]);
  useEffect(() => {
    setErrMsg("");
  }, [selectedClient]);
  const lang = useLanguage();
  useEffect(() => {
    if (user.role === "Seller") getSellerAddresses();
  }, []);
  useEffect(() => {
    if (user.role === "Seller") {
      setNewWorkModel(
        addresses?.find((a) => a.id === selectedAddress)?.new_work_model
      );
    } else {
      console.log(adminAddresses, adminSelectedAddress);
      setNewWorkModel(
        adminAddresses?.find((a) => a.id === adminSelectedAddress)
          ?.new_work_model
      );
    }
  }, [
    user,
    addresses,
    selectedAddress,
    adminSelectedAddress,
    adminAddresses
  ]);
  return (
        <ModifiedForm submitFunction={validate}>
            {user.role === "Seller" && (
                <Row>
                    <Col sm={6}>
                        <Form.Label>{t("chooseSellerAddress")}</Form.Label>
                        <Select
                            id="seller_address"
                            style={{ width: "100%" }}
                            value={selectedAddress}
                            onChange={changeAddress}
                        >
                            {
                                // return sellers[index]
                                addresses.map((address) => (
                                    <Select.Option
                                        key={address.id}
                                        value={address.id}
                                    >
                                        {address.address}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>
            )}
            <Row>
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <a
                            className={
                                lang === "en"
                                  ? "over-select-link-en"
                                  : "over-select-link-ar"
                            }
                            onClick={openCreateModal}
                        >
                            {t("createNewCustomer")}
                        </a>
                        <PaginatedSelect
                            id="client"
                            title={t("fullname")}
                            disabled={!selectedAddress && !adminSelectedAddress}
                            onScroll={onScroll}
                            choice={selectedClient.id}
                        >
                            {
                                // return sellers[index]
                                clients.map((client) => (
                                    <Select.Option
                                        key={client.id}
                                        clientinfo={client}
                                        value={client.id}
                                        disabled={!client.available}
                                    >
                                        {client.name}
                                        {!client.available &&
                                            `(${t("notSupportedBySeller")})`}
                                    </Select.Option>
                                ))
                            }
                        </PaginatedSelect>
                        <p className="error">{errMsg !== "" ? errMsg : null}</p>
                        <TextField
                            id="phone1client"
                            className="formTextField text-area"
                            validation={{ required: true }}
                            title={t("phone")}
                            value={
                                selectedClient !== ""
                                  ? `${selectedClient.phone}`
                                  : ""
                            }
                        />{" "}
                        <TextField
                            id="phone2client"
                            className="formTextField text-area"
                            validation={{ required: true }}
                            title={t("phone")}
                            value={
                                selectedClient !== ""
                                  ? `${selectedClient.phone_alt}`
                                  : ""
                            }
                        />
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="addressCard">
                        <label>{t("chooseCustomerAddress")} </label>
                        {selectedClient.id
                          ? selectedClient?.client_addresses?.map(
                            (address) => {
                              return (
                                          <>
                                              <Radio
                                                  value={address.id}
                                                  id="client_address"
                                                  validation={{
                                                    required: true
                                                  }}
                                                  name="address"
                                                  className="radio-btn"
                                                  txt={address.address}
                                              />
                                          </>
                              );
                            }
                          )
                          : null}
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="text-end" sm="12">
                    <button className="previous" onClick={previous}>
                        {t("previous")}
                    </button>
                    {/* <button className="cancel" disabled>
            {t("discard")}
          </button> */}
                    <input
                        // onClick={(e) => handleChange(e, value + 1)}
                        className="confirm"
                        value={t("proceed")}
                        type="submit"
                    />
                </Col>
            </Row>
            <CreateNewCustomer
                new_work_model={new_work_model}
                setCreated={setCreated}
                showModal={showCreate}
                hideModal={hideCreateModal}
            />
        </ModifiedForm>
  );
}
