import { Input, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function SelectSearch ({
  value,
  disabled,
  options,
  onChange,
  clasName,
  name,
  loading,
  allowClear,
  children,
  onSearch,
  ...props
}) {
  const [searchOptions, setOptions] = useState([...(options ?? [])]);
  const lang = useLanguage();
  useEffect(() => {
    setOptions(options);
  }, [options]);
  const { t } = useTranslation();
  const search = _.debounce((e) => {
    if (onSearch) {
      onSearch(e.target.value);
    } else {
      const copy = [...options];
      const filtered = copy.filter((option) =>
        option.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions(filtered);
    }
  }, 500);
  return (
        <div className="block">
            <Select
                {...props}
                style={{
                  width: "100%"
                }}
                value={value}
                disabled={disabled}
                onChange={onChange}
                showSearch={false}
                allowClear={allowClear}
                className={`${clasName} block`}
                name={name}
                dropdownRender={(menu) => (
                    <div>
                        <div>
                            <Input
                                onChange={search}
                                placeholder={t("search")}
                            />
                        </div>
                        {loading
                          ? (
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "36px"
                                }}
                            >
                                <Spin indicator={antIcon} />
                            </div>
                            )
                          : (
                              menu
                            )}
                    </div>
                )}
            >
                {(searchOptions ?? []).map((option) => (
                    <Select.Option
                        key={option.id}
                        name="area"
                        value={option.id}
                    >
                        {lang.includes("en")
                          ? option.name
                          : option.name_ar ?? option.name}
                    </Select.Option>
                ))}
                {children}
            </Select>
        </div>
  );
}
