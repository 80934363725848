import React, { useState } from "react";
import { Button, Input, Dropdown, Menu } from "antd";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TablePro from "../../../components/tables/TablePro";
import { useGetInventoryItemsMutation } from "../../../stateManagement/apis/inventoryApi";
import { useUserObject } from "../../../stateManagement/slices/userSlice";

const InventoryProductsTable = () => {
  const { t } = useTranslation();
  const [getInventoryItems] = useGetInventoryItemsMutation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [searchTerm, setSearchTerm] = useState("");
  const user = useUserObject();
  const navigate = useNavigate();

  console.log("User:", user);

  const fetchData = async (params = {}) => {
    setLoading(true);
    try {
      const response = await getInventoryItems({
        product__seller__id: user.user?.user_id || "",
        page: params.current || 1,
        limit: params.pageSize || 10,
        search: searchTerm,
        ...params.filters
      }).unwrap();
      setData(response.data || []);
      setPagination({
        current: params.current || 1,
        pageSize: params.pageSize || 10,
        total: response.count || 0
      });
    } catch (error) {
      console.error("Error fetching inventory items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    fetchData({
      current: pagination.current,
      pageSize: pagination.pageSize,
      filters
    });
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchData({ current: 1, pageSize: pagination.pageSize });
  };

  const columns = [
    {
      title: t("productName"),
      dataIndex: ["product", "name"],
      key: "product_name"
    },
    {
      title: t("productCategory"),
      dataIndex: ["product", "category", "name"],
      key: "product_category"
    },
    {
      title: t("productCode"),
      dataIndex: "code",
      key: "product_code"
    },
    {
      title: t("location"),
      dataIndex: ["product", "description"],
      key: "location",
      render: (text) => (
        <span style={{ color: "#1890ff", cursor: "pointer" }}>{text}</span>
      )
    },
    {
      title: t("actions"),
      key: "actions",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" onClick={() => navigate(`/inv_item/${record.product.id}`)}>
                {t("edit")}
              </Menu.Item>
              <Menu.Item key="delete">{t("delete")}</Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>...</Button>
        </Dropdown>
      )
    }
  ];

  React.useEffect(() => {
    fetchData({ current: pagination.current, pageSize: pagination.pageSize });
  }, []);

  return (
    <div style={{ padding: "24px", backgroundColor: "#f9f9f9" }}>
      <h2 style={{ marginBottom: "16px" }}>{t("products")}</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px"
        }}
      >
        <Input.Search
          placeholder={t("searchPlaceholder")}
          onSearch={handleSearch}
          style={{ width: 300 }}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate("/new_inv_item")}
          >
            {t("addProduct.title")}
          </Button>
          <Button icon={<FilterOutlined />} type="default">
            {t("filter")}
          </Button>
        </div>
      </div>
      <TablePro
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => handleTableChange({ current: page, pageSize })
        }}
        onSearch={handleSearch}
        onRowClick={(record) => console.log("Row clicked:", record)}
      />
    </div>
  );
};

export default InventoryProductsTable;
