import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/table.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTicketsColumns } from "../../../../../components/layout/tableColumns";
import TablePro from "../../../../../components/tables/TablePro";
import { addOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { useUserObject } from "../../../../../stateManagement/slices/userSlice";
import { useFilterTicketsMutation } from "../../../../../stateManagement/apis/ticketsApi";

const ReplacedTickets = (props) => {
  // State hooks
  const [data, setData] = useState([]); // Table data
  const [page, setPage] = useState(1); // Current page
  const [count, setCount] = useState(1); // Total number of items
  const [keyword, setKeyword] = useState(""); // Search keyword
  const [selectedTicket, setSelectedTicket] = useState([]); // Selected tickets

  // Redux dispatch
  const dispatch = useDispatch();

  // API hook for filtering tickets
  const [filterTickets, { isLoading }] = useFilterTicketsMutation();

  // Current user object
  const user = useUserObject();

  // Fetch table data
  const getData = async () => {
    const params = {
      page,
      search: keyword,
      state__in: "8" // Only fetch tickets in the desired state
    };

    // Add seller-specific filter if user is a seller
    if (user.role === "Seller") {
      params.order__seller__id = user.user.user_id;
    }

    const result = await filterTickets(params);

    // Update state with fetched data
    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  // Fetch data on user change or on component mount
  useEffect(() => {
    getData();
  }, [user, page, keyword]);

  // Proceed action when clicking the "Proceed" button
  const proceed = () => {
    if (selectedTicket.length > 0) {
      const selectedIds = selectedTicket.map((ticket) => ticket.id);
      const tickets = data.filter((item) => selectedIds.includes(item.id));

      dispatch(
        addOrder({
          id: selectedTicket[0]?.order.id,
          seller: tickets[0]?.order?.seller,
          return_tickets: tickets
        })
      );

      // Move to the next step in props handler
      props.handleChange(null, props.value + 1);
    }
  };

  // Translation hook
  const { t } = useTranslation();

  // Configure table columns
  const columns = useTicketsColumns().splice(1);

  return (
    <div>
      <Container>
        <Row>
          <TablePro
            loading={isLoading}
            searchPlaceholder={t("replaceTxt1")}
            emptyPlaceholder={
              <div className="noDataDiv">
                <h6 className="noDataHead">{t("replaceTxt2")}</h6>
                <p>{t("replaceTxt3")}</p>
              </div>
            }
            dataSource={data}
            columns={columns}
            selectedRows={selectedTicket}
            setSelectedRows={setSelectedTicket}
            onSearch={(value) => {
              setKeyword(value);
              setPage(1);
            }}
            selection="checkbox"
            pagination={{
              current: page,
              total: count,
              onChange: setPage
            }}
          />
        </Row>
        <Row>
          <Col className="text-end mt-2" sm="12">
            <button
              disabled={selectedTicket.length === 0}
              className={user.role === "Seller" ? "confirmSeller" : "confirm"}
              onClick={proceed}
              type="submit"
            >
              {t("proceed")}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReplacedTickets;
