import { createApi } from "@reduxjs/toolkit/query/react";
import { SUBSCRIPTIONS_API } from "../constants";
import { reAuthBaseQuery } from "./baseQuery";

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["Subscription", "Plan"],
  endpoints: (builder) => ({
    getMySubscriptions: builder.mutation({
      query: () => ({
        url: `${SUBSCRIPTIONS_API.ROOT}/${SUBSCRIPTIONS_API.MY_SUBSCRIPTION}`,
        method: "GET"
      }),
      providesTags: ["Subscription"]
    }),
    getPlans: builder.mutation({
      query: () => ({
        url: `${SUBSCRIPTIONS_API.PLANS_ROOT}/`,
        method: "GET",
        params: {
          page_size: 100
        }
      }),
      providesTags: ["Plan"]
    }),
    getPlan: builder.mutation({
      query: (planId) => ({
        url: `${SUBSCRIPTIONS_API.PLANS_ROOT}/${planId}/`,
        method: "GET"
      }),
      providesTags: ["Plan"]
    }),
    subscribe: builder.mutation({
      query: ({ planId, ...body }) => ({
        url: `${SUBSCRIPTIONS_API.ROOT}/${SUBSCRIPTIONS_API.SUBSCRIBE}/${planId}/`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Subscription"]
    }),
    renewSubscription: builder.mutation({
      query: (subscriptionId) => ({
        url: `${SUBSCRIPTIONS_API.ROOT}/${SUBSCRIPTIONS_API.RENEW}/${subscriptionId}/`,
        method: "POST"
      }),
      invalidatesTags: ["Subscription"]
    }),
    cancelSubscription: builder.mutation({
      query: (subscriptionId) => ({
        url: `${SUBSCRIPTIONS_API.ROOT}/${SUBSCRIPTIONS_API.CANCEL}/${subscriptionId}/`,
        method: "POST"
      }),
      invalidatesTags: ["Subscription"]
    }),
    validateTransaction: builder.mutation({
      query: (transactionId) => ({
        url: `${SUBSCRIPTIONS_API.ROOT}/${SUBSCRIPTIONS_API.VALIDATE}/${transactionId}/`,
        method: "POST"
      }),
      invalidatesTags: ["Subscription"]
    })
  })
});

export const {
  useGetMySubscriptionsMutation,
  useGetPlansMutation,
  useGetPlanMutation,
  useSubscribeMutation,
  useRenewSubscriptionMutation,
  useCancelSubscriptionMutation,
  useValidateTransactionMutation
} = subscriptionApi;
