import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Typography, Divider } from "antd";
import { useParams } from "react-router-dom";
import OrderChainHeader from "../OrderChainHeader";
import OrderChainRepository from "../../../../api/OrderChainRepository";
import TicketChainDetails from "../TicketChain";

const { Panel } = Collapse;
const { Text } = Typography;

function OrderChainPersonDetails ({ details }) {
  const { t } = useTranslation();
  return (
    <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
      {Object.entries(details).map(([key, value]) => (
        <li
          key={key}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 0",
            borderBottom: "1px solid #f0f0f0"
          }}
        >
          <Text style={{ fontWeight: 500, color: "black" }}>{t(key)}:</Text>
          <Text style={{ color: "black" }}>{value}</Text>
        </li>
      ))}
    </ul>
  );
}

export default function OrderChainDetails () {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchOrderChain = useCallback(async () => {
    try {
      const res = await OrderChainRepository.getById(id);
      if (res.success) {
        setData(res.data);
      } else {
        setError(res.message || "Failed to fetch order details.");
      }
    } catch (err) {
      setError("An error occurred while fetching order details.");
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchOrderChain();
    }
  }, [fetchOrderChain, id]);

  if (error) {
    return (
      <div
        style={{
          padding: "16px",
          textAlign: "center",
          color: "#ff4d4f",
          fontSize: "16px",
          border: "1px solid #ffccc7",
          borderRadius: "8px",
          backgroundColor: "#fff2f0",
          maxWidth: "600px",
          margin: "24px auto"
        }}
      >
        {error}
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "24px",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease",
        minWidth: "1000px"
      }}
    >
      {data.order && <OrderChainHeader order={data.order} />}

      <Collapse
        bordered={false}
        expandIconPosition="end"
        style={{
          backgroundColor: "transparent",
          marginTop: "24px"
        }}
      >
        <Panel
          header={<Text strong style={{ fontSize: "16px", color: "#434343" }}>{t("sellerInfo")}</Text>}
          key="1"
          style={{
            borderRadius: "8px",
            backgroundColor: "#fafafa",
            marginBottom: "16px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.05)",
            transition: "all 0.3s ease"
          }}
        >
          <OrderChainPersonDetails details={data.seller ?? {}} />
        </Panel>
        <Panel
          header={<Text strong style={{ fontSize: "16px", color: "#434343" }}>{t("customerInfo")}</Text>}
          key="2"
          style={{
            borderRadius: "8px",
            backgroundColor: "#fafafa",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.05)",
            transition: "all 0.3s ease"
          }}
        >
          <OrderChainPersonDetails details={data.customer ?? {}} />
        </Panel>
      </Collapse>

      {data.summary && (
        <>
          <Divider style={{ margin: "24px 0" }} />
          <TicketChainDetails data={data.summary} />
        </>
      )}
    </div>
  );
}
