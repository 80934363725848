import React from "react";
import "../../../../../style-sheets/form.css";

import { useAccount } from "../../../../../stateManagement/slices/addAccountSlice";
import OperationPersonalInfo from "./operationPersonalInfo";
import CourierPersonalInfo from "./courierPersonalInfo";
import SellerPersonalInfo from "./sellerPersonalInfo";
import ThirdPartyPersonalInfo from "./thirdPartyPersonalInfo";

export default function AccountPersonalInfoForm ({
  value,
  handleChange,
  accountTypes
}) {
  const onBack = (e) => {
    e?.preventDefault();
    handleChange(undefined, value - 1);
  };

  // Using optional chaining to prevent errors if `useAccount` is undefined/null
  const selectedUser = useAccount()?.type;

  console.log({ selectedUser });

  // Switch based on `selectedUser`, fallback to OperationPersonalInfo if undefined
  switch (selectedUser) {
    case accountTypes.seller.index:
      return (
        <SellerPersonalInfo
          value={value}
          handleChange={handleChange}
          onBack={onBack}
        />
      );
    case accountTypes.courier.index:
      return (
        <CourierPersonalInfo
          value={value}
          handleChange={handleChange}
          onBack={onBack}
        />
      );
    case accountTypes.thirdParty.index:
      return (
        <ThirdPartyPersonalInfo
          value={value}
          handleChange={handleChange}
          onBack={onBack}
        />
      );
    default:
      return (
        <OperationPersonalInfo
          value={value}
          handleChange={handleChange}
          onBack={onBack}
        />
      );
  }
}
