import React, { useState, useEffect } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

const DEFAULT_LOCATION = {
  lat: 37.7749,
  lng: -122.4194
};

export default function MapPicker ({ setLat, setLng, lat, lng, width = "100%", height = "400px", showSearch = true }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBRPQPqn8uXo5KF3A4-H9YCJiEX6YRNZ2c",
    libraries: ["places"]
  });
  const { t } = useTranslation();

  const [currentLocation, setCurrentLocation] = useState(DEFAULT_LOCATION);
  const [markerPosition, setMarkerPosition] = useState(null);
  const locationSetRef = React.useRef(false);
  const [searchValue, setSearchValue] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    if ((lat && lng) || locationSetRef.current) return;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const roundedLat = parseFloat(position.coords.latitude.toFixed(6));
          const roundedLng = parseFloat(position.coords.longitude.toFixed(6));
          const newLocation = { lat: roundedLat, lng: roundedLng };

          setCurrentLocation(newLocation);
          console.log("newLocation", newLocation);
          if (setLat) setLat(roundedLat);
          if (setLng) setLng(roundedLng);
          locationSetRef.current = true;
        },
        () => console.error("Failed to retrieve location. Using default.")
      );
    }
  }, []);

  const handleSearch = async () => {
    setLoadingSearch(true);
    if (!searchValue.trim()) return;

    try {
      const geocoder = new window.google.maps.Geocoder();
      const response = await geocoder.geocode({ address: searchValue });

      if (response.results && response.results[0]) {
        const { lat, lng } = response.results[0].geometry.location;
        const newLat = lat();
        const newLng = lng();

        const newPosition = { lat: newLat, lng: newLng };
        setMarkerPosition(newPosition);
        setCurrentLocation(newPosition);

        if (setLat) setLat(newLat);
        if (setLng) setLng(newLng);
      }
    } catch (error) {
      console.error("Geocoding error:", error);
    } finally {
      setLoadingSearch(false);
    }
  };

  if (!isLoaded) return <p>Loading map...</p>;

  const handleMapClick = (event) => {
    if (!event.latLng) return;

    const clickedLat = parseFloat(event.latLng.lat().toFixed(6));
    const clickedLng = parseFloat(event.latLng.lng().toFixed(6));
    const clickedPosition = { lat: clickedLat, lng: clickedLng };
    console.log("clickedPosition", clickedPosition);
    console.log("setLat", setLat);
    console.log("setLng", setLng);
    setMarkerPosition(clickedPosition);
    setCurrentLocation(clickedPosition);
    if (setLat) setLat(clickedLat);
    if (setLng) setLng(clickedLng);
  };

  const mapContainerStyle = {
    width,
    height
  };

  return (
    <div>
      {showSearch && (
        <div className="d-flex gap-2 mb-2">
          <Input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t("Enter address to search")}
            style={{ flex: 1 }}

          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button
          onClick={handleSearch}
          type="primary"
          loading={loadingSearch}
        >
          {t("Search")}
          </Button>
        </div>
      )}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={lat && lng ? { lat, lng } : currentLocation}
        onClick={handleMapClick}
      >
        {markerPosition && <MarkerF position={markerPosition} />}
      </GoogleMap>
    </div>
  );
}
