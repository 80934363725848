import React from "react";
import { Breadcrumb, Typography, Row, Col } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function TableTitle ({ currentStateTitle }) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "16px",

        marginBottom: "16px"
      }}
    >
      {/* Breadcrumb Section */}
      <Breadcrumb separator={<RightOutlined style={{ fontSize: "10px" }} />}>
        <Breadcrumb.Item>
          <Typography.Text strong>{t("sideMenu.dashboard")}</Typography.Text>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Typography.Text type="secondary">{currentStateTitle}</Typography.Text>
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Title Section */}
      <Row style={{ marginTop: "8px" }}>
        <Col span={24}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {currentStateTitle}
          </Typography.Title>
        </Col>
      </Row>
    </div>
  );
}
