import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "antd";

import { SuccessfulRequest, UnSuccessfulRequest } from "../modals/confirmationModal";
import TablePro from "../tables/TablePro";
import { useGetInternalRunsheetMutation, useReassignInternalRunsheetMutation } from "../../stateManagement/apis/runsheetsApi";
import { useFilterCouriersMutation } from "../../stateManagement/apis/accountsApi";
import { stringErrorsHandler } from "../../api/ErrorNotifier";
import { useCourierColumns } from "./tableColumns";

function RunSheetCouriers () {
  const { t } = useTranslation();
  const [couriers, setCouriers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modaltype, setModalType] = useState("success");
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    success: false
  });
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const columns = useCourierColumns();
  const [runsheet, setRunsheet] = useState({});
  const [getRunSheetApi, { isLoading: loading }] = useGetInternalRunsheetMutation();
  const [reassignInternalRunsheetApi, { isLoading: loadingChange }] = useReassignInternalRunsheetMutation();
  const [filterCouriersApi, { isLoading: loadingCourier }] = useFilterCouriersMutation();

  const handleModalClose = (nav) => {
    setShowModal(false);
    setModalData({
      title: "",
      body: "",
      success: false
    });
    if (nav) {
      navigate(nav);
    }
  };

  const getRunSheet = async () => {
    const result = await getRunSheetApi(id);
    if (result.data) {
      setRunsheet(result.data);
    }
  };

  const getCouriers = async () => {
    const result = await filterCouriersApi({
      page,
      search: searchTerm,
      branch__in: `${runsheet?.branch?.id},${runsheet?.to_branch?.id}`,
      available: "available"
    });
    if (result.data) {
      setCouriers(result.data.data);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getRunSheet();
  }, []);

  useEffect(() => {
    getCouriers();
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getCouriers();
    }
  }, [searchTerm, runsheet]);

  const handleSelect = (courier) => {
    setSelectedRow(courier.length > 0 ? courier[0] : null);
  };
  const assignCourier = async (courier) => {
    const result = await reassignInternalRunsheetApi({
      runsheetId: id,
      courier: courier.id
    });
    if (result.data) {
      setModalType("success");
      setModalData({
        title: t("Reassign Runsheet"),
        body: t("Reassign Runsheet Success"),
        success: true
      });
      getRunSheet();
    } else if (result.error?.data) {
      setModalType("error");
      setModalData({
        title: t("Reassign Runsheet"),
        body: t(stringErrorsHandler(result.error.data)),
        success: false
      });
    } else {
      setModalType("error");
      setModalData({
        title: t("Reassign Runsheet"),
        body: t("server_error"),
        success: false
      });
    }
    setShowModal(true);
  };

  return (
        <Container>
            <SuccessfulRequest
                show={modaltype === "success" && showModal}
                hide={() => handleModalClose("/all_internals")}
                {...modalData}
            />
            <UnSuccessfulRequest
                show={modaltype === "error" && showModal}
                hide={handleModalClose}
                {...modalData}
            />

            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.internalRunSheet")}</span>

                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t("Reassign Runsheet")}
                    </span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("Change Runsheet Courier")}</h2>
                </Col>
            </Row>
            <div className="d-flex flex-column justify-content-start align-items-start gap-3 position-relative">
                <div className="w-100">
                    <TablePro
                        loading={loading || loadingCourier}
                        dataSource={couriers}
                        columns={columns}
                        onSearch={setSearchTerm}
                        pagination={{
                          size: "medium",
                          current: page,
                          onChange: setPage,
                          total: count
                        }}
                        rowKey={(row) => row.id}
                        selection="radio"
                        setSelectedRows={handleSelect}
                    />
                </div>
                <div className="d-flex justify-content-end align-items-center gap-2 w-100 row-reverse">
                    <Button
                        onClick={() => navigate.goBack()}
                        ghost
                        danger
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={() => assignCourier(selectedRow)}
                        loading={loadingChange}
                        disabled={!selectedRow}
                        style={{ color: "#fff", backgroundColor: "#2CCF41" }}
                    >
                        {t("Reassign Runsheet")}
                    </Button>
                </div>
            </div>
        </Container>
  );
}

export default RunSheetCouriers;
