import React, { useState, useEffect } from "react";
import { Input, Button, Tabs, Card, Row, Col, message } from "antd";
import { useTranslation } from "react-i18next";
import "./Plugins.css";
import PageHeader from "../../../components/layout/PageHeader";
import { useGetPluginsMutation, useUpdatePluginMutation } from "../../../stateManagement/apis/publicSchemaApi";
import { notifyErrors } from "../../../api/ErrorNotifier";
const { Search } = Input;
const { TabPane } = Tabs;

const Plugin = () => {
  const { t, i18n } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [getPlugins, { isLoading: loading }] = useGetPluginsMutation();
  const [updatePlugin] = useUpdatePluginMutation();

  // Fetch plugins from the API
  const fetchPlugins = async (query = "") => {
    try {
      const response = await getPlugins({ search: query });
      if (response.data) { setFilteredData(response.data); } else throw new Error("Error fetching plugins");
    } catch (error) {
      notifyErrors(error.message);
    }
  };

  useEffect(() => {
    fetchPlugins();
  }, []);

  const handleSearch = (value) => {
    fetchPlugins(value);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === "All") {
      fetchPlugins();
    } else {
      fetchPlugins(`&category=${key}`);
    }
  };

  const toggleActivation = async (pluginId) => {
    try {
      const plugin = filteredData.find(item => item.id === pluginId);
      if (!plugin) return;

      const updatedData = [...filteredData];
      const pluginIndex = updatedData.findIndex(item => item.id === pluginId);
      updatedData[pluginIndex] = { ...plugin, active: !plugin.active };
      setFilteredData(updatedData);

      const response = await updatePlugin({ pluginId, active: !plugin.active });
      if (response.data) {
        updatedData[pluginIndex] = response.data;
        setFilteredData(updatedData);
        message.success(t(response.data.active ? "activate" : "deactivate") + " " + t("success"));
      } else if (response.error?.data) {
        notifyErrors(response.error.data);
      } else {
        throw new Error("Error updating plugin");
      }
    } catch (error) {
      message.error(t("toggleActivationFailed"));
    }
  };

  const isArabic = i18n.language === "ar";

  return (
    <>
    <PageHeader title={t("integrationApps")}/>
    <div className={`plugin-container ${isArabic ? "rtl" : "ltr"}`}>
      <Tabs activeKey={activeTab} onChange={handleTabChange} className="plugin-tabs" >
        <TabPane tab={t("all")} key="All" />
        <TabPane tab={t("3PL")} key="3PL" />
        <TabPane tab={t("ecommerce")} key="Ecommerce" />
        <TabPane tab={t("sms")} key="SMS" />
        <TabPane tab={t("paymentGateways")} key="Payment Gateway" />
        <TabPane tab={t("maps")} key="Maps" />
      </Tabs>
      <Search
        placeholder={t("searchPlaceholder")}
        onChange={(e) => handleSearch(e.target.value)}
        className="plugin-search"
        style={{ width: "100%", marginBottom: 20 }}
      />
      <Row gutter={[16, 16]}>
        {filteredData.map((item) => (
          <Col key={item.id} span={12}>
            <Card className="plugin-card" loading={loading}>
              <Card.Meta title={isArabic ? item.name_ar : item.name} style={{
                marginBottom: "50px",
                textAlign: isArabic ? "right" : "left", // Align right for RTL, left for LTR
                fontSize: "50px",
                fontWeight: "bold"
              }} />
              <img alt={isArabic ? item.name_ar : item.name} src={item.image} className="plugin-card-image" />
              <p className="plugin-card-description " >
                {isArabic ? item.description_ar : item.description}
              </p>
              <Button
                type="primary"
                className="plugin-button"
                onClick={() => toggleActivation(item.id)}
                style={{
                  backgroundColor: "#57be6c",
                  borderColor: "#57be6c",
                  float: isArabic ? "left" : "right", // Adjust float based on direction
                  padding: "10px ", // Increase padding
                  marginTop: "10px",
                  fontSize: "16px", // Increase font size
                  height: "40px", // Increase button height
                  width: "148px" // Set button width to auto to ac
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#4aa358")}
                onMouseLeave={(e) => (e.target.style.backgroundColor = "#57be6c")}
              >
                {t(item.active ? "deactivate" : "activate")}
              </Button>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
    </>
  );
};

export default Plugin;
