import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Radio, TextField } from "../../../../../components/form/TextInputFields";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addOrder, useOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { usePlan } from "../../../../../stateManagement/slices/planSlice";
import { useUserObject } from "../../../../../stateManagement/slices/userSlice";
import { PaymentAlert } from "../../../../../components/modals/confirmationModal";
import { useGetSellerMutation } from "../../../../../stateManagement/apis/accountsApi";

export default function ReplacementSellerAddress ({ value, handleChange }) {
  const dispatch = useDispatch();
  const user = useUserObject();
  const { t } = useTranslation();

  const [errMsg, setErrMsg] = useState(""); // Error message for validation
  const [showPaymentAlert, setPaymentAlert] = useState(false); // Payment alert modal visibility
  const [sellerAddresses, setSellerAddresses] = useState([]); // List of seller addresses
  const [selectedReturnAddress, setSelectedReturnAddress] = useState(null); // Selected return address

  const [getSeller] = useGetSellerMutation();
  const selectedSeller = useOrder().seller;
  const Seller_address = useOrder()?.return_tickets?.[0]?.order?.seller_address;
  const sellerId = selectedSeller?.id;

  const order = useOrder();
  const plan = usePlan();

  // Fetch seller addresses when a seller is selected
  useEffect(() => {
    if (sellerId) {
      getSeller(sellerId)
        .unwrap()
        .then((data) => setSellerAddresses(data.seller_addresses || []))
        .catch((error) => console.error("Error fetching seller addresses:", error));
    }
  }, [sellerId, getSeller]);

  // Validation and dispatch logic
  const validate = (data) => {
    if (!data.return_address || !data.seller_address) {
      setErrMsg(t("requiredField")); // Ensure both addresses are selected
    } else {
      dispatch(
        addOrder({
          ...order,
          id: order?.return_tickets?.[0]?.order?.id,
          return_address: data.return_address,
          seller_address: data.seller_address
        })
      );
      setErrMsg(""); // Clear error message
      handleChange(null, value + 1); // Move to next step
    }
  };

  // Clear error message when selectedSeller changes
  useEffect(() => {
    setErrMsg("");
  }, [selectedSeller]);

  // Show payment alert if order count exceeds remaining limit
  useEffect(() => {
    if (plan?.orders_count >= plan?.orders_remaining) {
      setPaymentAlert(true);
    }
  }, [plan]);

  return (
    <ModifiedForm submitFunction={validate}>
      <>
        <Row>
          {/* Seller Information */}
          <Col sm="12" md="6">
            <div className="text-area-form">
              <TextField
                id="sellerName"
                className="formTextField text-area"
                title={t("sellername")}
                value={selectedSeller?.user?.name || ""}
                name="sellerName"
              />
              <p className="error">{errMsg}</p>
              <TextField
                id="storeName"
                className="formTextField text-area"
                title={t("storename")}
                value={selectedSeller?.store_name || ""}
                name="storeName"
              />
              <TextField
                id="phone"
                className="formTextField text-area"
                title={t("phone")}
                value={selectedSeller?.user?.phone || ""}
                name="phone"
              />
              <TextField
                id="altPhone"
                className="formTextField text-area"
                title={t("altPhone")}
                value={selectedSeller?.user?.phone_alt || ""}
                name="altPhone"
              />
            </div>
          </Col>

          {/* Address Selection */}
          <Col sm="12" md="6">
            {/* Return Address */}
            <div className="addressCard">
              <label>{t("chooseSellerReturnAddress")}</label>
              {sellerAddresses.length > 0
                ? (
                    sellerAddresses.map((address) => (
                  <Radio
                    key={address.id}
                    value={address.id}
                    id="return_address"
                    name="returnAddress"
                    className="radio-btn"
                    txt={address.address}
                    checked={selectedReturnAddress === address.id}
                    onChange={() => setSelectedReturnAddress(address.id)}
                  />
                    ))
                  )
                : (
                <p>{t("noReturnAddressAvailable")}</p>
                  )}
            </div>

            {/* Seller Address */}
            <div className="addressCard mt-3">
              <label>{t("chooseSellerAddress")}</label>
              {Seller_address
                ? (
                <Radio
                  value={Seller_address.id}
                  id="seller_address"
                  name="sellerAddress"
                  className="radio-btn"
                  txt={Seller_address.address || t("noAddressAvailable")}
                />
                  )
                : (
                <p>{t("noSellerAddressAvailable")}</p>
                  )}
            </div>
          </Col>
        </Row>

        {/* Navigation Buttons */}
        <Row>
          <Col className="text-end mt-2" sm="12">
            <button
              onClick={() => handleChange(null, value - 1)}
              className="previous"
            >
              {t("previous")}
            </button>
            <input
              className={user.role === "Seller" ? "confirmSeller" : "confirm"}
              value={t("proceed")}
              type="submit"
            />
          </Col>
        </Row>

        {/* Payment Alert Modal */}
        <PaymentAlert
          body={t("paymentAlertBody")}
          title={t("paymentAlertTitle")}
          setShow={setPaymentAlert}
          show={showPaymentAlert}
        />
      </>
    </ModifiedForm>
  );
}
