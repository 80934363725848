import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CreationConfirmation,
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../modals/confirmationModal";
import { usePostponedColumns } from "../layout/tableColumns";
import TablePro from "../tables/TablePro";
import {
  usePostponeDate,
  usePostponedIds,
  usePostponedOrders
} from "../../stateManagement/slices/postponedOrdersSlice";
import { usePostponedOrderMutation } from "../../stateManagement/apis/ordersApi";
import { notifyErrors, stringErrorsHandler } from "../../api/ErrorNotifier";

function PostponeRequest ({ value, handleChange }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // State management
  const [modals, setModals] = useState({
    showRequest: false,
    showConfirm: false,
    showError: false
  });
  const [error, setError] = useState("");

  // Data hooks
  const postponedOrders = usePostponedOrders();
  const postponedDate = usePostponeDate();
  const postponedIds = usePostponedIds();
  const [postpondOrder] = usePostponedOrderMutation();

  // Derived tickets list
  // const ticketsList = postponedOrders.flatMap((order) => order.tickets || []);

  // Modal state management
  const hideModals = () =>
    setModals({ showRequest: false, showConfirm: false, showError: false });

  const handleSendRequest = () => setModals((prev) => ({ ...prev, showRequest: true }));

  const handleConfirm = async () => {
    // Ensure postponedDate is in the correct format before converting it to Date
    const dateString = postponedDate;
    const formattedDate = new Date(dateString);

    // Check if the date is valid
    if (isNaN(formattedDate)) {
      return handleError("Invalid date format");
    }

    // Convert to ISO format
    const isoFormattedDate = formattedDate.toISOString();
    const requestData = {
      orders: postponedIds,
      // tickets: ticketsList,
      new_date: isoFormattedDate // Use the formatted date here
      // state: "postponed"
    };

    try {
      const result = await postpondOrder(requestData);

      if (result.data) {
        hideModals();
        setModals((prev) => ({ ...prev, showConfirm: true }));
      } else if (result.error?.data) {
        handleError(result.error.data.failed?.state ||
          "Something went wrong");
      } else {
        handleError(t("somethingWentWrong"));
      }
    } catch {
      handleError(t("somethingWentWrong"));
    }
  };

  const handleError = (errorData) => {
    notifyErrors(errorData);
    setError(stringErrorsHandler(errorData) || t("somethingWentWrong"));
    setModals((prev) => ({ ...prev, showError: true }));
  };

  // Auto-hide error modal on error change
  useEffect(() => {
    if (error) setModals((prev) => ({ ...prev, showError: true }));
  }, [error]);

  return (
    <>
      <Row className="mt-3">
        <Col sm="12" md="6">
          <div className="position-relative text-area-form">
            <label className="bold-label">{t("postponedDate")}</label>
            <Form.Control defaultValue={postponedDate} disabled type="date" />
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="text-end buttons-margin mb-3" sm="12">
          <button className="cancel" onClick={() => handleChange(null, 0)}>
            {t("discard")}
          </button>
          <button onClick={handleSendRequest} className="confirm">
            {t("sendRequest")}
          </button>
        </Col>
      </Row>

      <TablePro dataSource={postponedOrders} columns={usePostponedColumns()} />

      <Row>
        <CreationConfirmation
          sendRequest={handleConfirm}
          show={modals.showRequest}
          hide={hideModals}
          body={t("postponeConfirmText")}
          title={t("postponeConfirmation")}
        />
        <SuccessfulRequest
          show={modals.showConfirm}
          hide={() => {
            hideModals();
            setTimeout(() => navigate("/dashboard"), 500);
          }}
          title={t("postponeConfirmation")}
          body={t("postponeSuccessText")}
        />
        <UnSuccessfulRequest
          show={modals.showError}
          hide={hideModals}
          title={t("postponeConfirmation")}
          body={error}
        />
      </Row>
    </>
  );
}

export default PostponeRequest;
