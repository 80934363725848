import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ProductInfoForm from "../../../../common/tickets/create/ui/productInfoForm";
import OrderInfoForm from "../../../../common/tickets/create/ui/orderInfoForm";
import useQuery from "../../../../../hooks/useQuery";
import { addOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import {
  getSeller

} from "../../../../../stateManagement/slices/selectedSellerSlice";
import { getTicketsReturned } from "../../../../../stateManagement/slices/returnedTicketsSlice";
import { useArchivedOrder } from "../../../../../stateManagement/slices/archivedOrderSlice";
import AdminCustomerInfo from "./AdminCustomerInfo";
import SellerInfoForm from "./sellerInfoForm";

function TabPanel (props) {
  const { children, value, index, onClick, ...other } = props;

  const handlePanelClick = (event) => {
    // Check if the click target is the TabPanel itself and not a child element
    if (event.target === event.currentTarget) {
      onClick(index);
    }
  };

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            onClick={handlePanelClick} // Attach the click handler
            style={{ cursor: "pointer" }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function AdminCreateParcel () {
  const { t } = useTranslation();
  const { query, appendQuery } = useQuery();
  const value = +(query.page ?? 0);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    appendQuery("page", parseInt(newValue));
  };
  const archivedOrder = useArchivedOrder();

  useEffect(() => {
    // Set page query and handle archived order
    appendQuery("page", 0);

    if (archivedOrder) {
      dispatch(addOrder(archivedOrder));
      console.log("Archived Order:", archivedOrder);
    } else {
      dispatch(addOrder(""));
    }

    // Dispatch actions to get tickets returned and seller information
    dispatch(getTicketsReturned(""));
    dispatch(getSeller(""));
  }, [archivedOrder]);

  return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="admin"
            >
                <Tab label={t("sellerInfo")} {...a11yProps(0)} />
                <Tab label={t("customerInfo")} {...a11yProps(1)} />
                <Tab label={t("productInfo")} {...a11yProps(2)} />
                <Tab label={t("orderInfo")} {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0} onClick={handleChange}>
                <SellerInfoForm value={value} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={1} onClick={handleChange}>
                <AdminCustomerInfo value={value} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={2} onClick={handleChange}>
                <ProductInfoForm value={value} handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={3} onClick={handleChange}>
                <OrderInfoForm value={value} handleChange={handleChange} />
            </TabPanel>
        </>
  );
}

export default AdminCreateParcel;
