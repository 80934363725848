import React from "react";
import { Empty } from "antd";
import styles from "./TableComponent.module.css";

export default function TableEmpty () {
  return (
        <tbody>
        <tr className={[styles.empty].join(" ")}>
            <td colSpan="200">
                <Empty/>
            </td>
        </tr>
        </tbody>
  );
}
