import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { SuccessfulPayment } from "../modals/confirmationModal";

function PaymentSuccess () {
  function useQuery () {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const [show, setShow] = useState(true);

  return (
        <div>
            <SuccessfulPayment setShow={setShow} show={show} body={query.get("status")} />
        </div>
  );
}

export default PaymentSuccess;
