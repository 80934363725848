import * as Yup from "yup";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import GenericForm from "../../form/GenericForm";
import { useUserObject } from "../../../stateManagement/slices/userSlice";

const validationSchema = Yup.object().shape({
  returnType: Yup.string().required("Required").oneOf(["RETURN", "REVERSE"], "Invalid Return Type")
});

const ReturnRequestType = ({ value, handleChange, setReturnType }) => {
  const { t } = useTranslation();
  const user = useUserObject();
  const options = user.role === "Seller" ? { value: "REVERSE", label: "Reverse" } : [{ value: "RETURN", label: "Return" }, { value: "REVERSE", label: "Reverse" }];
  return (
        <div>
        <GenericForm
        fields={[
          {
            name: "returnType",
            label: t("returnType"),
            component: Select,
            props: {
              options,
              required: true
            }

          },
          {
            type: "button",
            label: t("proceed"),
            htmlType: "submit",
            buttonType: "primary"
          }

        ]}
        submitFunction={(data) => {
          setReturnType(data.returnType);
          handleChange(null, 1);
        }}
        validationSchema={validationSchema}
    />
    </div>
  );
};

export default ReturnRequestType;
