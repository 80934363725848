import { useTranslation } from "react-i18next";
import { Select, Checkbox, Input, Radio } from "antd";
import { useSelector } from "react-redux";
import { useMemo, useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import { PaginatedSelect } from "../components/form/TextInputFields";
import SelectAreas from "../components/select-search/AreasSelect";
import MapPicker from "../components/form/MapPicker";
export const useAccountTypeFields = (accountTypes, branches, selectedUser, t) => {
  const formFields = [
    {
      name: "userType",
      component: Select,
      label: t("selectUser"),
      props: {
        options: Object.values(accountTypes).map((it) => ({
          label: it.title,
          value: it.index
        })),
        placeholder: t("userType")
      }
    },
    ...(selectedUser && ![null, -1, 0].includes(selectedUser)
      ? [
          {
            name: "branch",
            component: PaginatedSelect,
            label: t("sideMenu.branches"),
            props: {
              options: branches.map((branch) => ({
                label: branch.name,
                value: branch.id
              }))
            }
          }
        ]
      : [])
  ];

  return formFields;
};

export const useSellerFormFields = (lang, addresses, setAddresses, handleDeleteAddress, handleAddAddress, branches, onBack) => {
  const { t } = useTranslation();
  return [
    {
      name: "name",
      label: t("fullname"),
      component: Input,
      props: { placeholder: t("fullname") },
      rules: { required: true }
    },
    {
      name: "store_name",
      label: t("storename"),
      component: Input,
      props: { placeholder: t("storename") },
      rules: { required: true }
    },
    {
      name: "email",
      label: t("email"),
      component: Input,
      props: { placeholder: t("email"), type: "email" },
      rules: { required: true }
    },
    {
      name: "phone",
      label: t("phone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"

      }
    },
    {
      name: "phone_alt",
      label: t("altPhone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"
      }
    },
    {
      name: "addresses",
      // label: t("sellerAddress"),
      component: "AddressForm",
      props: {
        branches,
        addresses,
        setAddresses,
        handleDeleteAddress, // Pass the handleDeleteAddress function
        handleAddAddress // Pass the handleAddAddress function
      }
    },
    {
      type: "button",
      label: t("addAddress"),
      buttonType: "dashed",
      onClick: handleAddAddress
    },
    {
      type: "button",
      label: t("proceed"),
      htmlType: "submit",
      buttonType: "primary"
    },
    {
      type: "button",
      label: t("back"),
      buttonType: "default",
      onClick: onBack
    }
  ];
};

export const useOperationPersonalInfoFields = (onBack) => {
  const { t } = useTranslation();
  const errors = useSelector((state) => state.account.errors) ?? "";

  const fields = [
    {
      name: "name",
      label: t("fullname"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("fullname"),
        backendErrors: errors.name?.[0] ?? null
      }
    },
    {
      name: "phone",
      label: t("phone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown",
        backendErrors: errors.phone?.[0] ?? null
      }
    },
    {
      name: "phone_alt",
      label: t("altPhone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"
      }
    },
    {
      name: "email",
      label: t("email"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("email"),
        backendErrors: errors.email?.[0] ?? null
      }
    },
    {
      type: "button",
      label: t("proceed"),
      htmlType: "submit",
      buttonType: "primary"
    },
    {
      type: "button",
      label: t("discard"),
      htmlType: "button",
      buttonType: "default",
      onClick: onBack
    }
  ];

  return fields;
};

export const useThirdPartyPersonalInfoFields = (onBack) => {
  const { t } = useTranslation();
  const errors = useSelector((state) => state.account.errors) ?? "";

  const fields = [
    {
      name: "name",
      label: t("companyName"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("companyName"),
        backendErrors: errors.name ?? null
      }
    },
    {
      name: "name_ar",
      label: t("CompanyName_ar"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("CompanyName_ar"),
        backendErrors: errors.name_ar ?? null
      }
    },
    {
      name: "phone",
      label: t("phone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"
      }
    },
    {
      name: "phone_alt",
      label: t("altPhone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"
      }
    },
    {
      name: "email",
      label: t("email"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("email"),
        type: "email",
        backendErrors: errors.email ?? null
      }
    },
    {
      name: "address",
      label: t("address"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("address"),
        backendErrors: errors.address ?? null
      }
    },
    {
      name: "website",
      label: t("website"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("website"),
        type: "url",
        backendErrors: errors.website ?? null
      }
    },
    {
      name: "facebook",
      label: t("facebook"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("facebook"),
        type: "url",
        backendErrors: errors.facebook ?? null
      }
    },
    {
      name: "instagram",
      label: t("instagram"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("instagram"),
        type: "url",
        backendErrors: errors.instagram ?? null
      }
    },
    {
      name: "twitter",
      label: t("twitter"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("twitter"),
        type: "url",
        backendErrors: errors.twitter ?? null
      }
    },
    {
      type: "button",
      label: t("confirm"),
      htmlType: "submit",
      buttonType: "primary"
    },
    {
      type: "button",
      label: t("back"),
      htmlType: "button",
      buttonType: "default",
      onClick: onBack
    }
  ];

  return fields;
};

/**
 * Returns an array of form fields and buttons for creating a courier
 * @param {Function} onBack - The function to call when the back button is clicked
 * @returns {Array<{ name: string, label: string, component: string, props: Object, rules: Object, type: string, buttonType: string, htmlType: string, onClick: Function }>}
 */
export const useCourierFields = (onBack) => {
  const { t } = useTranslation();
  const errors = useSelector((state) => state.account.errors) ?? "";

  const fields = [
    {
      name: "name",
      label: t("fullname"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("fullname")
      }
    },
    {
      name: "phone",
      label: t("phone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"
      }
    },
    {
      name: "phone_alt",
      label: t("altPhone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"
      }
    },
    {
      name: "email",
      label: t("email"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("email"),
        backendErrors: errors.email?.[0] ?? null
      }
    },
    {
      name: "vehicle",
      label: t("typeofvehicles"),
      component: Input,
      props: {
        className: "formTextField text-area",
        placeholder: t("vehicle")
      }
    },
    {
      name: "assign_to_terminal",
      label: t("Assign To Terminal"),
      component: Checkbox,
      props: {
        className: "formCheckBox text-area"
      }
    },
    {
      type: "button",
      label: t("confirm"),
      htmlType: "submit",
      buttonType: "primary"
    },
    {
      type: "button",
      label: t("back"),
      htmlType: "button",
      buttonType: "default",
      onClick: onBack
    }
  ];

  return fields;
};

/**
 * Returns an array of form fields and buttons for creating a product
 * @param {{ categories: Array<{ id: number, name: string }>, hideModal: Function, useModal: boolean }} props
 * @returns {Array<{ name: string, label: string, component: string, props: Object, rules: Object, type: string, buttonType: string, htmlType: string, onClick: Function }>}}
 */
export const useProductFormFields = (categories, inventoryActive) => {
  const { t } = useTranslation();

  return [
    {
      name: "pname",
      label: t("name"),
      component: Input,
      props: {
        placeholder: t("name")
      },
      rules: [{ required: true, message: t("inputProductName") }]
    },
    {
      name: "desc",
      label: t("description"),
      component: Input,
      props: {
        placeholder: t("description")
      }
    },
    inventoryActive && {
      name: "skuNumber",
      label: t("SKU"),
      component: Input,
      props: {
        placeholder: t("SKU")
      },
      rules: [{ required: true, message: t("inputSKU") }]
    },
    {
      name: "price",
      label: t("price"),
      component: Input,
      props: {
        placeholder: t("price")
      },
      rules: [{ required: true, message: t("inputPrice") }]
    },
    inventoryActive && {
      name: "category",
      label: t("Category"),
      component: Select,
      props: {
        placeholder: t("Category"),
        options: categories.map((cat) => ({
          label: cat.name,
          value: cat.id
        })),
        showSearch: true,
        optionFilterProp: "children",
        filterOption: (input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
      },
      rules: [{ required: true, message: t("inputCategory") }]
    },
    {
      type: "button",
      label: t("save"),
      htmlType: "submit",
      buttonType: "primary"
    }
  ].filter(Boolean); // Filter out falsy values like conditional SKU field
};

export const useThirdPartyFields = (thirdParty) => {
  const { t } = useTranslation();

  const fields = [
    {
      name: "name",
      label: t("name"),
      type: "text",
      rules: [
        {
          required: true,
          message: t("requiredField")
        }
      ]
    },
    {
      name: "name_ar",
      label: t("nameAr"),
      type: "text"
    },
    {
      name: "email",
      label: t("email"),
      type: "email",
      rules: [
        {
          required: true,
          message: t("requiredField")
        }
      ]
    },
    {
      name: "phone",
      label: t("phone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"
      }
    },
    {
      name: "phone_alt",
      label: t("altPhone"),
      component: PhoneInput,
      props: {
        disableDropdown: false,
        country: "sa",
        containerClass: "phone-input-container",
        inputClass: "phone-input",
        buttonClass: "country-dropdown"
      }
    },
    {
      name: "address",
      label: t("address"),
      type: "text",
      rules: [
        {
          required: true,
          message: t("requiredField")
        }
      ]
    },
    {
      name: "website",
      label: t("website"),
      type: "text",
      rules: [
        {
          required: false
        },
        {
          pattern: /^(http|https):\/\/[^ "]+$/,
          message: t("Invalid URL")
        }
      ]
    },
    {
      type: "button",
      label: t("save"),
      htmlType: "submit",
      buttonType: "primary"
    }
  ];

  return fields;
};

export const useThirdPartyKeysFields = (keys, currentKey, regenerateKey, copyToClipboard) => {
  const { t } = useTranslation();

  const fields = [
    {
      name: "api_key",
      label: t("API Key"),
      component: "input",
      props: {
        value: keys?.api_key || "",
        disabled: true
      },
      buttons: [
        {
          type: "button",
          label: (
            <>
              <CopyOutlined /> {t("Copy")}
            </>
          ),
          buttonType: "default",
          onClick: () => copyToClipboard(keys?.api_key),
          props: {
            style: { backgroundColor: "#57BE6C", color: "#fff", borderColor: "#57BE6C" }
          }
        },
        {
          type: "button",
          label: t("Regenerate"),
          buttonType: "primary",
          onClick: () => regenerateKey("api_key"),
          props: {
            loading: currentKey === "api_key",
            disabled: currentKey === "api_key",
            style: { backgroundColor: "#57BE6C", color: "#fff", borderColor: "#57BE6C" }
          }
        }
      ]
    },
    {
      name: "hmac_secret",
      label: t("HMAC Secret"),
      component: "input",
      props: {
        value: keys?.hmac_secret || "",
        disabled: true
      },
      buttons: [
        {
          type: "button",
          label: (
            <>
              <CopyOutlined /> {t("Copy")}
            </>
          ),
          buttonType: "default",
          onClick: () => copyToClipboard(keys?.hmac_secret),
          props: {
            style: { backgroundColor: "#57BE6C", color: "#fff", borderColor: "#57BE6C" }
          }
        },
        {
          type: "button",
          label: t("Regenerate"),
          buttonType: "primary",
          onClick: () => regenerateKey("hmac_secret"),
          props: {
            loading: currentKey === "hmac_secret",
            disabled: currentKey === "hmac_secret",
            style: { backgroundColor: "#57BE6C", color: "#fff", borderColor: "#57BE6C" }
          }
        }
      ]
    }
  ];

  return fields;
};

export const useCustomerFields = ({
  selectedSeller,
  areas,
  getClients,
  handleChange,
  value,
  isArabic
}) => {
  const { t } = useTranslation();

  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);

  const fields = useMemo(() => [
    {
      label: t("phone"),
      name: "phone",
      component: PhoneInput,
      required: true,
      props: {
        country: "sa",
        disableDropdown: true,
        inputStyle: { width: "100%", height: "50px", direction: isArabic ? "rtl" : "ltr" },
        onBlur: (e) => getClients(e.target.value)
      }
    },
    {
      label: t("fullname"),
      name: "name",
      component: "input",
      required: true
    },
    {
      label: t("altPhone"),
      name: "phone_alt",
      component: PhoneInput,
      props: {
        country: "sa",
        disableDropdown: true,
        inputStyle: { width: "100%", height: "50px", direction: isArabic ? "rtl" : "ltr" }
      }
    },
    {
      label: t("area"),
      name: "area",
      component: SelectAreas,
      required: true,
      props: { areas }
    },
    {
      label: t("address"),
      name: "address",
      component: "input",
      required: true
    },
    {
      label: t("location"),
      name: "mapPicker",
      component: MapPicker,
      props: {
        setLng,
        setLat,
        lng,
        lat,
        width: "100%",
        height: "200px"
      }
    },
    {
      type: "button",
      label: t("confirm"),
      htmlType: "submit",
      buttonType: "primary"
    },
    {
      type: "button",
      label: t("back"),
      htmlType: "button",
      buttonType: "default",
      onClick: () => handleChange(null, parseInt(value) - 1)
    }
  ], [areas, getClients, handleChange, isArabic, lng, lat, t, value]);

  return { fields, lng, lat };
};

export const useProductFields = (setShowCreate, ticketData, products, selectedProduct, handleSelectedProduct, handleChange, value, addAnotherProduct, setTicketData) => {
  const { t } = useTranslation();

  // Function to handle field changes and update ticket data directly
  const handleFieldChange = (index, fieldName, fieldValue) => {
    const updatedTickets = [...ticketData];
    updatedTickets[index] = {
      ...updatedTickets[index],
      [fieldName]: fieldValue
    };
    setTicketData(updatedTickets); // Update the state in the parent component
  };

  const buildFieldsForTicket = (data, index) => {
    const availableProducts = products.filter(
      (product) => !ticketData.some((ticket, i) => ticket.product === product.id && i !== index)
    );

    return [
      {
        name: `product_${index}`,
        label: t("products"),
        component: Select,
        props: {
          value: data.product || undefined,
          options: availableProducts.map((product) => ({
            label: product.name,
            value: product.id
          })),
          onChange: (productId) => handleSelectedProduct(productId, index),
          placeholder: t("selectProduct"),
          dropdownRender: (menu) => (
            <div>
              {menu}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px",
                  cursor: "pointer",
                  backgroundColor: "#57be6c",
                  borderRadius: "8px",
                  color: "#fff",
                  marginTop: "8px"
                }}
                onClick={() => setShowCreate(true)}
              >
                <PlusOutlined style={{ marginRight: 8 }} />
                <span>{t("createNewProduct")}</span>
              </div>
            </div>
          )
        }
      },
      {
        name: `price_${index}`,
        label: t("price"),
        component: Input,
        props: {
          value: data.price || "",
          onChange: (e) => handleFieldChange(index, "price", e.target.value),
          placeholder: t("price"),
          type: "number"
        }
      },
      {
        name: `weight_${index}`,
        label: t("weight"),
        component: Input,
        props: {
          value: data.weight || "",
          onChange: (e) => handleFieldChange(index, "weight", e.target.value),
          placeholder: t("weight"),
          type: "number"
        }
      },
      {
        name: `quantity_${index}`,
        label: t("quantity"),
        component: Input,
        props: {
          value: data.quantity || "",
          onChange: (e) => handleFieldChange(index, "quantity", e.target.value),
          placeholder: t("quantity"),
          type: "number"
        }
      },
      {
        name: `special_label_${index}`,
        label: t("specialLabel"),
        component: Input,
        props: {
          value: data.special_label || "",
          onChange: (e) => handleFieldChange(index, "special_label", e.target.value),
          placeholder: t("specialLabel")
        }
      },
      {
        name: `fragile_${index}`,
        label: t("Fragile"),
        component: Checkbox,
        props: {
          checked: data.fragile || false,
          onChange: (e) => handleFieldChange(index, "fragile", e.target.checked)
        }
      },
      {
        name: `special_${index}`,
        label: t("SpecialCare"),
        component: Checkbox,
        props: {
          checked: data.special || false,
          onChange: (e) => handleFieldChange(index, "special", e.target.checked)
        }
      },
      {
        name: `allow_open_${index}`,
        label: t("Allow_Open_Package"),
        component: Checkbox,
        props: {
          checked: data.allow_open || false,
          onChange: (e) => handleFieldChange(index, "allow_open", e.target.checked)
        }
      },
      {
        type: "button",
        label: t("confirm"),
        htmlType: "submit",
        buttonType: "primary"
      },
      {
        type: "button",
        label: t("back"),
        htmlType: "button",
        buttonType: "default",
        onClick: () => handleChange(null, parseInt(value) - 1)
      },
      {
        type: "button",
        label: t("createNewProduct"),
        htmlType: "button",
        buttonType: "default",
        onClick: addAnotherProduct
      }
    ];
  };

  return useMemo(() => {
    return ticketData.map((data, index) => buildFieldsForTicket(data, index));
  }, [ticketData, products, selectedProduct, handleSelectedProduct, handleChange, addAnotherProduct]);
};

export const useSellerInfoFormFields = ({
  selectedSeller,
  sellers = [], // Default to empty array
  sellerAddresses = [], // Default to empty array
  handleSellerChange,
  search,
  onScroll,
  setPaymentAlert,
  returnedTickets,
  plan,
  i18n,
  loadingSellers,
  inventory, // Pass inventory flag,
  onScrollSellerAddress,
  onSearchSellerAddress,
  loadingSellerAddress
}) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const baseFields = [
      {
        name: "seller",
        label: t("sellername"),
        component: Select,
        props: {
          showSearch: true,
          placeholder: t("sellerSearch"),
          loading: loadingSellers,
          onSearch: search,
          onPopupScroll: onScroll,
          onChange: handleSellerChange,
          options: (sellers && sellers.length > 0 ? sellers : []).map((seller) => ({
            label: seller.store_name,
            value: seller.id
          })), // Safely map over sellers
          value: selectedSeller?.id || null,
          readOnly: returnedTickets.length > 0,
          filterOption: false
        }
      }
    ];

    const sellerFields = selectedSeller
      ? [
          {
            name: "store_name",
            label: t("storename"),
            props: {
              value: selectedSeller.store_name,
              readOnly: true
            }
          },
          {
            name: "phone",
            label: t("phone"),
            component: PhoneInput,
            props: {
              country: "sa",
              value: selectedSeller.user?.phone || "",
              disabled: true
            }
          },
          {
            name: "altPhone",
            label: t("altPhone"),
            component: PhoneInput,
            props: {
              country: "sa",
              value: selectedSeller.user?.phone_alt || "",
              disabled: true,
              inputStyle: {
                direction: i18n.language === "ar" ? "rtl" : "ltr"
              }
            }
          }
        ]
      : [];

    const addressField = selectedSeller
      ? {
          name: "seller_address",
          label: t("chooseSellerAddress"),
          component: Select,
          props: {
            options: (sellerAddresses && sellerAddresses.length > 0 ? sellerAddresses : []).map((address) => ({
              label: address.address,
              value: JSON.stringify(address)
            })), // Safely map over sellerAddresses
            onPopupScroll: onScrollSellerAddress,
            onSearch: onSearchSellerAddress,
            filterOption: false,
            placeholder: t("Choose Seller Address"),
            showSearch: true,
            allowClear: true,
            loading: loadingSellerAddress
          }
        }
      : null;

    // Additional fields if inventory is true
    const inventorySpecificFields = inventory
      ? [
          {
            name: "pickup_time",
            label: t("pickupTime"),
            component: "input",
            props: {
              placeholder: t("Enter Pickup Time"),
              readOnly: false
            }
          },
          {
            name: "inventory_notes",
            label: t("notes"),
            component: "textarea",
            props: {
              placeholder: t("Enter any additional notes"),
              readOnly: false
            }
          }
        ]
      : [];

    const buttonField = {
      type: "button",
      label: t("next"),
      buttonType: "primary",
      htmlType: "submit"
    };

    let updatedFields = [...baseFields, ...sellerFields];
    if (addressField) {
      updatedFields.push(addressField);
    }
    updatedFields = [...updatedFields, ...inventorySpecificFields];
    updatedFields.push(buttonField);

    setFields(updatedFields);

    if (plan?.orders_count >= plan?.orders_remaining) {
      setPaymentAlert(true);
    }
  }, [sellers, selectedSeller, sellerAddresses, plan, i18n.language, inventory]);

  return fields;
};

export const useSellerCustomerInfoFormFields = ({
  t,
  selectedClient,
  setSelectedClient,
  addresses,
  adminAddresses,
  selectedAddress,
  setSelectedAddress,
  clients,
  setClients,
  nextClients,
  setNextClients,
  user,
  lang,
  inventory,
  axiosInstance,
  setShowCreate
}) => {
  const fields = [
    {
      name: "seller_address",
      label: t("chooseSellerAddress"),
      component: Select,
      props: {
        options: (addresses.concat(adminAddresses || [])).map((address) => ({
          value: address.id,
          label: address.address
        })),
        onChange: setSelectedAddress,
        value: selectedAddress
      },
      show: user.role === "Seller"
    },
    {
      name: "client",
      label: t("customer"),
      component: Select,
      props: {
        options: clients.map((client) => ({
          value: client.id,
          label: client.name
        })),
        value: selectedClient?.id,
        onChange: (value) => setSelectedClient(clients.find((client) => client.id === value)),
        onScroll: async (event) => {
          const target = event.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight && nextClients) {
            const result = await axiosInstance.get(nextClients); // This remains in component
            setClients([...clients, ...result.data.data]);
            setNextClients(result.data.next);
          }
        }
      },
      show: inventory
    },
    {
      name: "phone",
      label: t("phone"),
      component: PhoneInput,
      props: {
        country: "sa",
        disableDropdown: true,
        value: selectedClient?.phone || "",
        lang
      },
      show: inventory
    },
    {
      name: "altPhone",
      label: t("altPhone"),
      component: PhoneInput,
      props: {
        country: "sa",
        disableDropdown: true,
        value: selectedClient?.phone_alt || "",
        lang
      },
      show: inventory
    },
    {
      name: "client_address",
      label: t("chooseCustomerAddress"),
      component: Radio.Group,
      props: {
        options: selectedClient?.client_addresses?.map((address) => ({
          value: address.id,
          label: address.address
        })) || [],
        value: selectedAddress || selectedClient?.client_address?.id || null,
        style: { display: "flex", flexDirection: "column", gap: "20px" },
        optionType: "button",
        buttonStyle: "solid",
        onChange: (e) => setSelectedAddress(e.target.value)
      },
      show: selectedClient && selectedClient.client_addresses?.length > 0
    },
    {
      type: "button",
      label: t("proceed"),
      buttonType: "primary",
      htmlType: "submit"
    },
    {
      type: "button",
      label: t("createNewCustomer"),
      htmlType: "button",
      buttonType: "default",
      onClick: () => setShowCreate(true) // Open CreateNewCustomer modal
    }
  ];

  return fields.filter(field => field.show !== false);
};

export const useCreateCustomerFormFields = (areas, onScroll, getAreas, hideModal) => {
  const { t } = useTranslation();
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const fields = useMemo(() => {
    const f = [
      {
        name: "name",
        label: t("fullname"),
        component: Input,
        props: { placeholder: t("fullname") },
        rules: { required: t("nameError") }

      },
      {
        name: "phone",
        label: t("phone"),
        component: PhoneInput,
        props: { country: "sa", placeholder: t("phone"), enableSearch: true },
        rules: { required: t("phoneError") }
      },
      {
        name: "altPhone",
        label: t("altPhone"),
        component: PhoneInput,
        props: { country: "sa", placeholder: t("altPhone"), enableSearch: true }
      },
      {
        name: "area",
        label: t("area"),
        component: Select,
        props: {
          options: areas.map((area) => ({ value: area.id, label: area.name })),
          style: { width: "100%" },
          onPopupScroll: onScroll,
          onClick: getAreas
        },
        rules: { required: t("areaError") }
      },
      {
        name: "address",
        label: t("address"),
        component: Input,
        props: { placeholder: t("address") },
        rules: { required: t("addressError") }
      },
      {
        label: t("location"),
        name: "mapPicker",
        component: MapPicker,
        props: {
          setLng: (lng) => setLng(lng),
          setLat: (lat) => setLat(lat),
          lng,
          lat,
          width: "100%",
          height: "200px"

        }
      },
      {
        type: "button",
        buttonType: "secondary",
        label: t("discard"),
        onClick: hideModal
      },

      {
        type: "button",
        label: t("proceed"),
        buttonType: "primary",
        htmlType: "submit"
      }
    ];

    return f;
  }, [areas, onScroll, getAreas, hideModal, t, lat, lng]);

  return { fields, lat, lng };
};
