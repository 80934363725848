export const INVENTORY_ORDER_TYPES = {
  RETURN: "RETURN",
  RECIEVE: "RECIEVE",
  TRANSFER: "TRANSFER"
};

export const INVENTORY_ORDER_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED"
};

export const AWB_TYPE = {
  ORDER_AWB: "Order AWB",
  TICKET_AWB: "Ticket AWB"
};

export const WITHIN_UNITS = {
  hour: "Hour",
  day: "Day"
};

export const PRICING_TYPES = {
  next_day: "Next Day",
  same_day: "Same Day"
};
