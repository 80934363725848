import React, { useState, useEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { CircleRightIcon } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { reduxBranch } from "../../../stateManagement/slices/branchSlice";
import { getSeller } from "../../../stateManagement/slices/selectedSellerSlice";
import TablePro from "../../tables/TablePro";
import { useAuthedUser } from "../../../stateManagement/slices/userSlice";
import { useGetSellersWalletsMutation } from "../../../stateManagement/apis/accountsApi";

function SellersWallets (props) {
  const cur = useAuthedUser().organization.country.currency;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sellerDispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const { getSellersWallets } = useGetSellersWalletsMutation();
  const columns = useMemo(
    () => [
      {
        dataIndex: "seller_id",
        title: t("sellerID"),
        render: (_, row) => <>#{row.seller_id}</>
      },
      {
        dataIndex: "seller_name",
        title: t("sellername")
      },
      {
        dataIndex: "store",
        title: t("storename")
      },
      {
        dataIndex: "debit",
        title: t("debit"),
        render: (_, row) => (
                    <p className="debit">
                        {cur} {row.debit}
                    </p>
        )
      },
      {
        dataIndex: "credit",
        title: t("credit"),
        width: 180,
        render: (_, row) => (
                    <p className="credit">
                        {cur} {row.credit}
                    </p>
        )
      },
      {
        dataIndex: "total_amount",
        title: t("totalAmount"),
        width: 220,
        // valueGetter: (row) => row.row?.id,
        render: (_, row) => (
                    <p
                        className={
                            parseInt(row.debit ?? 0) -
                                parseInt(row.credit ?? 0) >
                            0
                              ? "debit"
                              : parseInt(row.debit ?? 0) -
                                      parseInt(row.credit ?? 0) <
                                  0
                                ? "credit"
                                : "amount"
                        }
                    >
                        {cur}{" "}
                        {parseInt(row.debit ?? 0) - parseInt(row.credit ?? 0)}
                    </p>
        )
      },
      {
        dataIndex: "more",
        title: "more",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        render: (_, row) => (
                    <Link
                        onClick={() => {
                          dispatch(reduxBranch(row.seller_name));
                          sellerDispatch(getSeller(row));
                        }}
                        to={`/sellers_orders/${row.seller_id}`}
                        className="sellers-wallets"
                    >
                        <Icon source={CircleRightIcon} color="base" />
                    </Link>
        )
      }
    ],
    []
  );

  const getSellers = async () => {
    const result = await getSellersWallets({
      search: search?.length > 0 ? search : undefined,
      page
    });
    if (result) {
      setData(result.data.results);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getSellers();
  }, [search, page]);
  return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("totalBalance")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sellerWallet")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <TablePro
                title={
                    <h3 style={{ marginBottom: "0px", fontSize: "20px" }}>
                        {t("sideMenu.sellers")}
                    </h3>
                }
                pagination={{
                  current: page,
                  total: count,
                  onChange: (page) => setPage(page)
                }}
                onSearch={setSearch}
                dataSource={data}
                columns={columns}
            />
        </>
  );
}

export default SellersWallets;
