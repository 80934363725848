import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { notification, Form as AntForm, Button, Select, Input, Spin, Checkbox } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useGetBranchesMutation } from "../../../stateManagement/apis/treecodeApi";
import WareHousesRepository from "../../../api/WarehousesRepository";

const { Option } = Select;

const WarehouseDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { warehouseId } = useParams();

  const [warehouse, setWarehouse] = useState(null);
  const [warehouseForm] = AntForm.useForm();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [branches, setBranches] = useState([]);

  const [getBranchesApi] = useGetBranchesMutation();

  const fetchBranches = async () => {
    try {
      const response = await getBranchesApi({ page: 1, limit: 10 }).unwrap();
      setBranches(
        response.data.map((branch) => ({
          value: branch.id,
          label: branch.name
        }))
      );
    } catch (error) {
      notification.error({ message: t("Error while fetching branches") });
    }
  };

  const fetchWarehouse = async () => {
    setLoading(true);
    try {
      const response = await WareHousesRepository.getWarehouse(warehouseId);
      if (response.success) {
        const warehouseData = response.data;
        setWarehouse({
          ...warehouseData,
          locations:
            warehouseData.locations?.map((loc) => ({
              name: loc.name || "",
              hasShelves: false,
              parent: loc.parent_id || null
            })) || []
        });
      } else {
        notification.error({ message: response.error || t("An error occurred") });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const { locations, branches, ...rest } = values;
      const payload = {
        ...rest,
        branches: branches || [],
        locations: locations.map((location) => ({
          name: location.name,
          type: "SKU",
          has_shelves: location.hasShelves || false,
          parent: location.parent || null
        }))
      };

      const response =
        warehouseId === "create"
          ? await WareHousesRepository.createWarehouse(payload)
          : await WareHousesRepository.updateWarehouse(payload, warehouseId);

      if (response.success) {
        notification.success({
          message:
            warehouseId === "create"
              ? t("Warehouse has been created successfully")
              : t("Warehouse has been updated successfully")
        });
        navigate("/warehouses");
      } else {
        notification.error({ message: response.error || t("An error occurred") });
      }
    } catch (error) {
      notification.error({ message: t("An error occurred during submission") });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchBranches();
    if (warehouseId !== "create") {
      fetchWarehouse();
    } else {
      setWarehouse({
        name: "",
        code: "",
        address: "",
        branches: [],
        locations: [{ name: "", hasShelves: false, parent: null }]
      });
    }
  }, [warehouseId]);

  if (loading || !warehouse) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: 20, backgroundColor: "#f9f9f9", padding: 20, borderRadius: 10 }}>
      <Row>
        <Col>
          <h2 style={{ fontSize: 24, fontWeight: "bold", color: "#333" }}>
            {warehouseId === "create" ? t("Create Warehouse") : t("Edit Warehouse")}
          </h2>
        </Col>
      </Row>
      <Row>
        <AntForm
          layout="vertical"
          form={warehouseForm}
          onFinish={handleSubmit}
          initialValues={warehouse}
          style={{ background: "#fff", borderRadius: 8, padding: 20, boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
        >
          <AntForm.Item
            name="name"
            label={t("Warehouse Name")}
            rules={[{ required: true, message: t("requiredField") }]}
          >
            <Input placeholder={t("Warehouse Name")} />
          </AntForm.Item>

          <AntForm.Item name="code" label={t("Warehouse Code")}>
            <Input placeholder={t("Warehouse Code")} />
          </AntForm.Item>

          <AntForm.Item name="address" label={t("Address")}>
            <Input placeholder={t("Address")} />
          </AntForm.Item>

          <AntForm.Item name="branches" label={t("Branches")}>
            <Select mode="multiple" placeholder={t("Select Branches")}
              options={branches.map((branch) => ({ label: branch.label, value: branch.value }))} />
          </AntForm.Item>

          <h3 style={{ fontSize: 18, fontWeight: "bold", marginBottom: 16 }}>{t("Locations")}</h3>
          <AntForm.List name="locations">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: 8,
                      padding: 16,
                      marginBottom: 16
                    }}
                  >
                    <AntForm.Item
                      {...restField}
                      name={[name, "name"]}
                      label={t("Location Name")}
                      rules={[{ required: true, message: t("requiredField") }]}
                    >
                      <Input placeholder={t("Location Name")} />
                    </AntForm.Item>
                    <AntForm.Item
                      {...restField}
                      name={[name, "hasShelves"]}
                      valuePropName="checked"
                    >
                      <Checkbox>{t("Has Shelves")}</Checkbox>
                    </AntForm.Item>
                    <AntForm.Item
                      {...restField}
                      name={[name, "parent"]}
                      label={t("Parent Location")}
                    >
                      <Select placeholder={t("Select Parent")} allowClear>
                        {warehouse?.locations?.map((loc, index) => (
                          <Option key={index} value={loc.id}>
                            {loc.name}
                          </Option>
                        ))}
                      </Select>
                    </AntForm.Item>
                    <Button
                      type="link"
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(name)}
                      style={{ color: "red" }}
                    >
                      {t("Remove")}
                    </Button>
                  </div>
                ))}
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  style={{ marginTop: 10 }}
                >
                  {t("Add Location")}
                </Button>
              </>
            )}
          </AntForm.List>

          <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
            <Button
              style={{ border: "1px solid #27ae60", background: "transparent", color: "#27ae60" }}
              onClick={() => navigate("/warehouses")}
            >
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
              style={{ background: "#27ae60", borderColor: "#27ae60" }}
            >
              {warehouseId === "create" ? t("Next") : t("Update Warehouse")}
            </Button>
          </div>
        </AntForm>
      </Row>
    </Container>
  );
};

export default WarehouseDetails;
