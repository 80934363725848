import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../../../style-sheets/form.css";
import { useTranslation } from "react-i18next";
import BranchManagers from "./branchManagers";
import Permissions from "./permissions";

function TabPanel (props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
const PermissionsTabs = () => {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [selectedUser, setSelectedUser] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSelectedUser = (e) => {
    setSelectedUser(e);
  };

  return (
    <div>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.settings")}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("permissions")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("permissions")}</h2>
        </Col>
      </Row>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            // onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("chooseAccount")} {...a11yProps(0)} />
            <Tab label={t("permissionsList")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <BranchManagers
            searchText={t("managerSearch")}
            value={value}
            handleChange={handleChange}
            getSelectedUser={getSelectedUser}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Permissions value={value} handleChange={handleChange} selectedUser={selectedUser} />
        </TabPanel>
      </Box>
    </div>
  );
};

export default PermissionsTabs;
