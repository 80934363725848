import React, { useEffect, useState } from "react";
import { Input, Row, Col, Switch } from "antd";
import { useTranslation } from "react-i18next";
import {
  useGetPermissionsMutation,
  useAddPermissionToUserMutation,
  useRemovePermissionFromUserMutation
} from "../../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../../api/ErrorNotifier";

const Permissions = ({ selectedUser }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [getPermissions] = useGetPermissionsMutation();
  const [addPermissionToUser] = useAddPermissionToUserMutation();
  const [removePermissionFromUser] = useRemovePermissionFromUserMutation();

  const userId = selectedUser?.[0]?.user?.id;
  const userName = selectedUser?.[0]?.user?.name;
  const branchName = selectedUser?.[0]?.branch?.name;

  useEffect(() => {
    const fetchPermissions = async () => {
      if (userId) {
        const result = await getPermissions({ user_id: userId });
        if (result.data) setData(result.data);
      }
    };
    fetchPermissions();
  }, [userId, getPermissions]);

  const togglePermission = async (permissionId, isActive) => {
    const mutation = isActive ? removePermissionFromUser : addPermissionToUser;
    const result = await mutation({ user_id: userId, permissions: permissionId });
    if (result.data) {
      const updatedPermissions = await getPermissions({ user_id: userId });
      if (updatedPermissions.data) setData(updatedPermissions.data);
    } else {
      notifyErrors(result.error?.data || "An error occurred");
    }
  };

  return (
    <div
      style={{
        padding: 24,
        borderRadius: "10px",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        margin: "auto"
      }}
    >
      <Row gutter={16} style={{ marginBottom: 24 }}>
        <Col span={12}>
          <label style={{ fontWeight: "bold", fontSize: 14, color: "#595959" }}>
            {t("managerName")}
          </label>
          <Input
            value={userName}
            disabled
            style={{ borderRadius: 4, color: "#000", fontWeight: 500 }}
          />
        </Col>
        <Col span={12}>
          <label style={{ fontWeight: "bold", fontSize: 14, color: "#595959" }}>
            {t("branch")}
          </label>
          <Input
            value={branchName}
            disabled
            style={{ borderRadius: 4, color: "#000", fontWeight: 500 }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {data.map(({ id, name, active }) => (
          <Col span={12} key={id}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
                backgroundColor: "#ffffff",
                borderRadius: 6,
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
              }}
            >
              <p style={{ fontSize: 14, fontWeight: 500, margin: 0, color: "#595959" }}>
                {name}{" "}
                <span style={{ fontWeight: "bold", color: active ? "#52c41a" : "#f5222d" }}>
                  {active ? t("activated") : t("deactivated")}
                </span>
              </p>
              <Switch
                checked={active}
                onChange={() => togglePermission(id, active)}
                checkedChildren={t("activated")}
                unCheckedChildren={t("deactivated")}
                style={{
                  backgroundColor: active ? "#52c41a" : "#f5222d"
                }}
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Permissions;
