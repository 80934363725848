import { notification } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { notifyErrors } from "../../../api/ErrorNotifier";
import { refreshOrg, useAwbType } from "../../../stateManagement/slices/userSlice";

import { useChangeAwbTypeMutation } from "../../../stateManagement/apis/treecodeApi";
import { AWB_TYPE } from "../../../utils/beDict";
import GenericForm from "../../form/GenericForm";

const AwbLabelType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const awbType = useAwbType();
  const [changeAwbType, { isLoading: changeAwbTypeLoading }] = useChangeAwbTypeMutation();

  const validationSchema = yup.object().shape({
    awb_type: yup.string().required(t("AWB Type is required")).oneOf(Object.keys(AWB_TYPE), t(`AWB Type Should be one of ${Object.values(AWB_TYPE).join(", ")}`))
  });

  const fields = [
    {
      name: "awb_type",
      label: t("AWB Type"),
      component: "Select",
      xs: 24,
      props: {
        // className: "w-100",
        options: Object.entries(AWB_TYPE).map(([key, value]) => ({ label: t(value), value: key }))
      }
    },
    {
      name: "submit",
      type: "button",
      label: t("Change AWB Type"),
      buttonType: "primary",
      htmlType: "submit",
      props: {
        loading: changeAwbTypeLoading,
        disabled: changeAwbTypeLoading
      }
    }
  ];

  const handleSubmit = async (values) => {
    console.log("Values From AwbType", values.awb_type);
    const result = await changeAwbType(values);
    if (result.data) {
      dispatch(refreshOrg(result.data));
      notification.success({
        message: t("Success"),
        description: t("AWB Type Updated Successfully")
      });
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors(t("somethingWentWrong"));
    }
  };

  return (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div
                className=""
                style={{
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                  maxWidth: "600px",
                  width: "100%"
                }}
            >
                <GenericForm fields={fields} submitFunction={handleSubmit} validationSchema={validationSchema} initialValues={{ awb_type: awbType }} />
            </div>
        </div>
  );
};

export default AwbLabelType;
