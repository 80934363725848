import React, { useState, useEffect } from "react";
import { Table, Button, Input, Dropdown, Menu, Space, notification } from "antd";
import { PlusOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  useGetProductCategoriesMutation,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation
} from "../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../api/ErrorNotifier";
import CategoryModal, { DeleteCategoryModal } from "./CategoryModal";

const { Search } = Input;

const AllCategories = () => {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    search: ""
  });
  const [categories, setCategories] = useState([]);
  const [count, setCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // Mutations
  const [getProductCategories, { isLoading }] = useGetProductCategoriesMutation();
  const [createProductCategory] = useCreateProductCategoryMutation();
  const [updateProductCategory] = useUpdateProductCategoryMutation();
  const [deleteProductCategory] = useDeleteProductCategoryMutation();

  const [currentCategory, setCurrentCategory] = useState({
    id: null,
    name: "",
    name_ar: ""
  });

  const [categoryToDelete, setCategoryToDelete] = useState(null);

  // Fetch Categories
  const getCategories = async () => {
    const response = await getProductCategories(queryParams);
    if (response.data) {
      setCategories(response.data.data);
      setCount(response.data.count);
    }
  };

  const handleSearch = (value) => {
    setQueryParams({ ...queryParams, search: value.trim(), page: 1 });
  };

  const handlePageChange = (page) => {
    setQueryParams({ ...queryParams, page });
  };

  useEffect(() => {
    getCategories();
  }, [queryParams]);

  // Modal Submit
  const onModalSubmit = async () => {
    setLoadingModal(true);
    let response;
    if (currentCategory.id) {
      response = await updateProductCategory({
        productCategoryId: currentCategory.id,
        ...currentCategory
      });
    } else {
      response = await createProductCategory(currentCategory);
    }
    if (response.data) {
      notification.success({ message: t("Category saved successfully") });
      getCategories();
      setModalOpen(false);
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors(t("server_error"));
    }
    setLoadingModal(false);
  };

  // Delete Category Handler
  const confirmDeleteCategory = async () => {
    if (!categoryToDelete) return;

    setLoadingDelete(true);
    try {
      const response = await deleteProductCategory(categoryToDelete.id);
      if (!response.error) {
        notification.success({ message: t("Category deleted successfully") });
        getCategories();
        setDeleteModalOpen(false);
      } else {
        notifyErrors(t("server_error"));
      }
    } catch (error) {
      notifyErrors(t("server_error"));
    }
    setLoadingDelete(false);
  };

  const columns = [
    {
      title: t("Product category"),
      dataIndex: "name",
      key: "name"
    },
    {
      title: t("Product category (Arabic)"),
      dataIndex: "name_ar",
      key: "name_ar"
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                onClick={() => {
                  setCurrentCategory(record);
                  setModalOpen(true);
                }}
              >
                {t("edit")}
              </Menu.Item>
              <Menu.Item
                key="delete"
                danger
                onClick={() => {
                  setCategoryToDelete(record);
                  setDeleteModalOpen(true);
                }}
              >
                {t("Delete")}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      )
    }
  ];

  return (
    <div className="productCategories mt-3 bg-white p-4 rounded">
      {/* Modals */}
      <CategoryModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onFinish={onModalSubmit}
        loading={loadingModal}
        category={currentCategory}
        setCategory={setCurrentCategory}
      />

      <DeleteCategoryModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDeleteCategory}
        loading={loadingDelete}
        category={categoryToDelete}
      />

      {/* Header */}
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16
        }}
      >
        <h2 className="pageTitle">{t("sideMenu.allProductCategories")}</h2>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setCurrentCategory({ id: null, name: "", name_ar: "" });
            setModalOpen(true);
          }}
        >
          {t("createProductCategory")}
        </Button>
      </Space>

      {/* Search */}
      <Search
        placeholder={t("Search by Category Name or Arabic Name")}
        onSearch={handleSearch}
        style={{ marginBottom: 16, maxWidth: 300 }}
        allowClear
      />

      {/* Table */}
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={categories}
        pagination={{
          current: queryParams.page,
          pageSize: queryParams.limit,
          total: count,
          onChange: handlePageChange
        }}
        rowKey="id"
        locale={{
          emptyText: (
            <div style={{ textAlign: "center" }}>
              <h6>{t("noDataFound")}</h6>
              <p>
                {t(
                  "There are no product categories yet, click on create product category to add a new product category"
                )}
              </p>
            </div>
          )
        }}
      />
    </div>
  );
};

export default AllCategories;
