import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Select } from "antd";
import "../../../style-sheets/table.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CreationConfirmation, ConfirmDelete } from "../../modals/confirmationModal";
import CourierInfoModel from "../../modals/CourierInfoModel";
import { getSelectedUser } from "../../../stateManagement/slices/selectedUserSlice";
import TablePro from "../../tables/TablePro";
import useLanguage from "../../../hooks/useLanguage";
// import { notifyErrors } from "../../../api/ErrorNotifier";
import CourierChangePassword from "./CourierChangePassword";

const UserTable = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserID, setSelectedUserID] = useState([]);
  const [show, setShow] = useState(false);
  const [showCourier, setShowCourierInfo] = useState(false);
  const [action] = useState("");
  const [active, setActive] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const navigate = useNavigate();
  const getUserDispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [doRedirect, setDoRedirect] = useState(false);
  const lang = useLanguage();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false); // New state for ConfirmDelete modal

  useEffect(() => {
    setActive(selectedRows.length === 1);
    console.log(active);
  }, [selectedRows]);

  async function fetch () {
    const result = await props.fetchMethod({
      page,
      search: keyword,
      ...filters
    });
    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  }

  useEffect(() => {
    fetch();
  }, [page, keyword, filters]);

  useEffect(() => {
    setSelectedUser(data.filter((it) => selectedRows.includes(it.id)));
    setSelectedUserID(selectedRows);
  }, [selectedRows]);

  // const showModel = () => {
  //   if (selectedUserID.length > 0) {
  //     setShow(true);
  //   }
  // };

  const hide = () => {
    setShow(false);
  };

  const deleteAccount = async () => {
    if (selectedUserID.length === 0) return;

    setShowConfirmDelete(true); // Show ConfirmDelete modal
  };

  const confirmDelete = async () => {
    console.log("Deleting users:", selectedUserID);

    if (selectedUserID.length === 0) return; // Prevent action if no user is selected

    for (const user of selectedUserID) {
      try {
        const result = await props.deleteMethod(user.id);

        if (result?.error?.data) {
          toast.error(`${t("دث خطأ ما أثناء حذف العمي")} ${user?.user?.name}: ${result.error.data}`);
        } else if (result?.error) {
          toast.error(`${t("error.generic")} ${user?.user?.name}`);
        } else {
          toast.success(`${t("تم حذف العميل")} ${user?.user?.name}`);
        }
      } catch (err) {
        toast.error(`${t("حدث خطأ غير متوقع عند حذف العميل ")} ${user?.user?.name}`);
      }
    }

    // Refresh data or close modal
    hide();
    fetch();
    setShowConfirmDelete(false);
  };

  // const showCourierInfo = (action) => {
  //   setActions(action);
  //   setShowCourierInfo(true);
  // };

  const hideInfo = () => {
    setShowCourierInfo(false);
  };

  const redirect = () => {
    console.log("Redirecting to user details page :", selectedUser[0]);
    getUserDispatch(getSelectedUser(selectedUser));
    navigate(`/user_details/${props.userType}/${selectedUser[0].id}`);
  };

  useEffect(() => {
    if (
      doRedirect &&
      selectedUserID.length === 1 &&
      selectedUser[0]?.id
    ) {
      redirect();
    }
  }, [doRedirect, selectedUserID, selectedUser]);

  return (
    <div>
      <Container>
      <ToastContainer position="top-right" autoClose={3000} />
        <Row className="mb-4">
          <Col className="text-end" sm="12">

          </Col>
        </Row>
        <div>
          <TablePro
            onDelete={deleteAccount}
            loading={props.loading}
            headerExtra={
              <div className="row-centered-horizontal gap-1">
                {Object.keys(props.filters ?? {}).map((key) => (
                  <Select
                    key={key}
                    onChange={(v) => {
                      setFilters({
                        ...filters,
                        [key]: v
                      });
                    }}
                    value={props.filters[key]?.selectedValue}
                    allowClear
                    style={{ minWidth: "150px" }}
                    placeholder={props.filters[key].title}
                  >
                    {props.filters[key].values.map((it) => (
                      <Select.Option key={it.id} value={it.id}>
                        {it.name}
                      </Select.Option>
                    ))}
                  </Select>
                ))}
              </div>
            }
            selection={"radio"}
            columns={props.columns}
            dataSource={data}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            pagination={{
              current: page,
              total: count,
              onChange: (page) => setPage(page)
            }}
            onRowClick={(record) => {
              setSelectedUser([record]);
              setSelectedUserID([record.id]);
              setDoRedirect(true);
            }}
            searchPlaceholder={props.searchText}
            onSearch={setKeyword}
          />
        </div>
      </Container>
      <CreationConfirmation
        sendRequest={deleteAccount}
        show={show}
        hide={hide}
        body={
          lang === "ar"
            ? "هل تريد تعطيل هذا الحساب"
            : "Are you sure that you want to Archive this user?"
        }
        title={
          lang === "ar" ? "أرشفة الحساب" : "Archive User Confirmation"
        }
      />
       {/* Use the ConfirmDelete modal instead of window.confirm */}
       <ConfirmDelete
        title={lang === "ar" ? "تأكيد الحذف" : "Delete Confirmation"}
        show={showConfirmDelete}
        hide={() => setShowConfirmDelete(false)}
        deleteEntity={confirmDelete}
        body={
          lang === "ar"
            ? `هل تريد حذف العملاء التاليين؟\n${selectedUserID.map(user => user?.user?.name).join(", ")}`
            : `Are you sure you want to delete the following customers?\n${selectedUserID.map(user => user?.user?.name).join(", ")}`
        }
        loading={false} // Add loading state if necessary
      />
      {selectedUser.length !== 0 && props.userType === "courier" && (
        <>
          <CourierInfoModel
            setShow={setShowCourierInfo}
            action={action}
            showCourier={showCourier}
            info={selectedUser}
            hideInfo={hideInfo}
            getData={fetch}
            onOpenChangePassword={() => {
              setShowCourierInfo(false);
              setOpenChangePassword(true);
            }}
          />
          <CourierChangePassword
            show={openChangePassword}
            courierId={selectedUser[0]?.id}
            onHide={() => {
              setOpenChangePassword(false);
              setSelectedUser([]);
            }}
          />
        </>
      )}

    </div>
  );
};

export default UserTable;
