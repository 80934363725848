import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";

import "../../../style-sheets/table.css"; // Custom styles
import { useWarehouseLocationColumns } from "../../layout/tableColumns"; // Table column configuration
import TablePro from "../../tables/TablePro"; // Reusable table component
import WareHousesRepository from "../../../api/WarehousesRepository"; // API repository

const WarehouseLocations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { warehouseId } = useParams(); // Get warehouse ID from route parameters

  // State management
  const [queryParams, setQueryParams] = useState({ page: 1, limit: 10, search: "" });
  const [locations, setLocations] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const [warehouse, setWarehouse] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Handle activation or deactivation of a warehouse location
  const handleActivate = async (id, active) => {
    setActivateLoading(true);
    setSelectedLocation(id);

    try {
      const response = active
        ? await WareHousesRepository.activateWarehouseLocation(warehouseId, id)
        : await WareHousesRepository.deactivateWarehouseLocation(warehouseId, id);

      if (response.success) {
        notification.success({ message: t("Location has been updated successfully") });
        getLocations(); // Refresh locations list
      } else {
        handleApiErrors(response);
      }
    } catch (error) {
      notification.error({ message: t("An unexpected error occurred") });
    } finally {
      setActivateLoading(false);
      setSelectedLocation(null);
    }
  };

  // Fetch warehouse locations
  const getLocations = async () => {
    setLoading(true);
    try {
      const response = await WareHousesRepository.warehouseLocations(warehouseId, queryParams);

      if (response.success) {
        setLocations(response.data);
        setCount(response.count);
      } else {
        handleApiErrors(response);
      }
    } catch (error) {
      notification.error({ message: t("An unexpected error occurred while fetching locations.") });
    } finally {
      setLoading(false);
    }
  };

  // Fetch warehouse details
  const getWarehouse = async () => {
    setLoading(true);
    try {
      const response = await WareHousesRepository.getWarehouse(warehouseId);

      if (response.success) {
        setWarehouse(response.data);
      } else {
        handleApiErrors(response);
      }
    } catch (error) {
      notification.error({ message: t("An unexpected error occurred while fetching warehouse details.") });
    } finally {
      setLoading(false);
    }
  };

  // Handle API errors
  const handleApiErrors = (response) => {
    if (response.status === 400) {
      Object.entries(response.error).forEach(([key, value]) =>
        notification.error({ message: Array.isArray(value) ? value[0] : value })
      );
    } else {
      notification.error({ message: response.error });
    }
  };

  // Update query parameters
  const handleChangeQueryParams = (param, value) => {
    setQueryParams((prev) => ({
      ...prev,
      [param]: value,
      page: param === "page" ? value : 1 // Reset to page 1 for filter changes
    }));
  };

  // Load warehouse details on mount
  useEffect(() => {
    getWarehouse();
  }, []);

  // Load locations whenever queryParams changes
  useEffect(() => {
    getLocations();
  }, [queryParams]);

  // Configure table columns
  const columns = useWarehouseLocationColumns(handleActivate, activateLoading, selectedLocation);

  return (
    <Container>
      {/* Page Header */}
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.warehouses")}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("sideMenu.warehouseLocations")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{`${warehouse.name} ${t("sideMenu.locations")}`}</h2>
        </Col>
      </Row>

      {/* Add Location Button */}
      <Row className="mb-4">
        <Col className="text-end" sm="12">
          <button
            onClick={() => navigate(`/warehouse/${warehouseId}/locations/create`)}
            className="calculateBtn"
          >
            {t("addLocation")}
          </button>
        </Col>
      </Row>

      {/* Locations Table */}
      <Row>
        <TablePro
          loading={loading}
          emptyPlaceholder={
            <div>
              <h6 className="noDataHead">{t("noDataFound")}</h6>
              <p>
                {t(
                  "There are no locations available, click on the add location button to add a new location"
                )}
              </p>
            </div>
          }
          onSearch={(value) => handleChangeQueryParams("search", value)}
          dataSource={locations}
          columns={columns}
          pagination={{
            current: queryParams.page,
            total: count,
            onChange: (page) => handleChangeQueryParams("page", page)
          }}
        />
      </Row>
    </Container>
  );
};

export default WarehouseLocations;
