import { Form, Input, Spin, Button, Row, Col, notification } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import PageHeader from "../../layout/PageHeader";
import { useGetMyWebhooksMutation, useUpdateMyWebhooksMutation } from "../../../stateManagement/apis/accountsApi";
import { notifyErrors } from "../../../api/ErrorNotifier";

const WebhooksPage = () => {
  const { t } = useTranslation();
  const [webhook, setWebhook] = useState(null);
  const [form] = Form.useForm();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [getWebhooksApi, { isLoading: fetching }] = useGetMyWebhooksMutation();
  const [updateWebhooksApi, { isLoading: loading }] = useUpdateMyWebhooksMutation();

  const getWebhooks = async () => {
    try {
      const response = await getWebhooksApi();
      if (response.data) {
        setWebhook(response.data);
        form.setFieldsValue(response.data);
      } else {
        throw new Error("Error fetching webhooks");
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const updateWebhooks = async (values) => {
    try {
      const response = await updateWebhooksApi(values);
      if (response.data) {
        setWebhook(response.data);
        notification.success({ message: t("Webhooks updated successfully") });
      } else if (response.error?.data) {
        notifyErrors(response.error.data);
      } else {
        throw new Error("Error updating webhooks");
      }
    } catch (err) {
      console.log(err);
      notifyErrors(err.message);
    }
    setIsFormDirty(false);
  };

  useEffect(() => {
    getWebhooks();
  }, []);

  return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ zIndex: 999999 }}
                />
            <div>
                <PageHeader title={t("My Webhooks")} group={t("Third party integrations")} />
            </div>
            <div className="d-flex flex-column align-items-start justify-content-start gap-3 w-100">
                {
                    (fetching || !webhook) &&
                    <div className="d-flex align-items-center justify-content-center w-100" style={{ minHeight: "50vh" }}>
                        <Spin size="large" />
                    </div>
                }
                {
                    webhook && webhook.actions.length > 0 &&
                    <Form
                        form={form}
                        layout="horizontal"
                        onFinish={updateWebhooks}
                        initialValues={webhook}
                        className="w-100"
                    >
                        <Form.Item
                            name="url"
                            label={t("URL")}
                            rules={[
                              {
                                required: true,
                                message: t("Please input your URL")
                              },
                              {
                                type: "url",
                                message: t("Please input a valid URL")
                              }
                            ]}
                        >
                            <Input onChange={(e) => form.setFieldValue("url", e.target.value)} />
                        </Form.Item>
                        <Form.List name="actions">
                            {(fields, { add, remove }) => (
                                <Row gutter={[16, 16]}>
                                {console.log("FFFFFF", fields)}
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Col span={24} lg={12} key={key}>
                                        <div key={key} className="d-flex flex-column align-items-start justify-content-start gap-2 w-100 h-100 p-3 border border-success rounded">
                                            <Form.Item
                                                {...restField}
                                                key={key}
                                                label={form.getFieldValue("actions")?.[name]?.webhook_action.name}
                                                name={[name, "enabled"]}
                                                rules={[{ required: true, message: t("Missing action") }]}

                                            >
                                                <Input type="checkbox"
                                                defaultChecked={form.getFieldValue("actions")?.[name]?.enabled}
                                                onChange={(e) => {
                                                  const oldActions = form.getFieldValue("actions");
                                                  oldActions[name].enabled = e.target.checked;
                                                  if (!e.target.checked) {
                                                    oldActions[name].headers = [];
                                                  }
                                                  form.setFieldValue("actions", oldActions);
                                                  setIsFormDirty(true);
                                                }} />
                                            </Form.Item>
                                            <Form.List name={[name, "headers"]}>
                                                {(headersFields, { add: addHeader, remove: removeHeader }) => (
                                                    <>
                                                        {headersFields.map(({ key: headerKey, name: headerName, ...restHeaderField }) => (
                                                            <div key={headerKey} className="d-flex flex-row align-items-end justify-content-start gap-3">
                                                                <Form.Item
                                                                    {...restHeaderField}
                                                                    key={headerKey}
                                                                    label={t("Key")}
                                                                    name={[headerName, "key"]}
                                                                    rules={[{ required: true, message: t("Missing key") }]}
                                                                >
                                                                    <Input onChange={(e) => {
                                                                      const oldActions = form.getFieldValue("actions");
                                                                      oldActions[name].headers[headerName].key = e.target.value;
                                                                      form.setFieldValue("actions", oldActions);
                                                                      setIsFormDirty(true);
                                                                    }} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restHeaderField}
                                                                    key={headerKey}
                                                                    label={t("Value")}
                                                                    name={[headerName, "value"]}
                                                                    rules={[{ required: true, message: t("Missing value") }]}
                                                                >
                                                                    <Input onChange={(e) => {
                                                                      const oldActions = form.getFieldValue("actions");
                                                                      oldActions[name].headers[headerName].value = e.target.value;
                                                                      form.setFieldValue("actions", oldActions);
                                                                      setIsFormDirty(true);
                                                                    }} />
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Button onClick={() => {
                                                                      removeHeader(headerKey);
                                                                      setIsFormDirty(true);
                                                                    }}>
                                                                        <i className="fas fa-trash text-danger"></i>
                                                                    </Button>
                                                                </Form.Item>
                                                            </div>
                                                        ))}
                                                        <Form.Item>
                                                            {console.log("Field" + name, form.getFieldsValue())}
                                                            <Button block onClick={() => addHeader()} disabled={headersFields.length >= 10 || !form.getFieldValue("actions")?.[name]?.enabled}>
                                                                <i className="fas fa-plus"></i> {t("Add header")}
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </div>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </Form.List>
                        <Form.Item
                            className="mt-3"
                        >
                            <div className="d-flex flex-row align-items-center justify-content-end gap-3 w-100">
                                <Button type="primary" htmlType="submit" loading={loading} disabled={loading || !isFormDirty} className="ms-auto"
                                style={{ backgroundColor: "#5cb85c", borderColor: "#5cb85c" }}
                                >
                                    {t("Update")}
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                }
            </div>
        </div>
  );
};

export default WebhooksPage;
