import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  order: null
};

const archivedOrderSlice = createSlice({
  name: "archivedOrder",
  initialState,
  reducers: {
    setArchivedOrder: (state, action) => {
      state.order = action.payload;
    }
  }
});

export const { setArchivedOrder } = archivedOrderSlice.actions;
export const useArchivedOrder = () => useSelector((state) => state.archivedOrder.order);

export default archivedOrderSlice.reducer;
