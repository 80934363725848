import React, { useEffect, useState } from "react";
import { Row, Col, Upload, Button, Input, Typography, message } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SuccessfulRequest } from "../../../modals/confirmationModal";
import { refreshOrg, useUserObject } from "../../../../stateManagement/slices/userSlice";
import { useGetOrganizationMutation, useUpdateOrganizationByFormMutation } from "../../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../../api/ErrorNotifier";

const { Dragger } = Upload;
const { Title, Text } = Typography;

function SystemTheme () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const org = useUserObject().org;

  const [showSuccess, setShowSuccess] = useState(false);
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");
  const [file, setFile] = useState(null); // Use null instead of empty string
  const [getOrganization] = useGetOrganizationMutation();
  const [updateOrganization, { isLoading: loading }] = useUpdateOrganizationByFormMutation();

  const hideSuccessModal = () => setShowSuccess(false);

  const editTheme = async () => {
    try {
      // Step 1: Update colors as JSON data
      const colorData = { primary_color: primary, secondary_color: secondary };
      const colorResult = await updateOrganization(colorData);

      if (!colorResult.data) {
        return notifyErrors(colorResult.error?.data || t("updateError"));
      }

      // Step 2: If a file is provided, upload the logo separately
      if (file) {
        const formData = new FormData();
        formData.append("logo", file);

        const logoResult = await updateOrganization(formData);
        if (!logoResult.data) {
          return notifyErrors(logoResult.error?.data || t("updateError"));
        }
      }

      // Refresh theme and show success if both requests succeeded
      await refreshTheme();
      setShowSuccess(true);
      message.success(t("systemThemeUpdateSuccess"));
    } catch (error) {
      notifyErrors(error.message || t("updateError"));
    }
  };

  const refreshTheme = async () => {
    const result = await getOrganization();
    if (result.data) {
      const newOrg = result.data.data[0];
      dispatch(
        refreshOrg({
          id: newOrg.id,
          logo: `/media/${newOrg.logo.split("/media/")[1]}`,
          primary_color: newOrg.primary_color,
          secondary_color: newOrg.secondary_color
        })
      );
    }
  };

  useEffect(() => {
    setPrimary(org.primary_color);
    setSecondary(org.secondary_color);
  }, [org]);

  return (
    <div style={{ padding: "24px", background: "#fff", borderRadius: 12, boxShadow: "0 4px 12px rgba(0,0,0,0.1)" }}>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Text style={{ fontSize: 16, fontWeight: 500, color: "#888" }}>
            {t("sideMenu.settings")} &gt; {t("preferences")} &gt; {t("systemTheme")}
          </Text>
        </Col>
        <Col span={24}>
          <Title level={3} style={{ margin: 0, color: "#333" }}>{t("systemTheme")}</Title>
        </Col>
        <Col span={24}>
          <Text style={{ fontSize: 14, fontWeight: 500, color: "#333" }}>{t("uploadLogo")}</Text>
          <Dragger
            name="file"
            multiple={false}
            accept="image/*"
            style={{ borderRadius: 8, marginTop: 12, padding: 20, borderColor: "#d9d9d9" }}
            onChange={(info) => {
              const { status } = info.file;
              if (status === "done") setFile(info.file.originFileObj);
              if (status === "error") message.error(`${info.file.name} ${t("uploadFailed")}`);
            }}
          >
            <p style={{ fontSize: 14, color: "#555", margin: 0 }}>{t("uploadLogoText")}</p>
          </Dragger>
        </Col>
        <Col span={24} className="mt-4">
          <Text style={{ fontSize: 14, fontWeight: 500, color: "#333", marginBottom: 8 }}>{t("selectColors")}</Text>
        </Col>
        <Col span={8}>
          <Text style={{ fontSize: 14, color: "#555" }}>{t("primaryColor")}</Text>
          <Input
            type="color"
            value={primary}
            onChange={(e) => setPrimary(e.target.value)}
            style={{ width: "100%", borderRadius: 8, height: 40, marginTop: 8 }}
          />
        </Col>
        <Col span={8}>
          <Text style={{ fontSize: 14, color: "#555" }}>{t("secondaryColor")}</Text>
          <Input
            type="color"
            value={secondary}
            onChange={(e) => setSecondary(e.target.value)}
            style={{ width: "100%", borderRadius: 8, height: 40, marginTop: 8 }}
          />
        </Col>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
          <Button
            type="primary"
            loading={loading}
            onClick={editTheme}
            style={{
              borderRadius: 8,
              padding: "0 32px",
              fontSize: 16,
              backgroundColor: "#1890ff",
              borderColor: "#1890ff"
            }}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
      <SuccessfulRequest
        hide={hideSuccessModal}
        show={showSuccess}
        body={t("successfulRequest")}
        title={t("systemTheme")}
      />
    </div>
  );
}

export default SystemTheme;
