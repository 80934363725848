import React, { useEffect } from "react";
import { Card, Slider, Button, Typography, Row, Col, Space, Spin } from "antd";
import Container from "react-bootstrap/Container";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { usePlan } from "../../../../stateManagement/slices/planSlice";
import { useGetMySubscriptionsMutation } from "../../../../stateManagement/apis/subscriptionApi";
import useLanguage from "../../../../hooks/useLanguage";

const { Title, Text } = Typography;

const NoSubscriptionDashboard = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { t } = useTranslation();
  const lang = useLanguage();
  const navigate = useNavigate();
  const plan = usePlan();
  const [getMySubscriptions, { isLoading }] = useGetMySubscriptionsMutation();
  const contactSupport = () => {
    document
      .getElementsByClassName("b24-widget-button-inner-block")[0]
      .click();
  };
  const handleRenew = () => {
    // Logic to renew the current plan (e.g., navigate to renew page)
    navigate("/renew");
  };

  const fetchSubscriptions = async () => {
    await getMySubscriptions();
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const handleNewPlans = () => {
    // Logic to view new subscription plans (e.g., navigate to new plans page)
    navigate("/plans");
  };

  return (
    <div>
    <Container>
    <Row
      justify="center"
      align="top"
      style={{ minHeight: "100vh", paddingTop: "20px" }}
      gutter={[32, 32]}
       // [x, y
    >
      <Col lg={16} style={{ marginBottom: 20 }}>
      {
          isLoading
            ? (
            <Row justify="center" align="middle" style={{ minHeight: "500px" }}>
              <Col>
                <div className="spinnerAnt">
                  <Spin indicator={antIcon} />
                </div>
              </Col>
            </Row>
              )
            : (

        <Card
          style={{
            textAlign: "center",
            padding: "40px 20px",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
          }}
        >
          <Title level={2} style={{ marginBottom: 20 }}>
            Your Subscription Has Ended
          </Title>
          <Text type="secondary" style={{ fontSize: 16 }}>
            Your current subscription has ended. Please renew your subscription or explore new plans to continue enjoying our services.
          </Text>

          <Space
            direction="vertical"
            size="large"
            style={{ marginTop: 40, width: "100%" }}
          >
            <Button
              type="primary"
              size="large"
              block
              onClick={handleRenew}
              style={{ backgroundColor: "#1677ff", borderColor: "#1677ff" }}
            >
              Renew Current Plan
            </Button>
            <Button
              type="default"
              size="large"
              block
              onClick={handleNewPlans}
            >
              Explore New Plans
            </Button>
          </Space>
        </Card>
              )
      }
      </Col>
      <Col lg={8}>
            <Row className="statusCard p-3 mb-4">
                <p className="plan-header">{t("packageUsage")}</p>
                <p className="plan-name">
                    {lang === "ar" ? plan?.name_ar : plan?.name} -{" "} {t("plan")}
                </p>
                <Row className="orders-row">
                    <Col className="orders-count" sm="12">
                        <p>{t("orderPackage")}</p>
                        <span>
                            {plan?.orders_count} {t("outOf")}{" "}
                            {plan?.remaining_orders}
                        </span>
                    </Col>
                </Row>
                <Slider
                    value={plan?.orders_count}
                    disabled
                    max={plan?.remaining_orders}
                />
                <Link style={{ padding: "0px" }} to="/payments">
                    <p style={{ marginBottom: "0px" }}>
                        {t("payments")}
                    </p>
                </Link>
            </Row>
        <Row
            className="statusCard p-3 my-4"
            style={{ cursor: "unset" }}
        >
            <Row>
                <h3>
                    <strong>{t("needHelp")}</strong>
                </h3>
            </Row>

            <Row className="mt-4">
                <Col lg="1" className="me-2">
                    <i className="fas fa-comment-dots fs-4 side-icon"></i>
                </Col>

                <Col
                    onClick={contactSupport}
                    style={{ cursor: "pointer" }}
                >
                    <strong>{t("contactSupport1")}</strong>
                    <div className="desc-sm my-2">
                        {t("contactSupport2")}
                    </div>
                </Col>
            </Row>

            <Row className="my-2">
                <hr />
            </Row>
            <Row>
                <Col lg="1" className="me-2">
                    <i className="fas fa-question-circle fs-4 side-icon"></i>
                </Col>

                <Col>
                    <a
                        style={{ color: "black" }}
                        target="_blank"
                        href="https://lace-booth-6cf.notion.site/427d045aa04a4b28ae4aad271700c2bb"
                        rel="noreferrer"
                    >
                        <strong>{t("helpCenter1")}</strong>
                    </a>
                    <div className="desc-sm my-2">
                        {t("helpCenter2")}
                    </div>
                </Col>
            </Row>
        </Row>
    </Col>
    </Row>
    </Container>
    </div>
  );
};

export default NoSubscriptionDashboard;
