import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addRunsheetOrders } from "../../../../stateManagement/slices/rsOrdersSlice";
import { useAuthedUser } from "../../../../stateManagement/slices/userSlice";
import { useBranch } from "../../../../stateManagement/slices/branchSlice";
import { useGetSellerOrdersMutation } from "../../../../stateManagement/apis/ordersApi";

function SellersOrders (props) {
  const dispatchOrders = useDispatch();
  const { t } = useTranslation();
  const sellerID = useAuthedUser.user_id;

  const navigate = useNavigate();
  const branch = useBranch();
  const cur = useAuthedUser().organization.country.currency;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("1");
  const [selectionModel, setSelectionModel] = useState([]);
  const [changed, setChanged] = useState(false);
  const [orders, setOrders] = useState([]);
  const [getSellerOrders] = useGetSellerOrdersMutation();
  const [columns] = useState([
    {
      field: "id",
      headerName: `${t("orderID")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin tableID">#{row.row?.id}</p>
      )
    },
    {
      field: "sfee",
      headerName: `${t("shipmentFee")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin credit">{row.row?.delivery_fees}</p>
      )
    },
    // {
    //   field: "rto",
    //   headerName: `${t("returnedFee")}`,
    //   width: 220,
    //   // valueGetter: (row) => row.row?.id,
    //   renderCell: (row) => (
    //     <p className="paragraphMargin debit">EGP {row.row?.return_fees}</p>
    //   )
    // },
    {
      field: "value",
      headerName: `${t("orderValue")}`,
      width: 200,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin credit">{cur} {row.row?.sub_total}</p>
      )
    },
    {
      field: "method",
      headerName: `${t("paymentMethod")}`,
      width: 240,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.payment_method}</p>
      )
    }
  ]);
  const prevSelectionModel = useRef(selectionModel);
  const prevOrders = useRef(orders);

  const chooseType = (e) => {
    setType(e);
    getSellersOrders(e);
  };

  const getSellersOrders = async (type = "1") => {
    const result = await getSellerOrders(sellerID, { type });

    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  const pay = async () => {
    if (type === "2") {
      dispatchOrders(addRunsheetOrders(orders));
      console.log("clicked", type);
    } else {
      dispatchOrders(addRunsheetOrders(orders));
      console.log("type", type);
    }
    navigate(`/seller_settelment/${type}`);
  };
  useEffect(() => {
    setSelectionModel([...selectionModel, ...prevSelectionModel.current]);
    console.log("selection model ", selectionModel);
  }, [page, data]);

  useEffect(() => {
    getSellersOrders();
  }, []);
  return (
    <>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.wallet")}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("totalBalance")}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("sellerWallet")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="column-display" sm="12" md="12">
          <Select className="type" value={type} onChange={chooseType}>
            <Select.Option selected value="2">
            {t("debit")}
            </Select.Option>
            <Select.Option value="1">{t("credit")}</Select.Option>
          </Select>
          <button
            disabled={selectionModel.length === 0}
            onClick={pay}
            className="pay"
          >
            {type === "2" ? `${t("payTo")}` : `${t("recieveFrom")}`}
          </button>
        </Col>
      </Row>
      <div className="reqTableContainer">
        <div className="tableHeader">
          <h6>{branch} {t("orderDetails")}</h6>
        </div>

        <div className="requestTable">
          <DataGrid
            rows={data}
            columns={columns}
            rowCount={count}
            pagination
            selectionModel={selectionModel}
            checkboxSelection
            onPageChange={async (page) => {
              setPage(page);
              const result = await getSellerOrders(props.match.params.id, {
                type,
                page: page + 1
              });
              if (result.data) {
                setData(result.data.data);
                setCount(result.data.count);
                prevSelectionModel.current = selectionModel;
                prevOrders.current = orders;
              }
            }}
            onSelectionModelChange={(ids) => {
              setChanged(!changed);
              setSelectionModel(ids);

              // console.log("ids ", ids)
              const selectedIDs = new Set(ids);
              // // console.log([...selectedIDs])
              const selectedRowData = data.filter((row) =>
                selectedIDs.has(row.id)
              );
              setOrders(selectedRowData);
              // console.log(selectionModel)
              console.log(selectedRowData);
              // if(selectedRowData.length !== 0){
              //     console.log("here")

              //     setSelectedOrders(selectedRowData)
              // }
            }}
          />
        </div>
      </div>
    </>
  );
}

export default SellersOrders;
