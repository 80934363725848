import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Badge, notification, Card, Typography, Space } from "antd";
import { ToastContainer } from "react-toastify";
import PageHeader from "../../layout/PageHeader";
import WMButton from "../AllWorkModels/WMButton";
import { SuccessfulRequest } from "../../modals/confirmationModal";
import useLanguage from "../../../hooks/useLanguage";
import { ThirdPartyKeys } from "../ThirdPartyInfo";
import {
  useGetSellerMutation,
  useUpdateSellerMutation,
  useCreateSeller3rdPartyMutation
} from "../../../stateManagement/apis/accountsApi";
import { errorsHandler, notifyErrors } from "../../../api/ErrorNotifier";
import SellerEditPersonalInfo from "./seller-edit-personal-info";
import SellerEditAddresses from "./seller-edit-adresses";
import SellerEditPricing from "./seller-edit-pricing";

const { Title } = Typography;

function SellerAccountInfo () {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [seller, setSeller] = useState({});
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFetched, setIsFetched] = useState(false);
  const [getSellerApi] = useGetSellerMutation();
  const [updateSellerApi, { isLoading: loading }] = useUpdateSellerMutation();
  const [createSeller3rdPartyApi, { isLoading: loadingActivate }] =
    useCreateSeller3rdPartyMutation();
  const id = useParams().id;
  const lang = useLanguage();

  useEffect(() => {
    const fetchSeller = async () => {
      const res = await getSellerApi(id);
      if (res.data) {
        setSeller(res.data);
        setIsFetched(true);
      }
    };
    fetchSeller();
  }, [id, getSellerApi]);

  const updateSeller = async () => {
    try {
      console.log(seller);
      const result = await updateSellerApi({ sellerId: id, ...seller });
      if (result.data) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/all_accounts", { state: { tabIndex: 1 } });
        }, 1000);
      } else if (result.error?.data) {
        setErrors(errorsHandler(result.error.data));
        notifyErrors(result.error.data);
      } else {
        throw new Error("An error occurred while updating the seller");
      }
    } catch (e) {
      notifyErrors("An error occurred while updating the seller");
    }
  };

  const activateThirdParty = async () => {
    try {
      const res = await createSeller3rdPartyApi(id);
      if (res.data) {
        setSeller(res.data);
        notification.success({ message: t("Third party activated successfully") });
      } else if (res.error?.data) {
        notifyErrors(res.error.data);
      } else {
        throw new Error("An error occurred while activating the third party");
      }
    } catch (e) {
      notifyErrors(e.message);
    }
  };

  return (
    <Card
      className="seller-account-info-card"
      style={{ borderRadius: 8 }}
      bodyStyle={{ padding: 24 }}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
      />
      <Space
        direction="vertical"
        size="large"
        style={{ width: "100%" }}
      >
        <div className="header-section">
          <PageHeader title={t("personalInfo")} group={t("sideMenu.accounts")} />
          {!seller?.third_party
            ? (
            <Button
              type="primary"
              danger
              onClick={activateThirdParty}
              loading={loadingActivate}
              disabled={loadingActivate}
            >
              {t("Activate Third Party")}
            </Button>
              )
            : (
            <Badge.Ribbon text={t("Active")} color="#57BE6C">
              <div className="third-party-status">
                {t("Third Party Activated")}
              </div>
            </Badge.Ribbon>
              )}
        </div>

        {Object.keys(errors).length > 0 && (
          <Typography.Text type="danger">
            {lang.includes("en")
              ? "Please solve the errors below"
              : "من فضلك قم بحل الاخطاء التالية"}
          </Typography.Text>
        )}

        <SellerEditPersonalInfo
          isFetched={isFetched}
          seller={seller}
          setSeller={setSeller}
          errors={errors}
          setErrors={setErrors}
        />

        {seller?.third_party && (
          <div className="third-party-keys-section">
            <Title level={4}>{t("Third Party Keys")}</Title>
            <ThirdPartyKeys
              keys={seller.third_party}
              setKeys={({ api_key, hmac_secret }) =>
                setSeller({
                  ...seller,
                  third_party: {
                    ...seller.third_party,
                    api_key: api_key ?? seller.third_party.api_key,
                    hmac_secret: hmac_secret ?? seller.third_party.hmac_secret
                  }
                })
              }
              thirdPartyId={seller.third_party?.id}
            />
          </div>
        )}

        <SellerEditAddresses
          isFetched={isFetched}
          seller={seller}
          setSeller={setSeller}
          errors={errors}
          setErrors={setErrors}
        />
        <SellerEditPricing
          isFetched={isFetched}
          seller={seller}
          setSeller={setSeller}
          errors={errors}
          setErrors={setErrors}
        />

        <div className="save-button-section" style={{ textAlign: "right" }}>
          <WMButton onClick={updateSeller} loading={loading}>
            {t("save")}
          </WMButton>
        </div>

        <SuccessfulRequest
          show={success}
          hide={() => setSuccess(false)}
          title={lang === "ar" ? "تحديث معلومات المستخدم" : "Update User Info"}
          body={
            lang === "ar"
              ? "تم تحديث المعلومات بنجاح"
              : "This user has been updated successfully"
          }
        />
      </Space>
    </Card>
  );
}

export default SellerAccountInfo;
