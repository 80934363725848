import React, { useMemo } from "react";
import { Progress, Typography, Space } from "antd";
import { useTranslation } from "react-i18next";
import TicketScanRepository from "../data";

const { Text } = Typography;

export default function ScanProgress ({ runSheet, ticketsStates }) {
  const { t } = useTranslation();

  const scanned = useMemo(
    () => Object.values(ticketsStates).filter(
      (it) => it.state === TicketScanRepository.scanStates.scanned
    ).length,
    [ticketsStates]
  );

  const percent = useMemo(
    () => (scanned / runSheet.data.details.count) * 100,
    [runSheet.data.details.count, scanned]
  );

  return (
    <div
      style={{
        padding: "12px",
        background: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.08)",
        maxWidth: "320px",
        margin: "0 auto"
      }}
    >
      <Space
        direction="horizontal"
        size="small"
        style={{
          width: "100%",
          textAlign: "center",
          alignItems: "center"
        }}
      >
        <Text style={{ fontSize: "14px", color: "#595959" }}>
          {t("ticketsScanned")}
        </Text>
        {runSheet.data.details.count && (
          <Text style={{ fontSize: "16px", fontWeight: "600", color: "#1890ff" }}>
            {scanned} / {runSheet.data.details.count}
          </Text>
        )}

        <Progress
          percent={percent}
          showInfo={false}
          strokeColor={{
            "0%": "#1890ff",
            "100%": "#52c41a"
          }}
          trailColor="#e0e0e0"
          strokeWidth={10}
          style={{ width: "100%" }}
        />
      </Space>
    </div>
  );
}
