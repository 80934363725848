import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../../../style-sheets/form.css";

import { useUserObject } from "../../../../stateManagement/slices/userSlice";
import AccountTypeForm from "./accoutTypeForm";
import AccountPersonalInfoForm from "./personal-info/accountPersonalInfoForm";

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const CreateAccount = () => {
  const { t } = useTranslation();
  const user = useUserObject();
  const [accountData] = useState({});

  const [tab, setTab] = useState(0);

  const accountTypes = {
    seller: {
      index: -1,
      title: t("seller")
    },
    thirdParty: {
      index: 0,
      title: t("thirdParty")
    },
    operation: {
      index: 2,
      title: t("operation")
    },
    courier: {
      index: 3,
      title: t("courier")
    },
    accountant: {
      index: 4,
      title: t("accountant")
    },
    inventoryManager: {
      index: 5,
      title: t("inventoryManager")
    },
    teamMember: {
      index: 6,
      title: t("operationTeamMember")
    }
  };

  const handleChange = (event, newTab) => {
    setTab(newTab ?? 0);
  };

  return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.accounts")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.createNewAcc")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.createNewAcc")}</h2>
                </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tab}
                        aria-label="basic tabs example"
                        className={`${
                            user.role_id === "Seller" ? "seller" : "admin"
                        }`}
                    >
                        <Tab label={t("accType")} {...a11yProps(0)} />
                        <Tab label={t("personalInfo")} {...a11yProps(1)} />
                        <Tab label={t("sendInvitation")} {...a11yProps(2)} />

                        {/* <Tab label="Send Request" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>

                <TabPanel value={tab} index={0}>
                    <AccountTypeForm
                        accountTypes={accountTypes}
                        value={tab}
                        account={accountData}
                        handleChange={handleChange}
                    />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <AccountPersonalInfoForm
                        accountTypes={accountTypes}
                        value={tab}
                        account={accountData}
                        handleChange={handleChange}
                    />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <AccountPersonalInfoForm
                        accountTypes={accountTypes}
                        value={tab}
                        account={accountData}
                        handleChange={handleChange}
                    />
                </TabPanel>

            </Box>
        </div>
  );
};

export default CreateAccount;
