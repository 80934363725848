import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  selectedSeller: "",
  selectedClient: ""
};

const selectedSellerSlice = createSlice({
  name: "selectedData",
  initialState,

  reducers: {
    getSeller: (state, action) => {
      state.selectedSeller = action.payload;
    },
    getClient: (state, action) => {
      state.selectedClient = action.payload;
    }
  }
});

export const { getSeller, getClient } = selectedSellerSlice.actions;
export const useSelectedSeller = () => useSelector((state) => state.selectedSeller.selectedSeller);
export const useSelectedClient = () => useSelector((state) => state.selectedSeller.selectedClient);

export default selectedSellerSlice.reducer;
