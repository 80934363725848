import React from "react";
import { Button as AntButton, Spin } from "antd";
import styles from "./WMButton.module.css";

export default function WMButton ({
  className,
  type = "default",
  icon,
  iconPosition = "start",
  loading = false,
  children,
  ...rest
}) {
  const buttonClasses = [
    styles.button,
    iconPosition === "end" ? styles.iconEnd : styles.iconStart,
    className
  ].filter(Boolean).join(" ");

  return (
    <AntButton
      type={type}
      className={buttonClasses}
      icon={!loading ? icon : null}
      disabled={loading}
      {...rest}
      style={{
        width: "100%",
        padding: "12px 24px",
        borderRadius: "8px",
        border: "none",
        fontWeight: "bold",
        backgroundColor: "#57be6c",
        color: "#fff",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: iconPosition === "end" ? "space-between" : "center",
        transition: "transform 0.2s, background-color 0.3s",
        cursor: "pointer"
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "translateY(-3px)";
        e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.1)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      {loading ? <Spin className={styles.spinner} /> : children}
    </AntButton>
  );
}
