import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { persistStore } from "redux-persist";
import { Spin } from "antd";
import "./index.css";
import { Provider } from "react-redux";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { PersistGate } from "redux-persist/es/integration/react";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import i18n from "./resourses/i18n";
import "./resourses/Localization";
import "./i18next";
import store from "./stateManagement/store";

import App from "./App";

const domain = import.meta.env.VITE_APP_BASE_URL;

const root = createRoot(document.getElementById("root"));

const persistor = persistStore(store);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_APP_VERSION,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    new RegExp(`^https://(?:[a-zA-Z0-9-]+\\.)*${domain}/`)

  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

i18n.on("initialized", (options) => {
  console.log({ options: options.lang });
  root.render(
        <React.StrictMode>
            <Suspense fallback={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}><Spin size="large" /></div>}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ErrorBoundary>
                            <App />
                        </ErrorBoundary>
                    </PersistGate>
                </Provider>
            </Suspense>
            </React.StrictMode>,
        document.getElementById("root")
  );
});

reportWebVitals();
