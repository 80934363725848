import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  isValid: false
};

const isValidSlice = createSlice({
  name: "isValid",
  initialState,
  reducers: {
    isValid: (state, action) => {
      state.isValid = action.payload;
    }
  }
});

export const { isValid } = isValidSlice.actions;
export const useIsValid = () => useSelector((state) => state.isValid.isValid);

export default isValidSlice.reducer;
