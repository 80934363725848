import React from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import WMButton from "../../AllWorkModels/WMButton";
import ReportsRepository from "../../../../api/ReportsRepository";
import TablePro from "../../../tables/TablePro";
import styles from "./CommonReportsPage.module.css";

export default function ReportsTable ({ table }) {
  console.log({ table });

  async function onDownloadTable () {
    const { success } = await ReportsRepository.getTable(
      table.extra.ticket,
      table.extra.orders,
      table.extra.params,
      true
    );

    if (success) {
      toast.success("File Downloaded Successfully");
    }
  }

  const { t, i18n } = useTranslation();

  return (
        <div className={styles.reportsContainer}>
            <div
                className="row-centered-horizontal"
                style={{ justifyContent: "flex-end" }}
            >
                <WMButton onClick={onDownloadTable}>
                    {t("reports.download")}
                </WMButton>
            </div>
            <div className={styles.reportContainer}>
                <TablePro

                    loading={false}
                    columns={
                        Object.keys(table.data[0])
                          .map((it) => {
                            if (
                              i18n.exists(`reports.table.regular.${it}`)
                            ) {
                              return {
                                title: t(`reports.table.regular.${it}`),
                                key: it,
                                dataIndex: it
                              };
                            }

                            return undefined;
                          })
                          .filter((it) => it !== undefined) ?? []
                    }
                    dataSource={table.data}
                />
            </div>
        </div>
  );
}
