import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  userType: ""
};

const userTypeSlice = createSlice({
  name: "userType",
  initialState,
  reducers: {
    userType: (state, action) => {
      state.userType = action.payload;
    }
  }
});

export const { userType } = userTypeSlice.actions;
export const useUserType = () => useSelector((state) => state.userType.userType);
export default userTypeSlice.reducer;
