import store from "../stateManagement/store";
import { inventoryApi } from "../stateManagement/apis/inventoryApi";
import formattedError from "./errorFormatter";

const WareHousesRepository = {
  getWarehouses: async ({ page = 1, limit = 10, ...qp }) => {
    try {
      const res = await store.dispatch(inventoryApi.endpoints.getWarehouses.initiate({
        page,
        page_size: limit,
        ...qp
      })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || res.data.status,
          count: res.data.count
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  getAllLocations: async () => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getAllLocations.initiate());
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  getWarehouse: async (id) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getWarehouse.initiate(id)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  createWarehouse: async (data) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.createWarehouse.initiate(data)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  updateWarehouse: async (data, id) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.updateWarehouse.initiate({ id, ...data })
      );

      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  deleteWarehouse: async (id) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.deleteWarehouse.initiate(id)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  warehouseLocations: async (id, { page = 1, limit = 10, ...qp } = {}) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getWarehouseLocations.initiate(id, {
          page,
          page_size: limit,
          ...qp
        })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },
  warehouseLocation: async (id, locationId) => {
    console.log("data to pass: ", id, locationId);
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getWarehouseLocation.initiate({ id, locationId })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },
  createWarehouseLocation: async (id, data) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.createWarehouseLocation.initiate({ id, body: data })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },
  updateWarehouseLocation: async (id, locationId, data) => {
    if (!locationId || !data) {
      return { success: false, error: "Invalid arguments passed to the mutation" };
    }

    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.updateWarehouseLocation.initiate({
          id,
          locationId,
          ...data // Ensure all data is properly spread
        })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },
  activateWarehouseLocation: async (id, locationId) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.activateWarehouseLocation.initiate({ id, locationId })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  deactivateWarehouseLocation: async (id, locationId) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.deactivateWarehouseLocation.initiate({ id, locationId })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  deleteWarehouseLocation: async (id, locationId) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.deleteWarehouseLocation.initiate(id, locationId)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  warehouseInventoryRules: async (id, { page = 1, limit = 10, ...qp }) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getWarehouseInventoryRules.initiate(id, {
          page,
          page_size: limit,
          ...qp
        })
      );

      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  warehouseInventoryRule: async (id, ruleId) => {
    console.log("Parameters received:", { id, ruleId }); // Add this log
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getWarehouseInventoryRule.initiate({ id, ruleId }) // Pass as an object
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  createWarehouseInventoryRule: async (id, data) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.createWarehouseInventoryRule.initiate({ id, body: data })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },
  updateWarehouseInventoryRule: async (id, ruleId, data) => {
    console.log("Calling API with:", { id, ruleId, ...data });
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.updateWarehouseInventoryRule.initiate({
          id,
          ruleId,
          ...data
        })
      );

      return res.data
        ? { success: true, data: res.data, status: res.status || 200 }
        : formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  deleteWarehouseInventoryRule: async (id, ruleId) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.deleteWarehouseInventoryRule.initiate(id, ruleId)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  warehouseInventories: async (id, { page = 1, limit = 10, ...qp }) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getWarehouseInventories.initiate(id, {
          page,
          page_size: limit,
          ...qp
        })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  warehouseInventoriesSummary: async (id) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getWarehouseInventoriesSummary.initiate(id)
      );
      if (res.data) {
        return {
          success: true,
          data: res.data,
          status: res.status || 200
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  },
  warehouseLocationInventories: async (
    id,
    locationId,
    { page = 1, limit = 10, ...qp }
  ) => {
    try {
      const res = await store.dispatch(
        inventoryApi.endpoints.getWarehouseLocationInventories.initiate(id, locationId, {
          page,
          page_size: limit,
          ...qp
        })
      );
      if (res.data) {
        return {
          success: true,
          data: res.data.data,
          status: res.status || 200,
          count: res.data.count
        };
      } else return formattedError(res, true);
    } catch (e) {
      return formattedError(e);
    }
  }
};

export default WareHousesRepository;
