import { createApi } from "@reduxjs/toolkit/query/react";
import { REPORTS_API } from "../constants";
import { reAuthBaseQuery } from "./baseQuery";

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["Reports"],
  endpoints: (builder) => ({
    generateBranchReport: builder.mutation({
      query: (queryParameters) => ({
        url: REPORTS_API.BRANCH_REPORT,
        method: "GET",
        params: queryParameters,
        responseHandler: queryParameters.type === "excel" ? async (res) => window.location.assign(window.URL.createObjectURL(await res.blob())) : (res) => res.json()
      }),
      providesTags: ["Reports"]
    }),
    generateCourierReport: builder.mutation({
      query: (queryParameters) => ({
        url: REPORTS_API.COURIER_REPORT,
        method: "GET",
        params: queryParameters,
        responseHandler: queryParameters.type === "excel" ? async (res) => window.location.assign(window.URL.createObjectURL(await res.blob())) : (res) => res.json()
      }),
      providesTags: ["Reports"]
    }),
    generateFinancialReport: builder.mutation({
      query: (queryParameters) => ({
        url: REPORTS_API.FINANCIAL_REPORT,
        method: "GET",
        params: queryParameters,
        responseHandler: queryParameters.type === "excel" ? async (res) => window.location.assign(window.URL.createObjectURL(await res.blob())) : (res) => res.json()
      }),
      providesTags: ["Reports"]
    }),
    generateSellerReport: builder.mutation({
      query: (queryParameters) => ({
        url: REPORTS_API.SELLER_REPORT,
        method: "GET",
        params: queryParameters,
        responseHandler: queryParameters.type === "excel" ? async (res) => window.location.assign(window.URL.createObjectURL(await res.blob())) : (res) => res.json()
      }),
      providesTags: ["Reports"]
    }),
    getReports: builder.mutation({
      query: (queryParameters) => ({
        url: REPORTS_API.ROOT + "/",
        method: "GET",
        params: queryParameters,
        responseHandler: queryParameters.type === "excel" ? async (res) => window.location.assign(window.URL.createObjectURL(await res.blob())) : (res) => res.json()
      }),
      providesTags: ["Reports"]
    }),
    orderDetailsReport: builder.mutation({
      query: (queryParameters) => ({
        url: REPORTS_API.ORDER_DETAILS,
        method: "GET",
        params: queryParameters,
        responseHandler: queryParameters.type === "excel" ? async (res) => window.location.assign(window.URL.createObjectURL(await res.blob())) : (res) => res.json()
      }),
      providesTags: ["Reports"]
    })

  })
});

export const {
  useGenerateBranchReportMutation,
  useGenerateCourierReportMutation,
  useGenerateFinancialReportMutation,
  useGenerateSellerReportMutation,
  useGetReportsMutation,
  useOrderDetailsReportMutation
} = reportsApi;
