import { useTranslation } from "react-i18next";
import { Input, Select } from "antd";
import PhoneInput from "react-phone-input-2";
import { AWB_TYPE } from "../../../utils/beDict";

import "react-phone-input-2/lib/style.css";

export default function SellerEditPersonalInfo ({
  isFetched,
  seller,
  setSeller,
  errors,
  setErrors
}) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  // Form fields configuration
  const fields = [
    { name: "name", label: t("name"), dataIndex: ["user", "name"], required: true },
    { name: "store_name", label: t("storename"), dataIndex: ["store_name"], required: true },
    { name: "email", label: t("email"), dataIndex: ["user", "email"], required: true },
    { name: "phone", label: t("phone"), dataIndex: ["user", "phone"], required: true, isPhoneInput: true },
    { name: "phone_alt", label: t("altPhone"), dataIndex: ["user", "phone_alt"], isPhoneInput: true },
    {
      name: "awb_type",
      label: t("AWB Type"),
      dataIndex: ["awb_type"],
      isSelect: true,
      options: Object.entries(AWB_TYPE).map(([key, value]) => ({ label: t(value), value: key })),
      placeholder: t("Select AWB Type")
    },
    { name: "otp_delivery", label: t("OTP Delivery"), dataIndex: ["otp_delivery"], type: "checkbox" }
  ];

  // Handle input change
  const onChange = (dataIndex, value, name) => {
    if (errors[name]) {
      const errorsCopy = { ...errors };
      delete errorsCopy[name];
      setErrors(errorsCopy);
    }
    const updatedSeller = { ...seller };
    dataIndex.reduce((obj, key, idx) => {
      if (idx === dataIndex.length - 1) {
        obj[key] = value;
        return obj;
      }
      return obj?.[key];
    }, updatedSeller);
    setSeller(updatedSeller);
  };

  // Styles
  const inputStyle = (hasError, type) => ({
    width: type === "checkbox" ? "18px" : "100%",
    height: type === "checkbox" ? "18px" : "50px",
    padding: type === "select" ? "0px" : "10px 12px",
    borderRadius: "10px",
    borderColor: hasError ? "#ff4d4f" : "#d9d9d9",
    transition: "border-color 0.3s ease"
  });

  const errorStyle = {
    color: "#ff4d4f",
    marginTop: "4px",
    fontSize: "12px"
  };

  return (
    <div>
      <style>
        {`
          .ant-select-selector {
            border-radius: 10px;
          }
        `}
      </style>
      <h4 style={{ marginBottom: "16px" }}>{t("personalInfo")}</h4>
      <div style={{ display: "grid", gap: "16px", gridTemplateColumns: "repeat(2, 1fr)" }}>
        {fields.map((field, index) => {
          const value = field.dataIndex.reduce((obj, key) => obj?.[key], seller);
          const hasError = Boolean(errors[field.name]);

          return (
            <div key={index}>
              <label style={{ display: "block", marginBottom: "4px", fontWeight: "bold" }}>
                {field.label}
              </label>
              {field.isPhoneInput
                ? (
                <PhoneInput
                  value={value}
                  onChange={(val) => onChange(field.dataIndex, val, field.name)}
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    padding: "10px 12px",
                    direction: lang === "ar" ? "rtl" : "ltr",
                    borderColor: hasError ? "#ff4d4f" : "#d9d9d9",
                    borderRadius: "10px"
                  }}
                  containerClass="phone-input-container"
                  inputClass="phone-input"
                  buttonStyle={{
                    backgroundColor: "transparent",
                    border: "none",
                    position: "absolute",
                    right: lang === "ar" ? "initial" : "25px",
                    left: lang === "ar" ? "25px" : "initial"
                  }}
                  buttonClass="country-dropdown"
                />
                  )
                : field.isSelect
                  ? (
                <Select
                  options={field.options}
                  onChange={(value) => onChange(field.dataIndex, value, field.name)}
                  style={inputStyle(hasError, "select")}
                  value={value}
                  required={field.required}
                  placeholder={field.placeholder}
                />
                    )
                  : (
                <Input
                  type={field.type || "text"}
                  required={field.required}
                  value={value}
                  onChange={(e) => onChange(field.dataIndex, field.type === "checkbox" ? e.target.checked : e.target.value, field.name)}
                  style={inputStyle(hasError, field.type || "text")}
                />
                    )}
              {hasError && <p style={errorStyle}>{errors[field.name][0]}</p>}
              {isFetched && field.required && !value && !hasError && (
                <p style={errorStyle}>{t("requiredField")}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
