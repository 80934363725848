import { Route } from "react-router-dom";
import React from "react";
import { NoPermission } from "../../components/NotFound";

// This function checks if the user has permission to access a route
export const permissionGranted = (routePermissions, user, expiredSubscription) => {
  console.log("USER PERMISSIONS", user.permissions, routePermissions);
  console.log("EXPIRED SUBSCRIPTION", expiredSubscription);
  console.log("USER ROLE", user);

  // Grant access to Organization Admin or users with role_id "1"
  if (user.role === "Organization Admin" || user.role_id === "1") {
    return true;
  }

  // If no specific permissions are required, grant access
  if (!routePermissions) {
    return true;
  }

  // Check if the user has any of the required permissions
  return (
    Array.isArray(user.user.permissions) &&
    user.user.permissions.some((permission) => routePermissions.includes(permission[1]))
  );
};

// This function generates routes based on permissions and user role
export default function generateRoutes (routesGroup, user = null) {
  console.log("ROUTES GROUP", routesGroup);

  return Object.keys(routesGroup).map((path) => {
    const routeElement = routesGroup[path].element;
    const routeRules = routesGroup[path].rules;

    console.log("Checking route:", path, "with rules:", routeRules, "for user:", user);
    if (!routeElement) {
      console.warn(`Route element for path "${path}" is undefined. Ensure it's correctly exported.`);
    }

    return (
      <Route
        key={path}
        path={path}
        element={
          routeElement && user && permissionGranted(routeRules, user)
            ? React.createElement(routeElement)
            : !user
                ? React.createElement(routeElement)
                : <NoPermission />
        }
      />
    );
  });
}
