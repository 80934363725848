import React, { useEffect, useState } from "react";
import { useFilterAreasMutation, useGetAreaMutation } from "../../stateManagement/apis/treecodeApi";
import SelectSearch from "./SelectSearch";

export default function SelectAreas (props) {
  const { currentArea, initialOptions = [], ...rest } = props; // Destructure currentArea
  const [options, setOptions] = useState([...initialOptions]);
  const [page, setPage] = useState(1);
  const [isAllFetched, setAllFetched] = useState(false);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const [filterAreas] = useFilterAreasMutation();
  const [getAreaApi] = useGetAreaMutation();

  const onScroll = async (event) => {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (!isAllFetched) getAreas();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setPage(1);

      const current =
        currentArea && !alreadyFetched
          ? await getCurrentArea(currentArea)
          : undefined;

      await getAreas(current);
      setLoading(false);
    };

    fetchData();
  }, [search, currentArea]); // Use currentArea here

  const getAreas = async (current) => {
    const result = await filterAreas({
      search,
      page_size: 1000,
      page
    });

    if (result.data) {
      if (current) {
        setOptions((prev) => [
          ...new Set([
            ...prev.filter((it) => it.id === current.id),
            current
          ])
        ]);
      }
      setOptions((prev) => [...new Set([...prev, ...result.data.data])]);
      setPage(page + 1);
      setAllFetched(result.data.next);
    }
  };

  const getCurrentArea = async (id) => {
    const res = await getAreaApi(id);
    setAlreadyFetched(true);
    return res.data;
  };

  return (
    <SelectSearch
      onPopupScroll={onScroll}
      name={"area"}
      loading={loading}
      options={options}
      onSearch={(v) => {
        setPage(1);
        setSearch(v);
        setOptions([]);
      }}
      {...rest} // Spread other props except currentArea
    />
  );
}
