import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../../style-sheets/form.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom"; // Import useParams
import { SuccessfulRequest } from "../modals/confirmationModal";
import { useFilterAreasMutation, useGetBranchesMutation } from "../../stateManagement/apis/treecodeApi";
import { useFilterSellersMutation, useUpdateOrgEmployeeMutation, useBulkUpdateSellerAddressMutation, useCreateSellerAddressMutation } from "../../stateManagement/apis/accountsApi";
import { notifyErrors } from "../../api/ErrorNotifier";

import useLanguage from "../../hooks/useLanguage";
import { getSelectedUser, useSelectedUser } from "./../../stateManagement/slices/selectedUserSlice";
import "react-phone-input-2/lib/style.css"; // Import the default styles

const AccountInfo = (props) => {
  const { type } = useParams(); // Destructure type and id from useParams

  const selectedUserState = useSelectedUser();
  const { t } = useTranslation();
  const [name, setName] = useState(selectedUserState[0]?.user.name);
  const [phone, setPhone] = useState(selectedUserState[0]?.user.phone);
  const [phone2, setPhone2] = useState(selectedUserState[0]?.user.phone_alt);
  const [email, setEmail] = useState(selectedUserState[0]?.user.email);
  const [storeName, setStore] = useState(selectedUserState[0]?.store_name);
  const [assignToTerminal, setAssignToTerminal] = useState(
    selectedUserState[0]?.assign_to_terminal
  );
  const [allow_whatsapp, setallow_whatsapp] = useState(
    selectedUserState[0]?.allow_whatsapp
  );
  const [branches, setBranches] = useState([]);
  const [areas, setAreas] = useState({ old: {}, new: [] });
  const [, SetNext] = useState("");
  const [, setSelectedBranchesID] = useState([]);
  const [newAddresses, setNewAddresses] = useState([]);
  const [oldAddresses, setOldAddresses] = useState([]);
  const [filterAreas] = useFilterAreasMutation();
  const [filterSellers] = useFilterSellersMutation();
  const [updateOrgEmployee] = useUpdateOrgEmployeeMutation();
  const [updateSellerAddress] = useBulkUpdateSellerAddressMutation();
  const [createSellerAddress] = useCreateSellerAddressMutation();
  const [getBranchesApi] = useGetBranchesMutation();

  const [, setDeletedAddresses] = useState([]);
  const [, setSelectedBranches] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const hideModel = () => {
    setShow(false);
  };

  const getArea = async (i, type, city = null) => {
    const id =
            type === "new"
              ? newAddresses[i]?.new_work_model
              : oldAddresses[i]?.new_work_model;
    console.log({
      b: branches.find((b) => b.id === id)?.city.map((it) => it.id),
      city
    });

    const areasData = await filterAreas();
    if (areasData) {
      const copy = { ...areas[type] };
      copy[i] = areasData.data.data;
      console.log(areas, copy, "from_area");
      setAreas({ ...areas, [type]: copy });
      if (city) return areasData.data.data;
    }
  };

  const updateUser = async () => {
    const result = await filterSellers({ id: selectedUserState[0]?.id });
    if (result.data) { dispatch(getSelectedUser(result.data.data)); }
  };
  const edit = async (e) => {
    e.preventDefault();
    if (["manager", "courier"].includes(type)) {
      const data = {
        name,
        phone,
        phone_alt: phone2,
        email
      };
      if (type === "courier") {
        data.assign_to_terminal = assignToTerminal;
        data.allow_whatsapp = allow_whatsapp;
      }
      console.log(data);
      const result = await updateOrgEmployee({ orgEmployeeId: selectedUserState[0]?.id, ...data });
      if (result.data) {
        console.log(result);
        setShow(true);
      }
    } else if (type === "seller") {
      let edited = true;
      if (oldAddresses.length > 0) {
        edited = await updateSellerAddress(oldAddresses);
        if (edited.error?.data) {
          notifyErrors(edited.error.data);
        } else if (edited.error) {
          notifyErrors(t("server_error"));
        }
      }
      if (edited && newAddresses.length > 0) {
        const result = await createSellerAddress(newAddresses.map((a) => {
          a.seller = selectedUserState[0]?.id;
          return a;
        }));
        if (result?.data) {
          setNewAddresses([]);
          updateUser();
          setShow(true);
        } else if (result.error?.data) {
          notifyErrors(result.error.data);
        } else {
          notifyErrors(t("server_error"));
        }
      } else if (edit) setShow(true);
    }
  };
  const getBranches = async () => {
    const result = await getBranchesApi();
    if (result.data) {
      setBranches(result.data.data);
      SetNext(result.data.next);
    }
  };

  const handleSelectChange = (e, i, field, addresses, setAddresses) => {
    const addressesCopy = [...addresses];
    addressesCopy[i][field] = e.target.value;
    setAddresses(addressesCopy);
  };
  useEffect(() => {
    if (type === "seller") {
      getBranches();
      const copy = [];
      const branchesIDs = [];
      selectedUserState[0]?.saved_address.forEach((address, i) => {
        copy[i] = address.branch.name;
        setSelectedBranches(copy);
        console.log(copy);

        branchesIDs[i] = address.branch.id;
        setSelectedBranchesID(branchesIDs);
      });
    }
  }, [selectedUserState]);
  const addNewAddress = () => {
    setNewAddresses([
      ...newAddresses,
      { branch: "", area: "", new_work_model: "", address: "" }
    ]);
  };
  const lang = useLanguage();

  return (
        <div>
            <Container>
                <Row className="mb-2">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.accounts")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("sideMenu.allAccounts")}
                        </span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("personalInfo")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {t("sideMenu.allAccounts")}
                        </h2>
                    </Col>
                </Row>
                <Row className="">
                    <Col sm="12">
                        <h4 className="pageTitle">
                            {t("personalInfo")} #{ selectedUserState[0]?.id}
                        </h4>
                    </Col>
                </Row>
                <Form>
                    {" "}
                    <Row>
                        <Col className="mb-3" sm={12} md="6">
                            <Form.Label>{t("fullname")}</Form.Label>
                            <Form.Control
                                placeholder={t("fullname")}
                                name="fullName"
                                value={name}
                                // disabled
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Col>
                        {type === "seller"
                          ? (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("email")}</Form.Label>

                                <Form.Control
                                    placeholder={t("email")}
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>
                            )
                          : (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>
                                    {t("sideMenu.branches")}
                                </Form.Label>
                                <Form.Control
                                    placeholder={t("sideMenu.branches")}
                                    name="branch"
                                    value={ selectedUserState[0]?.branch?.name}
                                />
                            </Col>
                            )}
                    </Row>
                    <Row>
                        <Col className="mb-3" sm={12} md="6">
                            <Form.Label>{t("phone")}</Form.Label>
                                <PhoneInput
                                country={"sa"} // default country
                                onlyCountries={["sa"]} // restrict to Saudi Arabia
                                disableDropdown={true} // disable the country dropdown
                                value={phone}
                                onChange={(phone, countryData) => {
                                  setPhone(phone); // Update the phone number state
                                  console.log(countryData); // Handle or store country and prefix data
                                }}
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                  autoFocus: true

                                }}
                                inputStyle={{
                                  width: "100%",
                                  height: "50px",
                                  direction: lang === "ar" ? "rtl" : "ltr", // Adjust text direction based on language
                                  padding: "10px 12px"
                                }}
                                containerClass="phone-input-container "
                                inputClass="phone-input"
                                buttonStyle={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  position: "absolute", // Position button absolutely within container
                                  right: lang === "ar" ? "initial" : "0px", // Align to the right for LTR
                                  left: lang === "ar" ? "0px" : "initial" // Align to the left for RTL
                                }}
                                buttonClass="country-dropdown "
                                />

                        </Col>
                        {type !== "seller"
                          ? (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("email")}</Form.Label>

                                <Form.Control
                                    placeholder={t("email")}
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>
                            )
                          : (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("storename")}</Form.Label>

                                <Form.Control
                                    placeholder={t("storename")}
                                    name="storeName"
                                    value={storeName}
                                    onChange={(e) => setStore(e.target.value)}
                                />
                            </Col>
                            )}
                    </Row>
                    <Row>
                        <Col className="mb-3" sm="12" md="6">
                            <Form.Label>{t("altPhone")}</Form.Label>
                                <PhoneInput
                                country={"sa"} // default country
                                onlyCountries={["sa"]} // restrict to Saudi Arabia
                                disableDropdown={true} // disable the country dropdown
                                value={phone2}
                                onChange={(phone2, countryData) => {
                                  setPhone2(phone2); // Update the phone number state
                                  console.log(countryData); // Handle or store country and prefix data
                                }}
                                inputProps={{
                                  name: "Phone2",
                                  required: true,
                                  autoFocus: true

                                }}
                                inputStyle={{
                                  width: "100%",
                                  height: "50px",
                                  direction: lang === "ar" ? "rtl" : "ltr", // Adjust text direction based on language
                                  padding: "10px 12px"
                                }}
                                containerClass="phone-input-container "
                                inputClass="phone-input"
                                buttonStyle={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  position: "absolute", // Position button absolutely within container
                                  right: lang === "ar" ? "initial" : "0px", // Align to the right for LTR
                                  left: lang === "ar" ? "0px" : "initial" // Align to the left for RTL
                                }}
                                buttonClass="country-dropdown "
                                />
                        </Col>
                        {type === "courier" && (
                          <Row>
                            <Col
                                className="mb-3 d-flex flex-column justify-content-end"
                                sm="12"
                                md="6"
                            >
                                <Form.Check
                                    type="checkbox"
                                    id="assign-to-terminal"
                                    label={t("Assign to terminal")}
                                    checked={assignToTerminal}
                                    onChange={(e) =>
                                      setAssignToTerminal(e.target.checked)
                                    }
                                />
                            </Col>
                            <Col
                            className="mb-3 d-flex flex-column justify-content-end"
                            sm="12"
                            md="6"
                        >
                            <Form.Check
                                type="checkbox"
                                id="allow_whatsapp"
                                label={t("Allow WhatsApp")}
                                checked={allow_whatsapp}
                                onChange={(e) =>
                                  setallow_whatsapp(e.target.checked)
                                }
                            />
                        </Col>
                      </Row>
                        )}
                    </Row>
                    {type === "seller" &&
                    oldAddresses.length > 0
                      ? oldAddresses.map((address, i) => {
                        return (
                                  <div
                                      key={address.id}
                                      style={{ position: "relative" }}
                                      className="addressCard mt-2"
                                  >
                                      <div
                                          role="button"
                                          onClick={() => {
                                            setDeletedAddresses((prev) => [
                                              ...prev,
                                              address.id
                                            ]);

                                            const copy = [...oldAddresses];
                                            copy.splice(i, 1);
                                            setOldAddresses(copy);
                                          }}
                                          style={{
                                            position: "absolute",
                                            top: "0.75rem",
                                            left: "0.75rem",
                                            color: "#57BE6C",
                                            fontSize: "1.2em"
                                          }}
                                      >
                                          <i className="fas fa-times-circle"></i>
                                      </div>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("branch")}{" "}
                                              </Form.Label>
                                              <Form.Select
                                                  placeholder="Select Branch"
                                                  onChange={(e) =>
                                                    handleSelectChange(
                                                      e,
                                                      i,
                                                      "branch",
                                                      oldAddresses,
                                                      setOldAddresses
                                                    )
                                                  }
                                                  value={address.branch}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {" "}
                                                      --Select Branch--{" "}
                                                  </option>
                                                  {branches.map((branch) => {
                                                    return (
                                                          <option
                                                              key={branch.id}
                                                              value={branch.id}
                                                          >
                                                              {branch.name}
                                                          </option>
                                                    );
                                                  })}
                                              </Form.Select>
                                          </Col>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("area")}
                                              </Form.Label>
                                              <Form.Select
                                                  disabled={!address.branch}
                                                  onChange={(e) =>
                                                    handleSelectChange(
                                                      e,
                                                      i,
                                                      "area",
                                                      oldAddresses,
                                                      setOldAddresses
                                                    )
                                                  }
                                                  onClick={() =>
                                                    getArea(i, "old")
                                                  }
                                                  value={address.area}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {" "}
                                                      --Select Area--{" "}
                                                  </option>
                                                  {areas.old[i]?.map((area) => {
                                                    return (
                                                          <option
                                                              key={area.id}
                                                              value={area.id}
                                                          >
                                                              {area.name}
                                                          </option>
                                                    );
                                                  })}
                                              </Form.Select>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("address")}
                                              </Form.Label>
                                              <Form.Control
                                                  placeholder="Address Description"
                                                  name="Address Description"
                                                  value={address.address}
                                                  onChange={(e) => {
                                                    const copy = [
                                                      ...oldAddresses
                                                    ];
                                                    copy[i].address =
                                                          e.target.value;
                                                    setOldAddresses(copy);
                                                  }}
                                              />
                                          </Col>
                                      </Row>
                                  </div>
                        );
                      })
                      : null}
                    {type === "seller"
                      ? newAddresses.map((address, i) => {
                        return (
                                  <div
                                      key={address.id}
                                      style={{ position: "relative" }}
                                      className="addressCard mt-2"
                                  >
                                      <div
                                          role="button"
                                          onClick={() => {
                                            const copy = [...newAddresses];
                                            copy.splice(i, 1);
                                            setNewAddresses(copy);
                                          }}
                                          style={{
                                            position: "absolute",
                                            top: "0.75rem",
                                            left: "0.75rem",
                                            color: "#57BE6C",
                                            fontSize: "1.2em"
                                          }}
                                      >
                                          <i className="fas fa-times-circle"></i>
                                      </div>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("branch")}{" "}
                                              </Form.Label>
                                              <Form.Select
                                                  placeholder="Select Branch"
                                                  onChange={(e) =>
                                                    handleSelectChange(
                                                      e,
                                                      i,
                                                      "branch",
                                                      newAddresses,
                                                      setNewAddresses
                                                    )
                                                  }
                                                  value={address.branch}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {`${t("select")} ${t(
                                                          "branch"
                                                      )}`}
                                                  </option>
                                                  {branches.map((branch) => {
                                                    return (
                                                          <option
                                                              key={branch.id}
                                                              value={branch.id}
                                                          >
                                                              {branch.name}
                                                          </option>
                                                    );
                                                  })}
                                              </Form.Select>
                                          </Col>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("area")}
                                              </Form.Label>
                                              <Form.Select
                                                  disabled={!address.branch}
                                                  onChange={(e) =>
                                                    handleSelectChange(
                                                      e,
                                                      i,
                                                      "area",
                                                      newAddresses,
                                                      setNewAddresses
                                                    )
                                                  }
                                                  onClick={() =>
                                                    getArea(i, "new")
                                                  }
                                                  value={address.area}
                                                  aria-label="Default select example"
                                              >
                                                  <option
                                                      key="blankChoice"
                                                      hidden
                                                      value
                                                  >
                                                      {`${t("select")} ${t(
                                                          "area"
                                                      )}`}
                                                  </option>
                                                  {areas.new[i]?.map((area) => {
                                                    return (
                                                          <option
                                                              key={area.id}
                                                              value={area.id}
                                                          >
                                                              {area.name}
                                                          </option>
                                                    );
                                                  })}
                                              </Form.Select>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <Form.Label>
                                                  {t("address")}
                                              </Form.Label>
                                              <Form.Control
                                                  placeholder={t("address")}
                                                  name="Address Description"
                                                  value={address.address}
                                                  onChange={(e) => {
                                                    const copy = [
                                                      ...newAddresses
                                                    ];
                                                    copy[i].address =
                                                          e.target.value;
                                                    setNewAddresses(copy);
                                                  }}
                                              />
                                          </Col>
                                      </Row>
                                  </div>
                        );
                      })
                      : null}
                    {type === "seller"
                      ? (
                        <div
                            className="confirm mt-3 text-center"
                            role="button"
                            onClick={addNewAddress}
                        >
                            <i className="fas fa-plus-circle"></i>{" "}
                            {t("addAddress")}
                        </div>
                        )
                      : null}
                    <button onClick={edit} className="confirm mt-3">
                        {t("saveChanges")}
                    </button>
                </Form>
            </Container>
            <SuccessfulRequest
                show={show}
                hide={hideModel}
                title={
                    lang === "ar" ? "تحديث معلومات المستخدم" : "Update User Info"
                }
                body={
                    lang === "ar"
                      ? "تم تحديث المعلومات بنجاح"
                      : "This user has been updated successfully"
                }
            />
        </div>
  );
};

export default AccountInfo;
