export default function scannerListener (onScan) {
  let ticketCode = [];
  const listener = (e) => {
    if (e.target.localName !== "input") {
      if (e.code === "Enter") {
        if (ticketCode.length > 0) onScan(ticketCode.join(""));
        ticketCode = [];
      } else {
        ticketCode.push(e.key);
      }
    } else {
      if (ticketCode.length > 0) {
        ticketCode = [];
      }
    }
  };

  window.addEventListener("keyup", listener);

  return () => window.removeEventListener("keyup", listener);
}
