import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/form.css";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { PaginatedSelect, Radio } from "../form/TextInputFields";
import ModifiedForm from "../form/ModifiedForm";
import { addOrder, useOrder } from "../../stateManagement/slices/addOrderSlice";
import {
  getSeller,
  getClient
} from "../../stateManagement/slices/selectedSellerSlice";
import { getTicketPrintedData } from "../../stateManagement/slices/ticketPrintedDataSlice";
import {
  OrderConfirmationModal,
  OrderDeleteModal
} from "../modals/confirmationModal";
import { useUserObject } from "../../stateManagement/slices/userSlice";

import useLanguage from "../../hooks/useLanguage";
import { useCreateOrderMutation, useUpdateOrderMutation, useDeleteOrderMutation } from "../../stateManagement/apis/ordersApi";
import { useGetBranchesMutation } from "../../stateManagement/apis/treecodeApi";
import { useGetSellerMutation } from "../../stateManagement/apis/accountsApi";
import { notifyErrors } from "../../api/ErrorNotifier";
import { BaseUrl } from "../../stateManagement/constants";

export default function OrderInfoForm ({ value, handleChange }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const dispatchSeller = useDispatch();
  const dispatchClient = useDispatch();
  const clearDispatch = useDispatch();
  const dispatchPrinted = useDispatch();
  const [deliveryRangesErr, setDeliveryRangesErr] = useState("");
  const [branchErr, setBranchErr] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [calDeliveryFees, setCalDeliveryFees] = useState(deliveryFees);
  const [showCalculations] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branchesPage, setBranchesPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [sellerInfo, setSellerInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);
  const [printedTickets, setPrintedTickets] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [branchSearch, setBranchSearch] = useState("");
  const [paymentType, setPaymentType] = useState();
  const cur = useUserObject().user.organization.country.currency;
  const [createOrder, { isLoading: createOrderLoading }] = useCreateOrderMutation();
  const [updateOrder, { isLoading: updateOrderLoading }] = useUpdateOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [getBranchesApi] = useGetBranchesMutation();
  const [getSellerApi] = useGetSellerMutation();
  const columns = [
    {
      field: "id",
      headerName: "Ticket ID",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="tableID">#{row.row?.id}</p>
    },
    {
      field: "userbranch",
      headerName: "Seller Name",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.seller?.user.name}
                </p>
      )
    },
    {
      field: "storename",
      headerName: "Store Name",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.seller?.store_name}
                </p>
      )
    },
    {
      field: "client",
      headerName: "Client Name",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.client?.name}
                </p>
      )
    },
    {
      field: "deliveryfees",
      headerName: "Delivery Fees",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
                <p className="paragraphMargin">{row.row?.delivery_fees}</p>
      )
    }
  ];

  const columnsAr = [
    {
      field: "id",
      headerName: "رقم البوليصة",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="tableID">#{row.row?.id}</p>
    },
    {
      field: "userbranch",
      headerName: "اسم التاجر",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.seller?.user.name}
                </p>
      )
    },
    {
      field: "storename",
      headerName: "اسم المتجر",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.seller?.store_name}
                </p>
      )
    },
    {
      field: "client",
      headerName: "اسم العميل",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
                <p className="paragraphMargin">
                    {row.row?.order?.client?.name}
                </p>
      )
    },
    {
      field: "deliveryfees",
      headerName: "مصاريف الشحن",
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
                <p className="paragraphMargin">{row.row?.delivery_fees}</p>
      )
    }
  ];
  const navigate = useNavigate();

  const user = useUserObject();
  const showConfirmationModal = () => {
    setShow(true);
    clearDispatch(addOrder(""));
  };
  const showDeleteModal = () => {
    setShowDelete(true);
  };
  const hide = () => {
    setShow(false);
    navigate("/dashboard");
  };

  const hideDelete = () => {
    setShowDelete(false);
    setTimeout(() => {
      handleChange(null, 0);
    }, 1000);
  };
  const order = useOrder();
  // const sellerID = useSelector(
  //   (state) => state.selectedData.selectedSeller.id
  // );

  const validate = async (data) => {
    if (!data.branch && user.role !== "Seller") {
      console.log("brnach needed");
      setBranchErr(t("branchErr"));
    } else {
      setBranchErr("");
      setDeliveryRangesErr("");
      data.delivery_fees = deliveryFees;
      dispatch(addOrder(data));
      setSend(true);
      console.log(order);
    }
  };
  const discard = async () => {
    if (orderDetails !== "") {
      showDeleteModal();
      await deleteOrder(orderDetails.id);
      clearDispatch(addOrder(""));
      dispatchSeller(getSeller({}));
      dispatchClient(getClient({}));
    } else {
      dispatchSeller(getSeller({}));
      dispatchClient(getClient({}));
      clearDispatch(addOrder(""));
      handleChange(null, 0);
    }
  };

  useEffect(() => {
    if (send) {
      console.log(order);

      try {
        sendRequest();
      } catch (err) {
        console.log(err);
      }
    }
  }, [send]);

  const updateRequest = async () => {
    const result = await updateOrder({
      orderId: orderDetails.id,
      deliveryFees: calDeliveryFees
    });

    if (result.data) {
      dispatchPrinted(getTicketPrintedData(result.data));
      setShow(true);
      clearDispatch(addOrder(""));
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors("Error updating order");
    }
  };

  const sendRequest = async () => {
    console.log(order);
    const result = await createOrder({
      ...order,
      includingFees: order.including_fees === "true"
    });

    if (result.data) {
      setOrderDetails(result.data);
      console.log(result.data.tickets);
      console.log(result.data);
      setTickets(result.data.tickets);
      setDeliveryFees(parseFloat(result.data.delivery_fees));
      setCalDeliveryFees(parseFloat(result.data.delivery_fees));
      // setShowCalculations(true);
      dispatchSeller(getSeller({}));
      dispatchClient(getClient({}));
      dispatchPrinted(getTicketPrintedData(result.data));
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors("Error creating order");
    }
  };

  const getBranches = async (clear = false) => {
    const result = await getBranchesApi({ page: branchesPage, search: branchSearch });
    if (result) {
      if (clear) {
        setBranches(result.data.data);
      } else {
        setBranches([...branches, ...result.data.data]);
      }
      if (result.data.next === null) {
        setLoadMore(false);
      } else {
        setBranchesPage(branchesPage + 1);
      }
    }
  };

  useEffect(() => {
    if (createOrderLoading || updateOrderLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createOrderLoading, updateOrderLoading]);

  const onScroll = async (event) => {
    console.log("ee");
    const target = event.target;
    if (
      loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      await getBranches();
    }
  };

  useEffect(() => {
    getBranches(true);
  }, [branchSearch]);

  const previous = () => {
    handleChange(null, parseInt(value) - 1);
  };

  const getSellerInfo = async () => {
    if (user.role === "Seller") {
      const result = await getSellerApi(user.user.user_id);
      if (result.data) {
        console.log(result.data);
        setSellerInfo(result.data);
      } else if (result.error?.data) {
        notifyErrors(result.error.data);
      } else {
        notifyErrors("Error getting seller info");
      }
    }
  };

  useEffect(() => {
    getSellerInfo();
  }, []);

  useEffect(() => {
    console.log(
      deliveryFees,
      calDeliveryFees,
      deliveryFees === calDeliveryFees
    );
  }, [deliveryFees, calDeliveryFees]);
  const lang = useLanguage();

  return (
        <>
            <ModifiedForm
                submitFunction={validate}
                listenToValues={(getValues) => {
                  const v = getValues("payment_method");
                  console.log({ v });
                }}
            >
                <Row>
                    <Col sm="12">
                        {orderDetails !== ""
                          ? (
                            <p className="orderID">
                                {t("orderID")}#{orderDetails.id}
                            </p>
                            )
                          : null}
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="6">
                        <div className="position-relative text-area-form">
                            <div className="addressCard">
                                <label>{t("shippmentType")}</label>
                                <Radio
                                    value="1"
                                    id="type"
                                    validation={{ required: true }}
                                    name="shippmentType"
                                    className="radio-btn"
                                    txt={t("complete")}
                                />

                                <Radio
                                    value="2"
                                    id="type"
                                    validation={{ required: true }}
                                    name="shippmentType"
                                    className="radio-btn"
                                    txt={t("partial")}
                                />
                            </div>
                            {/* <PaginatedSelect
                                id="delivery_range"
                                title={t("deliveryRange")}
                                search={search}
                                validation={{ required: true }}
                                onScroll={(e) => onScrollRange(e, nextRanges)}
                                onClick={getRanges}
                                className="mt-4"
                            >
                                {ranges.map((range) => (
                                    <Select.Option
                                        key={range.id}
                                        value={range.id}
                                    >
                                        {range.min} - {range.max}
                                    </Select.Option>
                                ))}
                            </PaginatedSelect> */}
                            {user.role === "Seller"
                              ? null
                              : (
                                <>
                                    <PaginatedSelect
                                        id="branch"
                                        title={t("sideMenu.branches")}
                                        search={(e) => {
                                          setBranchesPage(1);
                                          setBranchSearch(e);
                                        }}
                                        validation={{ required: true }}
                                        onScroll={onScroll}
                                        onClick={getBranches}
                                        className="mt-4"
                                    >
                                        {branches.map((branch) => (
                                            <Select.Option
                                                key={branch.id}
                                                value={branch.id}
                                            >
                                                {branch.name}
                                            </Select.Option>
                                        ))}
                                    </PaginatedSelect>
                                    <p className="error">
                                        {branchErr !== "" ? branchErr : null}
                                    </p>
                                </>
                                )}
                        </div>
                        <p className="error">
                            {deliveryRangesErr !== ""
                              ? deliveryRangesErr
                              : null}
                        </p>
                    </Col>
                    <Col sm="12" md="6">
                        <div
                            className="addressCard"
                            onChange={(e) => {
                              if (e.target.value === 1 || e.target.value === 2) { setPaymentType(e.target.value); }
                            }}
                        >
                            <label>{t("paymentMethod")}</label>
                            <Radio
                                value="2"
                                id="payment_method"
                                validation={{ required: true }}
                                name="payment_method"
                                className="radio-btn"
                                txt={t("cash")}
                            />
                            <Radio
                                value="1"
                                id="payment_method"
                                validation={{ required: true }}
                                name="payment_method"
                                className="radio-btn"
                                txt={t("online")}
                            />
                            {paymentType === 1 && (
                                <div
                                    style={{
                                      marginInlineStart: "20px",
                                      fontSize: "13px"
                                    }}
                                >
                                    <Radio
                                        value={true}
                                        id="including_fees"
                                        validation={{ required: true }}
                                        name="including_fees"
                                        className="radio-btn"
                                        txt={t("includingFees")}
                                    />
                                    <Radio
                                        value={false}
                                        id="including_fees"
                                        validation={{ required: true }}
                                        name="including_fees"
                                        className="radio-btn"
                                        txt={t("notIncludingFees")}
                                    />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col sm="12" md="6">
                        {user.role === "Seller" && sellerInfo !== ""
                          ? sellerInfo?.saved_address.map((address) => {
                            return (
                                      <div key={address.id} className="addressCard">
                                          <label>
                                              {t("chooseSellerAddress")}{" "}
                                          </label>
                                          <Radio
                                              value={address.id}
                                              id="seller_address"
                                              validation={{ required: true }}
                                              name="address"
                                              className="radio-btn"
                                              txt={address.address}
                                          />
                                      </div>
                            );
                          })
                          : null}
                    </Col>
                </Row>
                <hr />
                {
                orderDetails !== ""
                  ? (
                    <>
                        <Row>
                            <Col className="text-end" sm="12" md="12">
                                {" "}
                                <Row className="my-2">
                                    <Col sm="6"></Col>

                                    <Col sm="3">
                                        {" "}
                                        <span className="tabsTitles me-4">
                                            <span className="">
                                                {t("shipment")}
                                            </span>
                                        </span>
                                    </Col>
                                    <Col style={{ textAlign: "end" }} sm="3">
                                        {" "}
                                        <span className="tabsTitles">
                                            <span className="">
                                                {t("totalProducts")}
                                            </span>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6"></Col>
                                    <Col sm="3">
                                        {" "}
                                        <div
                                            className={
                                                lang === "en"
                                                  ? "afterInput"
                                                  : "afterInputAr"
                                            }
                                        >
                                            <input
                                                className="shipFees"
                                                type="number"
                                                defaultValue={parseInt(
                                                  orderDetails.delivery_fees
                                                )}
                                                value={calDeliveryFees}
                                                onChange={(e) => {
                                                  setCalDeliveryFees(
                                                    e.target.value
                                                  );
                                                }}
                                            />
                                        </div>
                                        <br />
                                        {/* <span className="tabsInfoPrice me-2">
                  {t("{cur}")}
                    <span className="tabsCost">
                      {" "}
                      {orderDetails.delivery_fees}
                    </span>
                  </span> */}
                                    </Col>
                                    <Col style={{ textAlign: "end" }} sm="3">
                                        {" "}
                                        <span className="tabsInfoPrice me-4">
                                            {t(cur)}
                                            <span className="tabsCost">
                                                {" "}
                                                {orderDetails.sub_total}
                                            </span>
                                        </span>
                                    </Col>
                                </Row>
                                <br />
                                <Row className="my-2">
                                    <Col sm="6"></Col>
                                    <Col sm="3"></Col>
                                    <Col style={{ textAlign: "end" }} sm="3">
                                        <span className="tabsTitles">
                                            <span className="">
                                                {t("total")}
                                            </span>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6"></Col>
                                    <Col sm="3"></Col>

                                    <Col style={{ textAlign: "end" }} sm="3">
                                        {" "}
                                        <span className="tabsInfoPrice">
                                            {t(cur)}
                                            <span className="tabsCost">
                                                {parseInt(
                                                  orderDetails.delivery_fees
                                                ) +
                                                    parseInt(
                                                      orderDetails.sub_total
                                                    )}
                                            </span>
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    )
                  : (
                    <Row>
                        <Col className="text-end" sm="12" md="12">
                            {" "}
                            <Row className="my-2">
                                <Col sm="6"></Col>

                                <Col sm="3">
                                    <span className="tabsTitles me-4">
                                        <span className="">
                                            {t("shipment")}
                                        </span>
                                    </span>
                                </Col>
                                <Col style={{ textAlign: "end" }} sm="3">
                                    {" "}
                                    <span className="tabsTitles">
                                        <span className="">
                                            {t("totalProducts")}
                                        </span>
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6"></Col>
                                <Col sm="3">
                                    {" "}
                                    {/* <input className="shipFees" value={deliveryFees} onChange={(e) => {
                    setDeliveryFees(e.target.value)
                  }} /> */}
                                    <span className="tabsInfoPrice me-2">
                                        {t(cur)}
                                        <span className="tabsCost"> 00.00</span>
                                    </span>
                                </Col>
                                <Col style={{ textAlign: "end" }} sm="3">
                                    {" "}
                                    <span className="tabsInfoPrice me-4">
                                        {t(cur)}
                                        <span className="tabsCost"> 00.00</span>
                                    </span>
                                </Col>
                            </Row>
                            <br />
                            <Row className="my-2">
                                <Col sm="6"></Col>
                                <Col sm="3"></Col>
                                <Col style={{ textAlign: "end" }} sm="3">
                                    {" "}
                                    <span className="tabsTitles">
                                        <span className="">{t("total")}</span>
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6"></Col>
                                <Col sm="3"></Col>

                                <Col style={{ textAlign: "end" }} sm="3">
                                    {" "}
                                    <span className="tabsInfoPrice">
                                        {t(cur)}
                                        <span className="tabsCost">00.00</span>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    )}
                <Row>
                    <Col className="" sm="5"></Col>
                    <Col className="" sm="3"></Col>

                    <Col className="text-center buttons-margin" sm="4">
                        <button className="cancel" onClick={discard}>
                            {t("discard")}
                        </button>
                        {/* orderdetails != "" and deliveryFees===calDeliveryFees ? (scan):(deliveryFees === calDeliveryFees ?(calculate) : (update) ) */}
                        <>
                            <button className="previous" onClick={previous}>
                                {t("previous")}
                            </button>

                            {orderDetails !== "" &&
                            deliveryFees === calDeliveryFees
                              ? (
                                <input
                                    className="confirm doneBtn mx-2"
                                    onClick={showConfirmationModal}
                                    value={t("submit")}
                                />
                                )
                              : orderDetails !== "" &&
                              deliveryFees !== calDeliveryFees
                                ? (
                                <button
                                    onClick={updateRequest}
                                    className="calculateBtn mx-2"
                                    type="button"
                                    disabled={loading}
                                >
                                    {t("update")}
                                </button>
                                  )
                                : (
                                <input
                                    className="calculateBtn mx-2"
                                    value={t("calculate")}
                                    type="submit"
                                />
                                  )}
                        </>
                    </Col>
                </Row>
                {
                showCalculations && orderDetails !== ""
                  ? (
                    <Row>
                        <Col sm="12">
                            <button
                                className="confirm"
                                disabled={printedTickets.length === 0}
                            >
                                <a
                                    style={{ color: "white" }}
                                    href={`${BaseUrl}/CreateInvoice/?tickets=${printedTickets.join(
                                        ","
                                    )}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t("exportToPDF")}
                                </a>
                            </button>
                            <div dir="ltr" className="requestTable1 rs mt-2">
                                <DataGrid
                                    rowHeight="80"
                                    rows={tickets}
                                    columns={
                                        lang === "en" ? columns : columnsAr
                                    }
                                    disableSelectionOnClick
                                    // pageSize={5}
                                    // rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    // selectionModel={arr}
                                    // onSelectionModelChange={onSelect}
                                    onSelectionModelChange={(ids) => {
                                      const selectedIDs = new Set(ids);
                                      setPrintedTickets(ids);

                                      const selectedRowData =
                                            orderDetails.tickets.filter(
                                              (row) => {
                                                selectedIDs.has(row.id);
                                                console.log(selectedIDs);

                                                return selectedIDs.has(row.id);
                                              }
                                            );
                                      console.log(
                                        "onSelectionModelChange:",
                                        selectedRowData
                                      );
                                    }}
                                    onCellClick={async (p, e) => {
                                      console.log(p.field);

                                      if (p.field === "id") {
                                        console.log(p.row.order);
                                        setLoading(true);

                                        // currentState.title === "Pickup Request" ? setDetails(p.row) : setDetails(p.row.order);
                                      }

                                      e.defaultMuiPrevented = true;
                                    }}
                                />
                            </div>{" "}
                        </Col>
                    </Row>
                    )
                  : null}
            </ModifiedForm>
            <OrderConfirmationModal
                show={show}
                hide={hide}
                id={orderDetails.id}
            />
            <OrderDeleteModal
                show={showDelete}
                hide={hideDelete}
                id={orderDetails.id}
            />
        </>
  );
}
