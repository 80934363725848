import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WorkModelCard from "../../AllWorkModels/WorkModelCard";
import WMButton from "../../AllWorkModels/WMButton";
import styles from "./ReportsTypeSelection.module.css";

export default function ReportsTypeSelection () {
  const [selectedType, setSelectedType] = useState(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const types = [
    {
      path: "branch_reports",
      list: Object.values(
        t("reports.branch_list", { returnObjects: true }) ?? {}
      )
    },
    {
      path: "store_reports",
      list: Object.values(
        t("reports.store_list", { returnObjects: true }) ?? {}
      )
    }
  ];

  return (
        <div className={styles.container}>
            <WorkModelCard
                title={t("reports.report_type_select")}
                actions={
                    <>
                        <WMButton
                            disabled={!selectedType}
                            onClick={() => {
                              navigate(`/${selectedType}`);
                            }}
                        >
                            {t("choose")}
                        </WMButton>
                    </>
                }
            >
                <div className={styles.centeredCard}>
                    {types.map((it) => (
                        <div
                            key={it.path}
                            className={[
                              styles.typeCard,
                              it.path === selectedType ? styles.selected : ""
                            ].join(" ")}
                            onClick={() => setSelectedType(it.path)}
                        >
                            <header>{t(`reports.${it.path}`)}</header>
                            <ul>
                                {it.list.map((listItem) => (
                                    <li key={listItem}>{listItem}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </WorkModelCard>
        </div>
  );
}
