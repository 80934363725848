export const RECIPIENT_TYPE = {
  USER: "USER",
  SELLER: "SELLER",
  CUSTOMER: "CUSTOMER",
  ADMIN: "ADMIN",
  COURIER: "COURIER",
  ADMIN_AND_SELLER: "ADMIN_AND_SELLER",
  ADMIN_AND_CUSTOMER: "ADMIN_AND_CUSTOMER",
  ADMIN_AND_COURIER: "ADMIN_AND_COURIER"
};
