import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../../style-sheets/modal.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Select, Input, Form } from "antd";
import useLanguage from "../../hooks/useLanguage";
import { useAccount } from "../../stateManagement/slices/addAccountSlice";
// import { useUserObject } from "../../stateManagement/slices/userSlice";
import store from "../../stateManagement/store";
import { inventoryApi } from "../../stateManagement/apis/inventoryApi";
import { ordersApi } from "../../stateManagement/apis/ordersApi";
import { ticketsApi } from "../../stateManagement/apis/ticketsApi";
import { accountsApi } from "../../stateManagement/apis/accountsApi";
import { errorsHandler, notifyErrors, stringErrorsHandler } from "../../api/ErrorNotifier";
import { useGetCommentsMutation } from "../../stateManagement/apis/commentsApi";
import { useGetOrderTypesMutation, useChangeOrderTypeMutation } from "../../stateManagement/apis/treecodeApi";

const confirmRequest = async (
  selectedOrders,
  verdict,
  hide,
  currentState,
  nextState,
  setLoading,
  onError,
  isUnConfirmedRequest,
  inventory,
  extraBody = {},
  showIsUnConfirmedRequestResult,
  setIsUnConfirmedRequestResult
) => {
  try {
    setLoading(true);

    // Extract IDs from selectedOrders
    const idsList = selectedOrders.map(order => order.id);
    console.log({ idsList });

    const errorHandler = (err) => {
      onError(err?.data ? stringErrorsHandler(err.data) : "Something went wrong");
    };

    let result;
    console.log("NEXT STATE", nextState);

    if (isUnConfirmedRequest) {
      let endpoint, ordersKey;
      if (inventory) {
        endpoint = verdict ? inventoryApi.endpoints.acceptInventoryOrders : inventoryApi.endpoints.rejectInventoryOrders;
        ordersKey = "inventory_orders_ids";
      } else {
        endpoint = ordersApi.endpoints.acceptOrRejectOrders;
        ordersKey = "orders";
      }
      result = await store.dispatch(endpoint.initiate({ [ordersKey]: idsList, verdict, ...extraBody }));
    } else if (nextState.id === 12) {
      result = await store.dispatch(
        ticketsApi.endpoints.cancelConfirmedTickets.initiate({ tickets: idsList, ...extraBody })
      );
    } else {
      result = await store.dispatch(
        ticketsApi.endpoints.pushTicketToState.initiate({ state: nextState.id, tickets: idsList, ...extraBody })
      );
    }

    if (result.data) {
      if (isUnConfirmedRequest) {
        setIsUnConfirmedRequestResult(result.data);
        showIsUnConfirmedRequestResult();
      }
      hide();
    } else {
      errorHandler(result.error);
    }
  } catch (err) {
    onError?.(err);
  } finally {
    setLoading(false);
  }
};

const createAccount = async (
  account,
  showConfirm,
  hide,
  setID,
  type,
  getErrors,
  setPassword,
  setLoading,
  onError
) => {
  setLoading(true);
  let result;
  if (type === -1) {
    const { seller_addresses, store_name, ...user } = account;
    const seller = {
      user,
      store_name,
      seller_addresses
    };
    result = await store.dispatch(
      accountsApi.endpoints.createSeller.initiate(seller)
    );
  } else if (type === 0) {
    result = await store.dispatch(
      accountsApi.endpoints.createThirdParty.initiate(account)
    );
  } else {
    const formattedAccount = {
      ...account,
      type: account.type.toString(),
      permissions: account.permissions
        ? account.permissions
          .filter((it) => it.active)
          .map((it) => it.id)
        : undefined
    };
    console.log({ formattedAccount });
    result = await store.dispatch(
      accountsApi.endpoints.createOrgEmployee.initiate(formattedAccount)
    );
  }

  if (result.data) {
    setLoading(false);
    getErrors("");
    setID(result.data.data.id);
    setPassword(result.data.extras.password);
    hide();
    showConfirm();
  } else if (result.error?.data) {
    onError();
    setLoading(false);
    getErrors(errorsHandler(result.error.data));
  } else {
    onError();
    setLoading(false);
    getErrors({ error: "Something went wrong" });
  }
};

export function ConfirmationModal ({
  show,
  hide,
  selectedCount,
  currentState,
  selectedOrders,
  nextState,
  color,
  background,
  onError,
  isUnConfirmedRequest,
  inventory,
  extraBody,
  showIsUnConfirmedRequestResult,
  setIsUnConfirmedRequestResult
}) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = useLanguage();
  const [ticketComment, setTicketComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [customComment, setCustomComment] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [getCommentsApi, { isLoading: getCommentsLoading }] = useGetCommentsMutation();

  const getComments = async () => {
    const result = await getCommentsApi({
      page,
      page_size: 10
    });
    if (result.data) {
      setComments([...comments, ...result.data.data]);
      if (result.data.next !== null) {
        setLoadingMore(true);
        setPage(result.data.page + 1);
      } else {
        setLoadingMore(false);
      }
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };

  const onScroll = (e) => {
    const bottom =
          e.target.scrollHeight - e.target.scrollTop ===
          e.target.clientHeight;
    if (bottom && loadingMore) {
      getComments();
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
        <div dir={lang === "en" ? "ltr" : "rtl"}>
            <Modal show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("changeStatusConfirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ lineHeight: "2.3" }}>
                    {lang === "en"
                      ? (
                        <>
                            {" "}
                            Do you want to confirm that {selectedCount} items
                            will be changed from{" "}
                            <strong>{currentState.state.title}</strong> to{" "}
                            <strong>
                                <span
                                    className="nextState"
                                    style={{
                                      background: isUnConfirmedRequest
                                        ? "#126AFC"
                                        : nextState.background,
                                      color: isUnConfirmedRequest
                                        ? "white"
                                        : color
                                    }}
                                >
                                    {isUnConfirmedRequest
                                      ? "Approved"
                                      : nextState.name}
                                </span>
                            </strong>
                        </>
                        )
                      : (
                        <>
                            اتريد تأكيد ان عدد {selectedCount} شحنات سيتم
                            تغييرهم من{" "}
                            <strong>{currentState.state.title_ar}</strong> إلى{" "}
                            <strong>
                                <span
                                    className="nextState"
                                    style={{
                                      background: isUnConfirmedRequest
                                        ? "#126AFC"
                                        : nextState.background,
                                      color: isUnConfirmedRequest
                                        ? "white"
                                        : color
                                    }}
                                >
                                    {isUnConfirmedRequest
                                      ? "تمت الموافقة"
                                      : nextState.name}
                                </span>
                            </strong>
                        </>
                        )}
{nextState.id === 12 && (
                        <>
                            <Form.Item
                                name="comment"
                                style={{ marginTop: "10px" }}
                            >
                                <Select
                                    placeholder={t("Select Comment")}
                                    onChange={(value) => {
                                      setTicketComment(value);
                                    }}
                                    onPopupScroll={onScroll}
                                    loading={getCommentsLoading}
                                    options={comments.map((comment) => ({
                                      label: comment.text,
                                      value: comment.id
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item
                                name="customComment"
                                style={{ marginTop: "10px" }}
                            >
                                <Input
                                    placeholder={t("Custom Comment")}
                                    value={customComment}
                                    onChange={(e) =>
                                      setCustomComment(e.target.value)
                                    }
                                />
                            </Form.Item>
                        </>
)}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hide}
                      variant="outlined"
                      color="danger"
                    >
                        {t("cancel")}
                    </Button>
                    <Button
    type="primary"
    disabled={loading}
    loading={loading}
    onClick={() => {
      const extraBody = {};
      if (nextState.id === 12) {
        if (
          !ticketComment &&
              (!customComment || customComment === "")
        ) {
          onError?.(
            t(
              "Please enter a comment to confirm the cancellation"
            )
          );
          return;
        } else if (
          customComment &&
              customComment !== ""
        ) {
          extraBody.comment = customComment;
        } else {
          extraBody.comment = ticketComment;
        }
      }
      confirmRequest(
        selectedOrders,
        true,
        hide,
        currentState, // Pass currentState correctly
        nextState,
        setLoading,
        (err) => {
          onError?.(err);
          setLoading(false);
        },
        isUnConfirmedRequest,
        inventory,
        extraBody,
        showIsUnConfirmedRequestResult,
        setIsUnConfirmedRequestResult
      );
    }
  }
>
    {t("confirm")}
</Button>
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export function DeleteModal ({
  show,
  hide,
  selectedCount,
  selectedOrders,
  currentState,
  nextState,
  inventory
}) {
  const { t } = useTranslation();
  const lang = useLanguage();
  const [reason, setReason] = useState("");
  // console.log(currentState)
  return (
        <div>
            <Modal show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("archiveConfirmation")}</Modal.Title>
                </Modal.Header>
                {lang === "en"
                  ? (
                    <>
                        <Modal.Body>
                          <div>
                            Do you want to confirm that {selectedCount}{" "}
                            {selectedCount === 1 ? "item" : "items"} will be
                            changed from{" "}
                            <strong>{currentState.state.title}</strong> to{" "}
                            <span className="archive">Archive</span>
                          </div>
                          <Form.Item name="reason">
                                <Input
                                    placeholder="Cancellation Reason"
                                    style={{ marginTop: "10px" }}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            </Form.Item>
                        </Modal.Body>
                    </>
                    )
                  : (
                    <>
                        <Modal.Body>
                          <div>
                            اتريد تأكيد ان تغيير عدد {selectedCount} شحنات سيتم
                            تغييرهم من
                            <strong>
                                {currentState.state.title_ar}
                            </strong> إلي{" "}
                            <span className="archive">الأرشيف</span>
                          </div>
                          <Form.Item name="reason">
                                <Input
                                    placeholder="Cancellation Reason"
                                    style={{ marginTop: "10px" }}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            </Form.Item>
                        </Modal.Body>
                    </>
                    )}

                <Modal.Footer>
                    <Button onClick={hide}
                      variant="outlined"
                      color="danger"
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() =>
                          confirmRequest(
                            selectedOrders,
                            false,
                            hide,
                            currentState,
                            nextState,
                            () => {},
                            null,
                            true,
                            inventory,
                            { cancel_reason: reason }
                          )
                        }
                    >
                        {t("confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export function OrderConfirmationModal ({ show, hide, id }) {
  const lang = useLanguage();
  const { t } = useTranslation();

  return (
        <div dir={lang === "en" ? "ltr" : "rtl"}>
            <Modal
                dir={lang === "en" ? "ltr" : "rtl"}
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("createOrder")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {lang === "en"
                          ? `Your order with ID#${id} has been created succesfully`
                          : `تم إنشاء طلبك برقم ${id} بنجاح`}
                    </p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            className="path check"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
            </Modal>
        </div>
  );
}

export function AccountConfirmationModal ({
  show,
  hide,
  id,
  password,
  account
}) {
  const { t } = useTranslation();
  const lang = useLanguage();
  return (
        <div>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("accountConfirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {lang === "ar"
                      ? (
                        <p>
                            {" "}
                            تم انشاء الحساب بنجاح وكلمة السر هي{" "}
                            <strong>{password}</strong>
                        </p>
                        )
                      : (
                        <p>
                            {" "}
                            Your new <strong>{account.type}</strong> account
                            with ID <strong>#{id}</strong> and password{" "}
                            <strong>{password}</strong> has been created
                            succesfully{" "}
                        </p>
                        )}
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            className="path check"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
            </Modal>
        </div>
  );
}

export function CreationConfirmationModal ({
  title,
  show,
  hide,
  showConfirm,
  account,
  setID,
  getErrors,
  setPassword,
  onBack
}) {
  const type = useAccount().type;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const lang = useLanguage();
  console.log(title);
  return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("accountCreateTxt1")}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        // onClick={}
                        className="confirm"
                        disabled={loading}
                        onClick={() => {
                          createAccount(
                            account,
                            showConfirm,
                            hide,
                            setID,
                            type,
                            getErrors,
                            setPassword,
                            setLoading,
                            () => {
                              console.log("WHAAAAAAAAAAAY");
                              onBack();
                              hide();
                            }
                          );
                        }}
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export function OrderDeleteModal ({ show, hide, id }) {
  const lang = useLanguage();
  const { t } = useTranslation();

  return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("deleteOrder")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {lang === "en"
                          ? `Your order with ID <strong>#{id}</strong> has been deleted
            succesfully`
                          : `تم مسح طلبك رقم ${id} بنجاح`}
                    </p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="34.4"
                            y1="37.9"
                            x2="95.8"
                            y2="92.3"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="95.8"
                            y1="38"
                            x2="34.4"
                            y2="92.2"
                        />
                    </svg>{" "}
                </Modal.Body>
            </Modal>
        </div>
  );
}

export function CreationConfirmation ({
  title,
  sendRequest,
  body,
  show,
  hide,
  loading
}) {
  const { t } = useTranslation();
  const lang = useLanguage();
  return (
        <div>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <Button onClick={hide}
                      variant="outlined"
                      color="danger"
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        disabled={loading}
                        // onClick={}
                        // className={
                        //     type.role === "Seller" ? "confirmSeller" : "confirm"
                        // }
                        type="primary"
                        onClick={() => {
                          sendRequest();
                          // createAccount(account, showConfirm, hide , setID , type , getErrors , setPassword)
                        }}
                    >
                        {t("confirm")}
                    </Button>
                  </div>
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export function SuccessfulRequest ({ show, hide, title, body }) {
  const lang = useLanguage();
  return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header
                    className={
                        title === "Setup Your Account"
                          ? "justify-content-center"
                          : null
                    }
                    closeButton
                >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className={
                        title === "Setup Your Account"
                          ? "justify-content-center"
                          : null
                    }
                >
                    <p>{body}</p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            className="path check"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
                {title === "Setup Your Account"
                  ? (
                    <Modal.Footer className="justify-content-center">
                        <button onClick={hide} className="confirm">
                            Done
                        </button>
                    </Modal.Footer>
                    )
                  : null}
            </Modal>
        </div>
  );
}

export function SuccessfulPayment ({ show, setShow, body }) {
  const lang = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hide = () => {
    setShow(false);
    navigate("/dashboard");
  };
  return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("paymentState")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-capitalize">{t("paymentSuccess")}</p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            className="path check"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button onClick={hide} className="confirm">
                        {t("done")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export function UnSuccessfulRequest ({ show, hide, title, body }) {
  const lang = useLanguage();

  return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* TODO */}
                    <p className="text-capitalize">{JSON.stringify(body)}</p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="34.4"
                            y1="37.9"
                            x2="95.8"
                            y2="92.3"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="95.8"
                            y1="38"
                            x2="34.4"
                            y2="92.2"
                        />
                    </svg>{" "}
                </Modal.Body>
            </Modal>
        </div>
  );
}

export function UnSuccessfulPayment ({ show, setShow, body }) {
  const lang = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hide = () => {
    setShow(false);
    navigate("/payments");
  };
  return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal
                className="preline"
                dir={lang === "ar" ? "rtl" : "ltr"}
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("paymentState")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-capitalize">
                        {t("paymentFailed")}{" "}
                        <span className="text-capitalize fw-bold">{body}</span>
                    </p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="34.4"
                            y1="37.9"
                            x2="95.8"
                            y2="92.3"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="95.8"
                            y1="38"
                            x2="34.4"
                            y2="92.2"
                        />
                    </svg>{" "}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button onClick={hide} className="cancel">
                        {t("retry")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export function ConfirmDelete ({
  title,
  show,
  hide,
  deleteEntity,
  body,
  loading
}) {
  const lang = useLanguage();
  const { t } = useTranslation();

  return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        // onClick={}
                        className="confirm"
                        disabled={loading}
                        onClick={() => {
                          deleteEntity();
                        }}
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export function PaymentAlert ({ title, show, setShow }) {
  const lang = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hide = () => {
    setShow(false);
    navigate("/dashboard");
  };
  return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("packageExpired")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("packageExpiredText")}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        className="confirm"
                        onClick={() => navigate("/payments")}
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export function WMDeleteConfirmation ({
  title,
  deleteRequest,
  assignRequest,
  body,
  show,
  hide,
  loading
}) {
  const { t } = useTranslation();
  const lang = useLanguage();
  return (
        <div>
            <Modal
                dir={lang === "ar" ? "rtl" : "ltr"}
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        disabled={loading}
                        className={"cancel filled"}
                        onClick={() => {
                          deleteRequest();
                        }}
                    >
                        {t("delete")}
                    </button>
                    {/* <button */}
                    {/*    disabled={loading} */}
                    {/*    className={"confirm"} */}
                    {/*    onClick={() => { */}
                    {/*        assignRequest?.(); */}
                    {/*    }} */}
                    {/* > */}
                    {/*    {t("assign")} */}
                    {/* </button> */}
                </Modal.Footer>
            </Modal>
        </div>
  );
}

export const UnConfirmedRequestResultModal = ({
  show,
  hide,
  successOrders,
  failedOrders,
  canChangeOrderType,
  onError,
  errors
}) => {
  const { t } = useTranslation();
  const lang = useLanguage();
  const [selectedTypes, setSelectedTypes] = useState(canChangeOrderType);
  const [orderTypes, setOrderTypes] = useState([]);
  const [changeOrderType, { isLoading: changeOrderTypeLoading }] = useChangeOrderTypeMutation();
  const [getOrderTypes, { isLoading: getOrderTypesLoading }] = useGetOrderTypesMutation();

  const handleTypeChange = async (orderId, newType) => {
    const newSelected = [...selectedTypes];
    const index = newSelected.findIndex((el) => +Object.keys(el)[0] === orderId);
    newSelected[index][orderId] = newType;
    setSelectedTypes(newSelected);
  };

  useEffect(() => {
    setSelectedTypes(canChangeOrderType);
  }, [canChangeOrderType]);

  useEffect(() => {
    // Fetch order types when modal opens
    if (show) {
      fetchOrderTypes();
    }
  }, [show]);

  const fetchOrderTypes = async () => {
    const response = await getOrderTypes({
      page_size: 1000
    });
    if (response.data) {
      setOrderTypes(response.data.data || []);
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };

  const handleAcceptAll = async () => {
    const response = await changeOrderType(
      selectedTypes
    );
    if (response.data) {
      if (response.data.errors) {
        onError(response.data.errors);
      } else {
        hide();
      }
    } else if (response.error?.data) {
      onError(stringErrorsHandler(response.error.data));
    } else {
      onError("Something went wrong");
    }
  };

  return (
      <div dir={lang === "en" ? "ltr" : "rtl"}>
          <style>
              {`
          .modal {
              z-index: 1050;
          }
          `}
          </style>
          <Modal show={show} onHide={hide}>
              <Modal.Header closeButton>
                  <Modal.Title>{t("Operation Result")}</Modal.Title>
              </Modal.Header>
              <Modal.Body dir={lang === "en" ? "ltr" : "rtl"}>
                  {/* Success Section */}
                  {successOrders?.length > 0 && (
                      <div className="mb-4">
                          <h5 className="text-success mb-3">
                              <i className="fas fa-check-circle me-2"></i>
                              {t("Successful Orders")} ({successOrders.length}
                              )
                          </h5>
                          <div className="border rounded p-3 bg-light">
                              {successOrders.map((orderId) => (
                                  <div
                                      key={orderId}
                                      className="badge bg-success m-1"
                                  >
                                      #{orderId}
                                  </div>
                              ))}
                          </div>
                      </div>
                  )}

                  {/* Failed Section */}
                  {failedOrders?.length > 0 && (
                      <div className="mb-4">
                          <h5 className="text-danger mb-3">
                              <i className="fas fa-times-circle me-2"></i>
                              {t("Failed Orders")} ({failedOrders.length})
                          </h5>
                          <div className="border rounded p-3 bg-light">
                              {failedOrders.map((orderId) => (
                                <div key={orderId} className="d-flex align-items-center justify-content-start gap-2">
                                  <div
                                      key={orderId}
                                      className="badge bg-danger m-1"
                                  >
                                      #{orderId}
                                  </div>
                                  <div>
                                    {errors[orderId] || t("Something went wrong")}
                                  </div>
                                </div>
                              ))}
                          </div>
                      </div>
                  )}

                  {/* Change Order Type Section */}
                  {canChangeOrderType?.length > 0 && (
                      <div>
                          <h5 className="text-warning mb-3">
                              <i className="fas fa-exclamation-triangle me-2"></i>
                              {t("Orders Requiring Type Change")} (
                              {canChangeOrderType.length})
                          </h5>
                          <div className="border rounded p-3">
                              {canChangeOrderType.map((order) => {
                                const [orderId, typeId] =
                                      Object.entries(order)[0];
                                return (
                                      <div
                                          key={orderId}
                                          className="d-flex align-items-center mb-2 gap-2"
                                      >
                                          <span className="badge bg-warning">
                                              #{orderId}
                                          </span>
                                          <Select
                                              placeholder={t(
                                                "Select Order Type"
                                              )}
                                              loading={getOrderTypesLoading}
                                              style={{ width: "200px" }}
                                              onChange={(value) =>
                                                handleTypeChange(
                                                  orderId,
                                                  value
                                                )
                                              }
                                              options={orderTypes.map(
                                                (type) => ({
                                                  value: type.id,
                                                  label:
                                                          lang === "en"
                                                            ? type.name
                                                            : type.name_ar
                                                })
                                              )}
                                              value={
                                                  selectedTypes?.[orderId] ??
                                                  typeId
                                              }
                                          />
                                      </div>
                                );
                              })}
                          </div>
                      </div>
                  )}
              </Modal.Body>
              <Modal.Footer>
                  <Button
                    variant="outlined"
                    color="danger"
                    onClick={hide}
                  >
                      {t("Close")}
                  </Button>
                  {canChangeOrderType?.length > 0 && (
                      <Button
                          type="primary"
                          disabled={changeOrderTypeLoading}
                          onClick={handleAcceptAll}
                      >
                          {changeOrderTypeLoading
                            ? (
                              <i className="fas fa-spinner fa-spin me-2"></i>
                              )
                            : null}
                          {t("Accept All Changes")}
                        </Button>
                  )}
              </Modal.Footer>
          </Modal>
      </div>
  );
};
