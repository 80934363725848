import React, { useEffect, useState } from "react";
import { Spin, Card, Typography, Button, Row, Col } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";
import { useGetPlansMutation, useSubscribeMutation } from "../../../stateManagement/apis/subscriptionApi";
import { notifyErrors } from "../../../api/ErrorNotifier";
import styles from "./OurPlans.module.css";

const { Title, Text } = Typography;

const PricingPage = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const [getPlans, { isLoading: loadingPlans }] = useGetPlansMutation();
  const [annualPlans, setAnnualPlans] = useState([]);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [subscribe, { isLoading: subscribing }] = useSubscribeMutation();
  const [selectedPlan, setSelectedPlan] = useState(null);

  const fetchPlans = async () => {
    const response = await getPlans();
    if (response.data) {
      setAnnualPlans(response.data.data.filter((plan) => plan.recurring_type === "Yearly"));
      setMonthlyPlans(response.data.data.filter((plan) => plan.recurring_type === "Monthly"));
    }
  };

  const handleSubscribe = async (planId) => {
    setSelectedPlan(planId);
    const result = await subscribe({ planId });
    if (result.data) {
      console.log("SUBSCRIBED TO PLAN: ", result.data);
      window.location.href = result.data.payment_link;
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors("Failed to subscribe to plan");
    }
    setSelectedPlan(null);
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    console.log("PLANS FETCHED: ", annualPlans, monthlyPlans);
  }, [annualPlans, monthlyPlans]);

  return (
    <div className={styles.pricingPage}>
      <Text style={{ fontSize: 18, color: "#888" }} className={styles.subTitle}>
        {t("Pricing Plans Customized to Fit Your Company")}
      </Text>
      {
        loadingPlans &&
          <div className={styles.loadingContainer}>
            <Spin />
          </div>

      }
      {
        annualPlans.length > 0 &&
        <div className={styles.plansContainer}>
          <div className={styles.header}>

            <Title level={1} className={styles.title}>
              {
                language === "en"
                  ? <>
                    <span className={styles.highlighted}>Annual</span>{" "}
                    Pricing Plans
                  </>
                  : <>
                    {" "}
                    انظمة الاسعار {" "}
                    <span className={styles.highlighted}>السنوية</span>
                  </>
              }
            </Title>
          </div>
          <Row justify="center" gutter={[16, 16]}>
            {annualPlans.map((plan, index) => (
              <Col key={index} xs={24} lg={8}>
                <Card
                  hoverable
                  bordered
                  className={styles.planCard}
                >
                  <div className={styles.planHeader}>
                    <Title level={4} className={styles.planTitle}>
                      {language === "en" ? plan.name : plan.name_ar}
                    </Title>
                    <Title level={2} className={styles.planPrice}>
                      {plan.price} {t("SAR")}
                    </Title>
                    <Text type="secondary" className={styles.planSubtitle}>
                      {t("Per Year")}
                    </Text>
                  </div>
                  <div className={styles.benfitsList}>
                    {plan.plan_benefits.map((benfit, i) => (
                      <div key={i} className={styles.benfit}>
                        <div className={styles.benfitIcon}>
                          {
                            benfit.available
                              ? <CheckCircleFilled style={{ color: "#52c41a" }} />
                              : <CloseCircleFilled style={{ color: "#f5222d" }} />

                          }
                        </div>
                        <Text>
                          {
                          language === "en"

                            ? benfit.benefit.name
                            : benfit.benefit.name_ar
                      }
                      </Text>
                      </div>
                    ))}
                    <div className={styles.benfit}>
                      <div className={styles.benfitIcon}>
                        <CheckCircleFilled style={{ color: "#52c41a" }} />
                      </div>
                      {plan.orders_count} {t("Orders Per Year")}
                    </div>
                  </div>
                  <Button
                    type={plan.highlighted ? "primary" : "default"}
                    size="large"
                    className={styles.subscribeButton}
                    loading={subscribing && selectedPlan === plan.id}
                    disabled={subscribing}
                    onClick={() => handleSubscribe(plan.id)}
                  >
                    {t("Subscribe Now")}
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      }
      {
        monthlyPlans.length > 0 &&
        <div className={styles.plansContainer}>
          <div className={styles.header}>
            <Title level={1} className={styles.title}>
              {
                language === "en"
                  ? <>
                    <span className={styles.highlighted}>Monthly</span>{" "}
                    Pricing Plans
                  </>
                  : <>
                    {" "}
                    انظمة الاسعار {" "}
                    <span className={styles.highlighted}>الشهرية</span>
                  </>
              }
            </Title>
          </div>
          <Row justify="center" gutter={[16, 16]}>
            {monthlyPlans.map((plan, index) => (
              <Col key={index} xs={24} lg={8}>
                <Card
                  hoverable
                  bordered
                  className={styles.planCard}
                >
                  <div className={styles.planHeader}>
                    <Title level={4} className={styles.planTitle}>
                      {language === "en" ? plan.name : plan.name_ar}
                    </Title>
                    <Title level={2} className={styles.planPrice}>
                      {plan.price} {t("SAR")}
                    </Title>
                    <Text type="secondary" className={styles.planSubtitle}>
                      {t("Per Month")}
                    </Text>
                  </div>
                  <div className={styles.benfitsList}>
                    {plan.plan_benefits.map((benfit, i) => (
                      <div key={i} className={styles.benfit}>
                        <div className={styles.benfitIcon}>
                          {
                            benfit.available
                              ? <CheckCircleFilled style={{ color: "#52c41a" }} />
                              : <CloseCircleFilled style={{ color: "#f5222d" }} />

                          }
                        </div>
                        <Text>
                          {
                          language === "en"

                            ? benfit.benefit.name
                            : benfit.benefit.name_ar
                      }
                      </Text>
                      </div>
                    ))}
                    <div className={styles.benfit}>
                      <div className={styles.benfitIcon}>
                        <CheckCircleFilled style={{ color: "#52c41a" }} />
                      </div>
                      {plan.orders_count} {t("Orders Per Month")}
                    </div>
                  </div>
                  <Button
                    type={plan.highlighted ? "primary" : "default"}
                    size="large"
                    className={styles.subscribeButton}
                    loading={subscribing && selectedPlan === plan.id}
                    disabled={subscribing}
                    onClick={() => handleSubscribe(plan.id)}
                  >
                    {t("Subscribe Now")}
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      }
    </div>
  );
};

export { PricingPage };
