import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Tour from "reactour";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import { Collapse, Select, DatePicker, Form, Input } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import {
  ConfirmationModal,
  DeleteModal,
  UnSuccessfulRequest,
  PaymentAlert
} from "../../../../components/modals/confirmationModal";
import { plans, usePlan } from "../../../../stateManagement/slices/planSlice";

import useLanguage from "../../../../hooks/useLanguage";
import { useUserObject } from "../../../../stateManagement/slices/userSlice";
import { useGetInventoriesSummaryMutation, useGetAllOrdersMutation, useGetInventoriesMutation } from "../../../../stateManagement/apis/inventoryApi";
import { useFilterSellersMutation } from "../../../../stateManagement/apis/accountsApi";
import { useGetMySubscriptionsMutation } from "../../../../stateManagement/apis/subscriptionApi";
import { InventoryCardsHeader } from "./CardsHeader";
import DashboardRequestsTable from "./DashboardRequestsTable";
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

// const lang = localStorage.getItem("lang");
const steps = [
  {
    selector: ".first-step",
    content: "This is my first Step"
  }
  // ...
];

const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);

const InventoryDashboard = React.memo(() => {
  const lang = useLanguage();
  const user = useUserObject();
  const planDispatch = useDispatch();
  const { t } = useTranslation();
  const plan = usePlan();
  const [planConsumptionApi] = useGetMySubscriptionsMutation();
  const [showPaymentAlert, setPaymentAlert] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [status, setStatus] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState();
  const [summaryFilters] = useState({});
  const [cityFilter, setCityFilter] = useState("");
  const [tableSelection, setTableSelection] = useState([]);
  const [tableSelectedObjects, setTableSelectedObjects] = useState([]);
  const [getInventoriesSummary, { isLoading: loading }] = useGetInventoriesSummaryMutation();
  const [getAllOrders, { isLoading: loadingOrders }] = useGetAllOrdersMutation();
  const [getInventories, { isLoading: loadingInventories }] = useGetInventoriesMutation();
  const defaultState = useMemo(
    () => ({
      count: 1,
      state: {
        color: "#CEE1FF",
        accent_color: "#00000",
        id: "STOCK_AVAILABLE",
        title: "Stock Available",
        title_ar: "المخزون متاح"
      }
    }),
    []
  );
  const [currentState, setCurrentState] = useState(defaultState);
  const currentStateTitle = lang.includes("en")
    ? currentState?.state?.title
    : currentState?.state?.title_ar;
    // const unConfirmedStates = {
    //     pickup: 6,
    //     return: 10,
    //     replace: 11,
    // };
  const isUnConfirmedRequest = useMemo(() => {
    // console.log("CURRENT STATE ID", currentState);
    return currentState.state.id === "PENDING";
  }, [currentState]);

  const colors = {
    1: {
      color: "#CEE1FF",
      accent_color: "#DDE7F7"
    },
    2: {
      color: "#126AFC",
      accent_color: "#DDE7F7"
    },
    3: {
      color: "#1F0765",
      accent_color: "#DDE7F7"
    },
    4: {
      color: "#BFFFDA",
      accent_color: "#E0F3E8"
    },
    5: {
      color: "#57BE6C",
      accent_color: "#E0F3E8"
    },
    6: {
      color: "#148060",
      accent_color: "#E0F3E8"
    },
    7: {
      color: "#FFD3D3",
      accent_color: "#FCECEC"
    }
  };

  const [tableLoading, setTableLoading] = useState(false);
  useEffect(() => {
    if (!showConfirm && !showDelete) {
      getSummary();
      selectState(currentState);
      if (isUnConfirmedRequest) {
        getPlans();
      }
      fetchTableData();
    }
  }, [showConfirm, showDelete, pageSize, summaryFilters]);

  useEffect(() => {
    console.log("SUMMARY FILTERS", summaryFilters);
  }, [summaryFilters]);

  const getSummary = async () => {
    const result = await getInventoriesSummary(summaryFilters);
    if (result.data) {
      console.log("INVENTORY SUMMARY", result.data);
      setStatus(
        result.data.map((state, ind) => ({
          count: state.count,
          state: {
            id: state.status,
            title: state.status_name,
            title_ar: t(state.status_name),
            color: colors[ind + 1].color,
            accent_color: colors[ind + 1].accent_color
          }
        }))
      );
    }
  };

  const getPlans = async () => {
    const result = await planConsumptionApi();

    if (result.data) {
      console.log(result.plan);
      planDispatch(plans(result.plan));
    }
  };

  const showConfirmModal = () => {
    setShowConfirm(true);
  };
  const showDeleteModal = () => {
    setShowDelete(true);
  };

  async function fetchTableData () {
    const method = isUnConfirmedRequest ? getAllOrders : getInventories;
    // const result = await axiosInstance
    //   .get(url, {
    //     params: {
    //       search,
    //       page,
    //       page_size: pageSize,
    //       status: currentState.state.id
    //       // ...summaryFilters,
    //       // seller_address__area__city: cityFilter,
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     if (err.response?.status === 401) {
    //       window.location.reload();
    //     }
    //   });
    const result = await method({
      search,
      page,
      page_size: pageSize,
      status: currentState.state.id
    });
    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  }

  function selectState (newState) {
    setCurrentState(newState);
    setTableSelection([]);
    setPage(1);
    setSearch(undefined);
  }

  useEffect(() => {
    fetchTableData();
  }, [currentState, search, page, pageSize, cityFilter]);

  useEffect(() => {
    if (plan?.orders_count >= plan?.orders_remaining) {
      setPaymentAlert(true);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const closeTour = () => {
    setIsOpen(false);
  };

  const hideModal = () => {
    setShowConfirm(false);
    setShowDelete(false);
  };

  useEffect(() => {
    if (loadingOrders || loadingInventories) {
      setTableLoading(true);
    } else {
      setTableLoading(false);
    }
  }, [loadingOrders, loadingInventories]);

  return (
        <div>
            <Container>
                {/* <DashboardFilterExpandable
                    filters={summaryFilters}
                    setFilters={setSummaryFilters}
                /> */}
                <InventoryCardsHeader
                    selectState={selectState}
                    summaryFilters={summaryFilters}
                    cityFilter={cityFilter}
                    plan={plan}
                    currentStateTitle={currentStateTitle}
                    loading={loading}
                    status={status}
                    inventory
                />

                <Row>
                    {
                    ["Seller", "Third Party"].includes(user.role)
                      ? null
                      : (
                        <Col className="text-end" sm="12">
                            {currentState.state.id === "PENDING"
                              ? (
                                <>
                                    <button
                                        disabled={
                                            tableSelection?.length === 0
                                        }
                                        onClick={showDeleteModal}
                                        className="cancel"
                                    >
                                        {t("Reject Inventory Order")}
                                    </button>

                                    <button
                                        disabled={
                                            tableSelection.length === 0
                                        }
                                        onClick={showConfirmModal}
                                        className="confirm"
                                    >
                                        {t("Approve Inventory Order")}
                                    </button>
                                </>
                                )
                              : null}
                        </Col>
                        )}
                </Row>
                <Row>
                    <Col sm="12">
                        <DashboardRequestsTable
                            inventory
                            currentState={currentState}
                            isUnConfirmedRequest={isUnConfirmedRequest}
                            data={data}
                            onSearch={setSearch}
                            currentStateTitle={currentStateTitle}
                            onRefresh={() => fetchTableData()}
                            loading={tableLoading}
                            selected={tableSelection}
                            cityFilter={cityFilter}
                            setCityFilter={setCityFilter}
                            setSelected={setTableSelection}
                            tableSelecedObjects={tableSelectedObjects}
                            setTableSelecedObjects={setTableSelectedObjects}
                            pagination={{
                              current: page,
                              total: count,
                              pageSize,
                              defaultPageSize: 10,
                              pageSizeOptions: [10, 30, 50, 80, 100],
                              onChange: (page, pageSize) => {
                                setPage(page);
                                setPageSize(pageSize);
                              }
                            }}
                        />
                    </Col>
                </Row>
                <ConfirmationModal
                    isUnConfirmedRequest={isUnConfirmedRequest}
                    inventory
                    currentState={currentState}
                    nextState={{
                      color: "#126AFC",
                      accent_color: "#DDE7F7",
                      id: "STOCK_WAITING",
                      title: "Stock Waiting",
                      title_ar: "المخزون في انتظار"
                    }}
                    selectedCount={tableSelection.length}
                    selectedOrders={tableSelection}
                    show={showConfirm}
                    onError={setError}
                    hide={hideModal}
                />
                <DeleteModal
                    currentState={currentState}
                    inventory
                    selectedOrders={tableSelection}
                    nextState={{
                      color: "#1F0765",
                      accent_color: "#DDE7F7",
                      id: "STOCK_REJECTED",
                      title: "Stock Rejected",
                      title_ar: "المخزون مرفوض"
                    }}
                    selectedCount={tableSelection.length}
                    show={showDelete}
                    hide={hideModal}
                />
                <PaymentAlert
                    setShow={setPaymentAlert}
                    show={showPaymentAlert}
                />
                <UnSuccessfulRequest
                    show={error}
                    hide={() => setError(undefined)}
                    title={t("changeStatusConfirmation")}
                    body={error}
                />
            </Container>
            <Tour
                steps={steps}
                isOpen={isOpen}
                onRequestClose={closeTour}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
        </div>
  );
});
export default InventoryDashboard;

export const DashboardFilterExpandable = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [storeSearch, setStoreSearch] = useState("");
  const [selectedStores, setSelectedStores] = useState([]);
  const [orderSearch, setOrderSearch] = useState(
    filters?.order__id ?? filters?.id
  );
  const [waybillSearch, setWaybillSearch] = useState(
    filters?.order__waybill ?? filters?.waybill
  );
  const [filterSellers, { isLoading: loadingStores }] = useFilterSellersMutation();

  const getStores = async () => {
    const result = await filterSellers({
      page_size: 20,
      search: storeSearch
    });
    if (result.data) {
      setStores(result.data.data);
    }
  };

  useEffect(() => {
    getStores();
  }, [storeSearch]);

  const handleStoreSearch = (search) => {
    setStoreSearch(search);
  };

  const handleStoreSelect = (stores) => {
    console.log("HANDLE STORE SELECT", stores);
    setSelectedStores(stores);
  };

  // const handleStoreRemove = (store) => {
  //   console.log(store, "removed");
  //   setSelectedStores(selectedStores.filter((s) => s.id !== store.id));
  // };

  const handleStoreClear = () => {
    setSelectedStores([]);
  };

  const handleFilterChange = (filtersKeys, value) => {
    console.log("HANDLE FILTER CHANGE", filtersKeys, value);
    const newFilters = { ...filters };
    filtersKeys.forEach((filter) => {
      newFilters[filter] = value ?? undefined;
    });
    console.log("NEW FILTERS", newFilters, "FILTERS", filtersKeys);
    setFilters(newFilters);
  };

  useEffect(() => {
    handleFilterChange(
      ["seller__in", "order__seller__in"],
      selectedStores.join(",")
    );
  }, [selectedStores]);

  const clearFilters = () => {
    setFilters({});
    setSelectedStores([]);
    setStoreSearch("");
  };

  return (
        <Collapse>
            <style>
                {`
                .ant-picker {
                    width: 100%;
                    }

                .createDateRangePicker.ant-calendar-picker-input.ant-input:hover {
                    border-color: #1f9643e5 !important;
                    outline: 0 !important;
                    -webkit-box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
                    box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
                  }
                  
                  .createDateRangePicker:hover {
                    border-color: #1f9643e5 !important;
                  }
                  
                  .createDateRangePicker.ant-picker-focused {
                    border-color: #1f9643e5 !important;
                    outline: 0 !important;
                    -webkit-box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
                    box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
                  }
                  
                  .createDateRangePicker .ant-picker-active-bar {
                    background-color: #1f9643e5 !important;
                  }
                    .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-range-start
                    .ant-picker-cell-inner {
                    background-color: #27c456 !important;
                  }
                  
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-range-end
                    .ant-picker-cell-inner {
                    background-color: #27c456 !important;
                  }
                  
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-today
                    .ant-picker-cell-inner::before {
                    border-color: #1f9643e5 !important;
                  }
                  
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
                  .createDateRangePicker
                    .ant-picker-time-panel-column
                    > li.ant-picker-time-panel-cell-selected
                    .ant-picker-time-panel-cell-inner {
                    background: #e6ffe8 !important;
                  }
                  
                  .createDateRangePicker .ant-btn-primary {
                    background-color: #1f9643e5 !important;
                    border-color: #1f9643e5 !important;
                  }
                  `}
            </style>
            <Panel
                header={t("Dashboard Filters")}
                key="1"
                extra={generateExtra({ clearFilters })}
            >
                <Form layout="vertical">
                    <Row className="mb-3">
                        <Col sm="6">
                            <Form.Item label={t("Store Name")}>
                                <Select
                                    showSearch
                                    loading={loadingStores}
                                    value={selectedStores}
                                    onChange={handleStoreSelect}
                                    onSearch={handleStoreSearch}
                                    onClear={handleStoreClear}
                                    allowClear
                                    filterOption={false}
                                    placeholder={t("Select Store")}
                                    mode="multiple"
                                    options={stores.map((store) => ({
                                      label: store.store_name,
                                      value: store.id
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item label={t("Date")}>
                                <RangePicker
                                    className="createDateRangePicker"
                                    dropdownClassName="createDateRangePicker"
                                    onChange={(dates, dateStrings) => {
                                      console.log(
                                        dates,
                                        dateStrings,
                                        "dates"
                                      );
                                      handleFilterChange(
                                        ["create_date__date__range"],
                                        dateStrings.join(",")
                                      );
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item label={t("Order ID")}>
                                <Input
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleFilterChange(
                                          ["order__id", "id"],
                                          orderSearch
                                        );
                                      }
                                    }}
                                    onChange={(e) =>
                                      setOrderSearch(e.target.value)
                                    }
                                    value={orderSearch}
                                    placeholder={t("Search Order ID")}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item label={t("Waybill Number")}>
                                <Input
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleFilterChange(
                                          ["order__waybill", "waybill"],
                                          waybillSearch
                                        );
                                      }
                                    }}
                                    onChange={(e) =>
                                      setWaybillSearch(e.target.value)
                                    }
                                    value={waybillSearch}
                                    placeholder={t("Search Waybill Number")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Panel>
        </Collapse>
  );
};

const generateExtra = ({ clearFilters }) => {
  return (
        <ClearOutlined onClick={clearFilters} style={{ color: "#27c456" }} />
  );
};
