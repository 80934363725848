import { createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { navigateTo } from "../authActions";
const initialState = {
  currentPath: "/",
  previousPath: "/",
  currentName: "Home"
};

const navigateSlice = createSlice({
  name: "navigateStore",
  initialState,
  reducers: {
    setPath: (state, action) => {
      state.currentPath = action.payload;
    },
    setPreviousPath: (state, action) => {
      state.previousPath = action.payload;
    },
    setCurrentName: (state, action) => {
      state.currentName = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(navigateTo, (state, action) => {
      const navigate = useNavigate();
      navigate(action.payload);
    });
  }
});

export const { setPath, setPreviousPath, setCurrentName } = navigateSlice.actions;
export default navigateSlice.reducer;
export const useNavigateCurrentName = () => useSelector((state) => state.navigateStore.currentName);
