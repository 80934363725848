import store from "../stateManagement/store";
import { smsTemplatesApi } from "../stateManagement/apis/smsTemplatesApi";
import formattedError from "./errorFormatter";

const SmsTemplateRepository = {
  async getAllTemplates ({ page, page_size, search, ...params }) {
    try {
      // Await the dispatch action to ensure it completes before processing the result
      const res = await store.dispatch(smsTemplatesApi.endpoints.getSmsTemplates.initiate({
        page,
        page_size,
        search,
        ...params
      }));

      if (res.data) {
        return {
          success: true,
          status: res.status || 200,
          data: res.data.data,
          count: res.data.count
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },

  async getTemplateById (id) {
    try {
      const res = await store.dispatch(smsTemplatesApi.endpoints.getSmsTemplate.initiate(id));
      if (res.data) {
        return {
          success: true,
          status: res.status || 200,
          data: res.data
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },

  async updateTemplate (id, data) {
    try {
      const res = await store.dispatch(smsTemplatesApi.endpoints.updateSmsTemplate.initiate({
        smsTemplateId: id,
        ...data
      }));
      if (res.data) {
        return {
          success: true,
          status: res.status || 200,
          data: res.data
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  },

  async actionActivateSwitch (id) {
    try {
      const res = await store.dispatch(smsTemplatesApi.endpoints.actionActivateSwitch.initiate(id));
      if (res.data) {
        return {
          success: true,
          status: res.status || 200,
          data: res.data
        };
      } else {
        return formattedError(res, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  }
};

export default SmsTemplateRepository;
