import React, { useState } from "react";
import { Spin } from "antd";
import { Icon } from "@shopify/polaris";
import { DisabledIcon, UploadIcon } from "@shopify/polaris-icons";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InformationCard from "../../../../../components/layout/informationCard";
import {
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../../../../../components/modals/confirmationModal";
import {
  useBulkCreateOrdersMutation,
  useGetBulkOrdersTemplateMutation
} from "../../../../../stateManagement/apis/ordersApi";
// import { stringErrorsHandler } from "../../../../../api/ErrorNotifier";

const CreateBulkOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [bulkCreateOrders, { isLoading: loading }] = useBulkCreateOrdersMutation();
  const [getBulkOrdersTemplate] = useGetBulkOrdersTemplateMutation();

  const hideModal = () => {
    setShow(false);
    navigate("/dashboard");
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"
    ];
    if (!allowedTypes.includes(file.type)) {
      setErrorMessage(t("invalidFileType")); // Invalid file type message
      event.target.value = null; // Reset file input
      return;
    }

    // const formData = new FormData();
    // formData.append("file", file);

    try {
      const result = await bulkCreateOrders(file);

      if (result.data) {
        setMsg(result.data);
        setErrors([]);
        setShow(true);
      } else if (result.error?.data) {
        setErrors(result.error.data);
      } else {
        setErrorMessage(t("server_error"));
      }
    } catch (error) {
      console.error("File upload error:", error);
      setErrorMessage(t("server_error"));
    } finally {
      event.target.value = null; // Reset file input
    }
  };

  const handleTemplateDownload = async () => {
    try {
      await getBulkOrdersTemplate();
    } catch (err) {
      console.error("Error downloading template:", err.message);
      alert(t("downloadError"));
    }
  };

  const renderErrors = () => {
    if (!errors || errors.length === 0) return null;

    return Object.entries(errors).map(([issue, fields]) => (
      <Row key={issue}>
        <Col sm={1} className="error-bg p-2">
          <p>{issue}</p>
        </Col>
        <Col className="error-bg p-2">
          {Array.isArray(fields)
            ? (
                fields.map((field, index) => (
              <p className="error-icon" key={index}>
                <Icon source={DisabledIcon} color="base" />
                {`${Object.keys(field)[0]}: ${Object.values(field)[0]}`}
              </p>
                ))
              )
            : (
            <p className="error-icon">
              <Icon source={DisabledIcon} color="base" />
              {fields}
            </p>
              )}
        </Col>
      </Row>
    ));
  };

  return (
    <>
      <InformationCard
        title={t("infoBulkTitle")}
        subTitle={t("infoBulkSub")}
        list={[
          <p key="step1">
            {t("infoBulkStep1")}{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleTemplateDownload();
              }}
              style={{
                textDecoration: "underline",
                color: "#1890ff",
                cursor: "pointer"
              }}
            >
              ({t("bulkHyperLink")})
            </a>
          </p>,
          <p key="step2">{t("infoBulkStep2")}</p>,
          <p key="step3">{t("infoBulkStep3")}</p>
        ]}
      />

      <div className="uploaderCard">
        {loading ? <Spin /> : <Icon source={UploadIcon} color="base" />}
        <p className="bulk-upload-title">{t("uploadFileText")}</p>
        <p className="bulk-upload-sub">
          <strong>{t("uploadFileSub")}</strong>
        </p>
        <input
          disabled={loading}
          type="file"
          className="uploaderInput"
          onChange={handleFileUpload}
        />
      </div>

      {renderErrors()}

      <SuccessfulRequest
        body={msg}
        title={t("bulkSuccessTitle")}
        show={show}
        hide={hideModal}
      />
      <UnSuccessfulRequest
        show={!!errorMessage}
        hide={() => setErrorMessage(undefined)}
        title={t("postponeConfirmation")}
        body={errorMessage}
      />
    </>
  );
};

export default CreateBulkOrder;
