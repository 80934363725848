import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Switch, notification, Row, Col } from "antd";
import { addOrder, useOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import CreateNewProduct from "../../../../../components/modals/CreateNewProduct";
import { useSelectedSeller } from "../../../../../stateManagement/slices/selectedSellerSlice";
import { useFilterProductsMutation } from "../../../../../stateManagement/apis/treecodeApi";
import GenericForm from "../../../../../components/form/GenericForm";
import { useProductFields } from "../../../../../hooks/useFormFields";
import { useUserObject, useInventoryActive } from "../../../../../stateManagement/slices/userSlice";

const ProductInfoForm = ({ value, handleChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = useOrder();
  const currency = useUserObject().user.organization.country.currency;
  const selectedSeller = useSelectedSeller();
  const user = useUserObject();
  const inventoryActive = useInventoryActive();

  const getInitialTicketData = () => [{
    width: 0, height: 0, weight: 0, product: "", quantity: 1, special_label: "", price: 0, fragile: false, special: false, allow_open: false
  }];

  const getInitialSelectedProducts = (order) => order?.tickets?.map(ticket => parseInt(ticket.product)) ?? [];

  const [products, setProducts] = useState([]);
  // const [productsPage, setProductsPage] = useState(1);
  const [productsSearch, setProductsSearch] = useState("");
  const [filterProductsApi] = useFilterProductsMutation();
  const [showCreate, setShowCreate] = useState(false);
  const [fromInventory, setFromInventory] = useState(false);
  const [ticketData, setTicketData] = useState(order?.tickets?.length > 0 ? order?.tickets : getInitialTicketData());
  const [selectedProduct, setSelectedProduct] = useState(getInitialSelectedProducts(order));

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async (name = null, onlyNew = false) => {
    try {
      const result = await filterProductsApi({
        search: productsSearch,
        seller: user.role === "Seller" ? user.user.user_id : selectedSeller.id,
        name: name ?? ""
      });

      if (result.data) {
        setProducts(prevProducts => {
          const newProducts = onlyNew ? result.data.data : [...prevProducts, ...result.data.data];
          // De-duplicate by product ID
          return newProducts.filter((product, index, self) =>
            index === self.findIndex(p => p.id === product.id)
          );
        });
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSelectedProduct = (productId, index) => {
    const selectedProduct = products.find(product => product.id === productId);

    if (selectedProduct) {
      setTicketData(prevData => prevData.map((ticket, i) =>
        i === index ? { ...ticket, product: selectedProduct.id, price: selectedProduct.price } : ticket
      ));
    }
  };

  const addAnotherProduct = () => {
    setTicketData([...ticketData, getInitialTicketData()[0]]);
  };

  const removeProduct = (index) => {
    const updatedTickets = ticketData.filter((_, i) => i !== index);
    const updatedSelectedProducts = selectedProduct.filter((_, i) => i !== index);
    setTicketData(updatedTickets);
    setSelectedProduct(updatedSelectedProducts);
  };

  const validate = (tickets) => {
    const quantityChecks = {};
    const productsSet = new Set(tickets.map(ticket => ticket.product));

    if (fromInventory) {
      tickets.forEach(ticket => {
        quantityChecks[ticket.product] = (quantityChecks[ticket.product] || 0) + ticket.quantity;
      });
    }

    if (tickets.every(ticket => ticket.product && ticket.special_label && !isNaN(ticket.weight)) &&
      Object.entries(quantityChecks).every(([product, quantity]) =>
        quantity <= products.find(p => +p.id === +product)?.inventory_available_quantity ?? 0
      ) && (productsSet.size === tickets.length || !fromInventory)
    ) {
      // Calculate sub_total
      const subTotal = tickets.reduce((sum, ticket) => sum + (ticket.price * ticket.quantity), 0);

      const orderData = { tickets, from_stock: fromInventory, sub_total: subTotal };
      dispatch(addOrder(orderData));
      console.log({ orderData });
      handleChange(null, parseInt(value) + 1);
    } else {
      notification.error({
        message: t("validation_error"),
        description: t("Please check product fields and quantities"),
        duration: null
      });
    }
  };

  const fieldsArray = useProductFields(setShowCreate, ticketData, products, selectedProduct, handleSelectedProduct, handleChange, value, addAnotherProduct, setTicketData, currency, setProductsSearch, inventoryActive);

  return (
    <div>
      {
        inventoryActive && (
          <div className="d-flex justify-content-start align-items-center">
            <Switch checked={fromInventory} onChange={setFromInventory} aria-label={t("FromInventoryStock")} />
            <span style={{ marginLeft: 8 }}>{t("FromInventoryStock")}</span>
          </div>
        )
      }
      <hr style={{ marginBottom: "20px" }} />

      {fieldsArray.map((fields, index) => (
        <div key={index} style={{ marginBottom: "20px", position: "relative" }}>
          {ticketData.length > 1 && (
            <Button
              icon={<CloseOutlined />}
              type="text"
              style={{
                position: "absolute",
                top: 5,
                right: 120,
                color: "red",
                padding: 0,
                fontSize: "16px",
                zIndex: 1
              }}
              onClick={() => removeProduct(index)}
            />
          )}
          <Row>
            <Col span={22}>
              <GenericForm fields={fields} submitFunction={() => validate(ticketData)} />
            </Col>
          </Row>
        </div>
      ))}

      <hr style={{ marginBottom: "20px" }} />

      {showCreate && (
        <CreateNewProduct
          showModal={showCreate}
          hideModal={() => setShowCreate(false)}
          setCreated={() => getProducts()}
          useModal
        />
      )}
    </div>
  );
};

export default ProductInfoForm;
