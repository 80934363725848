import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  type: "default"

};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardType: (state, action) => {
      state.type = action.payload;
    }
  }
});

export const { setDashboardType } = dashboardSlice.actions;
export const useDashboardType = () => useSelector((state) => state.dashboard.type);

export default dashboardSlice.reducer;
