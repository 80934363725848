import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {};

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    plans: (state, action) => {
      state.plan = action.payload;
    }
  }
});

export const { plans } = planSlice.actions;
export const usePlan = () => useSelector((state) => state.plan.plan);

export default planSlice.reducer;
