import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../../style-sheets/form.css";
import { Select, Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import useLanguage from "../../hooks/useLanguage";

const { Option } = Select;

const CreateTicket = ({ showModal, hideModal, updatedStates }) => {
  const [titleAr, setTitleAr] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [desc, setDesc] = useState("");
  const [validated, setValidated] = useState(false);
  const [primary, setPrimary] = useState("#57BE6C");
  const [second, setSecond] = useState("#57BE6C");
  const [prevStates, setPrevStates] = useState([]);
  const [selectedPrevState, setSelectedPrevState] = useState([]);
  const [selectedNextState, setSelectedNextState] = useState([]);
  const [selectedPossible, setSelectedPossible] = useState([]);
  const { t } = useTranslation();

  const [count, setCount] = useState(1);
  const handleSubmit = async (e) => {
    // req
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      create();
    }
    setValidated(true);
    e.preventDefault();
  };

  const getStates = async () => {
    const result = await axiosInstance.get("/State/");
    if (result) {
      console.log(result);
      setPrevStates(result.data.data);
      setCount(result.data.count);
      console.log(count);
    }
  };
  const handlePrevState = (e) => {
    setSelectedPrevState(e);
  };
  const handleNextState = (e) => {
    setSelectedNextState(e);
  };
  const handlePossible = (e) => {
    console.log(e);
    setSelectedPossible(e);
  };

  const create = async () => {
    const data = {
      title: titleEn,
      title_ar: titleAr,
      color: primary,
      accent_color: second,
      description: desc,
      next_state: selectedNextState,
      prev_state: selectedPrevState,
      accepted_states: selectedPossible
    };
    console.log(data);
    const result = await axiosInstance.post("/State/", data).catch((err) => {
      console.log(err.response);
    });
    if (result) {
      console.log(result);
      if (result.data) {
        hideModal();
        updatedStates(true);
      }
    }
  };
  const lang = useLanguage();

  return (
    <div dir={lang === "ar" ? "rtl" : "ltr"}>
      <Modal width={1000} visible={showModal} onCancel={hideModal}>
        <Container dir={lang === "ar" ? "rtl" : "ltr"} className="pt-3">
          <Row>
            <Col sm="12">
              <h6 className="pageTitle"> {t("addNewState")}</h6>
            </Col>
          </Row>
          <Row className="customercreateCard" style={{ boxShadow: "unset" }}>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              //   onFinish={onFinish}
            >
              {" "}
              <Row>
                <Col sm={12} md={6}>
                  <div className="text-area-form">
                    <Form.Group controlId="validationtitleEn">
                      <Form.Label>{t("trackingTable.title")}</Form.Label>

                      <Form.Control
                        placeholder={t("trackingTable.title")}
                        name="titleEn"
                        value={titleEn}
                        onChange={(e) => setTitleEn(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Required field!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
                <Col className="6">
                  <div className="text-area-form">
                    <Form.Group controlId="validationtitleAr">
                      <Form.Label>{t("trackingTable.titleAr")}</Form.Label>

                      <Form.Control
                        placeholder={t("trackingTable.titleAr")}
                        name="titleAr"
                        value={titleAr}
                        onChange={(e) => setTitleAr(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Required field!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Form.Group controlId="validationtitleEn">
                    <Form.Label>{t("trackingTable.description")}</Form.Label>

                    <Form.Control
                      placeholder={t("trackingTable.description")}
                      name="desc"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                    />
                  </Form.Group>
                  <p style={{ color: "#6D7175", fontSize: "12px" }}>
                    {t("tip")}{" "}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="6">
                  <Form.Label htmlFor="exampleColorInput">
                    {t("trackingTable.primColor")}
                  </Form.Label>
                  <Form.Control
                    type="color"
                    id="exampleColorInput"
                    defaultValue="#57BE6C"
                    title="Primery Color "
                    // value={color}
                    onChange={(e) => setPrimary(e.target.value)}
                  />
                </Col>
                <Col sm="12" md="6">
                  <Form.Label htmlFor="exampleColorInput">
                    {t("trackingTable.secColor")}
                  </Form.Label>
                  <Form.Control
                    type="color"
                    id="exampleColorInput"
                    defaultValue="#57BE6C"
                    title="Selection Color"
                    onChange={(e) => setSecond(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="4">
                  <Form.Label htmlFor="exampleColorInput">
                    {t("trackingTable.prevState")}
                  </Form.Label>
                  <Select
                    onChange={handlePrevState}
                    value={selectedPrevState}
                    style={{ width: 100, display: "block" }}
                    placeholder={t("trackingTable.prevState")}
                    onClick={getStates}
                  >
                    {prevStates.map((state) => {
                      return (
                        <Option
                          name={state.title}
                          key={state.id}
                          value={state.id}
                        >
                          {state.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col sm="12" md="4">
                  {" "}
                  <Form.Label htmlFor="exampleColorInput">
                    {t("trackingTable.nextState")}
                  </Form.Label>
                  <Select
                    onChange={handleNextState}
                    value={selectedNextState}
                    style={{ width: 100, display: "block" }}
                    placeholder={t("trackingTable.nextState")}
                    onClick={getStates}
                  >
                    {prevStates.map((state) => {
                      return (
                        <Option
                          name={state.title}
                          key={state.id}
                          value={state.id}
                        >
                          {state.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col sm="12" md="4">
                  {" "}
                  <Form.Label htmlFor="exampleColorInput">
                    {t("trackingTable.possible")}
                  </Form.Label>
                  <Select
                    mode="multiple"
                    onChange={handlePossible}
                    value={selectedPossible}
                    style={{ width: 100, display: "block" }}
                    placeholder={t("trackingTable.possible")}
                    onClick={getStates}
                  >
                    {prevStates.map((state) => {
                      return (
                        <Option
                          name={state.title}
                          key={state.id}
                          value={state.id}
                        >
                          {state.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="text-end" sm="12">
                  <Button onClick={hideModal} className="cancel">
                    {t("discard")}
                  </Button>
                  <Button
                    type="primary"
                    className="confirm"
                    onClick={handleSubmit}
                  >
                    {t("save")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </Modal>
    </div>
  );
};

export default CreateTicket;
