import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { FLUSH, PAUSE, PURGE, REGISTER, REHYDRATE, PERSIST } from "redux-persist/es/constants";
import storage from "redux-persist/lib/storage";
import { authApi } from "./apis/authApi";
import { accountsApi } from "./apis/accountsApi";
import { ordersApi } from "./apis/ordersApi";
import { reportsApi } from "./apis/reportsApi";
import { inventoryApi } from "./apis/inventoryApi";
import { runsheetsApi } from "./apis/runsheetsApi";
import { smsTemplatesApi } from "./apis/smsTemplatesApi";
import { ticketsApi } from "./apis/ticketsApi";
import { treecodeApi } from "./apis/treecodeApi";
import { subscriptionApi } from "./apis/subscriptionApi";
import { publicSchemaApi } from "./apis/publicSchemaApi";
import userSliceReducer from "./slices/userSlice";
import addAccountSliceReducer from "./slices/addAccountSlice";
import addOrderSliceReducer from "./slices/addOrderSlice";
import addrunsheetSliceReducer from "./slices/addRunsheetSlice";
import branchSliceReducer from "./slices/branchSlice";
import codeSliceReducer from "./slices/codeSlice";
import dashboardSliceReducer from "./slices/dashboardSlice";
import inventoryOrderSliceReducer from "./slices/inventoryOrderSlice";
import isValidSliceReducer from "./slices/isValidSlice";
import navigateSliceReducer from "./slices/navigateSlice";
import planSliceReducer from "./slices/planSlice";
import postpondOrderSliceReducer from "./slices/postponedOrdersSlice";
import reassignedRSSliceReducer from "./slices/reassignedRSSlice";
import returnedTicketsSliceReducer from "./slices/returnedTicketsSlice";
import rsOrdersSliceReducer from "./slices/rsOrdersSlice";
import searchSliceReducer from "./slices/searchSlice";
import selectedSellerSliceReducer from "./slices/selectedSellerSlice";
import selectedUserSliceReducer from "./slices/selectedUserSlice";
import stateSliceReducer from "./slices/stateSlice";
import ticketPrintedDateSliceReducer from "./slices/ticketPrintedDataSlice";
import userTypeSliceReducer from "./slices/userTypeSlice";
import archivedOrderSliceReducer from "./slices/archivedOrderSlice";
import { RejectedWithValueHandler } from "./apis/errorHandler";
import { commentsApi } from "./apis/commentsApi";
const store = configureStore({
  reducer: {
    // Add slice reducers here
    auth: persistReducer({ key: "userStore", storage }, userSliceReducer),
    selectedState: stateSliceReducer,
    selectedSeller: selectedSellerSliceReducer,
    search: searchSliceReducer,
    order: addOrderSliceReducer,
    isValid: isValidSliceReducer,
    account: addAccountSliceReducer,
    userType: userTypeSliceReducer,
    runsheet: addrunsheetSliceReducer,
    rsOrders: rsOrdersSliceReducer,
    branch: branchSliceReducer,
    postponed: postpondOrderSliceReducer,
    reassignedRS: reassignedRSSliceReducer,
    returnedTickets: returnedTicketsSliceReducer,
    selectedUser: selectedUserSliceReducer,
    ticketPrintedData: ticketPrintedDateSliceReducer,
    code: codeSliceReducer,
    dashboard: dashboardSliceReducer,
    inventoryOrder: inventoryOrderSliceReducer,
    plan: planSliceReducer,
    navigate: navigateSliceReducer,
    archivedOrder: archivedOrderSliceReducer,

    // Add API reducers here
    [authApi.reducerPath]: authApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [runsheetsApi.reducerPath]: runsheetsApi.reducer,
    [smsTemplatesApi.reducerPath]: smsTemplatesApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [treecodeApi.reducerPath]: treecodeApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [publicSchemaApi.reducerPath]: publicSchemaApi.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([
      // will add all the api middleware here
      authApi.middleware,
      accountsApi.middleware,
      ordersApi.middleware,
      reportsApi.middleware,
      inventoryApi.middleware,
      runsheetsApi.middleware,
      smsTemplatesApi.middleware,
      ticketsApi.middleware,
      treecodeApi.middleware,
      subscriptionApi.middleware,
      publicSchemaApi.middleware,
      commentsApi.middleware
    ]),
    // will add request error logger here
    // requestErrorLogger,
    RejectedWithValueHandler

  ],
  devTools: import.meta.env.DEV
});

export default store;
