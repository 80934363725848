import React, { useState, useEffect } from "react";
import "../../../../style-sheets/form.css";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import GenericForm from "../../../../components/form/GenericForm";
import { addAccount } from "../../../../stateManagement/slices/addAccountSlice";
// import PermissionsList from "../../../../components/forms/PermissionsList";
import { useGetBranchesMutation, useGetPermissionsMutation } from "../../../../stateManagement/apis/treecodeApi";

export default function AccountTypeForm ({ accountTypes, value, handleChange }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [branches, setBranches] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [selectedUser, setSelected] = useState(null);
  const [branchErr, setBranchErr] = useState("");
  const [permissions, setPermissions] = useState(undefined);
  const [getBranchesApi] = useGetBranchesMutation();
  const [getPermissionsApi, { isLoading: permLoading }] = useGetPermissionsMutation();

  const getBranches = async () => {
    const result = await getBranchesApi({ page });
    if (result.data) {
      setBranches([...branches, ...result.data.data]);
      if (result.data.next === null) {
        setLoadMore(false);
      } else {
        setPage(page + 1);
      }
    }
  };

  const getUserPermissions = async () => {
    if (!selectedUser || [accountTypes.seller.index, accountTypes.courier.index, accountTypes.thirdParty.index].includes(selectedUser)) {
      setPermissions(undefined); // Clear permissions if user_type is null or a type without specific permissions
      return;
    }

    try {
      const result = await getPermissionsApi({ user_type: selectedUser });
      if (result.data) {
        setPermissions(result.data);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    if (selectedUser !== null) {
      getUserPermissions();
    }
  }, [selectedUser]);

  const onScroll = async (event) => {
    const target = event.target;
    if (loadMore && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      await getBranches();
    }
  };

  const handleUser = (e) => {
    setSelected(e);
  };

  const handleBranchChange = (value, option) => {
    if (option?.branchname) {
      dispatch({ type: "SELECT_BRANCH", payload: option.branchname });
      console.log(branchErr);
    }
  };

  const fields = [
    {
      name: "user",
      label: t("selectUser"),
      component: Select,
      props: {
        options: Object.values(accountTypes).map((it) => ({ value: it.index, label: it.title })),
        onChange: handleUser,
        placeholder: t("userType")
      }
    },
    selectedUser && ![accountTypes.seller.index, accountTypes.thirdParty.index].includes(selectedUser)
      ? {
          name: "branch",
          label: t("sideMenu.branches"),
          component: Select,
          props: {
            options: branches.map((branch) => ({ value: branch.id, label: branch.name })),
            onChange: handleBranchChange,
            onPopupScroll: onScroll,
            placeholder: t("selectBranch")
          }
        }
      : null,
    // Add PermissionsList as a custom field, styled separately
    permissions && permissions.length > 0
      ? {
          name: "permissions",
          component: "PermissionsList",
          props: {
            permissions,
            updatePermission: (i, v) => {
              // Create a copy of the permissions array
              const updatedPermissions = permissions.map((perm, index) =>
                index === i ? { ...perm, active: v } : { ...perm }
              );
              setPermissions(updatedPermissions); // Update permissions state with the modified array
            },
            loading: permLoading
          }
          // customStyle: {
          //   padding: "20px",
          //   borderRadius: "15px",
          //   border: "1px solid transparent",
          //   boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
          //   backgroundColor: "#fff"
          // }
        }
      : null,
    {
      type: "button",
      label: t("proceed"),
      htmlType: "submit",
      buttonType: "primary"
    }
  ].filter(Boolean);

  const validate = (data) => {
    if (!data.branch && ![accountTypes.seller.index, accountTypes.thirdParty.index].includes(selectedUser)) {
      setBranchErr(t("branchErr"));
    } else {
      setBranchErr("");
      data.type = selectedUser;
      data.permissions = permissions;
      handleChange(null, value + 1);
      dispatch(addAccount(data));
    }
  };

  useEffect(() => {
    getBranches();
    dispatch(addAccount(""));
  }, []);

  return (
    <GenericForm
      fields={fields}
      submitFunction={validate}
      hooks={{ initialValues: { user: selectedUser }, successMessage: t("success"), errorMessage: t("error") }}
    />
  );
}
