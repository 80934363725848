import React, { useState } from "react";
import "../../style-sheets/form.css";
import { Modal } from "antd";
import * as yup from "yup";
import "react-phone-input-2/lib/style.css"; // Import phone input styles
import { useSelectedSeller } from "../../stateManagement/slices/selectedSellerSlice";
import { useUserObject } from "../../stateManagement/slices/userSlice";
import { useFilterAreasMutation, useCreateClientMutation } from "../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../api/ErrorNotifier";
import { useCreateCustomerFormFields } from "../../hooks/useFormFields"; // Custom form hook
import GenericForm from "../form/GenericForm"; // GenericForm component

// Validation schema using Yup
const createCustomerSchema = yup.object().shape({
  name: yup.string().required("Full name is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be digits"),
  altPhone: yup.string().nullable(),
  area: yup.string().required("Area is required"),
  address: yup.string().required("Address is required")
});

const CreateNewCustomer = ({ showModal, hideModal, setCreated }) => {
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [areas, setAreas] = useState([]);
  const [filterAreas] = useFilterAreasMutation();
  const [createClient] = useCreateClientMutation();

  const selectedSeller = useSelectedSeller();
  const user = useUserObject();

  // Fetch areas
  const getAreas = async () => {
    const result = await filterAreas({ page });
    if (result.data) {
      setAreas((prevAreas) => [...prevAreas, ...result.data.data]); // Use result.data.data to access the areas
      setLoadMore(result.data.next !== null); // Check if there are more pages
      if (result.data.next) setPage((prevPage) => prevPage + 1); // Increment page only if there is a next page
    }
  };

  // Handle infinite scroll
  const onScroll = async (event) => {
    const target = event.target;
    if (loadMore && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      await getAreas();
    }
  };

  // Submit form data
  const submitForm = async (data) => {
    const formData = {
      seller: user.role === "Seller" ? user.user.user_id : selectedSeller.id,
      phone: data.phone,
      phone_alt: data.altPhone,
      name: data.name,
      address: data.address,
      area: data.area,
      lat,
      lng
    };

    const result = await createClient(formData);
    if (result.data) {
      setCreated(true);
      hideModal();
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };

  // Form fields using the custom hook
  const { fields: formFields, lat, lng } = useCreateCustomerFormFields(areas, onScroll, getAreas, hideModal);

  return (
    <Modal
      destroyOnClose
      width={1000}
      visible={showModal}
      onCancel={hideModal}
      footer={null}
    >
      <GenericForm
        fields={formFields}
        submitFunction={submitForm}
        validationSchema={createCustomerSchema}
        hooks={{ initialValues: {}, successMessage: "Success", errorMessage: "Error" }}
      />
    </Modal>
  );
};

export default CreateNewCustomer;
