import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import { notification, Form as AntForm, Button, Select, Input, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";

import InventoryItemLocationsModal from "../../modals/InventoryItemLocationsModal";
import InventoryOrderRepository from "../../../api/InventoryOrderRepository";
import WareHousesRepository from "../../../api/WarehousesRepository";
import { INVENTORY_ORDER_TYPES } from "../../../utils/beDict";
import { useGetInventoryItemAvailabilityMutation } from "../../../stateManagement/apis/inventoryApi";
import { notifyErrors } from "../../../api/ErrorNotifier";

const AcceptSellerCreatedOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const [acceptForm] = AntForm.useForm();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [backEndErrors, setBackEndErrors] = useState([]);
  const [inventoryLocations, setInventoryLocations] = useState([]);
  const [getInventoryItemAvailability, { isLoading: fetchLoading }] = useGetInventoryItemAvailabilityMutation();

  const getInventoryLocations = async () => {
    const response = await WareHousesRepository.getAllLocations();
    if (response.success) {
      setInventoryLocations(response.data);
    }
  };

  const handleChangeInventoryLocations = (
    fieldName,
    random_id,
    location,
    quantity,
    ticket,
    inventory_order
  ) => {
    const oldItems = acceptForm.getFieldValue("order_items");
    const oldLocations = oldItems[fieldName].inventory_locations || [];
    const oldLocationIndex = oldLocations?.findIndex(
      (loc) => loc.random_id === random_id
    );
    if (oldLocationIndex === -1) {
      oldLocations.push({
        random_id,
        location,
        quantity,
        ticket,
        inventory_order
      });
    } else {
      oldLocations[oldLocationIndex] = {
        random_id,
        location,
        quantity,
        ticket,
        inventory_order
      };
    }
    oldItems[fieldName].inventory_locations = oldLocations;
    acceptForm.setFieldsValue({ old_items: oldItems });
    setDirty(true);
    console.log(dirty);
  };

  const hideInventoryLocationsModal = (fieldName) => {
    const oldItems = acceptForm.getFieldValue("order_items");
    oldItems[fieldName].showInventoryLocationsModal = false;
    acceptForm.setFieldsValue({ old_items: oldItems });
  };

  const showInventoryLocationsModal = (fieldName) => {
    const oldItems = acceptForm.getFieldValue("order_items");
    oldItems[fieldName].showInventoryLocationsModal = true;
    acceptForm.setFieldsValue({ old_items: oldItems });
  };

  const fetchInventoryForItem = async (fieldName) => {
    const oldItems = acceptForm.getFieldValue("order_items");
    console.log("CURRENT FIELD VALUE", oldItems[fieldName]);
    const result = await getInventoryItemAvailability(
      oldItems[fieldName].item,
      order.type.toUpperCase(),
      {
        page: acceptForm.getFieldValue([fieldName, "location_page"]),
        page_size: 10
      }
    );
    if (result.data) {
      oldItems[fieldName].inventory_item_locations = result.data.data;
      oldItems[fieldName].location_page = result.data.page;
      oldItems[fieldName].location_count = result.data.count;
      acceptForm.setFieldsValue({ old_items: oldItems });
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors(t("Error fetching inventory locations"));
    }
  };

  const getOrder = async () => {
    setLoading(true);
    const result = await InventoryOrderRepository.getOrder(orderId);
    if (result.success) {
      setOrder(result.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getOrder();
    getInventoryLocations();
  }, []);

  const onFinish = async (values) => {
    console.log("FORM VALUES", values);
    setSubmitting(true);
    const response = await InventoryOrderRepository.acceptSellerCreateOrder(
      orderId,
      values
    );
    if (response.success) {
      notification.success({ message: t("Order accepted successfully") });
      setTimeout(() => navigate("/inventory_dashboard"), 2000);
    } else if (response.status < 500) {
      console.log("Error accepting order", response.error);
      setBackEndErrors(
        Object.entries(response.error).map(
          ([key, value]) =>
                        `${key}: ${value instanceof Array ? value[0] : value}`
        )
      );
      setDirty(false);
    } else {
      notification.error({ message: t("Error from server") });
    }
    setSubmitting(false);

    // let response;
    // setSubmitting(true);
    // if (locationId === "create") {
    //     response = await WareHousesRepository.createWarehouseLocation(
    //         warehouseId,
    //         values
    //     );
    // } else {
    //     response = await WareHousesRepository.updateWarehouseLocation(
    //         warehouseId,
    //         locationId,
    //         values
    //     );
    // }
    // if (response.success) {
    //     notification.success({
    //         message: t("Warehouse has been updated successfully"),
    //     });
    //     setDirty(false);
    //     if (locationId === "create") {
    //         navigate(
    //             `/warehouse/${warehouseId}/locations/${response.data.id}`
    //         );
    //     }
    // } else if (response.status === 400) {
    //     const nonFieldErrors = [];
    //     locationForm.setFields([
    //         Object.entries(response.error)
    //             .filter(([key, value]) => {
    //                 if (
    //                     locationFields
    //                         .map((field) => field.name)
    //                         .includes(key)
    //                 ) {
    //                     return true;
    //                 } else {
    //                     nonFieldErrors.push(
    //                         value instanceof Array ? value[0] : value
    //                     );
    //                     return false;
    //                 }
    //             })
    //             .map(([key, value]) => ({
    //                 name: key,
    //                 errors: value instanceof Array ? value : [value],
    //             })),
    //     ]);
    //     if (nonFieldErrors.length > 0) {
    //         nonFieldErrors.forEach((error) =>
    //             notification.error({ message: error })
    //         );
    //     }
    //     setDirty(false);
    // } else {
    //     notification.error({ message: response.error });
    // }
    // setSubmitting(false);
  };

  return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">
                            {t("Inventory Orders") + " >"}
                        </span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title1">
                            {t("Accept Seller Created Order") + " >"}
                        </span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("Order Number") + ": " + orderId}
                        </span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {t("Accept Seller Created Order")}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <div>
                        {(loading || !order) && (
                            <div
                                className="d-flex justify-content-center align-items-center w-100"
                                style={{ height: "50vh" }}
                            >
                                {" "}
                                <Spin size="large" />{" "}
                            </div>
                        )}
                        {!loading && order?.id && (
                            <div>
                                <AntForm
                                    layout="vertical"
                                    className=""
                                    onFinish={onFinish}
                                    form={acceptForm}
                                    // onFinishFailed={onFinishFailed}
                                    initialValues={order}
                                    requiredMark
                                    labelWrap
                                >
                                    <div className="grid-form small-gap">
                                        <AntForm.List
                                            name="order_items"
                                            initialValue={order.order_items}
                                        >
                                            {(
                                              fields,
                                              { add, remove },
                                              { errors }
                                            ) => (
                                                <>
                                                    {fields.map(
                                                      (
                                                        {
                                                          key: fieldKey,
                                                          name: fieldName,
                                                          ...restField
                                                        },
                                                        index
                                                      ) => (
                                                            <div
                                                                key={index}
                                                                className="d-grid gap-2 border p-3"
                                                            >
                                                                <AntForm.Item
                                                                    name={[
                                                                      fieldName,
                                                                      "ID"
                                                                    ]}
                                                                    label={t(
                                                                      "Inventory Item ID"
                                                                    )}
                                                                    disabled
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        disabled
                                                                        defaultValue={
                                                                            acceptForm.getFieldValue(
                                                                              "order_items"
                                                                            )[
                                                                              fieldName
                                                                            ]
                                                                              .item
                                                                        }
                                                                    />
                                                                </AntForm.Item>
                                                                <AntForm.Item
                                                                    name={[
                                                                      fieldName,
                                                                      "inventory_item"
                                                                    ]}
                                                                    label={t(
                                                                      "Inventory Item"
                                                                    )}
                                                                    disabled
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        disabled
                                                                        defaultValue={
                                                                            acceptForm.getFieldValue(
                                                                              "order_items"
                                                                            )[
                                                                              fieldName
                                                                            ]
                                                                              .item_display
                                                                              .product
                                                                              .name
                                                                        }
                                                                    />
                                                                </AntForm.Item>
                                                                <AntForm.Item
                                                                    name={[
                                                                      fieldName,
                                                                      "quantity"
                                                                    ]}
                                                                    label={t(
                                                                      "Quantity"
                                                                    )}
                                                                    disabled
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        disabled
                                                                        value={
                                                                            acceptForm.getFieldValue(
                                                                              "order_items"
                                                                            )[
                                                                              fieldName
                                                                            ]
                                                                              .quantity
                                                                        }
                                                                    />
                                                                </AntForm.Item>
                                                                {order.type ===
                                                                    INVENTORY_ORDER_TYPES.RETURN[0].toUpperCase() +
                                                                        INVENTORY_ORDER_TYPES.RETURN.slice(
                                                                          1
                                                                        ).toLowerCase() && (
                                                                    <>
                                                                        <AntForm.Item
                                                                            name={[
                                                                              fieldName,
                                                                              "location"
                                                                            ]}
                                                                            label={t(
                                                                              "Location"
                                                                            )}
                                                                            rules={[
                                                                              {
                                                                                validator:
                                                                                        async (
                                                                                          _,
                                                                                          value
                                                                                        ) => {
                                                                                          const sumQuantity =
                                                                                                acceptForm
                                                                                                  .getFieldValue(
                                                                                                    "order_items"
                                                                                                  )[fieldName].inventory_locations?.reduce(
                                                                                                    (
                                                                                                      acc,
                                                                                                      loc
                                                                                                    ) =>
                                                                                                      acc +
                                                                                                            loc.quantity,
                                                                                                    0
                                                                                                  );
                                                                                          if (
                                                                                            sumQuantity !==
                                                                                                acceptForm.getFieldValue(
                                                                                                  "order_items"
                                                                                                )[
                                                                                                  fieldName
                                                                                                ]
                                                                                                  .quantity
                                                                                          ) {
                                                                                            return Promise.reject(new Error("Location quantity must match item quantity"));
                                                                                          }
                                                                                        }
                                                                              }
                                                                            ]}
                                                                        >
                                                                            <Button
                                                                                type="primary"
                                                                                loading={
                                                                                    fetchLoading
                                                                                }
                                                                                onClick={async () => {
                                                                                  await fetchInventoryForItem(
                                                                                    fieldName
                                                                                  );
                                                                                  showInventoryLocationsModal(
                                                                                    fieldName
                                                                                  );
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                  "Select Locations"
                                                                                )}
                                                                            </Button>
                                                                        </AntForm.Item>
                                                                        <div>
                                                                            <div className="d-flex flex-column gap-2 shadow-sm p-3">
                                                                                {acceptForm
                                                                                  .getFieldValue(
                                                                                    "order_items"
                                                                                  )[fieldName].inventory_locations?.map(
                                                                                    (
                                                                                      location
                                                                                    ) => (
                                                                                            <div key={location.location} className="d-flex justify-content-between gap-2">
                                                                                                <span>
                                                                                                    {t(
                                                                                                      "Location ID"
                                                                                                    )}

                                                                                                    :{" "}
                                                                                                    {
                                                                                                        location.location
                                                                                                    }
                                                                                                </span>
                                                                                                <span>
                                                                                                    {t(
                                                                                                      "Quantity"
                                                                                                    )}

                                                                                                    :{" "}
                                                                                                    {
                                                                                                        location.quantity
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                    )
                                                                                  )}
                                                                                <div className="d-flex justify-content-end gap-2">
                                                                                    <span>
                                                                                        {t(
                                                                                          "Total Quantity"
                                                                                        )}

                                                                                        :{" "}
                                                                                        {acceptForm
                                                                                          .getFieldValue(
                                                                                            "order_items"
                                                                                          )[fieldName].inventory_locations?.reduce(
                                                                                            (
                                                                                              acc,
                                                                                              loc
                                                                                            ) =>
                                                                                              acc +
                                                                                                    loc.quantity,
                                                                                            0
                                                                                          )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <InventoryItemLocationsModal
                                                                            handleChangeInventoryLocations={(
                                                                              ...args
                                                                            ) =>
                                                                              handleChangeInventoryLocations(
                                                                                fieldName,
                                                                                ...args
                                                                              )
                                                                            }
                                                                            hideModal={() =>
                                                                              hideInventoryLocationsModal(
                                                                                fieldName
                                                                              )
                                                                            }
                                                                            count={
                                                                                acceptForm.getFieldValue(
                                                                                  "order_items"
                                                                                )[
                                                                                  fieldName
                                                                                ]
                                                                                  .location_count
                                                                            }
                                                                            page={
                                                                                acceptForm.getFieldValue(
                                                                                  "order_items"
                                                                                )[
                                                                                  fieldName
                                                                                ]
                                                                                  .location_page
                                                                            }
                                                                            fetchLoading={
                                                                                fetchLoading
                                                                            }
                                                                            inventoryItemLocations={
                                                                                acceptForm.getFieldValue(
                                                                                  "order_items"
                                                                                )[
                                                                                  fieldName
                                                                                ]
                                                                                  .inventory_item_locations
                                                                            }
                                                                            orderItem={
                                                                                acceptForm.getFieldValue(
                                                                                  "order_items"
                                                                                )[
                                                                                  fieldName
                                                                                ]
                                                                            }
                                                                            setPage={async (
                                                                              page
                                                                            ) => {
                                                                              const oldItems =
                                                                                    acceptForm.getFieldValue(
                                                                                      "order_items"
                                                                                    );
                                                                              oldItems[
                                                                                fieldName
                                                                              ].location_page =
                                                                                    page;
                                                                              acceptForm.setFieldsValue(
                                                                                {
                                                                                  order_items:
                                                                                            oldItems
                                                                                }
                                                                              );
                                                                              await fetchInventoryForItem(
                                                                                fieldName
                                                                              );
                                                                            }}
                                                                            open={
                                                                                acceptForm.getFieldValue(
                                                                                  "order_items"
                                                                                )[
                                                                                  fieldName
                                                                                ]
                                                                                  .showInventoryLocationsModal
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                      )
                                                    )}
                                                </>
                                            )}
                                        </AntForm.List>
                                    </div>
                                    {order.type ===
                                        INVENTORY_ORDER_TYPES.RECIEVE[0].toUpperCase() +
                                            INVENTORY_ORDER_TYPES.RECIEVE.slice(
                                              1
                                            ).toLowerCase() && (
                                        <Row className="mt-4">
                                            <Col sm="12" lg="6">
                                                <AntForm.Item
                                                    name="location"
                                                    label={t("Location")}
                                                >
                                                    <Select
                                                        name="location"
                                                        placeholder={t(
                                                          "Select Location"
                                                        )}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(
                                                          input,
                                                          option
                                                        ) =>
                                                          option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                              input.toLowerCase()
                                                            ) >= 0
                                                        }
                                                    >
                                                        {inventoryLocations.map(
                                                          (loc) => (
                                                                <Select.Option
                                                                    key={loc.id}
                                                                    value={
                                                                        loc.id
                                                                    }
                                                                >
                                                                    {
                                                                        loc
                                                                          .location
                                                                          .name
                                                                    }
                                                                </Select.Option>
                                                          )
                                                        )}
                                                    </Select>
                                                </AntForm.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </AntForm>
                                <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                                    {backEndErrors.map((error, ind) => (
                                        <p className="error" key={ind}>
                                            {error}
                                        </p>
                                    ))}
                                </div>

                                <div className="w-100 d-flex justify-content-end py-4">
                                    <AntForm.Item>
                                        <Button
                                            htmlType="submit"
                                            style={{
                                              backgroundColor: "#57BE6C",
                                              color: "#fff",
                                              borderColor: "#57BE6C"
                                            }}
                                            type="primary"
                                            loading={submitting}
                                            disabled={submitting}
                                            onClick={() => acceptForm.submit()}
                                        >
                                            {t("Accept Order")}
                                        </Button>
                                    </AntForm.Item>
                                </div>
                            </div>
                        )}
                    </div>
                </Row>
            </Container>
        </div>
  );
};

export default AcceptSellerCreatedOrder;
