import { Dropdown, Button } from "antd";
import { useTranslation } from "react-i18next";

const KeywordsSelector = ({ keywords, onClickKeyword }) => {
  const { t } = useTranslation();
  return (
    <Dropdown
        menu={{
          items: keywords.map(keyword => ({
            key: keyword.keyword,
            label: keyword.name
          })),
          onClick: ({ key }) => onClickKeyword(key)
        }}
        arrow={{ pointAtCenter: true }}
        placement="bottom"
        trigger={["click"]}
    >
        <Button>
            {t("Keywords")}
        </Button>
    </Dropdown>
  );
};

export default KeywordsSelector;
