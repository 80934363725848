import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Select, Checkbox, Divider, Row, Col } from "antd";
import SelectAreas from "../../select-search/AreasSelect";
import MapPicker from "../../form/MapPicker";
import { useGetBranchesMutation } from "../../../stateManagement/apis/treecodeApi";

const { Option } = Select;

export default function SellerEditAddresses ({
  seller,
  setSeller,
  isFetched,
  errors,
  setErrors
}) {
  const { t } = useTranslation();
  const addresses = seller.seller_addresses;
  const [branches, setBranches] = useState([]);
  const [getBranchesApi] = useGetBranchesMutation();

  useEffect(() => {
    const fetchBranches = async () => {
      const res = await getBranchesApi({ limit: 1000 });
      if (res.data) {
        setBranches(res.data.data);
      }
    };
    fetchBranches();
  }, [getBranchesApi]);

  const handleChange = (index, name, value) => {
    if (errors.seller_addresses?.[index]?.[name]) {
      const errorsCopy = { ...errors };
      delete errorsCopy.seller_addresses[index][name];
      setErrors(errorsCopy);
    }

    const updatedSeller = { ...seller, seller_addresses: [...seller.seller_addresses] };
    const updatedAddress = { ...updatedSeller.seller_addresses[index] };

    updatedAddress[name] = value;
    if (name === "lat" || name === "lng") {
      updatedAddress[name] = value || 0; // Ensure default value is 0
    }
    updatedAddress.isNew = !(
      updatedAddress.area &&
      updatedAddress.branch &&
      updatedAddress.address &&
      updatedAddress.lat !== null &&
      updatedAddress.lng !== null
    );

    updatedSeller.seller_addresses[index] = updatedAddress;
    setSeller(updatedSeller);
  };

  const addNewAddress = () => {
    const updatedSeller = { ...seller, seller_addresses: [...seller.seller_addresses] };
    updatedSeller.seller_addresses.push({
      branch: "",
      area: "",
      address: "",
      approved: false,
      isNew: true,
      lat: 0,
      lng: 0
    });
    setSeller(updatedSeller);
  };

  const removeAddress = (index) => {
    const updatedSeller = { ...seller };
    updatedSeller.seller_addresses.splice(index, 1);
    setSeller(updatedSeller);
  };

  return (
    <div>
      <h4>{t("sellerAddress")}</h4>
      {addresses?.map((address, index) => (
        <div key={index}>
          {index < addresses.length - 1 && <Divider />}
          <Row justify="end">
            {addresses.length > 1 && (
              <Button
                type="link"
                danger
                icon={<i className="fa fa-times" />}
                onClick={() => removeAddress(index)}
              />
            )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label>{t("branch")}</label>
              <Select
                style={{ width: "100%" }}
                value={address.branch}
                onChange={(value) => handleChange(index, "branch", value)}
              >
                {branches.map((branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
              {errors.seller_addresses?.[index]?.branch && (
                <p className="error">{errors.seller_addresses[index].branch[0]}</p>
              )}
            </Col>
            <Col span={12}>
              <label>{t("area")}</label>
              <SelectAreas
                value={address.area}
                onChange={(value) => handleChange(index, "area", value)}
                currentArea={address.area}
              />
              {errors.seller_addresses?.[index]?.area && (
                <p className="error">{errors.seller_addresses[index].area[0]}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mb-3 mt-3">
            <Col span={24}>
              <label>{t("address")}</label>
              <Input
                value={address.address}
                onChange={(e) => handleChange(index, "address", e.target.value)}
              />
              {errors.seller_addresses?.[index]?.address && (
                <p className="error">{errors.seller_addresses[index].address[0]}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mb-3 mt-3">
            <Col span={24}>
              <MapPicker
                lat={address.lat}
                lng={address.lng}
                setLat={(lat) => handleChange(index, "lat", lat)}
                setLng={(lng) => handleChange(index, "lng", lng)}
                width="100%"
                height="400px"
              />
            </Col>
          </Row>
          <Row align="middle" gutter={[16, 16]} className="mb-3 mt-3">
            <Col>
              <Checkbox
                checked={address.approved ?? false}
                onChange={(e) => handleChange(index, "approved", e.target.checked)}
              >
                {t("approved")}
              </Checkbox>
            </Col>
          </Row>
        </div>
      ))}
      <Button
        type="dashed"
        block
        icon={<i className="fas fa-plus-circle" />}
        onClick={addNewAddress}
      >
        {t("addAddress")}
      </Button>
    </div>
  );
}
