import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@shopify/polaris";
import {
  PackageIcon,
  EnvelopeSoftPackIcon,
  CircleLeftIcon
} from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import CustomerInfoForm from "../../../../../components/forms/customerInfoForm";
import ProductInfoForm from "../../../../../components/forms/productInfoForm";
import OrderInfoForm from "../../../../../components/forms/orderInfoForm";
import { addOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { SuccessfulRequest } from "../../../../../components/modals/confirmationModal";
import { useUserObject } from "../../../../../stateManagement/slices/userSlice";

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const CreateOrderTabs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clearDispatch = useDispatch();
  const cards = [
    {
      icon: PackageIcon,
      title: `${t("parcel")}`,
      description: `${t("orgSubTitle")}`,
      method: () => {
        navigate("/create_order/parcel");
      }
    },

    {
      icon: EnvelopeSoftPackIcon,
      title: `${t("bulk")}`,
      description: `${t("preferencesSubTitle")}`,
      method: () => {
        navigate("/create_order/bulk");
      }
    }
  ];
  const [value, setValue] = useState(0);
  // const [selectedProduct, setSelectedProduct] = useState([]);
  const [type, setType] = useState("");
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");

  const hide = () => {
    setShow(false);
    navigate("/dashboard");
  };
  const handleChange = (event, newValue) => {
    setValue(parseInt(newValue));
    setMsg("");
  };

  // const selectedSeller = useSelector(
  //   (state) => state.selectedData.selectedSeller
  // );

  const user = useUserObject();

  const selectType = (type) => {
    setType(type);
  };

  return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.tickets")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t("sideMenu.createNewTicket")}
                    </span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    {type !== ""
                      ? (
                        <div
                            className="back"
                            onClick={() => {
                              selectType("");
                              clearDispatch(addOrder(""));
                            }}
                        >
                            <Icon source={CircleLeftIcon} color="base" />
                        </div>
                        )
                      : null}
                    <h2 className="pageTitle">
                        {t("sideMenu.createNewTicket")}
                    </h2>
                </Col>
            </Row>
            {
            type === ""
              ? (
                <>
                    <Row>
                        {cards.map((card) => {
                          return (
                                <Col key={card.title} sm="6">
                                    <div className="setting-card">
                                        <div className="setting-icon">
                                            <Icon
                                                source={card.icon}
                                                color="base"
                                            />
                                        </div>
                                        <div>
                                            <p
                                                style={{ fontSize: "18px" }}
                                                className="setting-card-title"
                                                target="_blank"
                                                onClick={() => card.method()}
                                            >
                                                {card.title}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                          );
                        })}
                    </Row>
                </>
                )
              : type === "parcel"
                ? (

                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        {
                        user.role === "Seller"
                          ? (
                            <>
                                <Tabs
                                    value={value}
                                    // onChange={handleChange}
                                    aria-label="basic tabs example"
                                    className={`${
                                        user.role === "Seller"
                                            ? "seller"
                                            : "admin"
                                    }`}
                                >
                                    <Tab
                                        label={t("customerInfo")}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        label={t("ticketInfo")}
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label={t("orderInfo")}
                                        {...a11yProps(2)}
                                    />
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <CustomerInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    <ProductInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>

                                <TabPanel value={value} index={2}>
                                    <OrderInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>
                            </>
                            )
                          : (
                              undefined
                            )}
                    </Box>
                </Box>
                  )
                : undefined}

            <SuccessfulRequest
                body={msg}
                title={t("bulkSuccessTitle")}
                show={show}
                hide={hide}
            />
        </div>
  );
};

export default CreateOrderTabs;
