import { createAction } from "@reduxjs/toolkit";
import { LOGIN, CLEAR_AUTH } from "./constants";

const loginAction = createAction(LOGIN);
const clearAuthAction = createAction(CLEAR_AUTH);
const refreshTokenAction = createAction("REFRESH_TOKEN");
const changeLanguageAction = createAction("CHANGE_LANGUAGE");
const navigateTo = createAction("NAVIGATE_TO");

export { loginAction, clearAuthAction, changeLanguageAction, navigateTo, refreshTokenAction };
