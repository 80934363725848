import { useTranslation } from "react-i18next";
import { DatePicker, Input, InputNumber, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGetOrderTypesMutation } from "../../../stateManagement/apis/treecodeApi";

export default function ({ seller, setSeller, isFetched, errors, setErrors }) {
  const { t } = useTranslation();
  const [getOrderTypes, { isLoading: isLoadingOrderTypes }] = useGetOrderTypesMutation();
  const [orderTypes, setOrderTypes] = useState([]);
  const pricing = seller.seller_pricing ?? {};
  const tiers = seller.seller_pricing?.seller_tiers_prices ?? [];
  const baseFields = [
    {
      label: t("cod_fees"),
      name: "cod_fees",
      selectOptions: [
        {
          label: t("fixed"),
          value: "1"
        },
        {
          label: t("percentage"),
          value: "2"
        },
        {
          label: t("free"),
          value: "4"
        }
      ]
    },
    {
      label: t("pos_fees"),
      name: "pos_fees",
      selectOptions: [
        {
          label: t("fixed"),
          value: "1"
        },
        {
          label: t("percentage"),
          value: "2"
        },
        {
          label: t("free"),
          value: "4"
        }
      ]
    },
    {
      label: t("return_fees"),
      name: "return_fees",
      selectOptions: [
        {
          label: t("fixed"),
          value: "1"
        },
        {
          label: t("percentage"),
          value: "2"
        }
      ]
    },
    {
      label: t("reverse_shipment_fees"),
      name: "reverse_shipment_fees",
      selectOptions: [
        {
          label: t("fixed"),
          value: "1"
        },
        {
          label: t("percentage"),
          value: "2"
        },
        {
          label: t("same"),
          value: "3"
        }
      ]
    }
  ];

  const fields = [
    {
      label: t("first_100"),
      name: "first_100"
    },
    {
      label: t("up_to_500"),
      name: "up_to_500"
    },
    {
      label: t("above_500"),
      name: "above_500"
    }
  ];

  const fetchOrderTypes = async () => {
    const response = await getOrderTypes({
      limit: 1000
    });
    setOrderTypes(response.data.data);
  };

  useEffect(() => {
    fetchOrderTypes();
  }, []);

  const onPriceChange = ({ name, index, targetModelIdx, value }) => {
    const newSeller = {
      ...seller,
      seller_pricing: {
        ...seller.seller_pricing,
        seller_tiers_prices: seller.seller_pricing.seller_tiers_prices.map((tier, idx) => {
          if (idx === index) {
            return {
              ...tier,
              model_prices: tier.model_prices.map((model, modelIdx) => {
                if (modelIdx === targetModelIdx) {
                  return {
                    ...model,
                    [name]: value ?? 0
                  };
                }
                return model;
              })
            };
          }
          return tier;
        })
      }
    };
    setSeller(newSeller);
  };
  const onBaseChange = (name, value) => {
    if (errors[name]) {
      const errorsCopy = { ...errors };
      delete errorsCopy[name];
      setErrors(errorsCopy);
    }

    const newSeller = {
      ...seller,
      seller_pricing: {
        ...seller.seller_pricing,
        [name]: value < 0 ? 0 : value
      }
    };
    setSeller(newSeller);
  };

  const onDiscountChange = (name, value) => {
    if (errors[name]) {
      const errorsCopy = { ...errors };
      delete errorsCopy[name];
      setErrors(errorsCopy);
    }

    const newSeller = {
      ...seller,
      seller_pricing: {
        ...seller.seller_pricing,
        seller_discount: {
          ...seller.seller_pricing.seller_discount,
          [name]: value
        }
      }
    };
    setSeller(newSeller);
  };
  return (
        <div>
            <h4>{t("pricing")}</h4>
            <div>
                <div
                    className={"grid-form"}
                    style={{
                      marginBottom: "32px"
                    }}
                >
                                      <div>
                        <label>{t("Order Type")}</label>
                        <Select
                            value={seller.order_type}
                            loading={isLoadingOrderTypes}
                            onChange={(v) =>
                              setSeller({ ...seller, order_type: v })
                            }
                            options={orderTypes.map((ot) => ({
                              label: ot.name,
                              value: ot.id
                            }))}
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            optionFilterProp="label"
                            placeholder={t("Select Order Type")}
                            allowClear
                        />
                    </div>
                    {baseFields.map((field, i) => {
                      const name =
                            pricing[`${field.name}_type`] === "2"
                              ? `${field.name}_percentage`
                              : field.name;
                      const val = pricing[name];
                      return (
                            <div key={i}>
                                <label>{field.label}</label>
                                <div
                                    style={{
                                      display: "flex",
                                      gap: "16px",
                                      alignItems: "center"
                                    }}
                                >
                                    <Select
                                        value={pricing[`${field.name}_type`]}
                                        onChange={(v) =>
                                          onBaseChange(
                                                `${field.name}_type`,
                                                v
                                          )
                                        }
                                        defaultValue="1"
                                    >
                                        {field.selectOptions.map((option) => (
                                            <Select.Option
                                                value={option.value}
                                                key={option.value}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <InputNumber
                                        disabled={
                                            pricing[`${field.name}_type`] > 2
                                        }
                                        style={{ width: "100%" }}
                                        value={
                                            pricing[`${field.name}_type`] > 2
                                              ? 0
                                              : val
                                        }
                                        onChange={(v) => onBaseChange(name, v)}
                                        type="number"
                                        min={0}
                                        name={name}
                                    />
                                </div>
                                {errors[name]
                                  ? (
                                    <p className="error">{errors[name][0]}</p>
                                    )
                                  : (
                                      isFetched &&
                                    val < 0 && (
                                        <p className="error">
                                            {t("requiredField")}
                                        </p>
                                      )
                                    )}
                            </div>
                      );
                    })}
                    <div>
                        <label>{t("extra_shipment_weight")}</label>
                        <InputNumber
                            style={{ width: "100%" }}
                            value={pricing.extra_shipment_weight}
                            onChange={(v) =>
                              onBaseChange("extra_shipment_weight", v)
                            }
                            type="number"
                            min={1}
                            name={"extra_shipment_weight"}
                        />
                        {errors.extra_shipment_weight
                          ? (
                            <p className="error">
                                {errors.extra_shipment_weight[0]}
                            </p>
                            )
                          : (
                              isFetched &&
                            !pricing.extra_shipment_weight && (
                                <p className="error">{t("requiredField")}</p>
                              )
                            )}
                    </div>
                </div>
                <Discount
                    onDiscountChange={onDiscountChange}
                    pricing={pricing}
                    errors={errors}
                />
                {tiers.map((tier, index) => (
                    <div
                      key={index}
                        style={
                            index === tiers.length - 1
                              ? {}
                              : {
                                  paddingBottom: "24px",
                                  marginBottom: "24px",
                                  borderBottom: "1px solid #ccc"
                                }
                        }
                    >
                        <h5>
                            {t("tier")} {index + 1}
                        </h5>
                        <TimeOption
                            isFetched={isFetched}
                            tier={tier}
                            title={t("same_day")}
                            targetModelIdx={0}
                            fields={fields}
                            tierIndex={index}
                            onPriceChange={onPriceChange}
                        />
                        <TimeOption
                            isFetched={isFetched}
                            tier={tier}
                            targetModelIdx={1}
                            title={t("next_day")}
                            fields={fields}
                            tierIndex={index}
                            onPriceChange={onPriceChange}
                        />
                    </div>
                ))}
            </div>
        </div>
  );
}

function Discount ({ pricing, onDiscountChange, errors }) {
  const { t } = useTranslation();
  return (
        <div
            style={{
              marginBottom: "32px"
            }}
        >
            <h5>{t("discount")}</h5>
            <div className={"grid-form"}>
                <div>
                    <label>{t("discount_percentage")}</label>
                    <InputNumber
                        style={{ width: "100%" }}
                        value={pricing?.seller_discount?.discount_percentage}
                        onChange={(v) =>
                          onDiscountChange("discount_percentage", v)
                        }
                        type="number"
                        min={1}
                        name={"discount_percentage"}
                    />
                    {errors.discount_percentage && (
                        <p className="error">
                            {errors.discount_percentage[0]}
                        </p>
                    )}
                </div>
                <div>
                    <label>{t("from_date")}</label>
                    <DatePicker
                        style={{ width: "100%" }}
                        value={
                            pricing?.seller_discount?.from_date
                              ? moment(pricing?.seller_discount?.from_date)
                              : null
                        }
                        onChange={(v) =>
                          onDiscountChange(
                            "from_date",
                            v.format("YYYY-MM-DD")
                          )
                        }
                        name={"from_date"}
                    />
                    {errors.from_date && (
                        <p className="error">{errors.from_date[0]}</p>
                    )}
                </div>
                <div>
                    <label>{t("to_date")}</label>
                    <DatePicker
                        style={{ width: "100%" }}
                        value={
                            pricing?.seller_discount?.to_date
                              ? moment(pricing?.seller_discount?.to_date)
                              : null
                        }
                        onChange={(v) =>
                          onDiscountChange("to_date", v.format("YYYY-MM-DD"))
                        }
                        name={"to_date"}
                    />
                    {errors.to_date && (
                        <p className="error">{errors.to_date[0]}</p>
                    )}
                </div>
                <div>
                    <label>{t("target_orders_volume")}</label>
                    <InputNumber
                        style={{ width: "100%" }}
                        value={pricing?.seller_discount?.target_orders_volume}
                        onChange={(v) =>
                          onDiscountChange("target_orders_volume", v)
                        }
                        type="number"
                        min={1}
                        name={"target_orders_volume"}
                    />
                    {errors.target_orders_volume && (
                        <p className="error">
                            {errors.target_orders_volume[0]}
                        </p>
                    )}
                </div>
                <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px"
                    }}
                >
                    <label>{t("active")}</label>
                    <Input
                        type="checkbox"
                        style={{ width: "fit-content" }}
                        checked={pricing?.seller_discount?.active}
                        onChange={(e) =>
                          onDiscountChange("active", e.target.checked)
                        }
                        name={"active"}
                    />
                    {errors.active && (
                        <p className="error">{errors.active[0]}</p>
                    )}
                </div>
            </div>
        </div>
  );
}

function TimeOption ({
  tier,
  title,
  fields,
  targetModelIdx,
  tierIndex,
  onPriceChange,
  isFetched
}) {
  const { t } = useTranslation();
  return (
        <div>
            <h6
                style={{
                  fontWeight: "600",
                  marginTop: "20px"
                }}
            >
                {title}
            </h6>
            <div className="grid-form">
                {fields.map((field, i) => {
                  return (
                        <div key={i}>
                            <label>{field.label}</label>
                            <InputNumber
                                style={{ width: "100%" }}
                                value={tier.model_prices[targetModelIdx][field.name]}
                                onChange={(v) =>
                                  onPriceChange({
                                    name: field.name,
                                    index: tierIndex,
                                    targetModelIdx,
                                    value: v ?? ""
                                  })
                                }
                                name={field.name + "_" + targetModelIdx + "_" + tierIndex}
                            />
                            {isFetched && !tier.model_prices[targetModelIdx][field.name] && (
                                <p className="error">{t("requiredField")}</p>
                            )}
                        </div>
                  );
                })}
            </div>
        </div>
  );
}
