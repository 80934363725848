import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateNewProduct from "../../../../components/modals/CreateNewProduct";
import { SuccessfulRequest } from "../../../../components/modals/confirmationModal";
import useLanguage from "../../../../hooks/useLanguage";

export default function CreateProduct () {
  const language = useLanguage();
  const navigate = useNavigate();
  const [created, setCreated] = useState(false);
  return (
        <>
            <CreateNewProduct useModal={false} setCreated={setCreated} />
            <SuccessfulRequest
                show={created}
                hide={() => {
                  setCreated(false);
                  setTimeout(() => navigate("/products"), 100);
                }}
                title={language.includes("en") ? "Add Product" : "اضافة منتج"}
                body={
                    language.includes("en")
                      ? "Product Created Successfully"
                      : "تم اضافة المنتج بنجاح"
                }
            />
        </>
  );
}
