const LOCATION_TYPES = {
  SOH: "Stock On Hand",
  SKU: "Stock Keeping Unit",
  PRODUCT: "Product",
  PRODUCT_CATEGORY: "Product Category"
};

const RULE_TYPES = {
  Product: "Product",
  "Product Category": "Product Category",
  "Product Price": "Product Price",
  Customer: "Customer",
  "Inventory Status": "Inventory Status"
};

const COMPARISON_OPERATORS = {
  "=": "=",
  "!=": "!=",
  ">": ">",
  "<": "<",
  ">=": ">=",
  "<=": "<=",
  Contains: "Contains",
  "Not Contains": "Not Contains"
};

export { LOCATION_TYPES, RULE_TYPES, COMPARISON_OPERATORS };
