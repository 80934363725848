import { createApi } from "@reduxjs/toolkit/query/react";
import { INVENTORY_API, WAREHOUSES_API } from "../constants";
import { INVENTORY_ORDER_STATUS } from "../../utils/beDict";
import { reAuthBaseQuery } from "./baseQuery";

export const inventoryApi = createApi({
  reducerPath: "inventoryApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["Inventory", "Warehouses"],
  endpoints: (builder) => ({
    getAllOrders: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: INVENTORY_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Inventory"]
    }),
    getCompletedOrders: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: INVENTORY_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          status: INVENTORY_ORDER_STATUS.COMPLETED,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Inventory"]
    }),
    getUnCompletedOrders: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: INVENTORY_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          status: INVENTORY_ORDER_STATUS.APPROVED,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Inventory"]
    }),
    getRejectedOrders: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: INVENTORY_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          status: INVENTORY_ORDER_STATUS.REJECTED,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Inventory"]
    }),
    getPendingOrders: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: INVENTORY_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          status: INVENTORY_ORDER_STATUS.PENDING,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Inventory"]
    }),
    getOrder: builder.mutation({
      query: (id) => ({
        url: `${INVENTORY_API.ROOT}/${id}/`,
        method: "GET"
      }),
      invalidatesTags: ["Inventory"]
    }),
    completeOrder: builder.mutation({
      query: (id) => ({
        url: `${INVENTORY_API.ROOT}/${id}/${INVENTORY_API.COMPLETE_ORDER}`,
        method: "GET"
      }),
      invalidatesTags: ["Inventory"]
    }),
    cancelOrder: builder.mutation({
      query: (id) => ({
        url: `${INVENTORY_API.ROOT}/${id}/${INVENTORY_API.CANCEL_ORDER}`,
        method: "PUT"
      }),
      invalidatesTags: ["Inventory"]
    }),
    createOrder: builder.mutation({
      query: (body) => ({
        url: INVENTORY_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Inventory"]
    }),
    acceptSellerCreateOrder: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${INVENTORY_API.ROOT}/${id}/${INVENTORY_API.ACCEPT_SELLER_ORDER}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Inventory"]
    }),
    getWarehouses: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: WAREHOUSES_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Warehouses"]
    }),
    getWarehouse: builder.mutation({
      query: (id) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/`,
        method: "GET"
      }),
      providesTags: ["Warehouses"]
    }),
    getAllLocations: builder.mutation({
      query: ({ page = 1, limit = 1000, ...queryParameters }) => ({
        url: `${WAREHOUSES_API.ROOT}/${WAREHOUSES_API.ALL_LOCATIONS}`,
        method: "GET",
        params: {
          page_size: limit,
          page,
          ...queryParameters
        }
      }),
      providesTags: ["Warehouses"]
    }),
    createWarehouse: builder.mutation({
      query: (body) => ({
        url: WAREHOUSES_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Warehouses"]
    }),
    updateWarehouse: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Warehouses"]
    }),
    deleteWarehouse: builder.mutation({
      query: (id) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/`,
        method: "DELETE"
      }),
      invalidatesTags: ["Warehouses"]
    }),
    getWarehouseLocations: builder.mutation({
      query: (id, { page = 1, limit = 10, ...queryParameters } = {}) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.LOCATIONS}/`,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Warehouses"]
    }),
    getWarehouseLocation: builder.mutation({
      query: ({ id, locationId }) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.LOCATIONS}/${locationId}/`,
        method: "GET"
      }),
      providesTags: ["Warehouses"]
    }),

    createWarehouseLocation: builder.mutation({
      query: ({ id, body }) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.ADD_LOCATION}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Warehouses"]
    }),

    updateWarehouseLocation: builder.mutation({
      query: ({ id, locationId, ...body }) => {
        if (!id || !locationId) {
          throw new Error("Missing required parameters: id or locationId.");
        }
        return {
          url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.UPDATE_LOCATION}/${locationId}/`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: ["Warehouses"]
    }),

    deleteWarehouseLocation: builder.mutation({
      query: (id, locationId) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.DELETE_LOCATION}/${locationId}/`,
        method: "DELETE"
      }),
      invalidatesTags: ["Warehouses"]
    }),
    activateWarehouseLocation: builder.mutation({
      query: ({ id, locationId }) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.ACTIVATE_LOCATION}/${locationId}/`,
        method: "POST"
      }),
      invalidatesTags: ["Warehouses"]
    }),
    deactivateWarehouseLocation: builder.mutation({
      query: ({ id, locationId }) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.DEACTIVATE_LOCATION}/${locationId}/`,
        method: "POST"
      }),
      invalidatesTags: ["Warehouses"]
    }),

    getWarehouseInventoryRules: builder.mutation({
      query: (id, { page = 1, limit = 10, ...queryParameters } = {}) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.INVENTORY_ROLES}/`,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Warehouses"]
    }),
    getWarehouseInventoryRule: builder.mutation({
      query: ({ id, ruleId }) => {
        console.log("Constructing API URL with:", { id, ruleId }); // Debugging log
        return {
          url: `${WAREHOUSES_API.ROOT}/${id || "missing-id"}/${WAREHOUSES_API.INVENTORY_ROLES}/${ruleId || "missing-ruleId"}/`,
          method: "GET"
        };
      },
      providesTags: ["Warehouses"]
    }),
    createWarehouseInventoryRule: builder.mutation({
      query: ({ id, body }) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.ADD_INVENTORY_ROLE}/`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Warehouses"]
    }),
    updateWarehouseInventoryRule: builder.mutation({
      query: ({ id, ruleId, ...body }) => {
        if (!ruleId) {
          throw new Error("ruleId is required for updating inventory rules");
        }
        console.log("Query parameters:", { id, ruleId, body }); // Debug log
        return {
          url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.UPDATE_INVENTORY_ROLE}/${ruleId}/`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: ["Warehouses"]
    }),
    deleteWarehouseInventoryRule: builder.mutation({
      query: (id, ruleId) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.DELETE_INVENTORY_ROLE}/${ruleId}/`,
        method: "DELETE"
      }),
      invalidatesTags: ["Warehouses"]
    }),
    getWarehouseInventories: builder.mutation({
      query: (id, { page = 1, limit = 10, ...queryParameters }) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.INVENTORIES}`,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Warehouses"]
    }),
    getWarehouseInventoriesSummary: builder.mutation({
      query: (id) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.INVENTORIES_SUMMARY}`,
        method: "GET"
      }),
      providesTags: ["Warehouses"]
    }),
    getWarehouseLocationInventories: builder.mutation({
      query: (id, locationId, { page = 1, limit = 10, ...queryParameters }) => ({
        url: `${WAREHOUSES_API.ROOT}/${id}/${WAREHOUSES_API.LOCATIONS}/${locationId}/${WAREHOUSES_API.INVENTORIES}`,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Warehouses"]
    }),
    inventoryActivateSwitch: builder.mutation({
      query: () => ({
        url: INVENTORY_API.ACTIVATE_SWITCH,
        method: "POST"
      }),
      invalidatesTags: ["Inventory"]
    }),
    getInventoryItems: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: INVENTORY_API.INVENTORY_ITEMS + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Inventory"]
    }),
    getInventoryItemAvailability: builder.mutation({
      query: (inventoryItemId, orderType = "Ticket", { page = 1, limit = 10, ...queryParameters }) => ({
        url: `${INVENTORY_API.INVENTORY_ITEMS}/${inventoryItemId}/${INVENTORY_API.AVAILABLE_INVENTORY_ITEM_INVENTORIES}/${
            orderType.toUpperCase()
        }/`,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Inventory"]
    }),
    getInventories: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: INVENTORY_API.INVENTORIES + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Inventory"]
    }),
    getInventory: builder.mutation({
      query: (id) => ({
        url: `${INVENTORY_API.INVENTORIES}/${id}/`,
        method: "GET"
      }),
      providesTags: ["Inventory"]
    }),
    getInventoriesSummary: builder.mutation({
      query: (queryParameters) => ({
        url: INVENTORY_API.INVENTORIES_SUMMARY + "/",
        method: "GET",
        params: queryParameters
      }),
      providesTags: ["Inventory"]
    }),
    acceptInventoryOrders: builder.mutation({
      query: (body) => ({
        url: INVENTORY_API.ACCEPT_INVENTORY,
        method: "POST",
        body
      }),
      invalidatesTags: ["Inventory"]
    }),
    rejectInventoryOrders: builder.mutation({
      query: (body) => ({
        url: INVENTORY_API.REJECT_INVENTORY,
        method: "POST",
        body
      }),
      invalidatesTags: ["Inventory"]
    })

  })
});

export const {
  useGetCompletedOrdersMutation,
  useGetUnCompletedOrdersMutation,
  useGetRejectedOrdersMutation,
  useGetPendingOrdersMutation,
  useGetOrderMutation,
  useCompleteOrderMutation,
  useCancelOrderMutation,
  useCreateOrderMutation,
  useAcceptSellerCreateOrderMutation,
  useGetWarehousesMutation,
  useGetWarehouseMutation,
  useGetAllLocationsMutation,
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useDeleteWarehouseMutation,
  useGetWarehouseLocationsMutation,
  useGetWarehouseLocationMutation,
  useCreateWarehouseLocationMutation,
  useUpdateWarehouseLocationMutation,
  useDeleteWarehouseLocationMutation,
  useActivateWarehouseLocationMutation,
  useDeactivateWarehouseLocationMutation,
  useGetWarehouseInventoryRulesMutation,
  useGetWarehouseInventoryRuleMutation,
  useCreateWarehouseInventoryRuleMutation,
  useUpdateWarehouseInventoryRuleMutation,
  useDeleteWarehouseInventoryRuleMutation,
  useGetWarehouseInventoriesMutation,
  useGetWarehouseInventoriesSummaryMutation,
  useGetWarehouseLocationInventoriesMutation,
  useInventoryActivateSwitchMutation,
  useGetInventoryItemsMutation,
  useGetInventoryItemAvailabilityMutation,
  useGetInventoriesMutation,
  useGetInventoryMutation,
  useGetAllOrdersMutation,
  useGetInventoriesSummaryMutation,
  useAcceptInventoryOrdersMutation,
  useRejectInventoryOrdersMutation
} = inventoryApi;
