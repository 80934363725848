import React from "react";
import styles from "./TableComponent.module.css";

export default function TableColumnsHeader ({
  onSelect,
  columns,
  actions,
  actionsTitle
}) {
  return (
        <thead>
            <tr>
                {onSelect && (
                    <th
                        className={[
                          styles.selectable,
                          "row-centered-horizontal"
                        ].join(" ")}
                    >
                        <span
                            className={[
                              styles.checkboxContainer,
                              "row-centered"
                            ].join(" ")}
                        >
                            <input type="checkbox" />
                        </span>
                        <span>ID</span>
                    </th>
                )}
                <th style={{ width: "36px" }} width={36}>

                </th>
                {columns.map((it, index) => (
  <th key={it.id || it.name || index}>{it.name}</th>
                ))}

                {actions && (
                    <th className={styles.actionsHeader}>
                        {actionsTitle ?? "Actions"}
                    </th>
                )}
            </tr>
        </thead>
  );
}
