import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  selectedUser: ""
};

const selectedUserSlice = createSlice({
  name: "selectedUser",
  initialState,
  reducers: {
    getSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    }
  }
});

export const { getSelectedUser } = selectedUserSlice.actions;
export const useSelectedUser = () => useSelector((state) => state.selectedUser.selectedUser);

export default selectedUserSlice.reducer;
