import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {};

const codeSlice = createSlice({
  name: "code",
  initialState,
  reducers: {
    code: (state, action) => {
      state.code = action.payload;
    }
  }
});

export const { code } = codeSlice.actions;
export const useCode = () => useSelector((state) => state.code.code);

export default codeSlice.reducer;
