import { useTranslation } from "react-i18next";
import { Typography, Row, Col, Spin, Card } from "antd";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useValidateTransactionMutation } from "../../../stateManagement/apis/subscriptionApi";
import { notifyErrors, stringErrorsHandler } from "../../../api/ErrorNotifier";

const { Title, Text } = Typography;

const PaymentResult = () => {
  const { t } = useTranslation();
  const { transactionId } = useParams();
  const [validateTransaction, { isLoading }] = useValidateTransactionMutation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleValidateTransaction = async () => {
    const result = await validateTransaction(transactionId);
    if (result.data) {
      setSuccess(true);
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    } else if (result.error?.data) {
      setError(stringErrorsHandler(result.error.data));
    } else {
      notifyErrors("Failed to validate transaction");
      setError("Failed to validate transaction");
    }
  };

  useEffect(() => {
    handleValidateTransaction();
  }, []);

  return (
        <div>
            <Row justify="center">
                <Col span={24}>
                    <Card>
                        <Row justify="center">
                            <Col>
                                <Title level={3}>
                                    {t("Payment Result")}
                                </Title>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                {isLoading && <Spin />}
                                {error && <Text type="danger">{error}</Text>}
                                {success && <Text type="success">{t("Payment successful")}</Text>}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
  );
};

export default PaymentResult;
