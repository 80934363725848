import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../images/close.svg";
import styles from "./ArchivedCourierCard.module.css";

export default function ArchivedCourierCard ({ current, onClose }) {
  const { t } = useTranslation();

  return (
        <>
            {current && (
                <div className={[styles.container, current ? "ModalOpen" : "ModalClosed"].join(" ")} >
                    <div className={styles.background} onClick={onClose}/>

                    <div className={styles.card}>
                        <div className={styles.close} onClick={onClose}>
                            <img src={CloseIcon} alt="close" />
                        </div>
                        <h1>
                            {t("courier_id")} #{"88888"}
                        </h1>
                        <Form layout="vertical">
                            {[
                              {
                                label: t("name"),
                                value: current.user.name
                              },
                              {
                                label: t("phone"),
                                value: current.user.phone
                              },
                              {
                                label: t("phone"),
                                value: current.user.phone_alt ?? t("no_alt_phone")
                              },
                              {
                                label: t("city"),
                                value: current.user.city
                              },
                              {
                                label: t("typeofvehicles"),
                                value: current.user.vehicle
                              }
                            ].map((it, i) => (
                                <Form.Item
                                    className={"marginless-label-item"}
                                    key={i}
                                    label={it.label}
                                >
                                    <Input disabled value={it.value} />
                                </Form.Item>
                            ))}
                        </Form>
                    </div>
                </div>
            )}
        </>
  );
}
