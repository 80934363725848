import React, { useEffect, useState } from "react";
import { notification, Modal, Row, Col, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useArchivedOrdersColumns } from "../../../components/layout/tableColumns";
import { useGetArchivedOrdersMutation, useDeleteArchivedOrderMutation } from "../../../stateManagement/apis/ordersApi";
import TablePro from "../../../components/tables/TablePro";
import { notifyErrors } from "../../../api/ErrorNotifier";
import "./acrchivedTable.css"; // Assume modernized CSS file

const { Title } = Typography;

const ArchivedOrders = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [getArchivedOrders] = useGetArchivedOrdersMutation();
  const [deleteArchivedOrder] = useDeleteArchivedOrderMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Fetch archived orders data
  const fetchData = async (currentPage = 1) => {
    try {
      const result = await getArchivedOrders({ page: currentPage, limit: 10 });
      if (result.data) {
        setData(result.data.data);
        setTotal(result.data.count);
      } else {
        throw new Error(t("error_fetching_data"));
      }
    } catch (error) {
      notifyErrors(error.message || t("error_fetching_data"));
    }
  };

  // Handle send request after confirmation
  const sendRequest = async (record) => {
    const confirmed = await showCustomModal(t("archivedOrders_sendRequest"));
    if (confirmed) {
      navigate("/create_order/parcel", { state: { archivedData: record.archived_data } });
    }
  };

  // Show modern custom modal for confirmation
  const showCustomModal = (text) =>
    new Promise((resolve) => {
      Modal.confirm({
        title: t("confirmation"),
        content: <span style={{ fontSize: "16px" }}>{text}</span>,
        okText: t("confirm"),
        cancelText: t("cancel"),
        centered: true,
        width: 400,
        style: { borderRadius: 12, overflow: "hidden" },
        okButtonProps: {
          style: { backgroundColor: "#4caf50", borderColor: "#4caf50", borderRadius: 8 }
        },
        cancelButtonProps: {
          style: { borderRadius: 8 }
        },
        onOk: () => resolve(true),
        onCancel: () => resolve(false)
      });
    });

  // Delete archived order
  const deleteArchive = async (record) => {
    try {
      const response = await deleteArchivedOrder(record.id);
      if (response.error) {
        notifyErrors(response.error?.data || t("error_deleting_order"));
      } else {
        notification.success({
          message: t("order_deleted"),
          description: `${t("order")} ${record.id} ${t("deletion_successful")}`
        });
        fetchData(page);
      }
    } catch (error) {
      notifyErrors(t("error_deleting_order"));
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData(page);
  }, [page]);

  // Get table columns
  const archivedColumns = useArchivedOrdersColumns(sendRequest, deleteArchive);

  return (
    <div className="archived-orders-container">
      <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.tickets")}</span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("sideMenu.archived")}</span>
                    </Col>
                </Row>
                <Row justify="space-between" align="middle" className="mb-4">
                    <Col>
                        <Title level={2} className="page-title">{t("sideMenu.archived")}</Title>
                    </Col>
                </Row>

      <Row>
        <Col span={24}>
          <TablePro
            dataSource={data}
            columns={archivedColumns}
            pagination={{
              current: page,
              total,
              onChange: setPage
            }}
            rowClassName="responsive-row" // Responsive styles for rows
          />
        </Col>
      </Row>
    </div>
  );
};

export default ArchivedOrders;
