import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/form.css";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PaginatedSelect, Radio, TextField } from "../form/TextInputFields";
import ModifiedForm from "../form/ModifiedForm";
import { addOrder, useOrder } from "../../stateManagement/slices/addOrderSlice";
import { PaymentAlert } from "../modals/confirmationModal";
import { useUserObject } from "../../stateManagement/slices/userSlice";
import { useSelectedSeller } from "../../stateManagement/slices/selectedSellerSlice";
import { usePlan } from "../../stateManagement/slices/planSlice";
import { useFilterSellersMutation } from "../../stateManagement/apis/accountsApi";

export default function SellerInfoForm ({ value, handleChange, returnedObj }) {
  const dispatch = useDispatch();
  const user = useUserObject();
  const { t } = useTranslation();

  const [sellers, setSellers] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [showPaymentAlert, setPaymentAlert] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSellersApi] = useFilterSellersMutation();

  const selectedSeller = useSelectedSeller();

  const order = useOrder();

  const plan = usePlan();

  const returnedTickets = returnedObj ?? [];

  console.log({ returnedTickets });

  const getSellers = async (clearOld = false) => {
    const result = await filterSellersApi({ page, search: searchTerm });
    if (result) {
      if (clearOld) {
        setSellers(result.data.data);
      } else {
        setSellers([...sellers, ...result.data.data]);
      }
      if (result.data.next === null) {
        setLoadMore(false);
      } else {
        setPage(page + 1);
      }
    }
  };

  const onScroll = async (event) => {
    console.log("ee");
    const target = event.target;
    if (
      loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      await getSellers();
    }
  };
  const validate = (data) => {
    // console.log(formState.isValid)
    console.log(data);
    console.log(selectedSeller);
    if (
      data.seller === undefined &&
            selectedSeller === "" &&
            returnedTickets.length === 0
    ) {
      setErrMsg(t("requiredField"));
    } else {
      dispatch(addOrder(data));
      setErrMsg("");
      handleChange(null, value + 1);
    }
  };

  useEffect(() => {
    getSellers(true);
  }, [searchTerm]);
  useEffect(() => {
    setErrMsg("");
  }, [selectedSeller]);

  useEffect(() => {
    if (plan?.orders_count >= plan?.orders_remaining) {
      setPaymentAlert(true);
    }
  }, [plan]);
  return (
        <ModifiedForm submitFunction={validate}>
            <>
                <Row>
                    <Col sm="12" md="6">
                        <div className="text-area-form">
                            {
                            returnedTickets.length === 0
                              ? (
                                <PaginatedSelect
                                    id="seller"
                                    title={t("sellername")}
                                    search={(value) => {
                                      setPage(1);
                                      setSearchTerm(value);
                                    }}
                                    onScroll={onScroll}
                                    choice={selectedSeller?.id ?? null}
                                >
                                    {
                                        // return sellers[index]
                                        sellers.map((seller) => (
                                            <Select.Option
                                                key={seller.id}
                                                sellerinfo={seller}
                                                value={seller.id}
                                            >
                                                {seller.user.name}
                                            </Select.Option>
                                        ))
                                    }
                                </PaginatedSelect>
                                )
                              : (
                                <TextField
                                    id="dq"
                                    className="formTextField text-area"
                                    title={t("sellername")}
                                    value={
                                        selectedSeller !== ""
                                          ? selectedSeller.user.name
                                          : ""
                                    }
                                    name={
                                        selectedSeller !== ""
                                          ? selectedSeller.user.name
                                          : ""
                                    }
                                />
                                )}

                            <p className="error">
                                {errMsg !== "" ? errMsg : null}
                            </p>
                            <TextField
                                id="dq"
                                className="formTextField text-area"
                                title={t("storename")}
                                value={
                                    selectedSeller !== ""
                                      ? selectedSeller.store_name
                                      : ""
                                }
                                name={
                                    selectedSeller !== ""
                                      ? selectedSeller.store_name
                                      : ""
                                }
                            />
                            <>
                                <TextField
                                    id="dq"
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    value={
                                        selectedSeller !== ""
                                          ? `${selectedSeller?.user?.phone}`
                                          : ""
                                    }
                                    name={
                                        selectedSeller !== ""
                                          ? `${selectedSeller?.user?.phone}`
                                          : ""
                                    }
                                />{" "}
                                <TextField
                                    id="dqw"
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    value={
                                        selectedSeller !== ""
                                          ? selectedSeller?.user?.phone_alt ??
                                              ""
                                          : ""
                                    }
                                    name={
                                        selectedSeller !== ""
                                          ? `${selectedSeller?.user?.phone_alt}`
                                          : ""
                                    }
                                />
                            </>
                        </div>
                    </Col>
                    <Col sm="12" md="6">
                        <div className="addressCard">
                            <label> {t("chooseSellerAddress")}</label>
                            {selectedSeller.id
                              ? (selectedSeller?.saved_address ?? selectedSeller.seller_addresses).map(
                                  (address) => {
                                    return (
                                              <>
                                                  <Radio
                                                      value={
                                                          order.seller_address ??
                                                          address.id
                                                      }
                                                      id="seller_address"
                                                      validation={{
                                                        required: true
                                                      }}
                                                      name="address"
                                                      className="radio-btn"
                                                      txt={address.address}
                                                  />
                                              </>
                                    );
                                  }
                                )
                              : null}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-end mt-2" sm="12">
                        <button
                            className="previous"
                            onClick={() => handleChange(null, value - 1)}
                        >
                            {t("previous")}
                        </button>

                        <input
                            className={
                                user.role === "Seller"
                                  ? "confirmSeller"
                                  : "confirm"
                            }
                            value={t("proceed")}
                            type="submit"
                        />
                    </Col>
                </Row>
            </>
            <PaymentAlert
                body="dqwd"
                title="fewff"
                setShow={setPaymentAlert}
                show={showPaymentAlert}
            />
        </ModifiedForm>
  );
}
