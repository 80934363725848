import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Select, Radio, Button, InputNumber, Space, Typography, ConfigProvider, message } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined, MinusCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
// import { useFilterSellersMutation } from "../../../stateManagement/apis/accountsApi";
import { useGetInventoryItemsMutation, useCreateOrderMutation } from "../../../stateManagement/apis/inventoryApi";
import { setInventoryOrder } from "../../../stateManagement/slices/inventoryOrderSlice";
import { INVENTORY_ORDER_TYPES } from "../../../utils/beDict";
import { useUserObject } from "../../../stateManagement/slices/userSlice";
import { useGetSellerMutation } from "../../../stateManagement/apis/accountsApi";

const { Option } = Select;
const { Title } = Typography;

const CreateNewInventoryOrderSeller = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const isRTL = i18n.language === "ar";
  const dispatch = useDispatch();

  const user = useUserObject();
  console.log({ user });
  //   const [getAllLocations] = useGetAllLocationsMutation();
  const [getInventoryItems] = useGetInventoryItemsMutation();
  const [createInventoryOrder] = useCreateOrderMutation();
  const [getSellerApi] = useGetSellerMutation();

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [orderType, setOrderType] = useState(null);
  //   const [selectedLocation, setSelectedLocation] = useState(null);
  //   const [locations, setLocations] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [sellerInfo, setSellerInfo] = useState("");

  useEffect(() => {
    getSellerInfo();
    fetchInventoryItems();
    // fetchLocations();
  }, []);

  // Fetch seller info if user is a seller
  const getSellerInfo = async () => {
    const result = await getSellerApi(user.user.user_id);
    if (result.data) {
      setSellerInfo(result.data);
      console.log("Seller Info:", sellerInfo);
      setAddresses(result.data.seller_addresses);
    }
  };

  //   const fetchLocations = async () => {
  //     try {
  //       const response = await getAllLocations({ limit: 1000 }).unwrap();
  //       setLocations(response?.data || []);
  //     } catch (error) {
  //       console.error("Error fetching locations:", error);
  //     }
  //   };

  //   const handleLocationSelect = (value) => {
  //     const location = locations.find((loc) => loc.id === value);
  //     setSelectedLocation(location);
  //   };

  const handleOrderTypeChange = (e) => {
    const type = e.target.value;
    const typeMapping = {
      return: INVENTORY_ORDER_TYPES.RETURN,
      receive: INVENTORY_ORDER_TYPES.RECIEVE
    };
    setOrderType(typeMapping[type]);
  };

  // Fetch inventory items based on seller or search query
  const fetchInventoryItems = async (name = "") => {
    try {
      const response = await getInventoryItems({
        product__seller__id: user.user?.user_id || null,
        search: name,
        page_size: 1000
      }).unwrap();
      setInventoryItems(response?.data || []);
    } catch (error) {
      console.error("Error fetching inventory items:", error);
      message.error("Error fetching inventory items");
    }
  };

  const handleItemSelect = (itemId, quantity) => {
    const selectedItem = inventoryItems.find((item) => item.id === itemId);
    if (selectedItem) {
      const updatedItem = {
        id: selectedItem.id,
        name: selectedItem.product.name,
        quantity
      };
      setSelectedItems((prev) => {
        const existingItem = prev.find((item) => item.id === itemId);
        if (existingItem) {
          return prev.map((item) => (item.id === itemId ? updatedItem : item));
        }
        console.log(selectedItems);
        return [...prev, updatedItem];
      });
    }
  };

  const onFinish = async (values) => {
    console.log("Form values:", values);

    const payload = {
      type: orderType || "",
      seller: sellerInfo?.id,
      order_items: values.items.map((item) => {
        const orderItem = {
          item: item.item,
          quantity: item.quantity
        };

        // Include inventory_locations for return  orders
        if (orderType === INVENTORY_ORDER_TYPES.RETURN) {
          orderItem.inventory_locations = null;
        //   // Ensure inventory_locations is in the correct format: array of objects with 'id' and 'quantity'
        //   orderItem.inventory_locations = Array.isArray(item.inventory_locations)
        //     ? item.inventory_locations.map(locationId => ({ id: locationId, quantity: item.quantity }))
        //     : [{ id: item.inventory_locations, quantity: item.quantity }];
        }

        return orderItem;
      }),
      seller_address: selectedAddress?.id
    };

    // // Include location only for RECEIVE/TRANSFER orders (not for RETURN)
    // if (orderType !== INVENTORY_ORDER_TYPES.RETURN) {
    //   payload.location = selectedLocation?.id;
    // }

    // // Include location only for receive/transfer orders
    // if (orderType !== INVENTORY_ORDER_TYPES.RETURN) {
    //   payload.location = selectedLocation?.id;
    // }

    console.log("Payload:", payload);

    try {
      await createInventoryOrder(payload).unwrap();
      message.success(t("orderCreatedSuccessfully"));
      dispatch(setInventoryOrder());
      form.resetFields();
      setSelectedItems([]);
    } catch (error) {
      console.error("Error creating order:", error);
      message.error(t("errorCreatingOrder"));
    }
  };
  return (
    <ConfigProvider direction={isRTL ? "rtl" : "ltr"}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ maxWidth: 10000, padding: "2rem", border: "1px solid #d9d9d9", borderRadius: 8, backgroundColor: "#f9f9f9" }}
      >
      <Title level={2} style={{ marginBottom: "1rem" }}>
        {i18n.language === "en"
          ? (
          <LeftOutlined style={{ marginInlineEnd: "0.5rem", border: "1px solid #d9d9d9", borderRadius: 8, backgroundColor: "#EFF1F3", color: "#C2C9D1" }} />
            )
          : (
          <RightOutlined style={{ marginInlineEnd: "0.5rem", border: "1px solid #d9d9d9", borderRadius: 8, backgroundColor: "#EFF1F3", color: "#C2C9D1" }} />
            )}
        {t("createNewOrder")}
      </Title>

        <Title level={3}>{t("inventoryOrderInfo")}</Title>
        <Form.Item
          name="orderType"
          label={t("orderType")}
          rules={[{ required: true, message: t("orderTypeRequired") }]}
        >
          <Radio.Group onChange={handleOrderTypeChange}>
            <Space direction="vertical">
              <Radio value="return">{t("return")}</Radio>
              <Radio value="receive">{t("receive")}</Radio>

            </Space>
          </Radio.Group>
        </Form.Item>

        {/* {orderType !== INVENTORY_ORDER_TYPES.RETURN && (
          <Form.Item
            name="location"
            label={t("location")}
            rules={[{ required: orderType !== INVENTORY_ORDER_TYPES.RETURN, message: t("locationRequired") }]}>
            <Select
              placeholder={t("selectLocation")}
              onChange={handleLocationSelect}>
              {locations.map(({ id, location }) => (
                <Option key={id} value={id}>
                  {location.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )} */}

        <Title level={3} style={{ marginTop: "2rem" }}>{t("orderItems")}</Title>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} align="baseline" style={{ display: "flex", marginBottom: 8 }}>
                  {/* Item Selector */}
                  <Form.Item
                    {...restField}
                    name={[name, "item"]}
                    fieldKey={[fieldKey, "item"]}
                    rules={[{ required: true, message: t("itemRequired") }]}
                    label={t("item")}>
                    <Select
                      placeholder={t("selectItem")}
                      onChange={(itemId) => {
                        const selectedItem = inventoryItems.find((item) => item.id === itemId);
                        if (selectedItem) {
                          handleItemSelect(itemId, 1);
                        }
                      }}
                      style={{ width: "250px" }} // Increase width here

                      >
                      {inventoryItems.map(({ id, product, available_quantity }) => (
                        <Option key={id} value={id}>
                          {product.name} ({t("available")}: {available_quantity})
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {/* Quantity Selector */}
                  <Form.Item
                    {...restField}
                    name={[name, "quantity"]}
                    fieldKey={[fieldKey, "quantity"]}
                    rules={[
                      { required: true, message: t("quantityRequired") },
                      ({ getFieldValue }) => ({
                        validator (_, value) {
                          const selectedItemId = getFieldValue(["items", name, "item"]);
                          const selectedItem = inventoryItems.find((item) => item.id === selectedItemId);
                          if (orderType === INVENTORY_ORDER_TYPES.RETURN && selectedItem && value > selectedItem.available_quantity) {
                            return Promise.reject(new Error(t("exceedsAvailableQuantity")));
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                    label={t("quantity")}>
                    <InputNumber min={1} placeholder={t("qty")} style={{ width: "100%" }} />
                  </Form.Item>

                  {/* Inventory Location Selector for Return/Transfer
                  {orderType !== INVENTORY_ORDER_TYPES.RECIEVE && (
                    <Form.Item
                      {...restField}
                      name={[name, "inventory_locations"]}
                      fieldKey={[fieldKey, "inventory_locations"]}
                      rules={[{ required: true, message: t("inventoryLocationRequired") }]}
                      label={t("inventoryLocation")}>
                      <Select
                        mode="multiple"
                        placeholder={t("selectInventoryLocations")}
                        onChange={(values) => {
                          form.setFieldsValue({
                            items: fields.map((field, idx) =>
                              idx === name ? { ...form.getFieldValue(["items", name]), inventory_locations: values } : field
                            )
                          });
                        }}
                        style={{ width: "250px" }} // Increase width here

                        >
                        {locations.map(({ id, location }) => (
                          <Option key={id} value={id}>
                            {location.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )} */}

                  {/* Remove Button */}
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              <Form.Item>
                <Button type="link" style={{ color: "#57BE6C", marginInlineStart: "-1rem" }} icon={<PlusOutlined />} onClick={() => add()}>
                  {t("addItem")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Title level={3} style={{ marginTop: "2rem" }}>{t("sellerInfo")}</Title>
        <Form.Item
          name="address"
          label={t("address")}
          rules={[{ required: true, message: t("addressRequired") }]}>
          <Radio.Group
            onChange={(e) => {
              const selectedId = e.target.value;
              const addressObj = addresses.find(addr => addr.id === selectedId);
              setSelectedAddress(addressObj);
            }}>
            <Space direction="vertical">
              {addresses.map(({ id, address }) => (
                <Radio key={id} value={id}>{address}</Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>

      <Form.Item>
        <Space style={{ width: "100%", justifyContent: "space-between", marginTop: "2rem" }}>
          <Button type="default" style={{ width: "200px" }} htmlType="button">{t("cancel")}</Button>
          <Button type="primary" style={{ width: "200px" }} htmlType="submit">{t("createOrder")}</Button>
        </Space>
      </Form.Item>

      </Form>
    </ConfigProvider>
  );
};

export default CreateNewInventoryOrderSeller;
