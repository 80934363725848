import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  ticketPrintedData: ""
};

const ticketPrintedDataSlice = createSlice({
  name: "ticketPrintedData",
  initialState,
  reducers: {
    getTicketPrintedData: (state, action) => {
      state.ticketPrintedData = action.payload;
    }
  }
});

export const { getTicketPrintedData } = ticketPrintedDataSlice.actions;
export const useTicketPrintedData = () => useSelector((state) => state.ticketPrintedData.ticketPrintedData);

export default ticketPrintedDataSlice.reducer;
