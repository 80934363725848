import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import SortableTable from "../../SortableTable";
import {
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../../modals/confirmationModal";
import useLanguage from "../../../hooks/useLanguage";
import { useGetInternalRunsheetMutation, useGetRunsheetMutation, useUpdateRunsheetMutation } from "../../../stateManagement/apis/runsheetsApi";
import { stringErrorsHandler } from "../../../api/ErrorNotifier";
import styles from "./EditRunsheetPriorities.module.css";

export function EditRunsheetPriorities () {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [runsheet, setRunsheet] = useState({});
  const { id, type: rsType } = useParams();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const lang = useLanguage();
  const [getRunsheet, { isLoading: isRunsheetLoading }] = useGetRunsheetMutation();
  const [getInternalRunsheet, { isLoading: isInternalRunsheetLoading }] = useGetInternalRunsheetMutation();
  const [updateRunsheet] = useUpdateRunsheetMutation();
  const runsheetOrders = [
    {
      field: "id",
      name: "Order ID",
      width: 200,
      valueGetter: (row) => row?.id,
      selector: (row) => <p className="tableID">{row?.id}</p>
    },

    {
      field: "store_name",
      name: "Store name",
      width: 200,
      valueGetter: (row) =>
        row?.seller?.store_name ? row.seller?.store_name : null,
      selector: (row) => (
                <p className="paragraphMargin">
                    {row?.seller?.store_name
                      ? row.seller?.store_name
                      : "No Name"}
                </p>
      )
    },
    {
      field: "create_date",
      name: "Date Created",
      width: 210,
      selector: (row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
      )
    },
    {
      field: "order_value",
      name: "Order Value",
      width: 210,
      valueGetter: (row) => row?.promise_date,
      selector: (row) => (
                <p className="paragraphMargin">{row?.sub_total}</p>
      )
    },

    {
      field: "status",
      name: "Status",
      width: 230,
      selector: (row) =>
        row.runsheet_type?.map((status) => {
          return (
                        <div
                        key={status}
                            className={status + " btn btn-outline-primary mx-2"}
                        >
                            {status}
                        </div>
          );
        })
    }
  ];

  const runsheetOrders_ar = [
    {
      field: "id",
      name: "رقم الطلب",
      width: 200,
      valueGetter: (row) => row?.id,
      selector: (row) => <p className="tableID">{row.row?.id}</p>
    },

    {
      field: "store_name",
      name: "اسم المتجر",
      width: 200,
      valueGetter: (row) =>
        row?.seller?.store_name ? row.seller?.store_name : null,
      selector: (row) => (
                <p className="paragraphMargin">
                    {row.seller?.store_name
                      ? row.seller?.store_name
                      : "No Name"}
                </p>
      )
    },
    {
      field: "create_date",
      name: "تاريخ الانشاء",
      width: 210,
      selector: (row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
      )
    },
    {
      field: "order_value",
      name: "قيمة الطلب",
      width: 210,
      valueGetter: (row) => row?.promise_date,
      selector: (row) => (
                <p className="paragraphMargin">{row?.sub_total}</p>
      )
    },

    {
      field: "status",
      name: "الحالة",
      width: 230,
      selector: (row) =>
        row.runsheet_type?.map((status) => {
          return (
                        <div
                        key={status}
                            className={status + " btn btn-outline-primary mx-2"}
                        >
                            {status}
                        </div>
          );
        })
    }
  ];

  async function fetchOrders () {
    const res = rsType === "ext" ? await getRunsheet(id) : await getInternalRunsheet(id);
    if (res.data) {
      setRunsheet(res.data);
    }
  }

  async function submit () {
    const res = await updateRunsheet({ runsheetId: id, orders_ids: `[${runsheet.orders.map((it) => it.id).join(", ")}]` });
    if (res.data) {
      setState({ type: "success", message: t("priority_success") });
    } else if (res.error?.data) {
      setState({ type: "error", message: stringErrorsHandler(res.error.data) });
    } else {
      setState({ type: "error", message: t("server_error") });
    }
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (isInternalRunsheetLoading || isRunsheetLoading) setLoading(true);
    else setLoading(false);
  }, [isInternalRunsheetLoading, isRunsheetLoading]);

  return (
        <Container className={styles.container}>
            <SuccessfulRequest
                show={state.type === "success"}
                title={t("edit_priorities")}
                body={<p>{t("successfulRequest")}</p>}
                hide={() => {
                  setState({});
                  navigate("/assigned_rs");
                }}
            />
            <UnSuccessfulRequest
                show={state.type === "error"}
                title={t("edit_priorities")}
                body={state.message}
                hide={() => setState({})}
            />
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{rsType === "ext" ? t("sideMenu.runsheet") : t("sideMenu.internalRunSheet")}</span>

                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {rsType === "ext" ? t("sideMenu.assinedRunsheet") : t("View Internal Runsheet")}
                    </span>
                </Col>
                <Col sm="7">
                    <h2 className="pageTitle">{rsType === "ext" ? t("edit_priorities") : t("View Internal Runsheet")}</h2>
                </Col>
                {
                    rsType === "ext" && (
                <Col style={{ textAlign: "end" }}>
                    <button className={"confirm"} onClick={submit}>
                        {t("submit")}
                    </button>
                </Col>
                    )
                }
            </Row>
            <div>
                <SortableTable
                    columns={lang === "ar" ? runsheetOrders_ar : runsheetOrders}
                    data={runsheet.orders ?? []}
                    setData={(data) =>
                      setRunsheet({ ...runsheet, orders: data })
                    }
                    loading={loading}
                />
            </div>
        </Container>
  );
}

export default EditRunsheetPriorities;
