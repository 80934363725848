import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  returnedTickets: []
};

const returnedTicketsSlice = createSlice({
  name: "returnedTickets",
  initialState,
  reducers: {
    getTicketsReturned: (state, action) => {
      state.returnedTickets = action.payload;
    }
  }
});

export const { getTicketsReturned } = returnedTicketsSlice.actions;
export const useReturnedTickets = () => useSelector((state) => state.returnedTickets.returnedTickets);

export default returnedTicketsSlice.reducer;
