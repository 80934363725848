import { createApi } from "@reduxjs/toolkit/query/react";
import { TICKETS_API } from "../constants";
import { reAuthBaseQuery } from "./baseQuery";
import downloadHandler from "./downloadHandler";

export const ticketsApi = createApi({
  reducerPath: "ticketsApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["Tickets"],
  endpoints: (builder) => ({
    getTickets: builder.mutation({
      query: (queryParameters = {}) => ({
        url: TICKETS_API.ROOT + "/",
        method: "GET",
        params: queryParameters
      }),
      invalidatesTags: ["Tickets"]
    }),
    getTicket: builder.mutation({
      query: (ticketId, queryParameters = {}) => ({
        url: `${TICKETS_API.ROOT}/${ticketId}`,
        method: "GET",
        params: queryParameters
      }),
      providesTags: ["Tickets"]
    }),
    createTicket: builder.mutation({
      query: (body) => ({
        url: TICKETS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Tickets"]
    }),
    updateTicket: builder.mutation({
      query: ({ ticketId, ...body }) => ({
        url: `${TICKETS_API.ROOT}/${ticketId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Tickets"]
    }),
    deleteTicket: builder.mutation({
      query: (ticketId) => ({
        url: `${TICKETS_API.ROOT}/${ticketId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Tickets"]
    }),
    filterTickets: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: TICKETS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        },
        responseHandler: queryParameters.export ? async (response) => await downloadHandler(response) : undefined
      }),
      invalidatesTags: ["Tickets"]
    }),

    filterHistoryTickets: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: TICKETS_API.FILTER_HISTORY,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Tickets"]
    }),
    pushTicketToState: builder.mutation({
      query: (body) => ({
        url: TICKETS_API.MOVE_TICKET,
        method: "POST",
        body
      }),
      invalidatesTags: ["Tickets"]
    }),
    exportTicketsSheet: builder.mutation({
      query: (queryParameters) => ({
        url: TICKETS_API.FILTER,
        method: "GET",
        params: queryParameters,
        responseHandler: async (response) => await downloadHandler(response, queryParameters.fileName),
        cache: "no-cache"
      }),
      invalidatesTags: ["Tickets"]
    }),
    cancelConfirmedTickets: builder.mutation({
      query: (body) => ({
        url: TICKETS_API.CANCEL_CONFIRMED,
        method: "POST",
        body
      }),
      invalidatesTags: ["Tickets"]
    })
  })
});

export const {
  useGetTicketsMutation,
  useGetTicketMutation,
  useCreateTicketMutation,
  useUpdateTicketMutation,
  useDeleteTicketMutation,
  useFilterTicketsMutation,
  useFilterHistoryTicketsMutation,
  usePushTicketToStateMutation,
  useExportTicketsSheetMutation,
  useCancelConfirmedTicketsMutation
} = ticketsApi;
