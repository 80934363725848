import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import "../../style-sheets/form.css";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import { useUpdateOrgEmployeeMutation } from "../../stateManagement/apis/accountsApi";
import { notifyErrors } from "../../api/ErrorNotifier";

const CourierInfoModel = (props) => {
  console.log(props.info);
  const [fullName, setFullName] = useState(props.info[0]?.user.name);
  const [phoneNumber, setPhoneNumber] = useState(props.info[0]?.user.phone);
  const [altPhone, setAltPhone] = useState(props.info[0]?.user.phone_alt);
  const [vehicle, setVehicle] = useState(props.info[0]?.vehicle);
  const [updateCourier, { isLoading: loading }] = useUpdateOrgEmployeeMutation();

  const { t } = useTranslation();

  const openChangePassword = (e) => {
    e.preventDefault();
    props.onOpenChangePassword();
  };
  const edit = async (e) => {
    e.preventDefault();
    const data = {
      name: fullName,
      phone: phoneNumber,
      phone_alt: altPhone,
      vehicle
    };
    const result = await updateCourier({ orgEmployeeId: props.info[0].id, ...data });
    if (result.data) {
      props.setShow(false);
      props.getData();
    } else if (result.error?.data) {
      notifyErrors(result.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };
  useEffect(() => {
    console.log("here");
    console.log(props.action);
  });

  const handleInput = (e, setFunction) => {
    setFunction(e.target.value);
  };
  const lang = useLanguage();

  return (
        <div>
            {props.info.length !== 0
              ? (
                <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={props?.showCourier} onHide={props?.hideInfo}>
                    <Container className="p-3">
                        <Row>
                            <Col sm="12">
                                <i onClick={props?.hideInfo} className='fas fa-times closeInfoModel'></i>
                            </Col>
                        </Row>
                        <Row className="p-3">
                            <Col sm="12">
                                <h2 className="pageTitle">{t("personalInfo")} #{props.info[0].id}</h2>
                            </Col>
                        </Row>
                        <Row className="p-3">
                            <Form>
                                {" "}
                                <Col className='mb-3' sm={12}>
                                    <Form.Label>{t("fullname")}</Form.Label>
                                    <Form.Control
                                        placeholder="Full Name"
                                        value={fullName}
                                        onChange={(e) => handleInput(e, setFullName)}
                                        disabled={props.action === "show"}
                                    />
                                </Col>
                                <Col className='mb-3' sm="12">
                                    <Form.Label>{t("phone")}</Form.Label>

                                    <Form.Control
                                        placeholder="Phone"
                                        value={phoneNumber}
                                        type="number"
                                        onChange={(e) => handleInput(e, setPhoneNumber)}
                                        disabled={props.action === "show"}
                                    />
                                </Col>
                                <Col className='mb-3' sm="12">
                                    <Form.Label>{t("phone")}</Form.Label>
                                    <Form.Control
                                        placeholder="Phone"
                                        type="number"
                                        onChange={(e) => handleInput(e, setAltPhone)}
                                        value={altPhone}
                                        disabled={props.action === "show"}
                                    />
                                </Col>
                                <Col className='mb-3' sm="12">
                                    <Form.Label>{t("branch")}</Form.Label>
                                    <Form.Control
                                        placeholder={t("branch")}
                                        value={props.info[0].branch.name}
                                        disabled
                                    />
                                </Col>
                                <Col className='mb-3' sm="12">
                                    <Form.Label>{t("typeofvehicles")}</Form.Label>
                                    <Form.Control
                                        placeholder="Vehicle"
                                        value={vehicle}
                                        onChange={(e) => handleInput(e, setVehicle)}
                                        type="text"
                                        disabled={props.action === "show"}
                                    />
                                </Col>
                                {props.action === "edit"
                                  ? <Col sm="12" >
                                        <button type="submit" onClick={edit} disabled={loading} className="confirm">
                                            {t("edit")}
                                        </button>
                                        <button style={{ marginInlineStart: "8px" }} type="submit" className="confirm" onClick={openChangePassword}>
                                            {t("changePassword2")}
                                        </button>
                                    </Col>
                                  : null}

                            </Form>
                        </Row>
                    </Container>
                </Modal>
                )
              : null}
        </div>
  );
};

export default CourierInfoModel;
