import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { useLocation } from "react-router-dom";
import { useInventoryOrderColumns } from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import InventoryOrderRepository from "../../../api/InventoryOrderRepository";
import { INVENTORY_ORDER_STATUS } from "../../../utils/beDict";

const InventoryOrders = () => {
  const { t } = useTranslation(); // Translation hook for localization
  const location = useLocation(); // Get current location/state
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    search: ""
  }); // State for managing query parameters
  const [inventoryOrders, setInventoryOrders] = useState([]); // Inventory orders data
  const [count, setCount] = useState(0); // Total count of orders for pagination
  const [loading, setLoading] = useState(false); // Loading state
  const [fetchMethod, setFetchMethod] = useState(null); // API method to fetch orders
  const [selectedOrder, setSelectedOrder] = useState(null); // Selected order ID for completion
  const [completingOrder, setCompletingOrder] = useState(false); // State for completing an order

  // Extract status from location or default to APPROVED
  const { status } = location.state || {
    status: INVENTORY_ORDER_STATUS.APPROVED
  };

  // Function to handle completing an inventory order
  const handleCompleteOrder = async (id) => {
    setCompletingOrder(true);
    setSelectedOrder(id);
    try {
      const response = await InventoryOrderRepository.completeOrder(id);
      if (response.success) {
        notification.success({ message: t("Inventory order completed successfully") });
        getInventoryOrders(); // Refresh data after successful completion
      } else {
        handleError(response);
      }
    } finally {
      setCompletingOrder(false);
      setSelectedOrder(null);
    }
  };

  // Handle errors from API responses
  const handleError = (response) => {
    if (response.status === 400) {
      Object.entries(response.error).forEach(([key, value]) =>
        notification.error({
          message: `${key}: ${Array.isArray(value) ? value[0] : value}`
        })
      );
    } else {
      notification.error({ message: response.error });
    }
  };

  // Generate table columns with dynamic actions
  const columns = useInventoryOrderColumns(
    handleCompleteOrder,
    completingOrder,
    selectedOrder
  );

  // Determine the fetch method based on the order status
  useEffect(() => {
    const methodMap = {
      [INVENTORY_ORDER_STATUS.APPROVED]: "getUnCompletedOrders",
      [INVENTORY_ORDER_STATUS.COMPLETED]: "getCompletedOrders",
      [INVENTORY_ORDER_STATUS.REJECTED]: "getRejectedOrders",
      [INVENTORY_ORDER_STATUS.PENDING]: "getPendingOrders"
    };
    const method = methodMap[status];
    if (method) {
      setFetchMethod(method);
    } else {
      notification.error({ message: t("Invalid status") });
    }
  }, [status]);

  // Fetch inventory orders based on the current fetch method and query parameters
  const getInventoryOrders = async () => {
    if (!fetchMethod) return; // Skip if no valid fetch method
    setLoading(true);
    try {
      const response = await InventoryOrderRepository[fetchMethod](queryParams);
      if (response.success) {
        setInventoryOrders(response.data);
        setCount(response.count);
      } else {
        handleError(response);
      }
    } finally {
      setLoading(false);
    }
  };

  // Update query parameters and reset page if necessary
  const handleChangeQueryParams = (param, value) => {
    setQueryParams((prev) => ({
      ...prev,
      [param]: value,
      page: param === "page" ? value : 1
    }));
  };

  // Refetch orders whenever query parameters or fetch method changes
  useEffect(() => {
    getInventoryOrders();
  }, [queryParams, fetchMethod]);

  return (
    <div>
      <Container>
        <Row className="mb-4">
          <Col sm="12">
            <span className="title1">{t("Inventory Orders")}</span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">
              {t(
                `${status[0].toUpperCase()}${status.slice(1).toLowerCase()} Inventory Orders`
              )}
            </span>
          </Col>
          <Col sm="12">
            <h2 className="pageTitle">
              {t(
                `${status[0].toUpperCase()}${status.slice(1).toLowerCase()} Inventory Orders`
              )}
            </h2>
          </Col>
        </Row>

        <Row>
          <TablePro
            loading={loading}
            emptyPlaceholder={
              <div>
                <h6 className="noDataHead">{t("noDataFound")}</h6>
                <p>
                  {t(
                    `There are no ${status.toLowerCase()} inventory orders now.`
                  )}
                </p>
              </div>
            }
            onSearch={(value) => handleChangeQueryParams("search", value)}
            dataSource={inventoryOrders}
            columns={columns}
            pagination={{
              current: queryParams.page,
              total: count,
              onChange: (page) => handleChangeQueryParams("page", page)
            }}
          />
        </Row>
      </Container>
    </div>
  );
};

export default InventoryOrders;
