import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import "../../../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../api/axios";
// import { trakingCols } from "../../../layout/tableColumns";
import NextStatesModel from "../../../modals/NextStatesModel";
import traking from "../../../../images/traking.png";
import traking2 from "../../../../images/img.png";
import CreateTicket from "../../../modals/CreateTicket";
import ThreeDotsMenu from "../../../layout/threeDotsMenu";
import EditStateModel from "../../../modals/EditStateModel";
import useLanguage from "../../../../hooks/useLanguage";

const TrakingBoard = () => {
  const lang = useLanguage();

  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelected] = useState([]);
  const [count, setCount] = useState(1);
  const [nextStates, setNextStates] = useState("");
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [updatedStates, getUpdated] = useState(false);
  const [page, setPage] = useState(1);
  const [deleted, setDeleted] = useState(true);
  const [trakingCols] = useState([
    {
      field: "cardID",
      headerName: `${t("trackingTable.number")}`,
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="tableID">#{row.row?.id}</p>
    },
    {
      field: "Title",
      headerName: `${t("trackingTable.title")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin">{row.row?.title}</p>
    },
    {
      field: "arabicTitle",
      headerName: `${t("trackingTable.titleAr")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.title_ar}</p>
      )
    },
    {
      field: "Description",
      headerName: `${t("trackingTable.description")}`,
      width: 190,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.description}</p>
      )
    },
    {
      field: "Primery Color",
      headerName: `${t("trackingTable.primColor")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin">{row.row?.color}</p>
    },
    {
      field: "Selection Color",
      headerName: `${t("trackingTable.secColor")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.accent_color}</p>
      )
    },
    {
      field: "Prev State",
      headerName: `${t("trackingTable.prevState")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin">{row.row?.prev_state?.title}</p>
    },
    {
      field: "Possible States",
      headerName: `${t("trackingTable.possible")}`,
      width: 220,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="tableID">{t("clickHere")}</p>
    },
    {
      field: "actions",
      headerName: `${t("actions")}`,
      flex: 1,
      minWidth: 150,
      renderCell: (row) => (
        <ThreeDotsMenu
          link="State"
          deleted
          setDeleted={setDeleted}
          data={row.row}
          setEdited={setShowEdit}
          onClick={() => getSelectedData(row.row)}
        />
      )
    }
  ]);

  const getSelectedData = (data) => {
    console.log(data);
    setSelected(data);
  };

  const getStates = async () => {
    const result = await axiosInstance.get("/State/?page=1");
    if (result) {
      console.log(result);
      console.log(page);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  useEffect(() => {
    getStates();
  }, [updatedStates]);
  const close = () => {
    setShow(false);
  };
  const hideCreateModal = () => {
    setShowCreate(false);
  };
  const update = (e) => {
    getUpdated(e);
  };
  useEffect(() => {
    getStates();
  }, [deleted]);
  const onpageChangeHandle = async (page) => {
    setPage(page);
    const result = await axiosInstance.get(`/State/?page=${page + 1}`);
    if (result) {
      console.log(result);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  // const setDeletedState = (e) => {
  //   setDeleted(e);
  // };
  const closeEdit = () => {
    setShowEdit(false);
  };
  return (
    <div>
      <Container>
        <Row>
          <Col sm="12">
            <span className="title2">{t("sideMenu.settings")}</span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">{t("preferences")}</span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title1">{t("trackingBoard")}</span>
          </Col>
          <Col className="mt-3" sm="12">
            <h2 className="pageTitle">{t("trackingBoard")}</h2>
          </Col>
        </Row>

        <Row>
          <Col className="text-center" sm="12">
            <img className="img-fluid" src={lang === "en" ? traking : traking2} />
          </Col>
        </Row>
        <Row>
          <Col className="text-end" sm="12">
            <button
              onClick={() => {
                setShowCreate(true);
              }}
              className="confirm"
            >
              <i className="fas fa-plus-circle pe-2 mx-1"></i>
              {t("addNewState")}
            </button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div dir="ltr" className="requestTable rs mt-2">
              <DataGrid

                rows={data}
                columns={trakingCols}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                rowCount={count}
                pageSize={10}
                rowsPerPageOptions={[10]}
                onPageChange={onpageChangeHandle}
                onCellClick={(p, e) => {
                  if (p.field === "Possible States") {
                    console.log(p.row);
                    setNextStates(p.row);
                    setShow(true);
                  }
                }}
              />{" "}
              {show
                ? (
                <NextStatesModel
                  details={nextStates}
                  show={show}
                  close={close}
                />
                  )
                : null}
            </div>
          </Col>
        </Row>
      </Container>
      <CreateTicket
        showModal={showCreate}
        hideModal={hideCreateModal}
        updatedStates={update}
      />
      <EditStateModel
        data={selectedRow}
        show={showEdit}
        hideModal={closeEdit}
        updatedStates={update}
      />
    </div>
  );
};

export default TrakingBoard;
