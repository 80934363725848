import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addRunsheetOrders } from "../../../stateManagement/slices/rsOrdersSlice";
import TablePro from "../../tables/TablePro";
import { useBranch } from "../../../stateManagement/slices/branchSlice";
import { useAuthedUser } from "../../../stateManagement/slices/userSlice";
import { useGetSellerOrdersMutation } from "../../../stateManagement/apis/ordersApi";

function SellersOrders (props) {
  const dispatchOrders = useDispatch();
  const { t } = useTranslation();
  const cur = useAuthedUser().organization.country.currency;

  const navigate = useNavigate();
  const branch = useBranch();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("1");
  const [seller] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [orders, setOrders] = useState([]);
  const [getSellerOrders] = useGetSellerOrdersMutation();
  const [columns] = useState([
    {
      dataIndex: "orderid",
      title: `${t("orderID")}`,
      render: (_, row) => (
                <p className="paragraphMargin tableID">#{row.orderid}</p>
      )
    },
    {
      title: `${t("shipmentFee")}`,
      render: (_, row) => (
                <p className="paragraphMargin credit">{row.delivery_fees}</p>
      )
    },
    {
      dataIndex: "value",
      title: `${t("orderValue")}`,
      render: (_, row) => (
                <p className="paragraphMargin credit">
                    {cur} {row.sub_total}
                </p>
      )
    },
    {
      dataIndex: "method",
      title: `${t("paymentMethod")}`,
      render: (_, row) => (
                <p className="paragraphMargin">{row.payment_method}</p>
      )
    }
  ]);

  const chooseType = (e) => {
    setType(e);
  };

  const getSellersOrders = async () => {
    const result = await getSellerOrders(seller.id, { type, page });

    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  const pay = async () => {
    console.log({ orders, seller, branch });
    if (type === "1") {
      dispatchOrders(addRunsheetOrders(orders));
      console.log("clicked", type);
    } else {
      dispatchOrders(addRunsheetOrders(orders));
      console.log("type", type);
    }
    navigate(`/settelment/${type}`);
  };

  useEffect(() => {
    getSellersOrders();
  }, [page, type]);
  return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("totalBalance")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sellerWallet")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="column-display" sm="12" md="12">
                    <Select className="type" value={type} onChange={chooseType}>
                        <Select.Option selected value="1">
                            {t("debit")}
                        </Select.Option>
                        <Select.Option value="2">{t("credit")}</Select.Option>
                    </Select>
                    <button
                        disabled={selectionModel.length === 0}
                        onClick={pay}
                        className="pay"
                    >
                        {type === "1" ? `${t("payTo")}` : `${t("recieveFrom")}`}
                    </button>
                </Col>
            </Row>

            <TablePro
                title={
                    <h6>
                        {branch} {t("orderDetails")}
                    </h6>
                }
                dataSource={data}
                columns={columns}
                pagination={{
                  current: page,
                  total: count,
                  onChange: (page) => setPage(page)
                }}
                setSelectedRecords={setOrders}
                selection={"checkbox"}
                selectedRows={selectionModel}
                setSelectedRows={setSelectionModel}
            />
        </>
  );
}

export default SellersOrders;
