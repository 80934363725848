import { useEffect, useState, useCallback } from "react";
import { arrayMoveImmutable } from "array-move";
import classNames from "classnames";
import styles from "./TableComponent.module.css";
import TableEmpty from "./TableEmpty";
import TableColumnsHeader from "./TableColumnsHeader";
import TableLoading from "./TableLoading";
import TableContent from "./TableContent";

export default function SortableTable ({
  columns,
  data,
  setData,
  actions,
  disableAction,
  count,
  countText,
  onSearch,
  onSelect,
  onPageChange,
  onLimitChange,
  isLastPage,
  actionsTitle,
  className,
  loading,
  error,
  ...rest
}) {
  const [selection, setSelection] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  // Callback for updating selection
  const handleSelectionChange = useCallback((id, checked) => {
    setSelection((prevSelection) => {
      if (checked) {
        return [...prevSelection, id];
      }
      return prevSelection.filter((item) => item !== id);
    });
  }, []);

  // // Update on page change
  // const handlePageChange = useCallback(
  //   (page) => {
  //     setCurrentPage(page - 1);
  //     onPageChange?.(page);
  //   },
  //   [onPageChange]
  // );

  // Update data order on sort end
  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setData(arrayMoveImmutable(data, oldIndex, newIndex));
    },
    [data, setData]
  );

  // Reset page when data changes
  useEffect(() => {
    if (data.length === 0) setCurrentPage(0);
  }, [data]);

  // Synchronize selected items with the parent component
  useEffect(() => {
    onSelect?.(selection);
  }, [selection, onSelect]);

  // Prevent unnecessary rerenders or function calls when certain props remain the same
  const tableContent = loading
    ? (
    <TableLoading />
      )
    : data.length === 0
      ? (
    <TableEmpty />
        )
      : (
    <TableContent
      lockToContainerEdges
      lockAxis="y"
      useDragHandle
      onSortEnd={handleSortEnd}
      data={data}
      disableActions={disableAction}
      currentPage={currentPage}
      onSelect={onSelect}
      onCheckBoxChange={handleSelectionChange}
      columns={columns}
      actions={actions}
    />
        );

  return (
    <div className={classNames(styles.container, className)} {...rest}>
      <div className={styles.tableContainer}>
        <table className={styles.table} cellSpacing={0} {...rest}>
          <TableColumnsHeader
            onSelect={onSelect}
            columns={columns}
            actions={actions}
            actionsTitle={actionsTitle}
            disableActions={disableAction}
          />
          {tableContent}
        </table>
      </div>
    </div>
  );
}
