import React from "react";
import { Typography, Select, Row, Col, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../../../stateManagement/slices/userSlice";

const { Title, Text } = Typography;

const LanguageCurrency = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleLang = async (value) => {
    // Change language and update Redux state
    dispatch(changeLanguage(value));
    await i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
    window.location.reload(false);
  };

  return (
    <div
      style={{
        padding: 24,
        borderRadius: "10px",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        margin: "auto",
        minHeight: "60vh"
      }}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
          <Text style={{ fontSize: 16, color: "#8c8c8c" }}>
            {t("sideMenu.settings")} <span style={{ margin: "0 8px" }}>›</span> {t("language")}
          </Text>
          <Divider />
        </Col>
        <Col span={24}>
          <Title level={3} style={{ fontWeight: 600, color: "#595959" }}>
            {t("lang")}
          </Title>
        </Col>
        <Col span={24} md={16} style={{ textAlign: "center", background: "#fff", padding: 20, borderRadius: 8, boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
          <Text strong>{t("language")}</Text>
          <Select
            onChange={handleLang}
            value={i18n.language} // Set the default selected language based on the current system language
            style={{ width: "100%", marginTop: 10 }}
            placeholder={t("selectLang")}
          >
            <Select.Option value="en">English</Select.Option>
            <Select.Option value="ar">العربية</Select.Option>
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default LanguageCurrency;
