import { createApi } from "@reduxjs/toolkit/query/react";
import { RUNSHEETS_API, INTERNAL_RUNSHEETS_API } from "../constants";
import { reAuthBaseQuery } from "./baseQuery";

export const runsheetsApi = createApi({
  reducerPath: "runsheetApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["Runsheet", "InternalRunsheet"],
  endpoints: (builder) => ({

    // Runsheet
    getRunsheets: builder.mutation({
      query: () => ({
        url: RUNSHEETS_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["Runsheet"]
    }),
    getRunsheet: builder.mutation({
      query: (runsheetId) => ({
        url: `${RUNSHEETS_API.ROOT}/${runsheetId}`,
        method: "GET"
      }),
      providesTags: ["Runsheet"]
    }),
    createRunsheet: builder.mutation({
      query: (body) => ({
        url: RUNSHEETS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Runsheet"]
    }),
    updateRunsheet: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${RUNSHEETS_API.ROOT}/${runsheetId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Runsheet"]
    }),
    deleteRunsheet: builder.mutation({
      query: (runsheetId) => ({
        url: `${RUNSHEETS_API.ROOT}/${runsheetId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Runsheet"]
    }),
    filterRunsheets: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: RUNSHEETS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Runsheet"]
    }),
    addOrdersToRunsheet: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${RUNSHEETS_API.ADD_TO_RUNSHEET}/${runsheetId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Runsheet"]
    }),
    removeOrdersFromRunsheet: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${RUNSHEETS_API.REMOVE_FROM_RUNSHEET}/${runsheetId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Runsheet"]
    }),
    courierActiveRunsheet: builder.mutation({
      query: (courierId) => ({
        url: `${RUNSHEETS_API.COURIER_ACTIVE_SHEET}/${courierId}`,
        method: "GET",
        params: {
          page_size: 10000
        }
      }),
      providesTags: ["Runsheet"]
    }),
    hubReceivedOrders: builder.mutation({
      query: ([courierId, body]) => ({
        url: `${RUNSHEETS_API.HUB_RECEIVE}/${courierId}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Runsheet"]
    }),

    reassignCourier: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${RUNSHEETS_API.REASSIGN_COURIER}/${runsheetId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Runsheet"]
    }),
    unassignCourier: builder.mutation({
      query: (runsheetId) => ({
        url: `${RUNSHEETS_API.UNASSIGN_COURIER}/${runsheetId}`,
        method: "POST"
      }),
      invalidatesTags: ["Runsheet"]
    }),
    getCourierSettableOrders: builder.mutation({
      query: (courierId, queryParameters) => ({
        url: `${RUNSHEETS_API.COURIER_SETTLEMENT}/${courierId}`,
        method: "GET",
        params: queryParameters
      }),
      providesTags: ["Runsheet"]
    }),
    settleCourierOrders: builder.mutation({
      query: ({ courierId, force = false, ...body }) => ({
        url: `${RUNSHEETS_API.COURIER_SETTLEMENT}/${courierId}`,
        method: "POST",
        params: { force },
        body
      }),
      invalidatesTags: ["Runsheet"]
    }),

    forceAcceptRejectRunsheet: builder.mutation({
      query: (body) => ({
        url: RUNSHEETS_API.FORCE_ACCEPT,
        method: "POST",
        body
      }),
      invalidatesTags: ["Runsheet"]
    }),
    closeRunsheet: builder.mutation({
      query: (runsheetId) => ({
        url: `${RUNSHEETS_API.CLOSE_RUNSHEET}/${runsheetId}`,
        method: "POST"
      }),
      invalidatesTags: ["Runsheet"]
    }),
    // Internal Runsheet
    getInternalRunsheets: builder.mutation({
      query: () => ({
        url: INTERNAL_RUNSHEETS_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    getInternalRunsheet: builder.mutation({
      query: (runsheetId) => ({
        url: `${INTERNAL_RUNSHEETS_API.ROOT}/${runsheetId}`,
        method: "GET"
      }),
      providesTags: ["InternalRunsheet"]
    }),
    createInternalRunsheet: builder.mutation({
      query: (body) => ({
        url: INTERNAL_RUNSHEETS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    updateInternalRunsheet: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${INTERNAL_RUNSHEETS_API.ROOT}/${runsheetId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    deleteInternalRunsheet: builder.mutation({
      query: (runsheetId) => ({
        url: `${INTERNAL_RUNSHEETS_API.ROOT}/${runsheetId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    filterInternalRunsheets: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: INTERNAL_RUNSHEETS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    acceptRejectInternalRunsheet: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${INTERNAL_RUNSHEETS_API.STATE}/${runsheetId}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    availableOrders: builder.mutation({
      query: () => ({
        url: `${INTERNAL_RUNSHEETS_API.AVAILABLE_ORDERS}`,
        method: "GET"
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    addToInternalRunsheet: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${INTERNAL_RUNSHEETS_API.ADD_TO_RUNSHEET}/${runsheetId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    removeFromInternalRunsheet: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${INTERNAL_RUNSHEETS_API.REMOVE_FROM_RUNSHEET}/${runsheetId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["InternalRunsheet"]
    }),
    reassignInternalRunsheet: builder.mutation({
      query: ({ runsheetId, ...body }) => ({
        url: `${INTERNAL_RUNSHEETS_API.REASSIGN}/${runsheetId}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["InternalRunsheet"]
    })
  })
});

export const {
  useGetRunsheetsMutation,
  useGetRunsheetMutation,
  useCreateRunsheetMutation,
  useUpdateRunsheetMutation,
  useDeleteRunsheetMutation,
  useFilterRunsheetsMutation,
  useGetInternalRunsheetsMutation,
  useGetInternalRunsheetMutation,
  useCloseRunsheetMutation,
  useCreateInternalRunsheetMutation,
  useUpdateInternalRunsheetMutation,
  useDeleteInternalRunsheetMutation,
  useFilterInternalRunsheetsMutation,
  useAcceptRejectInternalRunsheetMutation,
  useAvailableOrdersMutation,
  useAddToInternalRunsheetMutation,
  useRemoveFromInternalRunsheetMutation,
  useReassignInternalRunsheetMutation,
  useAddOrdersToRunsheetMutation,
  useRemoveOrdersFromRunsheetMutation,
  useCourierActiveRunsheetMutation,
  useHubReceivedOrdersMutation,
  useReassignCourierMutation,
  useUnassignCourierMutation,
  useGetCourierSettableOrdersMutation,
  useSettleCourierOrdersMutation,
  useForceAcceptRejectRunsheetMutation
} = runsheetsApi;
