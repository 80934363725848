import React, { useState, useEffect } from "react";
import { Slider, InputNumber, Button, Row, Col, Typography, Card } from "antd";
import { DollarOutlined, ShoppingCartOutlined, PayCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import axiosInstance from "../../api/axios";
import useLanguage from "../../hooks/useLanguage";
import { useAuthedUser } from "../../stateManagement/slices/userSlice";
import { BaseUrl } from "../../stateManagement/constants";
import { DISPLAY_MODE, FawryPay } from "./fawrypay-payments";
import "./Payments.css"; // Add custom styles here for additional animations

const { Title, Text } = Typography;

const MERCHANT_CODE = "siYxylRjSPwVaIFPiORyNQ==";
const HASH_KEY = "8250efc06ee94f97b08c2fb018fd3e38";
const RETURN_URL = `${BaseUrl}/pay_fawry`;

const Payments = () => {
  const { t } = useTranslation();
  const lang = useLanguage();
  const user = useAuthedUser();

  const [inputValue, setInputValue] = useState(100);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const { data } = await axiosInstance.get("/Plan/");
      setPlans(data.data || []);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const getPrice = () => {
    const selectedPlan = plans.find(
      (plan) => inputValue >= plan.range_from && inputValue < plan.range_to
    );
    return selectedPlan?.price_per_order || 0;
  };

  const handleCheckout = async () => {
    try {
      setLoading(true);
      const amount = inputValue * getPrice();
      const { data } = await axiosInstance.post("/request_pay_fawry", {
        amount,
        orders_count: inputValue
      });

      if (data) {
        const signature = CryptoJS.SHA256(
          MERCHANT_CODE +
            data.data.id +
            RETURN_URL +
            "1" +
            inputValue +
            getPrice() +
            HASH_KEY
        ).toString();

        const chargeRequest = buildChargeRequest(data.data.id, signature);

        FawryPay.checkout(chargeRequest, {
          locale: lang,
          mode: DISPLAY_MODE.POPUP
        });
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    } finally {
      setLoading(false);
    }
  };

  const buildChargeRequest = (refNum, signature) => ({
    merchantCode: MERCHANT_CODE,
    merchantRefNum: refNum,
    customerMobile: user.phone,
    customerEmail: user.email,
    customerName: user.name,
    chargeItems: [
      {
        itemId: "1",
        price: getPrice(),
        quantity: inputValue
      }
    ],
    returnUrl: RETURN_URL,
    authCaptureModePayment: false,
    signature
  });

  return (
    <div className="payments-container">
      <Card className="payments-card" bordered hoverable>
        <Title level={2} className="page-title">
          {t("paymentGate")}
        </Title>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Slider
              min={100}
              max={100000}
              step={5}
              value={inputValue}
              onChange={setInputValue}
              tooltip={{ formatter: (value) => `${value} ${t("orders")}` }}
              className="payment-slider"
            />
          </Col>
          <Col span={24} className="input-section">
            <Text className="label">
              <ShoppingCartOutlined /> {t("ordersNumber")}
            </Text>
            <InputNumber
              min={100}
              max={100000}
              value={inputValue}
              onChange={setInputValue}
              style={{ marginLeft: 16, width: 150 }}
            />
          </Col>
          <Col span={24} className="amount-section">
            <Text className="amount-label">
              <DollarOutlined /> {t("amountpayed")}
            </Text>
            <Text strong className="amount-value">
              {isNaN(inputValue * getPrice())
                ? "0.00"
                : (inputValue * getPrice()).toFixed(2)}{" "}
              {/* {t("currency")} */}
            </Text>
          </Col>
          <Col span={24} className="checkout-section">
            <Button
              type="primary"
              icon={<PayCircleOutlined />}
              size="large"
              loading={loading}
              onClick={handleCheckout}
              className="checkout-button"
            >
              {t("checkout")}
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Payments;
