import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LabelPrinterIcon } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { Tooltip } from "reactstrap";
import useLanguage from "../../hooks/useLanguage";
import icon1 from "../../images/category.png";
import { useAuthedUser } from "../../stateManagement/slices/userSlice";

const TicketCard = ({ data, downloadLabel }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const cur = useAuthedUser().organization.country.currency;
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const { t } = useTranslation();
  const lang = useLanguage();

  return (
        <>
            <div dir={lang === "en" ? "ltr" : "rtl"} className="infoCard">
                <div
                    style={{
                      background: `${data.state.color}`
                    }}
                    className="infoCardHead"
                >
                    <Row>
                        <Col
                            className="d-flex justify-content-between align-items-center"
                            sm="12"
                        >
                            <p className={"ticketDetailsID"}>#{data.id}</p>
                            <button
                                style={{
                                  background: `${data.state.accent_color}`
                                }}
                            >
                                {lang === "en"
                                  ? data.state.title
                                  : data.state.title_ar}
                            </button>
                        </Col>
                    </Row>
                </div>
                <div id="TooltipExample" className="print-ticket">
                    <span
                        // target="_blank"
                        // href={`${BASE_URL}/CreateInvoice/?tickets=${
                        //     data.id
                        // }&lang=${localStorage.getItem("lang", "en")}`}
                        onClick={() => downloadLabel(data.id)}
                    >
                        {" "}
                        <Icon source={LabelPrinterIcon} color="base" />
                    </span>
                    <Tooltip
                        placement="top"
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={toggle}
                    >
                        {t("printTicket")}
                    </Tooltip>
                </div>
                <table className="ticketTable">
                    <tr>
                        <td className="tableIcon">
                            <i className="fas fa-file-alt"></i>
                        </td>
                        <td className="tableInfo">{data.product.name}</td>
                    </tr>
                    <tr>
                        <td className="tableIcon">
                            <img src={icon1} className="img-fluid" />
                        </td>
                        <td className="tableInfo">{data.quantity}</td>
                    </tr>
                    <tr>
                        <td className="tableIcon">
                            <i className="fas fa-shopping-cart"></i>
                        </td>
                        <td className="tableInfo">{data.weight} {t("KG")}</td>
                    </tr>
                    <tr>
                        <td className="tableIcon"></td>
                        <td className="tableInfo">
                            <Row>
                                <Col sm="12" md="6">
                                    {" "}
                                    {data.fragile && (
                                        <p className="productdesc">
                                            {t("Fragile")}
                                        </p>
                                    )}
                                </Col>
                                <Col sm="12" md="6">
                                    {" "}
                                    <p className="tableInfoPrice">
                                        {t(cur)}
                                        <span className="orderCost">
                                            {data.price}
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        </td>
                    </tr>
                    {(data.allow_open || data.special) && (
                        <tr>
                            <td className="tableIcon"></td>
                            <td className="tableInfo">
                                <Row>
                                    <Col sm="12" md="6">
                                        {" "}
                                        {data.special && (
                                            <p className="productdesc">
                                                {t("Special Care")}
                                            </p>
                                        )}
                                    </Col>
                                    <Col sm="12" md="6">
                                        {" "}
                                        {data.allow_open && (
                                            <p className="productdesc text-success">
                                                {t("Allow Open")}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    )}
                </table>
                {data.comment
                  ? (
                    <div
                        style={{
                          background: `${data.state.color}`
                        }}
                        className=""
                    >
                        <Row>
                            <Col
                                className="d-flex justify-content-between align-items-center"
                                sm="12"
                            >
                                <p className={"ticketDetailsID"}>
                                    {data.comment?.is_other
                                      ? data.comment?.text
                                      : !data.comment.is_other &&
                                          lang === "en"
                                          ? data.comment?.text
                                          : data.comment?.text_ar}
                                </p>
                            </Col>
                        </Row>
                    </div>
                    )
                  : null}
            </div>
        </>
  );
};

export default TicketCard;
