import React, { useState, useEffect } from "react";
import { Row, Col, Button, Layout, Card, Space, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOrderTypeColumns } from "../../../layout/tableColumns";
import TablePro from "../../../tables/TablePro";
import { notifyErrors } from "../../../../api/ErrorNotifier";

import { useGetOrderTypesMutation, useDeleteOrderTypeMutation } from "../../../../stateManagement/apis/treecodeApi";

const { Text, Title } = Typography;
const { Content } = Layout;

const AllOrderTypes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getOrderTypes, { isLoading: isLoadingOrderTypes }] = useGetOrderTypesMutation();
  const [deleteOrderType, { isLoading: isLoadingDeleteOrderType }] = useDeleteOrderTypeMutation();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 10,
    search: ""
  });
  const [orderTypes, setOrderTypes] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedOrderType, setSelectedOrderType] = useState(null);

  const handleDeleteAction = async (id) => {
    setSelectedOrderType(id);
    await deleteOrderType(id).unwrap().then(() => {
      getOrderTypes();
    }).catch((error) => {
      if (error.data) {
        notifyErrors(error.data);
      } else {
        notifyErrors("Something went wrong");
      }
    });
    setSelectedOrderType(null);
  };

  const columns = useOrderTypeColumns(
    handleDeleteAction,
    isLoadingDeleteOrderType,
    selectedOrderType
  );

  const handleGetOrderTypes = async () => {
    const response = await getOrderTypes(queryParams);
    if (response.data) {
      setOrderTypes(response.data.data);
      setCount(response.data.count);
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };

  const handleChangeQueryParams = (param, value) => {
    setQueryParams({
      ...queryParams,
      [param]: value,
      page: param === "page" ? value : 1
    });
  };

  useEffect(() => {
    handleGetOrderTypes();
  }, [queryParams]);

  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <Content style={{ padding: "24px" }}>
        <Card bordered={false} style={{ marginBottom: "24px" }}>
          <Row gutter={[16, 16]} align="middle">
            <Col span={24}>
              <Space size="middle" style={{ fontSize: "16px" }}>
                <Text strong>{t("sideMenu.orderTypes")}</Text>
                <RightOutlined />
                <Text>{t("sideMenu.allOrderTypes")}</Text>
              </Space>
            </Col>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={3} style={{ margin: "16px 0" }}>
                    {t("sideMenu.allOrderTypes")}
                  </Title>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => navigate("/order_types/new")}
                >
                  {t("Create Order Type")}
                </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>

        <Card bordered={false}>
                <Row>
                        <div className="w-100">
                            <TablePro
                                loading={isLoadingOrderTypes}
                                emptyPlaceholder={
                                    <div>
                                        <h6 className="noDataHead">
                                            {t("noDataFound")}
                                        </h6>
                                        <p>
                                            {t(
                                              "There are no order types available"
                                            )}
                                        </p>
                                    </div>
                                }
                                onSearch={(value) =>
                                  handleChangeQueryParams("search", value)
                                }
                                dataSource={orderTypes}
                                columns={columns}
                                pagination={{
                                  current: queryParams.page,
                                  total: count,
                                  onChange: (page) =>
                                    handleChangeQueryParams("page", page)
                                }}
                            />
                        </div>
                    </Row>
            </Card>
            </Content>
        </Layout>
  );
};

export default AllOrderTypes;
