import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select, Input, Form, Radio, Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addOrder, useOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { useUserObject } from "../../../../../stateManagement/slices/userSlice";
import {
  getSeller,
  getClient
} from "../../../../../stateManagement/slices/selectedSellerSlice";
import { getTicketPrintedData } from "../../../../../stateManagement/slices/ticketPrintedDataSlice";
import {
  OrderConfirmationModal,
  OrderDeleteModal
} from "../../../../../components/modals/confirmationModal";
import OrderSummary from "../../order-summary/ui/OrderSummary";
import { useDeleteOrderMutation, useCreateArchivedOrderMutation, useUpdateOrderMutation, useCreateOrderMutation } from "../../../../../stateManagement/apis/ordersApi";
import { useGetSellerMutation } from "../../../../../stateManagement/apis/accountsApi";
import { useGetBranchesMutation, useGetOrderTypesMutation } from "../../../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../../../api/ErrorNotifier";

// simport { BaseUrl } from "../../../../../stateManagement/constants";
// import { useOrderTicketColumns } from "../../../../../components/layout/tableColumns";
// import TablePro from "../../../../../components/tables/TablePro";
export default function OrderInfoForm ({ value, handleChange }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deliveryRangesErr, setDeliveryRangesErr] = useState("");
  const [branchErr, setBranchErr] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [calDeliveryFees, setCalDeliveryFees] = useState(deliveryFees);
  const [showCalculations, setShowCalculations] = useState(false);
  const [branchesPage, setBranchesPage] = useState(1);
  const [branchesSearchTerm, setBranchesSearchTerm] = useState("");
  const [branches, setBranches] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [sellerInfo, setSellerInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);
  // const [printedTickets] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [paymentType, setPaymentType] = useState(null);
  const [pos, setPos] = useState(null);
  const [cod, setCod] = useState(null);
  const [note, setNote] = useState("");
  const [getBranchesApi] = useGetBranchesMutation();
  const [createOrderApi, { isLoading: createOrderLoading }] = useCreateOrderMutation();
  const [updateOrderApi, { isLoading: updateOrderLoading }] = useUpdateOrderMutation();
  const [getSellerApi] = useGetSellerMutation();
  const [createArchivedOrderApi] = useCreateArchivedOrderMutation();
  const [deleteOrderApi] = useDeleteOrderMutation();
  const [orderTypes, setOrderTypes] = useState([]);
  const [getOrderTypesApi, { isLoading: getOrderTypesLoading }] = useGetOrderTypesMutation();
  // const columns = useOrderTicketColumns();
  const user = useUserObject();
  const order = useOrder();

  const showConfirmationModal = () => {
    setShow(true);
    dispatch(addOrder(""));
  };

  const getOrderTypes = async () => {
    const result = await getOrderTypesApi({
      page: 1,
      limit: 1000
    });
    if (result.data) {
      setOrderTypes(result.data.data);
    }
  };
  const showDeleteModal = () => {
    setShowDelete(true);
  };

  const hide = () => {
    setShow(false);
    navigate("/dashboard");
  };

  const hideDelete = () => {
    setShowDelete(false);
    setTimeout(() => {
      handleChange(null, 0);
    }, 1000);
  };

  const validate = async (data) => {
    if (!data.branch && !order.branch && user.role !== "Seller") {
      setBranchErr(t("branchErr"));
    } else {
      setBranchErr("");
      setDeliveryRangesErr("");
      console.log(deliveryRangesErr);
      data.delivery_fees = deliveryFees;
      dispatch(addOrder({ ...data, cod, pos, note }));
      setSend(true);
    }
  };

  const discard = async () => {
    if (orderDetails) {
      showDeleteModal();
      archive_order();
      await deleteOrderApi(orderDetails.id);
      dispatch(addOrder(""));
      dispatch(getSeller({}));
      dispatch(getClient({}));
    } else {
      dispatch(getSeller({}));
      dispatch(getClient({}));
      dispatch(addOrder(""));
      handleChange(null, 0);
    }
  };

  useEffect(() => {
    setLoading(createOrderLoading || updateOrderLoading);
  }, [createOrderLoading, updateOrderLoading]);

  useEffect(() => {
    const sendOrderRequest = async () => {
      try {
        await sendRequest();
        setSend(false); // Ensure `sendRequest` is not called again unintentionally
      } catch (err) {
        console.error("Error sending request:", err);
      }
    };

    if (send) {
      sendOrderRequest();
    }
  }, [send]);

  // Function to update an order
  const updateRequest = async () => {
    if (!orderDetails?.id) {
      notifyErrors(t("Order ID is missing. Unable to update order."));
      return;
    }

    try {
      const result = await updateOrderApi({
        orderId: orderDetails.id,
        delivery_fees: calDeliveryFees
      });

      if (result.data) {
        dispatch(getTicketPrintedData(result.data));
        setShow(true); // Show confirmation modal
        dispatch(addOrder("")); // Clear current order
      } else if (result.error?.data) {
        notifyErrors(result.error.data); // Show specific error message
      } else {
        notifyErrors(t("Something went wrong")); // Fallback error
      }
    } catch (error) {
      console.error("Error in updateRequest:", error);
    }
  };

  // Function to create a new order
  const sendRequest = async () => {
    console.log("Sending request with order:", order);

    try {
      const result = await createOrderApi(order);

      if (result?.data) {
        setOrderDetails(result.data); // Ensure order details are set
        setTickets(result.data.tickets || []); // Handle tickets if present
        setDeliveryFees(parseFloat(result.data.delivery_fees || 0));
        setCalDeliveryFees(parseFloat(result.data.delivery_fees || 0));
        setShowCalculations(true); // Show calculation section
        dispatch(getSeller({})); // Refresh seller data
        dispatch(getClient({})); // Refresh client data
        dispatch(getTicketPrintedData(result.data)); // Update printed ticket data
      } else if (result.error?.data) {
        notifyErrors(result.error.data); // Show specific error message
      } else {
        notifyErrors(t("Something went wrong")); // Fallback error
      }
    } catch (error) {
      console.error("Error in sendRequest:", error);
    }
  };

  const getBranches = async (onlyNew = false) => {
    const result = await getBranchesApi({ page: branchesPage, search: branchesSearchTerm });
    if (result) {
      setBranches(onlyNew ? result.data.data : [...branches, ...result.data.data]);
      setLoadMore(result.data.next !== null);
      if (result.data.next !== null) setBranchesPage(branchesPage + 1);
    }
  };

  const search = (value) => {
    setBranchesPage(1);
    setBranchesSearchTerm(value);
  };

  const onScroll = async (event) => {
    if (loadMore && event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight) {
      await getBranches();
    }
  };

  const previous = () => {
    handleChange(null, parseInt(value) - 1);
    dispatch(addOrder(order));
  };

  const getSellerInfo = async () => {
    if (user.role === "Seller") {
      const result = await getSellerApi(user.user.user_id);
      if (result.data) setSellerInfo(result.data);
    }
  };

  useEffect(() => {
    getSellerInfo();
    getBranches(true);
    getOrderTypes();
  }, []);

  useEffect(() => {
    getBranches(true);
  }, [branchesSearchTerm]);

  useEffect(() => {
    console.log("Delivery Fees:", deliveryFees, "Calculated Delivery Fees:", calDeliveryFees, "Are fees equal?", deliveryFees === calDeliveryFees);
  }, [deliveryFees, calDeliveryFees]);

  const [form] = Form.useForm();

  const archive_order = async () => {
    try {
      console.log("Archiving order:", order);
      const sellerData = await getSellerInfo(order.seller);

      const orderData = {
        ...order,
        delivery_fees: deliveryFees,
        sellerData // Use the fetched sellerData directly
      };

      const response = await createArchivedOrderApi({ archived_data: orderData });
      if (response.error?.data) {
        notifyErrors(response.error.data);
      } else if (response.error) {
        notifyErrors(t("Something went wrong"));
      } else {
        console.log("Order Archived:", response.data);
      }
      // Handle success, maybe update UI or show a message
    } catch (error) {
      console.error("Error archiving order:", error);
      // Handle error, show an error message or take appropriate action
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={validate}
        initialValues={{
          payment_method: "",
          note: "",
          branch: orderDetails?.branch
        }}
        style={{
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
        }}
        layout="vertical"
      >

        <Row>
          <Col sm="12" md="6">
            <div className="position-relative text-area-form">
              <div className="addressCard">
                <label>{t("shippmentType")}</label>
                <Form.Item name="type" rules={[{ required: true }]}>
                  <Radio.Group>
                    <Radio value="1" className="radio-btn">
                      {t("complete")}
                    </Radio>
                    <Radio value="2" className="radio-btn">
                      {t("partial")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </Col>

          <Col sm="12" md="6">
            <div
              className="addressCard"
              onChange={(e) => {
                if (["1", "2", "3"].includes(e.target.value)) {
                  setPaymentType(e.target.value);
                }
              }}
            >
              <label>{t("paymentMethod")}</label>
              <Form.Item name="payment_method" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="2" className="radio-btn">
                    {t("cash")}
                  </Radio>
                  <Radio value="1" className="radio-btn">
                    {t("online")}
                  </Radio>
                  <Radio value="3" className="radio-btn">
                    {t("POS")}
                  </Radio>
                </Radio.Group>
              </Form.Item>

            </div>
          </Col>

          <Col sm="12" md="6">
            <div className="position-relative text-area-form">
            {user.role !== "Seller" && (
                <>
                  <Form.Item
                    name="branch"
                    label={t("sideMenu.branches")}
                    rules={[{ required: true }]}
                    style={{ marginTop: "20px" }}
                  >
                    <Select
                      showSearch
                      placeholder={t("sideMenu.branches")}
                      defaultValue={orderDetails?.branch}
                      onSearch={search}
                      onPopupScroll={onScroll}
                      allowClear={false}
                    >
                      {branches.map((branch) => (
                        <Select.Option key={branch.id} value={branch.id}>
                          {branch.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <p className="error">{branchErr}</p>
                </>
            )}

              <Form.Item name="note" label={t("notes")}>
                <Input.TextArea
                  rows={4}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                />
              </Form.Item>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="position-relative text-area-form" style={{ marginTop: "20px" }}>
              <Form.Item name="order_type_instance" label={t("orderType")} rules={[{ required: false }]}>
                <Select
                  showSearch
                  placeholder={t("Choose Order Type")}
                  // value={selectedOrderType}
                  optionFilterProp="label"
                  filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                  allowClear={false}
                  options={orderTypes.map((orderType) => ({
                    label: orderType.name,
                    value: orderType.id
                  }))}
                  loading={getOrderTypesLoading}
                  // onChange={(value) => setSelectedOrderType(value)}
                />
              </Form.Item>
            {paymentType === "2" && (
                <Form.Item name="cod" label={t("CashOnDelivery")}>
                  <Input
                    type="number"
                    onChange={(e) => setCod(e.target.value)}
                    value={cod}
                  />
                  <div className="cod-disclaimer" style={{ marginTop: "8px", color: "blue" }}>
                    {t("cod_disclaimer")}
                  </div>
                </Form.Item>
            )}

              {paymentType === "3" && (
                <Form.Item name="pos" label={t("Pos")} rules={[{ required: true }]}>
                  <Input
                    type="number"
                    onChange={(e) => setPos(e.target.value)}
                    value={pos}
                  />
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>

        {user.role === "Seller" && sellerInfo?.seller_addresses && (
  <Row className="my-4">
    <Col sm="12" md="6">
      <div className="d-flex flex-column justify-content-start align-items-start gap-3 w-100">
        <Form.Item
          name="seller_address"
          label="Seller Address" // Optional: add a label if needed
          rules={[{ required: true, message: "Please select a seller address!" }]}
        >
          <Radio.Group onChange={(e) => form.setFieldsValue({ seller_address: e.target.value })}>
            {sellerInfo.seller_addresses.map((address) => (
              <Radio key={address.id} value={address.id} className="radio-btn">
                {address.address}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
    </Col>
  </Row>
        )}

        <OrderSummary orderDetails={orderDetails} onShipmentFeesChange={setCalDeliveryFees} />

        <Row style={{ marginInlineEnd: "-32px" }}>
          <Col className="text-end buttons-margin">
            <Button className="cancel" onClick={discard}>
              {t("discard")}
            </Button>
            <Button className="previous" onClick={previous}>
              {t("previous")}
            </Button>

            {orderDetails
              ? (
                  deliveryFees === calDeliveryFees
                    ? (
              <input
                className="confirm doneBtn mx-2"
                onClick={showConfirmationModal}
                value={t("submit")}
              />
                      )
                    : (
              <Button
                onClick={updateRequest}
                className="calculateBtn mx-2"
                type="button"
                disabled={loading}
              >
                {t("update")}
              </Button>
                      )
                )
              : (
            <input className="calculateBtn mx-2" value={t("calculate")} type="submit" />
                )}

          </Col>
        </Row>

        {showCalculations && orderDetails && tickets && (
          <Row>
            <Col sm="12">
              {/* <button className="confirm" disabled={printedTickets.length === 0}>
                <a
                  style={{ color: "white" }}
                  href={`${BaseUrl}/CreateInvoice/?tickets=${printedTickets.join(",")}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("exportToPDF")}
                </a>
              </button> */}

            </Col>
          </Row>
        )}
      </Form>

      <OrderConfirmationModal show={show} hide={hide} id={orderDetails?.id} />
      <OrderDeleteModal show={showDelete} hide={hideDelete} id={orderDetails?.id} />

    </>
  );
}
