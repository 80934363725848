import i18n from "i18next";
import { errorsHandler } from "./ErrorNotifier";

export default function formattedError (error, rtk = false) {
  if (rtk) {
    return {
      success: false,
      error: error.error?.data ? errorsHandler(error.error.data) : i18n.t("server_error"),
      status: error.error?.status || 500
    };
  }
  return {
    success: false,
    error: error?.response?.status < 500
      ? error?.response.data
      : i18n.t("server_error"),
    status: error?.response?.status || 500
  };
}
