import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { addRunsheet, useRunsheet } from "../../../stateManagement/slices/addRunsheetSlice";
import { search } from "../../../stateManagement/slices/searchSlice";
import {
  useCourierColumns
} from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import { useFilterCouriersMutation } from "../../../stateManagement/apis/accountsApi";

function ChooseCouriersStep ({ value, handleChange }) {
  const searchDispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const dispatchCourier = useDispatch();
  const selectedBranches = useRunsheet()?.branches;
  const [filterCouriers] = useFilterCouriersMutation();

  useEffect(() => {
    if (selectedRows[0] === undefined) return;
    dispatch(addRunsheet({ courier: selectedRows[0] }));
    dispatchCourier(
      addRunsheet({
        courierObj: data.find((it) => it.id === selectedRows[0])
      })
    );
  }, [selectedRows]);

  const getCouriers = async () => {
    const result = await filterCouriers({
      page,
      search: searchTerm,
      available: true,
      branch__in: `${selectedBranches.from?.id},${selectedBranches.to?.id}`
    });
    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  const searching = (v) => {
    setSearchTerm(v);
    searchDispatch(search(v));
  };

  useEffect(() => {
    getCouriers();
  }, [page, searchTerm]);

  const proceed = () => {
    handleChange(null, value + 1);
  };
  const { t } = useTranslation();
  return (
        <>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button
                        disabled={selectedRows.length === 0}
                        onClick={proceed}
                        className="confirm"
                    >
                        {t("choose")}
                    </button>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col sm="12">
                        <div>
                            <TablePro
                                dataSource={data}
                                columns={useCourierColumns()}
                                disableSelectionOnClick
                                paginationMode="server"
                                pagination={{
                                  current: page,
                                  total: count,
                                  onChange: (page) => setPage(page)
                                }}
                                selection={"radio"}
                                onSearch={searching}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
  );
}

export default ChooseCouriersStep;
