import React, { useState, useMemo } from "react";
import { Row, Col, Form, Input, Button, Space, Card } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import loginImg from "../../images/loginImg.png";
import sellerLoginImg from "../../images/sellerLoginImg.png";
import logoSeller from "../../images/logoSeller.png";
import logoAdmin from "../../images/logoAdmin.png";
import logoAdminAr from "../../images/arabicLogo.png";
import logoSellerAr from "../../images/sellerLogoAr.png";
import { useLoginMutation } from "../../stateManagement/apis/authApi";

import { notifyErrors } from "../../api/ErrorNotifier";
import "react-toastify/dist/ReactToastify.css";
import "../../style-sheets/login.css";

import { LanguageContext } from "../../components/contexts/LanguageContext";
import useLanguage from "../../hooks/useLanguage";

const Login = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailerr] = useState("");
  const [passErr] = useState("");
  const [loginApi, { isLoading: loading }] = useLoginMutation();
  const language = useLanguage();

  const isAdmin = useMemo(
    () =>
      location.pathname === "/login" ||
      location.pathname === "/login/ar" ||
      location.pathname === "/login/en",
    [location.pathname]
  );

  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (
      e.target.value.match(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      )
    ) {
      setEmail(e.target.value);
      setEmailerr("");
    } else {
      setEmailerr("Enter valid email!");
    }
  };

  const handlePass = (e) => {
    setPassword(e.target.value);
  };

  const submit = async () => {
    const response = await loginApi({ email, password });
    if (response.data) {
      navigate("/dashboard");
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors("Something went wrong");
    }
  };

  const languageChecker = (englishCallback, arabicCallback) =>
    language.includes("en") ? englishCallback : arabicCallback;

  return (
    <LanguageContext.Provider value={{ language }}>
      <div className="p-3 login-header" dir={languageChecker("ltr", "rtl")}>
        <img
          src={languageChecker(isAdmin ? logoAdmin : logoSeller, isAdmin ? logoAdminAr : logoSellerAr)}
          className="img-fluid"
          alt="Logo"
        />
        <button
          onClick={() => {
            const newLang = languageChecker("ar", "en");
            i18n.changeLanguage(newLang);
          }}
        >
          {languageChecker("ع", "EN")}
        </button>
      </div>

      <Space className="pageContainer" direction="vertical" size="large" style={{ width: "100%" }}>
        <Row justify="center">
          <Col xs={20} sm={10} md={8} style={{ marginInlineEnd: "10%" }} >
            <img src={isAdmin ? loginImg : sellerLoginImg} className="img-fluid" alt="Login Illustration" />
          </Col>

          <Col xs={20} sm={10} md={8} className="mt-5" >
            <Card
              title={<h3 className="title">{t("welcome")}</h3>}
              bordered={false}
              style={{
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                backgroundColor: "#fff"
              }}
            >
              <p className="subTitle">{t("welcomeSubtitle")}</p>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={submit}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: `${t("inputEmailErr")}` }]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder={t("email")}
                    value={email}
                    onChange={handleEmail}
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                      borderColor: "#d9d9d9"
                    }}
                  />
                </Form.Item>
                <p className="error">{emailErr}</p>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: `${t("inputPasswordErr")}` }]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t("password")}
                    value={password}
                    onChange={handlePass}
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                      borderColor: "#d9d9d9"
                    }}
                  />
                </Form.Item>
                <p className="error">{passErr}</p>

                <Form.Item className="forgot">
                  <a
                    className="login-form-forgot"
                    href={isAdmin ? "/admin_forget_password" : "/forget_password"}
                  >
                    {t("forgotPassword")}
                  </a>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={`login-form-button ${isAdmin ? "adminBtn" : "sellerBtn"}`}
                    loading={loading}
                    style={{
                      backgroundColor: "#57be6c",
                      color: "white",
                      padding: "12px",
                      fontSize: "16px",
                      borderRadius: "10px",
                      transition: "background-color 0.3s ease-in-out"
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#4aa55d")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#57be6c")}
                  >
                    {t("login")}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Space>
    </LanguageContext.Provider>
  );
};

export default Login;
