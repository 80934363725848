import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCloseRunsheetColumns } from "../layout/tableColumns";
import { useGetCourierSettableOrdersMutation, useSettleCourierOrdersMutation } from "../../stateManagement/apis/runsheetsApi";
import {
  CreationConfirmation,
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../modals/confirmationModal";
import TablePro from "../tables/TablePro";
import { stringErrorsHandler } from "../../api/ErrorNotifier";

function CloseRunsheet () {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  // State variables
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [courierName, setCourierName] = useState("");
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersValue, setOrdersValue] = useState(0);
  const [showFailed, setShowFailed] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showForce, setShowForce] = useState(false);
  const [error, setError] = useState("");

  // API hooks
  const [getCourierSettableOrders] = useGetCourierSettableOrdersMutation();
  const [settleCourierOrders] = useSettleCourierOrdersMutation();

  // Fetch orders data
  const fetchOrders = async () => {
    try {
      const result = await getCourierSettableOrders(id, { page });
      if (result.data) {
        setCourierName(result.data.courier_name);
        setData(result.data.data);
        setCount(result.data.count);
      } else {
        handleApiError(result.error?.data);
      }
    } catch {
      handleApiError();
    }
  };

  // Handle API errors
  const handleApiError = (errorData) => {
    setError(stringErrorsHandler(errorData) || "An unexpected error occurred");
    setShowFailed(true);
  };

  // Handle RS close request
  const closeRs = async (verdict = false) => {
    try {
      const payload = {
        courierId: id,
        force: verdict,
        money_returned: ordersValue,
        orders_money: selectionModel.map((order) => order.id)
      };

      const result = await settleCourierOrders(payload);
      if (result.data) {
        setShowSuccess(true);
      } else {
        handleApiError(result.error?.data);
      }
    } catch {
      handleApiError();
    }
  };

  // Calculate order sum
  useEffect(() => {
    if (selectionModel.length > 0) {
      const total = orders.reduce(
        (sum, order) => sum + parseFloat(order.total_amount ?? 0),
        0
      );
      setOrdersValue(total);
    }
  }, [orders]);

  // Fetch orders on page load and page change
  useEffect(() => {
    fetchOrders();
  }, [page]);

  // Modal handlers
  const hideForceModal = () => setShowForce(false);
  const hideSuccessModal = () => {
    setShowSuccess(false);
    navigate("/assigned_rs");
  };
  const hideFailedModal = () => {
    setShowFailed(false);
    navigate("/assigned_rs");
  };

  return (
    <>
      {/* Header */}
      <Row>
        <Col sm="6">
          <p className="orderID">
            {t("runsheetID")}#{id}
          </p>
        </Col>
      </Row>

      {/* Form Inputs */}
      <Row className="my-3">
        <Col sm="12" md="6">
          <label className="bold-label">{t("couriername")}</label>
          <Form.Control
            type="text"
            value={courierName}
            disabled
          />
        </Col>
        <Col sm="12" md="6">
          <label className="bold-label">{t("courierReturnAmount")}</label>
          <Form.Control
            type="number"
            value={selectionModel.length === 0 ? 0 : ordersValue}
            onChange={(e) => setOrdersValue(e.target.value)}
          />
        </Col>
      </Row>

      {/* Submit Button */}
      <Row className="my-2">
        <Col className="text-end buttons-margin" sm="12">
          <button
            disabled={selectionModel.length === 0}
            onClick={() => closeRs(false)}
            className="confirm"
          >
            {t("submit")}
          </button>
        </Col>
      </Row>

      {/* Table */}
      <TablePro
        dataSource={data.map((order) => ({ ...order, id: order.order_id }))}
        columns={useCloseRunsheetColumns()}
        selection="checkbox"
        unselectableRowsCondition={(row) => !row.available_to_settle}
        disable_reason={t("unsettledOrdersReason")}
        selectedRows={selectionModel}
        setSelectedRows={setSelectionModel}
        setSelectedRecords={setOrders}
        pagination={{
          current: page,
          total: count,
          onChange: setPage
        }}
      />

      {/* Modals */}
      <SuccessfulRequest
        title={t("settleTicket")}
        body={t("moneyRecievedText")}
        show={showSuccess}
        hide={hideSuccessModal}
      />
      <UnSuccessfulRequest
        title={t("settleTicket")}
        body={error}
        show={showFailed}
        hide={hideFailedModal}
      />
      <CreationConfirmation
        sendRequest={() => closeRs(true)}
        title={t("settleTicket")}
        body={error}
        show={showForce}
        hide={hideForceModal}
      />
    </>
  );
}

export default CloseRunsheet;
