import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import { notification, Form as AntForm, Button, Select, Input, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";

import WareHousesRepository from "../../../api/WarehousesRepository";
import { RULE_TYPES, COMPARISON_OPERATORS } from "./constants";

const WarehouseLocationRuleDetails = () => {
  const { t } = useTranslation(); // Translation hook for internationalization
  const navigate = useNavigate(); // Navigation hook for routing
  const { warehouseId, ruleId } = useParams(); // Get route parameters
  console.log(ruleId);
  console.log(warehouseId);
  const [locationRule, setLocationRule] = useState(); // State to store the location rule
  const [ruleForm] = AntForm.useForm(); // Ant Design form instance
  const [loading, setLoading] = useState(false); // Loading state
  const [submitting, setSubmitting] = useState(false); // Submitting state
  const [dirty, setDirty] = useState(false); // Tracks if the form has been modified
  const [locations, setLocations] = useState(); // List of available locations
  const [ruleFields, setRuleFields] = useState(); // Fields for the form
  const [warehouse, setWarehouse] = useState(); // Warehouse details

  // Fetch locations for the warehouse
  const getLocations = async () => {
    setLoading(true);
    const response = await WareHousesRepository.warehouseLocations(warehouseId, { page: 1, limit: 1000 });
    if (response.success) {
      setLocations(response.data.map((location) => ({ value: location.id, label: location.name })));
    } else {
      handleApiErrors(response);
    }
    setLoading(false);
  };

  // Fetch details of a specific location rule
  const getLocation = async () => {
    console.log(warehouseId);
    setLoading(true);
    const response = await WareHousesRepository.warehouseInventoryRule(warehouseId, ruleId);
    if (response.success) {
      setLocationRule(response.data);
    } else {
      handleApiErrors(response);
    }
    setLoading(false);
  };

  // Fetch warehouse details
  const getWarehouse = async () => {
    setLoading(true);
    const response = await WareHousesRepository.getWarehouse(warehouseId);
    if (response.success) {
      setWarehouse(response.data);
    } else {
      handleApiErrors(response);
    }
    setLoading(false);
  };

  // Handle API errors for cleaner error management
  const handleApiErrors = (response) => {
    if (response.status === 400) {
      Object.entries(response.error).forEach(([key, value]) =>
        notification.error({ message: Array.isArray(value) ? value[0] : value })
      );
    } else {
      notification.error({ message: response.error });
    }
  };

  // Set up form fields dynamically
  useEffect(() => {
    setRuleFields([
      {
        name: "name",
        label: t("Rule Name"),
        type: "text",
        rules: [{ required: true, message: t("requiredField") }]
      },
      {
        name: "location",
        label: t("Location"),
        type: "select",
        options: locations
      },
      {
        name: "priority",
        label: t("Priority"),
        type: "number",
        rules: [{ required: true, message: t("requiredField") }]
      },
      {
        name: "rule_type",
        label: t("Rule Type"),
        type: "select",
        options: Object.entries(RULE_TYPES).map(([value, label]) => ({ value, label })),
        rules: [{ required: true, message: t("requiredField") }]
      },
      {
        name: "comparison_operator",
        label: t("Comparison Operator"),
        type: "select",
        options: Object.entries(COMPARISON_OPERATORS).map(([value, label]) => ({ value, label })),
        rules: [{ required: true, message: t("requiredField") }]
      },
      {
        name: "comparison_value",
        label: t("Comparison Value"),
        type: "text",
        rules: [{ required: true, message: t("requiredField") }]
      }
    ]);
  }, [locations]);

  // Initialize component by fetching data
  useEffect(() => {
    getLocations();
    getWarehouse();
    if (ruleId !== "create") {
      getLocation();
    } else {
      setLocationRule({ id: "demo", name: "", code: "", address: "", branches: [] });
    }
  }, []);

  // Handle form submission
  const onFinish = async (values) => {
    setSubmitting(true);
    let response;
    if (ruleId === "create") {
      response = await WareHousesRepository.createWarehouseInventoryRule(warehouseId, values);
    } else {
      response = await WareHousesRepository.updateWarehouseInventoryRule(warehouseId, ruleId, values);
    }
    handleFormResponse(response);
    setSubmitting(false);
  };

  // Handle form submission response
  const handleFormResponse = (response) => {
    if (response.success) {
      notification.success({ message: t("Warehouse has been updated successfully") });
      setDirty(false);
      if (ruleId === "create") {
        navigate(`/warehouse/${warehouseId}/rules/`);
      }
    } else {
      processFormErrors(response);
    }
  };

  // Process form-specific errors
  const processFormErrors = (response) => {
    if (response.status === 400) {
      const fieldErrors = [];
      const nonFieldErrors = [];
      Object.entries(response.error).forEach(([key, value]) => {
        if (ruleFields.some((field) => field.name === key)) {
          fieldErrors.push({ name: key, errors: Array.isArray(value) ? value : [value] });
        } else {
          nonFieldErrors.push(Array.isArray(value) ? value[0] : value);
        }
      });
      ruleForm.setFields(fieldErrors);
      nonFieldErrors.forEach((error) => notification.error({ message: error }));
    } else {
      notification.error({ message: response.error });
    }
  };

  // Handle form field changes and set dirty state
  const handleChange = (event) => {
    ruleForm.setFieldValue(event.target.name, event.target.value);
    setDirty(ruleForm.isFieldsTouched(false));
  };

  return (
    <div>
      <Container>
        <Row className="mb-4">
          <Col sm="12">
            <span className="title1">{t("sideMenu.warehouses")}</span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title1">
              {warehouse?.name + " " + t("sideMenu.locationRules")}
            </span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">
              {ruleId === "create" ? t("createWarehouselocationRule") : t("editWarehouseLocationRule")}
            </span>
          </Col>
          <Col sm="12">
            <h2 className="pageTitle">
              {ruleId === "create" ? t("newWarehouseLocationRule") : locationRule?.name || t("editWarehouseLocationRule")}
            </h2>
          </Col>
        </Row>
        <Row>
          <div>
            {loading || !warehouse || !locations || !locationRule
              ? (
              <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "50vh" }}>
                <Spin size="large" />
              </div>
                )
              : (
              <AntForm
                layout="vertical"
                className="grid-form small-gap"
                form={ruleForm}
                onFinish={onFinish}
                initialValues={locationRule}
                requiredMark
                labelWrap
              >
                {ruleFields.map((field, index) => (
                  <AntForm.Item key={index} name={field.name} label={field.label} rules={field.rules || []}>
                    {field.type === "select"
                      ? (
                      <Select
                        mode={field.selectMultiple ? "multiple" : "default"}
                        placeholder={field.label}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => handleChange({ target: { name: field.name, value } })}
                      >
                        {field.options?.map((option, index) => (
                          <Select.Option key={index} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                        )
                      : (
                      <Input
                        type={field.type}
                        disabled={field.disabled}
                        placeholder={field.label}
                        onChange={handleChange}
                      />
                        )}
                  </AntForm.Item>
                ))}
                <div className="w-100 d-flex justify-content-end py-4">
                  <AntForm.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                      disabled={submitting || !dirty}
                      style={{ backgroundColor: "#57BE6C", color: "#fff", borderColor: "#57BE6C" }}
                    >
                      {ruleId === "create" ? t("Create Rule") : t("Update Rule")}
                    </Button>
                  </AntForm.Item>
                </div>
              </AntForm>
                )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default WarehouseLocationRuleDetails;
