import React, {
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { Form, Select } from "antd";
import { addRunsheet } from "../../../stateManagement/slices/addRunsheetSlice";
import { addRunsheetOrders } from "../../../stateManagement/slices/rsOrdersSlice";
import { isReAssigned } from "../../../stateManagement/slices/reassignedRSSlice";
import {
  useOrdersColumns
} from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import { useAvailableOrdersMutation } from "../../../stateManagement/apis/runsheetsApi";
import { useFilterCitiesMutation, useGetBranchesMutation } from "../../../stateManagement/apis/treecodeApi";

function ChooseOrdersStep ({ value, handleChange }) {
  const dispacthReassign = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState({
    from: undefined,
    to: undefined
  });
  const [cities, setCities] = useState([]);
  const [citySearchTerm, setCitySearchTerm] = useState("");
  const [selectedCities, setSelectedCities] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const fromBranches = useMemo(() => {
    const copy = [...allBranches];
    if (selectedBranches.to !== undefined) delete copy[selectedBranches.to];
    return copy ?? [];
  }, [allBranches, selectedBranches.to]);
  const toBranches = useMemo(() => {
    const copy = [...allBranches];
    if (selectedBranches.from !== undefined) { delete copy[selectedBranches.from]; }
    return copy ?? [];
  }, [allBranches, selectedBranches.from]);
  const [availableOrders] = useAvailableOrdersMutation();
  const [getBranchesApi] = useGetBranchesMutation();
  const [filterCities] = useFilterCitiesMutation();

  const columns = useOrdersColumns();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(value);
  }, []);
  const dispatchOrders = useDispatch();
  const getConfig = () => {
    return {
      params: {
        current_branch: allBranches[selectedBranches.from]?.id,
        page_size: 10,
        page,
        state: "1",
        client_address__area__city__in: selectedCities.join(","),
        search: searchTerm
      }
    };
  };
  const getOrders = async () => {
    if (selectedBranches.from === undefined) return;
    console.log({ selectedBranches });
    const result = await availableOrders(getConfig().params);
    if (result.data) {
      console.log(result.data.data);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  const getCity = async () => {
    const result = await filterCities({
      page_size: 20,
      search: citySearchTerm
    });
    if (result.data) {
      setCities(result.data.data);
    }
  };

  const getBranches = async () => {
    const result = await getBranchesApi({
      page_size: 10000
    });
    if (result.data) {
      setAllBranches(result.data.data);
    }
  };

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    getCity();
  }, [citySearchTerm]);

  useEffect(() => {
    getOrders();
  }, [page, selectedBranches.from, selectedCities, searchTerm]);

  useEffect(() => {
    console.log("SELECTED BRANCHES", selectedBranches);
    if (page !== 1) {
      setPage(1);
    } else {
      getOrders();
    }
  }, [searchTerm, selectedCities, selectedBranches.from]);

  const proceed = () => {
    dispatch(
      addRunsheet({
        branches: {
          from: allBranches[selectedBranches.from],
          to: allBranches[selectedBranches.to]
        }
      })
    );
    const selectedOrders = data.filter((it) =>
      selectedRows.includes(it.id)
    );
    dispatch(addRunsheet({ orders: selectedRows }));
    dispatchOrders(addRunsheetOrders(selectedOrders));
    handleChange(null, value + 1);
  };

  useEffect(() => {
    return () => {
      dispacthReassign(isReAssigned(false));
      console.log("cleaned up");
    };
  }, []);
  const { t } = useTranslation();

  return (
        <>
            <div>
                <Form layout="vertical" className="grid-form">
                    {Object.keys(selectedBranches).map((key) => (
                        <Form.Item key={key} label={t(`branches.${key}`)}>
                            <Select
                                allowClear
                                value={selectedBranches[key]}
                                onChange={(v) =>
                                  setSelectedBranches((prev) => ({
                                    ...prev,
                                    [key]: v
                                  }))
                                }
                            >
                                {{ from: fromBranches, to: toBranches }[
                                  key
                                ].map((it, i) => (
                                    <Select.Option value={i} key={it.id}>
                                        {it.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ))}
                </Form>
            </div>

            <Row className="my-2">
                <Col className="text-end buttons-margin" sm="12">

                    <button
                        disabled={
                            selectedRows.length === 0 ||
                            selectedBranches.from === undefined ||
                            selectedBranches.to === undefined
                        }
                        onClick={proceed}
                        className="confirm"
                    >
                        {t("next")}
                    </button>
                </Col>
            </Row>

            <div>
                <Row>
                    <Col sm="12" md="6" className="p-4"></Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div>
                            <TablePro
                                onSearch={setSearchTerm}
                                columns={columns.map((it, i) => {
                                  if (i === columns.length - 1) {
                                    return {
                                      title: t("status"),
                                      width: 270,
                                      dataIndex: "runsheet_type",
                                      render: (_, row) => (
                                                        <div
                                                            className={"HRS" + " btn btn-outline-primary mx-2"}
                                                        >
                                                            {"HRS"}
                                                        </div>
                                      )

                                    };
                                  } else {
                                    return it;
                                  }
                                })}
                                selection={"checkbox"}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                dataSource={data}
                                pagination={{
                                  size: "medium",
                                  pageSize: 10,
                                  total: count,
                                  current: page,
                                  onChange: (p) => setPage(p)
                                }}
                                headerExtra={
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        value={selectedCities}
                                        onChange={(v) => {
                                          setSelectedCities(v);
                                          setCitySearchTerm("");
                                        }}
                                        placeholder={t("Filter by city")}
                                        onSearch={setCitySearchTerm}
                                        filterOption={false}
                                        style={{ width: "200px" }}
                                        showArrow
                                        showSearch
                                        onClear={() => {
                                          setSelectedCities([]);
                                          setCitySearchTerm("");
                                        }}
                                        options={cities.map((it) => ({
                                          value: it.id,
                                          label: it.name
                                        }))}
                                    />
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
  );
}

export default ChooseOrdersStep;
