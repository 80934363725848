import { SettingsIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import Settings from "../../components/pages/settings/settings";
import Organization from "../../components/pages/settings/organization";
import Security from "../../components/pages/settings/security";

import Areas from "../../components/pages/areas";
import BranchesPage from "../../components/pages/Branch";
import AllPreferences from "../../components/pages/settings/Preferences/AllPreferences";
import TrakingBoard from "../../components/pages/settings/Preferences/TrakingBoard";
import SystemTheme from "../../components/pages/settings/Preferences/systemTheme";
import TableHeaders from "../../components/pages/settings/Preferences/TableHeaders";
import UpdatePassword from "../../components/pages/settings/UpdatePassword";
import LanguageCurrency from "../../components/pages/settings/LanguageCurrency";
import Plugins from "../../features/admin/plugins/Plugins";
import CommentsPage from "../../features/admin/comments/comments";
import AwbType from "../../components/pages/settings/AwbType";
import OrderTypes from "../../components/pages/settings/OrderTypes";
import OrderTypeDetails from "../../components/pages/settings/OrderTypes/OrderTypeDetails";

export default function useSettingsRoutes () {
  const { t } = useTranslation();
  return {
    "/settings": {
      element: Settings,
      icon: SettingsIcon,
      title: t("sideMenu.settings")
    },
    "/organization": {
      element: Organization
    },
    "/security": {
      element: Security
    },
    "/areas/:branch/:id": {
      element: Areas
    },
    "/areas": {
      element: Areas
    },
    "/branches": {
      element: BranchesPage
    },
    "/preferences": {
      element: AllPreferences
    },
    "/traking": {
      element: TrakingBoard
    },
    "/theme": {
      element: SystemTheme
    },
    "/create_headers": {
      element: TableHeaders
    },
    "/update_password": {
      element: UpdatePassword
    },
    "/lang_settings": {
      element: LanguageCurrency
    },
    "/plugins": {
      element: Plugins
    },
    "/comments": {
      element: CommentsPage
    },
    "/awb_label_type": {
      element: AwbType
    },
    "/order_types": {
      element: OrderTypes
    },
    "/order_types/:orderTypeId": {
      element: OrderTypeDetails
    }
  };
}
