import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import PhoneIcon from "@mui/icons-material/Phone";
import { Spin, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import { useAuthedUser } from "../../stateManagement/slices/userSlice";
import { useDownloadLabelMutation } from "../../stateManagement/apis/treecodeApi";
import TicketCard from "./TicketCard";

const antIcon = <LoadingOutlined style={{ fontSize: 115 }} spin />;

const OrderDetails = ({
  tickets = { data: [] },
  current,
  pagination,
  ticketsCount,
  loading,
  show,
  data,
  setClose
}) => {
  const { t } = useTranslation();
  const lang = useLanguage();
  const cur = useAuthedUser().organization.country.currency;
  const [downloadLabelApi, { isLoading: loadingDownloadOrder }] = useDownloadLabelMutation();

  const closeModal = () => setClose(false);

  useEffect(() => {
    console.log("Data:", data);
    console.log("Tickets:", tickets);
    console.log("Tickets Count:", ticketsCount);
  }, [data, tickets, ticketsCount]);

  const modalClasses = ["Modal detailsCard", show ? "ModalOpen" : "ModalClosed"];

  const downloadLabel = async (tickets, type) => {
    const queryParams = {
      order: type === "order" ? tickets : null,
      tickets: type !== "order" ? tickets : null,
      lang: localStorage.getItem("lang", "en")
    };
    Object.keys(queryParams).forEach((key) => !queryParams[key] && delete queryParams[key]);
    const result = await downloadLabelApi(queryParams);
    if (result.data) {
      console.log(result.data);
    }
  };

  const totalCost = parseFloat(data.delivery_fees) + parseFloat(data.sub_total);

  return (
    <div dir={lang === "en" ? "ltr" : "rtl"} className={modalClasses.join(" ")}>
      {loading
        ? (
        <Spin indicator={antIcon} />
          )
        : (
        <>
          {/* Header Section */}
          <div className="sectionPadding w-100 d-flex justify-content-start align-items-center gap-3 mb-3">
            <p>
              <i onClick={closeModal} className="fas fa-times" />
            </p>
            <h3 className="m-0">
              {lang === "en"
                ? `Order ID#${data.id}`
                : `رقم الشحنة ${data.id}`}
            </h3>
            <div>
              <span>{data.type}</span>
            </div>
            <div className="ms-auto d-inline-block" role="button">
              <span
                className="downloadLabel"
                onClick={() => downloadLabel(data.id, "order")}
              >
                {loadingDownloadOrder ? <Spin indicator={antIcon} /> : <i className="fas fa-download"></i>}
              </span>
            </div>
          </div>

          {/* Cost Section */}
          <div className="costSection">
            <table className="costTable">
              <thead>
                <tr>
                  <th></th>
                  <th>{t("shipment")}</th>
                  <th>{t("products")}</th>
                  <th>{t("total")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.payment_method}</td>
                  <td>
                    {t(cur)} <span className="priceSpan">{data.delivery_fees}</span>
                  </td>
                  <td>
                    {t(cur)} <span className="priceSpan">{data.sub_total}</span>
                  </td>
                  <td>
                    {t(cur)} <span>{totalCost}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Receiver Info Section */}
          <div className="section">
            <h5>{t("Sender Info")}</h5>
            <Row>
                <Col sm="6">
                    <p>
                        <i className="fas fa-user userIcons" />
                        {data.seller.store_name}
                    </p>
                </Col>
                <Col sm="6">
                    <p>
                        <PhoneIcon className="phoneIcon" />{" "}
                        {data.seller.user.phone}
                    </p>
                </Col>
                {data.seller_address.address && data.seller_address.address !== "N/A" &&
                        <Col sm="6">
                            <p>
                                <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                            <span>{data.seller_address.address}</span>{" "}
                            </p>
                        </Col>
                }
                <Col sm="6">
                        {
                            data.seller_address.lat && data.seller_address.lng && (
                                <p>
                                    <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                                <a href={`https://www.google.com/maps/search/?api=1&query=${data.seller_address.lat},${data.seller_address.lng}`} target="_blank" rel="noreferrer">
                                    {t("Show Address on Map")}
                                </a>
                                </p>
                            )
                        }
                </Col>
                {
                    data.seller_address.area &&
                    (<Col sm="6">
                        <p>
                            <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                            <span>{lang === "en" ? data.seller_address.area.name : data.seller_address.area.name_ar}</span>{" "}
                        </p>
                    </Col>)
                }
                {
                    data.seller.phone_alt && (
                    <Col sm="6">
                        <p>
                            <PhoneIcon className="phoneIcon" />{" "}
                            01094970654
                        </p>
                    </Col>
                    )
                }
            </Row>
        </div>
        <div className="section">
            <h5>{t("receiverInfo")}</h5>
            <Row>
                <Col sm="6">
                    <p>
                        <i className="fas fa-user userIcons" />
                        {data.client.name}
                    </p>
                </Col>
                <Col sm="6">
                    <p>
                        <PhoneIcon className="phoneIcon" />{" "}
                        {data.client.phone}
                    </p>
                </Col>
                <Col sm="6">
                    {
                        data.client_address.lat && data.client_address.lng && (
                            <p>
                                <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                                <a href={`https://www.google.com/maps/search/?api=1&query=${data.client_address.lat},${data.client_address.lng}`} target="_blank" rel="noreferrer">
                                    {t("Show Address on Map")}
                                </a>
                            </p>
                        )
                    }
                </Col>
                {
                    data.client_address.address && data.client_address.address !== "N/A" &&
                    <Col sm="6">
                        <p>
                            <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                            <span>{data.client_address.address}</span>{" "}
                        </p>
                    </Col>
                }
                {
                    data.client_address.area &&
                    (<Col sm="6">
                        <p>
                            <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                            <span>{lang === "en" ? data.client_address.area.name : data.client_address.area.name_ar}</span>{" "}
                        </p>
                    </Col>)
                }
                {
                    data.client.phone_alt && (
                    <Col sm="6">
                        <p>
                            <PhoneIcon className="phoneIcon" />{" "}
                            01094970654
                        </p>
                    </Col>
                    )
                }
            </Row>
        </div>

          {/* Product Details Section */}
          <div className="section">
            <Row>
              <Col sm={12}>
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <h5 className="m-0">{t("productDetails")}</h5>
                  <span
                    className="text-primary"
                    role="button"
                    onClick={() =>
                      downloadLabel(
                        data.tickets.map((ticket) => ticket.id).join(","),
                        "all"
                      )
                    }
                  >
                    {t("downloadAllLabels")}
                  </span>
                </div>
                <Pagination
                  pageSize={4}
                  onChange={pagination}
                  defaultCurrent={current}
                  total={ticketsCount}
                  current={current}
                />
              </Col>
            </Row>
            <Row>
  {data?.tickets?.length > 0
    ? (
        data.tickets.map((ticket) => (
      <Col key={ticket.id} sm={6}>
        <TicketCard data={ticket} downloadLabel={downloadLabel} />
      </Col>
        ))
      )
    : (
    <p>{t("noTicketsAvailable")}</p>
      )}
</Row>
          </div>
        </>
          )}
    </div>
  );
};

export default OrderDetails;
