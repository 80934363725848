const downloadHandler = async (response, fileName) => {
  if (!response.ok) {
    return { success: false, message: "Failed to download file" };
  }
  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  if (!fileName) {
    const contentDisposition = response.headers.get("content-disposition") || response.headers.get("Content-Disposition");
    fileName = contentDisposition.split("filename=")[1];
  }
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);

  return { success: true };
};

export default downloadHandler;
