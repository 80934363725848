import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    reduxBranch: (state, action) => {
      state.branch = action.payload;
    }
  }
});

export const { reduxBranch } = branchSlice.actions;
export const useBranch = () => useSelector((state) => state.branch.branch);

export default branchSlice.reducer;
