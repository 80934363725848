import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  selectedState: ""
};

const stateSlice = createSlice({
  name: "selectedState",
  initialState,
  reducers: {
    selectedState: (state, action) => {
      state.selectedState = action.payload;
    }
  }
});

export const { selectedState } = stateSlice.actions;
export const useSelectedState = () => useSelector((state) => state.selectedState.selectedState);
export default stateSlice.reducer;
