import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";

import "../../../style-sheets/table.css";
import { useWarehouseLocationRulesColumns } from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import WareHousesRepository from "../../../api/WarehousesRepository";

const WarehouseLocationRules = () => {
  const { t } = useTranslation(); // Translation hook
  const navigate = useNavigate(); // Navigation hook
  const { warehouseId } = useParams(); // Get warehouseId from route parameters

  // State variables
  const [queryParams, setQueryParams] = useState({ page: 1, limit: 10, search: "" });
  const [locationRules, setLocationRules] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [warehouse, setWarehouse] = useState({});
  const [activateLoading, setActivateLoading] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);

  // Handles activation/deactivation of a rule
  const handleActivate = async (id, is_active) => {
    console.log("is_active", is_active);
    console.log("id", id);
    setActivateLoading(true);
    setSelectedRule(id);
    try {
      const response = await WareHousesRepository.updateWarehouseInventoryRule(warehouseId, id, { is_active });
      if (response.success) {
        notification.success({ message: t("Location has been updated successfully") });
        getRules(); // Refresh the rules
      } else {
        handleError(response);
      }
    } finally {
      setActivateLoading(false);
      setSelectedRule(null);
    }
  };

  // Fetches location rules based on query parameters
  const getRules = async () => {
    setLoading(true);
    try {
      const response = await WareHousesRepository.warehouseInventoryRules(warehouseId, queryParams);
      if (response.success) {
        // Ensure each rule includes the warehouse ID
        const enrichedData = response.data.map((rule) => ({
          ...rule,
          warehouse: warehouseId
        }));
        setLocationRules(enrichedData);
        setCount(response.count);
      } else {
        handleError(response);
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetches warehouse details
  const getWarehouse = async () => {
    setLoading(true);
    try {
      const response = await WareHousesRepository.getWarehouse(warehouseId);
      if (response.success) {
        setWarehouse(response.data);
      } else {
        handleError(response);
      }
    } finally {
      setLoading(false);
    }
  };

  // Handles errors returned by the API
  const handleError = (response) => {
    if (response.status === 400) {
      Object.entries(response.error).forEach(([key, value]) =>
        notification.error({ message: Array.isArray(value) ? value[0] : value })
      );
    } else {
      notification.error({ message: response.error });
    }
  };

  // Updates query parameters
  const handleChangeQueryParams = (param, value) => {
    setQueryParams((prev) => ({
      ...prev,
      [param]: value,
      page: param === "page" ? value : 1 // Reset to page 1 for non-page changes
    }));
  };

  // Fetch warehouse details on component mount
  useEffect(() => {
    getWarehouse();
  }, []);

  // Fetch location rules when queryParams change
  useEffect(() => {
    getRules();
  }, [queryParams]);

  // Columns for the table with activation handler
  const columns = useWarehouseLocationRulesColumns(handleActivate, activateLoading, selectedRule, warehouseId);

  return (
    <div>
      <Container>
        {/* Page Header */}
        <Row className="mb-4">
          <Col sm="12">
            <span className="title1">{t("sideMenu.warehouses")}</span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">{t("sideMenu.warehouseLocationsRules")}</span>
          </Col>
          <Col sm="12">
            <h2 className="pageTitle">
              {warehouse.name} {t("sideMenu.locationsRules")}
            </h2>
          </Col>
        </Row>

        {/* Add Rule Button */}
        <Row className="mb-4">
          <Col className="text-end" sm="12">
            <button
              onClick={() => navigate(`/warehouse/${warehouseId}/rules/create`)}
              className="calculateBtn"
            >
              {t("addInventoryRule")}
            </button>
          </Col>
        </Row>

        {/* Rules Table */}
        <Row>
          <TablePro
            loading={loading}
            emptyPlaceholder={
              <div>
                <h6 className="noDataHead">{t("noDataFound")}</h6>
                <p>
                  {t(
                    "There are no location rules available, click on the add inventory rule button to add a new location rule"
                  )}
                </p>
              </div>
            }
            onSearch={(value) => handleChangeQueryParams("search", value)}
            dataSource={locationRules}
            columns={columns}
            pagination={{
              current: queryParams.page,
              total: count,
              onChange: (page) => handleChangeQueryParams("page", page)
            }}
          />
        </Row>
      </Container>
    </div>
  );
};

export default WarehouseLocationRules;
