import React, { useEffect, useState } from "react";
import { Col, Row, Progress, Typography, Card, Modal, Calendar } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CommentOutlined, QuestionCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  DeliveredProcedureOutlined,
  DatabaseOutlined,
  ReloadOutlined,
  HourglassOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import useLanguage from "../../../../hooks/useLanguage";
import StatusCard, { InventoryStatusCard } from "../../../../components/layout/StatusCard";

import { useUserObject } from "../../../../stateManagement/slices/userSlice";

const { Title } = Typography;

export default function CardsHeader ({
  loading,
  status,
  currentStateTitle,
  selectState,
  summaryFilters,
  cityFilter,
  plan,
  inventory
}) {
  const { t } = useTranslation();
  const lang = useLanguage();
  const user = useUserObject();

  const contactSupport = () => {
    document.getElementsByClassName("b24-widget-button-inner-block")[0].click();
  };

  const conicColors = {
    "0%": "#87d068",
    "50%": "#ffe58f",
    "100%": "#ffccc7"
  };

  const [animatedPercent, setAnimatedPercent] = useState(0);
  const targetPercent = plan?.orders_count ? (plan.remaining_orders / plan.orders_count) * 100 : 0;

  useEffect(() => {
    let timer;
    if (animatedPercent < targetPercent) {
      timer = setInterval(() => {
        setAnimatedPercent((prev) => {
          const newPercent = prev + 1;
          if (newPercent >= targetPercent) {
            clearInterval(timer);
            return targetPercent;
          }
          return newPercent;
        });
      }, 20);
    }
    return () => clearInterval(timer);
  }, [targetPercent]);

  return (
    <header>
      <Row gutter={[24, 24]} justify="center" >
        <Col xs={24} lg={16} justify="center" align="middle">
          <Row gutter={[16]} >
            {loading
              ? (
              <div

              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: "20px",
                width: "100%"
              }}>
                <div
                style={{
                  width: "100%",
                  height: "200px",
                  background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.5s infinite",
                  borderRadius: "8px"
                }} />
              </div>
                )
              : (
                  status.length > 0
                    ? (
                  // Update this part within the map loop for rendering each `StatusCard`
                        status.map((state, index) => (
                        <Col
                          key={state.state.id}
                          onClick={() => selectState(state, status[index + 1])}
                          xs={24}
                          sm={12}
                          md={8}
                          style={{ cursor: "pointer" }}
                        >
                          <StatusCard
                            inventory={inventory}
                            id={state.state.id}
                            selectedCard={currentStateTitle}
                            description={state.state.description}
                            summaryFilters={summaryFilters}
                            cityFilter={cityFilter}
                            type={lang === "ar" ? state.state.title_ar : state.state.title}
                            number={state.count}
                            background={state.state.accent_color}
                            styleClass="statusCard"
                            style={{
                              borderLeft: `solid 5px ${state.state.color}`,
                              height: "200px", // Fixed height for consistent dimensions
                              textAlign: "center",
                              overflow: "hidden",
                              whiteSpace: "nowrap", // Keep text on one line
                              textOverflow: "ellipsis" // Add ellipsis for overflow text
                            }}
                          />
                        </Col>

                        ))
                      )
                    : (
                <Col span={24} style={{ textAlign: "center", padding: "20px" }}>
                  <Title level={3}>{t("noOrders")}</Title>
                </Col>
                      )
                )}
          </Row>
        </Col>

        <Col xs={24} lg={8}>
          {!["Seller", "Third Party"].includes(user.role) && (
            <div
              className="statusCard p-4 my-4"
              style={{
                textAlign: "center",
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                marginBottom: "20px"
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "#333",
                  marginBottom: "10px"
                }}
              >
                {t("packageUsage")}
              </p>
              <p
                style={{
                  color: "#57be6c",
                  fontWeight: "bold",
                  fontSize: "14px",
                  margin: "3px 0px"
                }}
              >
                 ( {lang === "ar" ? plan?.name_ar : plan?.name}  )
              </p>
              <Progress
                type="dashboard"
                percent={animatedPercent}
                status="active"
                showInfo={true}
                strokeColor={conicColors}
                strokeWidth={10}
                style={{ margin: "20px auto" }}
              />
              <p style={{ color: "#333", fontWeight: "500" }}>
                {t("orderPackage")}:{plan?.remaining_orders}  {t("outOf")} {plan?.orders_count}
              </p>
              <Link
                to="/payments"
                style={{
                  display: "inline-block",
                  marginTop: "20px",
                  fontSize: "14px",
                  color: "#57be6c",
                  fontWeight: 500,
                  textDecoration: "underline"
                }}
              >
                {t("payments")}
              </Link>
            </div>
          )}
          <Card
              className="statusCard"
              style={{
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px"
              }}
            >
              <Typography.Title level={4} style={{ marginBottom: "24px" }}>
                {t("needHelp")}
              </Typography.Title>

              <Row gutter={16} style={{ marginBottom: "10px" }}>
                <Col onClick={contactSupport} style={{ cursor: "pointer" }}>
                  <CommentOutlined style={{ fontSize: "24px", color: "#57be6c", margin: "8px" }} />
                  <Typography.Text strong>{t("contactSupport1")}</Typography.Text>
                  <Typography.Text style={{ color: "#888", display: "block", marginTop: "5px" }}>
                    {t("contactSupport2")}
                  </Typography.Text>
                </Col>
              </Row>

              <hr style={{ width: "100%", margin: "10px 0" }} />

              <Row gutter={16}>
                <Col style={{ cursor: "pointer" }}>
                  <a
                    href="https://lace-booth-6cf.notion.site/427d045aa04a4b28ae4aad271700c2bb"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "black", textDecoration: "none", display: "block", marginBlockStart: "1em", marginBlockEnd: "1em" }}
                  >
                  <QuestionCircleOutlined style={{ fontSize: "24px", color: "#57be6c", margin: "8px " }} href="https://lace-booth-6cf.notion.site/427d045aa04a4b28ae4aad271700c2bb" />
                    <Typography.Text strong>{t("helpCenter1")}</Typography.Text>
                    <Typography.Text style={{ color: "#888", display: "block", marginTop: "5px" }}>
                      {t("helpCenter2")}
                    </Typography.Text>
                  </a>
                </Col>
              </Row>
            </Card>
        </Col>
      </Row>

      <style>
        {`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }
        `}
      </style>
    </header>
  );
}

export const InventoryCardsHeader = ({
  loading,
  status,
  currentStateTitle,
  selectState,
  summaryFilters,
  cityFilter,
  plan,
  inventory
}) => {
  const { t } = useTranslation();
  const [calendarVisible, setCalendarVisible] = useState(false);

  // Icon mapping based on state IDs
  const getStatusIcon = (id) => {
    switch (id) {
      case "INTERNAL_TRANSFER":
        return <SyncOutlined style={{ fontSize: "24px", color: "#126AFC" }} />;
      case "PENDING":
        return <ClockCircleOutlined style={{ fontSize: "24px", color: "#FFD700" }} />;
      case "STOCK_AVAILABLE":
        return <DatabaseOutlined style={{ fontSize: "24px", color: "#57BE6C" }} />;
      case "STOCK_DELIVERED":
        return <DeliveredProcedureOutlined style={{ fontSize: "24px", color: "#57BE6C" }} />;
      case "STOCK_RESERVED":
        return <CheckCircleOutlined style={{ fontSize: "24px", color: "#148060" }} />;
      case "STOCK_RETURNED":
        return <ReloadOutlined style={{ fontSize: "24px", color: "#FF4500" }} />;
      case "STOCK_WAITING":
        return <HourglassOutlined style={{ fontSize: "24px", color: "#FFD3D3" }} />;
      default:
        return null;
    }
  };

  const renderCardRow = (cards) =>
    cards.map((state) => (
      <Col
        key={state.state.id}
        xs={24}
        sm={12}
        md={12}
        lg={12}
        style={{
          marginBottom: "16px" // Space between rows
        }}
      >
        <InventoryStatusCard
          inventory={inventory}
          id={state.state.id}
          description={state.state.description}
          summaryFilters={summaryFilters}
          cityFilter={cityFilter}
          type={state.state.title}
          number={state.count}
          background={state.state.accent_color}
          selectedCard={currentStateTitle}
          onClick={() => selectState(state)}
          icon={getStatusIcon(state.state.id)} // Pass the mapped icon here
        />
      </Col>
    ));

  // Split states into two groups of 4
  const firstColumnStates = status.slice(0, 3);
  const secondColumnStates = status.slice(3, 8);
  const handleCalendarClick = () => {
    setCalendarVisible(true); // Open the calendar modal
  };

  const handleCancelCalendar = () => {
    setCalendarVisible(false); // Close the calendar modal
  };

  const currentDate = new Date();
  const formattedDate = `${currentDate.toLocaleString("default", { month: "long" })} ${currentDate.getFullYear()} - Today`;
  return (
    <header className="m-4">
          {/* Title Section */}
          <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "24px" }} // Added padding for better responsiveness
            >
          <Col xs={24} sm={24} md={12} lg={12}>
            <Typography.Title level={3} style={{ fontWeight: "bold", fontSize: "24px" }}>
              {t("sideMenu.dashboard")}
            </Typography.Title>
          </Col>

          <Col
            xs={24} // Ensure it takes full width on small screens
            sm={12} // Half width on small screens
            md={8} // Adjust width for medium screens
            lg={6} // Adjust width for large screens
            style={{
              border: "1px solid #57BE6C", // Border with specified color
              borderRadius: "8px", // Rounded corners
              padding: "8px 16px", // Padding around the text
              display: "flex", // Align items horizontally
              alignItems: "center", // Vertically center the text and icon
              cursor: "pointer", // Make it clickable
              marginTop: "8px", // Slight margin on top for smaller screens
              justifyContent: "space-between", // Space between date text and icon
              marginLeft: "4%"
            }}
            onClick={handleCalendarClick}
          >
                <Typography.Text
                  style={{
                    fontSize: "16px", // Font size for the date text
                    textOverflow: "ellipsis", // Ensure text doesn't overflow
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden" // Hide overflowing text
                  }}
                >
                  {formattedDate}
                </Typography.Text>
                <CalendarOutlined style={{ marginLeft: "8px", fontSize: "18px" }} />
          </Col>
      </Row>

      <Row
        gutter={[32, 32]} // Add consistent spacing between columns and rows
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap"
        }}
      >
        {/* First Column */}
        <Col
          xs={24}
          sm={24}
          md={11} // 11 out of 24 for spacing
          lg={11}
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
          }}
        >
          <Typography.Title level={5} style={{ marginBottom: "16px" }}>
          {t("overview")}
          </Typography.Title>
          <Row gutter={[16, 16]}>{renderCardRow(firstColumnStates)}</Row>
        </Col>

        {/* Second Column */}
        <Col
          xs={24}
          sm={24}
          md={11} // Match width with the first column
          lg={11}
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            marginLeft: "5%"
          }}
        >
          <Typography.Title level={5} style={{ marginBottom: "16px" }}>
          {t("stock_overview")}
          </Typography.Title>
          <Row gutter={[16, 16]}>{renderCardRow(secondColumnStates)}</Row>
        </Col>
      </Row>
       {/* Calendar Modal */}
       <Modal
        title="Select Date"
        visible={calendarVisible}
        onCancel={handleCancelCalendar}
        footer={null}
        centered
      >
        <Calendar fullscreen={false} />
      </Modal>
    </header>
  );
};
