import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Select } from "antd";
import "../../../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import { managerCol, managerCol_ar } from "../../../layout/tableColumns";
import TablePro from "../../../tables/TablePro";
import useLanguage from "../../../../hooks/useLanguage";
import { useGetBranchesMutation } from "../../../../stateManagement/apis/treecodeApi";
import { useFilterOrgEmployeesMutation } from "../../../../stateManagement/apis/accountsApi";

const BranchManagers = (props) => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectionModel, setSelectionModel] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [getBranchesApi] = useGetBranchesMutation();
  const [filterOrgEmployees] = useFilterOrgEmployeesMutation();
  const lang = useLanguage();
  const { Option } = Select;

  const handleBranch = (e) => {
    console.log(e);
    setSelectedBranch(e);
  };

  const getData = async () => {
    const result = await filterOrgEmployees({
      branch: selectedBranch !== null ? selectedBranch : "",
      page,
      search: keyword
    });

    if (result.data) {
      console.log(result);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedBranch, page, keyword]);

  const getBranches = async () => {
    const result = await getBranchesApi({ page_size: 100000 });
    if (result.data) {
      setBranches(result.data.data);
    }
  };

  const proceed = () => {
    props.handleChange(null, props.value + 1);
    props.getSelectedUser(selectedUser);
  };

  useEffect(() => {
    getBranches();
  }, []);
  return (
        <div>
            <Container>
                <Row className="my-3">
                    <Col className="text-end" sm="12">
                        <button
                            disabled={selectedUser.length === 0}
                            className="confirm"
                            onClick={proceed}
                        >
                            {t("proceed")}
                        </button>
                    </Col>
                </Row>
                <TablePro
                    onSearch={setKeyword}
                    headerExtra={
                        <Select
                            onChange={handleBranch}
                            value={selectedBranch}
                            style={{ width: 140 }}
                            placeholder={t("branch")}
                        >
                            {branches.map((branch) => {
                              return (
                                    <Option
                                        name={branch.name}
                                        key={branch.id}
                                        value={branch.id}
                                    >
                                        {branch.name}
                                    </Option>
                              );
                            })}
                        </Select>
                    }
                    dataSource={data}
                    columns={lang.includes("en") ? managerCol : managerCol_ar}
                    pagination={{
                      current: page,
                      total: count,
                      onChange: (page) => setPage(page)
                    }}
                    selection={"radio"}
                    selectedRows={selectionModel}
                    setSelectedRows={setSelectionModel}
                    setSelectedRecords={setSelectedUser}
                />
            </Container>
        </div>
  );
};

export default BranchManagers;
