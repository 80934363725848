import store from "../stateManagement/store";
import { ordersApi } from "../stateManagement/apis/ordersApi";
import formattedError from "./errorFormatter";

const OrderChainRepository = {
  async getById (id) {
    try {
      const resOrder = await store.dispatch(
        ordersApi.endpoints.getOrder.initiate(id)
      );
      const resSummary = await store.dispatch(
        ordersApi.endpoints.getOrderChainSummary.initiate(id)
      );

      if (resSummary.data && resOrder.data) {
        const order = resOrder.data;
        return {
          success: true,
          data: {
            order: {
              id: order.id,
              paymentMethod: order.payment_method,
              type: order.type
            },
            seller: {
              name: order.seller.user.name,
              store: order.seller.store_name,
              phone: order.seller.user.phone,
              pickupAddress: order.seller_address.address
            },
            customer: {
              name: order.client.name,
              phone: order.client.phone,
              address: order.client_address.address
            },
            summary: resSummary.data
          }
        };
      } else if (resSummary.error) {
        return formattedError(resSummary, true);
      } else if (resOrder.error) {
        return formattedError(resOrder, true);
      }
    } catch (e) {
      return formattedError(e);
    }
  }
};

export default OrderChainRepository;
