import React, { useState, useEffect } from "react";
import { notification } from "antd"; // Import Ant Design notification
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { addAccount, useAccount, useAccountErrors } from "../../../../../stateManagement/slices/addAccountSlice";
import { AccountConfirmationModal, CreationConfirmationModal } from "../../../../../components/modals/confirmationModal";
import { useGetBranchesMutation } from "../../../../../stateManagement/apis/treecodeApi";
import { accountCreationErrorAction } from "../../../../../stateManagement/actions";
import GenericForm from "../../../../../components/form/GenericForm";
import { useSellerFormFields } from "../../../../../hooks/useFormFields";
import useLanguage from "../../../../../hooks/useLanguage";

export default function SellerPersonalInfo ({ value, handleChange, onBack }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useLanguage();

  // State variables
  const [branches, setBranches] = useState([]);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [ID, setID] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [addresses, setAddresses] = useState([{ address: "", branch: "", area: "", new_work_model: "" }]);
  const [password, setPassword] = useState("");

  const account = useAccount(); // Get account state from Redux
  const accountErrors = useAccountErrors(); // Get account creation errors from Redux
  const [getBranchesApi] = useGetBranchesMutation();

  const getErrors = (e) => {
    const flattenObject = (obj, prefix = "") =>
      Object.keys(obj).reduce((acc, k) => {
        const pre = prefix.length ? prefix + "." : "";
        if (typeof obj[k] === "object" && !Array.isArray(obj[k])) {
          Object.assign(acc, flattenObject(obj[k], pre + k));
        } else acc[k] = obj[k];
        return acc;
      }, {});
    dispatch(accountCreationErrorAction(flattenObject(e)));
  };
  useEffect(() => {
    const getBranches = async () => {
      const result = await getBranchesApi({ page_size: 10000 });
      if (result.data) {
        setBranches(result.data.data);
      }
    };
    getBranches();
  }, [getBranchesApi]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("requiredField")),
    store_name: Yup.string().required(t("requiredField")),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    phone: Yup
      .string()
      .required("phoneRequired")
      .matches(/^966\d{9}$/, "invalidPhone"),
    phone_alt: Yup
      .string()
      .matches(/^966\d{9}$/, "invalidAltPhone")
      .nullable(),
    addresses: Yup.array()
      .of(
        Yup.object().shape({
          address: Yup.string().required(t("addressErr")),
          branch: Yup.string().required(t("branchErr")),
          area: Yup.string().required(t("areaErr"))
        })
      )
      .min(1, t("requiredField"))
      .required(t("requiredField"))
  });

  const validate = (data) => {
    data.seller_addresses = addresses;
    data.addresses = addresses;
    console.log(validationSchema);
    if (value === 1 && !addresses.length) {
      setAddressErr(t("addressErr"));
    } else {
      setAddressErr("");
      dispatch(addAccount(data));
      dispatch(accountCreationErrorAction({}));
      showModal();
    }
  };

  const showModal = () => setShow(true);
  const showConfirmModal = () => setShowConfirm(true);
  const hide = () => setShow(false);
  const hideConfirm = () => {
    if (Object.keys(accountErrors).length === 0) { // Only navigate if there are no errors
      setShowConfirm(false);
      navigate(`/user_details/seller/${ID}`);
    }
  };

  const handleDeleteAddress = (index) => setAddresses(addresses.filter((_, i) => i !== index));
  const handleAddAddress = () => setAddresses([...addresses, { address: "", branch: "", area: "" }]);

  const fields = useSellerFormFields(lang, addresses, setAddresses, handleDeleteAddress, handleAddAddress, branches, onBack);

  // Notification for account errors
  useEffect(() => {
    if (accountErrors && Object.keys(accountErrors).length > 0) { // Only show notification if accountErrors has values
      console.log(accountErrors);
      notification.error({
        message: t("accountCreationError"),
        description: Object.values(accountErrors).join(", "),
        duration: 5
      });
    }
  }, [accountErrors, t]);

  return (
    <>
      <GenericForm
        fields={fields}
        submitFunction={validate}
        errors={accountErrors}
        hooks={{
          successMessage: t("accountCreation"),
          errorMessage: t("accountCreationError"),
          initialValues: value === 2 ? account : undefined
        }}

      />
      <p className="error">{addressErr && t("addressFields")}</p>
      <CreationConfirmationModal
        getErrors={getErrors}
        setID={setID}
        setPassword={setPassword}
        title={lang === "ar" ? "انشاء حساب" : "Create Account"}
        show={show}
        showConfirm={showConfirmModal} // Trigger account confirmation modal
        hide={hide}
        account={account}
        onBack={onBack}
      />

      <AccountConfirmationModal
        show={showConfirm}
        hide={hideConfirm}
        id={ID}
        password={password}
        account={account}
      />
    </>
  );
}
