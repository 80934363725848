import React from "react";
import { useDispatch } from "react-redux";
import { Layout, Menu, Dropdown, Avatar, Image, Typography, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import userAvatar from "../../images/Avatar.png";
import logoAdminEn from "../../images/logoAdmin.png";
import logoSellerEn from "../../images/logoSeller.png";
import logoAdminAr from "../../images/arabicLogo.png";
import logoSellerAr from "../../images/sellerLogoAr.png";
import { clearAuthAction } from "../../stateManagement/authActions";
import { BaseUrl } from "../../stateManagement/constants";
import useLanguage from "../../hooks/useLanguage";
import { useUserObject } from "../../stateManagement/slices/userSlice";

const { Header: AntHeader } = Layout;
const { Text } = Typography;

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useLanguage();
  const user = useUserObject();
  const isSeller = user?.role === "Seller";
  const orgLogo = user?.org?.logo;

  const logoSrc = orgLogo
    ? `${BaseUrl}${orgLogo}`
    : isSeller
      ? (lang === "en" ? logoSellerEn : logoSellerAr)
      : (lang === "en" ? logoAdminEn : logoAdminAr);

  const logout = () => dispatch(clearAuthAction());

  const menu = (
    <Menu>
      <Menu.Item key="settings" icon={<SettingOutlined style={{ color: "#555" }} />}>
        <a href="/settings" style={{ color: "#333" }}>
          {t("sideMenu.settings")}
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined style={{ color: "#d9534f" }} />} onClick={logout}>
        <span style={{ color: "#d9534f" }}>{t("logout")}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader
      style={{
        backgroundColor: "#F6F6F7",
        padding: "0 24px",
        height: "120px", // Increase the height here
        display: "flex",
        alignItems: "center",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)"
      }}
    >
      <Row align="middle" justify="space-between" style={{ width: "100%" }}>
        <Col>
          <a href="/dashboard" style={{ display: "flex", alignItems: "center" }}>
            <Image
              src={logoSrc}
              alt="Logo"
              preview={false}
              style={{
                maxHeight: "50px",
                marginRight: "1rem"
              }}
            />
          </a>
        </Col>
        <Col>
          <Dropdown overlay={menu} trigger={["hover"]} placement="bottom" arrow={{ pointAtCenter: true }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "8px 12px",
              borderRadius: "8px",
              transition: "background 0.3s"
            }}>
              <Avatar src={userAvatar} alt="User Avatar" size={40} style={{ marginRight: "0.75rem", border: "2px solid #ddd" }} />
              <Text strong style={{ color: "#333", fontSize: "16px", marginRight: "0.5rem" }}>
                {user?.user?.name || "User"}
              </Text>
              {/* <CaretDownOutlined className="m-2 " style={{ color: "#555" }} /> */}
            </div>
          </Dropdown>
        </Col>
      </Row>
    </AntHeader>
  );
};

export default Header;
