import React from "react";
import { Row, Col, Form, Input, Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import SelectAreas from "../../components/select-search/AreasSelect";

/**
 * AddressForm Component
 * A reusable component for handling multiple addresses dynamically.
 *
 * @param {object} props - Component properties
 * @param {Array} props.branches - Array of branch options
 * @param {Array} props.addresses - Array of address objects
 * @param {Function} props.setAddresses - Function to update addresses
 * @param {Function} props.handleDeleteAddress - Function to delete an address
 */
export default function AddressForm ({
  branches,
  addresses,
  setAddresses,
  handleDeleteAddress,
  handleAddAddress
}) {
  const { t, i18n } = useTranslation();

  return addresses.map((address, index) => (
    <div style={{ position: "relative", marginBottom: 16 }} key={index}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t("branch")}>
            <Select
              onChange={(value) => {
                const updatedAddresses = [...addresses];
                updatedAddresses[index].branch = value;
                setAddresses(updatedAddresses);
              }}
              placeholder={t("branch")}
              options={branches.map((branch) => ({
                label: branch.name,
                value: branch.id
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("area")} >
            <SelectAreas
              value={address.area}
              onChange={(value) => {
                const updatedAddresses = [...addresses];
                updatedAddresses[index].area = value;
                setAddresses(updatedAddresses);
              }}
              style={{
                width: "100%",
                backgroundColor: "#ffffff",
                border: "1px solid #d9d9d9"
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("address")}>
            <Input
              onChange={(e) => {
                const updatedAddresses = [...addresses];
                updatedAddresses[index].address = e.target.value;
                setAddresses(updatedAddresses);
              }}
              placeholder={t("address")}
              style={{
                width: "100%",
                backgroundColor: "#ffffff"
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button
        icon={<CloseOutlined />}
        danger
        type="text"
        onClick={() => handleDeleteAddress(index)}
        style={{
          position: "absolute",
          top: 0,
          left: i18n.language === "en" ? "unset" : -17,
          right: i18n.language === "en" ? -17 : "unset"
        }}
        disabled={addresses.length === 1}
      />
      {/* <Button
        icon={<PlusCircleOutlined style={{ color: "#61c175" }} />}
        type="text"
        onClick={handleAddAddress}
        style={{
          position: "absolute",
          bottom: 10,
          left: i18n.language === "en" ? "unset" : 100,
          right: i18n.language === "en" ? 100 : "unset"
        }}
      /> */}
    </div>
  ));
}
