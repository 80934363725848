import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Input, Progress, Table, notification } from "antd";
import { PlusOutlined, SearchOutlined, HomeOutlined, AppstoreOutlined, FilterOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import WareHousesRepository from "../../../api/WarehousesRepository";
import { useWarehouseColumns } from "../../layout/tableColumns";

const { Search } = Input;

const OverviewCard = ({ count }) => {
  const { t } = useTranslation();

  return (
      <div style={{ textAlign: "center", marginBottom: "24px" }}>
        {/* Header */}
        {/* <h2 style={{ fontSize: "24px", fontWeight: "600", color: "#333" }}>{t("overview")}</h2> */}

        {/* Overview Card */}
        <Card
          style={{
            borderRadius: "12px",
            padding: "16px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
            height: "100%"
          }}
        >
        <Row gutter={[16, 16]} >
            <h2 style={{ fontSize: "24px", fontWeight: "600", color: "#333" }}>{t("overview")}</h2>
        </Row>
          <Row gutter={[16, 16]}>
            {/* Total Warehouses */}
            <Col xs={24} sm={12} md={12}>
              <div style={{ display: "flex", alignItems: "center", gap: "12px", minHeight: "100px" }}>
                <div
                  style={{
                    backgroundColor: "#E8F0FF",
                    borderRadius: "8px",
                    padding: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <HomeOutlined style={{ fontSize: "24px", color: "#1677FF" }} />
                </div>
                <div>
                  <p style={{ margin: "0", color: "#7A7A7A", fontSize: "14px", fontWeight: "bold" }}>
                  {t("sideMenu.allWarehouses")}
                  </p>
                  <span style={{ fontSize: "16px", fontWeight: "600" }}>{count}</span>
                </div>
              </div>
            </Col>

            {/* Available Shelves */}
            <Col xs={24} sm={12} md={12}>
              <div style={{ display: "flex", alignItems: "center", gap: "12px", minHeight: "100px" }}>
                <div
                  style={{
                    backgroundColor: "#E6FCE6",
                    borderRadius: "8px",
                    padding: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <AppstoreOutlined style={{ fontSize: "24px", color: "#52C41A" }} />
                </div>
                <div>
                  <p style={{ margin: "0", color: "#7A7A7A", fontSize: "14px", fontWeight: "bold" }}>
                    {t("AvailableShelves")}
                  </p>
                  <span style={{ fontSize: "16px", fontWeight: "600" }}>12</span>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
  );
};

const ProgressCircleCard = () => {
  const { t } = useTranslation();

  return (
      <Card style={{ textAlign: "center", borderRadius: "12px", padding: "24px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)", height: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {/* Progress Circle */}
          <Progress
            type="circle"
            percent={58}
            strokeWidth={12}
            strokeColor={{ "0%": "#52C41A", "100%": "#52C41A" }}
            size={120}
          />

          {/* Dots and Titles beside the progress circle */}
          <div style={{ marginLeft: "16px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            {/* Free Dot */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: "#52C41A",
                  marginRight: "8px"
                }}
              />
              <span style={{ fontSize: "14px", color: "#52C41A", marginInlineStart: "8px" }}>{t("availabe")}</span>
            </div>

            {/* Used Dot */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: "#D9D9D9",
                  marginRight: "8px"
                }}
              />
              <span style={{ fontSize: "14px", color: "#D9D9D9", marginInlineStart: "8px" }}>{t("used")}</span>
            </div>
          </div>
        </div>

      </Card>
  );
};

const SearchAndAdd = ({ onSearch, onAdd, onFilter }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const hoverStyle = {
    color: isHovered ? "#52C41A" : "inherit" // Change text color on hover
  };
  return (
      <Row justify="space-between" align="middle" style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={16} md={8} lg={8}>
          <Search
            placeholder="Search warehouses..."
            allowClear
            enterButton={<SearchOutlined />}
            onSearch={onSearch}
          />
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={onAdd} style={{ width: "70%" }}>
          { t("createWarehouse")}
          </Button>
          {/* Filter Button with same width as Add Warehouse */}
          <Button
          icon={<FilterOutlined />}
          style={{
            marginTop: "8px",
            width: "50%",
            ...hoverStyle, // Apply hover style dynamically
            borderColor: "#52C41A"
          }}
          onClick={onFilter}
          onMouseEnter={() => setIsHovered(true)} // Trigger hover style on mouse enter
          onMouseLeave={() => setIsHovered(false)} // Revert back on mouse leave
        >
            { t("Filter")}
          </Button>
        </Col>
      </Row>
  );
};

const WarehouseTable = ({ columns, dataSource, loading, pagination, onChange }) => {
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

const AllWarehouses = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState({ page: 1, limit: 10, search: "" });
  const [warehouses, setWarehouses] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const columns = useWarehouseColumns();

  const getWarehouses = async () => {
    setLoading(true);
    const response = await WareHousesRepository.getWarehouses(queryParams);
    if (response.success) {
      setWarehouses(response.data);
      setCount(response.count);
    } else {
      notification.error({ message: response.error || t("Error fetching data") });
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setQueryParams({ ...queryParams, search: value, page: 1 });
  };

  const handleTableChange = (pagination) => {
    setQueryParams({ ...queryParams, page: pagination.current });
  };

  useEffect(() => {
    getWarehouses();
  }, [queryParams]);

  return (
    <div style={{ padding: "16px", backgroundColor: "#fff", borderRadius: "12px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)" }}>
      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <OverviewCard count={count} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <ProgressCircleCard />
        </Col>
      </Row>

      <SearchAndAdd onSearch={handleSearch} onAdd={() => navigate("/warehouse/create")} />

      <WarehouseTable
        columns={columns}
        dataSource={warehouses}
        loading={loading}
        pagination={{
          current: queryParams.page,
          total: count,
          pageSize: queryParams.limit
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default AllWarehouses;
