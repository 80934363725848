import { Form, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useLanguage from "../../../../../hooks/useLanguage";
import WMButton from "../../../../../components/pages/AllWorkModels/WMButton";
import { useFilterAreasMutation, useGetTierMutation, useUpdateTierMutation } from "../../../../../stateManagement/apis/treecodeApi";
import { notifyErrors } from "../../../../../api/ErrorNotifier";

export default function EditTier ({ id, onClose }) {
  const { t } = useTranslation();
  const [areas, setAreas] = useState([]);
  const [tier, setTier] = useState({});
  const [form] = Form.useForm();
  const [updateTier, { isLoading: loading }] = useUpdateTierMutation();
  const [getTier] = useGetTierMutation();
  const [filterAreas] = useFilterAreasMutation();

  const lang = useLanguage();

  const getAreas = async () => {
    const res = await filterAreas({ page_size: 10000 });
    if (res.data) { setAreas(res.data.data); }
  };

  const getTierById = async () => {
    const res = await getTier(id);
    if (res.data) {
      const t = res.data ?? {};
      setTier(t);
      form.setFieldValue("areas", t.tier_areas ?? []);
    }
  };

  useEffect(() => {
    getAreas();
  }, []);

  useEffect(() => {
    if (typeof id === "undefined") return;
    getTierById();
  }, [id]);

  function onCloseModal () {
    form.setFieldValue("areas", []);
    setTier({});
    onClose();
  }

  async function onFinish (data) {
    try {
      const res = await updateTier({
        tierId: id,
        ...tier,
        tier_areas: data.areas
      });
      if (res.data) { onCloseModal(); } else if (res.error?.data) {
        form.setFields([
          { name: "areas", errors: res.error?.data?.tier_areas }
        ]);
        notifyErrors(res.error.data);
      } else {
        notifyErrors(t("serverError"));
      }
    } catch (e) {
      form.setFields([
        { name: "areas", errors: e.response?.data?.tier_areas }
      ]);
    }
  }

  return (
        <Modal
            onOk={onCloseModal}
            onCancel={onCloseModal}
            closeIcon={null}
            icon={null}
            footer={null}
            open={typeof id !== "undefined"}
        >
            <h4>{t("editTiersAreas")}</h4>
            <Form onFinish={onFinish} form={form} layout={"vertical"}>
                <Form.Item name={"areas"} label={t("areas")}>
                    <Select
                        mode="multiple"
                        style={{
                          overflow: "auto",
                          maxHeight: "200px"
                        }}
                    >
                        {areas.map((area) => (
                            <Select.Option key={area.id} value={area.id}>
                                {lang.includes("en") ||
                                area.name_ar?.length === 0
                                  ? area.name
                                  : area.name_ar}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "16px"
                    }}
                >
                    <WMButton
                        disabled={loading}
                        loading={loading}
                        htmlType="submit"
                    >
                        {t("save")}
                    </WMButton>
                </div>
            </Form>
        </Modal>
  );
}
