import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from "@mdi/js";
import {
  CreationConfirmation,
  UnSuccessfulRequest
} from "../modals/confirmationModal";
import {
  isReAssigned,
  getReassignedSheet
} from "../../stateManagement/slices/reassignedRSSlice";
import useLanguage from "../../hooks/useLanguage";
import {
  useUnassignCourierMutation,
  useDeleteRunsheetMutation
  , useCloseRunsheetMutation
} from "../../stateManagement/apis/runsheetsApi";
import { notifyErrors, stringErrorsHandler } from "../../api/ErrorNotifier";

import { useCourierWalletAvailable } from "../../stateManagement/slices/userSlice";

/**
 * ThreeDotsMenuRS Component
 * Provides action menu for managing runsheets.
 *
 * Props:
 * - data: The runsheet data.
 * - unAssigned: Boolean indicating unassignment status.
 * - setUnassigned: Function to toggle unassignment status.
 * - link: Navigation link (not used in the component).
 * - setArchived: Function to toggle archived status.
 * - isArchived: Boolean indicating archive status.
 */
function ThreeDotsMenuRS ({
  data,
  unAssigned,
  setUnassigned,
  setArchived,
  isArchived,
  refresh
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = useLanguage();
  const dispatch = useDispatch();

  const [closeRunsheet, { isLoading: closeRunsheetLoading }] = useCloseRunsheetMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [showArchiveModel, setShowArchivedModel] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [unassignCourier, { isLoading: unassignCourierLoading }] = useUnassignCourierMutation();
  const [deleteRunsheet, { isLoading: deleteRunsheetLoading }] = useDeleteRunsheetMutation();
  const courierWalletAvailable = useCourierWalletAvailable();

  // Handles unassignment of the runsheet
  const unAssignedItem = async () => {
    const result = await unassignCourier(data.id);
    if (result.data) {
      setUnassigned(!unAssigned);
      setShowWarning(false);
    } else {
      handleError(result);
    }
  };

  // Handles runsheet archival
  const ArchiveReq = async () => {
    console.log("ARCHIVE REQ", data);
    const result = await deleteRunsheet(data.id);
    if (result.error) {
      handleError(result);
    } else {
      setArchived(!isArchived);
      setShowArchivedModel(false);
    }
  };

  // Error handling helper
  const handleError = (result) => {
    setShowWarning(false);
    setShowArchivedModel(false);
    const errorMessage = result.error?.data
      ? stringErrorsHandler(result.error.data)
      : "Something went wrong";
    setError(errorMessage);
    setShowError(true);
  };

  // Opens the action menu
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  // Closes the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Hides modals
  const hide = () => {
    setShowWarning(false);
    setShowArchivedModel(false);
  };

  const handleSettleRunsheet = async (id) => {
    console.log("RUNSHEET_ID", id);
    if (courierWalletAvailable) {
      const result = await closeRunsheet(id);
      if (result.data) {
        handleClose();
        refresh();
      } else if (result.error?.data) {
        notifyErrors(result.error.data);
      } else {
        notifyErrors("somethingWentWrong");
      }
    } else {
      handleClose();
      navigate(`/closeRunsheet/${id}`);
    }
  };

  return (
    <div className="actionMenu">
      <Button onClick={handleClick}>
        <Icon path={mdiDotsHorizontal} size={1.2} />
      </Button>
      <Menu
        id="card-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* Add tickets to runsheet */}
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/add_to_runsheet/${data.id}`);
          }}
        >
          <i className="fas fa-plus mx-2 my-3"></i>
          {t("addTicketsToRunsheet")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            dispatch(getReassignedSheet(data));
            dispatch(isReAssigned(true));
            navigate(`/remove_runsheet_orders/${data.id}`);
          }}
        >
          <i className="fas fa-minus mx-2 my-3"></i>
          {t("Remove Orders")}
        </MenuItem>

        {/* Edit runsheet priorities */}
        <MenuItem
          onClick={() => {
            handleClose();
            dispatch(getReassignedSheet(data));
            dispatch(isReAssigned(true));
            navigate(`/edit_runsheet_priorities/${data.id}/ext`);
          }}
        >
          <i className="fas fa-edit mx-2 my-3"></i>
          {t("edit_priorities")}
        </MenuItem>

        {/* Reassign runsheet */}
        {data.state !== 1 && (
          <MenuItem
            onClick={() => {
              handleClose();
              dispatch(getReassignedSheet(data));
              dispatch(isReAssigned(true));
              navigate(`/reassign_runsheet/${data.id}`);
            }}
          >
            <i className="fas fa-user-edit mx-2 my-3"></i>
            {t("reAssign")}
          </MenuItem>
        )}

        {/* Settle runsheet */}
        {data.state === "1" && (
          <MenuItem
            onClick={() => {
              handleSettleRunsheet(data.id);
            }}
            disabled={closeRunsheetLoading}
          >
            <i className="fas fa-check-circle mx-2 my-3"></i>

            {t("settle")}
          </MenuItem>
        )}

        {/* Unassign runsheet */}
        <MenuItem
          onClick={() => {
            handleClose();
            setShowWarning(true);
          }}
        >
          <i className="fas fa-user-edit mx-2 my-3"></i>
          {t("unassign")}
        </MenuItem>

        {/* Archive runsheet */}
        <MenuItem
          onClick={() => {
            handleClose();
            setShowArchivedModel(true);
          }}
        >
          <i className="fas fa-archive mx-2 my-3"></i>
          {t("archiveRunsheet")}
        </MenuItem>
      </Menu>

      {/* Confirmation Modals */}
      <CreationConfirmation
        hide={hide}
        sendRequest={unAssignedItem}
        title={t("unassignRunsheet")}
        loading={unassignCourierLoading}
        body={
          lang === "en"
            ? `Are you sure you want to Unassign #${data.id} from ${data.courier.user.name}?`
            : `هل انت متأكد انك تريد إلغاء إسناد خط سير رقم ${data.id} من ${data.courier.user.name}؟`
        }
        show={showWarning}
      />
      <CreationConfirmation
        hide={hide}
        sendRequest={ArchiveReq}
        title={t("archiveRunsheet")}
        loading={deleteRunsheetLoading}
        body={
          lang === "en"
            ? `Are you sure you want to Archive Runsheet #${data.id}?`
            : `هل انت متأكد انك تريد إرسال خط السير رقم ${data.id} إلى الأرشيف؟`
        }
        show={showArchiveModel}
      />
      <UnSuccessfulRequest
        title={t("archiveRunsheet")}
        show={showError}
        body={error}
        hide={() => setShowError(false)}
      />
    </div>
  );
}

export default ThreeDotsMenuRS;
