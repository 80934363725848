import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrdersColumns } from "../layout/tableColumns";
import {
  CreationConfirmation,
  SuccessfulRequest,
  UnSuccessfulRequest
} from "../modals/confirmationModal";
import isReAssigned, {
  getReassignedSheet,
  useReassignedRS
} from "../../stateManagement/slices/reassignedRSSlice";
import TablePro from "../tables/TablePro";
import useLanguage from "../../hooks/useLanguage";
import { useRunsheetOrders } from "../../stateManagement/slices/rsOrdersSlice";
import { useRunsheet } from "../../stateManagement/slices/addRunsheetSlice";
import { useGetRunsheetMutation, useCreateRunsheetMutation, useReassignCourierMutation } from "../../stateManagement/apis/runsheetsApi";
import { notifyErrors, stringErrorsHandler } from "../../api/ErrorNotifier";
import GenericForm from "../form/GenericForm"; // Assuming GenericForm is in the forms directory

function RunsheetRequest ({
  value,
  isReAssigned: isReAssignedState,
  handleChange,
  skipDirty
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [ID, setID] = useState("");
  const [reAssignedRsOrder, setReAssignedRsOrder] = useState([]);
  const data = useRunsheetOrders();
  const courier = useRunsheet()?.courier; // Make sure courier is defined
  const object = useRunsheet();
  const reAssignedRS = useReassignedRS();
  const dispatch = useDispatch();
  const [reassignCourier] = useReassignCourierMutation();
  const [getRunsheet] = useGetRunsheetMutation();
  const [createRunsheet] = useCreateRunsheetMutation();

  const hide = () => {
    setShow(false);
    setShowConfirm(false);
    setShowError(false);
    navigate("/assigned_rs");
    dispatch(isReAssigned(false));
    dispatch(getReassignedSheet(""));
  };

  const confirm = async () => {
    // Debugging: Log the original orders
    console.log("Original orders:", object.orders);

    // Use the orders directly since they are already IDs
    const validOrderIds = object.orders?.filter(Boolean) ?? []; // Remove any falsy values
    console.log("Valid order IDs:", validOrderIds);

    let result;
    if (isReAssignedState) {
      result = await reassignCourier({ runsheetId: reAssignedRS.id, courier: object.courier.id });
    } else {
      result = await createRunsheet({
        orders: validOrderIds, // Pass the IDs directly
        orders_ids: JSON.stringify(validOrderIds), // Serialize the IDs for orders_ids
        courier: object.courier?.id,
        branch: object.branch
      });
    }

    if (result.data) {
      setID(result.data.id);
    } else {
      const errorMsg = result.error?.data ? stringErrorsHandler(result.error.data) : "Server Error";
      notifyErrors(errorMsg);
      setError(errorMsg);
    }
  };

  const getSelectedRsOrders = async () => {
    const result = await getRunsheet(reAssignedRS.id);
    if (result.data) {
      setReAssignedRsOrder(result.data.orders);
    }
  };

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
    console.log("value passed ", value);
    console.log("Courier ", courier);
  }, [error]);

  useEffect(() => {
    if (ID) {
      setShowConfirm(true);
    }
  }, [ID]);

  useEffect(() => {
    if (isReAssignedState) {
      getSelectedRsOrders();
    }
    console.log(object);
    console.log(courier);
  }, []);

  const discardRunsheet = () => {
    handleChange(null, 0);
  };

  const fields = [
    {
      name: "courierName",
      label: t("couriername"),
      component: "input",
      props: {
        type: "text",
        defaultValue: courier?.user?.name || "", // Fallback if courier is undefined
        disabled: true
      }
    },
    {
      name: "courierBranch",
      label: t("courierBranch"),
      component: "input",
      props: {
        type: "text",
        defaultValue: courier?.branch?.name || "", // Fallback if courier is undefined
        disabled: true
      }
    },
    {
      name: "courierVehicle",
      label: t("courierVehicle"),
      component: "input",
      props: {
        type: "text",
        defaultValue: courier?.vehicle || "", // Fallback if courier is undefined
        disabled: true
      }
    },
    {
      name: "courierPhone",
      label: t("courierPhone"),
      component: "input",
      props: {
        type: "text",
        defaultValue: courier?.user?.phone || "", // Fallback if courier is undefined
        disabled: true
      }
    },
    {
      type: "button",
      label: t("proceed"),
      htmlType: "submit",
      buttonType: "primary"
    },
    {
      type: "button",
      label: t("discard"),
      htmlType: "button",
      buttonType: "default",
      onClick: discardRunsheet
    }
  ];

  const lang = useLanguage();

  return (
    <>
      <Row>
        <Col sm="6">
          {isReAssignedState && <p className="orderID">{t("runsheetID")}#{reAssignedRS?.id}</p>}
        </Col>
      </Row>
      <GenericForm fields={fields} formDisabled={true} submitFunction={() => setShow(true)} skipDirty={skipDirty}/>
      <Row className="text-end buttons-margin mb-3">
      </Row>
      <Row>
        <Col sm="12">
          <TablePro
            dataSource={isReAssignedState ? reAssignedRsOrder : data}
            columns={useOrdersColumns()}
          />
        </Col>
      </Row>
      <CreationConfirmation
        sendRequest={confirm}
        show={show}
        hide={hide}
        body={`${t("runsheetCreateMsg1")} ${courier?.user?.name}`}
        title={t("runsheetConfirm")}
      />
      <SuccessfulRequest
        show={showConfirm}
        hide={hide}
        title={t("runsheetConfirm")}
        body={
          lang === "en"
            ? `Runsheet with ID #${ID} has been successfully assigned to ${courier?.user?.name}`
            : `تم تعيين خط سير برقم ${ID} للمندوب ${courier?.user?.name}`
        }
      />
      <UnSuccessfulRequest
        show={showError}
        hide={hide}
        title={t("runsheetConfirm")}
        body={error}
      />
    </>
  );
}

export default RunsheetRequest;
