import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Space } from "antd";
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from "@mdi/js";
import TablePro from "../../../../components/tables/TablePro";
import TicketScanRepository from "../data";
import ScannedIcon from "../../../../images/tick_circle.svg";
import UnknownIcon from "../../../../images/tick_outline.svg";
import styles from "./TicketsScan.module.css";

function TicketAction ({ row, markTicketAsScanned }) {
  const { t } = useTranslation();
  const items = [
    {
      key: 1,
      label: (
                <div
                    className={styles.menuItem}
                    onClick={() => markTicketAsScanned(row.id)}
                >
                    <img src={ScannedIcon} alt="scanned" />
                    <span>{t("markAsScanned")}</span>
                </div>
      )
    }
  ];
  return (
        <Dropdown menu={{ items }} trigger="click">
            <div
                style={{ cursor: "pointer" }}
                onClick={(e) => e.preventDefault()}
            >
                <Space>
                    <Icon path={mdiDotsHorizontal} size={1.2} />
                </Space>
            </div>
        </Dropdown>
  );
}

export default function TicketsTable ({
  data,
  ticketsStates,
  loading,
  markTicketAsScanned,
  selectedTickets,
  setSelectedTickets
}) {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        title: t("orderID"),
        dataIndex: ["order", "id"],
        render: (_, row) => (
                    <span style={{ color: "#40a9ff" }}>#{row.order.id}</span>
        )
      },
      {
        title: t("ticketID"),
        dataIndex: "id",
        render: (_, row) => (
                    <span style={{ color: "#40a9ff" }}>#{row.id}</span>
        )
      },
      {
        title: t("createDate"),
        dataIndex: "create_date",
        render: (_, row) => (
                    <p className="paragraphMargin">
                        {row.create_date?.split("T")[0]}
                    </p>
        )
      },
      // {
      //     title: t("specialNote"),
      //     dataIndex: "note",
      // },
      {
        title: t("scanStatus"),
        dataIndex: "scan_status",
        render: (_, row) => {
          const state =
                        ticketsStates[row.id]?.state ??
                        TicketScanRepository.scanStates.unknown;

          return (
                        <span className={styles.scanStateCell}>
                            {state ===
                            TicketScanRepository.scanStates.scanned
                              ? (
                                <img src={ScannedIcon} alt="scanned" />
                                )
                              : (
                                <img src={UnknownIcon} alt="unknown" />
                                )}
                        </span>
          );
        }
      },
      {
        title: t("actions"),
        render: (_, row) => (
                    <TicketAction
                        row={row}
                        markTicketAsScanned={markTicketAsScanned}
                    />
        )
      }
    ],
    [Object.values(ticketsStates).length]
  );
  console.log({ loading });
  return (
        <div>
            <TablePro dataSource={data} columns={columns} loading={loading} selection="checkbox" selectedRows={selectedTickets} setSelectedRows={setSelectedTickets} preserveSelectedRowKeys />
        </div>
  );
}
