import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { addOrder, useOrder } from "../../../../../stateManagement/slices/addOrderSlice";
import { getSeller, useSelectedSeller } from "../../../../../stateManagement/slices/selectedSellerSlice";
import { useUserObject } from "../../../../../stateManagement/slices/userSlice";
import { getTicketsReturned } from "../../../../../stateManagement/slices/returnedTicketsSlice";
import ReplacedTickets from "./ReplacedTickets";
import NewTickets from "./NewTickets";
import ReplacementSellerAddress from "./ReplacementSellerAddress";
import ReviewReplacementRequest from "./ReviewReplacementRequest";

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function TicketsReplacement () {
  const [value, setValue] = useState(0);
  const [returnedObj, setReturnedTicketsObj] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const selectedSeller = useSelectedSeller();
  const user = useUserObject();

  useEffect(() => {
    console.log(selectedSeller);
    console.log(returnedObj);
  }, [returnedObj]);

  const getReturnedTicketsObject = (e) => {
    console.log(e);
    setReturnedTicketsObj(e);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addOrder({}));
  }, []);

  const order = useOrder();
  useEffect(() => {
    console.log({ order });
  }, [order]);

  useEffect(() => {
    dispatch(addOrder(""));
    dispatch(getTicketsReturned(""));
    dispatch(getSeller(""));
  }, []);

  return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.tickets")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.replace")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("createReplaceRequest")}</h2>
                </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        // onChange={handleChange}
                        aria-label="basic tabs example"
                        className={`${
                            user.role === "Seller" ? "seller" : "admin"
                        }`}
                    >
                        <Tab label={t("selectTickets")} {...a11yProps(0)} />
                        <Tab label={t("sellerInfo")} {...a11yProps(1)} />
                        <Tab label={t("productInfo")} {...a11yProps(2)} />
                        <Tab label={t("reviewRequest")} {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ReplacedTickets
                        getReturnedTicketsObject={getReturnedTicketsObject}
                        value={value}
                        handleChange={handleChange}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ReplacementSellerAddress
                        value={value}
                        handleChange={handleChange}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <NewTickets value={value} handleChange={handleChange} />
                </TabPanel>

                <TabPanel value={value} index={3}>
                    <ReviewReplacementRequest
                        value={value}
                        handleChange={handleChange}
                    />
                </TabPanel>
            </Box>
        </div>
  );
}

export default TicketsReplacement;
