import { createApi } from "@reduxjs/toolkit/query/react";
import { SMS_TEMPLATES_API } from "../constants";
import { reAuthBaseQuery } from "./baseQuery";

export const smsTemplatesApi = createApi({
  reducerPath: "smsTemplatesApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["SmsTemplates"],
  endpoints: (builder) => ({
    getSmsTemplates: builder.mutation({
      query: ({ page = 1, limit = 10, search = "", ...queryParameters }) => ({
        url: SMS_TEMPLATES_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          search,
          ...queryParameters
        }
      }),
      invalidatesTags: ["SmsTemplates"]
    }),
    getSmsTemplate: builder.mutation({
      query: (smsTemplateId) => ({
        url: `${SMS_TEMPLATES_API.ROOT}/${smsTemplateId}/`,
        method: "GET"
      }),
      providesTags: ["SmsTemplates"]
    }),
    updateSmsTemplate: builder.mutation({
      query: ({ smsTemplateId, ...body }) => ({
        url: `${SMS_TEMPLATES_API.ROOT}/${smsTemplateId}/`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["SmsTemplates"]
    }),
    actionActivateSwitch: builder.mutation({
      query: (smsTemplateId) => ({
        url: `${SMS_TEMPLATES_API.ROOT}/${smsTemplateId}/${SMS_TEMPLATES_API.ACTIVE_SWITCH}`,
        method: "POST"
      }),
      invalidatesTags: ["SmsTemplates"]
    })
  })
});

export const {
  useGetSmsTemplatesMutation,
  useGetSmsTemplateMutation,
  useUpdateSmsTemplateMutation,
  useActionActivateSwitchMutation
} = smsTemplatesApi;
