import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Spin, notification, Button } from "antd";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import {
  useGetThirdPartyMutation,
  useUpdateThirdPartyMutation,
  useRegenerateThirdPartyKeysMutation
} from "../../../stateManagement/apis/accountsApi";
import GenericForm from "../../form/GenericForm";
import { useThirdPartyFields, useThirdPartyKeysFields } from "../../../hooks/useFormFields";
import PageHeader from "../../layout/PageHeader";
import { notifyErrors } from "../../../api/ErrorNotifier";
export const ThirdPartyKeys = ({ keys, setKeys, thirdPartyId }) => {
  const [currentKey, setCurrentKey] = useState(null);
  const [regenerateKeysApi] = useRegenerateThirdPartyKeysMutation();

  const regenerateKey = async (keyName) => {
    setCurrentKey(keyName);
    try {
      const result = await regenerateKeysApi(thirdPartyId, { key_name: keyName });
      if (result.data) {
        setKeys({ ...keys, [keyName]: result.data[keyName] });
        notification.success({
          message: "Success",
          description: result.data.message
        });
      } else if (result.error?.data) {
        notifyErrors(result.error.data);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (e) {
      console.error(e);
      notifyErrors(e.message);
    } finally {
      setCurrentKey(null);
    }
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    toast.info("Copied to clipboard");
  };

  const fields = useThirdPartyKeysFields(keys, currentKey, regenerateKey, copyToClipboard);

  return (
    <GenericForm
      fields={fields.map(field => ({
        ...field,
        component: ({ value, ...rest }) => (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <input type="text" value={value} {...rest} className="ant-input" style={{ flex: 1 }} />
            {field.buttons.map((button, idx) => (
              <Button key={idx} {...button.props} onClick={button.onClick}>
                {button.label}
              </Button>
            ))}
          </div>
        )
      }))}
      submitFunction={() => {}}
    />
  );
};
const ThirdPartyInfo = () => {
  const { t } = useTranslation();
  const [thirdParty, setThirdParty] = useState(null);
  const { id } = useParams();
  const [getThirdPartyApi, { isLoading: fetching }] = useGetThirdPartyMutation();
  const [updateThirdParty, { isLoading: loading }] = useUpdateThirdPartyMutation();

  const fields = useThirdPartyFields();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("requiredField")),
    name_ar: Yup.string().nullable(),
    email: Yup.string().email(t("invalidEmail")).required(t("requiredField")),
    phone: Yup.string()
      .matches(/^\+[1-9]\d{10,14}$/, t("Invalid phone number must be in format +XXXXXXXXXXX"))
      .required(t("requiredField")),
    phone_alt: Yup.string()
      .nullable()
      .matches(/^\+[1-9]\d{10,14}$/, {
        message: t("Invalid phone number must be in format +XXXXXXXXXXX"),
        excludeEmptyString: true
      }),
    address: Yup.string().required(t("requiredField")),
    website: Yup.string().nullable().matches(/^(http|https):\/\/[^ "]+$/, t("Invalid URL")),
    facebook: Yup.string().nullable().matches(/^(http|https):\/\/[^ "]+$/, t("Invalid URL")),
    instagram: Yup.string().nullable().matches(/^(http|https):\/\/[^ "]+$/, t("Invalid URL")),
    twitter: Yup.string().nullable().matches(/^(http|https):\/\/[^ "]+$/, t("Invalid URL"))
  });

  const getThirdParty = async () => {
    try {
      const response = await getThirdPartyApi(id);
      if (response.data) {
        setThirdParty(response.data);
      } else if (response.error?.data) {
        notifyErrors(response.error.data);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (e) {
      console.error(e);
      notifyErrors(e.message);
    }
  };

  useEffect(() => {
    getThirdParty();
  }, [id]);

  const onSubmit = async (values) => {
    try {
      console.log(values);
      // Map area to area.id before submitting
      const updatedValues = {
        ...values,
        area: values.area ? values.area.id : null // Ensure area.id is set or null if no area
      };

      const result = await updateThirdParty({ id, ...updatedValues });
      if (result.data) {
        notification.success({
          message: "Success",
          description: t("thirdPartyUpdated")
        });
      } else if (result.error?.data) {
        notifyErrors(result.error.data);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (e) {
      console.error(e);
      notifyErrors(e.message);
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 999999 }}
      />
      <PageHeader title={t("thirdPartyInfo")} group={t("sideMenu.accounts")} />
      <div className="d-flex flex-column gap-3">
        {(fetching || !thirdParty) && (
          <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "50vh" }}>
            <Spin size="large" />
          </div>
        )}
        {!fetching && thirdParty?.id && (
          <>
            <GenericForm
              fields={fields}
              hooks={{ initialValues: thirdParty }}
              submitFunction={onSubmit}
              validationSchema={validationSchema}
              submitButtonText={t("save")}
              loading={loading}
            />
            <ThirdPartyKeys keys={thirdParty} setKeys={setThirdParty} thirdPartyId={id} />
          </>
        )}
      </div>
    </div>
  );
};

export default ThirdPartyInfo;
