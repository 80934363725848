import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/form.css";
import { useTranslation } from "react-i18next";
import PostponedOrders from "../forms/postponedOrders";
import NewPostponeDate from "../forms/newPostponeDate";
import PostponeRequest from "../forms/postponeRequest";
import { useUserObject } from "../../stateManagement/slices/userSlice";

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
const PostponeOrder = (props) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const user = useUserObject();
  const { t } = useTranslation();

  return (
    <div>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.tickets")}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("sideMenu.postpond")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("createPostpondRequest")}</h2>
        </Col>
      </Row>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            aria-label="basic tabs example"
            className={`${user.role === "Seller" ? "seller" : "admin"}`}
          >
            <Tab label={t("productInfo")} {...a11yProps(0)} />
            <Tab label={t("newDate")} {...a11yProps(1)} />
            <Tab label={t("reviewRequest")} {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <PostponedOrders value={value} handleChange={handleChange} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <NewPostponeDate value={value} handleChange={handleChange} />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <PostponeRequest value={value} handleChange={handleChange} />
        </TabPanel>
      </Box>
    </div>
  );
};

export default PostponeOrder;
