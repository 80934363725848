import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useGetPlansMutation } from "../../../stateManagement/apis/subscriptionApi";

function Billing () {
  const [plans, setPlans] = useState([]);
  const [planConsumptionApi] = useGetPlansMutation();
  const getPlans = async () => {
    const result = await planConsumptionApi();

    if (result.data) {
      console.log(result);
      setPlans(result.data);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);
  return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">Settings</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">Billing</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">Billing</h2>
                </Col>
            </Row>
            <Card className="text-center">
                <Card.Header>My Plans</Card.Header>
                <Card.Body>
                    {plans.map(plan => {
                      console.log(plan);
                      return (
                            <div className="plan" key={plan.id}>

                                {/* <Card.Title>Special title treatment</Card.Title> */}
                                <Card.Text>
                                    {plan.name}
                                </Card.Text>
                            </div>
                      );
                    })}
                    {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
                <Card.Footer className="text-muted">
                </Card.Footer>
            </Card>

        </div>
  );
}

export default Billing;
