import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Row,
  Col,
  Typography,
  message
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  useGetProductCategoriesMutation,
  useCreateProductMutation,
  useGetProductMutation,
  useUpdateProductMutation
} from "../../../stateManagement/apis/treecodeApi";
import { useGetSellersMutation } from "../../../stateManagement/apis/accountsApi";

const { Title } = Typography;

const AddInventoryItem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const productId = parseInt(id);
  const [form] = Form.useForm();
  const [productPhotos, setProductPhotos] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [getSellers] = useGetSellersMutation();
  const [getProductCategories] = useGetProductCategoriesMutation();
  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [getProduct] = useGetProductMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sellersResponse = await getSellers().unwrap();
        setSellers(sellersResponse.data || []);

        const categoriesResponse = await getProductCategories({
          page: 1,
          limit: 10
        }).unwrap();
        setCategories(categoriesResponse.data || []);

        if (productId) {
          const productResponse = await getProduct(productId).unwrap();
          const productData = productResponse;

          form.setFieldsValue({
            productName: productData.name,
            price: productData.price,
            description: productData.description,
            sku: productData.sku,
            barcode: productData.barcode,
            category: productData.category.id,
            merchant: productData.seller,
            instructions: productData.instructions
          });

          setProductPhotos(
            productData.photos.map((photo) => ({ image: photo.image }))
          );
        }
      } catch (error) {
        message.error(t("addProduct.messages.loadDataFailed"));
      }
    };

    fetchData();
  }, [getSellers, getProductCategories, getProduct, productId, form, t]);

  const handleUploadChange = ({ fileList }) => {
    const processFileToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({ image: reader.result });
        reader.onerror = (error) => reject(error);
      });

    Promise.all(
      fileList.map(async (file) => {
        if (file.originFileObj) {
          const isSupportedType =
            file.type === "image/jpeg" || file.type === "image/png";
          const isUnder5MB = file.size / 1024 / 1024 < 5;

          if (!isSupportedType || !isUnder5MB) {
            message.error(
              t("addProduct.messages.invalidFile", { fileName: file.name })
            );
            return null;
          }

          return processFileToBase64(file.originFileObj);
        }
        return { image: file.url }; // Use existing URL for already uploaded images
      })
    ).then((photos) => {
      const newPhotos = photos.filter((photo) => photo !== null);
      setProductPhotos(newPhotos);
    });
  };

  const handleRemoveImage = (file) => {
    const updatedPhotos = productPhotos.filter((_, index) => index.toString() !== file.uid);
    setProductPhotos(updatedPhotos);
  };

  const handleFinish = async (values) => {
    const payload = {
      name: values.productName,
      price: parseFloat(values.price),
      description: values.description || "",
      sku: values.sku,
      seller: values.merchant,
      category: values.category,
      instructions: values.instructions,
      barcode: values.barcode,
      photos: productPhotos
    };

    try {
      setIsSubmitting(true);
      if (productId) {
        await updateProduct({ productId, ...payload }).unwrap();
        message.success(t("addProduct.messages.productUpdated"));
      } else {
        await createProduct(payload).unwrap();
        message.success(t("addProduct.messages.productCreated"));
      }
      navigate("/inventory_items");
      form.resetFields();
      setProductPhotos([]);
    } catch (error) {
      message.error(error.data?.message || t("addProduct.messages.createProductFailed"));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      style={{
        padding: 40,
        maxWidth: 1000,
        margin: "auto",
        background: "#fff",
        borderRadius: 8,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)"
      }}
    >
      <Title level={3} style={{ marginBottom: 24 }}>
        {t("addProduct.title")}
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Row gutter={32}>
          <Col span={8}>
            <Form.Item
              label={t("addProduct.photoLabel")}
              name="productPhotos"
              rules={[
                {
                  required: true,
                  message: t("addProduct.photoRequired")
                }
              ]}
            >
              <Upload
                listType="picture-card"
                multiple
                maxCount={5}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={handleUploadChange}
                onRemove={handleRemoveImage}
                fileList={productPhotos.map((photo, index) => ({
                  uid: index.toString(),
                  name: t("addProduct.photo", { index: index + 1 }),
                  status: "done",
                  url: photo.image
                }))}
              >
                {productPhotos.length < 5 && (
                  <div>
                    <PlusOutlined style={{ fontSize: 24 }} />
                    <div style={{ marginTop: 8, fontSize: 12 }}>{t("addProduct.upload")}</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Col>

          <Col span={16}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={t("addProduct.productNameLabel")}
                  name="productName"
                  rules={[
                    {
                      required: true,
                      message: t("addProduct.productNameRequired")
                    }
                  ]}
                >
                  <Input
                    placeholder={t("addProduct.productNamePlaceholder")}
                    style={{ borderRadius: 4 }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t("addProduct.skuLabel")}
                  name="sku"
                  rules={[
                    {
                      required: true,
                      message: t("addProduct.skuRequired")
                    }
                  ]}
                >
                  <Input
                    placeholder={t("addProduct.skuPlaceholder")}
                    style={{ borderRadius: 4 }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t("addProduct.barcodeLabel")}
                  name="barcode"
                  rules={[
                    {
                      required: true,
                      message: t("addProduct.barcodeRequired")
                    }
                  ]}
                >
                  <Input
                    placeholder={t("addProduct.barcodePlaceholder")}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t("addProduct.categoryLabel")}
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: t("addProduct.categoryRequired")
                    }
                  ]}
                >
                  <Select
                    placeholder={t("addProduct.categoryPlaceholder")}
                    style={{ borderRadius: 4 }}
                  >
                    {categories.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t("addProduct.merchantLabel")}
                  name="merchant"
                  rules={[
                    {
                      required: true,
                      message: t("addProduct.merchantRequired")
                    }
                  ]}
                >
                  <Select
                    placeholder={t("addProduct.merchantPlaceholder")}
                    style={{ borderRadius: 4 }}
                  >
                    {sellers.map((seller) => (
                      <Select.Option key={seller.id} value={seller.id}>
                        {seller.user.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t("addProduct.priceLabel")}
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: t("addProduct.priceRequired")
                    },
                    {
                      pattern: /^\d+(\.\d{1,2})?$/,
                      message: t("addProduct.priceInvalid")
                    }
                  ]}
                >
                  <Input
                    type="number"
                    placeholder={t("addProduct.pricePlaceholder")}
                    min="0"
                    step="0.01"
                    style={{ borderRadius: 4 }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("addProduct.descriptionLabel")}
                  name="description"
                >
                  <Input.TextArea
                    rows={3}
                    placeholder={t("addProduct.descriptionPlaceholder")}
                    style={{ borderRadius: 4 }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("addProduct.instructionsLabel")}
                  name="instructions"
                  rules={[
                    {
                      required: true,
                      message: t("addProduct.instructionsRequired")
                    }
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder={t("addProduct.instructionsPlaceholder")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginTop: 24 }}>
          <Col>
            <Button
              type="default"
              style={{ borderRadius: 4 }}
              onClick={() => navigate("/inventory_items")}
            >
              {t("addProduct.cancel")}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              style={{ borderRadius: 4 }}
            >
              {t("addProduct.next")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddInventoryItem;
