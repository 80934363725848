import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

const NextStatesModel = (props) => {
  console.log(props);
  const { t } = useTranslation();
  const lang = useLanguage();

  return (
    <div
      dir={lang === "en" ? "ltr" : "rtl"}
      className={`Modal deliveryRangeDetails ${
        props.show ? "ModalOpen" : "ModalClosed"
      }`}
      style={{ padding: "10px 0px" }}
    >
      <h6 className="p-3">{t("trackingTable.possible")}</h6>
      {props.details.accepted_states.length !== 0
        ? (
            props.details.accepted_states.map((state) => {
              return (
            <div key={state.id} className="stateList">
              <p className="ps-3">
                {lang === "en" ? state.title : state.title_ar}
              </p>
            </div>
              );
            })
          )
        : (
        <div className="">
          <p className="ps-3">{t("noStates")}</p>
        </div>
          )}

      <Row>
        <Col style={{ textAlign: "end", padding: "12px", marginInlineEnd: "15px" }}>
          <button onClick={props.close} className="deactivate">
            {t("cancel")}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default NextStatesModel;
