import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import { useTranslation } from "react-i18next";
import { notification, Form as AntForm, Button, Select, Input, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";

import WareHousesRepository from "../../../api/WarehousesRepository";
import { LOCATION_TYPES } from "./constants";

const WarehouseLocationDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { warehouseId, locationId } = useParams();
  const [location, setLocation] = useState();
  const [locationForm] = AntForm.useForm();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [locations, setLocations] = useState();
  const [locationFields, setLocationFields] = useState();
  const [warehouse, setWarehouse] = useState();

  const getLocations = async () => {
    setLoading(true);
    const response = await WareHousesRepository.warehouseLocations(warehouseId, { page: 1, limit: 1000 });
    if (response.success) {
      setLocations(response.data.map((location) => ({ value: location.id, label: location.name })));
    } else if (response.status === 400) {
      Object.entries(response.error).forEach(([key, value]) => notification.error({ message: value instanceof Array ? value[0] : value }));
    } else {
      notification.error({ message: response.error });
    }
    setLoading(false);
  };

  const getLocation = async () => {
    setLoading(true);
    const response = await WareHousesRepository.warehouseLocation(warehouseId, locationId);
    if (response.success) {
      setLocation(response.data);
    } else if (response.status === 400) {
      Object.entries(response.error).forEach(([key, value]) => notification.error({ message: value instanceof Array ? value[0] : value }));
    } else {
      notification.error({ message: response.error });
    }
    setLoading(false);
  };

  useEffect(() => {
    setLocationFields([
      {
        name: "name",
        label: t("Location Name"),
        type: "text",
        rules: [
          {
            required: true,
            message: t("requiredField")
          }
        ]
      },
      {
        name: "code",
        label: t("Location Code"),
        type: "text",
        rules: [
          {
            // max: 20 characters with no spaces allow letters and digits and _ and - only
            pattern: /^[a-zA-Z0-9_-]{6,20}$/,
            message: t("Location name must be between 6 and 20 characters and contain only letters, digits, _ and -")
          }
        ]
      },
      {
        name: "type",
        label: t("Location Type"),
        type: "select",
        options: Object.entries(LOCATION_TYPES).map(([value, label]) => ({ value, label }))
      },
      {
        name: "parent",
        label: t("Parent Location"),
        type: "select",
        options: locations
      }
    ]);
  }, [locations]);

  const getWarehouse = async () => {
    setLoading(true);
    const response = await WareHousesRepository.getWarehouse(warehouseId);
    if (response.success) {
      setWarehouse(response.data);
    } else if (response.status === 400) {
      Object.entries(response.error).forEach(([key, value]) => notification.error({ message: value instanceof Array ? value[0] : value }));
    } else {
      notification.error({ message: response.error });
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    locationForm.setFieldValue(event.target.name, event.target.value);
    setDirty(locationForm.isFieldsTouched(false));
    console.log("Is dirty", dirty);
  };

  useEffect(() => {
    getLocations();
    getWarehouse();
    if (locationId !== "create") {
      getLocation();
    } else {
      setLocation({
        id: "demo",
        name: "",
        code: "",
        address: "",
        branches: []
      });
    }
  }, []);

  const onFinish = async (values) => {
    let response;
    setSubmitting(true);
    if (locationId === "create") {
      response = await WareHousesRepository.createWarehouseLocation(warehouseId, values);
    } else {
      response = await WareHousesRepository.updateWarehouseLocation(warehouseId, locationId, values);
    }
    if (response.success) {
      notification.success({ message: t("Warehouse has been updated successfully") });
      setDirty(false);
      if (locationId === "create") {
        navigate(`/warehouse/${warehouseId}/locations/${response.data.id}`);
      }
    } else if (response.status === 400) {
      const nonFieldErrors = [];
      locationForm.setFields([
        Object.entries(response.error).filter(([key, value]) => {
          if (locationFields.map((field) => field.name).includes(key)) {
            return true;
          } else {
            nonFieldErrors.push(value instanceof Array ? value[0] : value);
            return false;
          }
        })
          .map(([key, value]) => ({
            name: key,
            errors: value instanceof Array ? value : [value]
          }))
      ]);
      if (nonFieldErrors.length > 0) {
        nonFieldErrors.forEach((error) => notification.error({ message: error }));
      }
      setDirty(false);
    } else {
      notification.error({ message: response.error });
    }
    setSubmitting(false);
  };

  return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">
                            {t("sideMenu.warehouses")}
                        </span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title1">
                            {warehouse?.name + " " + t("sideMenu.locations")}
                        </span>
                        <i className="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {locationId === "create" ? t("createWarehouselocation") : t("editWarehouseLocation")}
                        </span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {locationId === "create" ? t("newWarehouseLocation") : location?.name || t("editWarehouseLocation")}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <div>
                        {
                            (loading || !warehouse || !locations || !location) && <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "50vh" }}> <Spin size="large" /> </div>
                        }
                        {
                            !loading && warehouse?.id && locations && location?.id && locationFields && (
                        <div>
                           <AntForm
                            layout="vertical"
                            className="grid-form small-gap"
                            onFinish={onFinish}
                            form={locationForm}
                            // onFinishFailed={onFinishFailed}
                            initialValues={location}
                            requiredMark
                            labelWrap
                            >
                                {
                                    locationFields.map((field, index) => (
                                        <div key={index}>
                                            <AntForm.Item
                                                name={field.name}
                                                label={field.label}
                                                rules={field.rules || []}
                                            >
                                                {
                                                    field.type === "select"
                                                      ? (
                                                        <Select
                                                            mode={field.selectMultiple ? "multiple" : "default"}
                                                            placeholder={field.label}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={(value) => handleChange({ target: { name: field.name, value } })}
                                                            >
                                                                {
                                                                    field.options?.map((option, index) => (
                                                                        <Select.Option key={index} value={option.value}>
                                                                            {option.label}
                                                                        </Select.Option>
                                                                    ))
                                                                }
                                                        </Select>
                                                        )
                                                      : (
                                                        <Input
                                                            type={field.type}
                                                            disabled={field.disabled}
                                                            placeholder={field.label}
                                                            onChange={handleChange}
                                                        />
                                                        )
                                                }
                                            </AntForm.Item>
                                        </div>
                                    ))
                                }
                            </AntForm>
                                <div className="w-100 d-flex justify-content-end py-4">
                                    <AntForm.Item>
                                        <Button htmlType="submit" style={{
                                          backgroundColor: "#57BE6C",
                                          color: "#fff",
                                          borderColor: "#57BE6C"
                                        }} type="primary" loading={submitting}
                                            disabled={submitting || !dirty}
                                            onClick={() => locationForm.submit()}
                                        >
                                            {locationId === "create" ? t("Create Location") : t("Update Location")}
                                        </Button>
                                    </AntForm.Item>
                                </div>
                        </div>
                            )
                        }
                    </div>
                </Row>
            </Container>
        </div>
  );
};

export default WarehouseLocationDetails;
