import { createApi } from "@reduxjs/toolkit/query/react";
import { COMMENT_API } from "../constants";
import { reAuthBaseQuery } from "./baseQuery";

export const commentsApi = createApi({
  reducerPath: "commentsApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["Comment"],
  endpoints: (builder) => ({
    getComments: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParams }) => ({
        url: COMMENT_API.ROOT + "/",
        method: "GET",
        params: { page, limit, ...queryParams }
      }),
      providesTags: ["Comment"]
    }),
    // Fetch comments with pagination and search
    fetchComments: builder.query({
      query: ({ page = 1, search = "" }) => ({
        url: COMMENT_API.ROOT + "/",
        method: "GET",
        params: { page, search }
      }),
      providesTags: ["Comment"]
    }),

    // Create a standalone comment
    createComment: builder.mutation({
      query: (newComment) => ({
        url: COMMENT_API.ROOT + "/",
        method: "POST",
        body: newComment
      }),
      invalidatesTags: ["Comment"]
    }),

    // Update a comment
    updateComment: builder.mutation({
      query: ({ id, ...updatedFields }) => ({
        url: `${COMMENT_API.ROOT}/${id}/`,
        method: "PUT",
        body: updatedFields
      }),
      invalidatesTags: ["Comment"]
    }),

    // Delete a comment
    deleteComment: builder.mutation({
      query: (id) => ({
        url: `${COMMENT_API.ROOT}/${id}/`,
        method: "DELETE"
      }),
      invalidatesTags: ["Comment"]
    })
  })
});

export const {
  useFetchCommentsQuery,
  useGetCommentsMutation,
  useCreateCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation
} = commentsApi;
