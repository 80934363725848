import React from "react";
import styles from "./WorkModelCard.module.css";

export default function WorkModelCard ({ title, actions, children: body }) {
  return (
        <div className={[styles.container, "setting-card"].join(" ")}>
            <header>{title}</header>
            <hr/>
            <div className={styles.body}>{body}</div>
            {actions && <div className={styles.actions}>{actions}</div>}
        </div>
  );
}
