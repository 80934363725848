import { createApi } from "@reduxjs/toolkit/query/react";
import { PUBLIC_SCHEMA_API } from "../constants";
import { publicSchemaBaseQuery } from "./baseQuery";

export const publicSchemaApi = createApi({
  reducerPath: "publicSchemaApi",
  baseQuery: publicSchemaBaseQuery,
  tagTypes: ["PublicSchema", "Plugins"],
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (body) => ({
        url: `${PUBLIC_SCHEMA_API.REGISTER}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["PublicSchema"]
    }),
    // Plugins
    getPlugins: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: PUBLIC_SCHEMA_API.PLUGINS_ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Plugins"]
    }),
    getPlugin: builder.mutation({
      query: (pluginId) => ({
        url: `${PUBLIC_SCHEMA_API.PLUGINS_ROOT}/${pluginId}`,
        method: "GET"
      }),
      providesTags: ["Plugins"]
    }),
    updatePlugin: builder.mutation({
      query: ({ pluginId, ...body }) => ({
        url: `${PUBLIC_SCHEMA_API.PLUGINS_ROOT}/${pluginId}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: ["Plugins"]
    })

  })
});

export const { useRegisterMutation, useGetPluginsMutation, useGetPluginMutation, useUpdatePluginMutation } = publicSchemaApi;
