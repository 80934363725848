import { Modal, Form as AntForm, Input, Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const CategoryModal = ({ open, onClose, onFinish, loading, category, setCategory }) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered // Centers the modal on the screen
      title={category.id ? t("update") : t("createProductCategory")}
      open={open}
      onCancel={onClose}
      footer={null} // Custom footer for new design
    >
      <AntForm layout="vertical">
        <AntForm.Item
          label={t("Name")}
          rules={[{ required: true, message: t("Please input name!") }]}
        >
          <Input
            placeholder={t("Enter category name")}
            value={category.name}
            onChange={(e) => setCategory({ ...category, name: e.target.value })}
          />
        </AntForm.Item>
        <AntForm.Item
          label={t("Name (Arabic)")}
          rules={[{ required: true, message: t("Please input name!") }]}
        >
          <Input
            placeholder={t("Enter category name in Arabic")}
            value={category.name_ar}
            onChange={(e) => setCategory({ ...category, name_ar: e.target.value })}
          />
        </AntForm.Item>
        <div className="d-flex justify-content-end gap-3 mt-3">
          <Button type="default" onClick={onClose} style={{ borderColor: "#E4E6EB" }}>
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            onClick={onFinish}
            loading={loading}
            style={{ backgroundColor: "#2F80ED", borderColor: "#2F80ED" }}
          >
            {t(category.id ? "update" : "create")}
          </Button>
        </div>
      </AntForm>
    </Modal>
  );
};

export default CategoryModal;

export const DeleteCategoryModal = ({ open, onClose, onConfirm, loading, category }) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered // Centers the modal on the screen
      title={t("DeleteCategory")}
      open={open}
      onCancel={onClose}
      footer={null} // Custom footer for new design
    >
      <div>
        <Text style={{ fontSize: "16px", color: "#595959" }}>
          {t("Are you sure you want to delete the category?")}
        </Text>
        <br />
        <Text strong style={{ fontSize: "16px", color: "#D32F2F" }}>
          {category?.name || t("Unnamed Category")}{" "}
          {category?.name_ar ? `(${category.name_ar})` : ""}
        </Text>
      </div>

      <div className="d-flex justify-content-end gap-3 mt-4">
        <Button type="default" onClick={onClose} style={{ borderColor: "#E4E6EB" }}>
          {t("cancel")}
        </Button>
        <Button
          type="danger"
          danger
          onClick={onConfirm}
          loading={loading}
          style={{ borderColor: "red" }}
        >
          {t("Delete")}
        </Button>
      </div>
    </Modal>
  );
};
