import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useTicketsColumns
} from "../../layout/tableColumns";
import {
  getSeller
} from "../../../stateManagement/slices/selectedSellerSlice";
import TablePro from "../../tables/TablePro";
import { useUserObject } from "../../../stateManagement/slices/userSlice";
import { useGetSellerMutation } from "../../../stateManagement/apis/accountsApi";
import { useFilterTicketsMutation } from "../../../stateManagement/apis/ticketsApi";

const ProductInfoTable = (props) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [page, setPage] = useState(1);
  const orderSellerDispatch = useDispatch();
  const user = useUserObject();
  const [getSellerApi] = useGetSellerMutation();
  const [filterTickets, { isLoading: loading }] = useFilterTicketsMutation();

  const getData = async () => {
    const params = {
      page,
      search: keyword
    };

    if (props.returnType === "REVERSE") {
      params.order__seller__id = user.role === "Seller" ? user.id : null;
      params.state__in = "8";
    } else if (props.returnType === "RETURN") {
      params.state__in = "6";
    }
    Object.keys(params).forEach((key) => !params[key] && delete params[key]);
    const result = await filterTickets(params);

    if (result.data) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    console.log(selectedTicket);
    props.getReturnedTicketsObject(selectedTicket);
  }, [selectedTicket]);

  useEffect(() => {
    getData();
  }, [keyword, page]);

  const getSellerData = async (id) => {
    const result = await getSellerApi(id);
    if (result.data) {
      orderSellerDispatch(getSeller(result.data));
    }
  };

  const proceed = async () => {
    if (selectedTicket.length !== 0) {
      const sellerId = selectedTicket[0]?.order?.seller?.id; // Safely access seller ID
      if (sellerId) {
        props.handleChange(null, props.value + 1);
        await getSellerData(sellerId);
      } else {
        console.warn("Seller ID is undefined in the selected ticket.");
      }
    }
  };

  const { t } = useTranslation();

  return (
    <div>
      <Container>
        <TablePro
          onSearch={setKeyword}
          dataSource={data}
          columns={useTicketsColumns()}
          loading={loading}
          pagination={{
            current: page,
            total: count,
            onChange: (page) => setPage(page)
          }}
          selection={"checkbox"}
          selectedRows={selectionModel}
          setSelectedRows={setSelectionModel}
          setSelectedRecords={(selected) => {
            const validSelected = selected.filter((ticket) => ticket?.order); // Filter valid rows
            setSelectedTicket(validSelected);
          }}
          // unselectableRowsCondition={(record) => {
          //   const firstTicket = selectionModel[0]
          //     ? data.find((it) => it.id === selectionModel[0]) // Use `find` for a single match
          //     : data[0];

          //   return (
          //     record?.order?.seller?.id !== firstTicket?.order?.seller?.id // Use optional chaining
          //   );
          // }}
        />
        <Row>
          <Col className="text-end mt-2" sm="12">
            <button className="cancel" disabled>
              {t("discard")}
            </button>
            <button
              disabled={selectedTicket.length === 0}
              className={
                user.role === "Seller"
                  ? "confirmSeller"
                  : "confirm"
              }
              onClick={proceed}
              type="submit"
            >
              {t("proceed")}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductInfoTable;
