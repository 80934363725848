import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import Cookies from "js-cookie";
import { BaseUrl, AUTH_API, BasePublicUrl } from "../constants";
import { refreshTokenAction, clearAuthAction } from "..//authActions";
import { notifyErrors } from "../../api/ErrorNotifier";

const baseQuery = fetchBaseQuery({
  baseUrl: BaseUrl,
  prepareHeaders: (headers, { getState, extra }) => {
    const token = getState().auth.accessToken;
    headers.set("Accept-Language", getState().auth.language);
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    // if (!extra.isFormData) {
    //   headers.set("Content-Type", "application/json");
    // }
    return headers;
  }
});

const publicSchemaBaseQuery = fetchBaseQuery({
  baseUrl: BasePublicUrl,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Accept-Language", getState().auth.language);
    // headers.set("Content-Type", "application/json");
    return headers;
  }
});

const reAuthBaseQuery = async (args, api, extraOptions) => {
  // const extra = {
  //   ...(api.extra ?? {}),
  //   isFormData: args.isFormData ?? false
  // };
  // api.extra = extra;
  let result = await baseQuery(args, api, extraOptions);
  const isAuthError = result.error && result.error.status === 401;
  if (isAuthError && args.url !== AUTH_API.LOGIN) {
    const refreshToken = Cookies.get("refreshToken");
    if (refreshToken) {
      const refreshResult = await baseQuery({
        url: AUTH_API.REFRESH,
        method: "POST",
        body: { refresh: refreshToken }
      }, api, extraOptions
      );
      if (refreshResult.data) {
        api.dispatch(refreshTokenAction(refreshResult.data));
        result = await baseQuery(args, api, extraOptions);
      } else {
        notifyErrors("Session expired, please login again");
        api.dispatch(clearAuthAction());
      }
    } else {
      notifyErrors("Session expired, please login again");
      api.dispatch(clearAuthAction());
    }
  }
  return result;
};

export {
  reAuthBaseQuery,
  publicSchemaBaseQuery
};
