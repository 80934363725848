import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Typography, Space, Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useCourierWalletColumns } from "../../layout/tableColumns";
import TablePro from "../../tables/TablePro";
import { notifyErrors } from "../../../api/ErrorNotifier";
import { useGetCourierWalletsMutation, useSettleCourierBalanceMutation } from "../../../stateManagement/apis/accountsApi";

const { Content } = Layout;
const { Title, Text } = Typography;

const CourierWallets = () => {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 10,
    search: ""
  });
  const [courierWallets, setCourierWallets] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedCourierWallet, setSelectedCourierWallet] = useState(null);
  const [getCourierWalletsApi, { isLoading: loading }] = useGetCourierWalletsMutation();
  const [settleCourierBalanceApi, { isLoading: loadingSettlementAction }] = useSettleCourierBalanceMutation();

  const handleSettlementAction = async (id) => {
    setSelectedCourierWallet(id);
    const response = await settleCourierBalanceApi({
      courierWalletId: id
    });
    if (response.data) {
      getCourierWallets();
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors(t("somethingWentWrong"));
    }
    setSelectedCourierWallet(null);
  };

  const columns = useCourierWalletColumns(
    handleSettlementAction,
    loadingSettlementAction,
    selectedCourierWallet
  );

  const getCourierWallets = async () => {
    const response = await getCourierWalletsApi(
      queryParams
    );
    if (response.data) {
      setCourierWallets(response.data.data);
      setCount(response.data.count);
    } else if (response.error?.data) {
      notifyErrors(response.error.data);
    } else {
      notifyErrors(t("somethingWentWrong"));
    }
  };

  const handleChangeQueryParams = (param, value) => {
    setQueryParams({
      ...queryParams,
      [param]: value,
      page: param === "page" ? value : 1
    });
  };

  useEffect(() => {
    getCourierWallets();
  }, [queryParams]);

  return (
        <Layout style={{ backgroundColor: "transparent" }}>
        <Content style={{ padding: "24px" }}>
          <Card bordered={false} style={{ marginBottom: "24px" }}>
            <Row gutter={[16, 16]} align="middle">
              <Col span={24}>
                <Space size="middle" style={{ fontSize: "16px" }}>
                  <Text strong>{t("sideMenu.wallets")}</Text>
                  <RightOutlined />
                  <Text>{t("sideMenu.courierWallets")}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Title level={3} style={{ margin: "16px 0" }}>
                    {t("sideMenu.allCourierWallets")}
                </Title>
              </Col>
            </Row>
          </Card>

          <Card bordered={false}>

            <Row>
                <div className="w-100">
                    <TablePro
                        loading={loading}
                        emptyPlaceholder={
                            <div>
                                <h6 className="noDataHead">
                                    {t("noDataFound")}
                                </h6>
                                <p>
                                    {t(
                                      "There are no order types available"
                                    )}
                                </p>
                            </div>
                        }
                        onSearch={(value) =>
                          handleChangeQueryParams("search", value)
                        }
                        dataSource={courierWallets}
                        columns={columns}
                        pagination={{
                          current: queryParams.page,
                          total: count,
                          onChange: (page) =>
                            handleChangeQueryParams("page", page)
                        }}
                    />
                </div>
            </Row>
        </Card>
    </Content>
</Layout>
  );
};

export default CourierWallets;
