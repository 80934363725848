import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import { usePostponedColumns } from "../layout/tableColumns";
import { postponedIds, postponedOrders } from "../../stateManagement/slices/postponedOrdersSlice";
import TablePro from "../tables/TablePro";
import { useFilterOrdersMutation } from "../../stateManagement/apis/ordersApi";

function PostponedOrders ({ value, handleChange }) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const { t } = useTranslation();
  const columns = usePostponedColumns();
  const dispatch = useDispatch();
  const [filterOrders] = useFilterOrdersMutation();

  // Fetch orders data based on search and page
  const fetchData = async () => {
    const result = await filterOrders({ page, search, state: "1" });
    if (result.data) {
      setData(result.data.data || []);
      setCount(result.data.count || 0);
    }
  };

  // Debounced search handler
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setPage(1); // Reset to the first page on new search
    }, 300),
    []
  );

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Update orders and IDs in Redux
  useEffect(() => {
    if (selectedRows.length > 0 || selectedRecords.length > 0) {
      console.log("Dispatching selected records and rows:", {
        selectedRecords,
        selectedRows
      });
      dispatch(postponedOrders(selectedRecords));
      dispatch(postponedIds(selectedRows));
    }
  }, [selectedRows, selectedRecords, dispatch]);

  // Trigger fetchData on search or page change
  useEffect(() => {
    if (search !== null) {
      fetchData();
    }
  }, [page, search]);

  // Handle proceed button
  const proceed = () => handleChange(null, value + 1);

  return (
    <>
      <Row className="mt-3">
        <Col sm="12" md="6">
          <div className="input-icons">
            <i className="fa fa-search icon"></i>
            <input
              name="search"
              className="searchInput"
              placeholder={t("searchOrderID")}
              type="text"
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-end buttons-margin mb-3" sm="12">
          <button
            disabled={selectedRows.length === 0}
            onClick={proceed}
            className="confirm"
          >
            {t("next")}
          </button>
        </Col>
      </Row>
      {data.length > 0
        ? (
        <TablePro
          dataSource={data}
          columns={columns}
          selection="checkbox"
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setSelectedRecords={setSelectedRecords}
          pagination={{
            current: page,
            total: count,
            onChange: setPage
          }}
        />
          )
        : (
        <div className="noDataDiv1">
          <h6 className="noDataHead">{t("postponedTxt1")}</h6>
          <p>{t("postponedTxt2")}</p>
        </div>
          )}
    </>
  );
}

export default PostponedOrders;
